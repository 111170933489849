import React, { useEffect, useState } from "react";
import ContentView from "../../pageTemplates/contentView";
import { useInit } from "../../components/hooks/useInit";
import axios from "axios";
import moment from "moment-timezone";
import DropDownSelector_V4 from "../../components/inputs/dropDownSelector_V4";
import Button from "../../components/UI_elements/button";
import { useInputV4 } from "../../stateManagement/useInputV4";
import Input_V4 from "../../components/inputs/input_V4";
import IconSpinner from "../../components/UI_elements/iconSpinner";
import { VALIDATOR_MINLENGTH } from "../../components/forms/validators/validators";
import WeeklyHours from "../../utils/weeklyHours";

const UserSettings = () => {
  const { initState, initStarted, initCompleted, initErrors } = useInit(2);
  const { inputState, inputHandler, removeSidHandler, attachmentHandler } = useInputV4();
  const [userData, setUserData] = useState();
  const [selectedTimeZone, setSelectedTimeZone] = useState("UTC");
  const [currentTime, setCurrentTime] = useState("");
  const [edit, setEdit] = useState(false);
  const [noAvatar, setNoAvatar] = useState(true);

  const getUserDetailsHandler = () => {
    initStarted(0);

    axios
      .get(process.env.REACT_APP_BACKEND_URL + `/api/team/userSettings`, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      .then((response) => {
        setUserData(response.data);
        initCompleted(0);
      })
      .catch((err) => {
        console.log(err);
        const errorMessage = err?.response?.data?.message ?? err?.message ?? "";
        initErrors(errorMessage, 0);
      });
  };

  useEffect(() => {
    getUserDetailsHandler();
  }, []);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setCurrentTime(moment.tz(selectedTimeZone).format("YYYY-MM-DD HH:mm:ss"));
  //   }, 1000);

  //   return () => clearInterval(interval);
  // }, [selectedTimeZone]);

  const handleTimeZoneChange = (event) => {
    setSelectedTimeZone(event.target.value);
  };

  const timeZones = moment.tz.names();

  const saveUserDetailsHandler = () => {
    initStarted(1);

    axios
      .put(
        process.env.REACT_APP_BACKEND_URL + `/api/team/userSettings/update`,
        {
          firstName: inputState.inputs.firstName.value,
          lastName: inputState.inputs.lastName.value,
          timezone: inputState.inputs.timezone.value,
          // noAvatar: noAvatar,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        initCompleted(1);
        setEdit();
      })
      .catch((err) => {
        console.log(err);
        const errorMessage = err?.response?.data?.message ?? err?.message ?? "";
        initErrors(errorMessage, 1);
      });
  };


  return (
    <ContentView buttons={[]} label={"User Settings"}>
      {!initState[0].started ? (
        <div className="flex mt-2 ">
          <div className="bg-taskinatorWhite p-4 mx-1 rounded shadow w-fit">
            {/* <div>User Settings</div> */}

            <div className="w-96">
              <div className=" ">
                {!noAvatar ? (
                  <img style={{ width: "auto", height: "auto", maxWidth: "100%", maxHeight: "100%" }} src={null} alt="No Preview" />
                ) : (
                  <div className="h-32 w-32 rounded-full bg-taskinatorMedGrey flex items-center justify-center">
                    <div className="text-3xl uppercase">
                      {userData?.firstName?.[0]}
                      {userData?.lastName?.[0]}
                    </div>
                  </div>
                )}
                {/* <div className="w-40 text-center underlined cursor-pointer hover:opacity-50">Edit</div> */}
              </div>

              <div className="pt-4">
                <Input_V4 onInput={inputHandler} sid="inputs" id="firstName" label="FirstName" defaultValue={userData?.firstName ?? ""} disabled={!edit} validators={[VALIDATOR_MINLENGTH(1)]} />
              </div>
              <div className="pt-2">
                <Input_V4 onInput={inputHandler} sid="inputs" id="lastName" label="Last Name" defaultValue={userData?.lastName ?? ""} disabled={!edit} validators={[VALIDATOR_MINLENGTH(1)]} />
              </div>
              <div></div>
              <div className="pt-2">
                <DropDownSelector_V4
                  label={"Select Time Zone:"}
                  sid="inputs"
                  id="timezone"
                  defaultValue={userData?.timezone}
                  options={timeZones.map((tz) => {
                    return { value: tz, name: tz, _id: tz };
                  })}
                  value={selectedTimeZone}
                  onSelection={(id, value, isValid, sid) => {
                    setSelectedTimeZone(value);
                    console.log(value);
                    inputHandler(id, value, isValid, sid);
                  }}
                  disabled={!edit}
                  toolTip={"This is used to set your schedule correctly"}
                />
              </div>
              <div>
                <p>
                  Current Time in {selectedTimeZone}: {currentTime}
                </p>
              </div>

              <div className="pt-10 w-full ">
                <Button
                  onClick={
                    edit
                      ? () => {
                          saveUserDetailsHandler();
                        }
                      : () => {
                          setEdit(true);
                        }
                  }
                  label={`${edit ? "Save" : "Edit"}`}
                  disabled={!inputState.isValid && edit}
                  isLoading={initState[1].started}
                  customClasses={`${edit && inputState.isValid ? "bg-taskinatorBlue text-taskinatorWhite" : "bg-taskinatorMedGrey text-taskinatorDarkGrey"} h-8 w-40 text-sm`}
                  error={initState[1].error}
                />
              </div>
            </div>
          </div>{" "}
          <div className="bg-taskinatorWhite p-4 mx-1 rounded shadow w-fit">
            <div className="w-96"> {userData?.availabilities ? <WeeklyHours userAvailability={userData.availabilities} /> : <IconSpinner />}</div>
          </div>
        </div>
      ) : (
        <IconSpinner />
      )}
    </ContentView>
  );
};

export default UserSettings;
