import axios from "axios";
import React, { useContext, useEffect, useReducer, useState } from "react";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { AppContext } from "../../../contexts/appContext";
import { TaskContext } from "../../../contexts/taskContext";
import Button from "../../UI_elements/button";
import IconSpinner from "../../UI_elements/iconSpinner";
import TaskActionDisplayForm from "../../forms/taskActionDisplayForm";
import { useInit } from "../../hooks/useInit";
import AddStepDisplayButton from "../addStepDisplayButton";
import ToolTip from "../../../utils/toolTip";
const inputReducer = (state, action) => {
  console.log(action);

  switch (action.type) {
    case "ACTION_CHANGE":
      const { actionData, isActive, tracerId, stageUUID, tracerStatus, stageIndex, isInitial, completed, isNew, ...aidDetails } = action;
      let updatedState;

      if (state[action._id]) {
        updatedState = {
          ...state,
          [action._id]: {
            ...state[action._id],
            actionData: aidDetails,
            tracerId: tracerId,
            stageIndex: stageIndex,
            tracerStageId: stageUUID,
            isActive: action.isActive,
            percentageOfWorkflowTime: action.percentageOfWorkflowTime,
            time: action.time,
            toDo: action.toDo,
            assignee: actionData.assignee
              ? actionData.assignee.map((assignee) => {
                  return {
                    _id: assignee._id,
                    firstName: assignee.firstName,
                    lastName: assignee.lastName,
                    locked: assignee.locked,
                  };
                })
              : [],
            status: tracerStatus,
            isInitial: isInitial,
            completed: completed,

            isNew: isNew,
          },
        };
      } else {
        updatedState = {
          ...state,
          [action._id]: {
            actionData: aidDetails,
            tracerId: tracerId,
            tracerStageId: stageUUID,
            stageIndex: stageIndex,
            isActive: action.isActive,
            toDo: action.toDo,
            percentageOfWorkflowTime: action.percentageOfWorkflowTime,
            assignee: actionData.assignee
              ? actionData.assignee.map((assignee) => {
                  return {
                    _id: assignee._id,
                    firstName: assignee.firstName,
                    lastName: assignee.lastName,
                    locked: assignee.locked,
                  };
                })
              : [],
            status: tracerStatus,
            firstAssigneeState: actionData.assignee
              ? actionData.assignee.map((assignee) => {
                  return {
                    _id: assignee._id,
                    firstName: assignee.firstName,
                    lastName: assignee.lastName,
                    locked: assignee.locked,
                  };
                })
              : [],
            isInitial: isInitial,
            completed: completed,
            time: action.time,
            isNew: isNew,
          },
        };
      }
      return updatedState;

    default:
      return state;
  }
};

const TaskWorkflowSequenceDisplay = ({ incomingWorkflowData, stateId, dispatch, setModal, currentlySetActionData, item, tracer, releasing, taskDetails, dontAutoSelect }) => {
  const appData = useContext(AppContext);
  const taskContext = useContext(TaskContext);
  const { tid } = useParams();
  const { initState, initStarted, initCompleted, initErrors, initReset } = useInit(2);
  const [inputState, dispatchLocal] = useReducer(inputReducer, {});
  const [workflowActionPlan, setWorkflowActionPlan] = useState({
    workflow: incomingWorkflowData,
  });
  const [originalData, setOriginalData] = useState();
  const [dataChanged, setDataChanged] = useState();

  const workflowData = workflowActionPlan.workflow;

  useEffect(() => {
    if (!dataChanged && JSON.stringify(inputState) !== JSON.stringify(originalData) && initState[0].finished) {
      setDataChanged(true);
    }
    setOriginalData(inputState);
  }, [inputState]);

  const isCurrentManager = workflowData && workflowData.managers.filter((manager) => manager._id === appData.workspaceDataV4.user_id).length > 0;
  const atLeastOneIsActive = inputState ? Object.values(inputState).filter((action) => action.isActive === true).length >= 1 : false;
  const activeActionsHaveAssignees = inputState ? Object.values(inputState).filter((action) => action.isActive && action.assignee.length === 0).length === 0 : true;

  // const isDifferent = workflowData && originalData ? JSON.stringify(inputState)
  const isSave = inputState ? Object.values(inputState).filter((action) => action.tracerId).length >= 1 : false;
  const [stageUUIDS, setStageUUIDS] = useState();

  useEffect(() => {
    if (workflowData) {
      setStageUUIDS(Array.from({ length: workflowData.stages.length }, () => uuidv4()));
    }
  }, [workflowActionPlan]);

  useEffect(() => {
    if (!workflowData) {
      initStarted(0);
      axios
        .get(process.env.REACT_APP_BACKEND_URL + `/api/workflow/actionplan/${tracer.tracerWorkflowGroupId}/${tracer.wfid._id}`, {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        })
        .then((responseData) => {
          setWorkflowActionPlan(responseData.data);
          setOriginalData(responseData.data);
          initCompleted(0);
        })
        .catch((err) => {
          console.log(err);
          initErrors(err?.response?.data?.message ?? err?.message ?? "", 0);
        });
    }
  }, []);

  const remainingPercentage = 100;

  const saveClickHandler = () => {
    let url;
    if (isSave) {
      url = `update/actionplan/${tracer.tracerWorkflowGroupId}/${taskDetails.tid}/${tracer._id}/${tracer.tracerStageId}`;
    } else {
      url = `create/actionPlan/${tracer.tracerWorkflowGroupId}/${taskDetails.tid}/${tracer._id}`;
    }
    initStarted(1);

    axios
      .put(
        process.env.REACT_APP_BACKEND_URL + `/api/tracer/` + url,
        {
          actionPlan: Object.values(inputState),
          wfid: tracer.wfid,
          itemsOnTracer: tracer.itemsOnTracer,
          taskDetails: taskDetails,
          note: tracer.note,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      .then((responseData) => {
        releasing();
        initCompleted(1);
      })
      .catch((err) => {
        console.log(err);
        initErrors(err?.response?.data?.message ?? err?.message ?? "", 1);
      });
  };

  const dispatchDistributor = (data) => {
    if (tracer) {
      dispatchLocal(data);
    } else {
      dispatch(data);
    }
  };

  const vertical = false;
  return (
    <>
      {/* <Modal local={() => setModal()}> */}
      {!workflowData && !stageUUIDS && initState[0].started && (
        <div className="w-96 h-96 items-center justify-center flex ">
          <div className="pr-1">
            <IconSpinner />
          </div>{" "}
          Loading Action Plan...
        </div>
      )}
      {workflowData && stageUUIDS && (
        <div className={` flex ${vertical && isCurrentManager ? "flex-col " : "flex-col xl:grow xl:flex-row"}  mt-1 h-fit  `}>
          {/* <div className="bg-taskinatorWhite py-2 px-4  text-taskinatorDarkGrey mb-1 text-xs font-semibold uppercase  opacity-75 w-full">Workflow</div> */}
          <div className={`flex  ${vertical && isCurrentManager ? "flex-col" : "flex-col xl:grow xl:flex-row"}  px-4 py-2 w-full    `}>
            {workflowData.stages.map((stage, stageIndex) => {
              const numberOfTasks = stage.length;

              return (
                <div key={stageIndex} className={`items-center flex  ${vertical && isCurrentManager ? "flex-col w-full" : "flex-col xl:flex-row"}  `}>
                  {/* <div className="w-full uppercase font-medium text-taskinatorDarkGrey text-sm flex justify-center items-center">
                    Step {stageIndex + 1} <div className="normal-case"><ToolTip content={"Tasks within a stage will be activated at the same time within a sequence"} /></div>
                  </div> */}
                  <div className={`flex  ${vertical && isCurrentManager ? "flex-col" : "xl:flex-col flex-row"} items-center    `}>
                    {stage.map((actionData, actionIndex) => {
                      const isActionPlan = !incomingWorkflowData && workflowActionPlan.actionPlan && workflowActionPlan.actionPlan[actionData._id] && workflowActionPlan.actionPlan[actionData._id];

                      const tracerStageId = stage
                        .map((action) => {
                          if (!incomingWorkflowData && workflowActionPlan.actionPlan[action._id]) {
                            return workflowActionPlan.actionPlan[action._id].tracerStageId;
                          }
                        })
                        .filter((data) => data);

                      const tracerStageIdToUse = tracerStageId.length > 0 ? tracerStageId[0] : stageUUIDS[stageIndex];

                      return (
                        <div key={actionData._id} className="flex items-center p-2 ">
                          {!isCurrentManager && (
                            <div className="uppercase border rounded-lg shadow border-taskinatorMedGrey p-2 text-xs text-taskinatorDarkGrey font-semibold text-center   w-40  ">{actionData.name}</div>
                          )}
                          {isCurrentManager && (
                            <TaskActionDisplayForm
                              stageIndex={stageIndex}
                              stageUUID={tracerStageIdToUse}
                              tracerStatus={isActionPlan ? isActionPlan.status : null}
                              tracerId={currentlySetActionData ? null : isActionPlan ? isActionPlan.tracerId : null}
                              workflowData={workflowData}
                              stateId={stateId}
                              currentlySetActionData={currentlySetActionData ? currentlySetActionData : workflowActionPlan.actionPlan}
                              actionData={actionData}
                              completed={isActionPlan ? isActionPlan.completed : null}
                              dispatch={dispatchDistributor}
                              thirdPartyAccessCode={actionData.thirdPartyAccessCode}
                              dontAutoSelect={dontAutoSelect}
                              remainingPercentage={remainingPercentage}
                            />
                          )}
                        </div>
                      );
                    })}
                  </div>
                  <div className={`${workflowData.stages.length - 1 > stageIndex ? "xl:block hidden" : "hidden"} opacity-75 `}>{<AddStepDisplayButton notClickable />}</div>
                  <div className={`${workflowData.stages.length - 1 > stageIndex ?  "block xl:hidden" : "hidden"} opacity-75   justify-center flex`}>
                    {<AddStepDisplayButton rotate notClickable colour={"text-taskinatorBlue  border-taskinatorBlue"} />}
                  </div>
                </div>
              );
            })}
          </div>
          {tracer && isCurrentManager && (
            <div className="flex justify-center">
              <Button
                label={`${isSave ? "Save & Update" : "Save & Release"}`}
                customClasses={`${atLeastOneIsActive && activeActionsHaveAssignees ? "bg-taskinatorBlue" : "bg-taskinatorMedGrey"} bg-taskinatorBlue text-taskinatorWhite h-10 w-full mt-4 `}
                disabled={!atLeastOneIsActive || !activeActionsHaveAssignees}
                isLoading={initState[1].started}
                error={initState[1].error}
                onClick={() => saveClickHandler()}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default TaskWorkflowSequenceDisplay;
