import { Elements } from "@stripe/react-stripe-js";
import React, { useState } from "react";
// import { loadStripe } from "@stripe/stripe-js";
import Billing from "./billing";

// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

const BillingInit = () => {
  return (
    // <Elements
    //   stripe={stripePromise}
    //   options={{
    //     mode: "setup",
    //     // currency: "aud",
    //     appearance: {
    //       theme: "stripe",
    //     },
    //   }}
    // >
      <Billing  />
    // {/* </Elements> */}
  );
};

export default BillingInit;
