import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../contexts/appContext";
import { useInputV4 } from "../../stateManagement/useInputV4";
import Button from "../UI_elements/button";
import IconSpinner from "../UI_elements/iconSpinner";
import ItemStateSelector from "../dataSelectors/itemStateSelector";
import SharedOrganisationSelectors from "../dataSelectors/sharedOrganisationSelectors";
import TagSelector from "../dataSelectors/tagSelector";
import StampingFunction from "../functions/stampingFunction";
import { useInit } from "../hooks/useInit";
import PDFAttachV4 from "../inputs/filePDFAttach_V4";
import InputV4 from "../inputs/input_V4";
import LiveDataCheckOnInput from "../inputs/liveDataCheckOnInput";
import {
  VALIDATOR_MINLENGTH,
  VALIDATOR_REQUIRE,
} from "./validators/validators";

const NewItemForm = ({ itemData, onResponse, type }) => {
  const appData = useContext(AppContext);
  const { inputState, inputHandler, attachmentHandler } = useInputV4(
    { inputs: {} },
    false
  );
  const { initState, initStarted, initCompleted, initErrors, initReset } =
    useInit(2);
  const [nextNumberAndSettings, setNextNumberAndSettings] = useState();
  const [percentage, setPercentage] = useState(0);
  const [manualNumber, setManualNumber] = useState();

  useEffect(() => {
    const sendRequest = () => {
      if (!manualNumber) {
        initStarted(1);
        axios
          .get(
            process.env.REACT_APP_BACKEND_URL + `/api/items/numberandsettings`,
            {
              headers: {
                "Content-Type": "application/json",
              },
              withCredentials: true,
            }
          )
          .then((response) => {
            setNextNumberAndSettings(response.data);
            inputHandler(
              "itemNumber",
              response.data.nextNumber,
              true,
              "inputs"
            );
            initCompleted(1);
          })
          .catch((err) => {
            return initErrors(
              err?.response?.data?.message ?? err?.message ?? "",
              1
            );
          });
      }
    };

    sendRequest();
  }, [manualNumber]);

  const documentsAttached =
    inputState.attachments &&
    Object.values(inputState.attachments).length > 0 &&
    !inputState.attachments["required"];

  const createItemHandler = async () => {
    initStarted(0);
    let QRcodeUUID;
    let attachmentKey;
    let attachmentFileName;

    if (documentsAttached) {
      const stampDetails = await StampingFunction(
        inputState.inputs.itemNumber.value,
        inputState.inputs.revision.value,
        inputState.attachments,
        inputState.inputs.itemState.value,
        setPercentage,
        appData.workspaceDataV4.orgName,
        appData.workspaceDataV4.addStamp
      );

      if (stampDetails.error) {
        console.log(stampDetails.error);
        initErrors(stampDetails.error, 0);
        return;
      }

      attachmentKey = stampDetails.attachmentKey;
      QRcodeUUID = stampDetails.QRcodeUUID;
      attachmentFileName = stampDetails.attachmentFileName;
    }

    const submitResponse = await submitHandler(
      QRcodeUUID,
      attachmentKey,
      attachmentFileName
    );

    if (submitResponse.success) {
      onResponse();
      initCompleted(0);
    } else {
      console.log(submitResponse.err);
      const errorMessage = submitResponse.err.response.data
        ? submitResponse.err.response.data.message
        : submitResponse.err.message;
      console.log(errorMessage);
      // appData.ERROR(errorMessage);
      initErrors(errorMessage, 0);
    }
  };

  const submitHandler = async (
    QRcodeUUID,
    attachmentKey,
    attachmentFileName
  ) => {
    let submitData = {
      itemNumber: inputState.inputs.itemNumber.value,
      itemName: inputState.inputs.itemName.value,
      revision: inputState.inputs.revision.value,
      // status: inputState.inputs.status.value,
      state: inputState.inputs.itemState.value,
      accessTags: inputState.inputs.accessTags.value,
      itemTags: inputState.inputs.itemTags.value,
      wd: inputState.inputs.wd.value,
      externalAccess: inputState.inputs.externalAccess
        ? inputState.inputs.externalAccess.value
        : [],
    };

    if (attachmentKey) {
      submitData = {
        ...submitData,
        attachment: { key: attachmentKey, fileName: attachmentFileName },
        QRuuid: QRcodeUUID,
      };
    }

    try {
      const responseData = await axios.put(
        process.env.REACT_APP_BACKEND_URL + `/api/items/create/new`,
        submitData,
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      return { success: true };
    } catch (err) {
      return { success: false, err: err };
    }
  };

  /// this automatically sets draft state items to draft status
  const itemStatus =
    inputState.inputs.itemState && inputState.inputs.itemState.value;

  useEffect(() => {
    if (itemStatus === "draft") inputHandler("status", "draft", true, "inputs");
  }, [itemStatus]);
  ///// stamping handling

  return (
    // <>  <input type="file" multiple onChange={handleFileUpload} />
    <div className="w-full h-full flex flex-col overflow-auto">
      {/* <div className=" font-semibold w-full text-center text-lg text-taskinatorDarkGrey pb-4">{itemData ? (!newRev ? "View/Edit Item" : "Create New Revision") : "Create New Item"}</div> */}
      <div className="flex flex-col md:flex-row w-full h-full grow overflow-auto scrollbar relative">
        <div
          className={`w-full md:w-96 flex flex-col bg-taskinatorWhite p-4 rounded-0 md:rounded-l-lg h-full`}
        >
          {!manualNumber && (
            <div>
              <div className="text-sm mt-2 flex">
                <div className="text-taswkinatorDarkGrey">{`${appData.itemName} Number`}</div>
                <div className="text-taskinatorRed pl-1">(Required)</div>
              </div>
              <div className="text-base rounded-md block w-full appearance-none border border-taskinatorMedGrey px-3 py-2 text-gray-900 placeholder-gray-500 ">
                {initState[1].started && (
                  <div className="flex w-fll items-center">
                    <IconSpinner />{" "}
                    <div className="text-sm text-taskinatorBlue">
                      {" "}
                      Getting next item number...
                    </div>
                  </div>
                )}
                {initState[1].finished && (
                  <div className="flex w-full items-center text-xs">
                    <div className="grow">
                      {nextNumberAndSettings.nextNumber}
                    </div>
                    <div
                      onClick={() => {
                        setManualNumber(true);
                      }}
                      className="text-xs text-taskinatorBlue underlined hover:opacity-75 cursor-pointer"
                    >
                      Enter Manually
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          {manualNumber && (
            <div>
              <LiveDataCheckOnInput
                validators={[VALIDATOR_MINLENGTH(2)]}
                onValid={inputHandler}
                id="itemNumber"
                sid="inputs"
                placeholder={`${appData.itemName} Number`}
                label={`${appData.itemName} Number`}
                route="items"
                minLength={2}
              />
              <div
                onClick={() => {
                  setManualNumber();
                }}
                className="text-xs text-taskinatorBlue underlined hover:opacity-75 cursor-pointer"
              >
                Or Get Next
              </div>
            </div>
          )}
          {/* {manualNumber && (
            <InputV4
              sid="inputs"
              id="number"
              label="Number"
              placeholder={"Loading Number"}
              customClasses={"rounded-md"}
              defaultValue={itemData ? itemData.itemNumber : nextNumberAndSettings.nextNumber}
              onInput={inputHandler}
              validators={[VALIDATOR_REQUIRE()]}
              validityMessage={"Required"}
            />
          )} */}

          {
            <LiveDataCheckOnInput
              validators={[VALIDATOR_MINLENGTH(3)]}
              onValid={inputHandler}
              id="itemName"
              sid="inputs"
              placeholder={"Name"}
              label={`${appData.itemName} Name`}
              route="items"
              minLength={3}
            />
          }

          <div className="pt-1">
            <InputV4
              sid="inputs"
              id="revision"
              label={"Revision Number"}
              placeholder={"Revision Number"}
              customClasses={"rounded-md"}
              // defaultValue={!newRev && revData ? revData.revision : ""}
              onInput={inputHandler}
              validators={[VALIDATOR_REQUIRE()]}
              validityMessage={"Required"}
            />
          </div>
          <ItemStateSelector
            sid="inputs"
            label={`${appData.itemName} Status`}
            inputHandler={inputHandler}
            defaultValue={"1"}
          />

          {/* {inputState.inputs.itemState && inputState.inputs.itemState.value === "released" && (
            <ItemStatusSelector label={"Item Status"} sid={"inputs"} inputHandler={inputHandler} defaultValue={"1"} itemState={inputState.inputs.itemState && inputState.inputs.itemState.value} />
          )} */}

          {inputState.inputs.itemState && inputState.inputs.itemState.value && (
            <div className="my-2">
              <PDFAttachV4
                required={
                  inputState.inputs.itemState &&
                  inputState.inputs.itemState.value === "released"
                    ? true
                    : false
                }
                label={`${
                  inputState.inputs.itemState &&
                  inputState.inputs.itemState.value
                } Documents - PDF only`}
                attachmentHandler={attachmentHandler}
              />
            </div>
          )}
          {inputState.inputs.itemState && inputState.inputs.itemState.value && (
            <div className="text-xs text-center text-taskinatorRed pb-4">
              <strong>Note:</strong> Attachments will be merged to a single pdf,
              renamed and stamped for tracking
            </div>
          )}
        </div>

        <div className=" w-full md:w-96   md:ml-1 md:mt-0 mt-1 flex flex-col bg-taskinatorWhite p-4 h-full ">
          {!itemData && (
            <div className="">
              <TagSelector
                notRequired
                id={"itemTags"}
                inputHandler={inputHandler}
                defaultValue={[]}
                sid="inputs"
                type="item"
                label="Filter Tags"
                selectionDisplayText={"Select/Create Filter Tag"}
              />
            </div>
          )}

          <div className="pt-2">
            <TagSelector
              notRequired
              id={"accessTags"}
              inputHandler={inputHandler}
              defaultValue={[]}
              sid="inputs"
              type="access"
              label="Internal Access Tags"
              selectionDisplayText={"Select/Create Access Tag"}
            />
          </div>

          <SharedOrganisationSelectors
            notRequired
            id={"externalAccess"}
            inputHandler={inputHandler}
            defaultValue={[]}
            sid="inputs"
            label="External Access"
            selectionDisplayText={"Grant Access To"}
          />

          <div className="pt-2">
            <InputV4
              sid="inputs"
              id="wd"
              label={"Working Directory Link"}
              placeholder={"add working directory link"}
              customClasses={"rounded-md"}
              // defaultValue={!newRev && revData ? revData.revision : ""}
              onInput={inputHandler}
              validators={[]}
              textareaRows={1}
            />
          </div>
        </div>
      </div>
      <Button
        onClick={createItemHandler}
        label={`Create ${appData.itemName}`}
        customClasses={`${
          inputState.isValid ? "bg-taskinatorBlue" : "bg-taskinatorMedGrey"
        } bg-taskinatorBlue text-taskinatorWhite h-10 w-full mt-4 `}
        disabled={!inputState.isValid}
        isLoading={initState[0].started}
        error={initState[0].error}
        loadingPercentage={documentsAttached ? percentage : null}
      />
    </div>
  );
};

export default NewItemForm;
