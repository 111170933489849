import React from "react";
import DateDisplay from "../../UI_elements/dateDisplay";
import ItemStatus from "../../UI_elements/itemStatus";
import FileDisplay from "./fileDisplay";
import FilePreview from "../../../utils/filePreview";
import Tag from "../../UI_elements/tag";
import StringShortenDisplay from "../../UI_elements/stringShortenDisplay";

const FileDetails = ({ file, fileDetailsView, notClickable, wide, noDate, localOnly, noTags, showAuto, startingWorkflow, showAccess, isRef }) => {


  return file._id !== "placeHolder" ? (
    <div className="flex  items-center" key={file._id}>
      <div className="px-2  sm:flex hidden text-taskinatorMedGrey">|</div>
      <div className="pr-2 w-20"> {<ItemStatus status={file.archived === true ? "Archived" : file.status} />}</div>
      {!noDate && <div className="px-2  sm:flex hidden text-taskinatorMedGrey">|</div>}
      {!noDate && <div className=" w-20 text-center justify-center sm:flex hidden">{DateDisplay(file.createdDate)}</div>}
      <div className="px-2  sm:flex hidden text-taskinatorMedGrey">|</div>
      <div className="flex w-12 justify-center">
        <div className="font-medium pr-1">Rev: </div>
        <div className=" overflow-hidden">{file.revision ? file.revision : "N/A"}</div>
      </div>
      <div className="px-2 text-taskinatorMedGrey">|</div>
      {/* <div className="font-medium px-2  sm:flex hidden">File: </div> */}
      <div
        className="w-fit"
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <FilePreview file={file} markup viewMarkupsOnly={file.type !== "document" || isRef} notClickable={notClickable} wide={wide} isPreview={localOnly} />
      </div>
      {!noTags && <div className="px-2 text-taskinatorMedGrey ">|</div>}
      <div className="w-40 text-no-wrap">{StringShortenDisplay(20, file?.description)}</div>
      {!noTags && <div className="px-2 text-taskinatorMedGrey ">|</div>}
      {/* <div className="font-medium px-2  sm:flex hidden">Access Tag: </div> */}
      {!noTags && (
        <div className="flex w-80">
          {file?.settings?.fileTags.map((tag) => {
            return <Tag tagData={tag} />;
          })}
        </div>
      )}
      {showAuto && <div className="px-2 text-taskinatorMedGrey">|</div>}
      {showAccess && <div className="px-2 text-taskinatorMedGrey">|</div>}
      {startingWorkflow && <Tag tagData={{ value: startingWorkflow, name: startingWorkflow, type: "workflows" }} />}
      {showAccess && !startingWorkflow && (
        <div className="flex w-40">
          {file?.settings?.accessTags?.length > 0 ? (
            file.settings?.accessTags.map((tag) => {
              return <Tag tagData={{ ...tag }} />;
            })
          ) : (
            <div className="text-taskinatorMedGrey font-medium">None Access Tags</div>
          )}
        </div>
      )}
      {showAuto && !startingWorkflow && (
        <div className="flex w-40 border">
          {file?.autoSequence?.length > 0 ? (
            file.autoSequence.map((tag) => {
              return <Tag tagData={{ ...tag, name: startingWorkflow ?? tag.name, type: "workflows" }} />;
            })
          ) : (
            <div className="text-taskinatorRed font-medium">No Auto Sequence Selected</div>
          )}
        </div>
      )}
    </div>
  ) : (
    <div>No File</div>
  );
};

export default FileDetails;
