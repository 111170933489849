import React, { useContext, useState } from "react";
import { AppContext } from "../../contexts/appContext";
import ContentView from "../../pageTemplates/contentView";

import FilesGetAndSearchContainer from "../../components/displayElements/files/fileGetAndSearchContainer";

const Files = () => {
  const [buttons, setButtons] = useState([]);
  const appData = useContext(AppContext);
  const setEditButton = (editButton) => {
    setButtons([editButton]);
  };

  return (
    <ContentView buttons={buttons} label={"Files"} hideTopMenu>
      <FilesGetAndSearchContainer viewOnly setEditButton={(data) => setEditButton(data)} isRegister files dontRetainSelection={true}  isthisWorking/>
    </ContentView>
  );
};

export default Files;
