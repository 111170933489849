import React, { useState } from "react";
import ContentView from "../../pageTemplates/contentView";
import BulkFileUpload from "../../components/displayElements/files/bulkFileUpload";

const DraftFiles = () => {
  const [buttons, setButtons] = useState([]);

  const setEditButton = (editButton) => {
    setButtons([editButton]);
  };

  return (
    <ContentView buttons={buttons} label={"Draft Files"} notButton={<BulkFileUpload />}>
      {/* <FilesGetAndSearchContainer viewOnly setEditButton={(data) => setEditButton(data)} isRegister  files/> */}
    </ContentView>
  );
};

export default DraftFiles;
