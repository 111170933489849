import React, { useEffect, useState } from "react";
import { MdCheckCircle, MdRadioButtonUnchecked, MdClose } from "react-icons/md";
import { useInputV4 } from "../../stateManagement/useInputV4";
import Button from "../UI_elements/button";
import UserDisplayBubble from "../UI_elements/userDisplayBubble";
import MemberSelector from "../dataSelectors/memberSelector";
import NewInstruction from "../../components/forms/newInstruction";

const TaskActionDisplayForm = ({
  actionData,
  stateId,
  isSelected,
  assignees,
  currentlySetActionData,
  dispatch = () => {},
  stageIndex,
  stageUUID,
  tracerId,
  tracerStatus,
  completed,
  thirdPartyAccessCode,
  dontAutoSelect,
  noneTaskEdit,
}) => {
  let defaultValues = {
    scope: "",
    assignee: assignees ?? [],
    isActive: dontAutoSelect ? isSelected : isSelected ? isSelected : actionData.autoSelect,
    toDo: actionData.defaultToDos ?? [],
  };

  if (currentlySetActionData !== null && currentlySetActionData !== undefined && currentlySetActionData[actionData._id]) {
    defaultValues = currentlySetActionData[actionData._id];
  }

  const { inputState, inputHandler } = useInputV4({
    inputs: {
      scope: { value: defaultValues?.scope, isValid: true },
      assignee: { value: defaultValues?.assignee, isValid: true },
      toDo: { value: defaultValues?.toDo, isValid: true },
    },
    isValid: true,
  });

  const sid = "inputs";
  const [isActive, setIsActive] = useState(defaultValues?.isActive);
  const [isNew, setIsNew] = useState(!defaultValues?.isActive);
  const [wide, setWide] = useState();
  const [collapse, setCollapes] = useState(!isActive);

  useEffect(() => {
    setCollapes(!isActive);
  }, [isActive]);

  useEffect(() => {
    dispatch({
      type: "ACTION_CHANGE",
      stateId: stateId,
      _id: actionData?._id,
      actionData: {
        scope: inputState?.inputs?.scope?.value,
        assignee: inputState?.inputs?.assignee?.value,
      },
      isActive: isActive,
      tracerId: tracerId,
      tracerStatus: tracerStatus,
      stageIndex: stageIndex,
      stageUUID: stageUUID,
      canApproveItems: actionData?.canApproveItems,
      canMarkupItems: actionData?.canMarkupItems,
      viewReleased: actionData?.viewReleased,
      viewDraft: actionData?.viewDraft,
      addMoreItems: actionData?.addMoreItems,
      canCreateDraftItems: actionData?.canCreateDraftItems,
      name: actionData?.name,
      completed: completed,
      isNew: isNew,
      thirdParty: actionData?.thirdParty,
      fileTags: actionData?.fileTags,
      completeButton: actionData?.completeButton,
      rejectButton: actionData?.rejectButton,
      milestoneTask: actionData?.milestoneTask,
      toDo: inputState?.inputs?.toDo?.value ?? [],
      time: inputState?.inputs?.eta?.value,
      taskUUID: actionData?.taskUUID,
      team: (actionData?.team ?? []).map((team) => {
        return {
          _id: team._id,
          firstName: team.firstName,
          lastName: team.lastName,
          locked: team.locked,
        };
      }),
    });
  }, [inputState, isActive]);

  const activationCSS = !isActive
    ? {
        container: "border-taskinatorMedGrey",
        standardText: "text-taskinatorMedGrey",
        highlightedText: "text-taskinatorDarkGrey",
      }
    : {
        container: "border-taskinatorMedGrey ",
        standardText: "text-taskinatorDarkGrey",
        highlightedText: "text-taskinatorGDarkGrey",
      };

  return (
    <>
      <div
        onClick={tracerStatus && tracerStatus === "live" ? () => {} : !wide ? () => setIsActive(!isActive) : () => {}}
        key={actionData?._id}
        className={`${activationCSS.container} ${tracerStatus && tracerStatus === "live" ? "" : "cursor-pointer"} border-2 rounded-lg ${
          wide ? "grow absolute md:max-w-4xl md:max-h-[95%] md:h-fit w-full h-full rounded-xl z-50 inset-0 m-auto flex items-center justify-center" : "min-w-72 w-fit"
        } h-fit flex items-center justify-center m-2  bg-taskinatorOffWhite ${isActive ? "" : "opacity-25"}`}
      >
        <div className="flex flex-col items-center justify-center w-full p-4">
          <div className="flex grow justify-center  w-full items-center">
            <div className={`font-semibold ${activationCSS.highlightedText} w-fit text-base   p-1 rounded border-taskinatorMedGrey uppercase  h-8 item-center`}>{actionData?.name}</div>
            {isActive ? (
              <div className="text-taskinatorGreen ">
                {tracerStatus && tracerStatus === "live" ? (
                  <div className="border py-0.5 px-2 rounded border-taskinatorGreen bg-taskinatorGreen text-taskinatorWhite text-xxs ml-2">Active</div>
                ) : (
                  <div className="hidden"></div>
                )}{" "}
              </div>
            ) : (
              <div></div>
            )}
            {wide && (
              <div
                className="flex grow justify-end"
                onClick={() => {
                  setWide(!wide);
                }}
              >
                <div className="text-taskinatorDarkGrey hover:text-taskinatorRed">
                  <MdClose size={"2rem"} />
                </div>
              </div>
            )}
          </div>
          {!collapse && (
            <div
              onClick={(event) => {
                event.stopPropagation();
              }}
              className={`w-full pb-1 mt-2  `}
            >
              {(noneTaskEdit || (!noneTaskEdit && inputState?.inputs?.toDo?.value?.length === 0)) && (
                <MemberSelector
                  label={inputState?.inputs?.assignee?.value ? "Quick Assign" : `Available to:`}
                  selectionDisplayText={actionData?.thirdParty ? "Select 3rd Party" : `Select Team Members`}
                  inputHandler={inputHandler}
                  disabled={!isActive}
                  customClasses={activationCSS}
                  sid={sid}
                  roleTypes={[]}
                  id="assignee"
                  defaultValue={(defaultValues?.assignee ?? []).map((assignee) => {
                    return { value: assignee._id };
                  })}
                  teamIds={
                    actionData?.thirdParty
                      ? null
                      : actionData?.team.map((team) => {
                          return team._id;
                        })
                  }
                  thirdParty={actionData?.thirdParty}
                  multi
                />
              )}

              {!wide && inputState?.inputs?.toDo?.value?.length > 0 && !noneTaskEdit ? (
                <div
                  className="py-2  text-start bg-taskinatorWhite p-2 rounded  border border-taskiantorMedGrey mt-4"
                  onClick={() => {
                    setWide(!wide);
                  }}
                >
                  <div className="text-sm text-taskinatorDarkGrey ">Tasks:</div>
                  <div className=" pt-1">
                    {inputState?.inputs?.toDo?.value?.map((todo, index) => {
                      return (
                        <div className=" text-start text-xs border  border-taskinatorMedGrey text-taskinatorDarkGrey  rounded  px-1 font-medium flex items-center py-1 mb-1 text-nowrap">
                          {index + 1}. <div className="text-taskinatorGreen px-1 grow text-nowrap">{todo.title}</div> | Assignee:
                          <div className="opacity-75 flex flex-nowrap">
                            {todo.assignee.map((user) => {
                              return <UserDisplayBubble user={user} />;
                            })}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : (
                <div></div>
              )}
              {wide && !noneTaskEdit && (
                <div className="items-center grow  justify-start w-full flex flex-col">
                  <NewInstruction globalHandler={inputHandler} defaultValues={inputState?.inputs?.toDo?.value} availableTeam={actionData?.team ?? []} horizontal inJob isPreset/>
                </div>
              )}

              {!noneTaskEdit && (
                <div className="flex items-center justify-center mt-2">
                  <Button
                    label={wide ? "Done" : "Add/Edit Tasks"}
                    customClasses={"border-taskinatorGreen border text-sm text-taskinatorGreen h-8"}
                    onClick={() => {
                      setWide(!wide);
                    }}
                  />
                </div>
              )}
            </div>
          )}

          {!wide && (
            <div>
              {isActive ? (
                <div className="text-taskinatorGreen w-full justify-center flex mt-4">{tracerStatus && tracerStatus === "live" ? <div className="hidden"></div> : <MdCheckCircle size={30} />}</div>
              ) : (
                <div className="w-full justify-center flex mt-4">
                  {" "}
                  <MdRadioButtonUnchecked size={30} color={"#c3c3c3"} />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {wide && (
        <div
          className={`fixed top-0 left-0 h-full w-full z-40 bg-taskinatorDarkGrey opacity-50  `}
          onClick={() => {
            setWide();
          }}
        ></div>
      )}
    </>
  );
};

export default TaskActionDisplayForm;
