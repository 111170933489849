import axios from "axios";
import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { MdCheck, MdCheckCircle } from "react-icons/md";
import { useParams } from "react-router-dom";
import { AppContext } from "../../../contexts/appContext";
import TaskFormatter from "../../../utils/taskFormatter";
import Button from "../../UI_elements/button";
import IconSpinner from "../../UI_elements/iconSpinner";
import Modal from "../../UI_elements/modal";
import StringShortenDisplay from "../../UI_elements/stringShortenDisplay";
import UserDisplayBubble from "../../UI_elements/userDisplayBubble";
import ApproveRejectForm from "../../forms/approveRejectForm";
import JobDetailsForm from "../../forms/jobDetailsForm";
import SubmitFormV4 from "../../forms/submitFormV4";
import { useInit } from "../../hooks/useInit";
import ActionJobDetailsDisplay from "../actions task lists/actionJobDetailsDisplay";
import ItemActionableElementV2 from "../items/itemActionableElementV2";
import JobOnHold from "../task/JobOnHold";
import JobRequests from "../task/jobRequests";
import TaskWorkflowSequenceDisplay from "../task/taskWorkflowSequenceDisplay";
import ClockInClockOut from "../timeSheet/clockInClockOut";
import ActionCommentsDisplay from "./actionCommentsDisplay";
import ActionCommentsNoteAdder from "./actionCommentsNoteAdder";
import ActionNextStepsDisplayV2 from "./actionNextStepsDisplayV2";
import ActionTimesheetDetailsDisplay from "./actionTimesheetDetailsDisplay";
import AllWorkflowDisplay from "./allworkflowDisplay";
import TaskDetailsForm from "../../forms/taskDetailsForm";
import TaskOnHold from "../task/taskOnHold";

const ActionDetailDisplayV2 = ({ onSubmitSuccess, tracerId, tid }) => {
  const appData = useContext(AppContext);

  const { initState, initStarted, initCompleted, initErrors } = useInit(5);
  const { sid } = useParams();

  const [submitWork, setSubmitWork] = useState();
  const [workflowUpdateTrigger, setWorkflowUpdateTrigger] = useState();
  const [nextStep, setNextStep] = useState({});
  const [backStep, setBackStep] = useState({});
  const [actionPlan, setActionPlan] = useState();
  const [logs, setLogs] = useState({});
  const [isOnlyWorkflow, setIsOnlyWorkflow] = useState();
  const [onDelete, setDelete] = useState();
  const [editTaskDetails, setEditTaskDetail] = useState();
  const [timeSheetData, setTimeSheetData] = useState([]);
  const [buttons, setButtons] = useState([]);
  const [simpleView, setSimpleView] = useState();
  const [refresh, setRefresh] = useState();
  const [viewOtherWorkflows, setViewOtherWorkflows] = useState();
  const [workflowSet, setWorkflow] = useState();
  const [editWorkflow, setEditWorkflow] = useState();
  const [data, setTracer] = useState();

  const tracer = data && Object.values(data.elementData)[0];
  const workflowCompleted = tracer && tracer.tracerType === "workflow";
  const actionsCompleted = tracer && tracer.status !== "live";
  const disableEdits = workflowCompleted || actionsCompleted;
  const taskDetails = data;
  const assignees = tracer && tracer.assignee.length > 0;
  const manager =
    (tracer && tracer.wfid.managers.filter((manager) => manager._id === appData.workspaceDataV4.user_id).length > 0) ||
    [process.env.REACT_APP_ACCESS_A, process.env.REACT_APP_ACCESS_O].includes(appData.workspaceDataV4.role) ||
    tracer?.taskManaged;

  const getData = (previousTracerId) => {};

  const formateTracerHandler = (callback) => {
    if (callback?.tracer) {
      if (callback?.timeSheet?.length ?? 0) {
        const groupedData = _.groupBy(callback.timeSheet, "uid");
        const timeSheetArrData = [];
        Object.keys(groupedData).forEach((item) => {
          const totalTime = groupedData[item].reduce((total, currentValue) => total + (currentValue?.totalTime ?? 0), 0);

          timeSheetArrData.push({
            totalTime,
            firstName: groupedData[item][0].firstName,
            lastName: groupedData[item][0].lastName,
            clockedIn: groupedData[item].filter((subItem) => !(subItem?.totalTime ?? false) && subItem?.totalTime !== 0).length > 0,
          });
        });

        setTimeSheetData(timeSheetArrData);
      }
      const tracer = Object.values(TaskFormatter(callback.tracer, false, false, false, false, appData))[0];
      setActionPlan(callback.actionPlan);
      setLogs(callback.logs);
      setTracer(tracer);
    } else {
      appData.setViewAction();
    }
  };

  const StartHandler = () => {
    initStarted(3);
    axios
      .put(
        process.env.REACT_APP_BACKEND_URL + `/api/tracer/start/multiple`,
        {
          tracerIds: [tracerId],
          tid: taskDetails.tid,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        appData.viewAction.getData();
        getData();

        initCompleted(3);
      })
      .catch((err) => {
        console.log(err);
        initErrors(err?.response?.data?.message ?? err?.message ?? "", 3);
      });
  };

  const deleteHandler = () => {
    initStarted(0);
    axios
      .delete(
        process.env.REACT_APP_BACKEND_URL + `/api/tracer/delete/${tracer.tracerWorkflowGroupId}/${tracer.itemsOnTracer?._id ? tracer.itemsOnTracer._id : "none"}/${taskDetails.tid}`,

        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        // appData.viewAction.getData();
        appData.setActionViewHandler();
        setDelete();
        // taskContext.getActionData();
        initCompleted(0);
      })
      .catch((err) => {
        console.log(err);
        return initErrors(err.messag ? err.message : err.response.data.message, 0);
      });
  };

  useEffect(() => {
    appData.setIsModalOpened(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (tracer) {
      axios
        .get(
          process.env.REACT_APP_BACKEND_URL + `/api/tracer/isonly/${tracer.tracerWorkflowGroupId}/${taskDetails.tid}`,

          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        )
        .then((response) => {
          setIsOnlyWorkflow(response.data.isOnly);
          initCompleted(1);
        })
        .catch((err) => {
          console.log(err);

          return initErrors(err?.response?.data?.message ?? err?.message ?? "", 1);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tracer]);

  useEffect(() => {
    setWorkflowUpdateTrigger(!workflowUpdateTrigger);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appData?.viewAction?.getData]);

  /// mark as seen
  useEffect(() => {
    if (tracer) {
      const shouldSendRequest = appData.workspaceDataV4 && tracer.seen.filter((seen) => seen._id === appData.workspaceDataV4.user_id).length === 0;

      if (shouldSendRequest) {
        const delay = 1000;

        const timer = setTimeout(() => {
          axios
            .get(process.env.REACT_APP_BACKEND_URL + `/api/tracer/seen/${tracerId}`, {
              headers: {
                "Content-Type": "application/json",
              },
              withCredentials: true,
            })
            .then((response) => {
              // if (response.data.updatedTracer) {
              getData();
              //   // taskContext.getActionData();
              //   // appData.ADD_TASK(response.data.updatedTracer); /// old can be removed
              // }
            })
            .catch((err) => {
              console.log(err);
            });
        }, delay);

        return () => {
          clearTimeout(timer);
        };
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tracerId, tracer]);

  useEffect(
    () => {
      initStarted(2);

      appData.socket.emit(
        "connectToAction",
        {
          action: "connect",
          tracerId: tracerId,
          tid: tid,
        },
        (callback) => {
          if (callback.success) {
            formateTracerHandler(callback);
            initCompleted(2);
          } else {
            initErrors(callback.error, 2);
          }
        }
      );

      appData.socket.on(`${tracerId}`, (data) => {
        if (data.action === "update") {
          formateTracerHandler(data);
        }

        if (data.action === "remove") {
          appData.setActionViewHandler();
        }
      });

      return () => {
        appData.socket.off(`${tracerId}`);
        appData.socket.off(`logUpdate`);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [appData.tagFilter, tracerId]
  );

  return (
    <>
      {initState[2].started && !initState[2].initialised && (
        <div className="w-full h-full flex justify-center items-center">
          <IconSpinner size="m" /> Loading Data...
        </div>
      )}
      {initState[2].error && <div className="flex items-center justify-center text-base text-taskinatorRed h-full">ERROR - {initState[2].error}</div>}
      {initState[2].initialised && tracer && (
        <div className={`p-2 xl:h-full h-fit overflow-auto flex xl:flex-row flex-col text-sm`}>
          <div className={`flex flex-col xl:h-full h-fit xl:grow xl:overflow-auto md:mr-0.5 mr-0 pr-1 scrollbar`}>
            <div
              className={`text-sm pr-4 pl-2 text-taskinatorWhite flex bg-taskinatorDarkGrey rounded-lg md:min-h-12 h-fit md:py-0 py-2 flex-col justify-center items-start
              `}
            >
              <div className={`flex w-full ${simpleView ? "flex-col" : "lg:flex-row flex-col"} `}>
                {!disableEdits ? (
                  <div className={`flex w-full lg:items-center items-start lg:justify-start justify-center lg:flex-row flex-col box-border p-2`}>
                    <div className="flex items-center">
                      <div className="flex text-lg uppercase whitespace-nowrap">
                        <b className="order-taskinatorWhite pr-1"> Task —</b>
                        {StringShortenDisplay(30, tracer.aid.name === "Set Actions" ? "Set Workflow" : tracer.aid.name)}{" "}
                      </div>
                    </div>
                    <div className={`flex lg:border-llg:ml-4 ml-0 lg:pr-2 lg:pl-4 pl-0 text-base font-light text-taskinatorMedGrey`}>
                      <div className={`flex grow ${simpleView ? "py-2" : "items-center justify-center lg:py-0 py-2"}`}>
                        <div className="pr-1 text-base font-light text-taskinatorWhite flex whitespace-nowrap">Assigned To:</div>

                        <div className="flex">
                          {tracer.assignee.map((user, index) => {
                            return (
                              index < 2 && (
                                <div className="">
                                  <UserDisplayBubble textSize={`text-sm ${index > 0 ? "mr-1" : ""}`} user={user} />
                                </div>
                              )
                            );
                          })}
                        </div>
                      </div>
                    </div>

                    <div className="flex w-full justify-end">
                      <div className="">
                        <div className="flex grow justify-end"></div>

                        {tracer.couldbeAssigned && !taskDetails.onHold && (
                          <div
                            className="flex items-center"
                            onClick={(event) => {
                              event.stopPropagation();
                            }}
                          >
                            <Button
                              label="Start This Tasks"
                              onClick={() => StartHandler()}
                              isLoading={initState[3].started}
                              customClasses={" border uppercase bg-taskinatorGreen text-taskinatorWhite border-taskinatorGreen hover:opacity-75 text-base py-1 px-2"}
                            />
                          </div>
                        )}
                        {taskDetails?.onHold && <JobOnHold taskDetails={taskDetails} />}
                        {tracer.aid._id && !taskDetails.onHold && (
                          <div className={`pl-2 justify-start items-center`}>
                            {assignees && !["dash"].includes(sid) && (
                              <div className={`flex justify-end`}>
                                {(manager || tracer.isAssigned) && (
                                  <div className="">
                                    <ClockInClockOut tid={taskDetails.tid} tracerId={tracerId} />
                                  </div>
                                )}
                                {Object.keys(backStep).length !== 0 && !editWorkflow && (manager || tracer.isAssigned) && (
                                  <div>
                                    <Button
                                      onClick={() =>
                                        setSubmitWork({
                                          approvedStatus: false,
                                          rejectedStatus: true,
                                        })
                                      }
                                      customClasses="uppercase mr-2 text-base border border-taskinatorRed text-taskinatorWhite bg-taskinatorRed hover:text-taskinatorWhite h-8"
                                      label={
                                        <div className="flex items-center justify-center">
                                          <div className="pl-1">Reject Task</div>
                                        </div>
                                      }
                                    />
                                  </div>
                                )}

                                {!editWorkflow && actionPlan && (manager || tracer.isAssigned) && (
                                  <div>
                                    <Button
                                      onClick={() =>
                                        setSubmitWork({
                                          approvedStatus: true,
                                          rejectedStatus: false,
                                        })
                                      }
                                      customClasses="uppercase text-base border border-taskinatorGreen text-taskinatorWhite bg-taskinatorGreen h-8"
                                      label={
                                        <div className="flex items-center justify-center">
                                          <div className="pl-1">Mark Task As Completed</div>
                                        </div>
                                      }
                                    />
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        )}

                        {workflowSet && (
                          <div>
                            <Button
                              onClick={() => appData.setViewAction()}
                              customClasses="uppercase text-base border border-taskinatorGreen text-taskinatorWhite bg-taskinatorGreen h-8"
                              label={
                                <div className="flex items-center justify-center">
                                  <div className="pr-1">Close</div>
                                  <div>
                                    <MdCheck size={"1rem"} />
                                  </div>
                                </div>
                              }
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="flex items-center">
                    <div className="flex text-lg uppercase whitespace-nowrap text-taskinatorGreen pl-2 items-center">
                      <MdCheckCircle size={"2rem"} />
                      <div className="pl-2 text-taskinatorWhite">
                        {workflowCompleted
                          ? "Workflow Has Been Completed"
                          : actionsCompleted
                          ? `${StringShortenDisplay(30, tracer.aid.name === "Set Actions" ? "Set Workflow" : tracer.aid.name)} Task Has Been ${
                              tracer.approvedStatus ? "Completed" : tracer.rejectedStatus ? "Rejected" : "Closed"
                            }`
                          : ""}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="w-full xl:h-full h-fit flex lg:flex-row flex-col grow lg:overflow-auto">
              <div className={`flex flex-col grow xl:overflow-auto`}>
                <div className={`flex flex-col h-fit mr-0.5 mt-1`}>
                  <div className="border-taskinatorMedGrey bg-taskinatorLightGrey border mr-0.5 rounded-lg p-1 mt-1">
                    <div className="h-fit">
                      <ItemActionableElementV2 item={tracer.itemsOnTracer} aidDetails={tracer.aid} border noButtons tracer={tracer} taskDetails={taskDetails} viewHistory getData={() => getData()} />
                      <div className="flex text-sm font-light text-taskinatorDarkGrey mt-1 h-auto  items-end">
                        <div className="whitespace-nowrap pr-1">Seen By:</div>
                        <div className="flex w-fit">
                          {tracer.seen.length > 0 &&
                            tracer.seen.map((user, index) => {
                              return (
                                <div key={user._id} className="flex capitalize font-medium">
                                  <div className="flex pr-1 whitespace-nowrap">
                                    <div className="pr-1">{user.firstName}</div>
                                    <div>{user.lastName[0]}</div>
                                    {tracer.seen.length > 1 && <div>,</div>}
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  </div>

                  {!disableEdits && !tracer.aid._id && !workflowSet && (
                    <div className="w-full h-full flex items-center justify-center my-2">
                      <Button
                        onClick={() => setEditWorkflow(true)}
                        label={"Set Workflow"}
                        customClasses={`"border-taskinatorRed text-taskinatorWhite bg-taskinatorRed animate-pulse hover:opacity-75
                        uppercase items-center py-2 px-2 border rounded text-lg`}
                      />
                    </div>
                  )}

                  <div className={`mt-1 text-sm px-2 text-taskinatorDarkGrey flex  bg-taskinatorLightGrey border border-taskinatorMedGrey rounded-t-lg border-b-0 h-fit py-1 md:mr-0.5 mr-0`}>
                    <div className="flex md:items-center w-full md:flex-row flex-col">
                      <div className="flex text-base uppercase whitespace-nowrap">
                        <b className="pr-2">Job —</b>
                        {data.ref}
                      </div>

                      {data.project && (
                        <div className="md:ml-2 w-fit text-base bg-taskinatorPurple px-2 text-taskinatorWhite rounded font-medium uppercase py-0.5">{StringShortenDisplay(15, data.project.ref)}</div>
                      )}

                      <div className="flex grow justify-end">
                        {manager && <TaskOnHold activator taskDetails={taskDetails} tracer={tracer} getData={() => getData()} />}
                        {manager && (
                          <div className="">
                            <Button
                              onClick={() => setEditTaskDetail(true)}
                              label="Edit"
                              customClasses={`border-taskinatorMedGrey text-taskinatorMedGrey hover:bg-taskinatorMedGrey hover:text-taskinatorWhite uppercase h-8 flex items-center justify-center text-sm px-2 border rounded ml-1`}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    {/* </div> */}
                  </div>

                  <div className="h-fit bg-taskinatorLightGrey border border-taskinatorMedGrey p-1 rounded-b-lg border-t-0 md:mr-0.5 mr-0">
                    <ActionJobDetailsDisplay
                      data={taskDetails}
                      getData={() => getData()}
                      simpleView={simpleView}
                      setViewOtherWorkflows={setViewOtherWorkflows}
                      viewOtherWorkflows={viewOtherWorkflows}
                      isManager={manager}
                      tracerId={tracer._id}
                    />
                    <ActionTimesheetDetailsDisplay data={taskDetails} timeSheetData={timeSheetData} />
                  </div>
                </div>

                {(tracer.aid._id || disableEdits) && (
                  <div
                    className={`h-fit mt-1 flex-col border-taskinatorMedGrey bg-taskinatorLightGrey border md:mr-0.5 mr-0 rounded-lg p-1 ${
                      manager || [process.env.REACT_APP_ACCESS_M].includes(appData.workspaceDataV4.role) ? "flex" : "hidden"
                    }`}
                  >
                    <div className="flex w-full h-fit md:flex-row flex-col items-center">
                      <div className="text-taskinatorDarkGrey text-base uppercase flex md:flex-row flex-col md:items-center w-full">
                        <div className="flex text-base uppercase whitespace-nowrap">
                          <b className="order-taskinatorWhite px-1">Workflow —</b>
                          {StringShortenDisplay(30, tracer.wfid.name)}
                        </div>
                        <div className={`flex grow md:justify-end`}>
                          {([process.env.REACT_APP_ACCESS_A, process.env.REACT_APP_ACCESS_O].includes(appData.workspaceDataV4.role) ||
                            ([process.env.REACT_APP_ACCESS_M].includes(appData.workspaceDataV4.role) && manager)) &&
                            !disableEdits && (
                              <div className="flex">
                                <div className="items-center flex">
                                  <Button
                                    label={`Cancel`}
                                    customClasses={`${
                                      initState[1].error
                                        ? "border-taskiantorRed text-taskinatorRed cursor-not-allowed"
                                        : "cursor-pointer text-taskinatorRed hover:bg-taskinatorRed hover:text-taskinatorWhite border-taskinatorRed"
                                    } justify-end h-8 text-sm px-4 py-2 border rounded opacity-40 uppercase`}
                                    onClick={() => setDelete(true)}
                                    error={initState[1].error}
                                    disabled={initState[1].error}
                                  />
                                </div>
                              </div>
                            )}
                          {manager && !disableEdits && (
                            <Button
                              onClick={() => setEditWorkflow(true)}
                              label={"Edit"}
                              customClasses={`border-taskinatorMedGrey text-taskinatorMedGrey  hover:bg-taskinatorMedGrey hover:text-taskinatorWhite uppercase h-8 flex items-center justify-center text-sm px-2 border rounded ml-1`}
                            />
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="border border-taskinatorMedGrey bg-taskinatorWhite rounded-lg p-2 mt-1 h-fit overflow-auto">
                      <ActionNextStepsDisplayV2
                        tracer={tracer}
                        aidDetails={tracer.aid}
                        setNextStep={setNextStep}
                        nextStep={nextStep}
                        backStep={backStep}
                        setBackStep={setBackStep}
                        workflowUpdateTrigger={workflowUpdateTrigger}
                        noAssignee={manager ? false : true}
                        actionPlan={actionPlan}
                      />
                    </div>

                    {manager && (
                      <AllWorkflowDisplay
                        tid={taskDetails.tid}
                        activeTracerId={tracerId}
                        setViewOtherWorkflows={setViewOtherWorkflows}
                        viewOtherWorkflows={viewOtherWorkflows}
                        workflowUpdateTrigger={workflowUpdateTrigger}
                        manager={manager}
                      />
                    )}
                  </div>
                )}
              </div>

              <div className={`${simpleView ? "hidden" : "flex"} mt-1 lg:w-96 overflow-auto flex-col border rounded-lg p-1 border-taskinatorMedGrey bg-taskinatorLightGrey`}>
                <div className="text-base font-bold px-1 whitespace-nowrap text-taskinatorDarkGrey uppercase flex justify-center items-center py-1 mb-1 rounded-lg">Job Diary:</div>
                <div className="border border-taskinatorMedGrey bg-taskinatorWhite py-1  rounded-t-lg h-[19rem] grow flex flex-col overflow-auto scrollbar">
                  <ActionCommentsDisplay
                    tracerWorkflowGroupId={tracer.tracerWorkflowGroupId}
                    workflowUpdateTrigger={workflowUpdateTrigger}
                    tracer_id={tracer._id}
                    setLatestNote={() => {}}
                    refresh={refresh}
                    setRefresh={setRefresh}
                    logs={logs}
                  />
                </div>

                {!disableEdits && (
                  <div className="w-full mt-0.5 p-2 bg-taskinatorWhite border border-taskinatorMedGrey rounded-b-lg">
                    <ActionCommentsNoteAdder
                      tracerWorkflowGroupId={tracer.tracerWorkflowGroupId}
                      tracerId={tracer._id}
                      refresh={() => {
                        setWorkflowUpdateTrigger(!workflowUpdateTrigger);
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className={`xl:w-1/5 w-full shrink-0 flex flex-col mt-1 xl:mt-0 overflow-auto`}>
            <div className="text-sm px-4 text-taskinatorWhite bg-taskinatorOrange shadow  font-semibold uppercase flex w-full rounded-lg lg:h-12">
              <div className="flex text-base uppercase items-center text-center w-full justify-center">Critical Questions</div>
              {/* <div className="flex grow justify-end items-center">{buttons.length > 0 && <Button label={buttons[0].label} customClasses={buttons[0].customClasses} onClick={buttons[0].onClick} />}</div> */}
            </div>
            <div className="bg-taskinatorHoverOrange p-1 flex-col grow flex mt-2 rounded-lg  overflow-auto">
              <JobRequests group={"task"} tid={taskDetails.tid} canSetRequests={{ setButtons: setButtons, buttons: buttons }} simpleView={simpleView} tracer={tracer} />
            </div>
            <div className="flex h-fit mt-1 items-center w-full justify-end border border-taskinatorMedGrey p-2 rounded-lg">
              {buttons.length > 0 && <Button label={buttons[0].label} customClasses={buttons[0].customClasses} onClick={buttons[0].onClick} />}
            </div>
          </div>
        </div>
      )}

      {submitWork && (
        <Modal
          local={() => {
            setSubmitWork();
          }}
        >
          <SubmitFormV4
            tracer={tracer}
            workflowNext={nextStep.workflow || (submitWork.approvedStatus && Object.values(nextStep).length === 0) ? true : false}
            next={Object.values(nextStep)}
            back={Object.values(backStep)}
            onSubmitSuccess={() => {
              setSubmitWork();
              onSubmitSuccess();
            }}
            taskDetails={taskDetails}
            item={tracer.itemsOnTracer}
            approvedStatus={submitWork.approvedStatus}
            rejectedStatus={submitWork.rejectedStatus}
          />
        </Modal>
      )}

      {onDelete && (
        <Modal
          local={() => {
            setDelete();
          }}
          title={`Cancel Task/Workflow${isOnlyWorkflow ? " and Job" : ""}`}
        >
          <ApproveRejectForm
            confirmLoadingState={initState[0].started}
            error={initState[0].error}
            rejectFunction={() => setDelete()}
            confirmFunction={() => deleteHandler()}
            requiredTextMatch={isOnlyWorkflow ? "CANCEL WORKFLOW AND JOB" : "CANCEL WORKFLOW"}
            confirmButtonLabel="Confirm Cancel"
            confirmButtonCSS="bg-taskinatorRed"
            rejectButtonLabel="Return"
            message={`Are You Sure You Want To Cancel This Workflow${isOnlyWorkflow ? " and Job" : ""}? It cannot be undone.`}
          />
        </Modal>
      )}

      {editTaskDetails && (
        <Modal local={() => setEditTaskDetail()} title="Edit Job Details">
          <div className="md:w-96 w-full">
            <TaskDetailsForm details={taskDetails} closeModal={() => setEditTaskDetail()} onSuccess={getData} />
          </div>
        </Modal>
      )}

      {editWorkflow && (
        <Modal
          title="Edit Current Workflow"
          local={() => {
            setEditWorkflow();
          }}
        >
          <TaskWorkflowSequenceDisplay
            tracer={tracer}
            dontAutoSelect
            taskDetails={taskDetails}
            releasing={() => {
              if (!tracer.aid._id) {
                setWorkflow(tracerId);
              }

              getData(tracerId);
              setEditWorkflow();
              setWorkflowUpdateTrigger(!workflowUpdateTrigger);
              setViewOtherWorkflows(true);
            }}
          />
        </Modal>
      )}
    </>
  );
};

export default ActionDetailDisplayV2;
