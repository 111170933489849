import React, { useContext } from "react";
import { AppContext } from "../../contexts/appContext";
import DropDownSelector_V4 from "../inputs/dropDownSelector_V4";

const SignUpSelector = ({ inputVer, inputHandler, defaultValue, sid, disabled, toolTip }) => {
  let options = [
    { type: "", name: "Microsoft Login", value: "azure" },
    { type: "", name: "Google Login", value: "google" },
    { type: "", name: "Email & Password", value: null },
  ];

  return (
    <DropDownSelector_V4
      color={"border-taskinatorMedGrey text-taskinatorDarkGrey font-semibold"}
      selectionColor={"text-taskinatorBlue uppercase"}
      label="User Login Method"
      id={"signInStrategies"}
      notSearchable
      type="SEARCH"
      sid={sid}
      defaultValue={defaultValue}
      options={options}
      onSelection={inputHandler}
      disabled={disabled}
      selectionDisplayText={"Select Auth"}
      inputVer={inputVer}
      alwaysValid
      toolTip={toolTip}
    />
  );
};

export default SignUpSelector;
