import React, { useEffect, useContext } from "react";
import axios from "axios";
import { AppContext } from "../../contexts/appContext";
import { TaskContext } from "../../contexts/taskContext";
import { useInit } from "../hooks/useInit";
import { useInputV4 } from "../../stateManagement/useInputV4";

import Button from "../UI_elements/button";
import InputV4 from "../inputs/input_V4";
import FileUploaderV4 from "../inputs/fileUploader_V4";
import OldFileUploaderV4 from "../inputs/oldFIleUploaderV4";
import MemberSelector from "../dataSelectors/memberSelector";
import TagSelector from "../dataSelectors/tagSelector";
import PrioritySelector from "../dataSelectors/prioritySelector";
import { VALIDATOR_MINLENGTH, VALIDATOR_REQUIRE, VALIDATOR_MAXLENGTH, VALIDATOR_MAX, VALIDATOR_NUMBER } from "./validators/validators";
import { useParams } from "react-router-dom";
import ProjectSelector from "../dataSelectors/projectSelector";

const TaskDetailsForm = ({ details, globalSid, globalInputHandler, disabled, workflowId, closeModal, onSuccess, project, tracerId }) => {
  const appData = useContext(AppContext);
  const taskContext = useContext(TaskContext);
  const { inputState, inputHandler, attachmentHandler } = useInputV4({ inputs: {}, attachments: {}, deleteRef: {}, isValid: false });
  const { initState, initStarted, initCompleted, initErrors, initReset } = useInit();
  const sid = "inputs";

  useEffect(() => {
    if (globalSid) {
      Object.keys(inputState.inputs).map((inputKey) => {
        globalInputHandler(inputKey, inputState.inputs[inputKey].value, inputState.inputs[inputKey].isValid, globalSid);
      });

      globalInputHandler(
        "attachments",
        Object.keys(inputState.attachments).map((key) => {
          return inputState.attachments[key].value;
        }),
        Object.values(inputState.attachments).filter((attachment) => attachment.isValid === false).length === 0,
        globalSid
      );
    }
  }, [inputState]);

  const onSaveHandler = () => {
    initStarted(0);
    axios
      .put(
        process.env.REACT_APP_BACKEND_URL + `/api/task/edit/${details.tid}/${tracerId}`,
        {
          ref: inputState.inputs.ref.value,
          dueDate: inputState.inputs.dueDate.value,
          priority: inputState.inputs.priority.value,
          scope: inputState.inputs.scope.value,
          attachments: Object.values(inputState.attachments).map((attachment) => {
            return { key: attachment.value.key, fileName: attachment.value.fileName };
          }),
          taskTags: inputState.inputs.taskTag.value,
          managers: inputState.inputs.managers.value,
          watchers: details ? details.watchers : [],
          itemsOnTask: details.itemsOnTask,
          estimatedTime: inputState.inputs.estimatedTime.value,
          project: inputState.inputs.project.value,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + appData.tokenV4,
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        initCompleted(0);
        onSuccess();
        taskContext.getActionData();
        closeModal();
      })
      .catch((err) => {
        return initErrors(err?.response?.data?.message ?? err?.message ?? "", 0);
      });
  };

  return (
    <div className={`${!details ? "bg-taskinatorWhite border border-taskinatorMedGrey rounded-lg py-2 px-4 shadow grow  " : ""} `}>
      {/* {!details && <div className="flex pb-2 text-taskinatorDarkGrey uppercase"><div className="text-lg border rounded-full h-6 w-6 items-center justify-center flex font-bold border-2 mr-2 text-taskinatorDarkGrey border-taskinatorDarkGrey">1</div><div className="text-md font-semibold">Task Details</div></div>} */}
      <div className="mt-2">
        <InputV4
          label="Job Number/Reference"
          sid={sid}
          id="ref"
          placeholder={`Enter job number or ref`}
          customClasses={"rounded-md md:text-sm"}
          onInput={inputHandler}
          validators={[VALIDATOR_MINLENGTH(3)]}
          disabled={disabled}
          validityMessage="Min 3 characters"
          defaultValue={details ? details.ref : null}
        />
      </div>

      <div className="mt-2 hidden">
        <InputV4
          sid={sid}
          id="project"
          placeholder={`Enter job number or ref`}
          customClasses={"rounded-md md:text-sm"}
          onInput={inputHandler}
          validators={[]}
          disabled={disabled}
          validityMessage="Min 3 characters"
          defaultValue={details ? details.project : project ? project : null}
        />
      </div>

      {/* <div className="">
        <ProjectSelector sid="inputs" id="project" inputHandler={inputHandler} notRequired={true}   defaultValue={details ? details.project && details.project._id : null}/>
      </div> */}

      <div className="mt-2">
        <InputV4
          label="Due Date"
          id="dueDate"
          type="date"
          customClasses={"rounded-md md:text-sm"}
          disabled={disabled}
          sid={sid}
          onInput={inputHandler}
          defaultValue={details ? details.dueDate : null}
          validators={[]}
          validityMessage="Please Set A Due Date"
        />
      </div>

      <div className="mt-2">
        <MemberSelector
          inputHandler={inputHandler}
          defaultValue={
            details
              ? details.managers.map((manager) => {
                  return { value: manager._id };
                })
              : []
          }
          sid={sid}
          id="managers"
          label={"Manager/s"}
          multi
          roleTypes={[process.env.REACT_APP_ACCESS_M, process.env.REACT_APP_ACCESS_O, process.env.REACT_APP_ACCESS_A]}
          notRequired
          dontAutoSelect
        />
      </div>

      <div className="mt-2">
        <InputV4
          label="Scope Notes "
          id="scope"
          customClasses={"rounded-md md:text-sm "}
          placeholder="Detail scope of works for this task and it's deliverables..."
          textareaRows={8}
          sid={sid}
          onInput={inputHandler}
          validators={[VALIDATOR_MAXLENGTH(500)]}
          defaultValue={details ? details.scope : null}
          disabled={disabled}
          // validityMessage="Min of 6"
          requiredOverride
        />
        <div className="text-[0.75rem]">{inputState.inputs && inputState.inputs.scope && 500 - inputState.inputs.scope.value.length} characters remaining</div>
      </div>

      <div className="mt-2">
        <OldFileUploaderV4
          label="Reference Documents"
          disabled={disabled}
          id="attachment"
          inputHandler={inputHandler}
          attachmentHandler={attachmentHandler}
          defaultValue={details ? details.attachments : null}
        />
      </div>

      <div className="pt-2">
        <TagSelector
          notRequired
          id={"taskTag"}
          inputHandler={inputHandler}
          defaultValue={
            details
              ? details.taskTags.map((tag) => {
                  return { value: tag._id };
                })
              : []
          }
          sid="inputs"
          type="task"
          label="Tags"
          selectionDisplayText={"Add/Create Job Tag"}
        />
      </div>

      <PrioritySelector sid="inputs" id="priority" inputHandler={inputHandler} notRequired={true} />

      <div className="mt-2">
        <InputV4
          label="Estimated Time in hours"
          id="estimatedTime"
          customClasses={"rounded-md md:text-sm"}
          disabled={disabled}
          sid={sid}
          onInput={inputHandler}
          defaultValue={details ? details.estimatedTime : null}
          validators={[VALIDATOR_NUMBER()]}
          requiredOverride
          validityMessage="Add Estimated Time"
        />
      </div>

      {details && (
        <Button
          onClick={() => onSaveHandler()}
          label={"Save Job Details"}
          customClasses={`${inputState.isValid ? "bg-taskinatorBlue" : "bg-taskinatorMedGrey"} bg-taskinatorBlue text-taskinatorWhite h-10 w-full mt-4 `}
          disabled={!inputState.isValid}
          isLoading={initState[0].started}
          error={initState[0].error}
        />
      )}
    </div>
  );
};

export default TaskDetailsForm;
