import React, { useContext } from "react";

import { useInputV4 } from "../../stateManagement/useInputV4";
import InputV4 from "../inputs/input_V4";
import { VALIDATOR_MATCH } from "./validators/validators";

import Button from "../UI_elements/button";

const ApproveRejectForm = ({ confirmButtonLabel, rejectButtonLabel, confirmFunction, rejectFunction, message, requiredTextMatch, confirmLoadingState, error, confirmButtonCSS, noConfirmButton }) => {
  const { inputState, inputHandler } = useInputV4({ inputs: {}, isValid: false });


  const submitHandler = (event) => {
    event.preventDefault();
    confirmFunction()
  }
  return (
    <form className=" flex md:w-96 w-full flex-col p-4" onSubmit={submitHandler}>
      <div className="text-sm py-2 text-center">{message}</div>
      {requiredTextMatch &&   <div className="text-sm py-2">
        Type <strong>{requiredTextMatch}</strong> below to confirm (Case Sensitive)
      </div>}
      {requiredTextMatch && (
        <div>
          <InputV4
            inputVer="1"
            sid="inputs"
            id="name"
            placeholder={`Type ${requiredTextMatch} to confirm`}
            customClasses={"rounded-md "}
            onInput={inputHandler}
            validators={[VALIDATOR_MATCH(requiredTextMatch)]}
          />
        </div>
      )}
      <div className="flex w-full justify-center">
       {!noConfirmButton && <div>
          <Button
            type={"submit"}
            label={confirmButtonLabel ? confirmButtonLabel : "Yes"}
            customClasses={`${inputState.isValid || !requiredTextMatch ? `${confirmButtonCSS ? confirmButtonCSS : "bg-taskinatorGreen"}` : "bg-taskinatorMedGrey"} text-taskinatorWhite h-10 text-center justify-center flex items-center  mt-2 mr-2 `}
            disabled={!inputState.isValid && requiredTextMatch}
            isLoading={confirmLoadingState}
          
          />
        </div>}
        <div>
          <Button onClick={() => rejectFunction()} label={rejectButtonLabel ? rejectButtonLabel : "No"} customClasses={`bg-taskinatorMedGrey justify-center flex items-center  text-taskinatorDarkGrey text-center h-10  mt-2 `} />
        </div>
        
      </div>
      <div className="text-xs text-taskinatorRed text-center">{error}</div>
    </form>
  );
};

export default ApproveRejectForm;
