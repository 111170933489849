import React, { useState, useContext } from "react";
import axios from "axios";
import Input_V4 from "../../inputs/input_V4";
import { useInit } from "../../hooks/useInit";
import { TaskContext } from "../../../contexts/taskContext";
import { useInputV4 } from "../../../stateManagement/useInputV4";
import BulkFileUpload from "../files/bulkFileUpload";

import Button from "../../UI_elements/button";
import { VALIDATOR_MINLENGTH } from "../../forms/validators/validators";

import IconSpinner from "../../UI_elements/iconSpinner";

const TaskCommentsNoteAdder = ({ taskId, jobId, defaultData, refresh, isEdit,  setEditMode,isCompleted }) => {
  const { initState, initStarted, initCompleted, initErrors, initReset } = useInit();

  const { inputState, inputHandler, attachmentHandler, resetState } = useInputV4();
  const [critical, setCritical] = useState(false);
  const [defaultComments, setDefaultComments] = useState(defaultData?.comment ?? "");
  const [defaultAttachments, setDefaultAttachments] = useState(defaultData?.attachments ?? null);
  const [reset, setReset] = useState(false);

  const addNoteHandler = (isCritical) => {
    let URL = `/api/job/add/taskComments/${taskId}/${jobId}`;

    if (isEdit) {
      URL = `/api/job/save/taskComments/${defaultData._id}/${taskId}/${jobId}`;
    }
    initStarted(0);

    const attachmentData = Object.values(inputState?.attachments ?? {}).map((attachment) => {
      return {
        key: attachment.value.key,
        fileName: attachment.value.fileName,
        fileExtension: attachment.value.fileExtension,
        _id: attachment.value._id,
      };
    });

    axios
      .put(
        process.env.REACT_APP_BACKEND_URL + URL,
        {
          comment: inputState.inputs.comments.value,
          critical: isCritical,
          attachments: attachmentData,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      .then((responseData) => {
        // resetState({ inputs: { comments: { value: "", isValid: false }, attachments: { value: [], isValid: true } } });

        if (isEdit) {
          refresh({ ...defaultData, comment: inputState.inputs.comments.value, attachments: attachmentData, editedDate: new Date() });
        } else {
          setReset(!reset);
          refresh(responseData.data.updatedTask);
        }
        setCritical(false);

        initCompleted(0);
      })
      .catch((err) => {
        console.log(err);
        initErrors(err?.response?.data?.message ?? err?.message ?? "", 0);
      });
  };

  return (
    <>
      <div className="flex flex-col items-end xl:max-w-xl mx-auto pt-1 ">
        <div className="max-h-[10rem] overflow-auto w-full scrollbar">
          <Input_V4
            onInput={inputHandler}
            inputVer="1"
            id="comments"
            sid="inputs"
            textareaRows={2}
            customClasses={"w-full "}
            validators={[VALIDATOR_MINLENGTH(3)]}
            placeholder={"Add Comment"}
            defaultValue={defaultComments}
            reset={reset}
            disabled={isCompleted}
          />
        </div>
        {/* {isEdit && <div  className="text-taskinatorBlue underline cursor-pointer hover:opacity-75" onClick={() => addNoteHandler(false)}>Save Changes</div>} */}
        <div className="h-fit pt-2 w-full ">
          <BulkFileUpload
            globalHandler={{
              inputHandler: inputHandler,
              attachmentHandler: attachmentHandler,
            }}
            defaultValue={defaultAttachments}
            noButton
            type="task"
            reset={reset}
            disabled={isCompleted}
          />
        </div>
        <div className="mt-2 flex items-center  justify-end w-full">
          {/* <div className="h-full items-center flex">
              <Button
                label={
                  <div className="w-full  flex">
                    Submit Note as Critical
                        </div>
                }
                disabled={!inputState.isValid}
                isLoading={initState[0].started}
                customClasses={`${
                  inputState.isValid ? "border-taskinatorRed border text-taskinatorRed" : "border-taskiantorMedGrey text-taskinatorMedGrey border"
                } py-1 h-8 mr-1 rounded-lg font-medium text-sm  uppercase`}
                onClick={() => {
                  addNoteHandler(true);
                  setCritical(true);
                }}
                error={initState[0].error}
              />

    
            </div> */}

          {isEdit && (
            <div
            className="text-taskinatorRed pr-2 underline cursor-pointer hover:opacity-75"
              onClick={() => {
                setEditMode();
              }}
            >
              Cancel
            </div>
          )}
          <Button
            label={isEdit ? "Save Comment" : "Add Comment"}
            disabled={!inputState.isValid || isCompleted}
            isLoading={initState[0].started}
            customClasses={`${
              inputState.isValid ? "bg-taskinatorBlue border text-taskinatorWhite" : "bg-taskinatorMedGrey  text-taskinatorWhite"
            } py-1 h-8 mr-1 rounded-lg font-medium text-sm `}
            onClick={() => addNoteHandler(false)}
            error={initState[0].error}
          />
        </div>
      </div>
    </>
  );
};

export default TaskCommentsNoteAdder;
