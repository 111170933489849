import { useCallback, useState } from "react";

export const useWorkspaceDataV4 = () => {
  const [workspaceDataV4, setWorkspaceData] = useState();

  const setWorkspaceDataV4 = useCallback((data) => {
    setWorkspaceData(data);
  }, []);

  return { workspaceDataV4, setWorkspaceDataV4 };
};
