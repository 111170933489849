import React, { useEffect, useReducer } from "react";
import ToolTip from "../../utils/toolTip";

const checkboxReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE_DEFAULT":
      return action.state;

    case "CHANGE":
      return !state;

    default:
      return state;
  }
};

const CheckboxV4 = ({ defaultValue, onInput, disabled, id, sid, label, customCheckbox, customClasses, customeLabel, globalDispatch, globalDispatchType, toolTip }) => {
  const [isChecked, dispatch] = useReducer(checkboxReducer, defaultValue || false);
  
  const changeHandler = () => {
    if (globalDispatch) {
      globalDispatch({ type: globalDispatchType, isChecked: !isChecked });
    }

    dispatch({
      type: "CHANGE",
    });
  };

  useEffect(() => onInput(id, isChecked, true, sid), [id, isChecked, sid]);

  useEffect(() => {
    if (!disabled) {
      dispatch({
        type: "CHANGE_DEFAULT",
        state: defaultValue,
      });
    }
  }, [defaultValue]);

  return (
    <div className={`flex ${customClasses} items-center`}>
      <div className={`mr-2 flex ${disabled ? "opacity-75" : ""}`}>
        <input
          id={id}
          type="checkbox"
          disabled={disabled}
          onChange={changeHandler}
          checked={isChecked ?? false}
          className={`${customCheckbox} ${disabled ? "opacity-75 cursor-not-allowed" : "cursor-pointer"}`}
        />
      </div>
      <div className={`${customeLabel} flex`}>
        {label} {toolTip && <ToolTip content={toolTip} />}
      </div>
    </div>
  );
};

export default CheckboxV4;
