import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import IconSpinner from "../../components/UI_elements/iconSpinner";
import { useInit } from "../../components/hooks/useInit";
import { AppContext } from "../../contexts/appContext";
import ContentView from "../../pageTemplates/contentView";
import AppUsage from "./appUsage";
import BillingDetails from "./billingDetails";
import CurrentPlan from "./currentPlan";
import DrawingLogSettings from "./drawingLogSettings";
import DriveSettings from "./driveSettings";
import LowerCaseTrim from "../../utils/lowerCaseAndTrim";
const Billing = () => {
  const appData = useContext(AppContext);
  const { initState, initStarted, initCompleted, initErrors } = useInit(2);
  const [customer, setCustomer] = useState();

  const isCPT = LowerCaseTrim(appData.workspaceDataV4.orgName) === "cpt engineering";

  const getData = () => {
    initStarted(0);

    axios
      .get(process.env.REACT_APP_BACKEND_URL + `/api/billing/customerdetails`, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      .then((response) => {
        setCustomer(response.data);
        initCompleted(0);
      })
      .catch((err) => {
        console.log(err);
        const errorMessage = err?.response?.data?.message ?? err?.message ?? "";
        initErrors(errorMessage, 0);
      });
  };




  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLoading = initState[0].initialised && !initState[0].started;

  return (
    <ContentView buttons={[]} label={"Account Details"}>
      {/* <div className="bg-taskinatorWhite w-full -mt-1 p-4 text-taskiantorDarkGrey font-medium shadow">
        Account Details
      </div> */}
      <div className="flex flex-col w-full">
      

      
        <div></div>
        <div className="flex flex-col xl:flex-row">
        <div className="bg-taskinatorWhite mr-0.5 mt-0.5 p-4 shadow rounded grow">
          {isLoading && customer?.stats ? <AppUsage orgStats={customer?.stats} plan={customer?.planSelected} isCPT={isCPT}/> : <IconSpinner />}
        </div>
          <div className="bg-taskinatorWhite mr-0.5 mt-0.5 p-4 shadow rounded  xl:w-90 grow xl:shrink-0">
            {isLoading && customer?.planSelected ? <CurrentPlan plan={customer?.planSelected} unlimitedUntil={customer?.unlimitedUntil} getData={getData} /> : <IconSpinner />}
          </div>
          {/* {customer?.planSelected?.paymentDetails?.last4 && (
            <div className="bg-taskinatorWhite mr-0.5 mt-0.5 p-4 shadow rounded xl:w-90 grow xl:shrink-0">
              {isLoading && customer?.planSelected ? <BillingDetails plan={customer?.planSelected} getData={getData} /> : <IconSpinner />}
            </div>
          )} */}
        </div>

        <div className="bg-taskinatorWhite mr-0.5 mt-0.5 p-4 shadow rounded w-full">
          {isLoading && customer?.workspaceSettings ? <DrawingLogSettings settings={customer?.workspaceSettings} /> : <IconSpinner />}
        </div>

        <div className="bg-taskinatorWhite mr-0.5 mt-0.5 p-4 shadow rounded w-full">
          {isLoading && customer?.workspaceSettings ? <DriveSettings settings={customer?.workspaceSettings} currentDriveOption={appData.workspaceDataV4.driveOption}  plan={customer?.planSelected}/> : <IconSpinner />}
        </div>
      </div>
    </ContentView>
  );
};

export default Billing;
