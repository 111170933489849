
const StringShortenDisplay = (numChars, inputString, noDots) => {

  let shortenedValue  = inputString ? inputString.slice(0, numChars ? numChars : 0) : "..."


  if (inputString && inputString.length > numChars && !noDots) {
    shortenedValue += "...";
  }
  return shortenedValue;
};

export default StringShortenDisplay;
    