import React from "react";
import LoginScreen from "../../pageTemplates/loginScreen";
import SignUpForm from "../../components/forms/signUpForm";
import SignupFormV4 from '../../components/forms/signupForm_v4'


const SignUp = () => {
  return (
    <LoginScreen>
      <SignupFormV4 />
    </LoginScreen>
  );
};

export default SignUp;
