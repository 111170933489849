import React, { useState, useContext, useEffect } from "react";

import ContentView from "../../pageTemplates/contentView";

import InputV4 from "../../components/inputs/input_V4";

import RequestFormatter from "../../utils/requestFormatter";

import { useGetData } from "../../utils/useGetData";

import ColumnElement from "../../components/dashboard/columnElement";
import { AppContext } from "../../contexts/appContext";
import { useInit } from "../../components/hooks/useInit";

const AllQuestions = () => {
  // const { taskData, taskDataInitState } = useGetTaskData("mine/$");
  const appData = useContext(AppContext);
  const [requestData, requestDataInitState, getRequests] = useGetData({ url: "all/$", type: "requests" });

  const [allQuestions, setAllQuestions] = useState({});
  const { initState, initStarted, initCompleted, initErrors } = useInit(1);

  const isAll = Object.keys(appData.tagFilter)[0] === "all tags";
  const filterTags = !isAll
    ? Object.values(appData.tagFilter)
        .map((key) => appData.jobListFilterValues[key])
        .flat(1)
    : [];

  useEffect(() => {
    initStarted(0);

    appData.socket.emit(
      "allQuestions",
      {
        action: "getAllQuestions",
        tagFilter: filterTags,
        isAll: isAll,
      },
      (callback) => {
        if (callback.success) {
          setAllQuestions(callback.questions);
          initCompleted(0);
        } else {
          initErrors(callback.error, 0);
        }
      }
    );

    return () => {
      appData.socket.off("allQuestionsUpdate");
    };
  }, [appData.tagFilter, appData.questions]);

  appData.socket.on("allQuestionsUpdate", (data) => {
    if (data.action === "add") {
      if (filterTags.length === 0 || filterTags.includes(data.newQuestion.tid.toString())) {
        setAllQuestions({ ...allQuestions, [data.newQuestion._id]: data.newQuestion });
      }
    }
  });

  const buttons = [];

  const [filter, setFilter] = useState();

  const centerContent = (
    <div className="flex items-center sm:flex-row flex-col w-full sm:w-fit sm:justify-center  sm:mt-0 mt-1">
      <InputV4
        inputVer={"1"}
        customClasses={"sm:w-96 w-[100%] items-center"}
        placeholder="Search Questions"
        onInput={(id, value, isValid, sid) => {
          setFilter(value);
        }}
        validators={[]}
      />
    </div>
  );

  return (
    <ContentView buttons={buttons} label={"All Questions"} centerContent={centerContent}>
      <div className="flex h-full grow overflow-auto ">
        <div className="grow  m-0.5 h-auto  min-w-[26rem] overflow-auto scrollbar">
          <ColumnElement
            type="request"
            primaryColour={"bg-taskinatorOrange"}
            faintColour={"bg-taskinatorHoverOrange"}
            primaryBorder={"border-l-taskinatorOrange"}
            label="All Questions"
            getData={getRequests}
            loading={false}
            jobData={Object.values(RequestFormatter(allQuestions, filter, appData))}
            initState={initState}
            stateNumber={0}
          />
        </div>
      </div>
    </ContentView>
  );
};

export default AllQuestions;
