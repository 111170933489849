import React, { useEffect, useState } from "react";
import axios from "axios";
import { useInit } from "../../components/hooks/useInit";
import CalendarColumnElement from "../../components/dashboard/calanderColumnElement";
import IconSpinner from "../../components/UI_elements/iconSpinner";
import CalenderRowElement from "../../components/dashboard/calanderRowElement";

const TrackTeam = () => {
  const { initState, initStarted, initCompleted, initErrors, initReset } = useInit(2);
  const [teamSchedules, setTeamSchedules] = useState([]);

  useEffect(() => {
    initStarted(0);
    axios
      .get(
        process.env.REACT_APP_BACKEND_URL + `/api/team/schedules`,

        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        console.log(response.data);
        setTeamSchedules(response.data);
        initCompleted(0);
      })
      .catch((err) => {
        console.log(err);
        initErrors(err?.response?.data?.message ?? err?.message ?? "", 0);
      });
  }, []);

  return (
    <div className={` h-full flex flex-col overflow-auto`}>
      <div className="w-full flex-col  justify-center items-center px-2 flex bg-taskinatorWhite py-4 shadow">
        <div className="flex w-full items-center justify-center">
          <div className="flex grow justify-start pl-2 font-medium text-taskinatorDarkGrey items-center">
            <div className="pr-4 items-center whitespace-nowrap">Team Schedules</div>
            <div></div>
          </div>
        </div>
      </div>
      <div className="h-full flex flex-col grow overflow-auto ">
        <CalenderRowElement userSchedules={teamSchedules} />

        {initState[0].started && (
          <div className="my-4 w-full">
            <IconSpinner size="m" />
          </div>
        )}
      </div>
    </div>
  );
};

export default TrackTeam;
