import React, { useEffect, useState, useContext } from "react";
import axios from "axios";

import { useInit } from "../../components/hooks/useInit";
import { AppContext } from "../../contexts/appContext";

import TagSelector from "../../components/dataSelectors/tagSelector";

import ContentView from "../../pageTemplates/contentView";
import IconSpinner from "../../components/UI_elements/iconSpinner";
import ItemStatus from "../../components/UI_elements/itemStatus";

const Options = () => {
  const appData = useContext(AppContext);
  const { initState, initStarted, initCompleted, initErrors, initReset } = useInit();
  const [responseData, setResponseData] = useState();

  const sendRequest = async () => {
    initStarted(0);

    try {
      const responseData = await axios.get(process.env.REACT_APP_BACKEND_URL + `/api/options`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + appData.tokenV4,
        },
        withCredentials: true,
      });

      initCompleted(0);
      setResponseData(responseData.data);
    } catch (err) {
      console.log(err);
      initErrors(err.response.data.message, 0);
    }
  };

  useEffect(() => {
    sendRequest();
  }, []);


  const buttons = [];

  return (
    <ContentView buttons={buttons} label={"Options"}>
      <div className="w-full h-full">
        <div className="bg-taskinatorWhite rounded-lg p-2 w-1/2 shadow-md">
          <div className="text-taskinatorDarkGrey text-sm font-semibold uppercase pb-2 text-center">Item Status Options</div>
          {initState[0].started && !responseData && <IconSpinner />}

          {!initState[0].started && responseData && (
            <div className="flex flex-col w-full border border-taskinatorMedGrey rounded-lg">
              {responseData.itemStatusOptions.map((status, index) => {
              

                return (
                  <div className={`items-center flex hover:bg-taskinatorHoverBlue pl-2 text-xs py-2 ${responseData.itemStatusOptions.length - 1 > index ? "border-b border-b-taskinatorLightGrey border-b-2" : ""}`}>
                    <div className={`pr-2`}><ItemStatus status={status.name} /></div>
                    <div>Access Tags:</div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </ContentView>
  );
};

export default Options;
