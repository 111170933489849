import React from "react";
import CountUpTimer from "../../../utils/countUpTimer";
import FilePreview from "../../../utils/filePreview";
import LinkifyText from "../../../utils/linkifyText";
import DateDisplay from "../../UI_elements/dateDisplay";
import IconSpinner from "../../UI_elements/iconSpinner";
import UserDisplayBubble from "../../UI_elements/userDisplayBubble";
import BulkDownloader from "../../../utils/bulkDownloader";
import Tag from "../../UI_elements/tag";
import JobModalDropDownContainer from "./jobModalDropDownContainer";
import FileSetAction from "./fileSetAction";
const JobDetailsDisplay = ({ jobDetails }) => {
  let selectedFiles = {};

  jobDetails?.attachments?.forEach((attachment) => {
    selectedFiles = { ...selectedFiles, [attachment.key]: attachment };
  });

  if (jobDetails) {
    return (
      <>
        <div className="flex grow w-full  ">
      

          <div className=" lg:mt-0 mt-1 rounded-lg border p-2 border-taskinatorMedGrey bg-taskinatorWhite flex flex-col w-1/2">
            <div className="flex flex-col grow">
              <div className="flex flex-col  h-auto  ">
                <div className="text-taskinatorDarkGrey font-semibold whitespace-nowrap text-sm pb-2">Job Instructions:</div>
                <div className="text-base md:text-sm whitespace-pre-wrap h-fit grow">
                  {jobDetails?.scope?.length > 0 ? <LinkifyText text={jobDetails.scope} /> : <div className="text-taskinatorMedGrey whitespace-nowrap md:text-sm">No Scope Details</div>}
                </div>
              </div>
              <div className="flex flex-col  border-t-2 border-t-taskinatorLightGrey py-2 ">
                <div className="text-taskinatorDarkGrey pr-1 text-sm font-semibold whitespace-nowrap">Job Reference Documents:</div>
                <div className="text-base md:text-sm flex flex-wrap">
                  {jobDetails?.attachments.length > 0 ? (
                    jobDetails?.attachments.map((attachment, index) => {
                      return (
                        <div className="w-fit md:text-sm pr-1" key={index}>
                          <FilePreview viewMarkupsOnly file={attachment} typeOverride="job" showName small />
                          {/* <DocumentDisplay attachment={attachment} /> */}
                        </div>
                      );
                    })
                  ) : (
                    <div className="text-taskinatorMedGrey whitespace-nowrap md:text-sm">No Attachments</div>
                  )}
                </div>
                <div className="-ml-2">
                  <BulkDownloader selectedFiles={selectedFiles} fileType={"job"} fileNameData={`${jobDetails.ref}_Reference Document`} />
                </div>
              </div>
            </div>
          </div>

          <div className="grow ml-1 flex flex-col ">
            <div className="grow   rounded-lg border p-2 border-taskinatorMedGrey bg-taskinatorWhite flex flex-col">
              <div className="text-taskinatorDarkGrey font-semibold whitespace-nowrap text-sm pb-2">Reference Folder:</div>
              {jobDetails?.referenceFileSetIds?.length > 0 ? (
                jobDetails?.referenceFileSetIds?.map((fileSet, index) => {
                  return (
                    <JobModalDropDownContainer
                      thin
                      headerContent={
                        <div className="flex h-fit">
                          <div className="ml-2 border-2 flex my-1 px-1  rounded-lg border-taskinatorDarkGrey opacity-75 text-sm mr-2">
                            <b className="  pr-1">{fileSet?.fileSetDetails?.number}</b>
                            <div>-</div>
                            <b className="  pl-2">{fileSet?.fileSetDetails?.name}</b>{" "}
                          </div>
                          <div className="text-xs flex items-center justify-center">
                            {fileSet?.fileSetDetails?.settings?.fileTags?.map((tag, index) => {
                              return <div className="border border-taskinatorDarkGrey bg-taskinatorMedGrey font-medium rounded uppercase px-2 text-taskinatorDarkGrey mr-1">{tag.name}</div>;
                            })}
                          </div>
                          <div className="flex grow justify-end"></div>
                        </div>
                      }
                      textColour={"text-taskinatorDarkGrey"}
                      borderColour={"border-taskinatorMedGrey"}
                      backgroundColour={"bg-taskinatorItemBlue border border-taskinatorMedGrey "}
                    >
                      <div className="pt-1">
                        <FileSetAction fileSetByTask={{ ...fileSet, fileSetName: fileSet?.fileSetDetails?.name, fileSetId: fileSet?.fileSetDetails?._id }} noReference />
                      </div>
                    </JobModalDropDownContainer>
                  );
                })
              ) : (
                <div className="text-taskinatorMedGrey whitespace-nowrap md:text-sm">None Attached</div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <div>
        <IconSpinner />
      </div>
    );
  }
};

export default JobDetailsDisplay;
