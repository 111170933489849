import React, { useContext, useEffect, useState } from "react";
import { useGetData } from "../../../utils/useGetData";
import Button from "../../UI_elements/button";

import { AppContext } from "../../../contexts/appContext";
import TaskFormatter from "../../../utils/taskFormatter";
import ItemActionableElementV2 from "../items/itemActionableElementV2";
import AddWorkflowItemHandler from "../task/addWorkflowItemHandler";
import ActionNextStepsDisplayV2 from "./actionNextStepsDisplayV2";
import { SortWithKeyword } from "../requests/requestModal";
import JobAndWorkflow from "../../UI_elements/jobAndWorkflow";

const AllWorkflowDisplay = ({ tid, activeTracerId, workflowUpdateTrigger, jobsOnly, notFull, manager }) => {
  const appData = useContext(AppContext);
  const [modal, setModal] = useState();
  const [viewOtherWorkflows, setViewOtherWorkflows] = useState();

  const [activeTaskData, activeTaskDataInitState, getActiveTasks] = useGetData({
    url: `history2/${tid}`,
    type: "task",
  });

  // useEffect(() => {
  //   getActiveTasks();
  //   // const interval = setInterval(() => {
  //   //   getActiveTasks();
  //   // }, process.env.REACT_APP_SERVER_CHECK_TIME);

  //   // return () => clearInterval(interval);
  // }, [workflowUpdateTrigger]);

  let compltedActivities = [];
  let liveActivities = [];
  let allActivities = [];

  // if (Object.keys(activeTaskData).length > 0) {
  //   Object.values(activeTaskData).forEach((item) => {
  //     if (item?.completed ?? false) {
  //       compltedActivities.push(item);
  //     } else {
  //       liveActivities.push(item);
  //     }
  //   });
  //   allActivities = [...SortWithKeyword(liveActivities, "createdDate"), ...SortWithKeyword(compltedActivities, "createdDate")];
  // }

  return (
    <>
      <div>
        {/* {taskDetails && <ActionJobDetailsDisplay data={taskDetails[0]} getData={() => {}} />} */}
        {!jobsOnly && (
          <div className="flex md:flex-row flex-col grow md:items-center mt-1 ml-1 ">
            {/* <div className="font-bold text-taskinatorDarkGrey text-base uppercase flex"> All Other Workflows</div> */}
            <div className="flex md:pl-2 md:justify-end grow">
              {manager && (
                <Button
                  label={viewOtherWorkflows ? "Hide All Job Activity" : "Show Other Workflows"}
                  customClasses={` ${
                    viewOtherWorkflows ? "border-taskinatorRed text-taskinatorRed" : "border-taskinatorBlue text-taskinatorBlue"
                  }  mr-1 text-xs rounded h-8 px-2 border rounded hover:opacity-75 uppercase`}
                  onClick={() => setViewOtherWorkflows(!viewOtherWorkflows)}
                />
              )}

              {![process.env.REACT_APP_ACCESS_T, process.env.REACT_APP_ACCESS_G, process.env.REACT_APP_ACCESS_D].includes(appData.workspaceDataV4.role) && (
                <Button
                  label={"Add Another Drawing"}
                  customClasses={"border-taskinatorGreen bg-taskinatorGreen  text-taskinatorWhite hover:opacity-75  text-xs rounded h-8 px-2 border rounded  uppercase"}
                  onClick={() => setModal(true)}
                />
              )}
            </div>
          </div>
        )}

        {viewOtherWorkflows && (
          <div className={` ${jobsOnly ? "" : "mt-1 overflow-y-auto overflow-x-hidden h-full mb-2   rounded-lg border border-taskinatorMedGrey bg-taskinatorWhite"} `}>
            <JobAndWorkflow filter={""} jobView activityRange={"all"} filterTags={[tid]} isAll={false} activeTracerId={activeTracerId} legacy/>
          </div>
        )}
      </div>

      {modal && (
        <AddWorkflowItemHandler
          closeModal={() => {
            setModal();
            // getActiveTasks();
          }}
          tid={tid}
        />
      )}
    </>
  );
};

export default AllWorkflowDisplay;
