import React, { useState, useContext } from "react";
import { AppContext } from "../../../contexts/appContext";
import TaskCommentsNoteAdder from "./taskCommentAdder";
import FilePreview from "../../../utils/filePreview";
import LinkifyText from "../../../utils/linkifyText";

const TaskCommentBubble = ({ comment, refresh, taskId, jobId, setComments, isCompleted }) => {
  const appData = useContext(AppContext);
  const [editMode, setEditMode] = useState(false);

  if (!editMode) {
    return (
      <div className=" w-full text-taskiantorDarkGrey bg-taskinatorPaper rounded p-2 flex-col ">
        <div className="flex items-center">
          {<LinkifyText text={comment?.comment} />}{" "}
          {!isCompleted && comment?.createdBy?._id?.toString() === appData.workspaceDataV4.user_id && (
            <div onClick={() => setEditMode(true)} className=" underline text-taskinatorBlue pl-2 text-xxs cursor-pointer hover:opacity-75">
              Edit
            </div>
          )}
        </div>
        <div className={` ${comment?.attachments?.length === 0 ? "hidden" : "flex"}  pt-2`}>
          {comment?.attachments?.map((attachment) => {
            return <FilePreview file={attachment} typeOverride="task" showName viewMarkupsOnly small />;
          })}
        </div>
      </div>
    );
  } else {
    return (
      <div className=" w-full-taskinatorMedGrey text-taskiantorDarkGrey   flex-col ">
        <TaskCommentsNoteAdder
          taskId={taskId}
          jobId={jobId}
          refresh={(newComment) => {
            setComments((comments) => {
              return comments.map((comment) => {
                if (comment._id === newComment._id) {
                  return newComment;
                } else {
                  return comment;
                }
              });
            });
            setEditMode();
          }}
          defaultData={comment}
          isEdit
          setEditMode={() => setEditMode()}
          isCompleted={isCompleted}
        />
      </div>
    );
  }
};

export default TaskCommentBubble;
