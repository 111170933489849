import React, { useState, useEffect } from "react";
import IconSpinner from "../../components/UI_elements/iconSpinner";
import { useInputV4 } from "../../stateManagement/useInputV4";
import CheckboxV4 from "../../components/inputs/checkbox_V4";
import Button from "../../components/UI_elements/button";
import { useInit } from "../../components/hooks/useInit";
import axios from "axios";

const DrawingLogSettings = ({ settings }) => {
  const { initState, initStarted, initCompleted, initErrors, initReset } = useInit(3);

  const [incomingState, setIncomingState] = useState({
    inputs: {
      addStamp: { value: settings?.addStamp ? settings.addStamp : false, isValid: true },
      addWaterMarks: { value: settings?.addWaterMarks ? settings.addWaterMarks : false, isValid: true },
      addQRCode: { value: settings?.addQRCode ? settings.addQRCode : false, isValid: true },
    },
  });

  const { inputState, inputHandler } = useInputV4(incomingState, true);
  const liveInputs = JSON.stringify(inputState.inputs);
  const initialStateValues = JSON.stringify(incomingState.inputs);

  const hasChanged = liveInputs !== initialStateValues;

  useEffect(() => {
    initReset(0);
  }, [inputState]);

  const saveHandler = () => {
    initStarted(0);
    axios
      .put(process.env.REACT_APP_BACKEND_URL + `/api/items/updateDrawingSettings`, inputState.inputs, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      .then((responseData) => {
        setIncomingState({ inputs: inputState.inputs });
        initCompleted(0);
      })
      .catch((err) => {
        console.log(err);
        return initErrors(err?.response?.data?.message ?? err?.message ?? "", 0);
      });
  };

  return (
    <div>
      <div className="font-semibold text-sm">Drawing Log Settings</div>
      <div className="pt-2 text-sm border-t border-taskinatorMedGrey mt-2">
        <CheckboxV4
          sid="inputs"
          id="addWaterMarks"
          onInput={inputHandler}
          label="Add Watermark to drawing"
          defaultValue={inputState.inputs.addWaterMarks.value}
          customeLabel={"text-sm"}
          customCheckbox={"h-4 w-4"}
          toolTip={"This adds the `Draft` or `Released` water marks to drawings when adding them to the system"}
        />{" "}
      </div>
      <div className="pt-2">
        <CheckboxV4
          sid="inputs"
          id="addQRCode"
          onInput={inputHandler}
          label="Add QR code to drawings"
          defaultValue={inputState.inputs.addQRCode.value}
          customeLabel={"text-sm"}
          customCheckbox={"h-4 w-4"}
          toolTip={"This adds the QR code to drawings when adding to the system"}
        />{" "}
      </div>
      {hasChanged && !initState[0].finished && (
        <div className="pt-4">
          <Button label={"Save Changes"} customClasses={"bg-taskinatorBlue h-8 py-2 text-taskinatorWhite text-xs"} onClick={() => saveHandler()} isLoading={initState[0].started} />
        </div>
      )}
      {initState[0].finished && <div className="pt-2 text-xs text-taskinatorGreen">Saved</div>}
    </div>
  );
};

export default DrawingLogSettings;
