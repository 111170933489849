import axios from "axios";
import React, { useEffect, useState } from "react";
import IconSpinner from "../UI_elements/iconSpinner";
import { useInit } from "../hooks/useInit";
import MultiSelectDropdown from "../inputs/multiSelectDropdown";

const SharedOrganisationSelectors = ({
  inputVer,
  inputHandler,
  defaultValue,
  sid,
  disabled,
  selectionDisplayText,
  label,
  id,
  searchPlaceHolder,
  notRequired,
  modal,
  inline,
  AddButtonText,
}) => {
  const { initState, initStarted, initCompleted, initErrors, initReset } =
    useInit();
  const [sharedOid, setSharedOid] = useState();

  useEffect(() => {
    const sendRequest = () => {
      initStarted(0);
      axios
        .get(
          process.env.REACT_APP_BACKEND_URL + `/api/options/document/shared`,

          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        )
        .then((response) => {
          setSharedOid(response.data.shareDocumentsWith);

          initCompleted(0);
        })
        .catch((err) => {
          console.log(err);
          return initErrors(
            err?.response?.data?.message ?? err?.message ?? "",
            0
          );
        });
    };

    sendRequest();
  }, [id]);

  if (sharedOid && defaultValue) {
    return (
      <div className="pt-2">
        <MultiSelectDropdown
          color={
            "border-taskinatorMedGrey text-taskinatorDarkGrey font-semibold"
          }
          selectionColor={"text-taskinatorBlue uppercase"}
          label={label}
          id={id}
          searchPlaceHolder={searchPlaceHolder}
          type="SEARCH"
          sid={sid}
          defaultValue={defaultValue}
          options={sharedOid.map((shared) => {
            return {
              type: "",
              value: shared._id,
              name: shared.name,
              data: shared,
            };
          })}
          onSelection={inputHandler}
          disabled={disabled}
          selectionDisplayText={selectionDisplayText}
          notRequired={notRequired ? notRequired : false}
          inputVer={inputVer}
          modal={modal}
          inline={inline}
          AddButtonText={AddButtonText}
          dontAutoSelect
        />
      </div>
    );
  } else {
    if (initState[0].started) {
      return (
        <div>
          <div className="text-sm h-5">{label}</div>
          <div className="flex text-xs w-full items-center border h-8 rounded pl-2 my-2 border-taskinatorMedGrey">
            <IconSpinner />{" "}
            <div className="capitalize ml-2"> {`Loading...`}</div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
};

export default SharedOrganisationSelectors;
