import axios from "axios";
import React, { useEffect, useState } from "react";
import { MdHelp, MdUpdate } from "react-icons/md";
import { useParams } from "react-router-dom";
import Button from "../../components/UI_elements/button";
import DocumentDisplay from "../../components/UI_elements/documentDisplay";
import HeaderTitle from "../../components/UI_elements/headerTitle";
import IconSpinner from "../../components/UI_elements/iconSpinner";
import ItemStatus from "../../components/UI_elements/itemStatus";
import Modal from "../../components/UI_elements/modal";
import StringShortenDisplay from "../../components/UI_elements/stringShortenDisplay";
import UserDisplayBubble from "../../components/UI_elements/userDisplayBubble";
import { VALIDATOR_MINLENGTH } from "../../components/forms/validators/validators";
import { useInit } from "../../components/hooks/useInit";
import PDFAttachV4 from "../../components/inputs/filePDFAttach_V4";
import InputV4 from "../../components/inputs/input_V4";
import { useInputV4 } from "../../stateManagement/useInputV4";
import AdobePdfViewer from "../../utils/adobePdfViewer";
import AwsUploader from "../../utils/awsUploader";

const ThirdPartyAccess = ({ simpleView }) => {
  const { accessCode, accessCode2, id, accessCode3 } = useParams();
  const { initState, initStarted, initCompleted, initErrors } = useInit(3);
  const [tracer, setTracer] = useState();
  const [signedURL, setSignedURL] = useState();
  const [approveReject, setApproveReject] = useState({});
  const [savedMarkup, setSavedMarkup] = useState();
  const [savedMarkupURL, setSavedMarkupURL] = useState();
  const [resentSaved, setResentSaved] = useState();
  const [submitted, setSubmitted] = useState();
  const [annotationAdded, setAnnotationAdded] = useState();
  const [thirdPartyUser, setThirdPartyUser] = useState();
  const [guide, setGuide] = useState();
  const [loadedDocument, setLoadedDocument] = useState();
  const [documentLoaded, setDocumentLoaded] = useState();
  const [completedCount, setCompletedCount] = useState(0);
  const [thirdPartyAnnotations, setThirdPartyAnnotations] = useState();
  const { inputState, inputHandler, attachmentHandler } = useInputV4({ inputs: {}, attachments: {} }, false);

  const revData = tracer && tracer.itemsOnTracer?._id ? (tracer.itemsOnTracer?.draft?._id ? tracer.itemsOnTracer.draft : tracer.itemsOnTracer.released) : null;
  const item = tracer && tracer.itemsOnTracer && tracer.itemsOnTracer._id && tracer.itemsOnTracer;

  const getData = () => {
    initStarted(0);
    axios
      .get(process.env.REACT_APP_BACKEND_URL + `/api/tp/${accessCode}/${accessCode2}/${id}/${accessCode3}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setThirdPartyAnnotations(response.data.currentAnnotations);
        setThirdPartyUser(response.data.thirdPartyUser);
        setTracer(response.data.tracer);
        setSignedURL(response.data.signedURL);
        initCompleted(0);
      })
      .catch((err) => {
        console.log(err);
        return initErrors(err?.response?.data?.message ?? err?.message ?? "", 0);
      });
  };

  const handleDownload = (markedUpPdf) => {
    // Example PDF data; replace this with your actual PDF data

    const mimeType = "application/pdf";
    const blob = new Blob([markedUpPdf], { type: mimeType });
    const fileURL = URL.createObjectURL(blob);

    const downloadLink = document.createElement("a");
    downloadLink.href = fileURL;
    downloadLink.download = markedUpPdf.name; // Set your desired PDF filename here
    document.body.appendChild(downloadLink);
    downloadLink.click();

    URL.revokeObjectURL(fileURL);
    document.body.removeChild(downloadLink);
  };

  const SubmitHandler = async () => {
    initStarted(1);

    try {
      let attachments = [];
      let attachmentArray = [...Object.values(inputState.attachments), { value: savedMarkup, type: "markup" }].filter((data) => data.value);

      if (attachmentArray.length > 0) {
        attachments = await AwsUploader(attachmentArray, setCompletedCount, true, { accessCode, accessCode2, id, accessCode3 });
      }

      await axios.put(
        process.env.REACT_APP_BACKEND_URL + `/api/tp/submit/${accessCode}/${accessCode2}/${id}/${accessCode3}`,
        {
          attachments: attachments.filter((attachment) => attachment.type !== "markup"),
          // markup: attachments.filter((attachment) => attachment.type === "markup"),
          comments: inputState.inputs.comments.value,
          outcome: approveReject,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (savedMarkup) {
        handleDownload(savedMarkup);
      }
      setSubmitted(true);
      initCompleted(1);
    } catch (err) {
      console.log(err);
      return initErrors(err?.response?.data?.message ?? err?.message ?? "", 1);
    }
  };

  const thirdPartySendAnnotations = async (annotationData, event) => {
    initStarted(2);

    try {
      if (event === "ANNOTATION_DELETED") {
        await axios.put(
          process.env.REACT_APP_BACKEND_URL + `/api/tp/deleteAnnotation/${accessCode}/${accessCode2}/${id}/${accessCode3}`,
          {
            annotation: annotationData.map((data) => {
              return { ...data, thirdParty: true };
            }),
            attachmentKey: tracer?.originalAttachment.key,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
      } else {
        await axios.put(
          process.env.REACT_APP_BACKEND_URL + `/api/tp/updateAnnotation/${accessCode}/${accessCode2}/${id}/${accessCode3}`,
          {
            annotation: annotationData.map((data) => {
              return { ...data, thirdParty: true };
            }),
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
      }

      initCompleted(2);
    } catch (err) {
      console.log(err);
      return initErrors(err?.response?.data?.message ?? err?.message ?? "", 1);
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessCode]);

  useEffect(() => {
    if (savedMarkup) {
      setResentSaved(true);

      const interval = setInterval(() => {
        setResentSaved(false);
      }, 500);
      // Cleanup function to clear the interval when the component is unmounted
      return () => clearInterval(interval);
    }
  }, [savedMarkup]);

  useEffect(() => {
    if (tracer) {
      const shouldSendRequest = tracer.seen.filter((seen) => seen._id.toString() === thirdPartyUser._id.toString()).length === 0;

      if (shouldSendRequest) {
        const delay = 1000;

        const timer = setTimeout(() => {
          axios
            .get(process.env.REACT_APP_BACKEND_URL + `/api/tp/markAsSeen/${accessCode}/${accessCode2}/${id}/${accessCode3}`, {
              headers: {
                "Content-Type": "application/json",
              },
              withCredentials: true,
            })
            .then((response) => {})
            .catch((err) => {
              console.log(err);
            });
        }, delay);

        return () => {
          clearTimeout(timer);
        };
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tracer]);

  return (
    <div className="flex flex-col w-screen h-screen overflow-auto">
      {/* <div className="w-full h-16 bg-taskinatorBlue text-taskinatorWhite flex items-center px-4 text-2xl">
       
      </div> */}
      <div className="w-full grow items-center justify-center">
        {initState[0].started && !initState[0].initialised && (
          <div className="w-full h-full flex justify-center items-center">
            <IconSpinner size="m" /> Loading Data...
          </div>
        )}
        {((initState[0].initialised && !tracer) || initState[0].error) && (
          <div className="w-full h-full flex items-center justify-center  bg-taskinatorDarkGrey">
            <div className="flex flex-col  text-lg uppercase items-center  justify-center mt-5">
              <b className="order-taskinatorWhite  mb-2 border-b border-b-taskinatorWhite">
                {" "}
                <HeaderTitle home customCSS="text-taskinatorBlue text-4xl" />
              </b>{" "}
              <div className="text-base text-taskinatorWhite">
                This Link Has Expired -
                <a href={`${process.env.REACT_APP_FRONTEND_URL}/login`} className="underline text-taskinatorBlue px-1">
                  Sign In/Sign Up
                </a>
                To View
              </div>
              <div></div>
            </div>
          </div>
        )}
        {initState[0].initialised && tracer && (
          <div className={` h-full overflow-auto flex  text-sm `}>
            <div
              className={` text-sm pr-2 pl-2 text-taskinatorWhite flex  bg-taskinatorDarkGrey  w-96 shrink-0 md:py-0 py-2 flex-col h-full  overflow-auto scrollbar
               `}
            >
              <div className={`flex flex-col w-full h-full  overflow-auto  `}>
                <div className="flex justify-center ">
                  <div className="flex flex-col  text-lg uppercase items-center  justify-center mt-5">
                    <b className="order-taskinatorWhite  mb-2 border-b border-b-taskiantorWhite">
                      {" "}
                      <HeaderTitle home customCSS="text-taskinatorBlue text-2xl" />
                    </b>{" "}
                    {StringShortenDisplay(30, tracer.aid.name === "Set Actions" ? "Set Workflow" : tracer.aid.name)}{" "}
                  </div>
                </div>
                <div className={`flex  `}>
                  <div className={`flex grow ${simpleView ? "py-2" : "items-center justify-center lg:py-0 py-2 "} `}>
                    <div className="pr-1 text-xs font-light text-taskinatorWhite  flex whitespace-nowrap">Submitted To:</div>

                    <div className="flex">
                      {/* {tracer.assignee.map((user, index) => {
                        if (index < 2)
                          return ( */}

                      <UserDisplayBubble textSize={`text-xs `} user={tracer.assignee} short={true} />

                      {/* ); */}
                      {/* })} */}
                    </div>
                  </div>
                </div>

                <div className="h-full overflow-auto">
                  <div className="w-full px-2 py-2 rounded-lg mt-5 bg-taskinatorLightGrey text-taskinatorDarkGrey justify-center flex flex-col text-md">
                    <div className="font-semibold">Drawing Details:</div>
                    {item._id && (
                      <div className="border rounded-md py-1 px-2 border-taskinatorMedGrey bg-taskinatorItemBlue">
                        <div className={`flex flex-col font-medium pr-2 w-full`}>
                          <div className="flex border-b border-b-taskinatorLightGrey pb-0.5 mb-0.5">
                            {item?.draft?._id && (
                              <div className={`flex text-taskinatorOrange pr-1 border-r border-r-taskinatorMedGrey mr-1`}>
                                <MdUpdate size={"1rem"} />
                              </div>
                            )}
                            <div className="">{item.currentItemDetails.itemNumber}</div>
                            <div className="px-2">-</div>
                            <div className="uppercase w-fit whitespace-nowrap">{StringShortenDisplay(40, item.currentItemDetails.itemName)}</div>
                          </div>
                          <div className="flex pt-1">
                            <div className="pr-2">
                              <ItemStatus status={revData.status} />
                            </div>
                            <div className="pr-2">Revision: </div>
                            <div className="font-semibold">{revData.revision}</div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="w-full text-md px-2 py-2 rounded-lg mt-1 bg-taskinatorLightGrey text-taskinatorDarkGrey justify-center flex flex-col">
                    <div className="font-semibold">Special Notes/Comments:</div>
                    <div className="border rounded-md py-1 px-2 border-taskinatorMedGrey bg-taskinatorWhite">
                      <div className="flex border-b border-b-taskinatorLightGrey py-1 mb-0.5">{tracer.comments.comments}</div>
                      <div className="font-semibold py-2">
                        {tracer.comments.attachments.length > 0 ? (
                          tracer.comments.attachments.map((attachment) => {
                            return (
                              <div className="w-fit text-xs pr-1">
                                <DocumentDisplay attachment={attachment} thirdParty />
                              </div>
                            );
                          })
                        ) : (
                          <div className="text-taskinatorMedGrey whitespace-nowrap md:text-sm">No Attachments</div>
                        )}
                      </div>
                    </div>
                  </div>
                  {!submitted && (
                    <div className="w-full border my-1 bg-taskinatorLightGrey p-2 rounded-lg flex flex-col grow overflow-auto text-taskinatorDarkGrey">
                      {tracer.nextSteps > 0 && (
                        <div className="pt-1">
                          <Button
                            onClick={() => setApproveReject(1)}
                            customClasses={`uppercase text-base border border-taskinatorGreen ${
                              approveReject === 1 ? "bg-taskinatorGreen text-taskinatorWhite" : "text-taskinatorGreen hover:bg-taskinatorGreen hover:text-taskinatorWhite"
                            } h-10 grow  `}
                            label={"Approve"}
                          />
                        </div>
                      )}

                      {tracer.backSteps > 0 && (
                        <div className="pt-1">
                          <Button
                            onClick={() => setApproveReject(0)}
                            customClasses={`uppercase  text-base border  border-taskinatorRed ${
                              approveReject === 0 ? "bg-taskinatorRed text-taskinatorWhite" : "hover:text-taskinatorWhite text-taskinatorRed hover:bg-taskinatorRed"
                            }  h-10 w-full`}
                            label={"Reject"}
                          />
                        </div>
                      )}

                      <div className="pt-2">
                        <InputV4
                          sid={"inputs"}
                          inputVer={"1"}
                          id="comments"
                          validityMessage={"Minimum 6 chars required"}
                          validators={[VALIDATOR_MINLENGTH(3)]}
                          onInput={inputHandler}
                          textareaRows={10}
                          placeholder={"Enter any details that might be helpful or members should be aware of in the next task..."}
                          label="Submission Comments"
                        />
                      </div>

                      <div className="pt-2 text-taskinatorDarkGrey">
                        <PDFAttachV4 label="Submission Attachments" attachmentHandler={attachmentHandler} id={"attachments"} />
                      </div>

                      {savedMarkup && (
                        <div className="flex mt-2 w-full border border-taskinatorGreen h-12 text-taskinatorGreen items-center justify-center bg-taskinatorGreenHover rounded py-1 px-2">
                          <div className="uppercase font-medium text-center">{savedMarkup.name}</div>
                          {resentSaved && (
                            <div className="flex grow justify-end text-taskinatorGreen">
                              Updated <IconSpinner />
                            </div>
                          )}
                        </div>
                      )}

                      <Button
                        label={`Submit`}
                        customClasses={`${
                          inputState.isValid && [0, 1].includes(approveReject) && !annotationAdded ? "bg-taskinatorBlue" : "bg-taskinatorMedGrey"
                        } bg-taskinatorBlue text-base text-taskinatorWhite h-10 w-full mt-4`}
                        disabled={!inputState.isValid || ![0, 1].includes(approveReject) || annotationAdded}
                        isLoading={initState[1].started}
                        error={initState[1].error}
                        onClick={() => SubmitHandler()}
                      />
                      {![0, 1].includes(approveReject) && <div className="text-center text-taskinatorRed">Must Select Accept Or Reject</div>}
                      {annotationAdded && <div className="text-center text-taskinatorRed">Save PDF Markups</div>}
                    </div>
                  )}
                  {submitted && (
                    <div className="w-full border my-1 bg-taskinatorLightGrey p-2 rounded-lg flex flex-col grow overflow-auto text-taskinatorDarkGrey text-center">
                      Thank Your For Your Submission. A confirmation email with the summary will be emailed to your shortly.
                    </div>
                  )}
                  <div className="pt-5">
                    <Button
                      label={
                        <div className="flex items-center justify-center">
                          <div className="pr-2">
                            <MdHelp />
                          </div>
                          Your Guide
                        </div>
                      }
                      customClasses={"bg-taskinatorBlue rounded py-2 px-2 w-full text-base"}
                      onClick={() => setGuide(true)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="border-taskinatorMedGrey bg-taskinatorLightGrey border rounded-lg flex grow justify-center items-center sticky top-0">
              {(signedURL || savedMarkupURL) && !submitted ? (
                <AdobePdfViewer
                  pdfURL={signedURL}
                  userName={thirdPartyUser}
                  pdfFileName={`${tracer.itemsOnTracer.currentItemDetails.itemName}.pdf`}
                  attachmentKey={tracer?.originalAttachment.key}
                  iid={tracer.itemsOnTracer._id}
                  setSavedMarkup={setSavedMarkup}
                  setSavedMarkupURL={setSavedMarkupURL}
                  savedMarkupURL={savedMarkupURL}
                  setAnnotationAdded={setAnnotationAdded}
                  setDocumentLoaded={setDocumentLoaded}
                  setLoadedDocument={setLoadedDocument}
                  thirdPartySendAnnotations={thirdPartySendAnnotations}
                  thirdPartyAnnotations={thirdPartyAnnotations}
                />
              ) : initState[0].initialised ? (
                <div className="text-base text-taskinatorRed">{submitted ? "Submitted" : "No Attachments For Review"}</div>
              ) : (
                <IconSpinner size="l" />
              )}
            </div>
          </div>
        )}
      </div>
      {guide && (
        <div>
          <Modal local={() => setGuide()} size={"large"}>
            <div className="h-full">
              <iframe src={process.env.REACT_APP_THIRD_PARTY_GUIDE_VIDEO_LINK} frameBorder="0" style={{ top: 0, left: 0, height: "100%", width: "100%" }} title="guideVideo"></iframe>
            </div>
          </Modal>
        </div>
      )}
    </div>
  );
};

export default ThirdPartyAccess;
