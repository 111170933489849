import axios from "axios";

const AwsUploader = async (
  attachments,
  setCompletedCount,
  thirdParty,
  params
) => {
  const { accessCode, accessCode2, id, accessCode3 } = params;

  let counter = 0;

  try {
    if (thirdParty && (!accessCode || !accessCode2 || !id)) {
      throw new Error("Not Authorised");
    }

    const url = thirdParty
      ? `/api/tp/attach/sign/${accessCode}/${accessCode2}/${id}/${accessCode3}`
      : `/api/attachment/attach/sign`;

    const results = await Promise.all(
      attachments.map(async (attachment) => {
        // Request a signed URL for each attachment

        const file = attachment.value;

        const signedAWSURLResponse = await axios.put(
          `${process.env.REACT_APP_BACKEND_URL}${url}`,
          { fileName: encodeURIComponent(file.name) },
          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: thirdParty ? false : true,
          }
        );

        const signedAWSURL = signedAWSURLResponse.data.uploadURL;

        if (!signedAWSURL) {
          throw new Error("no signed URL");
        }

        // Upload the attachment using the signed URL
        const options = {
          headers: {
            "Content-Type": file.type,
            "Content-Disposition": "inline",
          },
          //   onUploadProgress: (progressEvent) => {
          //     const { loaded, total } = progressEvent;
          //     const filePercentage = Math.floor((loaded * 100) / total);
          //     setPercentage(`${filePercentage}%`);
          //   },
        };

        await axios.put(signedAWSURL, file, options);

        counter++;
        setCompletedCount(counter);

        return {
          key: signedAWSURLResponse.data.key,
          fileName: signedAWSURLResponse.data.fileName,
          type: attachment.type,
        };
      })
    );

    return results;
    // Handle successful uploads
  } catch (err) {
    // Handle any errors
    console.error(err);
  }
};

export default AwsUploader;
