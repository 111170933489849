import React from "react";

import HeaderTitle from "../components/UI_elements/headerTitle";

const LoginScreen = (props) => {
  return (
    <div className=" flex items-center  h-screen w-full justify-center  opacity-100 bg-taskinatorDarkGrey  overflow-hidden">
      <div className="w-full sm:max-w-md  lg:shadow-lg sm:h-fit my-auto  justify-center  sm:items-center flex px-8 py-4  bg-taskinatorLightGrey sm:rounded-lg ">
        <div className="  w-full  h-full items-center sm:justify-center flex grow flex-col    ">
          <div className="items-center justify-center w-full my-4 flex flex-col flex ">
            <HeaderTitle customCSS={"text-taskinatorDarkGrey justify-center flex text-4xl w-full"} largeOnly />
            {/* <div className="text-xs font-semibold text-taskinatorDarkGrey text-center">Effortlessly Managing Tasks And Document Version Control For You.</div> */}
            <div className="text-xs text-taskinatorMedGrey "></div>
          </div>

          {props.children}

          <div className="text-center text-xs text-taskinatorMedGrey pt-4">{`© Taskinator Pty Ltd ${new Date().getFullYear(new Date())}. All rights reserved`} </div>
        </div>
      </div>
      {/* 
      <div className="items-center justify-center w-full hidden lg:flex flex-col">
        <HeaderTitle customCSS={"text-taskinatorBlue justify-center flex text-6xl w-full"} largeOnly />
        <div className="text-lg text-taskinatorMedGrey">Effortlessly Managing Tasks And Document Version Control For You.</div>
      </div> */}
    </div>
  );
};

export default LoginScreen;
