import React, { useState, useEffect } from "react";
import ToolTip from "../../utils/toolTip";
import { MdSearch, MdPlayArrow } from "react-icons/md";

const checkDefaultValue = (defaultValue, options) => {
  let startingValue;

  if (defaultValue !== null && defaultValue !== undefined && defaultValue.length > 0) {
    const defaultIndex = options.findIndex((data) => data.value.toLowerCase() === defaultValue.toLowerCase());

    if (defaultIndex >= 0) {
      startingValue = defaultIndex;
    }
  } else {
    const defaultIndex = options.findIndex((data) => data.value === defaultValue);

    if (defaultIndex >= 0) {
      startingValue = defaultIndex;
    }
  }

  return startingValue;
};

const DropDownSelector_V4 = ({
  inputVer,
  defaultValue,
  label,
  disabled,
  options,
  notSearchable,
  onSelection,
  id,
  sid,
  color,
  selectionDisplayText,
  exclude,
  notRequired,
  customClasses,
  alwaysValid,
  toolTip,
}) => {
  // if (defaultValue !== null && defaultValue !== undefined && defaultValue.length > 0) {
  //   const defaultIndex = options.findIndex((data) => data.value.toLowerCase() === defaultValue.toLowerCase());

  //   if (defaultIndex >= 0) {
  //     startingValue = defaultIndex;
  //   }
  // } else {
  //   const defaultIndex = options.findIndex((data) => data.value === defaultValue);

  //   if (defaultIndex >= 0) {
  //     startingValue = defaultIndex;
  //   }
  // }

  const excludedValues = exclude ? exclude : [];

  const [selection, setSelection] = useState();
  const [dropDown, setDropDown] = useState();
  const [filterValue, setFilterValue] = useState("");

  const onClickHandler = () => {
    if (!disabled) {
      if (dropDown) {
        setDropDown();
      } else {
        setDropDown(true);
      }
    }
  };

  const onFilterInputChangeHandler = (event) => {
    setFilterValue(event.target.value);
  };

  const selectionHandler = (index) => {
    if (selection === index && notRequired) {
      setSelection();
      onClickHandler();
    }

    if (selection !== index) {
      setSelection(index);
      onClickHandler();
    }
  };

  useEffect(() => {
    if (defaultValue === "Varies" && !options[selection]) {
      onSelection(id, "Varies", true, sid);
    } else {
      if (options[selection]) {
        onSelection(
          id,
          options[selection].data ? options[selection].data : options[selection].value,
          options[selection].value || options[selection].value === 0 ? true : alwaysValid ? true : false,
          sid
        );
      } else {
        onSelection(id, null, notRequired ? true : false, sid);
      }
    }
  }, [selection]);

  useEffect(() => {
    const startingValue = checkDefaultValue(defaultValue, options);

    setSelection(startingValue);
  }, [defaultValue]);

  return (
    <div className={` ${customClasses}  grow relative`}>
      {label && (
        <div className={`text-sm h-5 flex`}>
          <div>{label}</div>
          <div className={` ${!notRequired ? "text-taskinatorRed" : "text-taskinatorMedGrey"} pl-1`}>{!notRequired ? " (Required)" : " (Optional)"}</div>
          {toolTip && <ToolTip content={toolTip} />}
        </div>
      )}
      <div
        className={`${inputVer === "1" ? "" : "bg-taskinatorLightGrey"} flex border rounded  ${color} w-full h-8 items-center capitalize  ${!disabled ? "cursor-pointer" : "cursor-not-allowed"} ${
          !disabled
            ? `hover:bg-taskinatorBlue hover:border-taskinatorBlue hover:text-taskinatorWhite ${
                options[selection] ? "border-taskinatorBlue border-1 font-semibold  text-taskinatorBlue" : "border-taskinatorDarkGrey"
              }`
            : ""
        }  `}
        onClick={onClickHandler}
      >
        <div className={`text-sm flex justify-center items-center text-taskinatorMedGrey h-6 w-6 ${dropDown ? "rotate-90" : ""}`}>
          <MdPlayArrow />
        </div>
        <div className={`text-xs  flex grow justify-center text-center `}>
          {options[selection] ? (
            <div>
              {options[selection].type && options[selection].type !== "Member" ? `${options[selection].type} - ` : ""}
              {options[selection].name}
            </div>
          ) : (
            `${selectionDisplayText}`
          )}
        </div>
      </div>
      <div className={`${dropDown ? "relative" : "hidden"} scrollbar grow w-full sticky top-0 z-50    bg-taskinatorWhite rounded border border-taskinatorMedGrey shadow-lg`}>
        {!notSearchable && (
          <div className="flex items-center mx-2 my-1 bg-taskinatorLightGrey rounded">
            <div className="mx-2 bg-taskinatorLightGrey py-1">
              <MdSearch />
            </div>
            <div className="w-full">
              <input onChange={onFilterInputChangeHandler} className="w-full bg-taskinatorLightGrey py-1 focus:outline-none text-base " placeholder={"Search"}></input>
            </div>
          </div>
        )}
        <div className="max-h-[10rem] overflow-y-auto scrollbar">
          {!options || (options && options.length === 0) ? (
            <div className="text-center text-taskinatorMedGrey text-xs py-1 ">No options available</div>
          ) : (
            options.map((data, index) => {
              return (
                <div
                  key={data.value}
                  className={` text-base md:text-xs  flex p-1 ${selection !== index ? "hover:bg-taskinatorHoverBlue cursor-pointer" : "bg-taskinatorBlue text-taskinatorWhite cursor-pointer"}
                    ${excludedValues.includes(data.value) ? "hidden" : filterValue ? (data.name.toLowerCase().includes(filterValue.toLowerCase()) ? "block" : "hidden") : "block"}
                    capitalize justify-start text-center`}
                  onClick={() => selectionHandler(index)}
                >
                  <div className="font-semibold mr-1">{data.type}</div>-<div className=" ml-1 capitalize">{data.name}</div>
                </div>
              );
            })
          )}
        </div>

        <div></div>
      </div>
      {dropDown && <div onClick={onClickHandler} className={`fixed inset-0 z-30 `}></div>}
    </div>
  );
};

export default DropDownSelector_V4;
