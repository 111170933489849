import React, { useState } from "react";
import Button from "../components/UI_elements/button";
import axios from "axios";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { StampPDF } from "../components/functions/mergeAndStampPDF";
import { useInit } from "../components/hooks/useInit";
import IconSpinner from "../components/UI_elements/iconSpinner";
import { PDFDocument } from "pdf-lib";
import Input_V4 from "../components/inputs/input_V4";

import Modal from "../components/UI_elements/modal";
import { Input } from "react-select/animated";

const BulkDownloader = ({ selectedFiles, fileType, fileNameData }) => {
  const [downloadAsOneName, setDownloadAsOneName] = useState("");
  const [modal, setModal] = useState();
  const [modal2, setModal2] = useState();
  const filesSelected = Object.values(selectedFiles ?? {}).length > 0;
  const allSelectedFilesArePDF = filesSelected && Object.values(selectedFiles).every((file) => file.fileExtension === ".pdf");
  const { initState, initStarted, initCompleted, initErrors, initReset } = useInit(2);

  const downloadHandler = async (type) => {
    const zip = new JSZip();
    initStarted(1, type);
    try {
      const pdfDocs = [];

      for (const file of Object.values(selectedFiles)) {
        if (type === "asOne" && file.fileExtension !== ".pdf") {
          throw new Error();
        }

        const responseToGetSignedURL = await axios.get(process.env.REACT_APP_BACKEND_URL + `/api/attachment/get/${file.key}/${fileType ?? "document"}`, {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        });

        const response = responseToGetSignedURL.data;
        let pdfDownloadResponse;

        switch (response.driveOption) {
          case "GOOGLE":
            break;

          case "MICROSOFT":
            pdfDownloadResponse = await axios.get(
              `https://graph.microsoft.com/v1.0/me/drive/items/${response.thirdPartyStorageId}/content`,
              // `https://graph.microsoft.com/v1.0/me/drive/root:/${encodeURIComponent(process.env.REACT_APP_EXTERNAL_STORAGE_FOLDER_NAME)}/${file.key}${file.fileExtension}:/content`,
              {
                responseType: "blob",
                headers: {
                  Authorization: `Bearer ${response.accessToken}`,
                },
              }
            );
            break;

          case "AWS":
            pdfDownloadResponse = await axios.get(responseToGetSignedURL.data.signedURL, {
              responseType: "blob",
            });

            break;
        }

        if (!pdfDownloadResponse.status === 200) {
          throw new Error("Error Downloading Files" + file.fileName);
        }

        let blobToUse;
        if (file.fileExtension === ".pdf" && !fileType) {
          blobToUse = await StampPDF(pdfDownloadResponse.data, file);
        } else {
          blobToUse = pdfDownloadResponse.data;
        }

        const arrayBuffer = await blobToUse.arrayBuffer();

        if (type === "asOne") {
          pdfDocs.push(arrayBuffer);
        } else {
          zip.file(`${decodeURIComponent(file.fileName)}${file.fileExtension}`, arrayBuffer);
        }
      }

      if (type === "asOne" && pdfDocs.length > 0) {
        // Merge PDFs
        const mergedPdf = await PDFDocument.create();

        for (const pdfBytes of pdfDocs) {
          const pdf = await PDFDocument.load(pdfBytes);
          const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
          copiedPages.forEach((page) => mergedPdf.addPage(page));
        }

        const mergedPdfBytes = await mergedPdf.save();
        const blob = new Blob([mergedPdfBytes], { type: "application/pdf" });
        saveAs(blob, `${downloadAsOneName ? `${downloadAsOneName}.pdf` : "merged.pdf"}`);
        setModal();
      } else {
        const content = await zip.generateAsync({ type: "blob" });
        saveAs(content, `${downloadAsOneName}.zip`);
        setModal2();
      }

      initCompleted(1, type);
    } catch (err) {
      console.log(err);
      initErrors(err?.response?.data?.message ?? err?.message ?? "", 1, type);
    }
  };

  if (filesSelected) {
    return (
      <>
        <div className="flex items-center ">
          {allSelectedFilesArePDF && (
            <div>
              {" "}
              {!initState[1].started ? (
                <Button
                  isLoading={initState[1].started && initState[1].identifier === "asOne"}
                  onClick={() => setModal(true)}
                  label={"Download As One"}
                  customClasses=" border-taskinatorBlue  underline text-taskinatorBlue py-2  w-full  "
                />
              ) : initState[1].identifier === "asOne" ? (
                <div className="text-xxs flex pl-2">
                  <IconSpinner />
                  Downloading...
                </div>
              ) : (
                <div></div>
              )}
            </div>
          )}

          {!initState[1].started ? (
            <Button
              isLoading={initState[1].started && initState[1].identifier === "individual"}
              onClick={() => setModal2(true)}
              label={"Download All"}
              customClasses=" border-taskinatorBlue  underline text-taskinatorBlue py-2  w-full  "
            />
          ) : initState[1].identifier === "individual" ? (
            <div className="text-xxs flex pl-2">
              <IconSpinner />
              Downloading...
            </div>
          ) : (
            <div></div>
          )}
        </div>
        {modal && (
          <Modal local={() => setModal()} title={<div className="text-base">Give Merged PDF a Name.</div>}>
            <div className="w-96 p-4 justify-center items-center ">
              <Input_V4
                onInput={(id, value, isValid, sid) => {
                  setDownloadAsOneName(value);
                }}
                validators={[]}
                id="name"
                sid="inputs"
                placeholder={"Enter Name For Merged PDF File"}
              />
              <Button
                label={"Download As Ones"}
                onClick={() => {
                  downloadHandler("asOne");
                }}
                disabled={downloadAsOneName.length === 0}
                isLoading={initState[1].started && initState[1].identifier === "asOne"}
                customClasses={`${downloadAsOneName.length === 0 ? "bg-taskinatorMedGrey" : "bg-taskinatorblue"} bg-taskinatorBlue text-taskinatorWhite h-10 w-full mt-2 text-sm`}
              />
            </div>
          </Modal>
        )}
        {modal2 && (
          <Modal local={() => setModal2()} title={<div className="text-base">Name Zip Folder.</div>}>
            <div className="w-96 p-4 justify-center items-center ">
              <Input_V4
                onInput={(id, value, isValid, sid) => {
                  setDownloadAsOneName(value);
                }}
                defaultValue={fileNameData ?? "downloaded Files"}
                validators={[]}
                id="name"
                sid="inputs"
                placeholder={"Enter Name For Merged PDF File"}
              />
              <Button
                label={"Download All Now"}
                onClick={() => {
                  downloadHandler("individual");
                }}
                disabled={downloadAsOneName.length === 0}
                isLoading={initState[1].started && initState[1].identifier === "individual"}
                customClasses={`${downloadAsOneName.length === 0 ? "bg-taskinatorMedGrey" : "bg-taskinatorblue"} bg-taskinatorBlue text-taskinatorWhite h-10 w-full mt-2 text-sm`}
              />
            </div>
          </Modal>
        )}
      </>
    );
  }
};

export default BulkDownloader;
