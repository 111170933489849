import React, { useContext, useEffect, useState } from "react";

import axios from "axios";

import { AppContext } from "../../../contexts/appContext";
import { RequestContext } from "../../../contexts/requestContext";
import IconSpinner from "../../UI_elements/iconSpinner";
import RequestForm from "../../forms/requestForm";
import { useInit } from "../../hooks/useInit";
import ViewRequest from "../requests/viewRequest";
import Button from "../../UI_elements/button";
import JobModalDropDownContainer from "../jobs/jobModalDropDownContainer";

const JobQuestions = ({ tid, simpleView, tracer }) => {
  const [taskRequest, setTaskRequests] = useState({});

  const group = "task";

  const { initState, initStarted, initCompleted, initErrors, initReset } = useInit();

  const appData = useContext(AppContext);
  const [requestModal, setRequestModal] = useState();

  const getRequests = () => {
    initStarted(0);
    axios
      .get(process.env.REACT_APP_BACKEND_URL + `/api/requests/retrieve/task/${tid}`, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      .then((response) => {
        setTaskRequests(response.data.data);

        initCompleted(0);
      })
      .catch((err) => {
        console.log(err);
        return initErrors(err?.response?.data?.message ?? err?.message ?? "", 0);
      });
  };

  useEffect(() => { 
    getRequests();
  }, [tid]);

  appData.socket.on("questionUpdate", (data) => {
    if (data.action === "add" && data.newQuestion.job._id.toString() === tid) {
      setTaskRequests({ ...taskRequest, [data.newQuestion._id]: data.newQuestion });
    } else {
    }
  });

  const numberOfTaskRequests = Object.values(taskRequest ?? {})?.filter((tasks) => !tasks?.completed)?.length;
  return (
    <RequestContext.Provider
      value={{
        getRequests: getRequests,
      }}
    >
      <JobModalDropDownContainer
        headerContent={
          <div className="flex grow">
            <div className="flex items-center">
              <div className="flex text-base uppercase items-center text-center w-full justify-start font-semibold"> Questions</div>
              {numberOfTaskRequests > 0 && (
                <div className="relative  ml-2 items-center justify-center flex w-fit h-auto text-taskinatorWhite  bg-taskinatorRed px-2 rounded-full font-semibold text-sm">
                  {numberOfTaskRequests}
                </div>
              )}
            </div>{" "}
            <div className="flex grow justify-end">
              {" "}
              <div
                className=""
                onClick={(e) => {
                  e.stopPropagation();
                }}
              ></div>
            </div>
          </div>
        }
        borderColour={"border-taskinatorMedGrey"}
        textColour={"text-taskinatorOrange"}
        backgroundColour={"bg-taskinatorHoverOrange border-taskinatorMedGrey border hover:bg-taskinatorHoverOrange"}
      >
        <div className={` w-full shrink-0 flex flex-col mt-1 xl:mt-0 overflow-auto`}>
          <div className="bg-taskinatorOffWhite p-1 flex-col grow flex mt-1 rounded-lg overflow-auto">
            <div className="flex flex-col">
              {initState[0].started && !initState[0].initialised && (
                <div className=" my-4">
                  <IconSpinner size="m" />
                </div>
              )}
              {initState[0].initialised && !taskRequest && <div className="text-sm text-center pt-2 text-taskinatorDarkGrey">No Active Requests</div>}
              {initState[0].error && <div className="text-sm text-center pt-2 text-taskinatorRed">Error Getting Questions</div>}

              {!simpleView &&
                initState[0].initialised &&
                taskRequest &&
                Object.values(taskRequest)
                  .filter((request) => !request.completed)
                  .map((request, index) => {
                    return <ViewRequest key={request._id} request={request} group={group} getData={() => {}} />;
                  })}

              <div className="text-[0.6rem] mt-1"></div>

              {!simpleView &&
                initState[0].initialised &&
                taskRequest &&
                Object.values(taskRequest)
                  .filter((request) => request.completed)
                  .map((request) => {
                    return <ViewRequest key={request._id} request={request} group={group} getData={() => {}} />;
                  })}
            </div>
          </div>

          <div className="flex grow justify-end mr-1.5 -mt-2">
            <Button
              label={"Add Question"}
              customClasses={` text-taskinatorOrange border-taskinatorOrange border rounded px-2 py-1 text-xs uppercase h-8 w-full`}
              onClick={() => setRequestModal(true)}
            />
          </div>
        </div>
      </JobModalDropDownContainer>

      {requestModal && (
        <div>
          <RequestForm
            tid={tid}
            tracer={tracer}
            onClose={() => setRequestModal()}
            onSuccess={() => {
              //   getRequests();
            }}
          />
        </div>
      )}
    </RequestContext.Provider>
  );
};

export default JobQuestions;
