import React from "react";
import Button from "../../UI_elements/button";
import axios from "axios";
import { useInit } from "../../hooks/useInit";

const RemoveDraftForm = ({ item, onSuccess }) => {
  const { initState, initStarted, initCompleted, initErrors, initReset } = useInit();

  const submitHandler = () => {
    initStarted(0);
    axios

      .put(
        process.env.REACT_APP_BACKEND_URL + `/api/items/removedraft/${item.iid}`,
        {
          rev_id: item._id,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      .then((responseData) => {
        onSuccess();
        initCompleted(0);
      })
      .catch((err) => {
        console.log(err);
        initErrors(err?.response?.data?.message ?? err?.message ?? '', 0);
      });
  };

  return (
    <div className="flex items-center justify-center flex-col">
      <div className="text-taskinatorRed font-semibold py-1">Warning! This Cannot Be Undone</div>
      <Button label="Remove Active Draft" customClasses={"border-taskinatorRed border h-8 text-sm px-2 text-taskinatorRed"} onClick={() => submitHandler()} isLoading={initState[0].started} />
    </div>
  );
};

export default RemoveDraftForm;
