import React from "react";
import { useNavigate } from "react-router-dom";

const HeaderTitle = ({ customCSS, largeOnly, home }) => {
  const navigate = useNavigate();
  return (
    <div
      className={`${customCSS ? customCSS : "text-taskinatorBlue"} flex  w-full  cursor-pointer `}
      onClick={home ? () => (window.location.href = `${process.env.REACT_APP_WEBSITE}`) : () => navigate("/")}
    >
      <div className=""> NEXT</div>
      <div className={`lg:block ${largeOnly ? "" : "block"}  `}>-</div>
      <div className=" font-bold">TASK</div>
    </div>
  );
};

export default HeaderTitle;
