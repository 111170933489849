exports.AreArraysSame = (arr1, arr2) => {
    // Check if arrays have different lengths
    if (arr1.length !== arr2.length) {
      return false;
    }
  
    // Check if every element in both arrays has an _id
    if (!arr1.every(obj => obj && obj._id) || !arr2.every(obj => obj && obj._id)) {
      return false; // Return false if any element lacks an _id
    }
  
    // Create maps to store objects by their _id
    const map1 = new Map(arr1.map(obj => [obj._id, obj]));
    const map2 = new Map(arr2.map(obj => [obj._id, obj]));
  
    // Check if sets of _id keys are the same
    const keys1 = new Set(arr1.map(obj => obj._id));
    const keys2 = new Set(arr2.map(obj => obj._id));
    if (keys1.size !== keys2.size || ![...keys1].every(key => keys2.has(key))) {
      return false;
    }
  
    // Check if each object in arr1 exists in arr2 and has the same properties
    for (const [id, obj1] of map1) {
      const obj2 = map2.get(id);
      if (!obj2 || !objectsAreEqual(obj1, obj2)) {
        return false;
      }
    }
  
    // If all checks pass, return true (arrays are the same)
    return true;
  }
  
  function objectsAreEqual(obj1, obj2) {
    // Compare properties of obj1 and obj2
    const allKeys = new Set([...Object.keys(obj1), ...Object.keys(obj2)]);
    for (const key of allKeys) {
      if (obj1.hasOwnProperty(key) && obj2.hasOwnProperty(key)) {
        if (obj1[key] !== obj2[key]) {
          return false;
        }
      } else {
        // If one of the objects does not have the key, return false
        return false;
      }
    }
    // If all properties are the same, return true
    return true;
  }
  