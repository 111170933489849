import React, { useEffect, useState } from "react";
import { useInit } from "../../hooks/useInit";
import axios from "axios";
import JobFileViewer from "./jobFileViwer";

const JobAutoSequenceDisplay = ({ fileSets, setUsePreset, globalInputHandler, globalSid, setExcludeList, excludeList }) => {
  const { initState, initStarted, initCompleted, initErrors, initReset } = useInit(2);
  
  const [files, setFiles] = useState();
   

  const allFileSetsHaveFiles =
    fileSets.length > 0 &&
    fileSets?.map((fs) => !!files?.find((f) => f.fileSets.map((ffs) => ffs._id).includes(fs._id.toString())) && !!files?.find((f) => f.autoSequence.length > 0)).filter((data) => data === false)
      .length === 0;

  useEffect(() => {
    globalInputHandler("autoSequence", true, allFileSetsHaveFiles, globalSid);
    globalInputHandler("workflow", [null], true, globalSid);
  }, [allFileSetsHaveFiles]);

  const removePreSetHandler = () => {
    globalInputHandler("autoSequence", false, true, globalSid);
    setUsePreset();
  };

  return (
    <>
      <div className="mb-1 flex flex-col bg-taskinatorWhite h-fit border border-taskinatorMedGrey rounded-lg  overflow-x-auto w-full">
        {/* <div className="">
      <TaskItemDisplayV4 globalSid={globalSid} globalInputHandler={globalInputHandler} workflowId />
    </div> */}
        <div className="w-full flex-col flex h-fit">
          <div className="flex-col flex px-4 pt-4 pb-4">
            <div className="flex items-center justify-center">
              {/* <div className="text-md border rounded-full h-6 w-6 items-center justify-center flex font-bold border-2 mr-2 text-taskinatorDarkGrey border-taskinatorDarkGrey">2</div> */}
              <div className="w-fit pr-2 text-md flex text-taskinatorGreen uppercase font-semibold">Auto Sequence</div>

              <div className="grow pr-2 flex"></div>
              <div
                className="cursor-pointer text-taskinatorRed hover:opacity-100 justify-end text-xs mx-1 px-4 py-2 border border-taskinatorRed rounded opacity-50"
                onClick={() => removePreSetHandler()}
              >
                Change Sequence
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full overflow-x-auto">
        <JobFileViewer fileSets={fileSets}  setGlobalFiles={ setFiles} excludeList={excludeList} setExcludeList={setExcludeList} globalInputHandler={globalInputHandler} globalSid={globalSid} />
      </div>

      {fileSets.length === 0 && allFileSetsHaveFiles && (
        <div className="mb-1 text-sm flex flex-col bg-taskinatorWhite h-fit border border-taskinatorMedGrey rounded-lg  ">
          <div className="flex text-sm justify-center items-center  text-taskinatorRed py-4 font-bold">Add file set to continue...</div>
        </div>
      )}
      {(fileSets.length > 0 && files?.length === 0) ||
        (!allFileSetsHaveFiles && (
          <div className="mb-1 text-sm flex flex-col bg-taskinatorWhite h-fit border border-taskinatorMedGrey rounded-lg  ">
            <div className="flex text-sm justify-center items-center  text-taskinatorRed py-4 font-medium">A selected file set has no files or file does not have auto sequencing set.</div>
          </div>
        ))}
    </>
  );
};

export default JobAutoSequenceDisplay;
