import React, { useContext, useState } from "react";
import { AppContext } from "../../../contexts/appContext";
import Button from "../../UI_elements/button";
import ItemStatus from "../../UI_elements/itemStatus";
import Modal from "../../UI_elements/modal";
import StringShortenDisplay from "../../UI_elements/stringShortenDisplay";
import UserDisplayBubble from "../../UI_elements/userDisplayBubble";
import JobDetailsForm from "../../forms/jobDetailsForm";
import ActionJobDetailsDisplay from "../actions task lists/actionJobDetailsDisplay";
import JobRequests from "../task/jobRequests";

const ActionDisplay_ItemPrimary = ({ data, getData }) => {
  const [buttons, setButtons] = useState([]);
  const [editTaskDetails, setEditTaskDetail] = useState();
  const isManager = true;
  const appData = useContext(AppContext);
  return (
    <>
      <div className="w-full lg:h-full h-fit flex xl:flex-row flex-col grow overflow-auto">
        <div className="flex flex-col xl:grow h-fit xl:w-3/4 xl:overflow-auto mr-1">
          <div className="text-sm py-4 px-4 text-taskinatorWhite flex bg-taskinatorDarkGrey rounded-lg lg:h-12 flex-col justify-center items-start">
            <div className={`flex w-full md:flex-row flex-col`}>
              <div
                className={`flex w-full md:items-center items-start md:justify-start justify-center md:pl-1 md:flex-row flex-col`}
              >
                <div className="flex items-center w-full">
                  <div className="flex text-base uppercase">
                    Job —
                    <b className="order-taskinatorWhite px-2">{data.ref}</b>
                    {" "}
                  </div>
                  <div className="flex grow justify-end">
                    {isManager && (
                      <div className="pr-1">
                        <Button
                          onClick={() => setEditTaskDetail(true)}
                          label="Edit Details"
                          customClasses={
                            "border-taskinatorMedGrey text-taskinatorMedGrey hover:bg-taskinatorMedGrey  hover:text-taskinatorWhite uppercase items-center text-xs h-8 px-2 border rounded"
                          }
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full flex lg:flex-row flex-col overflow-auto grow">
            <div
              className={`flex xl:flex-col grow flex-col-reverse h-fit overflow-auto`}
            >
              <div className="h-fit bg-taskinatorLightGrey border border-taskinatorMedGrey p-1 rounded-lg mt-1">
                {" "}
                <ActionJobDetailsDisplay
                  data={data}
                  getData={() => getData()}
                  //   simpleView={simpleView}
                  //   setViewOtherWorkflows={setViewOtherWorkflows}
                  //   viewOtherWorkflows={viewOtherWorkflows}
                  //   isManager={manager}
                />
              </div>

              <div className="text-sm py-4 px-4 text-taskinatorWhite flex bg-taskinatorGreen rounded-lg lg:h-12 flex-col justify-center items-start mt-1">
                <div className="flex items-center">
                  <div className="flex text-base uppercase">
                    Task —
                    <b className="order-taskinatorWhite px-2">
                      {StringShortenDisplay(30, data.actionName)}
                    </b>
                    {" "}
                  </div>
                </div>
              </div>

              <div className="flex flex-col w-full rounded-lg border p-2 mt-1 border-taskinatorMedGrey bg-taskinatorWhite">
                {Object.values(data.tracers).map((tracer) => {
                  const item = tracer.itemsOnTracer;
                  const isNew =
                    tracer.seen &&
                    tracer.seen.filter(
                      (seen) => seen._id === appData.workspaceDataV4.user_id
                    ).length === 0;

                  return (
                    <>
                      {!isNew && (
                        <div className="flex grow justify-start h-0 pt-2 z-40">
                          <div className="relative -top-2 ml-2 items-center flex h-4 w-fit text-taskinatorWhite text-[0.75rem] bg-taskinatorRed px-1 rounded-full font-semibold">
                            New
                          </div>
                        </div>
                      )}

                      <div
                        className={`text-xs flex items-center text-taskinatorDarkGrey mb-1 p-2 border border-taskinatorMedGrey rounded bg-taskinatorItemBlue`}
                      >
                        <div className="font-semibold uppercase">
                          {item.currentItemDetails.itemNumber}
                        </div>
                        <div className="px-1">-</div>
                        <div className="font-semibold uppercase pr-2">
                          {StringShortenDisplay(
                            20,
                            item.currentItemDetails.itemName
                          )}
                        </div>
                        <div className="pr-2">|</div>
                        <div className="pr-2">
                          {item.draft ? (
                            <ItemStatus status={item.draft.status} />
                          ) : item.released ? (
                            <ItemStatus status={item.released.status} />
                          ) : (
                            "No Release"
                          )}
                        </div>
                        <div className="pr-2">Rev: </div>
                        <div className="font-semibold uppercase pr-2">
                          {item.draft
                            ? item.draft.revision
                            : item.released
                            ? item.released.revision
                            : ""}
                        </div>
                        <div className="flex grow justify-end">
                          {tracer.assignee.map((user, index) => {
                            if (index < 2)
                              return (
                                <div KEY={user._id} className="">
                                  <UserDisplayBubble
                                    firstName={user.firstName}
                                    lastName={user.lastName}
                                    textSize={"text-xs"}
                                    user={user}
                                  />
                                </div>
                              );
                          })}
                          {tracer.assignee.length > 2 && (
                            <div className="text-xs">...</div>
                          )}
                        </div>
                      </div>
                    </>
                  );
                })}
                {" "}
              </div>

              {/* <div className={`bg-taskinatorLightGrey border border-taskinatorMedGrey rounded-lg mr-1 mt-2 p-2 overflow-auto flex flex-col h-fit grow scrollbar`}>
              <div className="pt-1 h-fit">
                {" "}
                <ActionJobDetailsDisplay
                  data={data}
                  getData={() => getData()}
                  //   simpleView={simpleView}
                  //   setViewOtherWorkflows={setViewOtherWorkflows}
                  //   viewOtherWorkflows={viewOtherWorkflows}
                  //   isManager={manager}
                />
              </div>
              <div className="pt-1 h-fit">
                <>

                
                  <div className="flex grow items-center w-full">
                    <div className="font-bold text-taskinatorDarkGrey text-base uppercase"> Documents On Task</div>
                  </div>
                  <div className="flex flex-col grow w-full rounded-lg border p-2 mt-1 border-taskinatorMedGrey bg-taskinatorWhite">
                    {Object.values(data.tracers).map((tracer) => {
                      const item = tracer.itemsOnTracer;
                      const isNew = tracer.seen && tracer.seen.filter((seen) => seen._id === appData.workspaceDataV4.user_id).length === 0;

                      return (
                        <>
                          {!isNew && (
                            <div className="flex grow  justify-start h-0 pt-2 z-40">
                              <div className="relative -top-2 ml-2 items-center flex h-4 w-fit text-taskinatorWhite text-[0.75rem] bg-taskinatorRed  px-1 rounded-full font-semibold">New</div>
                            </div>
                          )}

                          <div className={`text-xs flex items-center text-taskinatorDarkGrey mb-1 p-2 border border-taskinatorMedGrey rounded bg-taskinatorItemBlue`}>
                            <div className="font-semibold uppercase">{item.currentItemDetails.itemNumber}</div>
                            <div className="px-1">-</div>
                            <div className="font-semibold uppercase pr-2">{StringShortenDisplay(20, item.currentItemDetails.itemName)}</div>
                            <div className="pr-2">|</div>
                            <div className="pr-2">{item.draft ? <ItemStatus status={item.draft.status} /> : item.released ? <ItemStatus status={item.released.status} /> : "No Release"}</div>
                            <div className="pr-2">Rev: </div>
                            <div className="font-semibold uppercase pr-2">{item.draft ? item.draft.revision : item.released ? item.released.revision : ""}</div>
                            <div className="flex grow justify-end">
                              {tracer.assignee.map((user, index) => {
                                if (index < 2)
                                  return (
                                    <div KEY={user._id} className="">
                                      <UserDisplayBubble firstName={user.firstName} lastName={user.lastName} textSize={"text-xs"} user={user} />
                                    </div>
                                  );
                              })}
                              {tracer.assignee.length > 2 && <div className="text-xs">...</div>}
                            </div>
                          </div>
                        </>
                      );
                    })}{" "}
                  </div>
                </>
              </div>

          
            </div> */}

              <div className="flex justify-center p-2 border mr-1 mt-1 rounded-lg border-taskinatorMedGrey"></div>
            </div>
          </div>
        </div>

        <div
          className={`xl:w-1/6 grow shrink-0 flex flex-col xl:h-full h-fit mt-1 xl:mt-0`}
        >
          <div className="text-sm px-4 text-taskinatorWhite bg-taskinatorMedGrey shadow font-semibold uppercase flex w-full rounded-lg lg:h-12">
            <div className="flex text-base uppercase items-center text-center w-full justify-center">
              Job Log/Notes
            </div>
            {/* <div className="flex grow justify-end items-center">{buttons.length > 0 && <Button label={buttons[0].label} customClasses={buttons[0].customClasses} onClick={buttons[0].onClick} />}</div> */}
          </div>
          <div className="bg-taskinatorHoverGrey p-1 flex-col grow flex mt-2 rounded-lg">
            <JobRequests
              group={"task"}
              tid={data.tid}
              canSetRequests={{ setButtons: setButtons, buttons: buttons }}
            />
          </div>
          <div className="flex mt-1 items-center w-full justify-end border border-taskinatorMedGrey p-2 rounded-lg">
            {buttons.length > 0 && (
              <Button
                label={buttons[0].label}
                customClasses={buttons[0].customClasses}
                onClick={buttons[0].onClick}
              />
            )}
          </div>
        </div>

        <div
          className={`xl:w-1/6 grow ml-1 shrink-0 flex flex-col xl:h-full h-fit mt-1 xl:mt-0`}
        >
          <div className="text-sm px-4 text-taskinatorWhite bg-taskinatorOrange shadow font-semibold uppercase flex w-full rounded-lg lg:h-12">
            <div className="flex text-base uppercase items-center text-center w-full justify-center">
              Requests On Job
            </div>
            {/* <div className="flex grow justify-end items-center">{buttons.length > 0 && <Button label={buttons[0].label} customClasses={buttons[0].customClasses} onClick={buttons[0].onClick} />}</div> */}
          </div>
          <div className="bg-taskinatorHoverOrange p-1 flex-col grow flex mt-2 rounded-lg">
            <JobRequests
              group={"task"}
              tid={data.tid}
              canSetRequests={{ setButtons: setButtons, buttons: buttons }}
            />
          </div>
          <div className="flex mt-1 items-center w-full justify-end border border-taskinatorMedGrey p-2 rounded-lg">
            {buttons.length > 0 && (
              <Button
                label={buttons[0].label}
                customClasses={buttons[0].customClasses}
                onClick={buttons[0].onClick}
              />
            )}
          </div>
        </div>
      </div>

      {editTaskDetails && (
        <Modal local={() => setEditTaskDetail()} title="Edit Job Details">
          <div className="md:w-96 w-full">
            <JobDetailsForm
              details={data}
              closeModal={() => setEditTaskDetail()}
              onSuccess={() => getData()}
            />
          </div>
        </Modal>
      )}
    </>
  );
};

export default ActionDisplay_ItemPrimary;
