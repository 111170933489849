import React, { useEffect, useState, useContext } from "react";
import moment from "moment-timezone";
import { AppContext } from "../../contexts/appContext";

const CalendarColumnElement = ({ userSchedule, noneCollapsible }) => {
  const [currentTime, setCurrentTime] = useState(moment.tz(moment.tz.guess()));
  const [collapse, setCollapse] = useState(noneCollapsible ? false : true);
  const appData = useContext(AppContext);

  const jobs = userSchedule ? userSchedule : Array.isArray(appData.schedule) ? appData.schedule : [];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(moment.tz(moment.tz.guess()));
    }, 60000); // Update every minute

    return () => clearInterval(interval);
  }, []);

  const hours = Array.from({ length: 24 }, (_, i) => i);

  // Calculate the date range of the calendar based on jobs
  const allDates = jobs.reduce((dates, job) => {
    const startDate = moment.tz(job.scheduleStartDate, moment.tz.guess());
    const endDate = moment.tz(job.scheduledFinishDate, moment.tz.guess());
    dates.push(startDate, endDate);
    job.tasks.forEach((task) => {
      dates.push(moment.tz(task.scheduleStartDate, moment.tz.guess()), moment.tz(task.scheduledFinishDate, moment.tz.guess()));
    });
    return dates;
  }, []);

  // if (allDates.length === 0) {
  //   return <div>No jobs scheduled.</div>;
  // }

  const minDate = moment.min(allDates).startOf("day");
  const maxDate = moment.max(allDates).endOf("day");
  const totalDays = maxDate.diff(minDate, "days") + 1;

  const dateHeaderHeightRem = 2; // Height of the date header in rem
  const hourHeightRem = 2; // Height of each hour in rem

  const calculateTopOffset = (date) => {
    const daysPastStart = date.diff(minDate, "days");
    const extraHeaderHeight = daysPastStart * dateHeaderHeightRem;
    const timeOffset = (date.diff(minDate, "minutes") / 60) * hourHeightRem;
    return timeOffset + extraHeaderHeight + dateHeaderHeightRem;
  };

  const renderTasks = () => {
    return jobs.flatMap((job) =>
      job.tasks.map((task, index) => {
        const taskStart = moment.tz(task.scheduleStartDate, moment.tz.guess());
        const taskEnd = moment.tz(task.scheduleFinishDate, moment.tz.guess());

        const top = calculateTopOffset(taskStart);
        const height = (taskEnd.diff(taskStart, "minutes") / 60) * hourHeightRem;

        return (
          <div key={task.scheduleStartDate} className="absolute pb-0.5" style={{ top: `${top}rem`, height: `${height}rem`, left: "3rem", right: "0.5rem" }}>
            <div
              onClick={() => appData.setJobViewHandler({ jobId: job.jobId, jobData: appData.jobs[job.jobId] })}
              className="bg-taskinatorWhite rounded border-taskinatorGreen text-xs border h-full p-2 hover:ring-1 hover:ring-taskinatorGreen cursor-pointer"
            >
              <div className="uppercase text-taskinatorDarkGrey flex">
                <div className="font-bold pr-1">{job.jobRef}</div> - <div></div>
                <div className="pl-1">{task.title}</div>
              </div>
            </div>
          </div>
        );
      })
    );
  };

  return (
    <div className="h-full  w-fit overflow-auto flex flex-col ">
      {!noneCollapsible && (
        <div
          onClick={!noneCollapsible ? (collapse ? () => setCollapse(false) : () => setCollapse(true)) : () => {}}
          className={`bg-taskinatorItemBlue  ${
            collapse ? "h-full w-6 cursor-pointer hover:opacity-75 overflow-hidden transition-all duration-500 mt-1.5 rounded" : "w-96 justify-center px-4 py-3 text-base mt-1.5 rounded-lg mr-1"
          }  flex items-center shadow  `}
        >
          <div
            className={` ${
              collapse ? `rotate-90  flex justify-center items-center w-full h-full ${collapse ? "transition-transform duration-500" : "transform"}` : "mx-2 flex grow w-full items-center  "
            } text-taskinatorDarkGrey font-semibold uppercase text-base `}
          >
            {collapse || noneCollapsible ? <div className="w-20"></div> : <div className="flex w-20 justify-start text-xxs items-center underline cursor-pointer capitalize">Hide</div>}{" "}
            <div className="flex grow justify-center text-base">Schedule</div>
            <div className="w-20"></div>
          </div>
        </div>
      )}
      <div
        className={`${collapse ? "hidden" : "flex w-96 transition-all duration-500"} relative rounded h-full overflow-auto flex-col scrollbar mt-1 `}
        style={{ height: `${totalDays * (hourHeightRem * 24 + dateHeaderHeightRem)}rem` }}
      >
        {Array.from({ length: totalDays }).map((_, dayIndex) => (
          <div key={dayIndex} className="relative">
            {hours.map((hour) => (
              <React.Fragment key={hour}>
                {hour === 0 && (
                  <div className="left-0 w-full text-center text-base font-bold border border-taskinatorMedGrey rounded text-taskinatorDarkGrey items-center flex justify-center shadow bg-taskinatorWhite h-[2rem] sticky top-0 z-30">
                    {minDate.clone().add(dayIndex, "days").format("dddd, MMMM Do YYYY")}
                  </div>
                )}
                <div className="flex items-center border-b border-taskinatorWhite border-b-2 h-8">
                  <div className="w-12 pl-1 text-left pr-2 text-xs">{moment({ hour }).format("h A")}</div>
                  <div className="flex-1"></div>
                </div>
              </React.Fragment>
            ))}
          </div>
        ))}
        {renderTasks()}
        <div className="absolute w-full border-t-2 border-red-500 opacity-50" style={{ top: `${calculateTopOffset(currentTime)}rem` }}></div>
      </div>
    </div>
  );
};

export default CalendarColumnElement;
