import React, { useState, useContext, useEffect } from "react";
import { JobModalContext } from "../jobs/jobModalContext";
import CheckboxV4 from "../../inputs/checkbox_V4";
import BulkDownloader from "../../../utils/bulkDownloader";
import BulkFileUpload from "../files/bulkFileUpload";
import BulkFileEditor from "../files/bulkFileEditor";
import FileViewAndActions from "../files/fileViewAndActions";
import JobModalDropDownContainer from "../jobs/jobModalDropDownContainer";
import Modal from "../../UI_elements/modal";
import IconSpinner from "../../UI_elements/iconSpinner";
import axios from "axios";
import { useInit } from "../../hooks/useInit";
import Button from "../../UI_elements/button";
import JobSubmission from "../jobs/jobSubmission";
import AddFileBackIntoTask from "./addFilesBackIntoTask";


export const FileDisplayWithEdits = ({ fileSetByTask, workflowData, fileSelected, setSelectAll, setFileSelected, selectAll, fileSelectionHandler, setWorkflow, noReferences, CompleteHandler, backwardMovementOptions, forwardMovementOptions, nextWorkflows, setAddTask, allFilesSelected, setMarkedAsCompleted }) => {
  const { initState, initStarted, initCompleted, initErrors, initReset } = useInit(2);
  const [bulkEdit, setBulkEdit] = useState();
  const jobData = useContext(JobModalContext);
  const [referenceFiles, setReferenceFiles] = useState([]);
  const [refSelectAll, setRefSelectAll] = useState(false);
  const [refSelected, setRefSelected] = useState({});

  const [addFilesBack, setAddFilesBack] = useState();

  const refFileSelectionHandler = (selectedId, isChecked, file) => {
    if (isChecked) {
      setRefSelected((prevSelected) => ({
        ...prevSelected,
        [selectedId]: file, // Add the selected ID
      }));
    } else {
      setRefSelected((prevSelected) => {
        const newSelection = { ...prevSelected }; // Create a new object
        delete newSelection[selectedId]; // Remove the ID
        return newSelection; // Return the new state
      });
    }
  };




  // /referenceFiles/:jobId/:taskId/:fileSetId
  useEffect(() => {
    const fetchHandler = async () => {
      try {
        initStarted(0, 1);

        const responseData = await axios.get(
          process.env.REACT_APP_BACKEND_URL + `/api/job/referenceFiles/${jobData.jobId?.toString()}/${fileSetByTask._id}/${fileSetByTask?.fileSetId?.toString()}`,

          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        );
        initCompleted(0, 1);

        setReferenceFiles(responseData.data.data);
      } catch (err) {
        console.log(err);
        return initErrors(err?.response?.data?.message ?? err?.message ?? "", 0, 1);
      }
    };

    if (workflowData) {
      fetchHandler();
    }
  }, [fileSetByTask._id, fileSetByTask.files]);

  const checkOutHandler = () => {};

  return (
    <>
      <div>
        {!fileSetByTask?.taskCompleted && !jobData.jobCompleted ? (
          <div className="flex grow h-fit  items-center">
            <div className="pl-2 flex">
              <CheckboxV4
                label={`Select All Files (${Object.values(fileSelected ?? {}).length})`}
                onInput={(id, isChecked, isValid, sid) => {
                  setSelectAll(isChecked);

                  if (!isChecked) {
                    setFileSelected({});
                  }
                }}
                defaultValue={selectAll}
                customCheckbox={"h-5 w-5"}
              />
              <BulkDownloader selectedFiles={fileSelected} fileNameData={`${fileSetByTask.fileSetName}_ZIPPED FILES`} />
            </div>
            <div className="flex grow justify-end items-center">
              {Object.values(fileSelected).length > 1 && (
                <div onClick={() => setBulkEdit(true)} className="underline text-taskinatorBlue py-2 cursor-pointer mr-1">
                  Bulk Edit
                </div>
              )}
              <div>
                <BulkFileUpload
                  onFilesUploaded={() => {}}
                  fileSetId={fileSetByTask.fileSetId}
                  fileSetName={fileSetByTask.fileSetName}
                  buttonandModal
                  customButtonCSS=" border-taskinatorBlue border  text-xs text-taskinatorBlue py-2  w-full mr-1 uppercase  "
                  jobData={{ _id: jobData.jobId, taskDetails: fileSetByTask.taskDetails, taskId: fileSetByTask._id, workflowData: workflowData }}
                />
              </div>

              <div className="flex  items-center " onClick={(e) => e.stopPropagation()}>
                  {!fileSetByTask.taskCompleted && (
                    <div className="flex grow justify-end  mr-1">
                      <Button
                        onClick={() => setAddTask(true)}
                        label="Add Tasks"
                        customClasses={` text-taskinatorGreen border border-taskinatorGreen rounded px-2 py-2 text-xs uppercase  w-full hover:opacity-75`}
                      />
                    </div>
                  )}

                  {!jobData.jobCompleted &&
                    !fileSetByTask.taskCompleted &&
                    workflowData?.workflowMap &&
                    fileSetByTask?.isAssigned &&
                    !setWorkflow &&
                    (backwardMovementOptions?.length > 0 || forwardMovementOptions?.length > 0 || nextWorkflows?.length > 0 ? (
                      <JobSubmission
                        fileSelected={fileSelected}
                        fileSetByTask={fileSetByTask}
                        setFileSelected={setFileSelected}
                        setSelectAll={setSelectAll}
                        workflowData={workflowData}
                        allFilesSelected={allFilesSelected}
                      />
                    ) : (
                      <div className="text-taskinatorRed"> </div>
                    ))}

                  {/* {!jobData.jobCompleted && fileSetByTask.isManager && (
                    <Button
                      label={fileSetByTask?.taskCompleted ? "Re-Open Stage" : `Mark Stage As Completed`}
                      customClasses={`${
                        fileSetByTask?.taskCompleted ? "bg-taskinatorOrange text-taskinatorBlack opacity-50" : "border-taskinatorOrange border text-taskinatorOrange"
                      } hover:opacity-75   py-2  text-xs uppercase ml-1`}
                      onClick={fileSetByTask?.taskCompleted ? () => CompleteHandler() : () => setMarkedAsCompleted(true)}
                      isLoading={initState[1].started}
                      disabled={initState[1].started}
                    />
                  )} */}
                </div>
              <div></div>
            </div>
          </div>
        ) : (
          <div className="h-5"></div>
        )}

        {fileSetByTask?.files.map((file, index) => {
          return (
            <div key={file.revisionTrackingId} className="h-fit">
              <FileViewAndActions
                globalOnInput={(selectedId, isChecked) => {
                  fileSelectionHandler(selectedId, isChecked, file);
                }}
                key={index}
                file={file}
                index={index}
                fileSetByTask={fileSetByTask}
                workflowData={workflowData}
                setWorkflow={setWorkflow}
                selectAll={selectAll}
                selectedFiles={fileSelected}
              />
            </div>
          );
        })}

        {!jobData.jobCompleted && referenceFiles && workflowData && (
          <div className="mt-2">
            <JobModalDropDownContainer
              thin
              headerContent={
                <>
                  <div className=" order-taskinatorWhite pl-2 pr-2 text-taskinatorDarkGrey text-sm normal-case">Files not active on this stage:</div>
                  <div className="text-taskinatorDarkGrey text-sm font-medium">({referenceFiles?.length})</div>
                </>
              }
              backgroundColour={"bg-taskinatorMedGrey"}
            >
              {referenceFiles.length === 0 && initState[0].initialised && <div className="text-xs text-taskinatorMedGrey">No Relevant Files</div>}

              <div className="">
                {referenceFiles.length > 0 && (
                  <div>
                    <div className="flex w-full">
                      <div className="pl-2 flex text-nowrap w-fit">
                        <CheckboxV4
                          label={`Select All Files (${Object.values(refSelected ?? {}).length})`}
                          onInput={(id, isChecked, isValid, sid) => {
                            setRefSelectAll(isChecked);

                            if (!isChecked) {
                              setRefSelected({});
                            }
                          }}
                          defaultValue={selectAll}
                          customCheckbox={"h-5 w-5"}
                        />
                      </div>
                      <div className="w-full flex justify-end mb-1 pr-1">
                        <Button
                          label={"Add Files Into Task"}
                          customClasses={`${
                            Object.values(refSelected ?? {}).length === 0 ? "opacity-50 cursor-not-allowed" : "opacity-100  hover:text-taskinatorWhite hover:bg-taskinatorGreen"
                          } border-taskinatorGreen text-taskinatorGreen   w-fit border uppercase   text-xs  h-8  `}
                          onClick={() => setAddFilesBack(refSelected)}
                          disabled={Object.values(refSelected ?? {}).length === 0}
                        />
                      </div>
                    </div>
                    {referenceFiles.map((file, index) => {
                      return (
                        <div key={file.revisionTrackingId}>
                          <FileViewAndActions
                            file={file}
                            index={index}
                            fileSetByTask={fileSetByTask}
                            workflowData={workflowData}
                            taskData={fileSetByTask.taskDetails}
                            fileSetId={fileSetByTask.fileSetId}
                            completed={fileSetByTask?.taskCompleted || jobData.jobCompleted}
                            isRef
                            selectAll={refSelectAll}
                            selectedFiles={refSelected}
                            globalOnInput={(selectedId, isChecked) => {
                              refFileSelectionHandler(selectedId, isChecked, file);
                            }}
                          />
                        </div>
                      );
                    })}{" "}
                  </div>
                )}
                {initState[0].started && <IconSpinner />}
              </div>
            </JobModalDropDownContainer>
          </div>
        )}
      </div>

      {bulkEdit && (
        <Modal local={() => setBulkEdit()} title="Bulk Edit" size={"medium"}>
          <BulkFileEditor
            titleLess
            bulkFileList={fileSelected}
            currentFileSetId={fileSetByTask.fileSetId}
            taskId={fileSetByTask._id}
            workflowGroupId={workflowData?.workflowGroupId}
            onResponse={() => setBulkEdit()}
            taskTags={fileSetByTask?.taskDetails?.fileTags}
          />
        </Modal>
      )}

      {addFilesBack && (
        <Modal local={() => setAddFilesBack()} title="Add Files To Running Or Task" size="medium">
          <div className="p-4">
            {" "}
            <AddFileBackIntoTask files={addFilesBack} taskData={fileSetByTask} onResponse={() => setAddFilesBack()} />
          </div>
        </Modal>
      )}
    </>
  );
};
