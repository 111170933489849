import React, { useEffect, useContext } from "react";
import CheckboxV4 from "../../inputs/checkbox_V4";
import FileDetails from "./fileDetails";
import Button from "../../UI_elements/button";
import { FileContext } from "./FileContext";

const FilesileListViewDisplayer = ({
  isVisible,
  index,
  file,
  fileSetId,
  fileId,
  inJob,
  isSelected,
  onSelectHandler,
  localOnly,
  removeFileHandler,
  selectAll,
  setFileSelection,
  setSelectAll,
  initState,
  showAuto,
  hideCheckbox,
  excludeHandler,
  excludeList,
  localSelection,
  startingWorkflow,
  createJob,
  GetFiles
}) => {
  const fileContext = useContext(FileContext);

  useEffect(() => {
    if ((localSelection?.[file.revisionTrackingId] || fileContext?.bulkFileSelection?.[file.revisionTrackingId]) && !isVisible) {
     
      onSelectHandler(false, file.revisionTrackingId, file);
    }

    if (!localSelection?.[file.revisionTrackingId] && !fileContext?.bulkFileSelection?.[file.revisionTrackingId] && isVisible && selectAll) {
      onSelectHandler(true, file.revisionTrackingId, file);
    }

    if (isSelected) {
      setFileSelection();
    }
  }, [isVisible]);

  if (isVisible) {
    return (
      <div
        key={fileId ?? index}
        className={`${isSelected ? "bg-taskinatorHoverBlue " : "bg-taskinatorWhite"} text-xs flex  items-center cursor-pointer hover:bg-taskinatorHoverBlue mt-0.5 text-taskinatorDarkGrey ${
          isVisible ? "" : "hidden"
        }  ${excludeList?.[file.revisionTrackingId.toString()] ? "opacity-30" : ""}`}
      >
        <div
          className="w-8 items-center justify-center flex ml-1.5"
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          {!localOnly && !hideCheckbox && (
            <CheckboxV4
              id={file.revisionTrackingId}
              sid="fileOnSet"
              defaultValue={selectAll ? selectAll : !!localSelection?.[file.revisionTrackingId] ?? !!fileContext?.bulkFileSelection?.[file.revisionTrackingId]}
              onInput={(id, isChecked, isValid, sid) => onSelectHandler(isChecked, id, file, fileSetId)}
              customCheckbox={"h-5 w-5"}
            />
          )}
        </div>
        <div
          className="flex   grow h-full py-1.5"
          onClick={
            setFileSelection
              ? () => {
                  setSelectAll(false);
                  fileContext.setBulkFileSelection();
                  setFileSelection({ file: file, fileSetId: fileSetId });
                }
              : () => {}
          }
        >
          <FileDetails file={file} wide localOnly={localOnly} showAccess startingWorkflow={startingWorkflow} />
          {/* {excludeHandler && (
            <div className="flex grow justify-end">
              <div className="px-2 items-center font-medium flex">
                {!excludeList?.[file.revisionTrackingId.toString()] ? <div className="text-taskinatorGreen">Included</div> : <div className="text-taskinatorRed">Excluded</div>}
              </div> */}
              {/* <div
                className="w-fit mr-2"
                onClick={(event) => {
                  event.stopPropagation();
                }}
              >
                <Button
                  onClick={() => {
                    excludeHandler();
                  }}
                  label={excludeList?.[fileId.toString()] ? "Include" : "Exclude"}
                  customClasses={`${
                    !excludeList?.[fileId.toString()]
                      ? "text-taskinatorRed opacity-50 hover:opacity-100 hover:border-taskinatorRed hover:border"
                      : "text-taskinatorGreen hover:text-taskinatorGreen hover:border-taskinatorGreen hover:border"
                  }`}
                />
              </div> */}
            {/* </div>
          )} */}
          {file.status === "pending" && !inJob && !localOnly && !hideCheckbox &&  !createJob && (
            <div className="flex grow justify-end ">
              <div
                className="w-fit "
                onClick={(event) => {
                  event.stopPropagation();
                }}
              >
                <Button
                  onClick={() => {
                    removeFileHandler(fileId, file.revisionTrackingId);
                  }}
                  label={"Delete"}
                  customClasses={"text-taskinatorRed opacity-50 hover:opacity-100 hover:border-taskinatorRed hover:border "}
                  isLoading={initState[0].started && initState[0].identifier === fileId}
                />
              </div>
            </div>
          )}{" "}
        </div>
      </div>
    );
  }
};

export default FilesileListViewDisplayer;
