import React, { useEffect, useContext, useState } from "react";
import { AppContext } from "../../../contexts/appContext";
import axios from "axios";
import { useInit } from "../../hooks/useInit";
import IconSpinner from "../../UI_elements/iconSpinner";
import Button from "../../UI_elements/button";
import Modal from "../../UI_elements/modal";
import AddJobsButton from "./addJobsButton";
import NewTask from "../../../pages/newTask/newTask";
import AllWorkflowDisplay from "../actionDisplay/allworkflowDisplay";
import StringShortenDisplay from "../../UI_elements/stringShortenDisplay";
import ActionNextStepsDisplayV2 from "../actionDisplay/actionNextStepsDisplayV2";
import ItemStatus from "../../UI_elements/itemStatus";
import ItemActionableElementV2 from "../items/itemActionableElementV2";
import TaskFormatter_byTask from "../../../utils/taskFormatter_byTask";
import ApproveRejectForm from "../../forms/approveRejectForm";
import DateDisplay from "../../UI_elements/dateDisplay";
import { MdCheckCircle } from "react-icons/md";
import JobAndWorkflow from "../../UI_elements/jobAndWorkflow";

const ProjectJobDisplay = ({ projectData, getData }) => {
  const appData = useContext(AppContext);
  const [onDelete, setDelete] = useState();

  const { initState, initStarted, initCompleted, initErrors } = useInit();

  const manager =
    (projectData && projectData.managers.filter((manager) => manager._id === appData.workspaceDataV4.user_id).length > 0) ||
    [process.env.REACT_APP_ACCESS_A, process.env.REACT_APP_ACCESS_O].includes(appData.workspaceDataV4.role);

  const [addJob, setAddJob] = useState();

  const deleteHandler = (tid) => {
    initStarted(0);
    axios
      .put(
        process.env.REACT_APP_BACKEND_URL + `/api/project/remove/job/${projectData._id}/${tid}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        getData();
        setDelete();
        initCompleted(0);
      })
      .catch((err) => {
        console.log(err);
        const errorMessage = err?.response?.data?.message ?? err?.message ?? '';
        initErrors(errorMessage, 0);
      });
  };

  if (projectData) {
    return (
      <>
        <div className="flex grow items-center w-full">
          <div className="font-bold text-taskinatorDarkGrey text-base uppercase">Jobs</div>
          <div className="flex pl-2 justify-end grow">
            {manager && (
              <div className="pr-1 flex">
                <AddJobsButton onSuccess={() => getData()} pid={projectData._id} pref={projectData.ref} />
                <Button
                  onClick={() => setAddJob(true)}
                  label="Add New"
                  customClasses={"ml-1 border-taskinatorGreen bg-taskinatorGreen   text-taskinatorWhite uppercase items-center text-xs h-8 px-2 border rounded"}
                />
              </div>
            )}
          </div>
        </div>

        <div className="flex flex-col grow w-full mt-1   ">
          <JobAndWorkflow tids={projectData.tids} getData={getData} setDelete={setDelete}/>
          {/* <div>
            {Object.values(TaskFormatter_byTask(projectData.tids)).map((jobActionWorkflow, index) => {
              {
                const deleted = jobActionWorkflow.taskStatus.closed && jobActionWorkflow.taskStatus.closedReason === "DELETED";

                return (
                  <div
                    key={jobActionWorkflow.tid}
                    className={` border-taskinatorMedGrey bg-taskinatorWhite  border border-taskinatorMedGrey mb-0.5 py-2 ${index === 0 ? "rounded-t-lg" : ""}  ${
                      index === Object.values(projectData.tids).length - 1 ? "rounded-b-lg" : ""
                    }`}
                  >
                    <div className="flex capitalize text-sm items-center px-2 ">
                      <div className="font-bold uppercase text-taskinatorDarkGrey border py-1 px-2 rounded-lg">{StringShortenDisplay(20, jobActionWorkflow.ref)} </div>
                      <div className={`pl-4 font-medium ${deleted ? "text-taskinatorRed" : "text-taskinatorGreen"}`}>
                        {jobActionWorkflow.taskStatus.closed && (
                          <div>
                            {jobActionWorkflow.taskStatus.closedReason} - {DateDisplay(jobActionWorkflow.taskStatus.closedDate)}
                          </div>
                        )}
                      </div>
                      <div className="flex justify-end grow items-center ">
                        <Button
                          label={`Remove Job`}
                          customClasses={`cursor-pointer text-taskinatorRed hover:bg-taskinatorRed hover:text-taskinatorWhite border-taskinatorRed  justify-end h-8  text-sm px-4 py-2  border  rounded opacity-40 `}
                          onClick={() => setDelete(jobActionWorkflow.tid)}
                  
                        />
                      </div>

                    </div>
                    {Object.values(jobActionWorkflow.tracers).map((tracer) => {
                      const item = tracer.itemsOnTracer;
                      return (
                        <div key={tracer._id} className={`mx-2 my-1 border bg-clip-border border-taskinatorMedGrey rounded-lg text-taskinatorDarkGrey`}>
                          <ItemActionableElementV2
                            viewHistory
                            tracer={tracer}
                            item={item}
                            displayOnly={() =>
                              appData.setViewAction({
                                tracerId: tracer._id,

                                getData: () => getData(),
                              })
                            }
                          />

                          <ActionNextStepsDisplayV2
                            setActionPlanLoaded={() => {
                              getData();
                            }}
                            tracer={tracer}
                            aidDetails={tracer.aid}
                            displayOnly
                          />
                        </div>
                      );
                    })}
                  </div>
                );
              }
            })}
          </div> */}
        </div>



        {addJob && (
          <Modal local={() => setAddJob()} title="Create Job" bgColour={"bg-taskinatorLightGrey"} size="max">
            <NewTask
              project={{ _id: projectData._id, ref: projectData.ref }}
              closeModal={() => {
                getData();
                setAddJob();
              }}
            />
          </Modal>
        )}

        {onDelete && (
          <Modal
            local={() => {
              setDelete();
            }}
            title={`Remove Job`}
          >
            <ApproveRejectForm
              confirmLoadingState={initState[0].started}
              error={initState[0].error}
              rejectFunction={() => setDelete()}
              confirmFunction={() => deleteHandler(onDelete)}
              requiredTextMatch={"Remove Job"}
              confirmButtonLabel="Remove"
              confirmButtonCSS="bg-taskinatorRed"
              rejectButtonLabel="Close"
              message={`Are You Sure You Want To Remove This Job From The Project? - Note: This will NOT close the job`}
            />
          </Modal>
        )}
      </>
    );
  } else {
    return (
      <div>
        <IconSpinner />
      </div>
    );
  }
};

export default ProjectJobDisplay;
