import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { AppContext } from "../../contexts/appContext";

import { useInit } from "../hooks/useInit";
import { useInputV4 } from "../../stateManagement/useInputV4";

import InputV4 from "../inputs/input_V4";
import FileUploaderV4 from "../inputs/fileUploader_V4";
import Button from "../UI_elements/button";

import { VALIDATOR_MINLENGTH } from "./validators/validators";
import Modal from "../UI_elements/modal";
import RequestTypeSelector from "../dataSelectors/requestTypeSelector";
import RequestAffectSelector from "../dataSelectors/requestAffectSelector";
import MemberSelector from "../dataSelectors/memberSelector";
import { useParams } from "react-router-dom";
import IconSpinner from "../UI_elements/iconSpinner";
import BulkFileUpload from "../displayElements/files/bulkFileUpload";

const RequestForm = ({ onClose, onSuccess, tid, tracer }) => {
  const { initState, initStarted, initCompleted, initErrors, initReset } = useInit();
  const { inputState, inputHandler, attachmentHandler } = useInputV4({
    inputs: { requestUrgency: { value: null, isValid: false }, assignees: {}, requestDetails: {} },
  });
  const appData = useContext(AppContext);
  const [requestType, setRequestType] = useState([]);
  const [requestUrgancy, setRequestUrgency] = useState([]);


  const submitHandler = () => {
    initStarted(0);
    axios
      .put(
        process.env.REACT_APP_BACKEND_URL + `/api/requests/create/${tid}`,
        {
          // requestType: inputState.inputs.requestType.value,
          requestUrgency: inputState.inputs.requestUrgency.value,
          assignees: inputState.inputs.assignees.value,
          requestDetails: inputState.inputs.requestDetails.value,
          attachments: Object.values(inputState.attachments).map((attachment) => {
            return { key: attachment.value.key, fileName: attachment.value.fileName, fileExtension: attachment.value.fileExtension, _id: attachment.value._id };
          }),
          tracerId: tracer?._id ?? null,
          tracerWorkflowGroupId: tracer?.workflowGroupId ?? null,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        initCompleted(0);
        onSuccess();
        onClose();
      })
      .catch((err) => {
        const errorMessage = err?.response?.data?.message ?? err?.message ?? "";
        return initErrors(errorMessage, 0);
      });
  };

  const getRequestUrgency = () => {
    initStarted(0);
    axios
      .get(
        process.env.REACT_APP_BACKEND_URL + `/api/requests/requestTypes`,

        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        setRequestType(response.data.requestTypes);
        initCompleted(0);
      })
      .catch((err) => {
        return initErrors(err?.response?.data?.message ?? err?.message ?? "", 0);
      });
  };

  const getRequestTypes = () => {
    initStarted(1);
    axios
      .get(
        process.env.REACT_APP_BACKEND_URL + `/api/requests/requesturgency`,

        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        setRequestUrgency(response.data.requestUrgancy);
        initCompleted(1);
      })
      .catch((err) => {
        return initErrors(err?.response?.data?.message ?? err?.message ?? "", 0);
      });
  };

  useEffect(() => {
    getRequestTypes();
    getRequestUrgency();
  }, []);

  return (
    <Modal title="Ask Critical Question" local={() => onClose()}>
      <div className="md:w-96 w-full">
        {/* <div className="flex capitalize text-sm text-taskinatorDarkGrey">
          Request Type <div className="text-taskinatorRed pl-1">(Required)</div>
        </div>
        <div className="flex">
          {requestType.length === 0 && <IconSpinner />}
          {requestType && requestType.length > 0 && requestType.map((button) => {
            return (
              <div
                onClick={() => inputHandler("requestType", button.value, true, "inputs")}
                className={`w-1/3 h-20   border rounded p-2 mr-1 border-2  items-center justify-center text-xs flex text-center  font-medium shadow ${
                  inputState.inputs.requestType.value === button.value
                    ? "text-taskinatorBlue border-taskinatorBlue "
                    : "bg-taskinatorWhite text-taskinatorMedGrey border-taskinatorMedGrey  hover:border-taskinatorBlue hover:text-taskinatorBlue cursor-pointer"
                }`}
              >
                {button.name}
              </div>
            );
          })}

        </div> */}

        <div className="flex capitalize text-sm text-taskinatorDarkGrey pt-2">
          Urgency <div className="text-taskinatorRed pl-1">(Required)</div>
        </div>
        <div className=" flex">
          {requestUrgancy.length === 0 && <IconSpinner />}
          {requestUrgancy.length > 0 &&
            requestUrgancy.map((button) => {
              return (
                <div
                  onClick={() => inputHandler("requestUrgency", button.value, true, "inputs")}
                  className={`w-1/3 h-20    rounded p-2 mr-1 border-2  items-center justify-center text-xs flex text-center shadow font-medium  ${
                    inputState.inputs.requestUrgency.value === button.value
                      ? button.selectColour
                      : ` text-taskinatorMedGrey border border-taskinatorMedGrey ${
                          button.value === "critical"
                            ? "hover:border-taskinatorRed hover:text-taskinatorRed"
                            : button.value === "urgent"
                            ? "hover:border-taskinatorOrange hover:text-taskinatorOrange"
                            : "hover:border-taskinatorGreen hover:text-taskinatorGreen"
                        } cursor-pointer`
                  }`}
                >
                  <div> {button.name} </div>
                </div>
              );
            })}
          {/* <RequestAffectSelector sid={"inputs"} inputHandler={inputHandler} /> */}
        </div>
        <div className="mt-2">
          <MemberSelector selectionDisplayText="Select Team Member" id={"assignees"} inputHandler={inputHandler} sid={"inputs"} roleTypes={[]} defaultValue={[]} multi label="Assign To" />
        </div>
        <div className="mt-2">
          <InputV4
            sid={"inputs"}
            onInput={inputHandler}
            id={"requestDetails"}
            label="Request/Question Details"
            textareaRows={5}
            validators={[VALIDATOR_MINLENGTH(6)]}
            placeholder={"Provide details here..."}
          />
        </div>
        <div className="mt-0.5">
          <BulkFileUpload globalHandler={{ inputHandler: inputHandler, attachmentHandler: attachmentHandler }} type="questions"/>
          {/* <FileUploaderV4 id="attachments" inputHandler={inputHandler} attachmentHandler={attachmentHandler} /> */}
        </div>

        <Button
          onClick={() => submitHandler()}
          label={"Submit Question"}
          customClasses={`${inputState.isValid ? "bg-taskinatorBlue" : "bg-taskinatorMedGrey"} bg-taskinatorBlue text-taskinatorWhite h-10 w-full mt-2 `}
          disabled={!inputState.isValid}
          isLoading={initState[0].started}
          error={initState[0].error}
        />
      </div>
    </Modal>
  );
};

export default RequestForm;
