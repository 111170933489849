import React, { useState, useEffect } from "react";
import axios from "axios";

import { useInit } from "../hooks/useInit";

import DropDownSelector_V4 from "../inputs/dropDownSelector_V4";
import IconSpinner from "../UI_elements/iconSpinner";

const ItemStateSelector = ({ inputVer, inputHandler, defaultValue, sid, disabled, label, excludeRelease, selectionDisplayText }) => {
  const { initState, initStarted, initCompleted, initErrors, initReset } = useInit();

  const [itemStates, setItemStates] = useState();

  const sendRequest = () => {
    initStarted(0);

    axios
      .get(process.env.REACT_APP_BACKEND_URL + `/api/options/itemStateOptions`, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      .then((response) => {
        initCompleted(0);
        setItemStates(response.data.itemStates);
      })
      .catch((err) => {
        initErrors(err?.response?.data?.message ?? err?.message ?? "", 0);
      });
  };

  useEffect(() => {
    sendRequest();
  }, []);

  return !initState[0].started && defaultValue && Array.isArray(itemStates) ? (
    <DropDownSelector_V4
      color={"border-taskinatorMedGrey text-taskinatorDarkGrey font-semibold"}
      selectionColor={"text-taskinatorBlue  uppercase"}
      label={label}
      id={"itemState"}
      sid={sid}
      notSearchable
      defaultValue={defaultValue ? defaultValue : null}
      options={itemStates}
      onSelection={inputHandler}
      disabled={disabled}
      selectionDisplayText={selectionDisplayText ?? "Select Status"}
      inputVer={inputVer}
    />
  ) : (
    <div>
      <div className="text-sm h-5">{label}</div>
      <div className="flex text-xs w-full items-center border h-8 rounded pl-2 my-2 border-taskinatorMedGrey">
        <IconSpinner /> <div className="capitalize ml-2"> {`Loading Item State Options...`}</div>
      </div>
    </div>
  );
};

export default ItemStateSelector;
