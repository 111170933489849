import React, { useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import { useInit } from "../../components/hooks/useInit";
import { useInputV4 } from "../../stateManagement/useInputV4";

import ContentView from "../../pageTemplates/contentView";
import JobDetailsForm from "../../components/forms/jobDetailsForm";

import TaskWorkflowDisplayV4 from "../../components/displayElements/task/taskWorkflowDisplayV4";
import Button from "../../components/UI_elements/button";
import TaskItemDisplayV4 from "../../components/displayElements/task/taskItemDisplayV4";

const NewTask = ({ closeModal, project }) => {
  const navigate = useNavigate();

  const { initState, initStarted, initCompleted, initErrors, initReset } = useInit(1);
  const { inputState, inputHandler, removeSidHandler, attachmentHandler } = useInputV4();

  const workflowAdded = inputState.details && inputState.details.workflow && inputState.details.workflow.value.length > 0;

  
  const issueTaskHandler = async () => {
    initReset();
    initStarted(0);

    try {
      const responseData = await axios.put(
        process.env.REACT_APP_BACKEND_URL + `/api/task/create/new`,
        {
          taskDetails: inputState.details,
          // attachments: Object.values(inputState.attachments).map((attachment) => {
          //   return {
          //     key: attachment.value.key,
          //     fileName: attachment.value.fileName,
          //   };
          // }),
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      initCompleted(0);

      navigate("/");
      closeModal();
    } catch (err) {
      console.log(err);
      initErrors(err?.response?.data?.message ?? err?.message ?? "", 0);
    }
  };

  return (
    // <ContentView buttons={buttons} label={""}>
    <div className="h-full flex flex-col grow overflow-auto ">
      <div className="flex flex-col xl:flex-row overflow-auto h-full scrollbar xl:pr-0 pr-1 ">
        <div className="xl:w-1/4 w-full shrink-0 xl:mr-1 mr-0 xl:h-full h-fit  xl:overflow-auto flex flex-col">
          <div className="  py-2 px-4 text-taskinatorDarkGrey bg-taskinatorDarkGrey rounded-lg shadow mb-1  font-semibold uppercase flex items-center ">
            <div className="flex items-center text-taskinatorWhite text-md">
              {/* <div className="border rounded-full justify-center flex h-6 w-6  items-center mr-2 border-2 border-taskinatorDarkGrey text-taskinatorDarkGrey ">1</div> */}
              Job Details
            </div>
          </div>
          <JobDetailsForm globalInputHandler={inputHandler} globalSid={"details"} initState={initState} disabled={initState[0].started} project={project} />
          {/* <ActionTaskViewCard label="Task Details" customCSS={"xl:w-1/4 w-full shrink-0 xl:mr-1 mr-0 xl:h-full h-fit  xl:overflow-auto  "}>
          <JobDetailsForm globalInputHandler={inputHandler} globalSid={"details"} initState={initState} disabled={initState[0].started} />
        </ActionTaskViewCard> */}
        </div>
        <div className="flex grow flex-col xl:h-full h-fit  xl:overflow-auto">
          {/* <ActionTaskViewCard label="Item/s" customCSS={" w-full lg:mt-0 mt-1 lg:mr-0.5 md:h-full h-fit scrollbar lg:overflow-auto  lg:shink-0 "}>
            <TaskItemDisplayV4 globalSid={"details"} globalInputHandler={inputHandler} />
          </ActionTaskViewCard> */}

          <div className="xl:mt-0 mt-1 border-l border-l-4 border-l-taskinatorGreen py-2 px-4  bg-taskinatorGreen shadow mb-1 font-semibold uppercase flex items-center rounded-lg ">
            <div className="flex items-center text-taskinatorWhite">
              {/* <div className="border rounded-full justify-center flex h-6 w-6  text-sm items-center mr-2 border-2 border-taskinatorGreen text-taskinatorGreen">2</div> */}
              Workflow
            </div>
            <div className="pl-1 text-taskinatorWhite capitalize font-normal text-base">(Required)</div>
            {/* <div className="pl-1 text-taskinatorRed capitalize font-normal text-xs">(Required)</div> */}
          </div>
          <TaskWorkflowDisplayV4 globalSid={"details"} globalInputHandler={inputHandler} globalAttachmentHandler={attachmentHandler} closeModal={closeModal} />
          <div className="pt-2">
            <TaskItemDisplayV4 globalSid={"details"} globalInputHandler={inputHandler} workflowId workflowAdded={workflowAdded} number={`3`} />
          </div>
          {/* <ActionTaskViewCard label="Starting Workflow" customCSS={"w-full bg-taskinatorWhite xl:mt-0 mt-1   "}>
      
          </ActionTaskViewCard> */}
        </div>
      </div>
      <div className="w-full flex justify-end mt-2">
        <Button
          label="Create New Job"
          customClasses={`${inputState.isValid ? "bg-taskinatorBlue border border-taskinatorBlue" : "bg-taskinatorMedGrey border border-taskinatorMedGrey "} text-taskinatorWhite h-10 text-base py-2 `}
          onClick={() => issueTaskHandler()}
          isLoading={initState[0].started}
          disabled={!inputState.isValid}
          error={initState[0].error}
        />
      </div>
    </div>
    // </ContentView>
  );
};

export default NewTask;
