import axios from "axios";
import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { MdCheckCircle } from "react-icons/md";
import { animateScroll } from "react-scroll";
import { AppContext } from "../../../contexts/appContext";
import { useInputV4 } from "../../../stateManagement/useInputV4";
import CountUpTimer from "../../../utils/countUpTimer";
import FilePreview from "../../../utils/filePreview";
import FormatDate from "../../../utils/formattedDate";
import LinkifyText from "../../../utils/linkifyText";
import ActionTaskViewCard from "../../UI_elements/actionTaskViewCard";
import Button from "../../UI_elements/button";
import UserDisplayBubble from "../../UI_elements/userDisplayBubble";
import MemberSelector from "../../dataSelectors/memberSelector";
import { VALIDATOR_MINLENGTH } from "../../forms/validators/validators";
import { useInit } from "../../hooks/useInit";
import InputV4 from "../../inputs/input_V4";
import BulkFileUpload from "../files/bulkFileUpload";

const RequestModal = ({ request }) => {
  request.responses = SortWithKeyword(request.responses, "date");
  const appData = useContext(AppContext);
  const { initState, initStarted, initCompleted, initErrors } = useInit(2);

  const defaultState = { inputs: { response: {} }, attachments: {} };
  const { inputState, inputHandler, attachmentHandler, resetState } = useInputV4(defaultState, false);
  const [acceptedIndex, setAcceptedIndex] = useState();
  const youAreCreator =
    request.createdBy._id === appData.workspaceDataV4.user_id ||
    [process.env.REACT_APP_ACCESS_A, process.env.REACT_APP_ACCESS_O, process.env.REACT_APP_ACCESS_M].includes(appData.workspaceDataV4.role);
  const youreAssigned =
    request.assignees &&
    request.assignees
      .map((user) => {
        return user._id.toString();
      })
      .includes(appData.workspaceDataV4.user_id.toString());

  const responseHandler = () => {
    initStarted(0);
    axios
      .put(
        process.env.REACT_APP_BACKEND_URL + `/api/requests/response/${request._id}`,
        {
          response: inputState.inputs.response.value,
          addedTeam: inputState.inputs.addedTeam ? inputState.inputs.addedTeam.value : [],
          attachments: Object.values(inputState.attachments).map((attachment) => {
            return {
              key: attachment.value.key,
              fileName: attachment.value.fileName,
              fileExtension: attachment.value.fileExtension,
            };
          }),
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        initCompleted(0);
        resetState(defaultState);
        // getData()
        // requestContext.getRequests()
      })
      .catch((err) => {
        const errorMessage = err?.response?.data?.message ?? err?.message ?? "";
        appData.ERROR(errorMessage);
        return initErrors(errorMessage, 0);
      });
  };

  const lodgeHandler = () => {
    initStarted(1);
    axios
      .put(
        process.env.REACT_APP_BACKEND_URL + `/api/requests/lodge/${request._id}`,
        {
          acceptedIndex: acceptedIndex,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        // getData();
        // requestContext.getRequests();
        initCompleted(1);
      })
      .catch((err) => {
        const errorMessage = err?.response?.data?.message ?? err?.message ?? "";
        // appData.ERROR(errorMessage);
        return initErrors(errorMessage, 1);
      });
  };

  const fixScrollToBottom = (id) => {
    const card = document.querySelector(id);
    const duration = (card.scrollHeight - card.clientHeight) / 5;

    animateScroll.scrollToBottom({
      containerId: "response-card",
      duration: duration,
      smooth: true,
    });
  };

  // const fixScrollToTop = (id) => {
  //   const card = document.querySelector(id);
  //   const duration = (card.scrollHeight - card.clientHeight) / 5;

  //   animateScroll.scrollToTop({
  //     containerId: "response-card",
  //     duration: duration,
  //     smooth: true,
  //   });
  // };

  useEffect(() => {
    fixScrollToBottom("#response-card");
  }, [request.responses.length]);

  /// this is getting the seen
  useEffect(() => {
    const shouldSendRequest = request.seen.filter((seen) => seen._id === appData.workspaceDataV4.user_id).length === 0;

    if (shouldSendRequest) {
      const delay = 1000;

      const timer = setTimeout(() => {
        axios
          .get(process.env.REACT_APP_BACKEND_URL + `/api/requests/seen/${request._id}`, {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          })
          .then((response) => {
            // if (response.data.updatedRequest) {
            //   requestContext.getRequests();
            // }
          })
          .catch((err) => {
            console.log(err);
          });
      }, delay);

      return () => {
        clearTimeout(timer);
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request._id, appData.workspaceDataV4.user_id]);

  return (
    <div className="w-full pt-4 overflow-auto">
      <ActionTaskViewCard customCSS={""} label={""}>
        <div className="flex items-center text-sm">
          <div className="">
            <UserDisplayBubble firstName={request.createdBy.firstName} lastName={request.createdBy.lastName} textSize={"text-sm"} short={true} />
          </div>

          <div className="mr-2 font-semibold  uppercase">
            <div
              className={`py-1 px-2 rounded text-taskinatorWhite ${
                !request.completed
                  ? request.urgancy && request.urgancy.toLowerCase() === "critical"
                    ? `${youreAssigned ? "bg-taskinatorRed" : "bg-taskinatorMedGrey"} animate-pulse`
                    : request.urgancy && request.urgancy.toLowerCase() === "urgent"
                    ? `${youreAssigned ? "bg-taskinatorOrange" : "bg-taskinatorMedGrey"}`
                    : `${youreAssigned ? "bg-taskinatorGreen" : "bg-taskinatorMedGrey"}`
                  : "bg-taskinatorMedGrey"
              }`}
            >
              {request.urgancy === "critical" ? "Can't Continue Task" : request.urgancy === "urgent" ? "Will Be Held Up Soon" : "Need ASAP"}
            </div>
          </div>
          <div className="pr-1">Assignees: </div>
          {request.assignees.map((user, index) => {
            return (
              <div className="pr-1">
                <UserDisplayBubble firstName={user.firstName} lastName={user.lastName} user={user} textSize={"text-xs"} />
              </div>
            );
          })}

          {request.createdBy.date && !request.completed && <div className="flex grow justify-end text-xs px-4">{<CountUpTimer startDate={request.createdBy.date} />}</div>}
          {request.creatorTracer && (appData.viewAction && appData.viewAction.tracerId) !== request.creatorTracer && (
            <div className="flex">
              <Button
                label="View Task"
                onClick={
                  request.creatorTracer
                    ? () =>
                        appData.setActionViewHandler({
                          tracerId: request.creatorTracer,
                          // getData: () => {},
                        })
                    : () => {}
                }
                customClasses={"bg-taskinatorMedGrey text-taskinatorWhite rounded hover:bg-taskinatorBlue h-8"}
              />
            </div>
          )}

          {!request.creatorTracer && appData?.viewJob?.jobId !== request?.job?._id && (
            <div className="flex">
              <Button
                label="View Job"
                onClick={() => appData.setJobViewHandler({ jobId: request?.job?._id })}
                customClasses={"bg-taskinatorMedGrey text-taskinatorWhite rounded hover:bg-taskinatorBlue h-8"}
              />
            </div>
          )}
        </div>

        {request && (
          <div className="text-xxs w-full items-center flex pt-1">
            <div className="w-fit whitespace-nowrap pr-1 font-medium">Seen By:</div>
            <div className="flex w-full">
              {request.seen.length > 0 &&
                request.seen.map((user) => {
                  return (
                    <div key={user._id} className="flex capitalize pr-1">
                      {/* {user.firstName} */}
                      <div className="flex">
                        <div className="pr-1">{user.firstName}</div>
                        <div>{user.lastName[0]}</div>
                      </div>
                      {/* <UserDisplayBubble firstName={user.firstName} lastName={user.lastName} /> */}
                    </div>
                  );
                })}
            </div>
          </div>
        )}
      </ActionTaskViewCard>
      <ActionTaskViewCard customCSS={"mt-1"} label={"Details"}>
        <div>
          <div className="">
            <div className="text-sm whitespace-pre-wrap pb-1 border-b-taskinatorLightGrey border-b-2 mb-1">{request.requestDetails}</div>
            <div className="flex flex-wrap text-xs">
              {request.attachments.length > 0 ? (
                request.attachments.map((attachment) => {
                  return (
                    <div className="w-fit">
                      <FilePreview file={attachment} typeOverride={"questions"} />
                    </div>
                  );
                })
              ) : (
                <div className="text-taskinatorMedGrey text-sm pt-1"></div>
              )}
            </div>
          </div>
        </div>
      </ActionTaskViewCard>

      <ActionTaskViewCard customCSS={"mt-1"} label={"Responses"}>
        <div id="response-card" className={`overflow-auto ${request.responses && request.responses.length > 2 ? "h-96" : "h-fit"} scrollbar`}>
          {request.responses && request.responses.length > 0 && (
            <div>
              {request.responses.map((response, index) => {
                const isYou = response.by._id === appData.workspaceDataV4.user_id;
                const acceptedResponse = index === acceptedIndex || response.responseAccepted;
                return (
                  <div
                    key={index}
                    className={`${
                      acceptedResponse
                        ? "bg-taskinatorHoverGreen text-taskinatorWhite border-taskinatorHoverGreen  border-2 "
                        : request.completed
                        ? "bg-taskinatorLightGrey"
                        : isYou
                        ? ` rounded-br-none bg-taskinatorBlue text-taskinatorWhite  mr-2 sm:ml-6  ml-2 `
                        : `rounded-bl-none  bg-taskinatorDarkGrey text-taskinatorWhite sm:mr-6 mr-2 `
                    } rounded-xl mt-2 h-fit shadow ${acceptedIndex !== undefined && !acceptedResponse ? "hidden" : ""}`}
                  >
                    <div className="flex h-full items-center">
                      <div className="flex flex-col grow">
                        <div className="flex grow border-b border-b-taskinatorMedGrey pb-1 items-center px-2 pt-2">
                          <div className="w-fit pr-2">
                            <UserDisplayBubble firstName={response.by.firstName} lastName={response.by.lastName} noBackground short textColour="text-taskiantorWhite" />
                          </div>
                          <div className="text-xs py-1">{FormatDate(response.date)}</div>
                        </div>
                        <div className={`text-sm font p-2 whitespace-pre-wrap ${acceptedResponse ? "text-taskinatorWhite font-medium" : ""}`}>
                          <LinkifyText text={response.response} />
                        </div>

                        {response.attachments.length > 0 && (
                          <div className="flex flex-wrap text-xs p-2">
                            {response.attachments.map((attachment) => {
                              return (
                                <div className="w-fit">
                                  <FilePreview
                                    file={attachment}
                                    colorChange={!request.completed ? "bg-taskinatorLightGrey text-taskinatorDarkGrey hover:bg-taskinatorWhite hover:text-taskinatorDarkGrey" : null}
                                    typeOverride={"questions"}
                                  />
                                  {/* <DocumentDisplay
                                    attachment={attachment}
                                    colorChange={
                                      "bg-taskinatorLightGrey text-taskinatorDarkGrey"
                                    }
                                  /> */}
                                </div>
                              );
                            })}
                          </div>
                        )}

                        <div className="flex justify-between border-t pt-1 border-t-taskinatorMedGrey p-2">
                          <div className="font flex text-xs items-center opacity-60">
                            {response.addedTeamMembers && response.addedTeamMembers.length > 0 && <div className="pr-1">Added Member:</div>}
                            {response.addedTeamMembers &&
                              response.addedTeamMembers.length > 0 &&
                              response.addedTeamMembers.map((user) => {
                                return (
                                  <div key={user._id} className="flex capitalize">
                                    <UserDisplayBubble firstName={user.firstName} lastName={user.lastName} />
                                  </div>
                                );
                              })}
                          </div>

                          <div
                            onClick={
                              request.completed
                                ? () => {}
                                : acceptedResponse && youAreCreator
                                ? () => {
                                    setAcceptedIndex();
                                  }
                                : () => {
                                    setAcceptedIndex(index);
                                  }
                            }
                            className="text-xs"
                          >
                            {acceptedResponse ? (
                              <div className="text-taskinatorWhite font-medium flex items-center uppercase">
                                <div className="pr-1">
                                  <MdCheckCircle />
                                </div>
                                {`Accepted${request.completed ? ` by ${request.completedBy}` : ""}`}
                              </div>
                            ) : (
                              youAreCreator && (
                                <div className={`border-taskinatorWhite rounded py-1 px-2 border cursor-pointer hover:opacity-100 opacity-75 ${request.completed ? "hidden" : ""}`}>
                                  Accept This Answer
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          {request.responses && request.responses.length === 0 && <div className="text-sm text-taskinatorMedGrey">No Responses yet...</div>}
        </div>
      </ActionTaskViewCard>
      {!request.completed && (
        <ActionTaskViewCard customCSS={"mt-1"}>
          {acceptedIndex === undefined && (
            <div className="">
              {!initState[0].started && (
                <div className="">
                  <div className="text-sm mt-1">
                    <InputV4
                      inputVer="1"
                      textareaRows={5}
                      onInput={inputHandler}
                      defaultValue={inputState.inputs.response.value}
                      validators={[VALIDATOR_MINLENGTH(3)]}
                      id="response"
                      sid="inputs"
                      placeholder={"Type Response Here... (min 3 charactors)"}
                    />
                  </div>

                  <div className="flex flex-col mt-1">
                    <div className="">
                      <BulkFileUpload globalHandler={{ inputHandler: inputHandler, attachmentHandler: attachmentHandler }} type={"questions"} />
                      {/* <FileUploaderV4
                        id="attachments"
                        inputHandler={inputHandler}
                        attachmentHandler={attachmentHandler}
                      /> */}
                    </div>

                    <div className="mt-1">
                      <MemberSelector
                        id={"addedTeam"}
                        inputHandler={inputHandler}
                        sid={"inputs"}
                        roleTypes={[]}
                        defaultValue={[]}
                        notRequired
                        multi
                        selectionDisplayText="Add Member To Conversation"
                        excludeList={[
                          ...request.assignees.map((user) => {
                            return user._id;
                          }),
                          request.createdBy._id,
                        ]}
                        inline
                        dontAutoSelect
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className="flex justify-end mt-3">
                <Button
                  label="Submit Response"
                  onClick={() => responseHandler()}
                  customClasses={`${inputState.isValid ? "bg-taskinatorBlue" : "bg-taskinatorMedGrey"} bg-taskinatorBlue text-taskinatorWhite text-sm h-10 w-full mt-1`}
                  disabled={!inputState.isValid}
                  isLoading={initState[0].started}
                  error={initState[0].error}
                />
              </div>
            </div>
          )}

          {acceptedIndex !== undefined && (
            <Button
              label="Confirm & Mark As Completed"
              onClick={() => lodgeHandler()}
              customClasses={`${acceptedIndex !== undefined ? "bg-taskinatorBlue" : "bg-taskinatorMedGrey"} bg-taskinatorBlue text-taskinatorWhite text-sm h-10 w-full mt-1`}
              disabled={acceptedIndex === undefined}
              isLoading={initState[1].started}
              error={initState[1].error}
            />
          )}
        </ActionTaskViewCard>
      )}
    </div>
  );
};

export default RequestModal;

export const SortWithKeyword = (data, keyWord, dir = "asc") => _.orderBy(data, keyWord, dir);
