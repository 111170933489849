import React, { useContext } from "react";
import { AppContext } from "../contexts/appContext";
import ToolTip from "../utils/toolTip";
import Button from "../components/UI_elements/button";

const TopMenu = ({ label, buttons, onClick, centerContent, notButton, toolTip }) => {
  const appData = useContext(AppContext);

  return (
    <div className="py-2 min-h-12 h-14 shadow-lg flex items-center bg-taskinatorOffWhite border-b-2 border-b-taskinatorLightGrey  md:mt-0 w-full   ">
      <div className="ml-3 flex items-center">
        <div className="  md:text-taskinatorDarkGrey text-taskinatorDarkGrey font-semibold sm:block hidden   whitespace-nowrap ">{label}</div>
        <div className="text-xl">{toolTip && <ToolTip content={toolTip} full video customCSS={"whitespace-nowrap"}/>}</div>
      </div>
      <div className="flex grow justify-center  items-center w-full  ml-5">{centerContent}</div>
      {notButton}
      {buttons && (
        <div className="mr-5 flex-row flex md:py-0 py-1">
          {buttons.map((button, index) => {
            const displayButton = button.isCreator ? appData.accessType === process.env.REACT_APP_ACCESS_A || appData.creatorType : true;

            if (displayButton) {
              return (
                <div key={button.label} className="">
                  <Button
                    error={button.error}
                    type={"button"}
                    label={button.label}
                    onClick={onClick(index)}
                    customClasses={button.customClasses}
                    disabled={button.disabled}
                    isLoading={button.isLoading}
                  />
                </div>
              );
            }
          })}
        </div>
      )}
    </div>
  );
};

export default TopMenu;
