import axios from "axios";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import IconSpinner from "../../components/UI_elements/iconSpinner";
import { GetTimeString } from "../../components/displayElements/actionDisplay/actionTimesheetDetailsDisplay";
import { useInit } from "../../components/hooks/useInit";
import ContentView from "../../pageTemplates/contentView";
import FormatDate from "../../utils/formattedDate";

const Timesheet = () => {
  const { initState, initStarted, initCompleted, initErrors } = useInit();
  const [timeSheetData, setTimeSheetData] = useState([]);
  const [totalTimes, setTotalTimes] = useState({});
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [selectedTime, setSelectedTime] = useState(null);
  // const [modal, setModal] = useState();

  const updateTimesheet = (timesheetId) => {
    initStarted(0);
    axios
      .put(
        process.env.REACT_APP_BACKEND_URL +
          `/api/timesheet/update/${timesheetId}`,
        { updatedTime: selectedTime },
        {
          headers: {
            "Content-Type": "application/json",
          },

          withCredentials: true,
        }
      )
      .then((response) => {
        sendRequest();
        setSelectedIndex(-1);
        setSelectedTime(null);
        initCompleted(0);
      })
      .catch((err) => {
        return initErrors(
          err?.response?.data?.message ?? err?.message ?? "",
          0
        );
      });
  };

  const sendRequest = () => {
    initStarted(0);
    axios
      .get(process.env.REACT_APP_BACKEND_URL + `/api/timesheet/all`, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      .then((response) => {
        let timeSheets = [];
        if (response.data?.timeSheets?.length) {
          const timeSheetsArr = response.data.timeSheets.map(
            (item) => item.timeSheet
          );

          const timeSheetsFlat = timeSheetsArr.flat();

          setTotalTimes(response.data?.totalTimes ?? {});

          timeSheets = timeSheetsFlat.map((item) => {
            return {
              _id: item?._id ?? "",
              job: item?.tracerId?.taskDetails?.ref ?? "",
              project: item?.tracerId?.taskDetails?.project?.ref ?? "",
              task: item?.tracerId?.aid?.name ?? "",
              clockIn: item?.clockIn ?? "",
              clockOut: item?.clockOut ?? "",
              totalTime: item?.totalTime ?? 0,
            };
          });
          setTimeSheetData(timeSheets);
        }

        initCompleted(0);
      })
      .catch((err) => {
        return initErrors(
          err?.response?.data?.message ?? err?.message ?? "",
          0
        );
      });
  };

  /// get team data on start up
  useEffect(() => {
    sendRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickEdit = (index) => {
    setSelectedIndex(index);
    setSelectedTime(null);
  };

  const handleCalendarChange = (value, clockIn) => {
    const changed = new Date(value._d);
    const clockedIn = new Date(clockIn);

    setSelectedTime(
      changed.getTime() > clockedIn.getTime() ? changed : clockedIn
    );
  };

  const handleSave = (timesheetId) => {
    if (selectedTime === null) {
      setSelectedIndex(-1);
      return;
    }
    updateTimesheet(timesheetId);
  };

  const handleClose = () => {
    setSelectedIndex(-1);
    setSelectedTime(null);
  };

  return (
    <>
      <ContentView label={"Timesheet"}>
        <div className=" grid-rows-3  ">
          <div className="grid lg:grid-cols-3 md:grid-cols-3 gap-3 mb-2">
            <div className="col-span-1">
              <div className="w-full h-28 bg-taskinatorWhite border-2 border-solid  border-taskinatorMedGrey box-border px-7 py-3 flex justify-start items-center rounded">
                <div className="min-w-[280px] flex h-full flex-col justify-between">
                  <span className="font-bold text-taskinatorDarkGrey">
                    Time Today
                  </span>
                  <span className="font-bold text-taskinatorBlue text-[44px]">
                    {GetTimeString(totalTimes?.todayTotalTime ?? 0)}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-span-1">
              <div className="w-full h-28 bg-taskinatorWhite border-2 border-solid  border-taskinatorMedGrey box-border px-7 py-3 flex justify-start items-center rounded">
                <div className="min-w-[280px] flex h-full flex-col justify-between">
                  <span className="font-bold text-taskinatorDarkGrey">
                    Time This Week
                  </span>
                  <span className="font-bold text-taskinatorBlue text-[44px]">
                    {GetTimeString(totalTimes?.weekTotalTime ?? 0)}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-span-1">
              <div className="w-full h-28 bg-taskinatorWhite border-2 border-solid  border-taskinatorMedGrey box-border px-7 py-3 flex justify-start items-center gap-x-5 rounded">
                <div className="min-w-[280px] flex h-full flex-col justify-between">
                  <span className="font-bold text-taskinatorDarkGrey">
                    Time This month
                  </span>
                  <span className="font-bold text-taskinatorBlue text-[44px]">
                    {GetTimeString(totalTimes?.monthTotalTime ?? 0)}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full overflow-x-auto">
            <div className=" grid grid-cols-5  bg-taskinatorWhite   text-xs font-semibold py-2 text-taskinatorDarkGrey">
              <div className=" pl-2 border-r-taskinatorLightGrey border-r-2 overflow-clip">
                Job | Project{" "}
              </div>
              <div className=" pl-2 border-r-taskinatorLightGrey border-r-2 overflow-clip">
                Task
              </div>
              <div className=" pl-2 border-r-taskinatorLightGrey border-r-2 overflow-clip">
                Clocked In
              </div>
              <div className=" col-span-1 pl-2 border-r-taskinatorLightGrey border-r-2 overflow-clip">
                Clocked Out
              </div>

              <div className="col-span-1 pl-2 lg:border-r-taskinatorLightGrey lg:border-r-2 overflow-clip lg:flex hidden">
                Time On Task
              </div>
            </div>
          </div>
          {initState[0].started && (
            <div className=" my-4">
              <IconSpinner size="m" />
            </div>
          )}
          {initState[0].error && (
            <div className="text-center w-full text-taskinatorRed my-4 capitalize">
              {initState[0].error}
            </div>
          )}
          {initState[0].finished && timeSheetData.length ? (
            timeSheetData.map((item, itemIndex) => {
              return (
                // <div className="flex w-full">
                <div
                  key={itemIndex}
                  className={`lg:h-10 lg-20 items-center grid lg:grid-cols-5 grid-cols-3  bg-taskinatorWhite my-0.5 py-1 text-sm hover:bg-taskinatorHoverBlue text-taskinatorBlack cursor-pointer `}
                >
                  <div className=" pl-2 border-r-taskinatorLightGrey border-r-2 flex">
                    <div className="capitalize mr-1 ">
                      {item.job}&nbsp;{item.project ? `| ${item.project}` : ``}
                    </div>
                  </div>
                  <div className=" pl-2 border-r-taskinatorLightGrey border-r-2 ">
                    {item.task}
                  </div>
                  <div className=" pl-2 border-r-taskinatorLightGrey border-r-2  ">
                    {item.clockIn ? FormatDate(item.clockIn, true) : ""}
                  </div>
                  <div className="flex justify-between items-center pl-2 border-r-taskinatorLightGrey border-r-2 ">
                    {itemIndex === selectedIndex ? (
                      <div className="w-full flex justify-between bg-taskinatorWhite overflow-visible z-50">
                        <Datetime
                          value={selectedTime}
                          className="w-fit flex"
                          initialValue={FormatDate(item.clockOut, true)}
                          initialViewDate={new Date(item.clockOut)}
                          isValidDate={(current) => {
                            return current.isAfter(
                              moment(new Date(item?.clockIn ?? null)).subtract(
                                1,
                                "day"
                              )
                            );
                          }}
                          dateFormat="DD MMM YYYY [at] "
                          timeFormat="hh:mm A"
                          renderDay={(props, currentDate, selectedDate) =>
                            IsSameDay(item.clockOut, currentDate) ? (
                              <td
                                {...props}
                                className="border border-solid border-taskinatorRed"
                              >
                                {currentDate.date()}
                              </td>
                            ) : (
                              <td {...props}>{currentDate.date()}</td>
                            )
                          }
                          onChange={(value) =>
                            handleCalendarChange(value, item.clockIn)
                          }
                        />
                        <div className="w-fit flex items-center gap-2 mr-4 bg-taskinatorWhite">
                          <span
                            className="hover:text-taskinatorBlue cursor-pointer underline text-sm flex justify-end"
                            onClick={() => handleSave(item?._id ?? "")}
                          >
                            Save
                          </span>
                          <span
                            className="hover:text-taskinatorBlue cursor-pointer underline text-sm flex justify-end"
                            onClick={handleClose}
                          >
                            Close
                          </span>
                        </div>
                      </div>
                    ) : (
                      <>
                        <span className="w-fit">
                          {item.clockOut ? FormatDate(item.clockOut, true) : ""}
                        </span>
                        <span
                          className="hover:text-taskinatorBlue cursor-pointer pr-4 underline text-sm flex justify-end px-4"
                          onClick={() => handleClickEdit(itemIndex)}
                        >
                          Edit
                        </span>
                      </>
                    )}
                  </div>
                  <div className=" pl-2  ">
                    {GetTimeString(item.totalTime, true)}
                  </div>{" "}
                </div>
              );
            })
          ) : (
            <div className="w-full flex justify-center items-center italic">
              No timesheets
            </div>
          )}
        </div>
      </ContentView>
    </>
  );
};

export default Timesheet;

export const IsSameDay = (first, second) => {
  const date1 = new Date(first);
  const date2 = new Date(second);

  const year1 = date1.getFullYear();
  const month1 = date1.getMonth();
  const day1 = date1.getDate();

  const year2 = date2.getFullYear();
  const month2 = date2.getMonth();
  const day2 = date2.getDate();

  const isSameDay = year1 === year2 && month1 === month2 && day1 === day2;
  return isSameDay;
};

export const getStartEndOfToday = () => {
  const currentDate = new Date();

  const startDate = new Date(currentDate);
  startDate.setHours(0, 0, 0);

  const endDate = new Date(startDate);
  endDate.setHours(0, 0, 0);

  return {
    startDate,
    endDate,
  };
};

export const getCurrentWeekRange = () => {
  const currentDate = new Date();
  const currentDay = currentDate.getDay();

  const startDate = new Date(currentDate);
  startDate.setDate(currentDate.getDate() - currentDay);
  startDate.setHours(0, 0, 0);

  const endDate = new Date(startDate);
  endDate.setDate(startDate.getDate() + 6);
  endDate.setHours(0, 0, 0);

  return {
    startDate,
    endDate,
  };
};

export const getMonthRange = () => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth();

  const startDate = new Date(year, month, 1);
  const endDate = new Date(year, month + 1, 0);

  return {
    startDate,
    endDate,
  };
};

export const filterDataByDateRange = (data, startDate, endDate) => {
  return _.filter(data, (item) => {
    const itemDate = new Date(item.clockIn);

    return itemDate >= startDate && itemDate <= endDate;
  });
};
