import React from "react";
import DateDisplay from "../../UI_elements/dateDisplay";
import DocumentDisplay from "../../UI_elements/documentDisplay";
import CountUpTimer from "../../../utils/countUpTimer";

const ActivityDisplay = ({ actionComment, row, textSize }) => {
  return (
    <div className={`font-medium    ${textSize ? textSize : "text-xxs"}  ${row ? "flex flex-col justify-center" : "p-2 rounded border-taskinatorMedGrey border "}`}>
      {" "}
      <div className={`${row ? "flex" : ""} pb-2 items-center`}>
        {actionComment.job && (
          <div className="flex pr-2">
            <div className="font-medium pr-1">Job Ref:</div>
            <div className="font-normal capitalize">{actionComment.job.ref}</div>
          </div>
        )}
        {actionComment.workflow && (
          <div className="flex pr-2">
            <div className="font-medium pr-1">Workflow:</div>
            <div className="font-normal capitalize">{actionComment.workflow.name}</div>
          </div>
        )}
        {actionComment.task && (
          <div className="flex pr-2">
            <div className="font-medium pr-1">Task Ref:</div>
            <div className="font-normal capitalize">{actionComment.task.name}</div>
          </div>
        )}
        {actionComment.creatorName && (
          <div className="flex pr-2">
            <div className="font-medium pr-1">Creator:</div>
            <div className="font-normal capitalize">{actionComment.creatorName}</div>
          </div>
        )}
        {actionComment.createdDate && (
          <div className="flex pr-2">
            <div className="font-medium pr-1">Created:</div>
            <div className="font-normal capitalize flex"><CountUpTimer startDate={actionComment.createdDate} />  <div className="pl-2">(Ago)</div></div>
          </div>
        )}
        {actionComment.logDescription && (
          <div className="flex pr-2">
            <div className="font-medium pr-1">Description:</div>
            <div className="font-normal capitalize">{actionComment.logDescription}</div>
          </div>
        )}
        {actionComment.comments && (
          <div className="flex pr-2">
            <div className="font-medium pr-1">Submission Comments:</div>
            <div className="font-normal capitalize">{actionComment.comments}</div>
          </div>
        )}
        {actionComment.attachments && (
          <div className="flex items-center pr-2">
            <div className="font-medium pr-1">Attachments:</div>
            <div className="font-normal capitalize">
              {actionComment.attachments.length > 0
                ? actionComment.attachments.map((attachment) => {
                    return <DocumentDisplay attachment={attachment} />;
                  })
                : "No Attachments"}
            </div>
          </div>
        )}
      </div>
      {actionComment.item ? (
        <div className={`pt-2 bg-taskinatorItemBlue p-1 ${row ? "flex" : ""} items-center rounded px-2`}>
          {!row && <div className="font-bold uppercase">Drawing Details:</div>}
          <div className="flex pr-2">
            <div className="font-medium pr-1">Name:</div>
            <div className="font-normal capitalize">{actionComment.item.itemName}</div>
          </div>
          <div className="flex pr-2">
            <div className="font-medium pr-1">Number:</div>
            <div className="font-normal capitalize">{actionComment.item.itemNumber}</div>
          </div>
          <div className="flex pr-2">
            <div className="font-medium pr-1">Revision:</div>
            <div className="font-normal capitalize">{actionComment.item.revision}</div>
          </div>
          <div className="flex pr-2">
            <div className="font-medium pr-1">Status:</div>
            <div className="font-normal capitalize">{actionComment.item.status}</div>
          </div>
          <div className="flex items-center pr-2">
            <div className="font-medium pr-1 ">Drawing:</div>
            <div className="font-normal capitalize">{actionComment.item.attachment ? <DocumentDisplay attachment={actionComment.item.attachment} /> : "None"}</div>
          </div>
        </div>
      ) : (
        <div className="pt-2 font-normal pr-2 p-2 rounded border-taskinatorMedGrey border ">No Drawing</div>
      )}
    </div>
  );
};

export default ActivityDisplay;
