import React, { useContext } from "react";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { AppContext } from "../../contexts/appContext";
import { useInit } from "../hooks/useInit";
import AddStepDisplayButton from "./addStepDisplayButton";
import NewActionForm from "../forms/newActionForm";

const WorkflowActionDisplay = ({ workflowState, dispatch, teamData, stageIndex, stepId, totalNumberOfStages, removeButtonStyle }) => {
  const StageData = workflowState.stages[stageIndex];

  return (
    <div className="flex flex-col">
      {StageData.length === 0 && (
        <div
          className="border border-taskinatorBlue rounded text-center py-1 px-2  text-taskinatorBlue hover:bg-taskinatorBlue hover:text-taskinatorWhite cursor-pointer text-sm"
          onClick={() => dispatch({ type: "ADD_ACTION", actionIndex: 0, stageIndex: stageIndex })}
        >
          Add
        </div>
      )}
      {StageData.length > 0 &&
        StageData.map((action, actionIndex) => {
          return (
            <div key={action._id ? action._id : actionIndex} className="flex flex-col items-center">
              {/* {stageIndex !== 0 && actionIndex === 0 && totalNumberOfStages - 1 > stageIndex &&  <AddStepDisplayButton customClasses={"w-36 h-6"} action onClick={() => dispatch({ type: "ADD_ACTION", stageIndex: stageIndex, actionIndex: actionIndex - 1 })} />} */}
              <div className="flex items-center justify-center  w-full">
                <NewActionForm
                  action={action}
                  dispatch={dispatch}
                  workflowState={workflowState}
                  teamData={teamData}
                  stageIndex={stageIndex}
                  removeButtonStyle={removeButtonStyle}
                  actionIndex={actionIndex}
                ></NewActionForm>
              </div>
              {/* {stageIndex !== 0 && totalNumberOfStages > stageIndex && totalNumberOfStages - 1 > stageIndex && <AddStepDisplayButton customClasses={" w-36 h-6"} action onClick={() => dispatch({ type: "ADD_ACTION", stageIndex: stageIndex, actionIndex: actionIndex })} />} */}
            </div>
          );
        })}
    </div>
  );
};

export default WorkflowActionDisplay;
