import React, { useReducer, useState, useEffect, useContext, useRef } from "react";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import WorkflowNextWorkflow from "./workflowNextWorkflow";
import { useInit } from "../hooks/useInit";
import { AppContext } from "../../contexts/appContext";

import IconSpinner from "../UI_elements/iconSpinner";

import WorkflowStageDisplay from "./workflowStageDisplay";
import { AreArraysSame } from "../../utils/areArraysSame";
import ToolTip from "../../utils/toolTip";

const Validation = (state) => {
  if (state.stages.length === 0) {
    return { state: false, reason: "No Tasks" };
  }
  if (state.stages[0].length !== 1) {
    return { state: false, reason: "Can Only Have A Single First Task" };
  }

  // Check if the last element has length 1
  if (state.stages[state.stages.length - 1].length !== 1) {
    return { state: false, reason: "Can Only Have A Single Last Task" };
  }

  for (let i = 0; i < state.stages.length; i++) {
    const stage = state.stages[i];

    if (!stage || stage.length === 0) {
      return { status: false, reason: "Must Have A Task Within The Stage" };
    }

    for (let j = 0; j < stage.length; j++) {
      const action = stage[j];

      if (!action.isValid || !action._id) {
        return { status: false, reason: "Must Set Task Details" };
      }

    

      if (action.thirdParty) {
        return { status: false, reason: "Third Party Task Disabled - Please Delete" };
      }
    }
  }

  return { status: true };
};

const workflowReducer = (state, action) => {
  const newActionId = uuidv4();

  const newStage = [];
  const currentStageData = state.stages[action.stageIndex];

  let updatedStageData = state.stages;
  let updatedActionData = state.actions;
  let stateUpdates;

  switch (action.type) {
    case "ADD_STAGE":
      stateUpdates = { ...state, stages: [...state.stages.slice(0, action.stageIndex + 1), newStage, ...state.stages.slice(action.stageIndex + 1)] };
      return { ...stateUpdates, isValid: Validation(stateUpdates) };

    case "REMOVE_STAGE":
      stateUpdates = {
        ...state,
        stages: [...state.stages.slice(0, action.stageIndex), ...state.stages.slice(action.stageIndex + 1)],
      };

      return {
        ...stateUpdates,
        isValid: Validation(stateUpdates),
      };

    case "ADD_ACTION":
      updatedStageData[action.stageIndex] = [...currentStageData.slice(0, action.actionIndex + 1), newActionId, ...currentStageData.slice(action.actionIndex + 1)];

      stateUpdates = {
        ...state,
        stages: updatedStageData,
        // actions: {
        //   ...state.actions,
        //   [newActionId]: "",
        // },
      };

      return {
        ...stateUpdates,
        isValid: Validation(stateUpdates),
      };

    case "SAVE_ACTION":
      updatedStageData[action.stageIndex][action.actionIndex] = action.actionData;

      stateUpdates = {
        ...state,
        stages: updatedStageData,
        // actions: { ...state.actions, [action.actionId]: action.actionData },
      };

      return { ...stateUpdates, isValid: Validation(stateUpdates) };

    case "REMOVE_ACTION":
      updatedStageData[action.stageIndex] = [...currentStageData.slice(0, action.actionIndex), ...currentStageData.slice(action.actionIndex + 1)];

      stateUpdates = {
        ...state,
        stages: updatedStageData,
      };

      return { ...stateUpdates, isValid: Validation(stateUpdates) };

    case "NEXT_WORKFLOWS":
      return { ...state, nextAvailableWorkflows: action.nextAvailableWorkflows };

    case "TAG_NOTIFICATION":
      return { ...state, accessTagsToNotify: action.accessTagsToNotify };

    case "ITEM_TAG_NOTIFICATION":
      return { ...state, notifyByItemTags: action.isChecked };

    case "WORKFLOW_MANAGER_NOTIFICATION":
      return { ...state, notifyWorkflowManagers: action.isChecked };

    case "REVERT BACK":
      return { ...state };

    case "STATE_REFRESH":
      return action.updatedState;

    default:
      return state;
  }
};

const WorkflowDisplay = ({ zoom, teamData, workflowData, setStatus, workflowOptions }) => {
  const [workflowState, dispatch] = useReducer(workflowReducer, { ...workflowData, isValid: Validation(workflowData) });

  const [defaultStateOnLoad, setDefaultStateOnLoad] = useState(workflowData);
  
  const appData = useContext(AppContext);
  const { initState, initStarted, initCompleted, initErrors } = useInit(2);

  useEffect(() => {
    setStatus(workflowState.isValid);
  }, [workflowState.isValid]);

  const SaveWorkFlowHandler = () => {
    let actionIdsOnly = {};

    console.log("saving")
    // Object.keys(workflowState.actions).map((key) => (actionIdsOnly = { ...actionIdsOnly, [key]: workflowState.actions[key]._id }));
   if(initState[0].started) return;
   
    initStarted(0);
    axios
      .put(
        process.env.REACT_APP_BACKEND_URL + `/api/workflow/save/${workflowState._id}`,
        {
          stages: workflowState.stages,
          nextAvailableWorkflows: workflowState.nextAvailableWorkflows,
          accessTagsToNotify: workflowState.accessTagsToNotify,
          notifyByItemTags: workflowState.notifyByItemTags,
          notifyWorkflowManagers: workflowState.notifyWorkflowManagers,
          isValid: workflowState.isValid,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + appData.tokenV4,
          },
          withCredentials: true,
        }
      )
      .then((responseData) => {
        initCompleted(0);
      })
      .catch((err) => {
        console.log(err);
        initErrors(err.response.data.message, 0);
      });
  };

  /// this check to ensure that any action value in the action state is a valid action with a _id value
  const dontSaveYetAsActionNotCompleted =
    workflowState.stages
      .map((stage) =>
        stage
          .map((action) => {
            if (!action._id) {
              return true;
            }
          })
          .filter((data) => data)
      )
      .flat().length === 0;

  ///this section is a delay timer to auto save the state
  // const [delayTime, setDelayTime] = useState();
  // const [isFirstRender, setIsFirstRender] = useState(true);
  const [remainingPercentage, setRemainingPercentage] = useState(100);

  const isFirstRender = useRef(true);
  const delayTime = useRef(null);


  useEffect(() => {
    SaveWorkFlowHandler();
  }, [workflowState.isValid]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    // Delay the effect by 3 seconds
    const delay = setTimeout(() => {
      if (dontSaveYetAsActionNotCompleted && AreArraysSame(defaultStateOnLoad.stages.flat(1), workflowState.stages.flat(1))) {
        if (delayTime.current) {
          clearTimeout(delayTime.current);
        }
        const newTimer = setTimeout(() => {
          SaveWorkFlowHandler();
        }, 1000);

        delayTime.current = newTimer;
      }
    }, 3000); // 3 seconds delay

    return () => {
      clearTimeout(delay);
      if (delayTime.current) {
        clearTimeout(delayTime.current);
      }
    };
  }, [workflowState, workflowState.isValid]);

  const removeButtonStyle = "text-xs text-taskinatorRed cursor-pointer hover:opacity-100 opacity-30 text-center";

  return (
    <div className={`w-full overflow-auto  flex flex-col `}>
      <div className={`overflow-auto h-fit scrollbar p-4`}>
        {/* <div className={`${zoom} transform transition duration-500  grow overflow-auto h-fit scrollbar`}> */}
        <WorkflowStageDisplay
          workflowState={workflowState}
          teamData={teamData}
          dispatch={dispatch}
          removeButtonStyle={removeButtonStyle}
          workflowOptions={workflowOptions}
          workflowData={workflowData}
        />
      </div>
      {/* <div className="border-y border-y-1 border-y-taskinatorMedGrey flex flex-col p-4 text-sm items-center">
        {" "}
        <div className="flex">
          Next Sequence To Trigger? (Optional) <ToolTip content={"Select which sequence you would like to make available once this sequence gets to the last step"} />
        </div>
        <div>
          {" "}
          <WorkflowNextWorkflow workflowOptions={workflowOptions} currentWorkFlow={workflowData} dispatch={dispatch} />
        </div>
      </div> */}
      {initState[0].started && (
        <div className="flex justify-center w-full text-xs text-center p-2">
          <IconSpinner /> Saving...
        </div>
      )}
      {initState[0].error && <div className="flex justify-center w-full text-xs text-center text-taskinatorRed">{initState[0].error}</div>}
    </div>
  );
};

export default WorkflowDisplay;
