import React from "react";

const ActionTaskViewCard = ({ label, canEdit, children, editFunction, customCSS, smallLabel, editInProgress, editInProgressText, editText}) => {
  return (
    <div className={`bg-taskinatorWhite flex flex-col scrollbar ${customCSS}  `}>
    <div className="p-4 md:h-full h-fit md:overflow-auto flex flex-col">
    { <div className={`flex items-center  ${(label )  ? "border-b border-b-taskinatorMedGrey mb-2" : ""}   `}>
         <div className={`${smallLabel ? "text-[0.6rem]" : "text-sm font-bold opacity-60 text-taskinatorDarkGrey"}  `}>{label}</div>
       {canEdit && <div className="flex grow justify-end text-sm text-taskinatorBlue pr-2 cursor-pointer hover:opacity-50" onClick={()=>{editFunction()}}>{editInProgress ? `${editInProgressText ?  editInProgressText : "Close"}` : `${editText ? editText : "Edit"}`}</div>}
      </div>}
   {children}
    </div>
    </div>
  );
};

export default ActionTaskViewCard;
