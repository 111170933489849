import React, { useState, useContext } from "react";
import { AppContext } from "../../contexts/appContext";
import ContentView from "../../pageTemplates/contentView";
import ItemGetAndSearchContainer from "../../components/displayElements/items/itemGetAndSearchContainer";
import BulkFileUpload from "../../components/displayElements/files/bulkFileUpload";

const Item = () => {
  const [buttons, setButtons] = useState([]);
  const appData = useContext(AppContext);
  const setEditButton = (editButton) => {
    setButtons([editButton]);
  };

  return (
    <ContentView buttons={buttons} label={`${appData.itemName} Log`}>
      <ItemGetAndSearchContainer viewOnly setEditButton={(data) => setEditButton(data)} isRegister />
    </ContentView>
  );
};

export default Item;
