import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useInit } from "../components/hooks/useInit";
import { AppContext } from "../contexts/appContext";

export const useGetData = (settings) => {
  const appData = useContext(AppContext);
  const { initState, initStarted, initCompleted, initErrors } = useInit();

  const [data, setData] = useState({});

  const getData = () => {
    initStarted(0);

    axios
      .get(process.env.REACT_APP_BACKEND_URL + `/api/${settings.type}/retrieve/${settings.url}`, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      .then((response) => {
  
        setData(response.data.data);

        initCompleted(0);
      })
      .catch((err) => {
        console.log(err);
        const errorMessage = err?.response?.data?.message ?? err?.message ?? "";
        initErrors(errorMessage, 0);
      });
  };

  /// this timer pulses to get the latest data from the server
  useEffect(() => {
    // if (
    //   appData.workspaceDataV4 &&
    //   appData.workspaceDataV4.authenticated &&
    //   !appData.isModalOpened
    // ) {
    getData();

    // const interval = setInterval(() => {
    //   getData();

    // }, process.env.REACT_APP_SERVER_CHECK_TIME);
    // Cleanup function to clear the interval when the component is unmounted
    // return () => clearInterval(interval);
    // }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appData.workspaceDataV4, appData.isModalOpened]);

  return [data, initState, getData];
};
