import { v4 as uuidv4 } from "uuid";
import LowerCaseTrim from "./lowerCaseAndTrim";
const JobFormatter = (jobs, filter, appData) => {
  let formattedJobs = {};

  // if (Object.values(jobs).length) {
  //   debugger;
  // }

  Object.values(jobs)
    .filter((job) => {
      if (appData.tagFilter["all tags"]) {
        return true;
      } else if (appData.tagFilter["no tags"]) {
        return job.jobDetails.taskTags.length === 0;
      } else {
        return job.jobDetails.taskTags.filter((tag) => Object.keys(appData.tagFilter).includes(tag.name)).length > 0;
      }
    })
    .filter((job) => {
      if (!filter || filter.trim().length === 0) {
        return true;
      }

      const lowerCaseTrim = (str) => str?.toLowerCase().trim();
      const lowerCaseFilter = lowerCaseTrim(filter);

      return (
        lowerCaseTrim(job.jobDetails.ref)?.includes(lowerCaseFilter) ||
        job.jobDetails.taskTags.some((tag) => lowerCaseTrim(tag.name)?.includes(lowerCaseFilter)) ||
        job?.workflows?.some((workflow) => {
          if (lowerCaseTrim(workflow?.name)?.includes(lowerCaseFilter)) {
            return true;
          }

          if (lowerCaseTrim(workflow?.fileSetDetails?.name)?.includes(lowerCaseFilter)) {
            return true;
          }

          if (lowerCaseTrim(workflow?.fileSetDetails?.number)?.includes(lowerCaseFilter)) {
            return true;
          }

          // THIS IS A MEGGA CHANGE COMMENT PLEASE FIX THIS

          return workflow.tasks.some((task) => {
            if (lowerCaseTrim(task?.taskDetails?.name)?.includes(lowerCaseFilter)) {
              return true;
            }
          });
        }) ||
        job.files.some((file) => {
          return file?.currentJobs?.some((wf) => {
            const workflowData = job.workflows.find((workflow) => workflow.workflowGroupId === wf.workflowGroupId);

            return workflowData?.tasks
              ?.filter((task) => task._id.toString() === wf.taskId.toString())
              .map((task) => task?.assignee)
              .flat(1)
              .some((user) => {
                if ((user && lowerCaseTrim(user?.firstName)?.includes(lowerCaseFilter)) || lowerCaseTrim(user?.lastName)?.includes(lowerCaseFilter)) {
                  return true;
                }
              });
          });
        })
      );
    })

    .sort((a, b) => {
      // Convert dates to Date objects for accurate comparison
      const dateA = new Date(a.jobDetails.createdDate);
      const dateB = new Date(b.jobDetails.createdDate);

      return dateB - dateA; // Ascending order
      // return dateB - dateA; // For descending order
    })
    .map((job) => {
      let fileMapObject = {};
      let taskMapObject = {};
      let taskVSFiles = {};
      let tasksMap = {};
      let workflowMap = {};
      let count = 0;
      let activeAssignees = {};
      let tasksWithJobData = [];

      (job?.tasks ?? []).forEach((task) => {
        tasksWithJobData = [
          ...tasksWithJobData,
          {
            ...task,
            primaryTaskDetails: job.workflows.find((workflow) => workflow.workflowGroupId === task.workflowGroupId).tasks.find((t) => t?.taskUUID === task?.currentJobTaskUUID),
            workflowData: job.workflows.find((workflow) => workflow.workflowGroupId === task.workflowGroupId),
            fileSetDetails: job.workflows.find((workflow) => workflow.workflowGroupId === task.workflowGroupId).fileSetDetails,
          },
        ];
      });

      /// here we set up the workflow maps for any active task that the user is assigned to.
      job.workflows.map((workflow) => {
        const fileSetId = workflow?.fileSetDetails?._id;

        if (!workflowMap?.[fileSetId]?.[workflow.workflowGroupId]) {
          // Initialize the workflow in the map if it doesn't exist

          if (!workflowMap[fileSetId]) {
            workflowMap[fileSetId] = {};
          }

          let activeTaskObject = {};

          const activeTasks = workflow.tasks.map((task) => {
            if (
              (!task.markedAsCompleted && (task?.assignee ?? []).map((user) => user._id.toString()).includes(appData.workspaceDataV4.user_id)) ||
              (workflow?.managers ?? []).map((user) => user._id.toString()).includes(appData.workspaceDataV4.user_id)
            ) {
              activeTaskObject = { ...activeTaskObject, [task._id]: task };
              return task;
            }
          });

          const placeHolders = Object.values(activeTaskObject)
            .map((task) => {
              const placeHolderFileId = uuidv4();

              if (!job.files.find((file) => file.currentJobs.find((job) => job.taskUUID === task.taskUUID))) {
                return {
                  currentJobs: [
                    {
                      workflowId: workflow._id.toString(),
                      workflowName: workflow.name,
                      workflowGroupId: workflow.workflowGroupId,
                      submissionId: uuidv4(),
                      jobId: job._id.toString(),
                      jobRef: job.jobDetails.ref,
                      taskId: task._id.toString(),
                      taskUUID: task.taskUUID,
                      taskName: task.taskDetails.name,
                      stageIndex: task.stageId,
                      seen: [],
                    },
                  ],
                  revisionTrackingId: placeHolderFileId,
                  _id: placeHolderFileId,
                  key: placeHolderFileId,
                  fileName: process.env.REACT_APP_PLACEHOLDER_CODE,
                  stageIndex: placeHolderFileId,
                };
              }
            })
            .filter((data) => data);

          if (Object.values(activeTaskObject).length > 0) {
            workflowMap[fileSetId][workflow.workflowGroupId] = {
              _id: workflow._id,
              files: placeHolders,
              tasks: [],
              activeTasks: activeTaskObject,
              name: workflow.name,
              newFileCount: {},
              workflowData: workflow,
              setWorkflow: !workflow.workflowMap || workflow.tasks.find((task) => task.assignee.length === 0) ? true : false,
            };
          }
        }

        fileMapObject = {
          ...fileMapObject,
          [fileSetId]: {
            name: workflow.fileSetDetails.name,
            number: workflow.fileSetDetails.number,
            fileSetId: fileSetId,
            uuid: uuidv4(),
            fileSetName: workflow.fileSetDetails.fileSetName,
            setting: workflow.fileSetDetails.settings,
            workflows: Object.values(workflowMap[fileSetId]),
          },
        };
      });

      job.files.forEach((file, index) => {
        let fileSetId;
        let workflowData;

        let fileData =
          file?.currentJobs.length > 0
            ? file.currentJobs
            : file.currentJobHistory.length > 0
            ? [
                file.currentJobHistory.reduce((max, current) => {
                  // Convert stageIndex from string to number
                  const currentStageIndex = Number(current.stageIndex);
                  const maxStageIndex = Number(max.stageIndex);
                  return currentStageIndex > maxStageIndex ? current : max;
                }, file.currentJobHistory[0]),
              ].filter((file) => file.submissionDirection !== "checked in")
            : [];

        let activeTasks = {};

        fileData.forEach((wf) => {
          workflowData = job.workflows.find((workflow) => workflow.workflowGroupId === wf.workflowGroupId);

          /// this is formatting to work with the new task layout
          const taskDataForThisWorkflow = workflowData?.tasks?.find((task) => task._id.toString() === wf.taskId.toString());
          const taskOnThisWorkflow = job?.tasks?.filter((tasklist) => tasklist.workflowGroupId === wf.workflowGroupId && tasklist.currentJobTaskUUID === taskDataForThisWorkflow.taskUUID);

          fileSetId = workflowData?.fileSetDetails?._id;

          if (!workflowMap?.[fileSetId]?.[wf.workflowGroupId]) {
            // Initialize the workflow in the map if it doesn't exist

            if (!workflowMap[fileSetId]) {
              workflowMap[fileSetId] = {};
            }

            workflowMap[fileSetId][wf.workflowGroupId] = {
              _id: wf.workflowId,
              files: [],
              tasks: taskOnThisWorkflow,
              activeTasks: [],
              name: job.workflows.find((wft) => wft.workflowGroupId === wf.workflowGroupId).name,
              newFileCount: {},
              workflowData: workflowData,
              setWorkflow: !workflowData.workflowMap || workflowData.tasks.find((task) => task.assignee.length === 0) ? true : false,
            };
          }

          // console.log(workflowMap, "workflow mpa checker");

          const jobDataToSelect = file?.currentJobs.length > 0 ? file.currentJobs : file.currentJobHistory;

          // Add file to files array if not already added (avoid duplication)
          if (!workflowMap?.[fileSetId]?.[wf.workflowGroupId]?.files[file._id]) {
            workflowMap[fileSetId][wf.workflowGroupId].files = {
              ...workflowMap[fileSetId][wf.workflowGroupId].files,
              [wf._id]: { ...file, currentJobs: jobDataToSelect },
            };
          }

          if (!workflowMap?.[fileSetId]?.[wf.workflowGroupId]?.activeTasks[taskDataForThisWorkflow._id]) {
            workflowMap[fileSetId][wf.workflowGroupId].activeTasks = {
              ...workflowMap[fileSetId][wf.workflowGroupId].activeTasks,
              [taskDataForThisWorkflow._id]: {
                ...taskDataForThisWorkflow,
                numberOfTasks: taskOnThisWorkflow?.filter(
                  (task) =>
                    task.assignee
                      .map((user) => {
                        return user._id.toString();
                      })
                      .includes(appData.workspaceDataV4.user_id) && !task.completed
                ).length,
              },
            };
          }

          fileData.forEach((cfp, index) => {
            if (
              workflowMap[fileSetId][wf.workflowGroupId] &&
              !cfp.seen.find((user) => user._id.toString() === appData.workspaceDataV4.user_id) &&
              !cfp.referenceOnly &&
              activeAssignees?.[appData.workspaceDataV4.user_id]
            ) {
              workflowMap[fileSetId][wf.workflowGroupId].newFileCount = {
                ...workflowMap[fileSetId][wf.workflowGroupId].newFileCount,
                [`${file._id.toString()}-${wf.workflowGroupId}`]: wf.workflowGroupId,
              };
            }
          });
        });

        if (workflowData) {
          taskMapObject = {
            ...taskMapObject,
            name: workflowData.fileSetDetails.name,
            number: workflowData.fileSetDetails.number,
            fileSetId: fileSetId,
            uuid: uuidv4(),
            fileSetName: workflowData.fileSetDetails.fileSetName,
            setting: workflowData.fileSetDetails.settings,
          };

          fileMapObject = {
            ...fileMapObject,
            [fileSetId]: {
              name: workflowData.fileSetDetails.name,
              number: workflowData.fileSetDetails.number,
              fileSetId: fileSetId,
              uuid: uuidv4(),
              fileSetName: workflowData.fileSetDetails.fileSetName,
              setting: workflowData.fileSetDetails.settings,
              workflows: Object.values(workflowMap[fileSetId]),
            },
          };
        } else {
        }
      });

      formattedJobs = {
        ...formattedJobs,
        [job._id]: {
          ...job,
          activeTeamOnTask: Object.values(activeAssignees ?? {}),
          fileMapObject: fileMapObject,
          taskVSFiles: taskVSFiles,
          tasksMap: tasksMap,
          tasks: tasksWithJobData,
        },
      };
    });

  return formattedJobs;
};

export default JobFormatter;
