import React, {useContext} from "react";
import FileSetDropdown from "./fileSetDropdown";
import { JobModalContext } from "./jobModalContext";
const WorkflowGroup = ({ fileSet }) => {

  const jobData = useContext(JobModalContext);

  return fileSet.workflows
    .sort((a, b) => {
      if (b.name > a.name) {
        return -1;
      } else if (b.name < a.name) {
        return 1;
      }
      return 0;
    })
    .filter(workflow => Object.values(jobData.selectedTaskIds).length === 0 ? true : Object.values(jobData.selectedTaskIds).map(selections => selections.workflowId).flat(1).includes(workflow._id))
    .map((selectedWorkflowData) => {
      return (
        <div className="mb-2" key={selectedWorkflowData._id}>
          <FileSetDropdown selectedWorkflowData={selectedWorkflowData} fileSetId={fileSet.fileSetId} fileSetName={fileSet.name} />
        </div>
      );
    });
};

export default WorkflowGroup;
