import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../contexts/appContext";
import Button from "../../UI_elements/button";
import IconSpinner from "../../UI_elements/iconSpinner";
import StringShortenDisplay from "../../UI_elements/stringShortenDisplay";
import { useInit } from "../../hooks/useInit";
import ProjectDetailsDisplay from "./projectDetailsDisplay";
import ProjectJobDisplay from "./projectJobDisplay";

const ProjectView = ({ pid }) => {
  const appData = useContext(AppContext);
  const { initState, initStarted, initCompleted, initErrors } = useInit(4);
  const [projectData, setProjectData] = useState();

  const getData = () => {
    initStarted(2);
    if (pid) {
      axios
        .get(
          process.env.REACT_APP_BACKEND_URL +
            `/api/project/retrieve/all/${pid}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        )
        .then((response) => {
          setProjectData(response.data.data);

          initCompleted(2);
        })
        .catch((err) => {
          console.log(err);
          const errorMessage =
            err?.response?.data?.message ?? err?.message ?? "";
          initErrors(errorMessage, 2);
        });
    }
  };

  useEffect(() => {
    if (
      appData.workspaceDataV4 &&
      appData.workspaceDataV4.authenticated &&
      pid
    ) {
      getData();

      const interval = setInterval(() => {
        getData();
      }, process.env.REACT_APP_SERVER_CHECK_TIME);
      // Cleanup function to clear the interval when the component is unmounted
      return () => clearInterval(interval);
    }
  }, [appData.workspaceDataV4, pid]);

  useEffect(() => {
    if (
      projectData &&
      projectData.seen.filter(
        (seen) => seen._id === appData.workspaceDataV4.user_id
      ).length === 0
    ) {
      initStarted(4);
      axios
        .get(
          process.env.REACT_APP_BACKEND_URL + `/api/project/markasseen/${pid}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        )
        .then((response) => {
          initCompleted(4);
        })
        .catch((err) => {
          console.log(err);
          const errorMessage =
            err?.response?.data?.message ?? err?.message ?? "";
          initErrors(errorMessage, 4);
        });
    }
  }, [projectData]);

  const closeProjectHandler = () => {
    initStarted(3);
    axios
      .post(
        process.env.REACT_APP_BACKEND_URL + `/api/project/close/${pid}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        initCompleted(3);
        appData.setViewProject();
      })
      .catch((err) => {
        console.log(err);
        const errorMessage = err?.response?.data?.message ?? err?.message ?? "";
        initErrors(errorMessage, 3);
      });
  };

  return (
    <>
      {initState[2].started && !initState[2].initialised && (
        <div className="w-full h-full flex justify-center items-center">
          <IconSpinner size="m" /> Loading Data...
        </div>
      )}
      {initState[2].initialised && projectData && (
        <div className="w-full lg:h-full h-fit flex xl:flex-row flex-col grow overflow-auto">
          <div className="flex flex-col xl:grow h-fit xl:w-3/4 xl:overflow-auto mr-1">
            <div className="text-sm px-4 text-taskinatorWhite flex bg-taskinatorPurple rounded-lg max-h-12 min-h-12 flex-col justify-center items-start">
              <div className={`flex w-full md:flex-row flex-col`}>
                <div
                  className={`flex w-full md:items-center items-start md:justify-start justify-center md:pl-1 md:flex-row flex-col`}
                >
                  <div className="flex items-center">
                    <div className="flex text-lg uppercase">
                      Project —{" "}
                      <b className="pl-1">
                        {StringShortenDisplay(30, projectData.ref)}
                      </b>{" "}
                    </div>
                    <div className="flex justify-end"></div>
                  </div>
                </div>

                {/* <div className="flex items-center text-sm font-light text-taskinatorMedGrey">
                  <div className="whitespace-nowrap pr-1">Seen By:</div>
                  <div className="flex w-fit">
                    {projectData.seen.length > 0 &&
                      projectData.seen.map((user, index) => {
                        return (
                          <div key={user._id} className="flex capitalize font-medium">
                          
                            <div className="flex pr-1 whitespace-nowrap">
                              <div className="pr-1">{user.firstName}</div>
                              <div>{user.lastName[0]}</div>
                              {projectData.seen.length > 1 && <div>,</div>}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div> */}
              </div>
            </div>

            <div className="w-full flex lg:flex-row flex-col overflow-auto grow">
              <div
                className={`flex xl:flex-col grow flex-col-reverse h-fit overflow-auto`}
              >
                <div
                  className={`bg-taskinatorHoverPurple border border-taskinatorMedGrey rounded-lg mr-1 mt-2 p-2 overflow-auto flex flex-col h-fit grow scrollbar`}
                >
                  <div className="pt-1 h-fit">
                    {" "}
                    <ProjectDetailsDisplay
                      data={projectData}
                      getData={() => getData()}
                    />
                  </div>
                  <div className="pt-1 h-fit">
                    <ProjectJobDisplay
                      projectData={projectData}
                      getData={() => getData()}
                    />
                  </div>

                  {/* <div className="pt-1 h-fit">
                    <ProjectJobDataAndScopes projectData={projectData} getData={() => getData()} />
                  </div> */}
                </div>
                <div className="flex justify-center p-2 border mr-1 mt-1 rounded-lg border-taskinatorMedGrey">
                  <div className="flex text-sm w-full items-center pt-1">
                    <div className="w-fit whitespace-nowrap pr-1 font-medium">
                      Seen By:
                    </div>
                    <div className="flex w-full">
                      {projectData &&
                        projectData.seen.map((user, index) => {
                          return (
                            <div key={user._id} className="flex capitalize">
                              {/* {user.firstName} */}
                              <div className="flex pr-1">
                                <div className="pr-1">{user.firstName}</div>
                                <div>{user.lastName[0]}</div>
                                {index > 0 && <div>,</div>}
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                  <div className="flex grow justify-end">
                    <Button
                      label="Close Project"
                      disabled={projectData.atLeastOneJobOpen}
                      customClasses={`${
                        projectData.atLeastOneJobOpen
                          ? "bg-taskinatorMedGrey"
                          : "bg-taskinatorGreen"
                      } h-10 uppercase text-taskinatorWhite px-2`}
                      onClick={() => closeProjectHandler()}
                      isLoading={initState[3].started}
                      error={initState[3].error}
                    />
                  </div>
                </div>
              </div>
              {/* <div className={`mt-2 lg:w-96 shrink-0  border-taskinatorMedGrey overflow-auto flex flex-col  bg-taskinatorLightGrey`}>
                <div className=" border border-taskinatorMedGrey border-b-0 rounded-t-lg  flex flex-col overflow-auto scrollbar grow">
            
                </div>

                <div className="w-full border border-taskinatorMedGrey p-2 border-t-2 rounded-b-lg">
              
                </div>
              </div> */}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProjectView;
