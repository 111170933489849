import LowerCaseTrim from "./lowerCaseAndTrim";

const RequestFormatter = (requestData, filter, appData) => {
  let formattedRequests = {};

  Object.values(requestData ?? {})
    .filter((request) => {
      if (!appData.tagFilter || appData.tagFilter["all tags"]) {
        return true;
      } else {
        return (
          appData.tagFilter &&
          Object.values(appData.tagFilter)
            .map((key) => appData.jobListFilterValues[key])
            .flat(1)
            .includes(request?.tid?._id?.toString())
        );
      }
    })
    .filter((request) => {
      if (!filter) {
        return true;
      } else {
        if (filter.trim().length === 0) {
          return true;
        } else {
          return (
            (request?.tid?.taskDetails?.ref &&
              LowerCaseTrim(request?.tid?.taskDetails?.ref).includes(
                LowerCaseTrim(filter)
              )) ||
            request.collaborators.filter((assignee) =>
              LowerCaseTrim(assignee.lastName).includes(LowerCaseTrim(filter))
            ).length > 0 ||
            request.collaborators.filter((assignee) =>
              LowerCaseTrim(assignee.firstName).includes(LowerCaseTrim(filter))
            ).length > 0
          );
        }
      }
    })
    .sort((a, b) => {
      // Prioritize non-completed items over completed ones
      if (a.completed !== b.completed) {
        return a.completed ? 1 : -1;
      }

      // If both items have the same completed status, compare their dates
      let dateA = new Date(a.createdBy.date);
      let dateB = new Date(b.createdBy.date);

      return dateB - dateA;
    })

    .map((filteredRequests) => {
      const { tid, ...requestDetails } = filteredRequests;

      const idToUse = tid?._id ?? tid;

      const updatedRequestDetails = formattedRequests[idToUse]
        ? {
            ...formattedRequests[idToUse].elementData,
            [filteredRequests._id]: { ...requestDetails },
          }
        : {
            [filteredRequests._id]: {
              ...requestDetails,
              ...tid.taskDetails,
              tid: idToUse,
            },
          };

      formattedRequests = {
        ...formattedRequests,
        [idToUse]: {
          ...tid.taskDetails,
          tid: idToUse,
          elementData: updatedRequestDetails,
        },
      };

      return null;
    });

  return formattedRequests;
};

export default RequestFormatter;
