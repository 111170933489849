import axios from "axios";
import React, { useContext, useEffect, useState} from "react";
import { AppContext } from "../../contexts/appContext";
import { useInputV4 } from "../../stateManagement/useInputV4";
import { useInit } from "../hooks/useInit";
import TagSelector from "../dataSelectors/tagSelector";
import Button from "../UI_elements/button";
import Input_V4 from "../inputs/input_V4";
import { VALIDATOR_EMAIL } from "./validators/validators";
import WorkflowNextWorkflow from "../displayElements/workflowNextWorkflow";
import CheckboxV4 from "../inputs/checkbox_V4";

const AddThirdParty = ({ user, theirDetails, myDetails, onResponse, onCloseModal }) => {
  const appData = useContext(AppContext);
  const { inputState, inputHandler } = useInputV4({ inputs: {}, isValid: false });
  const { initState, initStarted, initCompleted, initErrors, initReset } = useInit(4);
  const [saved, setSaved] = useState();
  const isUserCreator = appData.workspaceDataV4.oid === user?.partyA.oid;

  useEffect(() => {
   
  }, [user]);

  const submitHandler = (event) => {
    event.preventDefault();

    let data = {
      // firstName: inputState.inputs.firstName.value,
      // lastName: inputState.inputs.lastName.value,
      email: inputState.inputs.email.value,
      accessTags: inputState.inputs.tags.value,
      editFiles: inputState.inputs.editFiles.value,
      // company: inputState.inputs.company.value,
    };

    if (user) {
      data = { ...data, userId: user._id };
    }

    initStarted(0);
    axios
      .put(process.env.REACT_APP_BACKEND_URL + `/api/thirdparty/${user ? `edit/${user._id}` : "add"}`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "token ",
        },
        withCredentials: true,
      })
      .then((response) => {
        initCompleted(0);
        onResponse();
        setSaved(true);
      })
      .catch((err) => {
        console.log(err);
        const errorMessage = err?.response?.data?.message ?? err?.message ?? "";

        return initErrors(errorMessage, 0);
      });
  };

  const deactivateHandler = () => {
    initStarted(1);

    axios
      .put(
        process.env.REACT_APP_BACKEND_URL + `/api/thirdparty/deactivate`, // this should change to workspaceDataId
        {
          thirdPartyId: user._id,
          lock: !user.locked,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        onResponse();
        initCompleted(1);
      })
      .catch((err) => {
        console.log(err);
        const errorMessage = err?.response?.data?.message ?? err?.message ?? "";

        return initErrors(errorMessage, 1);
      });
  };

  const deleteHandler = () => {
    initStarted(2);

    axios
      .delete(process.env.REACT_APP_BACKEND_URL + `/api/thirdparty/delete/${user._id}`, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      .then((response) => {
        onResponse();
        initCompleted(2);
      })
      .catch((err) => {
        console.log(err);
        const errorMessage = err?.response?.data?.message ?? err?.message ?? "";
        return initErrors(errorMessage, 2);
      });
  };

  const acceptHandler = () => {
    initStarted(3);

    axios
      .get(process.env.REACT_APP_BACKEND_URL + `/api/thirdparty/accept/${user._id}`, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      .then((response) => {
        onResponse();
        initCompleted(3);
      })
      .catch((err) => {
        console.log(err);
        const errorMessage = err?.response?.data?.message ?? err?.message ?? "";
        return initErrors(errorMessage, 3);
      });
  };

  useEffect(() => {}, []);

  return (
    <form className="md:w-96 w-full " onSubmit={submitHandler}>
      {/* <div className=" font-semibold w-full text-center text-lg text-taskinatorDarkGrey">{user ? "Edit Member" : "Invite People"}</div> */}

      <Input_V4
        label="Email"
        sid="inputs"
        id="email"
        disabled={user?.[theirDetails]?.email}
        placeholder={`Email`}
        onInput={inputHandler}
        validators={[VALIDATOR_EMAIL]}
        defaultValue={user ? user?.[theirDetails].email : null}
      />

      <div className="pt-1">
        <TagSelector
          inputVer="1"
          notRequired={true}
          disabled={user ? (!isUserCreator && !user?.accepted) || user?.locked : false}
          id={"tags"}
          inputHandler={inputHandler}
          defaultValue={
            user?.[theirDetails]?.accessTagsGranted?.map((tag) => {
              return { type: tag.type, value: tag._id, name: tag.name };
            }) ?? []
          }
          sid="inputs"
          type="access"
          label="Access Tag"
          selectionDisplayText={"Select Access Tag"}
          toolTip={"What file sets and files can this third party access"}
        />
      </div>

      <div className="flex justify-start items-center gap-1 my-2">
        <CheckboxV4
          onInput={inputHandler}
          sid="inputs"
          id="editFiles"
          label="Edit Files"
          customeLabel={"text-sm h-5"}
          customCheckbox={"h-4 w-4"}
          defaultValue={user?.[theirDetails]?.editFiles ?? false}
          toolTip={"If checked then the third party can edit the files"}
        />
      </div>

      {user && !isUserCreator && !user.accepted && (
        <Button
          label={"Accept Connection"}
          customClasses={`border-taskinatorGreen text-taskinatorWhite bg-taskinatorGreen border  rounded  h-8 w-full mt-2   mb-2 `}
          isLoading={initState[3].started}
          onClick={() => acceptHandler()}
          error={initState[3].error}
        />
      )}

      {((user && user.accepted) || !user || (user && isUserCreator)) && (
        <Button
          type="submit"
          label={user ? "Save" : "Add"}
          customClasses={`${inputState.isValid && !user?.locked ? "bg-taskinatorBlue" : "bg-taskinatorMedGrey"} bg-taskinatorBlue text-taskinatorWhite h-10 w-full mt-2 mb-2`}
          disabled={!inputState.isValid || user?.locked}
          isLoading={initState[0].started}
          error={initState[0].error}
        />
      )}

      {user && user.accepted && (
        <Button
          label={!user.locked ? "Lock Link" : "Unlock Link"}
          customClasses={`${
            !user.locked || user?.lockedBy?.toString() === appData?.workspaceDataV4?.oid?.toString() ? "border-taskinatorRed text-taskinatorRed" : "border-taskinatorDarkGrey text-taskinatorDarkGrey"
          } border  rounded  h-8 w-full  mb-2 opacity-25 `}
          isLoading={initState[1].started}
          disabled={user?.locked && user?.lockedBy?.toString() !== appData?.workspaceDataV4?.oid?.toString()}
          onClick={() => deactivateHandler()}
          error={initState[1].error}
        />
      )}

      {user && isUserCreator && !user?.accepted && (
        <Button
          label={"Delete"}
          customClasses={`border-taskinatorRed text-taskinatorRed border  rounded  h-8 w-full   mb-2 `}
          isLoading={initState[2].started}
          onClick={() => deleteHandler()}
          error={initState[2].error}
        />
      )}
    </form>
  );
};

export default AddThirdParty;
