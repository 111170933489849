import React from "react";
import axios from "axios";
import { useInit } from "../../hooks/useInit";
import Button from "../../UI_elements/button";

const AcknowledgeNotificationButton = ({ tracerWorkflowGroupId, logId, getNotifications, large }) => {
  const { initState, initStarted, initCompleted, initErrors, initReset } = useInit();

  const acknowledgeHandler = () => {
    initStarted(0);
    axios
      .put(
        process.env.REACT_APP_BACKEND_URL + `/api/notification/acknowledge/${tracerWorkflowGroupId}/${logId}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      .then((responseData) => {
        console.log(responseData)
        getNotifications();
        initCompleted(0);
      })
      .catch((err) => {
        console.log(err);
        initErrors(err?.response?.data?.message ?? err?.message ?? '', 0);
      });
  };

  return (
    <Button
      onClick={() => acknowledgeHandler()}
      customClasses={`hover:bg-taskinatorGreen hover:text-taskinatorWhite border rounded border-taskinatorGreen w-full text-taskinatorGreen ${large ? "h-8 text-sm" : "py-1 text-xs"}  `}
      label={"Acknowledge"}
      isLoading={initState[0].started}
      error={initState[0].error}
    />
  );
};

export default AcknowledgeNotificationButton;
