import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/UI_elements/button";
import JobFileSelector from "../../components/displayElements/jobs/jobFileSelector";
import JobWorkflowDisplay from "../../components/displayElements/jobs/jobWorkflowDisplay";
import JobDetailsForm from "../../components/forms/jobDetailsForm";
import { useInit } from "../../components/hooks/useInit";
import { useInputV4 } from "../../stateManagement/useInputV4";
import JobAutoSequenceDisplay from "../../components/displayElements/jobs/jobAutoSequenceDisplay";
import JobFileViewer from "../../components/displayElements/jobs/jobFileViwer";
import { MdLock } from "react-icons/md";
import ToolTip from "../../utils/toolTip";
const NewJob = ({ closeModal, project, setShowDetails }) => {
  const navigate = useNavigate();
  // const [uploadTrigger, setUploadTrigger] = useState();
  const { initState, initStarted, initCompleted, initErrors, initReset } = useInit(2);
  const { inputState, inputHandler, removeSidHandler, attachmentHandler } = useInputV4();
  const [addDetails, setAddDetails] = useState(true);

  const workflowAdded = inputState.details && inputState.details.workflow && inputState.details.workflow.value.length > 0;

  const [excludeList, setExcludeList] = useState({});
  const [usePreset, setUsePreset] = useState();

  useEffect(() => {
    setExcludeList({});
  }, [inputState?.details?.fileSets?.value]);

  useEffect(() => {
    inputHandler("exclusionList", Object.keys(excludeList ?? {}), true, "details");
  }, [excludeList]);

  useEffect(() => {
    initReset();
  }, [inputState]);

  const issueTaskHandler = async () => {
    initStarted(0);

    axios
      .post(
        process.env.REACT_APP_BACKEND_URL + `/api/job/create/new`,
        {
          jobDetails: inputState.details,
          attachments: Object.values(inputState?.attachments ?? {}).map((attachment) => {
            return {
              key: attachment.value.key,
              fileName: attachment.value.fileName,
            };
          }),
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        initCompleted(0);
        navigate("/");
        closeModal();
      })
      .catch((err) => {
        console.log(err);
        return initErrors(err?.response?.data?.message ?? err?.message ?? "", 0);
      });
  };

  return (
    <div className={` h-full flex flex-col grow overflow-auto`}>
      <div className="flex flex-col xl:flex-row overflow-auto h-full scrollbar xl:pr-0 pr-1 w-full">
        <div className={`${addDetails ? "xl:w-1/4 w-full xl:mr-1" : "grow"} shrink-0  mr-0 xl:h-full h-fit  xl:overflow-auto flex flex-col `}>
          <div className="py-2 px-4  text-taskinatorWhite rounded-lg shadow mb-1 border border-taskinatorDarkGrey bg-taskinatorDarkGrey font-semibold uppercase flex items-center">
            <div className="flex items-center  text-md">Job Details <ToolTip content={"Job details stay at the top of the job for everyone to see at any step in a workflow."}/></div>
          </div>
          <JobDetailsForm
            globalInputHandler={inputHandler}
            globalSid={"details"}
            initState={initState}
            disabled={initState[0].started}
            project={project}
            newJob
            addDetails={() => {
              setShowDetails(true);
              setAddDetails(true);
            }}
            fileSetExclusions={inputState?.details?.fileSets?.value.map((fileSet) => fileSet._id.toString())}
          />
        </div>
        {addDetails && (
          <div className="flex grow flex-col xl:h-full h-fit xl:overflow-auto transition-all duration-500 ">
            <div className="xl:mt-0 mt-1 border border-taskinatorMedGrey py-2 px-4 text-taskinatorDarkGrey bg-taskinatorMedGrey shadow mb-1 font-semibold uppercase flex items-center rounded-lg">
              <div className="flex items-center">Starting Workflow</div>

              {/* <div className="pl-1 text-taskinatorWhite capitalize font-normal text-base">(Required)</div> */}
            </div>

            <JobWorkflowDisplay
              globalSid={"details"}
              globalInputHandler={inputHandler}
              globalAttachmentHandler={attachmentHandler}
              closeModal={closeModal}
              fileSetsSelected={inputState?.details?.fileSets?.value}
            />

            <div className="mt-2 ">
              <JobFileSelector
                globalSid={"details"}
                globalInputHandler={inputHandler}
                workflowId
                workflowAdded={workflowAdded}
                number={`3`}
                noNotes
                fileSetExclusions={inputState?.details?.refFileSet?.value.map((fileSet) => fileSet._id.toString())}
              />
            </div>

            {inputState?.details?.fileSets?.value.length > 0 && (
              <JobFileViewer
                fileSets={inputState?.details?.fileSets?.value ?? []}
                excludeList={excludeList}
                setExcludeList={setExcludeList}
                startingWorkflow={inputState?.details?.workflow?.value?.[0]?.wfid?.name}
                globalInputHandler={inputHandler}
                globalSid={"details"}
              />
            )}
            {/* <JobAutoSequenceDisplay
            fileSets={inputState?.details?.fileSets?.value ?? []}
            setUsePreset={setUsePreset}
            globalInputHandler={inputHandler}
            globalSid={"details"}
            usePreset={usePreset}
            setExcludeList={setExcludeList}
            excludeList={excludeList}
          /> */}
          </div>
        )}
        {/* {!addDetails && (
          <div
            onClick={() => {
              setShowDetails(true);
              setAddDetails(true);
            }}
            className="h-full w-10  border-taskinatorDarkGrey border ml-1 rounded cursor-pointer overflow-hidden"
          >
            <div className=" text-taskinatorDarkGrey font-medium  text-base  rotate-90  flex justify-center items-center w-full h-full text-nowrap transition-all duration-500 hover:opacity-75">More Details</div>
          </div>
        )} */}
      </div>
      {addDetails && (
        <div className="w-full flex justify-end mt-2 pr-20">
          {/* <Button
            label="<< Remove Task Details"
            customClasses={"text-taskinatorDarkGrey border border-taskinatorDarkGrey h-10 text-sm mr-2 "}
            onClick={() => {
              setShowDetails();
              setAddDetails();
            }}
          /> */}
          <Button
            label="Create New Job"
            customClasses={`${inputState.isValid ? "bg-taskinatorBlue border border-taskinatorBlue" : "bg-taskinatorMedGrey border border-taskinatorMedGrey"} text-taskinatorWhite h-10 text-sm py-2`}
            onClick={() => {
              issueTaskHandler();
            }}
            isLoading={initState[0].started}
            disabled={!inputState.isValid}
            error={initState[0].error}
          />
        </div>
      )}

      {!addDetails && (
        <div className="flex  mt-2 justify-end grow">
          {/* <Button label="Send To Department Manager" customClasses={"bg-taskinatorBlue  text-taskinatorWhite  h-10 text-sm "} /> */}
          <Button
            onClick={() => {
              setShowDetails(true);
              setAddDetails(true);
            }}
            label="Add Task Details >>"
            customClasses={" text-taskinatorBlue border border-taskinatorBlue h-10 text-sm ml-2"}
          />
        </div>
      )}
    </div>
    // </ContentView>
  );
};

export default NewJob;
