import axios from "axios";
import React, { useContext, useEffect, useState, useCallback } from "react";
import { useInit } from "../../../components/hooks/useInit";
import { useInputV4 } from "../../../stateManagement/useInputV4";
import Button from "../../UI_elements/button";
import IconSpinner from "../../UI_elements/iconSpinner";
import { FileContext } from "./FileContext";
import FileDetails from "./fileDetails";
import CheckboxV4 from "../../inputs/checkbox_V4";
import LowerCaseTrim from "../../../utils/lowerCaseAndTrim";
import FilesileListViewDisplayer from "./fileListViewDisplayer";
import { remove } from "lodash";
import { file } from "jszip";

const FileListView = ({
  wfid,
  tid,
  fileSetId,
  fileSelection,
  onBulkSelection,
  selectAll,
  setSelectAll,
  setFileSelection,
  localOnly,
  searchValue,
  setResetTrigger,
  bulkFileSelection,
  numberOfFilesInSet,
  onUpdate,
}) => {
  const fileContext = useContext(FileContext);
  const [itemsOnList, setItemsOnList] = useState();
  const [isChecked, setIsChecked] = useState();
  const { initState, initStarted, initCompleted, initErrors, initReset } = useInit(2);
  const { inputState, inputHandler } = useInputV4({
    inputs: { search: { value: "", isValid: false } },
    isValid: false,
  });
  const [itemsSelected, setItemsSelected] = useState();
  const [deleteList, setDeleteList] = useState({});
  const inJob = fileContext?.itemsSelected?.[fileSetId]?.inJob;

  const GetFiles = () => {
    const url = `initialise/${1000}/$${inputState?.inputs?.search?.value}/${fileSetId}`;
    initStarted(1);
    axios
      .put(
        process.env.REACT_APP_BACKEND_URL + `/api/file/${url}/null`,
        {
          isAll: true,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        let fileObject = localOnly ? {} : { ...fileContext.itemList };

        response.data.data.forEach((file) => {
          if (fileObject[fileSetId] && fileObject[fileSetId][file.revisionTrackingId.toString()]) {
            fileObject[fileSetId][file.revisionTrackingId.toString()] = file;
          } else {
            fileObject = {
              ...fileObject,
              [fileSetId]: {
                ...fileObject[fileSetId],
                [file.revisionTrackingId.toString()]: file,
              },
            };
          }
        });

        localOnly ? setItemsOnList(response.data.data) : fileContext.setItemList(fileObject);

        initCompleted(1);
      })
      .catch((err) => {
        console.log(err);
        initErrors(err?.response?.data?.message ?? err?.message ?? "", 1);
      });
  };

  useEffect(() => {
    GetFiles();
  }, [fileSetId, numberOfFilesInSet]);

  const removeFileHandler = (fileId, revisionTrackingId) => {
    initStarted(0, revisionTrackingId);

    axios
      .get(process.env.REACT_APP_BACKEND_URL + `/api/file/remove/${fileId}/${null}/${null}/${null}`, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      .then((response) => {
        // fileContext.setFilesDeleted((deleteList) => {
        //   return { ...deleteList, [fileId]: fileId };
        // });

        let fileObject = { ...fileContext.itemList };

        if (fileObject[fileSetId] && fileObject[fileSetId][revisionTrackingId]) {
          // Delete the file from the object
          delete fileObject[fileSetId][revisionTrackingId];

          // Optionally, if after deleting the file, you want to check if the fileSetId group is empty and remove it too
          if (Object.keys(fileObject[fileSetId]).length === 0) {
            delete fileObject[fileSetId];
          }

          // Finally, update your context or state with the modified fileObject

          fileContext.setItemList(fileObject);
        } else {
          console.log("File not found.");
        }

        initCompleted(0, revisionTrackingId);
      })
      .catch((err) => {
        return initErrors(err?.response?.data?.message ?? err?.message ?? "", 0, revisionTrackingId);
      });
  };

  const filesOnThisFocus = localOnly ? itemsOnList : fileContext?.itemList[fileSetId] && Object.values(fileContext?.itemList[fileSetId]);

  const onSelectHandler = useCallback(
    (isChecked, fileId, fileData) => {
      if (onBulkSelection) {
        onBulkSelection(fileId, isChecked, fileData);
      }
    },

    [fileSelection]
  );

  // does this clean up the file data

  return (
    <div className=" ">
      {initState[1].started && (
        <div className="bg-taskinatorWhite py-2 mt-0.5">
          <IconSpinner size={"m"} />
        </div>
      )}
      {filesOnThisFocus?.length > 0 &&
        filesOnThisFocus

          // ?.filter((file) => !fileContext.filesDeleted[file._id.toString()])
          .map((file, index) => {
            const fileId = file?._id?.toString();
            const isSelected = fileSelection?._id?.toString() === fileId;
            let isVisible = false;

            if (searchValue && searchValue?.length > 0) {
              isVisible =
                LowerCaseTrim(file?.fileName)?.includes(searchValue) ||
                LowerCaseTrim(file?.fileExtension)?.includes(searchValue) ||
                LowerCaseTrim(file?.status)?.includes(searchValue) ||
                LowerCaseTrim(file?.revision)?.includes(searchValue) ||
                !!file.settings.fileTags.find((tag) => LowerCaseTrim(tag.name).includes(searchValue));
            } else isVisible = true;

            return (
              <FilesileListViewDisplayer
                inJob={inJob}
                file={file}
                fileId={fileId}
                isSelected={isSelected}
                onSelectHandler={onSelectHandler}
                localOnly={localOnly}
                removeFileHandler={removeFileHandler}
                index={index}
                selectAll={selectAll}
                fileSetId={fileSetId}
                setFileSelection={setFileSelection}
                setSelectAll={setSelectAll}
                initState={initState}
                isVisible={isVisible}
                onUpdate={onUpdate}
                showAuto
                GetFiles={GetFiles}
              />
            );
          })}
      {filesOnThisFocus?.length === 0 && <div className={`flex grow items-center justify-center border-t border-t-taskinatorMedGrey ${localOnly ? "mt-4" : ""} `}>No Files</div>}
    </div>
  );
};

export default FileListView;
