import React, { useState, useContext, useEffect } from "react";
import { JobModalContext } from "./jobModalContext";
import NewInstruction from "../../forms/newInstruction";
import { useInputV4 } from "../../../stateManagement/useInputV4";
import ToolTip from "../../../utils/toolTip";
import Button from "../../UI_elements/button";
import UserDisplayBubble from "../../UI_elements/userDisplayBubble";
import { useInit } from "../../hooks/useInit";
import axios from "axios";

const AddFileBackIntoTask = ({ files, taskData, onResponse }) => {
  const jobData = useContext(JobModalContext);
  const [tasksToAdd, setTasksToAdd] = useState();
  const { inputState, inputHandler, attachmentHandler, resetState } = useInputV4({ inputs: {} }, false);


  const runningTasks = jobData.tasks.filter((task) => task.currentJobTaskUUID === taskData.taskUUID && task.completed !== true);

  const isSelectionValid = inputState?.inputs?.toDo?.value?.length > 0 && Object.keys(files ?? {})?.length > 0;

  const { initState, initStarted, initCompleted, initErrors, initReset } = useInit(3);

  const submitHandler = () => {
    initStarted(2);

    axios
      .put(
        process.env.REACT_APP_BACKEND_URL + `/api/job/addFiles/${taskData.taskUUID}/${taskData.workflowGroupId}/${jobData.jobId}`,
        { files: Object.keys(files), tasks: inputState.inputs.toDo.value },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        initCompleted(2);
        resetState();
        onResponse()
      })
      .catch((err) => {
        console.log(err);
        return initErrors(err?.response?.data?.message ?? err?.message ?? "", 2);
      });
  };

  return (
    <div>
      <div className="text-base  text-taskinatorRed border py-1 px-2 rounded-lg mb-4">
        {Object.keys(files ?? {}).length} {Object.keys(files ?? {}).length === 1 ? "file" : "files"} selected
      </div>

      <div class>
        <div className="border-b border-taskinatorLightGrey border-b-2 flex mb-1">
          <div className="pr-1">
            {" "}
            Add To Running Tasks <div></div>
          </div>
          <ToolTip content={"Select a task you want this file to be associated with"} />
        </div>

        {runningTasks.length > 0 ? (
          runningTasks.map((task) => {
            return (
              <div
                key={task._id}
                onClick={() => inputHandler("toDo", task._id, true, "inputs")}
                className={`${
                  task._id.toString() === inputState?.inputs?.toDo?.value ? "bg-taskinatorGreenHover" : "hover:bg-taskinatorGreenHover"
                } border border-taskinatorGreen items-center  rounded py-1 px-2 mb-1 cursor-pointer  flex w-full`}
              >
                <div className="font-normal pr-1 font-medium"> {task.title}</div>
                <div className="flex items-center border-l border-l-taskinatorMedGrey pl-1 ml-1">
                  Assignees:
                  {task.assignee.map((user) => {
                    return (
                      <div className="ml-1">
                        <UserDisplayBubble user={user} />
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })
        ) : (
          <div className="text-taskinatorMedGrey text-xs">No Active Tasks</div>
        )}
      </div>

      <NewInstruction toolTip={"Add the selected files into a new task"} globalHandler={inputHandler} inJob label="Add To New Task" horizontal availableTeam={taskData.assignees} />

      <div className="mt-2">
        <Button
          label="Add Files Into Tasks"
          customClasses={`${!isSelectionValid ? "bg-taskinatorMedGrey" : "bg-taskinatorBlue"}  text-taskinatorWhite h-10 w-full mt-2 text-sm`}
          onClick={() => submitHandler()}
          isLoading={initState[2].started}
          error={initState[2].error}
          disabled={!isSelectionValid}
        />
      </div>
    </div>
  );
};

export default AddFileBackIntoTask;
