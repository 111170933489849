import React, { useState, useContext, useCallback, useEffect } from "react";
import DaySchedule from "./daySchedule";
import { AppContext } from "../contexts/appContext";
import axios from "axios";
import { useInit } from "../components/hooks/useInit";
import IconSpinner from "../components/UI_elements/iconSpinner";
import ToolTip from "./toolTip";
const WeeklyHours = ({ userAvailability }) => {
  const days = [
    { name: "MON", unavailable: true },
    { name: "TUE", unavailable: true },
    { name: "WED", unavailable: true },
    { name: "THU", unavailable: true },
    { name: "FRI", unavailable: true },
    { name: "SAT", unavailable: true },
    { name: "SUN", unavailable: true },
  ];

  const { initState, initStarted, initCompleted, initErrors } = useInit(2);
  const [availability, setAvailability] = useState();

  const onSelectHandler = useCallback(
    (day, available, timeSlots, isValid) => {
      setAvailability((prev) => {
        const newAvailability = { ...prev };
        newAvailability[day] = { available, day, timeSlots, isValid };
        return newAvailability;
      });
    },
    [days]
  );

  useEffect(() => {
    if (availability && Object.values(availability)?.every((day) => day?.isValid === true)) {
      initStarted(0);
      axios
        .put(process.env.REACT_APP_BACKEND_URL + `/api/team/userSettings/availability`, Object.values(availability ?? {}), {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        })
        .then((response) => {
          initCompleted(0);
        })
        .catch((err) => {
          console.log(err);
          const errorMessage = err?.response?.data?.message ?? err?.message ?? "";
          initErrors(errorMessage, 0);
        });
    } else {
    }
  }, [availability]);

  return (
    <div className="">
      <div className="flex items-center justify-center mb-4">
        <h2 className="text-base font-semibold text-taskinatorDarkGrey  pr-1">My Availability </h2> <ToolTip content="This sets how your task schedule should display" />
      </div>
      {days.map((day) => (
        <div key={day.name} className="border-b border-b-taskinatorMedGrey mb-1">
          <DaySchedule day={day} onSelect={onSelectHandler} userAvailability={userAvailability} />
        </div>
      ))}
      {initState[0].started && (
        <div className="flex text-xs justify-center mt-4">
          <IconSpinner /> Saving...
        </div>
      )}
      {initState[0].error && <div className="text-taskinatorRed text-xs text-center">{initState[0].error}</div>}
    </div>
  );
};

export default WeeklyHours;
