import React, { useEffect, useContext, useState } from "react";
import axios from "axios";

import { useInit } from "../hooks/useInit";
import { AppContext } from "../../contexts/appContext";
import IconSpinner from "./iconSpinner";
import { useNavigate, Link } from "react-router-dom";

import { MdDownload, MdPreview, MdOutlineDocumentScanner, MdOutlineLibraryBooks, MdPictureAsPdf, MdImage, MdFolderZip } from "react-icons/md";
import Modal from "./modal";
import StringShortenDisplay from "./stringShortenDisplay";
import { useParams } from "react-router-dom";
import PDFViewer from "../../utils/adobePdfViewer";
import AdobePDFViewerContainer from "../../utils/adobePDFViewerContainer";

const DocumentDisplay = ({ attachment, colorChange, maxWidth, noName, notClickable, itemId, thirdParty, markup, item, viewMarkupsOnly, viewInMarkup, setMarkupDocument }) => {
  const appData = useContext(AppContext);
  const { initState, initStarted, initCompleted, initErrors, initReset } = useInit(3);
  const { accessCode, accessCode2, id, accessCode3 } = useParams();
  // const navigate = useNavigate();
  // const [attachment, setAttachment] = useState();
  // const [imageData, setImageData] = useState();
  // const [pdfData, setPdfData] = useState();
  const [modal, setModal] = useState();
  const [attachmentMarkup, setAttachmentMarkup] = useState();
  // const [downloadPercentage, setDownloadPercentage] = useState(0);
  const [savedMarkupURL, setSavedMarkupURL] = useState();

  const regex = /\.([^.]+)$/;
  const extension = attachment.fileName.match(regex);

  const checkIsAttachmentMarkedup = () => {
    axios
      .get(process.env.REACT_APP_BACKEND_URL + `/api/items/attachment/ismarkedup/${attachment.key}`, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      .then((res) => {
        if (res.data.isMarkup) {
          setAttachmentMarkup(true);
        } else {
          setAttachmentMarkup();
        }
      })
      .catch((err) => console.log({ err }));
  };

  useEffect(() => {
    if (item) {
      checkIsAttachmentMarkedup();
    }
  }, [item]);

  const attachmentClickHandler = async (action) => {
    initStarted(1);

    const url = thirdParty
      ? `/api/tp/attachment/download/${accessCode}/${accessCode2}/${id}/${accessCode3}/${attachment.key}/${attachment.fileName}`
      : `/api/attachment/download/${attachment.key}/${attachment.fileName}/${itemId}`;
    try {
      const responseData = await axios.get(process.env.REACT_APP_BACKEND_URL + url, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      });

      if (markup || viewMarkupsOnly) {
        setModal({url: responseData.data.signedURL});
      } else if (viewInMarkup) {
        setMarkupDocument({ pdfURL: responseData.data.signedURL, attachmentKey: attachment.key, pdfFileName: attachment.fileName });
      } else {
        window.open(`${responseData.data.signedURL}`, `_blank`, `noopener, noreferrer`);
      }
      initCompleted(1);
    } catch (err) {
      console.log(err);
      return initErrors(err?.response?.data?.message ?? err?.message ?? "", 1);
    }
  };

  return (
    <>
      <div className="flex flex-col">
        <div
          className={` flex w-fit rounded border px-2 py-1  ${!colorChange ? "border-taskinatorBlue text-taskinatorDarkGrey" : colorChange} ${
            notClickable ? "" : "cursor-pointer  hover:bg-taskinatorBlue hover:border-2 hover:text-taskinatorWhite"
          }`}
          onClick={notClickable ? () => {} : () => attachmentClickHandler("download")}
        >
          <div className="flex  justify-center items-center ">
            {initState[1].started && (
              <div className="flex ">
                <div>{<IconSpinner color={"text-taskinatorBlue"} />}</div>
              </div>
            )}
            {/* </div>{downloadPercentage !== 100 && <div  className="text-xs pl-1"> {downloadPercentage}%</div> */}
            {!initState[1].started && (
              <div className="flex items-center justify-center">
                <div>{extension[1] === "pdf" ? <MdPictureAsPdf size={"1.25rem"} /> : extension[1] === "zip" ? <MdFolderZip size={"1.25rem"} /> : <MdImage size={"1.25rem"} />}</div>

                {!noName && <div className="font-normal pl-1 overflow-clip text-xs  ">{StringShortenDisplay(20, decodeURIComponent(decodeURIComponent(attachment.fileName)))} </div>}
                {(attachmentMarkup || item?.markups?.length > 0) && <div className="text-[0.7rem] bg-taskinatorRed text-taskinatorWhite w-fit  px-2 rounded ml-2">MARKUPS</div>}
              </div>
            )}
          </div>
        </div>
      </div>

      {modal && (
        <Modal
          size={"full"}
          local={() => {
            setModal();
            appData.socket.emit(
              "pdfAnnotation",
              {
                action: "disconnect",
                attachmentKey: attachment.key,
              },
              (callback) => {}
            );
          }}
          isWithoutClose={true}
        >
          <div className="flex flex-row h-full">
            <AdobePDFViewerContainer
              attachmentKey={attachment.key}
              pdfFileName={attachment.fileName}
              pdfURL={modal}
              item={item}
              savedMarkupURL={savedMarkupURL}
              viewMarkupsOnly={viewMarkupsOnly}
              uniqueID={attachment.key}
              close={() => {
                setModal();
                appData.socket.emit(
                  "pdfAnnotation",
                  {
                    action: "disconnect",
                    attachmentKey: attachment.key,
                  },
                  (callback) => {}
                );
              }}
            />
            {/* <PDFViewer
              attachmentKey={attachment.key}
              pdfFileName={attachment.fileName}
              pdfURL={modal}
              item={item}
              close={() => {
                setModal("");
                appData.socket.emit(
                  "pdfAnnotation",
                  {
                    action: "disconnect",
                    attachmentKey: attachment.key,
                  },
                  (callback) => {
                    console.log(callback.message);
                  }
                );
              }}
              setAnnotationAdded={() => {}}
              savedMarkupURL={savedMarkupURL}
              setSavedMarkupURL={() => setSavedMarkupURL()}
              setSavedMarkup={() => {}}
              viewMarkupsOnly={viewMarkupsOnly}
              userMadeChanges={() => checkIsAttachmentMarkedup()}
            /> */}
          </div>
        </Modal>
      )}
    </>
  );
};

export default DocumentDisplay;
