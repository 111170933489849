import React, { useEffect, useState } from "react";

import { useInit } from "../../components/hooks/useInit";
import axios from "axios";

import ContentView from "../../pageTemplates/contentView";
import IconSpinner from "../../components/UI_elements/iconSpinner";

import InviteEditMember from "../../components/forms/inviteEditMember";
import Tag from "../../components/UI_elements/tag";
import Modal from "../../components/UI_elements/modal";

const Team = () => {
  const { initState, initStarted, initCompleted, initErrors, initReset } = useInit();
  const [responseData, setResponseData] = useState();
  const [modal, setModal] = useState();

  const sendRequest = () => {
    initStarted(0);
    axios
      .get(process.env.REACT_APP_BACKEND_URL + `/api/team/teammembers`, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      .then((response) => {
        setResponseData(response.data.teamData);

        initCompleted(0);
      })
      .catch((err) => {
        return initErrors(err?.response?.data?.message ?? err?.message ?? "", 0);
      });
  };

  /// get team data on start up
  useEffect(() => {
    sendRequest();
  }, []);

  const buttons = [
    {
      label: "Invite Team",
      customClasses: "bg-taskinatorBlue text-taskinatorWhite h-10 text-xs md:text-sm ml-2 py-2",
      onClick: () => setModal({ user: null }),
      id: "newTask",
    },
  ];

  return (
    <>
      <ContentView buttons={buttons} label={"Manage Team"}>
        <div className=" grid-rows-2  ">
          <div className=" grid lg:grid-cols-8 grid-cols-4 bg-taskinatorWhite   text-xs font-semibold py-2 text-taskinatorDarkGrey">
            <div className=" pl-2 border-r border-r-taskinatorLightGrey border-r-2 overflow-clip">Full Name</div>
            <div className=" pl-2 border-r border-r-taskinatorLightGrey border-r-2 overflow-clip">Email</div>
            <div className=" pl-2 border-r border-r-taskinatorLightGrey border-r-2 overflow-clip">Role</div>
            <div className=" col-span-1 pl-2 border-r border-r-taskinatorLightGrey border-r-2 overflow-clip">Role Tag</div>
            <div className=" col-span-1 pl-2 border-r border-r-taskinatorLightGrey border-r-2 overflow-clip">Department</div>
            {/* <div className=" pl-2 border-r border-r-taskinatorLightGrey border-r-2">Permissions</div> */}
            {/* <div className="col-span-2 pl-2 lg:border-r lg:border-r-taskinatorLightGrey lg:border-r-2 overflow-clip lg:flex hidden">Access Tags</div> */}
            <div className=" pl-2 hidden lg:flex overflow-clip">Status</div>
          </div>
          {initState[0].started && (
            <div className=" my-4">
              <IconSpinner size="m" />
            </div>
          )}
          {initState[0].error && <div className="text-center w-full text-taskinatorRed my-4 capitalize">{initState[0].error}</div>}
          {initState[0].finished &&
            responseData &&
            responseData.map((user) => {
              return (
                // <div className="flex w-full">
                <div
                  key={user._id}
                  className={`min-h-10 items-center grid lg:grid-cols-8 grid-cols-4  bg-taskinatorWhite my-0.5 py-1 text-sm hover:bg-taskinatorHoverBlue ${
                    user.locked ? "text-taskinatorMedGrey" : ""
                  } cursor-pointer `}
                  onClick={() => setModal({ user: user })}
                >
                  <div className=" pl-2 border-r border-r-taskinatorLightGrey border-r-2 flex">
                    <div className="capitalize mr-1 overflow-clip">{user.firstName}</div>
                    <div className="capitalize mr-1 overflow-clip">{user.lastName}</div>
                  </div>
                  <div className=" pl-2 border-r border-r-taskinatorLightGrey border-r-2 overflow-clip whitespace-nowrap">{user.email}</div>
                  <div className=" pl-2 border-r border-r-taskinatorLightGrey border-r-2 overflow-clip ">{user.role === "Owner" ? "App Manager" : user.role}</div>
                  {/* <div className=" pl-2 border-r border-r-taskinatorLightGrey border-r-2 capitalize">{data.permissions}</div> */}
                  <div className=" pl-2 col-span-1 border-r border-r-taskinatorLightGrey border-r-2 capitalize flex flex-wrap overflow-clip lg:flex hidden">
                    {user?.roleTags?.length > 0 ? (
                      user.roleTags.map((tag) => {
                        return <Tag key={tag._id} tagData={tag} />;
                      })
                    ) : (
                      <div className="text-xs text-taskinatorMedGrey">None Set..</div>
                    )}
                  </div>
                  <div className=" pl-2 col-span-1 border-r border-r-taskinatorLightGrey border-r-2 capitalize flex flex-wrap overflow-clip lg:flex hidden">
                    {user?.departmentTags?.length > 0 ? (
                      user.departmentTags.map((tag) => {
                        return <Tag key={tag._id} tagData={tag} />;
                      })
                    ) : (
                      <div className="text-xs text-taskinatorMedGrey">{[process.env.REACT_APP_ACCESS_T, process.env.REACT_APP_ACCESS_D].includes(user.role) ? "N/A" : "None Set"}</div>
                    )}
                  </div>
                  <div className=" pl-2 col-span-2 border-r border-r-taskinatorLightGrey border-r-2 capitalize flex flex-wrap overflow-clip  hidden">
                    {user?.accessTags?.length > 0 ? (
                      user.accessTags.map((tag) => {
                        return <Tag key={tag._id} tagData={tag} />;
                      })
                    ) : (
                      <div className="text-xs text-taskinatorMedGrey">None Set..</div>
                    )}
                  </div>
                  <div className={`pl-2 capitalize hidden lg:flex ${user.activated && !user.locked ? "text-taskinatorGreen" : "text-taskinatorMedGrey"}`}>
                    {user.locked ? "locked" : user.activated ? "active" : user.invited ? "invited" : ""}
                  </div>
                </div>
              );
            })}
        </div>
      </ContentView>
      {modal && (
        <Modal
          title={
            modal.user ? (
              <div className="capitalize">
                Editing: {modal.user.firstName} {modal.user.lastName}
              </div>
            ) : (
              "Invite People"
            )
          }
          local={() => {
            setModal();
          }}
        >
          <InviteEditMember
            user={modal.user}
            onResponse={() => {
              sendRequest();
              setModal();
            }}
          />
        </Modal>
      )}
    </>
  );
};

export default Team;
