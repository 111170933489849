import axios from "axios";
import React, { useContext, useEffect, useState } from "react";

import { AppContext } from "../../../contexts/appContext";
import { useInit } from "../../hooks/useInit";

import DateDisplay from "../../UI_elements/dateDisplay";
import IconSpinner from "../../UI_elements/iconSpinner";
import ItemStatus from "../../UI_elements/itemStatus";

import Button from "../../UI_elements/button";

import Modal from "../../UI_elements/modal";

import { MdOutlineMoreHoriz, MdUpdate } from "react-icons/md";
import { useParams } from "react-router-dom";
import DocumentDisplay from "../../UI_elements/documentDisplay";
import Tag from "../../UI_elements/tag";
import EditItemDetailsForm from "../../forms/editItemDetailsForm";
import RemoveDraftForm from "./removeDraftForm";

const ItemRevisionDetailsContainer = ({ iid }) => {
  const appData = useContext(AppContext);
  const { initState, initStarted, initCompleted, initErrors, initReset } =
    useInit(2);
  const [itemData, setItemData] = useState();
  const [itemHistory, setItemHistory] = useState();
  const { tid } = useParams();
  const [newDraftOrRelease, setNewDraftOrRelease] = useState();
  const [approveDraft, setApproveDraft] = useState();
  const [editItemDetails, setEditItemDetails] = useState();
  const [numberOfHistoryItems, setNumberOfHistoryItems] = useState(20);
  const [draftOptions, setDraftOptions] = useState();

  const isManager = [
    process.env.REACT_APP_ACCESS_A,
    process.env.REACT_APP_ACCESS_O,
    process.env.REACT_APP_ACCESS_M,
  ].includes(appData.workspaceDataV4.role);

  const GetItemDetails = () => {
    initStarted(0);
    axios

      .get(process.env.REACT_APP_BACKEND_URL + `/api/items/details/${iid}`, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      .then((responseData) => {
        setItemData(responseData.data.itemDetails);
        initCompleted(0);
      })
      .catch((err) => {
        console.log(err);
        initErrors(err?.response?.data?.message ?? err?.message ?? "", 0);
      });
  };

  const GetItemHistory = () => {
    initStarted(1);
    axios

      .get(process.env.REACT_APP_BACKEND_URL + `/api/items/history/${iid}`, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      .then((responseData) => {
        setItemHistory(responseData.data.itemHistory);
        initCompleted(1);
      })
      .catch((err) => {
        console.log(err);
        initErrors(err?.response?.data?.message ?? err?.message ?? "", 1);
      });
  };

  useEffect(() => {
    GetItemDetails();
    GetItemHistory();
  }, [iid, numberOfHistoryItems]);

  /// this gets the item updates
  const OnSuccessHandler = () => {
    GetItemDetails();
    GetItemHistory();
    setNewDraftOrRelease();
    setApproveDraft();
    setEditItemDetails();
  };

  const documentStateDisplay = (item, noDataDisplayAction, state) => {
    return (
      <div
        onClick={
          state === "draft" && isManager && item?.isReleased
            ? () => {
                setDraftOptions(item);
              }
            : () => {}
        }
        className={`w-full ${
          state === "draft" && !item ? "h-8" : "h-16"
        }  flex  hover:bg-taskinatorHoverBlue   ${
          state !== "superseded"
            ? `bg-taskinatorWhite border-l border-l-4  mt-1 shadow ${
                state === "released"
                  ? "border-l-taskinatorGreen"
                  : "border-l-taskinatorDarkGrey mb-1"
              } `
            : " bg-taskinatorWhite"
        } items-center ${
          state === "draft" && item?.isReleased ? "cursor-pointer" : ""
        }`}
      >
        {item ? (
          <>
            <div className="items-center flex w-full h-full text-sm">
              <div className={`flex items-center  w-full h-full "}`}>
                <div
                  className={` items-center justify-center ${columnClasses[4]}`}
                >
                  <ItemStatus
                    status={
                      state === "superseded" && item.status === "released"
                        ? state
                        : item.status
                    }
                  />
                  {item.approvalStatus && state === "draft" && (
                    <div className="flex text-taskinatorGreen items-center  text-xs pl-2 font-semibold">
                      Approved
                    </div>
                  )}
                  {/* {state === "superseded" && item.status === "released" && <div className="flex text-taskinatorRed items-center  text-xs pl-2 font-semibold">Superseded</div>} */}
                </div>

                <div className={` ${columnClasses[0]}`}>{item.revision}</div>
                <div
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                  className={`  ${columnClasses[1]} `}
                >
                  {item.attachment ? (
                    <DocumentDisplay
                      attachment={item.attachment}
                      itemId={item._id}
                      item={item}
                      viewMarkupsOnly
                    />
                  ) : (
                    <div className="text-taskinatorMedGrey">None</div>
                  )}
                </div>
                <div
                  className={`  ${columnClasses[0]} capitalize xl:flex hidden`}
                >
                  {item.createdBy}
                </div>
                <div className={`  ${columnClasses[0]} xl:flex hidden`}>
                  {DateDisplay(item.createdDate)}
                </div>
                <div className={`  ${columnClasses[2]} `}>
                  {item.changeReason}
                </div>

                <div
                  className={`flex  ${columnClasses[0]} capitalize xl:flex hidden`}
                >
                  {item.approvalStatus && ["released", "draft"].includes(state)
                    ? item.approvalStatus.approvedBy
                    : item.approvalStatus && item.status === "released"
                    ? item.approvalStatus.approvedBy
                    : ""}
                </div>
                <div className={`flex  ${columnClasses[0]} xl:flex hidden`}>
                  {item.approvalStatus && ["released", "draft"].includes(state)
                    ? DateDisplay(item.approvalStatus.approvedDate)
                    : item.approvalStatus && item.status === "released"
                    ? DateDisplay(item.approvalStatus.approvedDate)
                    : ""}
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="text-taskinatorDarkGrey opacity-75 text-sm items-center flex w-full justify-center ">
            {noDataDisplayAction}{" "}
          </div>
        )}
      </div>
    );
  };

  const IsMarkedUp = ({ item, state }) => (
    <>
      <div className="text-xxs w-full flex gap-2 justify-start items-center h-fit px-4 py-2 bg-taskinatorHoverPurple rounded-br-md -mt-1 border-l-taskinatorDarkGrey border-l-4">
        <span>Live markups By :</span>
        {item?.draft_mark_up?.length > 0 &&
          item.draft_mark_up.map((item) => (
            <span
              className={`w-fit h-fit rounded-md py-1 px-2 ${
                item === "YOU"
                  ? "bg-taskinatorGreen text-taskinatorWhite"
                  : "bg-taskiantorMedDarkGrey text-taskinatorBlack"
              }`}
            >
              {item}
            </span>
          ))}

        <span className="font-semibold">Manually Attached Markups:</span>
        {item?.markups?.length > 0 ? (
          item.markups.map((markup, index) => (
            <div
              // onMouseEnter={() => setChildHover(true)}
              // onMouseLeave={() => setChildHover(false)}
              className="font-semibold "
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
              {markup.attachment ? (
                <DocumentDisplay
                  attachment={markup.attachment}
                  itemId={markup._id}
                  item={item}
                />
              ) : (
                <div className="">None</div>
              )}
            </div>
          ))
        ) : (
          <div>None Attached</div>
        )}
      </div>
    </>
  );

  const itemTags = itemData && itemData.itemTags;
  const accessTags = itemData && itemData.accessTags;

  const columnClasses = [
    "flex lg:w-1/12 md:w-2/12 w-1/3  items-center  justify-center border-r border-r-1 border-r-taskinatorLightGrey h-full shrink-0",
    "  md:w-3/12  w-1/3 flex  justify-center items-center shrink-0 pl-2 border-r border-r-1 border-r-taskinatorLightGrey h-full",
    "md:flex hidden lg:w-3/12 md:grow items-center pl-2 border-r shrink-0 border-r-1 border-r-taskinatorLightGrey h-full",
    "xl:flex xl:w-1/12  hidden px-2 items-center justify-center shrink-0 border-r border-r-1 border-r-taskinatorLightGrey h-full",
    "flex lg:w-1/12  md:w-2/12 w-1/3  items-center  justify-center border-r border-r-1 border-r-taskinatorLightGrey h-full shrink-0",
  ];

  return (
    <>
      <div className="h-full w-full flex flex-col overflow-auto">
        {!itemData && initState[0].started && (
          <div className="w-full h-full flex items-center justify-center my-4">
            <IconSpinner size="m" />{" "}
            <div className="pl-2 text-sm text-taskinatorDarkGrey">
              Loading details...
            </div>
          </div>
        )}
        {itemData && !initState[0].started && (
          <div className="flex flex-col p-2 h-full ">
            <div className=" flex  flex-col h-full">
              <div className="flex flex-col pt-4 px-2 w-full bg-taskinatorWhite  shadow  justify-center text-taskinatorBlue ">
                <div className="flex items-center ">
                  {![process.env.REACT_APP_ACCESS_G].includes(
                    appData.workspaceDataV4.role
                  ) &&
                    itemData.oid.toString() ===
                      appData.workspaceDataV4.oid.toString() && (
                      <div
                        className="hover:text-taskinatorBlue text-taskinatorDarkGrey cursor-pointer pr-2"
                        onClick={
                          itemData.oid === appData.workspaceDataV4.oid
                            ? () => {
                                setEditItemDetails(true);
                              }
                            : () => {}
                        }
                      >
                        <MdOutlineMoreHoriz size={"1.25rem"} />
                      </div>
                    )}
                  {itemData.draft && (
                    <div className="flex text-taskinatorOrange pr-2 border-r border-r-taskinatorMedGrey mr-2  text-lg">
                      <MdUpdate />
                    </div>
                  )}
                  <div className=" text-lg  font-semibold  ">
                    {itemData.currentItemDetails.itemNumber} -{" "}
                  </div>
                  <div className=" text-lg  font-semibold pl-2 uppercase">
                    {itemData.currentItemDetails.itemName}
                  </div>
                  <div className="flex grow "></div>
                  {/* {!tid && itemData.inTask && itemData.inTask.length === 0 && <YourItemActions item={itemData} state="released" onSuccess={() => OnSuccessHandler()} />} */}
                  {itemData.currentItemDetails.wd && (
                    <div>
                      <Button
                        label={"Working Directory"}
                        customClasses={
                          "border border-taskinatorBlue text-xs rounded py-1 h-8"
                        }
                        onClick={() => {
                          window.open(
                            `${itemData.currentItemDetails.wd}`,
                            `_blank`,
                            `noopener, noreferrer`
                          );
                        }}
                      />
                    </div>
                  )}
                  {itemData?.inTask?.length > 0 && (
                    <div
                      className="text-taskinatorGreen px-2 border-taskinatorGreen border rounded uppercase cursor-pointer"
                      onClick={() =>
                        appData.setActionViewHandler({
                          tracerId: null,
                          tid: itemData.inTask[0]._id,
                        })
                      }
                    >
                      Active In: {itemData.inTask[0].ref}
                    </div>
                  )}
                </div>
                <div className="flex pb-2 pt-1 pr-2 border-t border-t-taskinatorLightGrey mt-1 text-xs text-taskinatorDarkGrey">
                  <div className="border-r border-r-taskinatorMedGrey pr-2 mr-2 font-medium flex items-center">
                    <div className="pr-1">Filter Tag:</div>
                    {itemData.currentItemTags.length > 0 &&
                      itemData.currentItemTags.map((tag, index) => {
                        return (
                          <div key={index}>
                            <Tag tagData={tag} />
                          </div>
                        );
                      })}
                    {itemData.currentItemTags.length === 0 && (
                      <div className="pl-1 font-normal text-taskinatorMedGrey">
                        No Filter Tags
                      </div>
                    )}
                  </div>
                  <div className=" font-medium flex items-center ">
                    <div className="pr-1">Access Tag:</div>
                    {itemData.currentAccessTags &&
                      itemData.currentAccessTags.length > 0 &&
                      itemData.currentAccessTags.map((tag) => {
                        return (
                          <div>
                            <Tag tagData={tag} />
                          </div>
                        );
                      })}
                    {itemData.currentAccessTags &&
                      itemData.currentAccessTags.length === 0 &&
                      itemData.sharedWith &&
                      itemData.sharedWith.length === 0 && (
                        <div className="pl-1 font-normal text-taskinatorMedGrey">
                          No Access Tags
                        </div>
                      )}
                    <div>
                      {itemData.sharedWith &&
                        itemData.sharedWith.map((tag, index) => {
                          console.log(tag);
                          return (
                            <Tag
                              key={tag._id}
                              tagData={{ type: "external", ...tag }}
                            />
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex  flex-col   overflow-auto">
                <div className="items-center flex bg-taskinatorWhite pl-1 text-[0.6rem] font-semibold text-taskinatorDarkGrey  uppercase w-full h-10  mt-1 shadow">
                  <div className={`${columnClasses[4]} `}>
                    <div className="py-2">Status</div>
                  </div>
                  <div className={`${columnClasses[0]}`}>
                    <div className="py-2">Revision</div>
                  </div>

                  <div className={`${columnClasses[1]} `}>
                    <div className="py-2">Document</div>
                  </div>

                  <div className={`${columnClasses[0]} xl:flex hidden`}>
                    <div className="py-2 ">Created By</div>
                  </div>
                  <div className={`${columnClasses[0]} xl:flex hidden`}>
                    <div className="py-2 ">Created Date</div>
                  </div>

                  <div className={`${columnClasses[2]}`}>
                    <div className="py-2">Change Reason</div>
                  </div>

                  <div className={`${columnClasses[0]} xl:flex hidden`}>
                    <div className="py-2 ">Approved By</div>
                  </div>
                  <div className={`${columnClasses[0]} xl:flex hidden`}>
                    <div className="py-2">Approved Date</div>
                  </div>
                  {/* <div className={`${columnClasses[3]}`}></div> */}
                </div>

                {/* <div className="pt-2 text-taskinatorGreen font-semibold  text-xs ">Current Version</div> */}
                <div className="">
                  {documentStateDisplay(
                    itemData.released && {
                      ...itemData.released,
                      itemDetails: {
                        itemName: itemData.currentItemDetails.itemName,
                        itemNumber: itemData.currentItemDetails.itemNumber,
                      },
                    },
                    "No released version",
                    "released"
                  )}
                </div>
                {/* <div className="pt-2 text-taskinatorDarkGrey font-semibold  text-xs ">Current Draft</div> */}
                <div className="">
                  {documentStateDisplay(
                    itemData.draft && {
                      ...itemData.draft,
                      iid: itemData._id,
                      isReleased: itemData.released,
                      itemDetails: {
                        itemName: itemData.currentItemDetails.itemName,
                        itemNumber: itemData.currentItemDetails.itemNumber,
                      },
                    },
                    "No pending drafts...",
                    "draft"
                  )}
                  {/* {(itemData?.draft_mark_up || itemData?.markups) && <IsMarkedUp item={itemData.draft && itemData.draft} state={"draft"} />} */}
                </div>
                {/* <div className="pt-2 text-taskinatorDarkGrey font-semibold  text-xs ">History</div> */}

                <div className="flex mb-2 mt-1">
                  {/* <div className="text-taskinatorBlue font-semibold pr-2 ">Item History</div>
                  <div className="pr-2 text-taskinatorMedGrey">|</div> <div className="hover:text-taskinatorBlue cursor-pointer">Activity Log</div> */}
                </div>
                <div className="flex flex-col overflow-auto scrollbar border-l border-l-4 border-l-taskinatorMedGrey ">
                  {itemHistory &&
                    itemHistory.map((item) => {
                      return (
                        <div className="">
                          {documentStateDisplay(
                            {
                              ...item,
                              itemDetails: {
                                itemName: itemData.currentItemDetails.itemName,
                                itemNumber:
                                  itemData.currentItemDetails.itemNumber,
                              },
                            },
                            "No pending updates...",
                            "superseded"
                          )}
                        </div>
                      );
                    })}
                </div>

                {!itemHistory && initState[1].started && (
                  <div className="py-4 flex items-center w-full justify-center">
                    <IconSpinner size="m" />
                    <div className="text-sm pl-2">Loading item history...</div>
                  </div>
                )}
                {itemHistory &&
                  itemHistory.length === 0 &&
                  !initState[1].started && (
                    <div className="text-center text-taskinatorDarkGrey py-4 text-xs">
                      No item History
                    </div>
                  )}
                {initState[1].error && (
                  <div className="py-4 text-taskinatorRed text-xs text-center">
                    Error loading item history!
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      {/* {newDraftOrRelease && (
        <Modal
          title={` ${newDraftOrRelease === "released" ? "Create New Released" : "Create New Draft"}`}
          local={() => {
            setNewDraftOrRelease();
          }}
        >
          <CreateDraftItemForm itemData={itemData} iid={iid} aid={aidDetails._id} onSuccess={() => OnSuccessHandler()} state={newDraftOrRelease} />
        </Modal>
      )}
      {approveDraft && (
        <Modal
          title={`Approve Draft`}
          local={() => {
            setApproveDraft();
          }}
        >
          <ApproveDraftItemForm draftData={itemData.draft} aid={aidDetails._id} onSuccess={() => OnSuccessHandler()} />
        </Modal>
      )} */}

      {draftOptions && (
        <Modal
          title={``}
          local={() => {
            setDraftOptions();
          }}
        >
          <RemoveDraftForm
            item={draftOptions}
            onSuccess={() => {
              setDraftOptions();
              OnSuccessHandler();
            }}
          />
        </Modal>
      )}

      {editItemDetails && (
        <Modal
          title={`Edit Drawing Details`}
          local={() => {
            OnSuccessHandler();
          }}
        >
          <EditItemDetailsForm
            itemData={itemData}
            onSuccess={() => {
              OnSuccessHandler();
            }}
          />
        </Modal>
      )}
    </>
  );
};

export default ItemRevisionDetailsContainer;
