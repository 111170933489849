import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { IoDocumentSharp } from "react-icons/io5";
import { MdGrading } from "react-icons/md";
import { AppContext } from "../../contexts/appContext";
import CountUpTimer from "../../utils/countUpTimer";
import { FormatDateWithoutTime } from "../../utils/formattedDate";
import Button from "../UI_elements/button";
import ItemStatus from "../UI_elements/itemStatus";
import Modal from "../UI_elements/modal";
import StringShortenDisplay from "../UI_elements/stringShortenDisplay";
import UserDisplayBubble from "../UI_elements/userDisplayBubble";
import ActionDisplay_ItemPrimary from "../displayElements/actionDisplay/actionDisplay_ItemPrimary";
import { SortWithKeyword } from "../displayElements/requests/requestModal";
import { useInit } from "../hooks/useInit";
import ElementCard_V2 from "./elementCard_V2";

const ColumnItemElement_V2 = ({
  primaryColour,
  primaryBorder,
  data,
  type,
  getData,
  textColour,
  opacity,
}) => {
  const appData = useContext(AppContext);
  const { initState, initStarted, initCompleted, initErrors, initReset } =
    useInit();

  const [modal, setModal] = useState();
  const item = data.itemsOnTracer;
  const [hoverChild, setHoverChild] = useState();
  const [modalSize, setModalSize] = useState("medium");
  const [startId, setStartId] = useState();
  const tracerIds = Object.keys(data.tracers);

  const StartHandler = (id, tid) => {
    initStarted(0);
    setStartId(id);
    axios
      .put(
        process.env.REACT_APP_BACKEND_URL + `/api/tracer/start/multiple`,
        {
          tracerIds: [id],
          tid: tid,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        getData();
        initCompleted(0);
        setStartId();
      })
      .catch((err) => {
        console.log(err);
        initErrors(err?.response?.data?.message ?? err?.message ?? "", 0);
        setStartId();
      });
  };

  useEffect(() => {}, []);

  return (
    <>
      <ElementCard_V2
        topContent={
          <div className="flex w-full flex-col capitalize text-xs items-start">
            <div className="flex w-full justify-between items-center mb-1">
              <div className="flex items-center">
                <div className="mr-1 w-fit text-xs border-taskinatorDarkGrey border px-2 text-taskinatorDarkGrey rounded font-medium uppercase py-0.5 box-border">
                  {StringShortenDisplay(20, data.ref)}
                </div>
                {data.project && (
                  <div className="w-fit text-xs border-taskinatorPurple border px-2 text-taskinatorPurple rounded font-medium uppercase py-0.5">
                    {StringShortenDisplay(15, data.project.ref)}
                  </div>
                )}
              </div>

              <div>
                {data?.dueDate ? (
                  <div className="flex justify-start items-center text-sm">
                    <span className="text-xs text-taskinatorDarkGrey">
                      Job Due :&nbsp;
                    </span>
                    <span className="font-bold text-xs text-taskinatorDarkGrey">
                      {FormatDateWithoutTime(data?.dueDate)}
                    </span>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className="flex justify-start gap-1">
              <div
                className={`${
                  data.actionName === "Set Actions"
                    ? " bg-taskinatorRed animate-pulse"
                    : " bg-taskinatorGreen"
                } text-taskinatorWhite font-semibold uppercase py-0.5 px-2 rounded`}
              >
                {StringShortenDisplay(
                  20,
                  data.actionName === "Set Actions"
                    ? "Set Workflow"
                    : data.actionName
                )}
              </div>
              {data.workflowName && (
                <div className="w-fit text-xs border-taskinatorMedGrey border px-2 text-taskinatorMedGrey rounded font-medium uppercase py-0.5 box-border">
                  {data.workflowName}
                </div>
              )}
              {data?.onHold && (
                <div className="mr-1 w-fit text-xs border-taskinatorRed border px-2 text-taskinatorWhite bg-taskinatorRed rounded font-medium uppercase py-0.5 box-border">
                  ON HOLD
                </div>
              )}
            </div>
          </div>
        }
        bottomContent={
          <div className="flex grow flex-col">
            {SortWithKeyword(Object.values(data.tracers), "_id").map(
              (tracer) => {
                const item = tracer.itemsOnTracer;
                const isNew =
                  tracer?.seen.filter(
                    (seen) => seen._id === appData.workspaceDataV4.user_id
                  ).length === 0;

                if (item?._id) {
                  return (
                    <div key={tracer._id}>
                      {isNew && (
                        <div className="flex grow justify-start h-0 pt-2 z-40">
                          <div className="relative -top-2 ml-2 items-center flex h-4 w-fit text-taskinatorWhite text-[0.75rem] bg-taskinatorRed px-1 rounded-full font-semibold">
                            New
                          </div>
                        </div>
                      )}

                      <div
                        key={tracer._id}
                        className={`text-xs flex flex-col items-start justify-center text-taskinatorDarkGrey mb-1 p-2 border border-taskinatorMedGrey rounded bg-taskinatorItemBlue hover:bg-taskinatorBlue hover:text-taskinatorWhite  cursor-pointer gap-y-2`}
                        onClick={() =>
                          appData.setActionViewHandler({
                            tracerId: tracer._id,

                            tid: tracer.tid,
                            getData: () => getData(),
                            // onStartHandler: () => StartHandler(),
                            // startHandlerLoading: initState[0]
                          })
                        }
                      >
                        <div className="text-xs flex items-center text-taskinatorDarkGrey mb-1">
                          <IoDocumentSharp size={"1rem"} />
                          <div className="px-1">|</div>
                          <div className="font-semibold uppercase">
                            {item?.currentItemDetails?.itemNumber}
                          </div>
                          <div className="px-1">-</div>
                          <div className="font-semibold uppercase pr-2">
                            {StringShortenDisplay(
                              20,
                              item?.currentItemDetails?.itemName
                            )}
                          </div>
                          <div className=" pr-2">|</div>
                          <div className="pr-2">
                            {item?.draft?._id ? (
                              <ItemStatus status={item.draft.status} />
                            ) : item?.released?._id ? (
                              <ItemStatus status={item.released.status} />
                            ) : (
                              "No Release"
                            )}
                          </div>
                          <div className="pr-2">Rev: </div>
                          <div className=" font-semibold uppercase pr-2">
                            {item?.draft?._id
                              ? item.draft.revision
                              : item?.released?._id
                              ? item.released.revision
                              : ""}
                          </div>
                        </div>

                        {/* {} */}
                        <hr className="w-full border-t border-solid border-taskinatorMedGrey" />

                        <div className="w-full flex justify-between text-xs items-center text-taskinatorDarkGrey">
                          <div className="flex items-center">
                            <div
                              className={`w-3 h-3 rounded-full flex items-center mr-2 bg-taskinatorRed ${
                                tracer.clockedIn ? "" : "hidden"
                              } `}
                            ></div>
                            <div className="text-xs text-taskinatorDarkGrey">
                              Active : &nbsp;
                            </div>
                            <div
                              className={`flex justify-end text-xs ${
                                tracer.time_in ? "block" : "hidden"
                              } `}
                            >
                              {<CountUpTimer startDate={tracer.time_in} />}
                            </div>
                          </div>
                          <div className="flex items-center">
                            <div className="text-xs text-taskinatorDarkGrey">
                              Assignees : &nbsp;
                            </div>
                            {tracer.assignee.map((user, index) => {
                              if (index < 2)
                                return (
                                  <div key={user._id} className="">
                                    <UserDisplayBubble
                                      firstName={user.firstName}
                                      lastName={user.lastName}
                                      textSize={"text-xs"}
                                      user={user}
                                    />
                                  </div>
                                );
                            })}
                            {tracer.assignee.length > 2 && (
                              <div className="text-xs">...</div>
                            )}
                            {tracer.couldbeAssigned && (
                              <div
                                className="flex grow items-center justify-end"
                                onClick={(event) => {
                                  event.stopPropagation();
                                }}
                              >
                                <Button
                                  label="Start"
                                  onClick={() =>
                                    StartHandler(tracer._id, tracer.tid)
                                  }
                                  onMouseEnter={() => {
                                    setHoverChild(true);
                                  }}
                                  onMouseLeave={() => {
                                    setHoverChild();
                                  }}
                                  isLoading={
                                    initState[0].started &&
                                    startId === tracer._id
                                  }
                                  customClasses={
                                    "hover:bg-taskinatorGreen border uppercase text-taskinatorGreen border-taskinatorGreen hover:text-taskinatorWhite text-xs py-1 px-2"
                                  }
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div key={tracer._id}>
                      {isNew && (
                        <div className="flex grow justify-start h-0 pt-2 z-40">
                          <div className="relative -top-2 ml-2 items-center flex h-4 w-fit text-taskinatorWhite text-[0.75rem] bg-taskinatorRed px-1 rounded-full font-semibold">
                            New
                          </div>
                        </div>
                      )}
                      <div
                        key={tracer._id}
                        className={`text-xs flex flex-col items-center text-taskinatorDarkGrey mb-1 p-2 gap-y-2 border border-taskinatorMedGrey rounded ${
                          tracer.note
                            ? "bg-taskinatorItemBlue text-taskinatorDarkGrey font-medium"
                            : "bg-taskinatorLightGrey"
                        } hover:bg-taskinatorBlue hover:text-taskinatorWhite cursor-pointer`}
                        onClick={() =>
                          appData.setActionViewHandler({
                            tracerId: tracer._id,
                            tid: tracer.tid,
                            getData: () => getData(),
                          })
                        }
                      >
                        <div className="w-full text-xs flex justify-start items-center text-taskinatorDarkGrey mb-1">
                          {tracer.note ? (
                            <div className="flex items-center justify-start flex-nowrap">
                              <MdGrading size={"1rem"} />{" "}
                              <div className="px-1">|</div>{" "}
                              {StringShortenDisplay(30, tracer.note)}
                            </div>
                          ) : (
                            "No Document"
                          )}{" "}
                        </div>
                        <hr className="w-full border-t border-solid border-taskinatorMedGrey" />
                        <div className="w-full flex justify-between text-xs items-center text-taskinatorDarkGrey">
                          <div className="flex items-center">
                            <div
                              className={`w-3 h-3 rounded-full flex items-center mr-2 bg-taskinatorRed ${
                                tracer.clockedIn ? "" : "hidden"
                              } `}
                            ></div>
                            <div className="text-xs text-taskinatorDarkGrey">
                              Active : &nbsp;
                            </div>
                            <div
                              className={`flex justify-end text-xs ${
                                tracer.time_in ? "block" : "hidden"
                              }`}
                            >
                              {<CountUpTimer startDate={tracer.time_in} />}
                            </div>
                          </div>
                          <div className="flex items-center">
                            <div className="text-xs text-taskinatorDarkGrey">
                              Assignees : &nbsp;
                            </div>
                            {tracer.assignee.map((user, index) => {
                              if (index < 2)
                                return (
                                  <div key={user._id} className="">
                                    <UserDisplayBubble
                                      firstName={user.firstName}
                                      lastName={user.lastName}
                                      textSize={"text-xs"}
                                      user={user}
                                    />
                                  </div>
                                );
                            })}
                            {tracer.assignee.length > 2 && (
                              <div className="text-xs">...</div>
                            )}
                            {tracer.couldbeAssigned && (
                              <div
                                className="flex grow items-center justify-end"
                                onClick={(event) => {
                                  event.stopPropagation();
                                }}
                              >
                                <Button
                                  label="Start"
                                  onClick={() =>
                                    StartHandler(tracer._id, tracer.tid)
                                  }
                                  onMouseEnter={() => {
                                    setHoverChild(true);
                                  }}
                                  onMouseLeave={() => {
                                    setHoverChild();
                                  }}
                                  isLoading={
                                    initState[0].started &&
                                    startId === tracer._id
                                  }
                                  customClasses={
                                    "hover:bg-taskinatorGreen border uppercase text-taskinatorGreen border-taskinatorGreen hover:text-taskinatorWhite text-xs py-1 px-2"
                                  }
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }

                // {
                //   data.couldbeAssigned && (
                //     <div
                //       className="flex grow items-center justify-end  "
                //       onClick={(event) => {
                //         event.stopPropagation();
                //       }}
                //     >
                //       <Button
                //         label="Start"
                //         onClick={() => StartHandler()}
                //         onMouseEnter={() => {
                //           setHoverChild(true);
                //         }}
                //         onMouseLeave={() => {
                //           setHoverChild();
                //         }}
                //         isLoading={initState[0].started}
                //         customClasses={"hover:bg-taskinatorGreen border uppercase  text-taskinatorGreen border-taskinatorGreen hover:text-taskinatorWhite text-xs py-1 px-2"}
                //       />
                //     </div>
                //   );
                // }
              }
            )}
          </div>
        }
        primaryBorder={primaryBorder}
        createdDate={data.time_in}
        seenBy={data.seen}
        onClick={() => {}}
      />

      {modal && (
        <Modal
          size={"max"}
          bgColour="bg-taskinatorWhite"
          local={() => {
            // setModalSize("medium")
            setModal();
          }}
        >
          <ActionDisplay_ItemPrimary data={data} getData={() => getData()} />
        </Modal>
      )}
    </>
  );
};

export default ColumnItemElement_V2;
