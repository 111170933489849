import React, { useState } from "react";
import CardDetails from "./cardDetails";
import Button from "../../components/UI_elements/button";
import Modal from "../../components/UI_elements/modal";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import UpdateBillingElement from "./updateBillingElement";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

const BillingDetails = ({ plan, getData }) => {
  const [upgrade, setUpgrade] = useState();

  return (
    <>
      <div className="flex flex-col grow h-full">
        <div className="font-semibold text-sm">Billing Card Details</div>
        <div></div>
        <div className="text-sm w-full flex flex-col  justify-between pt-2 text-sm border-t border-taskinatorMedGrey mt-2">
          <CardDetails currentPlan={plan} />
          <Button
            label="Change Card"
            onClick={() => {
              setUpgrade(true);
            }}
            customClasses={"bg-taskinatorBlue bg-taskinatorBlue text-taskinatorWhite h-8  mt-2 grow"}
          />
        </div>

        {/* {customer.card ? (
        <div className="text-xs pt-4">
          <div className="flex pt-1">
            <div className="pr-1 font-medium">Billing Address:</div> <div>{customer.billing.billingAddress ? customer.billing.billingAddress : "None Set"}</div>
          </div>
          <div className="flex pt-1">
            <div className="pr-1 font-medium">Billing Emails:</div> <div>{customer.billing.email ? customer.billing.email : "None Set"}</div>
          </div>
          <div className="flex pt-1">
            <div className="pr-1 font-medium capitalize">Billing Name:</div> <div>{customer.billing.name ? customer.billing.name : "None Set"}</div>
          </div>
        </div>
      ) : (
        <div className="text-xs text-taskinatorRed pt-2">Add Payment Method</div>
      )} */}
      </div>

      {upgrade && (
        <Modal
          title="Update Card Details"
          local={() => {
            setUpgrade();
          }}
        >
          <Elements
            stripe={stripePromise}
            options={{
              mode: "setup",
              currency: "aud",
              appearance: {
                theme: "stripe",
              },
            }}
          >
          <UpdateBillingElement getData={getData}/>
          </Elements>
        </Modal>
      )}
    </>
  );
};

export default BillingDetails;
