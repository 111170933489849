import React from "react";

const CardDetails = ({ currentPlan }) => {
  return (
    <div className="border-2 border-taskinatorMedGrey shadow p-2 rounded my-1 text-xs text-taskinatorDarkGrey">
      <div className="font-medium">Current Billing Details:</div>
      <div>
        Last Four Digits: <b>{currentPlan.paymentDetails.last4}</b>
      </div>
      <div>
        Exp Month: <b>{currentPlan.paymentDetails.exp_month}</b>
      </div>
      <div>
        Exp Year: <b>{currentPlan.paymentDetails.exp_year}</b>
      </div>
    </div>
  );
};

export default CardDetails;
