import React, { useEffect, useState, useContext } from "react";
import moment from "moment-timezone";
import { AppContext } from "../../contexts/appContext";
import StringShortenDisplay from "../UI_elements/stringShortenDisplay";

const createDateRange = (startDate, endDate) => {
  const dates = [];
  let currentDate = moment(startDate);
  while (currentDate.isSameOrBefore(endDate)) {
    dates.push(currentDate.clone());
    currentDate.add(1, "days");
  }
  return dates;
};

const groupTasksByDay = (tasks) => {
  return tasks.reduce((groups, task) => {
    const taskStartDate = moment.tz(task.scheduleStartDate, moment.tz.guess()).startOf("day").format("ddd, Do");
    if (!groups[taskStartDate]) {
      groups[taskStartDate] = [];
    }
    groups[taskStartDate].push(task);
    return groups;
  }, {});
};

const getUniqueDates = (userSchedules) => {
  const allDates = userSchedules.reduce((dates, userSchedule) => {
    const jobs = userSchedule.jobs;
    jobs.forEach((job) => {
      job.tasks.forEach((task) => {
        const taskStartDate = moment.tz(task.scheduleStartDate, moment.tz.guess()).startOf("day");
        const taskEndDate = moment.tz(task.scheduleFinishDate, moment.tz.guess()).startOf("day");

        dates.push(taskStartDate);
        dates.push(taskEndDate);
      });
    });
    return dates;
  }, []);

  let updatedDates = [];

  if (allDates.length > 0) {
    const minDate = moment.min(allDates);
    const maxDate = moment.max(allDates);

    const dateRange = createDateRange(minDate, maxDate);

    const uniqueFormattedDates = new Set(dateRange.map((date) => date.format("ddd, Do")));

    const nextDay = moment(maxDate).add(1, "days");
    for (let i = 0; i < 4; i++) {
      uniqueFormattedDates.add(nextDay.clone().add(i, "days").format("ddd, Do"));
    }

    updatedDates = Array.from(uniqueFormattedDates);
  }

  return updatedDates;
};

const groupTasksForUsers = (userSchedules, uniqueDates) => {
  return userSchedules.map((userSchedule) => {
    const jobs = userSchedule.jobs;
    let allTasks = [];

    jobs.forEach((job) => {
      job.tasks.forEach((task) => {
        const taskWithJobDetails = {
          ...task,
          jobRef: job.jobRef,
          jobId: job.jobId,
        };
        allTasks.push(taskWithJobDetails);
      });
    });

    const groupedTasks = groupTasksByDay(allTasks);

    uniqueDates.forEach((date) => {
      if (!groupedTasks[date]) {
        groupedTasks[date] = [];
      }
    });

    return {
      userId: userSchedule.userId,
      firstName: userSchedule.firstName,
      lastName: userSchedule.lastName,
      tasksByDay: groupedTasks,
    };
  });
};

const CalendarRowElement = ({ userSchedules, noneCollapsible }) => {
  const [currentTime, setCurrentTime] = useState(moment.tz(moment.tz.guess()));
  const [collapse, setCollapse] = useState(noneCollapsible ? false : true);
  const appData = useContext(AppContext);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(moment.tz(moment.tz.guess()));
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  const uniqueDates = getUniqueDates(userSchedules);
  const groupedTasksForUsers = groupTasksForUsers(userSchedules, uniqueDates);

  const containerWidthRem = 15; // w-60 is 15rem
  const width = "w-[17rem] shrink-0";
  const height = "h-20";

  return (
    <div className="h-full w-full overflow-auto flex-col">
      <div className={`flex w-full ${height}`}>
        <div className={`w-40 flex shrink-0 border items-center justify-center`}>Team Member</div>
        {uniqueDates.map((date) => {
          return (
            <div key={date} className={`${width} text-taskinatorDarkGrey flex justify-center items-center capitalize font-bold border text-sm`}>
              {date}
            </div>
          );
        })}
      </div>
      {groupedTasksForUsers.map((userSchedule) => {
        return (
          <div className="flex w-full" key={userSchedule.userId}>
            <div className="w-40 flex shrink-0 flex border items-center justify-center text-taskinatorDarkGrey capitalize font-medium text-sm">
              {userSchedule.firstName} {userSchedule.lastName}
            </div>
            {Object.values(userSchedule.tasksByDay).map((tasks, index) => {
              const totalDuration = tasks.reduce((sum, task) => sum + parseFloat(task.duration), 0);

              return (
                <div key={index} className={`${width} border items-center flex justify-center`}>
                  {tasks.length > 0 ? (
                    tasks.map((task) => {
                      const taskWidthPercentage = totalDuration > 0 ? Math.round((parseFloat(task.duration) / totalDuration) * 100) : 0; // Calculate the width percentage based on duration

                      return (
                        <div
                          key={task.id}
                          className="bg-taskinatorWhite rounded border-taskinatorGreen text-xs border h-20 overflow-hidden mr-0.5 p-2 hover:ring-1 hover:ring-taskinatorGreen cursor-pointer text-xs"
                          style={{ width: `${(taskWidthPercentage / 100) * containerWidthRem}rem` }} // Set the width dynamically
                        >
                          <div className="uppercase text-taskinatorDarkGrey flex items-start flex-col overflow-hidden">
                            <div className="font-bold pr-1 overflow-hidden">{task.jobRef}</div>
                            <div className="overflow-hidden">{StringShortenDisplay(15, task.title)}</div>
                            <div className="overflow-hidden">{Math.round(parseFloat(task.duration))} hrs</div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="text-taskinatorDarkGrey text-xs">No tasks</div>
                  )}
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default CalendarRowElement;
