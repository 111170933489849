import axios from "axios";
import React, { useContext, useState, useEffect } from "react";
import { IoDocumentsSharp } from "react-icons/io5";
import { MdClose, MdDehaze, MdLibraryBooks, MdLogout, MdManageAccounts, MdPeopleAlt, MdQuestionMark, MdTimeline, MdEventNote, MdGroups, MdAdminPanelSettings } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../components/UI_elements/button";
import HeaderTitle from "../components/UI_elements/headerTitle";
import Modal from "../components/UI_elements/modal";
import StringShortenDisplay from "../components/UI_elements/stringShortenDisplay";
import ProjectView from "../components/displayElements/project/projectView";
import { useInit } from "../components/hooks/useInit";
import { AppContext } from "../contexts/appContext";
import NewJob from "../pages/newTask/newJob";
import NewTask from "../pages/newTask/newTask";
import JobListFetcher from "../utils/jobListFetcher";
import LowerCaseTrim from "../utils/lowerCaseAndTrim";
const SideNav = () => {
  const appData = useContext(AppContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { initState, initStarted, initCompleted, initErrors } = useInit();
  const [menuOpen, setMenuOpen] = useState();
  const [guide, setGuide] = useState();
  const [newTask, setNewTask] = useState();
  const [newJob, setNewJob] = useState();
  const [newProject, setNewProject] = useState();
  const [showDetails, setShowDetails] = useState(true);

  useEffect(() => {
    setShowDetails();
  }, [newJob]);

  const isCPT = LowerCaseTrim(appData.workspaceDataV4.orgName) === "cpt engineering";

  let MenusData = [
    {
      header: "YOUR",
      links: [
        {
          title: "Timesheet",
          icon: <MdEventNote />,
          link: "/timesheet",
          sid: "timesheet",
          access: [],
        },
        // {
        //   title: "Jobs",
        //   icon: <MdEventNote />,
        //   link: "/jobs",
        //   sid: "jobs",
        //   access: [],
        // },
      ],
      access: [process.env.REACT_APP_ACCESS_M, process.env.REACT_APP_ACCESS_A, process.env.REACT_APP_ACCESS_O, process.env.REACT_APP_ACCESS_T], /// Nothing in array means all have access
    },
    {
      header: "TRACK ALL",
      links: [
        {
          title: "Schedules",
          icon: <MdPeopleAlt />,
          link: "/track/team",
          sid: "all",
          access: [process.env.REACT_APP_ACCESS_M, process.env.REACT_APP_ACCESS_A, process.env.REACT_APP_ACCESS_O, process.env.REACT_APP_ACCESS_D],
        },
        {
          title: "Jobs",
          icon: <MdLibraryBooks />,
          link: "/track/jobs",
          sid: "all",
          access: [process.env.REACT_APP_ACCESS_M, process.env.REACT_APP_ACCESS_A, process.env.REACT_APP_ACCESS_O, process.env.REACT_APP_ACCESS_D],
        },
        {
          title: "Questions",
          icon: <MdQuestionMark />,
          link: "/track/questions",
          sid: "all",
          access: [process.env.REACT_APP_ACCESS_M, process.env.REACT_APP_ACCESS_A, process.env.REACT_APP_ACCESS_O, process.env.REACT_APP_ACCESS_D],
        },

        {
          title: `${appData.itemName}s`,
          icon: <IoDocumentsSharp />,
          link: `/track/items`,
          access: [process.env.REACT_APP_ACCESS_M, process.env.REACT_APP_ACCESS_G, process.env.REACT_APP_ACCESS_A, process.env.REACT_APP_ACCESS_O, process.env.REACT_APP_ACCESS_T],
        },

        {
          title: `Folders`,
          icon: <IoDocumentsSharp />,
          link: `/track/files`,
          access: [process.env.REACT_APP_ACCESS_M, process.env.REACT_APP_ACCESS_G, process.env.REACT_APP_ACCESS_A, process.env.REACT_APP_ACCESS_O, process.env.REACT_APP_ACCESS_T],
        },
      ],
      access: [process.env.REACT_APP_ACCESS_M, process.env.REACT_APP_ACCESS_A, process.env.REACT_APP_ACCESS_O],
    },
    {
      header: "MANAGE",
      links: [
        {
          title: "Team",
          icon: <MdPeopleAlt />,
          link: `/manage/team`,
          access: [process.env.REACT_APP_ACCESS_A, process.env.REACT_APP_ACCESS_O],
        },
        // {
        //   title: "3rd Parties",

        //   icon: <MdGroups />,
        //   link: `/manage/thirdparty`,
        //   access: [process.env.REACT_APP_ACCESS_A, process.env.REACT_APP_ACCESS_O],
        // },
        {
          title: "Workflows",
          icon: <MdTimeline />,
          link: `/manage/workflows`,
          access: [process.env.REACT_APP_ACCESS_A, process.env.REACT_APP_ACCESS_O],
        },
      ],
      access: [process.env.REACT_APP_ACCESS_A, process.env.REACT_APP_ACCESS_O],
    },

    {
      header: "SETTINGS",
      links: [
        {
          title: "Account",
          icon: <MdAdminPanelSettings />,
          link: `/settings/account`,
          access: [process.env.REACT_APP_ACCESS_O],
        },
        {
          title: "User",
          icon: <MdManageAccounts />,
          link: `/settings/user`,
          access: [],
        },
      ],
      access: [],
    },
  ];

  if (!isCPT) {
    MenusData = MenusData.map((menu) => {
      if (menu.header === "YOUR") {
        return null;
      }

      if (menu.header === "TRACK ALL") {
        return { ...menu, links: menu.links.filter((link) => link.link !== `/track/items`) };
      }
      // if (menu.header === "MANAGE") {
      //   return { ...menu, links: menu.links.filter((link) => link.link !== `/manage/thirdparty`) };
      // }

      return menu;
    }).filter((data) => data);
  }

  const onLogoutHandler = async () => {
    initStarted(0);

    try {
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/auth/logout`, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      });

      appData.setWorkspaceDataV4();
      // window.localStorage.removeItem(`google_token`);
      initCompleted(0);
    } catch (err) {
      console.log(err);
      initErrors(err, 0);
    }
  };

  return (
    <>
      <div className="flex md:flex-col h-full w-full justify-start">
        <div className="flex md:flex-col w-full flex-row-reverse mb-2">
          <div className="h-full md:h-16 flex items-center w-full">
            <HeaderTitle customCSS={"text-taskinatorBlue md:ml-0 md:justify-center ml-2 text-l text-2xl md:text-xl cursor-pointer md:flex-row"} />
          </div>

          <div className="md:w-full w-40 md:flex hidden justify-center items-center md:h-8 md:my-0 my-2 md:mx-0 mx-2">
            <div
              className="w-full h-full justify-center items-center text-xs hover:opacity-70 mx-1 rounded text-taskinatorWhite bg-taskinatorBlue py-2 cursor-pointer font-medium"
              onClick={() => {
                navigate("/tasks");
              }}
            >
              <div className="uppercase text-center h-full flex items-center justify-center">My Dash</div>
            </div>
          </div>

          {[process.env.REACT_APP_ACCESS_M, process.env.REACT_APP_ACCESS_A, process.env.REACT_APP_ACCESS_O].includes(appData.workspaceDataV4.role) && (
            <div className="w-full  md:flex-col md:pt-2 pt-0 justify-center md:flex hidden">
              <div className="md:w-full w-40 flex justify-center items-center md:h-8 md:my-0 my-2 md:mx-0 mx-2">
                <div
                  className="w-full h-full justify-center items-center text-xs hover:opacity-70 mx-1 rounded text-taskinatorBlue border border-taskinatorBlue py-2 cursor-pointer font-medium"
                  onClick={() => setNewJob(true)}
                >
                  <div className="uppercase text-center h-full flex items-center justify-center">Create Job</div>
                </div>
              </div>
            </div>
          )}

          {appData.jobListFilterOptions && (
            <div className="mx-1">
              <JobListFetcher />
            </div>
          )}
        </div>
        <div className=" mr-2 cursor-pointer hover:opacity-80 items-center  justify-end sm:w-full w-40 flex md:hidden text-taskinatorBlue text-xl ">
          <div
            onClick={() => {
              setMenuOpen(!menuOpen);
            }}
          >
            {menuOpen ? <MdClose size={"2rem"} /> : <MdDehaze size={"2rem"} />}
          </div>
        </div>
        <div className="hidden md:block sticky grow">
          {MenusData.map(
            (menus, mIndex) =>
              (menus.access.length === 0 || menus.access.includes(appData.workspaceDataV4.role)) && (
                <div key={mIndex}>
                  {menus.header && <div className="text-taskinatorMedGrey text-xs mt-5 flex w-full px-1 mb-2 opacity-50">{menus.header}</div>}

                  {menus.links.map(
                    (links, lIndex) =>
                      (links.access.length === 0 || links.access.includes(appData.workspaceDataV4.role) || links.access.includes(appData.creatorType)) && (
                        <div key={lIndex} className={`cursor-pointer text-sm font-medium my-2 flex `} onClick={() => navigate(links.link)}>
                          <div
                            className={`flex items-center md:justify-start px-1 h-6 w-full ${
                              location.pathname.toLowerCase() === links.link.toLowerCase()
                                ? "bg-taskinatorHighlightBlue text-taskinatorDarkGrey font-semibold"
                                : "text-taskinatorMedGrey hover:text-taskinatorBlue"
                            } `}
                          >
                            <div className="mr-2 md:text-sm text-base">{links.icon}</div>
                            <div className="hidden md:block w-0 lg:w-fit whitespace-nowrap">
                              {links.title} {links.beta ? "(Beta)" : ""}
                            </div>
                            <div className="hidden md:block">{links.notificationDisplayBubble}</div>
                          </div>
                        </div>
                      )
                  )}
                </div>
              )
          )}
        </div>
        {/* <div className="my-8 px-1 md:flex hidden">
          <ToolTip full video content={<GuideDisplay url={`https://www.loom.com/embed/7f4cc468f31449f0aa163ec85ee7da6f?sid=ed95a7c7-1408-42cd-a95b-7d438241d264`} />} />
        </div> */}

        <div className="hidden md:flex-col md:flex w-full">
          {appData.workspaceDataV4?.userName && (
            <div className="flex justify-center w-full">
              <div className="text-center text-taskinatorLightGrey text-base uppercase">{StringShortenDisplay(10, appData.workspaceDataV4.userName)}</div>
            </div>
          )}
          {appData.workspaceDataV4?.orgName && (
            <div className="flex flex-col w-full mt-2 border-y border-y-taskinatorBlue px-2 py-1 text-taskinatorBlue items-center">
              <div className="text-taskinatorLightGrey text-[0.6rem] opacity-75">Organisation</div>
              <div className="w-full text-center text-sm font-semibold uppercase">{StringShortenDisplay(10, appData.workspaceDataV4.orgName)}</div>
            </div>
          )}
        </div>

    
        <div className="hidden md:flex justify-center">
          <Button
            customClasses="border-taskinatorMedGrey border-2 my-5 border-solid text-taskinatorMedGrey h-8 w-fit flex items-center justify-center opacity-50 hover:opacity-100"
            onClick={() => onLogoutHandler()}
            label={
              <div className="flex">
                <MdLogout />
                <div className="text-xs pl-2 hidden md:block">Logout</div>
              </div>
            }
            isLoading={initState[0].started}
          />
        </div>
      </div>
      {menuOpen && (
        <div className="bg-taskinatorDarkGrey mt-0.5 pt-2 pb-4 z-40 w-full grow shadow sticky">
          <div className="w-full h-full">
            <div className=" flex  flex-col ml-2">
              <div>
                <Button
                  label="Dashboard"
                  customClasses={"text-taskinatorWhite bg-taskinatorBlue h-8 mb-2 w-full"}
                  onClick={() => {
                    navigate("/tasks");
                    setMenuOpen();
                  }}
                />
              </div>
              <div>
                <Button
                  label="Create Job"
                  customClasses={"text-taskinatorDarkGrey bg-taskinatorMedGrey bg-taskinatorHoverGrey h-8 w-full"}
                  onClick={() => {
                    setNewJob(true);
                    setMenuOpen();
                  }}
                />
              </div>
            </div>
            {MenusData.map(
              (menus, mIndex) =>
                (menus.access.length === 0 || menus.access.includes(appData.workspaceDataV4.role)) && (
                  <div key={mIndex}>
                    {menus.header && <div className="text-taskinatorMedGrey text-sm mt-8 flex w-full px-1 mb-2 opacity-50">{menus.header}</div>}

                    {menus.links.map(
                      (links, lIndex) =>
                        (links.access.length === 0 || links.access.includes(appData.workspaceDataV4.role) || links.access.includes(appData.creatorType)) && (
                          <div
                            key={lIndex}
                            className={`cursor-pointer text-lg my-4 flex items-center`}
                            onClick={() => {
                              setMenuOpen();
                              navigate(links.link);
                            }}
                          >
                            <div
                              className={`flex items-center px-1 h-6 w-full ${
                                location.pathname.toLowerCase() === links.link.toLowerCase()
                                  ? "bg-taskinatorHighlightBlue text-taskinatorDarkGrey font-semibold"
                                  : "text-taskinatorLightGrey hover:text-taskinatorBlue"
                              }`}
                            >
                              <div className="mr-2">{links.icon}</div>
                              <div className="whitespace-nowrap">{links.title}</div>
                              <div>{links.notificationDisplayBubble}</div>
                            </div>
                          </div>
                        )
                    )}
                  </div>
                )
            )}
          </div>
        </div>
      )}
      {newTask && (
        <Modal bgColour="bg-taskinatorLightGrey" size="max" local={() => setNewTask()}>
          <NewTask closeModal={() => setNewTask()} />
        </Modal>
      )}
      {/* size={showDetails ? "max" : "large"} */}
      {newJob && (
        <Modal bgColour="bg-taskinatorLightGrey" size={"max"} local={() => setNewJob()}>
          <NewJob closeModal={() => setNewJob()} setShowDetails={setShowDetails} />
        </Modal>
      )}
      {newProject && (
        <Modal size="max" local={() => setNewProject()}>
          <ProjectView isNew={true} />
        </Modal>
      )}
      {guide && (
        <div>
          <Modal local={() => setGuide()} size={"large"}>
            <div className="h-full">
              <iframe src={process.env.REACT_APP_THIRD_PARTY_GUIDE_VIDEO_LINK} frameBorder="0" style={{ top: 0, left: 0, height: "100%", width: "100%" }} title="guide" />
            </div>
          </Modal>
        </div>
      )}
    </>
  );
};

export default SideNav;
