import React, { useState } from "react";
import Button from "../UI_elements/button";
import Input_V4 from "../inputs/input_V4";
import Modal from "../UI_elements/modal";
import { useInputV4 } from "../../stateManagement/useInputV4";
import { v4 as uuidv4 } from "uuid";

const AddEditNoteForm = ({ noteData, buttonLabel, setNotes, notes }) => {
  const [modal, setModal] = useState();
  const { inputState, inputHandler } = useInputV4();

  // const onNoteHandler = () => {
  //   setNotes({ ...notes, [uuidv4()]: inputState.inputs.note.value });
  //   setModal();
  // };

  return (
    <>
      <div>
        <Button
          label={buttonLabel ? buttonLabel : "Add Action"}
          customClasses={"border-taskinatorGreen border text-taskinatorGreen  hover:text-taskinatorWhite hover:bg-taskinatorGreen  text-xs  mx-1 py-2 "}
          onClick={() => setModal(true)}
        />
      </div>
      {modal && (
        <Modal local={() => setModal()} title={noteData ? "Edit Action" : "Create Action"}>
          <div className="w-96">
            <Input_V4 onInput={inputHandler} customClasses="text-sm" id="note" sid="inputs" validators={[]}  placeholder={"Add Action"} />
            <Button
              label={noteData ? "Save" : "Create"}
              customClasses={` bg-taskinatorBlue text-taskinatorWhite h-10 w-full mt-2 `}
              onClick={() => {
                setNotes(inputState.inputs.note.value);
                setModal();
              }}
            />
          </div>
        </Modal>
      )}{" "}
    </>
  );
};

export default AddEditNoteForm;
