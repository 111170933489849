import React, { useState, useEffect } from "react";
import ContentView from "../../pageTemplates/contentView";
import axios from "axios";
import { useInit } from "../../components/hooks/useInit";
import IconSpinner from "../../components/UI_elements/iconSpinner";
import DropDownSelector_V4 from "../../components/inputs/dropDownSelector_V4";
import ActivityDisplay from "../../components/displayElements/actionDisplay/activityDisplay";
import Input_V4 from "../../components/inputs/input_V4";
import { useInputV4 } from "../../stateManagement/useInputV4";
import { VALIDATOR_MINLENGTH } from "../../components/forms/validators/validators";

const Activity = () => {
  const [activity, setActivity] = useState();

  const [activityRange, setActivityRange] = useState(7);
  const [searchValue, setSearchValue] = useState("");
  const { inputState, inputHandler } = useInputV4({ inputs: { search: { value: "", isValid: false } }, isValid: false });
  const { initState, initStarted, initCompleted, initErrors, initReset } = useInit();



  const sendRequest = () => {
    initStarted(0);
    axios
      .get(process.env.REACT_APP_BACKEND_URL + `/api/activity/mine/${activityRange}/$${inputState.inputs.search.value}`, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      .then((response) => {
        setActivity(response.data.activity);
        initCompleted(0);
      })
      .catch((err) => {
        console.log(err);
        return initErrors(err?.response?.data?.message ?? err?.message ?? '', 0);
      });
  };

  useEffect(() => {
    sendRequest();
  }, [activityRange]);

  useEffect(() => {
    const delay = 500;
    let timer;

    clearTimeout(timer);

    if (inputState.inputs.search.value.trim().length > 0) {
      timer = setTimeout(() => {
        sendRequest();
      }, delay);
    } else if (inputState.inputs.search.value.trim().length === 0) {
      sendRequest();
    }
    return () => {
      clearTimeout(timer);
    };
  }, [inputState]);

  return (
    <ContentView
      buttons={[]}
     
      centerContent={
        <div className="flex items-center justify-center w-full">
          <DropDownSelector_V4
            customClasses={"w-40 items-center pr-2"}
            id="range"
            defaultValue="7"
            options={[
              { data: { name: "7 days", value: "7", type: "" }, name: "7 days", value: "7", type: "" },
              { data: { name: "30 days", value: "30", type: "" }, name: "30 days", value: "30", type: "" },

              { data: { name: "60 days", value: "60", type: "" }, name: "60 days", value: "60", type: "" },
            ]}
            onSelection={(id, value, isValid, sid) => {
              setActivityRange(value.value);
            }}
          />
          <Input_V4
            defaultValue={inputState.inputs.search.value}
            customClasses={"sm:w-96 w-[100%] items-center"}
            id={"searchValue"}
            onInput={inputHandler}
            placeholder={"Search Activity"}
            validators={[VALIDATOR_MINLENGTH(1)]}
          />
        </div>
      }
    >
      {initState[0].started && (
        <div className="mt-2 flex items-center justify-center">
          <IconSpinner size="m" />
          Getting Activity....
        </div>
      )}
      {initState[0].error && <div className=" text-taskinatorRed w-full text-center">Error Getting Activity</div>}
      {!initState[0].started &&
        activity &&
        activity.map((actionComment) => {
          return (
            <div className="bg-taskinatorWhite border border-taskinatorMedGrey px-4 m-0.5 py-2 mb-1 items-center hover:bg-taskinatorHoverBlue ">
              <ActivityDisplay actionComment={actionComment} row textSize={"text-sm"} />
            </div>
          );
        })}
    </ContentView>
  );
};

export default Activity;
