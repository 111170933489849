const JobListFormatter = (tasks, isJobModel) => {
  const transformed = {};

  ///this gives us objects where the key is the tag and the values are the array of tids  that have that tag.
  tasks.forEach((task) => {
    const detailsValueToUse = task?.workflows ? "jobDetails" : "taskDetails";
    const iDtoUse = task?.workflows ? "_id" : "tid";

    // Determine the tags, use "no tags" if none
    const tags = task[detailsValueToUse].taskTags.length > 0 ? task[detailsValueToUse].taskTags : [{ name: "no tags" }];

    tags.forEach((tag) => {
      const tagName = tag.name;

      // Ensure initialization of the tagName section with an array for tids
      if (!transformed[tagName]) {
        transformed[tagName] = [];
      }

      // Add tid if not already present
      if (!transformed[tagName].includes(task[iDtoUse])) {
        transformed[tagName].push(task[iDtoUse]);
      }
    });
  });

  // Create an "all tags" section, aggregating unique tids across all tasks
  transformed["all tags"] = [];
  Object.keys(transformed).forEach((tagName) => {
    if (tagName !== "all tags") {
      // Avoid including the "all tags" key in its own aggregation
      transformed[tagName].forEach((tid) => {
        if (!transformed["all tags"].includes(tid)) {
          transformed["all tags"].push(tid);
        }
      });
    }
  });

  return transformed;
};

export default JobListFormatter;
