import React, { useContext, useState } from "react";
import TaskCommentsNoteAdder from "./taskCommentAdder";
import FilePreview from "../../../utils/filePreview";
import FormatDate from "../../../utils/formattedDate";
import { AppContext } from "../../../contexts/appContext";
import TaskCommentBubble from "./taskCommentBubble";
import AutoScrollDownContainer from "../../../utils/autoScrollDownContainer";
import LinkifyText from "../../../utils/linkifyText";
const TaskCommentDisplay = ({ comments, setComments, taskId, jobId, isCompleted }) => {
  const commentDataToUse = comments?.sort((a, b) => new Date(a.createdDate) - new Date(b.createdDate));

  return (
    <>
      <div className=" w-full ">
        {/* <div>Activity:</div> */}
        <AutoScrollDownContainer id={"comments"} customCSS={"max-h-[15rem] pr-2   my-1 p"} triggerAgain={comments?.length}>
          {commentDataToUse?.length > 0 ? (
            commentDataToUse.map((comment) => {
              return (
                <div className=" mt-1 w-full  mb-1" key={comment._id}>
                  <div className="flex items-center my-1 justify-between text-xxs font-semibold text-taskinatorDarkGrey ">
                    <div className="capitalize pr-1  ">
                      {" "}
                      {comment?.createdBy?.firstName} {comment?.createdBy?.lastName}
                    </div>
                    {comment.editedDate ? (
                      <div className="flex">
                        {FormatDate(comment?.editedDate)} <div className="pl-1 text-taskinatorMedGrey">edited</div>
                      </div>
                    ) : (
                      FormatDate(comment?.createdDate)
                    )}
                  </div>
                  <TaskCommentBubble isCompleted={isCompleted} comment={comment} setComments={setComments} taskId={taskId} jobId={jobId} />
                </div>
              );
            })
          ) : (
            <div className="text-taskinatorMedGrey">No Comments</div>
          )}{" "}
        </AutoScrollDownContainer>
      </div>

      <div className="w-full  mt-1   pt-1">
        {" "}
        <TaskCommentsNoteAdder
          taskId={taskId}
          jobId={jobId}
          refresh={(newComment) =>
            setComments((comments) => {
              return [...comments, newComment];
            })
          }
          isCompleted={isCompleted}
        />
      </div>
    </>
  );
};

export default TaskCommentDisplay;
