import React, { useState, useContext } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

import { AppContext } from "../../../contexts/appContext";
import { useInit } from "../../hooks/useInit";
import Button from "../../UI_elements/button";
import Modal from "../../UI_elements/modal";


import FilesGetAndSearchContainer from "./fileGetAndSearchContainer";


const AddFileButton = ({ itemsOnTask, selectableItemsOnTask, globalSetItems, aid, disabled, wfid, type, tid, searchAndAdd, multi, fileSetExclusions }) => {
  const appData = useContext(AppContext);
  // const { tid } = useParams();
  const [itemModal, setItemModal] = useState();
  const { initState, initStarted, initCompleted, initErrors, initReset } = useInit();
  const [addWorkflow, setAddWorkflow] = useState();

  
  //// add file 
  const addItemHandler = (itemList) => {
    if (!globalSetItems) {
      axios
        .put(
          process.env.REACT_APP_BACKEND_URL + `/api/tracer/addItem/${tid}`,
          {
            itemList: Object.values(itemList),
            // commitmentDate: inputState.inputs.commitmentDate.value,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        )
        .then((response) => {
          appData.ADD_TASK(response.data.updatedTracer);
          initCompleted(0);
        })
        .catch((err) => {
          console.log(err);
          initErrors(err?.response?.data?.message ?? err?.message ?? "", 0);
        });
    }
  };

  return (
    <>
      <div className="items-center flex justify-center flex-col">
        <div onMouseOver={() => setAddWorkflow(true)} onMouseLeave={() => setAddWorkflow()}>
          <Button
            label={`Add Folder`}
            customClasses={`my-2 ${
              !disabled ? "border-taskinatorBlue text-taskinatorBlue hover:text-taskinatorWhite hover:bg-taskinatorBlue " : "text-taskinatorMedGrey"
            }  w-fit border    text-xs  mx-1 py-2 `}
            onClick={() => setItemModal(true)}
            isLoading={initState[0].started}
            disabled={disabled}
          />
        </div>
      
      </div>
      {itemModal && (
        <Modal bgColour="bg-taskinatorLightGrey" size="max" local={() => setItemModal()}>
          <div className="h-full flex flex-col overflow-auto justify-between">
            <FilesGetAndSearchContainer
              searchAndAdd={searchAndAdd}
              setItemModal={setItemModal}
              itemsOnTask={itemsOnTask}
              selectableItemsOnTask={selectableItemsOnTask}
              globalSetItems={globalSetItems}
              addItemHandler={addItemHandler}
              wfid={wfid}
              type={type}
              tid={tid}
              hideFiles
              disableDetails
              hideArchived
              dontRetainSelection={!multi}
              fileSetExclusions={fileSetExclusions}
              newJob
            />
          </div>
        </Modal>
      )}
    </>
  );
};

export default AddFileButton;
