import { createContext } from 'react'

export const AppContext = createContext({
  topLevelModal: null,
  setTopLevelModal: () => {},
  modelSetController: () => {},
  modelCloseController: () => {},
  workspaceDataV4: null,
  setWorkspaceDataV4: () => {},
  myTaskState: null,
  RefreshTasks: () => {},
  getWorkspaceData: () => {},
  ADD_TASK: () => {},
  ERROR: () => {},
  initialLoad: null,
  refresh: null,
  viewAction: null,
  isModalOpened: false,
  setIsModalOpened: () => {},
  setViewAction: () => {},
  socket: null
})
