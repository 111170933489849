import axios from "axios";
import React, { useContext, useState } from "react";
import { useInputV4 } from "../../stateManagement/useInputV4";
import Button from "../UI_elements/button";
import { useInit } from "../hooks/useInit";
import CheckboxV4 from "../inputs/checkbox_V4";
import InputV4 from "../inputs/input_V4";
import { VALIDATOR_EMAIL, VALIDATOR_MINLENGTH } from "./validators/validators";
import { AppContext } from "../../contexts/appContext";
import { useGoogleLogin } from "@react-oauth/google";
import { useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import IconSpinner from "../UI_elements/iconSpinner";

const loginRequest = {
  scopes: ["User.Read"], // Specify scopes
};

const SignupFormV4 = () => {
  const { initState, initStarted, initCompleted, initErrors } = useInit();
  const { inputState, inputHandler } = useInputV4({
    inputs: { confirmAgree: { value: false, isValid: true } },
    isValid: false,
  });
  const [oAuthLogin, setOAuthLogin] = useState();
  const navigate = useNavigate();
  const appData = useContext(AppContext);
  const { instance } = useMsal();

  const googleSignIn = useGoogleLogin({
    onSuccess: (codeResponse) => {
      initStarted(0);
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/api/auth/google/signup`,
          {
            accessToken: codeResponse.access_token,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        )
        .then((response) => {
          appData.setWorkspaceDataV4(response.data.workspaceData);
          // navigate("/tasks");
        })
        .catch((err) => {
          setOAuthLogin();
          const errorMessage = err?.response?.data?.message ?? err?.message ?? "";
          return initErrors(errorMessage, 0);
        });
    },
  });

  const handleMicrosoftLogin = (event) => {
    event.preventDefault();
    setOAuthLogin(true);
    instance
      .loginPopup(loginRequest)
      .then((response) => {
        initStarted(0);
        console.log("signing up", response)
        axios
          .post(
            `${process.env.REACT_APP_BACKEND_URL}/api/auth/aad/signup`,
            {
              accessToken: response.accessToken,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
              withCredentials: true,
            }
          )
          .then((response) => {
            appData.setWorkspaceDataV4(response.data.workspaceData);
            // navigate("/tasks");
            setOAuthLogin();
          })
          .catch((err) => {
            setOAuthLogin();
            const errorMessage = err?.response?.data?.message ?? err?.message ?? "";
            return initErrors(errorMessage, 0);
          });
        // Handle login success, e.g., navigate or set user data
      })
      .catch((e) => {
        // Handle errors
        setOAuthLogin();
        console.error(e);
      });
  };

  const submitHandler = async (event) => {
    event.preventDefault();
    initStarted(0);

    axios
      .put(
        `${process.env.REACT_APP_BACKEND_URL}/api/auth/signup`,
        {
          email: inputState.inputs.email.value,
          password: inputState.inputs.password.value,
          firstName: inputState.inputs.firstName.value,
          lastName: inputState.inputs.lastName.value,
        },
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      )
      .then((response) => {
        appData.setWorkspaceDataV4(response.data.workspaceData);
        // navigate("/tasks");
      })
      .catch((err) => {
        const errorMessage = err?.response?.data?.message ?? err?.message ?? "";
        return initErrors(errorMessage, 0);
      });
  };


  return (
    !initState[0].started ? 
    <div className="w-full flex flex-col grow md:h-full h-fit justify-center items-center gap-2" key={"signup"}>
      <div className="font-medium uppercase justify-start text-start w-full">Sign Up With:</div>

      <button
        onClick={() => {
          googleSignIn();
          setOAuthLogin(true);
        }}
        className="w-full flex justify-center items-center box-border p-2 gap-2 border border-solid border-taskiantorMedDarkGrey rounded-md"
      >
        <img src="https://aid-frontend.prod.atl-paas.net/atlassian-id/front-end/5.0.541/google-logo.5867462c.svg" width={20} height={20} />
        <span>Continue with Google</span>
      </button>
      <button onClick={handleMicrosoftLogin} className="w-full flex justify-center items-center box-border p-2 gap-2 border border-solid border-taskiantorMedDarkGrey rounded-md">
        <img src="https://aid-frontend.prod.atl-paas.net/atlassian-id/front-end/5.0.541/microsoft-logo.c73d8dca.svg" width={20} height={20} />
        <span>Continue with Microsoft</span>
      </button>
      <div className="w-full flex justify-center items-center">or</div>
      <form className="w-full" onSubmit={submitHandler}>
        <div className="-rounded-md ">
          <div className="pt-2">
            <InputV4
              inputVer="1"
              // label="First Name"
              sid="inputs"
              id="firstName"
              placeholder={`First Name (Required)`}
              customClasses={"rounded-md"}
              onInput={inputHandler}
              validators={[VALIDATOR_MINLENGTH(2)]}
              error={"Too short"}
            />
          </div>
          <div className="pt-2">
            <InputV4
              inputVer="1"
              // label="Last Name"
              sid="inputs"
              id="lastName"
              placeholder={`Last Name (Required)`}
              customClasses={"rounded-md"}
              onInput={inputHandler}
              validators={[VALIDATOR_MINLENGTH(2)]}
              error={"Too short "}
            />
          </div>
          <div className="pt-2">
            <InputV4
              inputVer="1"
              // label="Email"
              sid="inputs"
              id="email"
              placeholder={`Email (Required)`}
              customClasses={"rounded-md"}
              onInput={inputHandler}
              validators={[VALIDATOR_EMAIL()]}
              error={"Must be email "}
            />
          </div>
          <div className="pt-2">
            <InputV4
              inputVer="1"
              // label="Password"
              type="password"
              sid="inputs"
              id="password"
              placeholder={`Password (Required)`}
              customClasses={"rounded-md"}
              onInput={inputHandler}
              validators={[VALIDATOR_MINLENGTH(6)]}
              error={"Minimum 6 characters"}
            />
          </div>
          <div className="">
            <div className="text-sm pt-4 items-center">
              <div className="text-xs items-center text-center mx-auto h-full  text-taskinatorDarkGrey">
                By clicking "Continue With Google" or "Continue With Microsoft" or "Create Account" you confirm you have read and agree to the
                <a onClick={() => window.open(`${process.env.REACT_APP_WEBSITE}/terms`, "_blank")} className="pl-1 text-taskinatorBlue cursor-pointer">
                  terms
                </a>{" "}
                and
                <a onClick={() => window.open(`${process.env.REACT_APP_WEBSITE}/privacy`, "_blank")} className="pl-1 text-taskinatorBlue cursor-pointer">
                  privacy
                </a>
              </div>
            </div>
          </div>
        </div>

        <div>
          <Button
            type="submit"
            customClasses={`${inputState.isValid ? "bg-taskinatorBlue" : "bg-taskinatorMedGrey"} text-taskinatorWhite h-10 w-full  my-2`}
            label={"Create Account"}
            isLoading={initState[0].started}
            disabled={!inputState.isValid}
            error={initState[0].error}
          />
        </div>
      </form>
    </div> : <IconSpinner />
  );
};

export default SignupFormV4;
