import React from "react";

// ProgressBar component that takes `percentage` as a prop
const ProgressBar = ({ percentage }) => {
  return (
    <div className="w-36 border rounded-full h-1 ">
      <div className="bg-taskinatorGreen h-1 rounded-full" style={{ width: `${percentage}%` }}></div>
    </div>
  );
};

export default ProgressBar;
