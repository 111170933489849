import React, { useContext, useEffect, useState } from "react";

import axios from "axios";

import { AppContext } from "../../../contexts/appContext";
import { RequestContext } from "../../../contexts/requestContext";
import IconSpinner from "../../UI_elements/iconSpinner";
import RequestForm from "../../forms/requestForm";
import { useInit } from "../../hooks/useInit";
import ViewRequest from "../requests/viewRequest";

const JobRequests = ({ group, canSetRequests, tid, simpleView, tracer, setQuestions }) => {
  const [taskRequest, setTaskRequests] = useState({});

  const { initState, initStarted, initCompleted, initErrors, initReset } = useInit();

  const appData = useContext(AppContext);
  const [showCompleted, setShowCompleted] = useState();
  const [requestModal, setRequestModal] = useState();

  useEffect(() => {
    if (canSetRequests) {
      const { setButtons, buttons } = canSetRequests;

      const buttonLabel = "Add Question";
      if (buttons.filter((button) => button.label === buttonLabel).length === 0) {
        setButtons([
          ...buttons,
          {
            label: buttonLabel,
            customClasses: ` text-taskinatorWhite border-taskinatorOrange bg-taskinatorOrange border rounded px-2 py-1 text-xs uppercase h-8 w-full`,
            onClick: () => setRequestModal(true),
          },
        ]);
      }
    }
  }, []);

  const getRequests = () => {
    initStarted(0);
    axios
      .get(process.env.REACT_APP_BACKEND_URL + `/api/requests/retrieve/${group}/${tid}`, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      .then((response) => {
        setTaskRequests(response.data.data);
        if (setQuestions) setQuestions(Object.values(response.data.data).length);
        initCompleted(0);
      })
      .catch((err) => {
        console.log(err);
        return initErrors(err?.response?.data?.message ?? err?.message ?? "", 0);
      });
  };

  useEffect(() => {
    getRequests();
  }, [group]);

  appData.socket.on("questionUpdate", (data) => {
    if (data.action === "add") {
      setTaskRequests({ ...taskRequest, [data.newQuestion._id]: data.newQuestion });
    } else {
    }
  });

  return (
    <RequestContext.Provider
      value={{
        getRequests: getRequests,
      }}
    >

      
      <div className="flex flex-col">
        {initState[0].started && !initState[0].initialised && (
          <div className=" my-4">
            <IconSpinner size="m" />
          </div>
        )}
        {initState[0].initialised && !taskRequest && <div className="text-sm text-center pt-2 text-taskinatorDarkGrey">No Active Requests</div>}
        {initState[0].error && <div className="text-sm text-center pt-2 text-taskinatorRed">Error Getting Questions</div>}

        {!simpleView &&
          initState[0].initialised &&
          taskRequest &&
          Object.values(taskRequest)
            .filter((request) => !request.completed)
            .map((request, index) => {
              return <ViewRequest key={request._id} request={request} group={group} getData={() => {}} />;
            })}

        <div className="text-[0.6rem] mt-1"></div>

        {!simpleView &&
          initState[0].initialised &&
          taskRequest &&
          Object.values(taskRequest)
            .filter((request) => request.completed)
            .map((request) => {
              return <ViewRequest key={request._id} request={request} group={group} getData={() => {}} />;
            })}
      </div>
      {requestModal && (
        <div>
          <RequestForm
            tid={tid}
            tracer={tracer}
            onClose={() => setRequestModal()}
            onSuccess={() => {
              //   getRequests();
            }}
          />
        </div>
      )}
    </RequestContext.Provider>
  );
};

export default JobRequests;
