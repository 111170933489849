import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import IconSpinner from "../../components/UI_elements/iconSpinner";
import Modal from "../../components/UI_elements/modal";
import WorkflowContainer from "../../components/displayElements/workflowContainer";
import NewWorkflowForm from "../../components/forms/newWorkflowForm";
import { useInit } from "../../components/hooks/useInit";
import { AppContext } from "../../contexts/appContext";
import GuideDisplay from "../../guides/guideDisplay";
import ContentView from "../../pageTemplates/contentView";
import { getAllWorkflow } from "../../services/workflow.service";

const Workflow = () => {
  const appData = useContext(AppContext);
  const { initState, initStarted, initCompleted, initErrors } = useInit(2);
  const [workflowData, setWorkflowData] = useState();
  const [teamData, setTeamData] = useState([]);
  const [modal, setModal] = useState();

  const buttons = [
    // {
    //   label: "+ New Task",
    //   customClasses: "border border-taskinatorBlue text-taskinatorBlue h-10 text-xs md:text-sm ml-2 py-2",
    //   onClick: () => navigate("/tasks/create"),
    //   id: "newTask",
    // },
    {
      label: "New Workflow",
      customClasses: "bg-taskinatorBlue text-taskinatorWhite h-10 text-xs md:text-sm ml-2 py-2",
      onClick: () => appData.modelSetController(<NewWorkflowForm onResponse={() => onReponseHandler()} otherWorkflowNames={workflowData} />),
      id: "newTask",
    },
  ];

  const sendRequestWorkflow = () => {
    initStarted(0);

    axios
      .get(process.env.REACT_APP_BACKEND_URL + `/api/workflow/workflows/all`, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      .then((response) => {
        initCompleted(0);
        setWorkflowData(response.data);
      })
      .catch((err) => {
        return initErrors(err?.response?.data?.message ?? err?.message ?? "", 0);
      });

  };

  const sendRequestTeam = async () => {
    initStarted(1);

    axios
      .put(
        process.env.REACT_APP_BACKEND_URL + `/api/team/getByIdAndRole`,
        { roleTypes: [] },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        setTeamData(
          Object.values(response.data.teamData).map((team) => {
            return {
              type: "",
              name: `${team.firstName} ${team.lastName[0]}`,
              value: team._id.toString(),
            };
          })
        );
        initCompleted(1);
      })
      .catch((err) => {
        console.log(err);
        return initErrors(err?.response?.data?.message ?? err?.message ?? "", 0);
      });
  };

  const onReponseHandler = () => {
    appData.setTopLevelModal([]);
    sendRequestWorkflow();
    sendRequestTeam();
  };

  useEffect(() => {
    sendRequestWorkflow();
    sendRequestTeam();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ContentView
        buttons={buttons}
        label={"Workflow Templates"}
        // toolTip={<GuideDisplay url={`https://www.loom.com/embed/bcab84a4e6e64e1d95255dfee5c88ea0?sid=305fff90-a136-433b-8981-b2fdf6bfe450`} />}
      >
        <div className="h-full overflow-auto">
          {initState[0].started && (
            <div className=" my-4">
              <IconSpinner size="m" />
            </div>
          )}
          {/* {initState[0].error && <div className="text-center w-full text-taskinatorRed my-4 capitalize">{initState[0].error}</div>} */}
          {initState[0].finished &&
            workflowData &&
            teamData &&
            workflowData.workflows.map((data) => {
              return (
                <WorkflowContainer
                  key={data._id}
                  onEditHandler={() => setModal(data)}
                  workflowData={data}
                  teamData={teamData}
                  workflowOptions={workflowData.workflows}
                  onResponse={() => {
                    sendRequestWorkflow();
                    sendRequestTeam();
                  }}
                />
              );
            })}
        </div>
      </ContentView>
      {modal && (
        <Modal
          title="Create New Workflow"
          local={() => {
            setModal();
          }}
        >
          <NewWorkflowForm
            onResponse={() => {
              sendRequestWorkflow();
              sendRequestTeam();
              setModal();
            }}
            otherWorkflowNames={workflowData}
            defaultValue={modal}
          />
        </Modal>
      )}
    </>
  );
};

export default Workflow;
