import React, { useContext } from "react";
import IconSpinner from "../UI_elements/iconSpinner";
import { SortWithKeyword } from "../displayElements/requests/requestModal";
import ColumnItemElement_V2 from "./columnItemElement_V2";
import JobDashboardElement from "./jobDashboardElement";
import { AppContext } from "../../contexts/appContext";
import DateDisplay from "../UI_elements/dateDisplay";
import StringShortenDisplay from "../UI_elements/stringShortenDisplay";
import Tag from "../UI_elements/tag";
import TaskDisplay from "../UI_elements/taskDisplay";

const ColumnElement_V2 = ({
  primaryColour,
  faintColour,
  primaryBorder,
  label,
  jobData,
  jobs,
  priorityJob,
  priorityTasks,
  type,
  getData,
  upcomingTasks,
  textColour,
  initState,
  project,
  row,
  opacity,
  needsTimeZoneAvailability,
  stateNumber,
}) => {
  let totalElements = 0;

  [...(jobData ?? []), ...(jobs ?? [])].forEach((obj) => {
    if (obj.tracers && typeof obj.tracers === "object") {
      totalElements += Object.keys(obj.tracers).length;
    }

    if (obj.jobDetails && typeof obj.jobDetails === "object") {
      totalElements += 1;
    }
  });

  if (upcomingTasks) {
    totalElements = upcomingTasks.length;
  }

  if (priorityTasks) {
    totalElements = priorityTasks?.filter((task) => !task.completed).length;
  }

  return (
    <div className="flex flex-col h-full">
      <div className={`${primaryColour} shadow mt-0.5 px-4 py-3 rounded-lg flex items-center`}>
        <div className={`${textColour} font-semibold uppercase text-base`}>{label}</div>
        <div className={`text-base ${textColour} font-bold pl-2`}>({totalElements})</div>
       
      </div>
      <div className={"text-[0.65rem] text-center mt-0.5"}>Highest priority at top</div>
      <div className={`${faintColour} shadow mb-0.5 mt-0.5 rounded-lg px-1 py-1 grow  overflow-auto scrollbar flex ${row ? "md:flex-row flex-col" : "flex-col"}`}>
        {initState[stateNumber].started && !initState[stateNumber].initialised && (
          <div className="w-full items-center justify-center flex text-xs text-taskiantorMedGrey my-2">
            <IconSpinner />
          </div>
        )}
        {initState[stateNumber].error && <div className="w-full items-center justify-center flex text-xs text-taskinatorRed my-2">Error getting data</div>}
        {initState[stateNumber].initialised && !initState[stateNumber].error && jobData?.length === 0 && jobs?.length === 0 && priorityJob?.tasks?.length === 0 && (
          <div className="w-full items-center justify-center flex text-xs text-taskiantorMedGrey my-2">Nothing Here For You...</div>
        )}

        {initState[stateNumber].initialised && jobs?.length > 0
          ? jobs.map((job, index) => {
              {
                return (
                  <div key={job?._id ?? index} className="opacity-75">
                    <JobDashboardElement primaryColour={primaryColour} primaryBorder={primaryBorder} job={job} textColour={textColour} />
                  </div>
                );
              }
            })
          : jobs && <div className="w-full items-center justify-center flex text-xs text-taskiantorMedGrey my-2">Nothing Here For You...</div>}

        {initState[stateNumber].initialised &&
          jobData?.length > 0 &&
          SortWithKeyword(jobData, "_id").map((data) => {
            return (
              <div key={`${data.tid}-${data.actionName}-${data.workflowName}`} className="">
                <ColumnItemElement_V2 key={data._id} primaryColour={primaryColour} primaryBorder={primaryBorder} data={data} getData={getData} textColour={textColour} />
              </div>
            );
          })}

        {initState[stateNumber].initialised && !needsTimeZoneAvailability && priorityTasks?.length > 0
          ? priorityTasks.map((task) => {
            
          
              return (
                <div className="mb-1" key={task._id.toString()}>
                  <TaskDisplay task={task} jobData={priorityJob} goToJob bgOverWrite={"bg-taskinatorWhite"} primary />
                </div>
              );
            })
          : priorityTasks && <div className="w-full items-center justify-center flex text-xs text-taskiantorMedGrey my-2">Nothing Here For You...</div>}

        {needsTimeZoneAvailability && (
          <div className="p-2 text-base text-center text-taskinatorRed">Set Your timezone and availability in user settings to view priority tasks. Refresh page once set. </div>
        )}

        {initState[stateNumber].initialised && upcomingTasks?.length > 0
          ? upcomingTasks.map((task, index) => {
         
              return (
                <div key={task._id} className="mb-1">
                  <TaskDisplay task={task} jobData={task.jobData} goToJob bgOverWrite={"bg-taskinatorWhite"} />
                </div>
              );
            })
          : upcomingTasks && <div className="w-full items-center justify-center flex text-xs text-taskiantorMedGrey my-2">Nothing Here For You...</div>}
      </div>
    </div>
  );
};

export default ColumnElement_V2;
