import React from "react";

const AppUsage = ({ orgStats, plan, isCPT }) => {
  return (
    <div>
      <div className="font-semibold text-sm">Usage</div>
      {orgStats && (
        <div className="pt-2 text-sm border-t border-taskinatorMedGrey mt-2">
          <div className="flex">
            <div className="text-taskinatorDarkGrey font-medium pr-1">Jobs Running:</div>
            <div>{orgStats.jobsRunning}</div>
          </div>
          {/* <div className="flex ">
            <div className="text-taskinatorDarkGrey font-medium pr-1">Jobs Closed:</div>
            <div>{orgStats.jobsClosed}</div>
          </div> */}
          <div className="flex">
            <div className="text-taskinatorDarkGrey font-medium pr-1">Jobs Completed:</div>
            <div>{orgStats.jobsCompleted}</div>
          </div>
          {/* <div className="flex">
            <div className="text-taskinatorDarkGrey font-medium pr-1">Tasks Active</div>
            <div>{orgStats.activeTasks}</div>
          </div> */}
          {/* <div className="flex">
            <div className="text-taskinatorDarkGrey font-medium pr-1">Workflows Active:</div>
            <div>{orgStats.activeWorkflows} </div>
          </div> */}
          <div className="flex">
            <div className="text-taskinatorDarkGrey font-medium pr-1">Team:</div>
            <div>
              {orgStats.team} out of ({orgStats.teamLimit})
            </div>
          </div>
          {/* <div className="flex">
            <div className="text-taskinatorDarkGrey font-medium pr-1">Guests:</div>
            <div>
              {orgStats.guests} out of ({orgStats.teamLimit})
            </div>
          </div> */}
          <div className="flex">
            <div className="text-taskinatorDarkGrey font-medium pr-1">File Sets:</div>
            <div>{orgStats.fileSets}</div>
          </div>
          <div className="flex">
            <div className="text-taskinatorDarkGrey font-medium pr-1">Files:</div>
            <div>{orgStats.files}</div>
          </div>
          {isCPT && (
            <div className="flex">
              <div className="text-taskinatorDarkGrey font-medium pr-1">Drawings:</div>
              <div>{orgStats.items}</div>
            </div>
          )}
          <div className="flex">
            <div className="text-taskinatorDarkGrey font-medium pr-1">Storage (AWS):</div>
            <div>
              {orgStats.currentStorage} GB out of {plan.storageLimit / 1000} GB (
              {parseFloat(((orgStats?.currentStorage > 0 ? orgStats.currentStorage : 0 / (plan.storageLimit / 1000)) * 100).toFixed(3))}%)
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AppUsage;
