import React, { useState } from "react";

import axios from "axios";
import { useInit } from "../../hooks/useInit";
import Button from "../../UI_elements/button";

const TaskOnHold = ({ activator, taskDetails, tracer, getData }) => {
  const { initState, initStarted, initCompleted, initErrors } = useInit();

  const isOnHold = taskDetails?.onHold;
  // const [onHold, setOnHold] = useState(isOnHold ? isOnHold : false);

  const onSubmitHandler = () => {
    initStarted(0);
    if (taskDetails && tracer) {
      axios
        .put(
          process.env.REACT_APP_BACKEND_URL + `/api/task/onHold/${taskDetails.tid}/${tracer.tracerWorkflowGroupId}`,
          { onHold: !isOnHold },
          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        )
        .then((response) => {
          // setOnHold(response.data.onHold);
          // getData();
          initCompleted(0);
        })
        .catch((err) => {
          console.log(err);
          const errorMessage = err?.response?.data?.message ?? err?.message ?? "";
          initErrors(errorMessage, 0);
        });
    }
  };

  if (activator) {
    return (
      <Button
        onClick={() => onSubmitHandler()}
        label={isOnHold ? "Reactivate Job" : "Put Job On Hold"}
        customClasses={` ${
          isOnHold ? "border-taskinatorGreen bg-taskinatorGreen text-taskinatorWhite" : "opacity-40 border-taskinatorRed text-taskinatorRed  hover:bg-taskinatorRed hover:text-taskinatorWhite "
        }
uppercase h-8 flex items-center justify-center text-xs  px-2 border rounded ml-2  `}
        isLoading={initState[0].started}
      />
    );
  } else {
    return (
      isOnHold && (
        <div className="uppercase mr-2 px-2 rounded text-base border border-taskinatorRed  text-taskinatorWhite bg-taskinatorRed hover:text-taskinatorWhite h-8 animate-pulse flex items-center justify-center ">
          On Hold
        </div>
      )
    );
  }
};

export default TaskOnHold ;
