import React, { useContext, useState, useEffect } from "react";
import { AppContext } from "../../contexts/appContext";
import Tag from "./tag";
import DateDisplay from "./dateDisplay";
import StringShortenDisplay from "./stringShortenDisplay";
import FilePreview from "../../utils/filePreview";
import NewInstruction from "../forms/newInstruction";
import Modal from "./modal";
import UserDisplayBubble from "./userDisplayBubble";
import CheckboxV4 from "../inputs/checkbox_V4";
import axios from "axios";
import Button from "./button";
import CountUpTimer from "../../utils/countUpTimer";
import TaskCommentDisplay from "../displayElements/task/taskCommentDisplay";
import { useInit } from "../hooks/useInit";
import { MdCheck, MdCheckBox, MdCheckCircle } from "react-icons/md";
import DurationSelector from "../dataSelectors/durationSelector";
import LinkifyText from "../../utils/linkifyText";

const TaskDisplay = ({ task, jobData, limit, goToJob, horizontal, bgOverWrite, taskSelected, onClick, index, setSelectedTaskIds, selectedTaskIds, dropdown }) => {
  const appData = useContext(AppContext);
  const [currentTaskState, setCurrentTaskState] = useState(task.completed);
  const [viewDetails, setViewDetails] = useState();
  const fileSetDetails = task?.fileSetDetails;
  const primaryTaskDetails = task?.primaryTaskDetails;
  const workflowData = task?.workflowData;
  const [comments, setComments] = useState(task.comments);
  const { initState, initStarted, initCompleted, initErrors, initReset } = useInit(4);
  const [hover, setHover] = useState(false); // State to manage hover status
  const [viewLocalDetails, setViewLocalDetails] = useState(selectedTaskIds?.[task._id] ? true : false);
  const isNew = !task?.seen.find((seen) => seen._id === appData.workspaceDataV4.user_id);

  const selectionData = {
    files: task?.submittedFiles?.map((file) => {
      return { revisionTrackingId: file?.revisionTrackingId, submissionId: file?.submissionId };
    }),
    workflowId: task.workflowData?._id?.toString(),
    primaryTask: task.primaryTaskDetails?._id?.toString(),
  };

  const selectionHandler = (checked) => {
    setSelectedTaskIds((currentSelection) => {
      if (currentSelection[task._id]) {
        const newSelection = { ...currentSelection };
        delete newSelection[task._id];
        setViewLocalDetails(false);
        return newSelection;
      } else {
        setViewLocalDetails(true);
        if (checked) {
          return { ...currentSelection, [task._id]: selectionData };
        } else {
          return { [task._id]: selectionData };
        }
      }
    });
  };

  useEffect(() => {
    if (currentTaskState !== task.completed) {
      setViewLocalDetails(false);
      if (selectedTaskIds?.[task._id]) {
        setSelectedTaskIds({});
      }
    }
  }, [task.completed]);

  const isSelected = selectedTaskIds?.[task._id] ? true : false;
  const selectButNotThisOne = !isSelected && Object.values(selectedTaskIds ?? {}).length > 0;

  useEffect(() => {
    if (isNew && !task.closed && isSelected) {
      axios
        .post(
          process.env.REACT_APP_BACKEND_URL + `/api/job/task/seen/${task?._id}/${jobData._id}`,
          {},
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "token ",
            },
            withCredentials: true,
          }
        )
        .then((response) => {
          // setSeen((current) => [...current, { _id: appData.workspaceDataV4.user_id, firstName: appData.workspaceDataV4.firstName, lastName: appData.workspaceDataV4.lastName }]);
        })
        .catch((err) => {
          console.log(err);
          const errorMessage = err?.response?.data?.message ?? err?.message ?? "";
        });
    }
  }, [isSelected]);

  
  const CompleteHandler = () => {
    initStarted(1);
    axios
      .get(process.env.REACT_APP_BACKEND_URL + `/api/job/task/completed/${task._id}/${jobData._id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "token ",
        },
        withCredentials: true,
      })
      .then((response) => {})
      .catch((err) => {
        console.log(err);
        const errorMessage = err?.response?.data?.message ?? err?.message ?? "";
        return initErrors(errorMessage, 1);
      });
  };

  useEffect(() => {
    initCompleted(1);
  }, [task.completed]);

  return (
    <>
      {/* {!goToJob && isNew && !task.completed && !selectButNotThisOne && (
        <div className="flex grow justify-start h-0 pt-2 z-40">
          <div className="relative -top-2 ml-2 items-center flex h-4 w-fit text-taskinatorWhite text-[0.75rem] bg-taskinatorRed px-1 rounded-full font-semibold">New</div>
        </div>
      )} */}
      <div
        key={task._id}
        className={`border w-full items-start text-taskinatorDarkGrey ${
          task.completed ? "bg-taskinatorLightGrey text-taskinatorMedGrey" : isNew ? "bg-taskinatorGreenHover" : "bg-taskinatorWhite"
        } border-taskinatorGreen text-taskinatorDarkGrey p-2 rounded  hover:ring-taskinatorGreen  text-sm ${isSelected ? "" : selectButNotThisOne ? "hidden hover:ring-1 cursor-pointer" : "block hover:ring-1 cursor-pointer"}`}
        onClick={
          goToJob
            ? () =>
                appData.setJobViewHandler({
                  jobId: jobData.jobId,
                  jobData: appData.jobs[jobData.jobId],
                  taskId: task._id,
                })
            : isSelected ? ()=>{} : () => selectionHandler(!viewLocalDetails)
        }
      >
        <div className="flex  ">
        {task.completed && <div className={`text-2xl text-taskinatorGreen grow flex -ml-1 pr-2  ${isSelected ? "items-start pt-6" : "items-center " } justify-center`} ><MdCheckCircle /></div>}
          <div className="flex flex-col w-full  ">
            
            <div className={`${horizontal ? "flex items-center " : "flex flex-col h-fit "}`}>
              
              <div className="flex items-center flex-wrap">
                {/* {task.completed && <div className={`${task.completed ? "text-taskinatorGreen" : "text-taskinatorGreen"} pr-2`}>Completed</div>} */}
                {/* {!task.completed && !goToJob && (
                  <div className="border-r border-r-taskinatorMedGrey mr-2 " onClick={(e) => e.stopPropagation()}>
                    <CheckboxV4 onInput={() => {}} customCheckbox={"h-5 w-5"} />
                  </div>
                )} */}
               
                <b className={` text-sm uppercase  font-medium text-nowrap`}> {task.title}</b>
                <div className="flex items-center text-nowrap   pl-2   ">
                  {/* Created: */}
                  <div className="pl-1 "></div> <CountUpTimer startDate={task?.createdDate} /> <div className="pl-1 ">ago</div>
                </div>

                {!goToJob && (
                  <div className="flex text-nowrap items-center   pl-2  ">
                    Assignee:{" "}
                    <div className={`items-center flex ${task.completed ? "opacity-50" : ""} `}>
                      {task.assignee.map((user) => {
                        return <div key={user._id}><UserDisplayBubble user={user} /></div>;
                      })}
                    </div>{" "}
                  </div>
                )}
              </div>

              <div className="text-xxs flex font-normal">
                Seen By:{" "}
                {task.seen.map((user) => {
                  return (
                    <div key={user._id} className="capitalize ml-1 font-medium">
                      {user.firstName} {user.lastName[0]}
                    </div>
                  );
                })}
              </div>

              {!horizontal && <div></div>}
             {!task.completed &&  <div className={`${horizontal ? "ml-4" : "mt-2 pt-2 border-t border-t-taskinatorMedGrey"}  flex flex-wrap text-xs `}>
                <div className="flex items-center text-nowrap  mt-1 ">
                  Priority:
                  <div className="pl-1 mr-2">
                    <Tag tagData={{ name: task.priority, type: "priority" }} />
                  </div>
                </div>
                <div className="flex items-center text-nowrap mt-1">
                  Due:
                  <div className="font-normal pl-1 mr-2">{DateDisplay(task.dueDate)}</div>
                </div>
                <div className="flex items-center text-nowrap  mt-1">
                  Duration:
                  <div className="font-normal pl-1 mr-2">
                    {task.duration > 0 ? `${Math.round(parseFloat(task.duration))} Hr` : <div className=" text-taskinatorMedGrey font-medium rounded ">Needs Setting</div>}
                  </div>
                </div>
              </div>}
              {/* {!task.completed && <DurationSelector onInput={() => {}} id={task._id} label="Time Completed" defaultValue={task.timeCompleted} validationRequired={false} />} */}
              {!isSelected && (
                <div className={`${!horizontal ? " mt-1 border-t border-t-taskinatorMedGrey pt-1 items-center" : "justify-end items-center flex h-full "} mt-1 flex grow  text-xs`}>
                  <div className="flex flex-wrap items-center text-taskinatorMedGrey ">
                    {primaryTaskDetails && <div className="font-medium border border-taskinatorMedGrey px-1 mx-1 rounded  uppercase">{primaryTaskDetails.taskDetails.name}</div>}
                    {primaryTaskDetails && <div className="px-1 text-nowrap my-1">in</div>}
                    {primaryTaskDetails && <div className="font-medium border border-taskinatorMedGrey  px-1 mx-1 rounded uppercase ">{workflowData.name}</div>}
                    {fileSetDetails && <div className="px-1 text-nowrap my-1">for folder</div>}
                    {fileSetDetails && (
                      <div className="uppercase rounded-lg px-1 flex text-nowrap  my-1 font-medium border border-taskinatorMedGrey ">
                        {fileSetDetails.number} - {fileSetDetails.name}
                      </div>
                    )}
                    {goToJob && (
                      <div className="flex text-nowrap pl-1 my-1">
                        in Job: <div className="font-medium border border-taskinatorMedGrey  px-1 mx-1 rounded ">{jobData.jobRef}</div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>

            {isSelected && (
              <div
                className={`flex flex-col   grow h-full ${
                  horizontal
                    ? "w-full mt-2 rounded border border-taskinatorMedGrey  grow bg-taskinatorLightGrey p-4 flex-col "
                    : "flex-col mt-8 pt-2 border-t border-t-taskinatorMedGrey justify-center"
                } font-medium`}
              >
                
                <div>Description:</div>
                <div className={`${horizontal ? "" : ""} font-normal text-pretty whitespace-pre `}>{task.details.length === 0 ? <div className="text-xs text-taskinatorMedGrey">No details added...</div> : <LinkifyText text={task.details} /> }</div>
                <div className="mt-2">Attachments:</div>
                <div className="flex">
                  {task.attachments.length > 0 ? (
                    task.attachments.map((attachment) => {
                      return (
                        <div className="mr-1" key={attachment.key}>
                          <FilePreview file={attachment} typeOverride="task" showName viewMarkupsOnly small />
                        </div>
                      );
                    })
                  ) : (
                    <div className="font-normal text-xs text-taskinatorMedGrey">No Attachments</div>
                  )}
                </div>
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  className="h-full pt-4 bg-taskinatorOffWhite border border-taskinatorLightGrey mt-2 p-2 rounded-lg "
                >
                  <TaskCommentDisplay setComments={setComments} comments={comments} isCompleted={task.completed} taskId={task._id} jobId={jobData._id.toString()} />
                </div>

                <div className="mt-4" onClick={(e) => e.stopPropagation()}>
                  {task.completed ? (
                    <Button
                      label={
                        hover ? (
                          "Undo"
                        ) : (
                          <div className="flex items-center">
                            {" "}
                            <MdCheck /> Completed
                          </div>
                        )
                      }
                      customClasses={`${
                        hover ? "text-taskinatorRed border-taskinatorRed" : "text-taskinatorGreen border-taskinatorGreen"
                      } border flex items-center font-bold rounded   h-10 px-2 cursor-pointer h-10 w-full justify-center`}
                      onMouseEnter={() => setHover(true)} // Set hover to true when mouse enters
                      onMouseLeave={() => setHover(false)} // Set hover to false when mouse leaves
                      onClick={() => CompleteHandler()}
                      isLoading={initState[1].started}
                      error={initState[1].error}
                    />
                  ) : (
                    <Button
                      onClick={() => CompleteHandler()}
                      isLoading={initState[1].started}
                      error={initState[1].error}
                      label="Mark As Completed"
                      customClasses={`bg-taskinatorGreen text-taskinatorWhite h-10 w-full hover:opacity-75 `}
                    />
                  )}
                </div>

                <div>
                  {!goToJob && isSelected && !task.completed && (
                    <div onClick={(e) => e.stopPropagation()} className=" mt-1">
                      <Button
                        label="Edit"
                        customClasses={"border-taskinatorMedGrey border py-1 w-full hover:bg-taskinatorMedGrey hover:text-taskinatorBlack text-taskinatorDarkGrey hover:opacity-75"}
                        onClick={() => setViewDetails(true)}
                      />
                    </div>
                  )}
                </div>
                <div onClick={()=>selectionHandler(!viewLocalDetails)} className="text-taskinatorRed text-center pt-4 hover:font-bold cursor-pointer">Minimise</div>
              </div>
            )}
          </div>
        </div>
      </div>
      {viewDetails && (
        <NewInstruction
          defaultValues={[task]}
          defaultAsForm={() => {
            setViewDetails();
          }}
          inJob
        />
      )}
    </>
  );
};

export default TaskDisplay;
