import React, { useState, useContext } from "react";

import axios from "axios";
import { AppContext } from "../../contexts/appContext";
import { useInit } from "../hooks/useInit";

import { useInputV4 } from "../../stateManagement/useInputV4";
import { VALIDATOR_REQUIRE, VALIDATOR_MINLENGTH } from "./validators/validators";
import InputV4 from "../inputs/input_V4";
import Button from "../UI_elements/button";

import PDFAttachV4 from "../inputs/filePDFAttach_V4";
import StampingFunction from "../functions/stampingFunction";
import ItemStatus from "../UI_elements/itemStatus";
import { TaskContext } from "../../contexts/taskContext";

const CreateDraftItemForm = ({ itemData, tracerId, iid, onSuccess, aid, state }) => {
  const { inputState, inputHandler, attachmentHandler } = useInputV4({ inputs: {}, attachments: {} }, false);
  const { initState, initStarted, initCompleted, initErrors, initReset } = useInit();
  const [percentage, setPercentage] = useState(0);
  const appData = useContext(AppContext);

  const documentsAttached = inputState.attachments && Object.values(inputState.attachments).length > 0 && !inputState.attachments.required;

  const createDraftHandler = async () => {
    initStarted(0);
    let QRcodeUUID;
    let attachmentKey;
    let attachmentFileName;

    try {
      if (documentsAttached) {
        const stampDetails = await StampingFunction(
          itemData.currentItemDetails.itemNumber,
          inputState.inputs.revision.value,
          inputState.attachments,
          state,
          setPercentage,
          appData.workspaceDataV4.orgName,
          appData.workspaceDataV4.addStamp
        );

        attachmentKey = stampDetails.attachmentKey;
        attachmentFileName = stampDetails.attachmentFileName;
        QRcodeUUID = stampDetails.QRcodeUUID;
      }

      await submitHandler(QRcodeUUID, attachmentKey, attachmentFileName);

      onSuccess();
      initCompleted(0);
    } catch (err) {
      console.log(err);
      initErrors(err.message, 0);
    }
  };

  const submitHandler = async (QRcodeUUID, attachmentKey, attachmentFileName) => {
    let putData = {
      revision: inputState.inputs.revision.value,
      changeReason:
        itemData && state !== "released"
          ? state === "markup"
            ? `Markup of revision: ${itemData.released?._id ? itemData.released.revisions : itemData.draft.revision}`
            : inputState.inputs.changeReason.value
          : state !== "released"
          ? "First Draft"
          : "New Approved Version",
      releasedID: itemData.released?._id ? itemData.released._id : null,
      draftID: itemData.draft?._id ? itemData.draft._id : null,
      markupIDs: itemData.markups?._id ? itemData.markups : null,
      state: state,
    };

    if (attachmentKey) {
      putData = { ...putData, attachments: { key: attachmentKey, fileName: attachmentFileName }, QRuuid: QRcodeUUID };
    }

    try {
      await axios.put(process.env.REACT_APP_BACKEND_URL + `/api/items/new/${state}/${iid}/${tracerId}/${aid}`, putData, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      });

      return;
    } catch (err) {
      console.log(err)
      throw { success: false, message: `Error created ${state}` };
    }
  };

  return (
    <div className="md:w-96 w-full items-center h-full p-4 ">
      {state !== "markup" && itemData[state]?._id && (
        <div className=" flex items-center text-sm border-b border-b-taskinatorMedGrey border-b-3 pb-2 mb-4 text-center justify-center">
          <div className="text-taskinatorRed">That will supersede:</div>

          <div className=" flex  ">
            <div className="px-2">
              <ItemStatus status={itemData[state].status} />
            </div>
            <div>Revision:</div>
            <div className="flex pl-1 font-semibold text-taskinatorDarkGrey">{itemData[state].revision}</div>
          </div>
        </div>
      )}

      {state !== "markup" && !itemData[state]?._id && (
        <div className="pl-2">
          <div> {`This will be the first ${state}.`} </div>
        </div>
      )}

      <div className={`${state === "markup" ? "hidden" : ""}`}>
        <InputV4
          sid="inputs"
          id="revision"
          label={`New Revision`}
          placeholder={"Enter new revision..."}
          customClasses={"rounded-md"}
          defaultValue={state === "markup" ? `MarkUp - ${appData.workspaceDataV4.firstName} ${appData.workspaceDataV4.lastName[0]}` : ""}
          onInput={inputHandler}
          validators={[VALIDATOR_REQUIRE()]}
          validityMessage={"Required"}
        />
      </div>  

      <div className="my-2">
        {itemData && !["released", "markup"].includes(state) && (
          <InputV4
            sid="inputs"
            id="changeReason"
            textareaRows={3}
            label="Reason For Update"
            placeholder={"Detail reason for update"}
            customClasses={"rounded-md"}
            onInput={inputHandler}
            validators={[VALIDATOR_MINLENGTH(5)]}
            validityMessage={"Required at least 5 characters"}
          />
        )}
      </div>

      <div className="my-2">
        <PDFAttachV4 required={["released", "markup"].includes(state) ? true : itemData.draft ? true : false} label={`${state} Documents - PDF only`} attachmentHandler={attachmentHandler} />
      </div>
      <div className="text-xs text-center whitespace-normal flex items-center justify-center  text-taskinatorRed pb-4 w-full">Multiple attachments will be merged to a single pdf.</div>

      <Button
        onClick={createDraftHandler}
        label={`Create ${state} Version`}
        customClasses={`${
          inputState.isValid ? `${state === "released" ? "bg-taskinatorGreen" : "bg-taskinatorBlue"}` : "bg-taskinatorMedGrey"
        } bg-taskinatorBlue text-taskinatorWhite h-10 w-full mt-4 capitalize `}
        disabled={!inputState.isValid}
        isLoading={initState[0].started}
        loadingPercentage={documentsAttached ? percentage : null}
        error={initState[0].error}
      />
    </div>
  );
};

export default CreateDraftItemForm;
