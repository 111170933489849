import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Navigate, Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./auth/authConfig";
import { GoogleOAuthProvider } from "@react-oauth/google";

const root = ReactDOM.createRoot(document.getElementById("root"));

const msalInstance = new PublicClientApplication(msalConfig);

const app = (
  <MsalProvider instance={msalInstance}>
    <GoogleOAuthProvider clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}>
      <Router>
        <App />
      </Router>
    </GoogleOAuthProvider>
  </MsalProvider>
);

// if (process.env.NODE_ENV !== 'production' || process?.versions?.electron) {
//   root.render(app);
// } else {
root.render(<React.StrictMode>{app}</React.StrictMode>);
// }

reportWebVitals();
