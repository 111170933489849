import React, { useState } from "react";
import Modal from "../../UI_elements/modal";
import RequestDisplay from "./requestDisplay";
import RequestModal from "./requestModal";

const ViewRequest = ({ request, group, getData }) => {
  const [modal, setModal] = useState();

  return (
    <>
      <RequestDisplay
        key={request._id}
        group={group}
        request={request}
        onClick={() => {
          setModal(true);
        }}
      />

      {modal && (
        <Modal
          title="Critical Question"
          size="medium"
          bgColour={"bg-taskinatorLightGrey"}
          local={() => setModal()}
        >
          <RequestModal
            request={request}
            group={group}
            closeModal={() => setModal()}
            getData={getData}
          />
        </Modal>
      )}
    </>
  );
};

export default ViewRequest;
