import React, { useContext, useState } from "react";
import { AppContext } from "../../contexts/appContext";
import StringShortenDisplay from "../UI_elements/stringShortenDisplay";
import ItemStatus from "../UI_elements/itemStatus";
import ElementCard from "./elementCard";
import Modal from "../UI_elements/modal";
import ActionDetailDisplayV2 from "../displayElements/actionDisplay/actionDetailDisplayV2";
import { useNavigate } from "react-router-dom";
import AcknowledgeNotificationButton from "../displayElements/notification/acknowledgeNotificationButton";

const ColumnNotificationElement = ({ primaryBorder, data, jobData, type, getData, textColour }) => {
  const appData = useContext(AppContext);
  const isNew = data.seen && data.seen.filter((seen) => seen._id === appData.workspaceDataV4.user_id).length === 0;
  const [modal, setModal] = useState();
  const item = data.itemsOnTracer;

  return (
    <>
      <ElementCard
        topContent={
          <div className="flex capitalize text-xs items-end font-semibold">
            <div className={`py-1 px-2 rounded uppercase border ${data.archived ? "border-taskinatorMedGrey text-taskinatorMedGrey" : "border-taskinatorRed text-taskinatorRed  "}`}>{data.type}</div>
          </div>
        }
        bottomContent={
          !data.noAcknowledgeMentRequired ? (
            <div className="flex items-center grow">
              {data.itemNumber && data.itemName ? (
                <div className={` text-xs flex items-center text-taskinatorMedGrey `}>
                  <div className=" font-semibold uppercase ">{data.itemNumber}</div>
                  <div className={`px-1 `}>-</div>
                  <div className=" font-semibold uppercase pr-2">{StringShortenDisplay(8, data.itemName)}</div>
                </div>
              ) : (
                <div className={`font-semibold  opacity-50   text-xs uppercase`}>{`No ${appData.itemName}`}</div>
              )}{" "}
              <div className={`pr-2 ${data.archived ? "text-taskinatorMedGrey" : "text-taskinatorDarkGrey"}`}>|</div>
              <div className={` font-semibold uppercase pr-2 ${data.archived ? "text-taskinatorMedGrey" : "text-taskinatorDarkGrey"}  w-[6rem] overflow-hidden whitespace-nowrap`}>
                {StringShortenDisplay(30, data.comments)}
              </div>
              <div
                className="flex grow justify-end "
                onClick={(event) => {
                  event.stopPropagation();
                }}
              >
                {!data.archived && <AcknowledgeNotificationButton logId={data.logId} tracerWorkflowGroupId={data.tracerWorkflowGroupId} getNotifications={() => getData()} />}
              </div>
            </div>
          ) : (
            <div className="flex items-center grow">
              Job: <div className="font-medium pl-1 uppercase ">{data.ref}</div>
            </div>
          )
        }
        isNew={isNew}
        primaryBorder={data.archived ? "border-taskinatorMedGrey" : primaryBorder}
        createdDate={data.createdDate}
        done={data.archivedDate}
        onClick={() => appData.setActionViewHandler({ tracerId: null, tid: jobData.tid, getData: () => getData() })}
      />

      {/* {modal && (
        <Modal
          size="max"
          bgColour="bg-taskinatorLightGrey "
          title={"Action"}
          local={() => {
            setModal();
          }}
        >
          <ActionDetailDisplayV2
            type={"mine"}
            aidDetails={data.aid}
            item={item}
            tracer={data}
            onSubmitSuccess={() => {
              getData();
              setModal();
            }}
            taskDetails={jobData}
            tid={jobData.tid}
            getData={getData}
            // onStartHandler={onStartHandler}
            // startHandlerError={startHandlerError}
            // startHandlerLoading={startHandlerLoading}
          />
        </Modal>
      )} */}
    </>

    // <div className="hover:scale-95">
    //   {isNew && (
    //     <div className="flex grow  justify-end h-0 pt-3 z-40">
    //       <div className="relative -top-2 mr-2 items-center flex h-4 w-fit text-taskinatorWhite text-[0.75rem] bg-taskinatorRed  px-2 rounded-full font-semibold">New</div>
    //     </div>
    //   )}
    //   <div className={`flex flex-col grow bg-taskinatorWhite shadow border-l-4 ${primaryBorder} mt-1  cursor-pointer`}>
    //     <div className="flex grow border-b border-b-taskinatorMedGrey p-2">
    //       <div className="flex capitalize text-base">
    //         <div className="text-taskinatorGreen font-semibold ">{StringShortenDisplay(20, data.aid.name)}</div>
    //         <div className="text-taskinatorMedGrey pl-1 ">({StringShortenDisplay(10, data.wfid.name)})</div>
    //       </div>
    // \
    //     </div>

    //     {item ? (
    //       <div className={`flex  items-center px-2 text-sm  m-0.5 rounded h-10`}>
    //         <div className=" font-semibold uppercase ">{item.currentItemDetails.itemNumber}</div>
    //         <div className="px-1">-</div>
    //         <div className=" font-semibold uppercase pr-2">{StringShortenDisplay(10, item.currentItemDetails.itemName)}</div>
    //         <div className=" pr-2">|</div>
    //         <div className="pr-2">{item.draft ? <ItemStatus status={item.draft.status} /> : item.released ? <ItemStatus status={item.released.status} /> : "No Release"}</div>
    //         <div className="pr-2 ">Rev: </div>
    //         <div className=" font-semibold uppercase pr-2 ">{item.draft ? item.draft.revision : item.released ? item.released.revision : ""}</div>
    //       </div>
    //     ) : (
    //       <div className={`font-semibold  opacity-50 px-2 items-center flex text-xs uppercase h-10`}>{`No ${appData.itemName}`}</div>
    //     )}
    //   </div>
    // </div>
  );
};

export default ColumnNotificationElement;
