import axios from "axios";
import React, { useEffect, useState } from "react";
import IconSpinner from "../UI_elements/iconSpinner";
import { useInit } from "../hooks/useInit";
import DropDownSelectorV4 from "../inputs/dropDownSelector_V4";
import MultiSelectDropdown from "../inputs/multiSelectDropdown";

const MemberSelector = ({
  inputVer,
  inputHandler,
  defaultValue,
  sid,
  disabled,
  selectionDisplayText,
  label,
  id,
  searchPlaceHolder,
  multi,
  roleTypes,
  teamIds,
  notRequired,
  modal,
  inline,
  excludeList,
  AddButtonText,
  dontAutoSelect,
  thirdParty,
  toolTip
}) => {
  const { initStarted, initCompleted, initErrors } = useInit();
  const [team, setTeam] = useState();

  useEffect(() => {
    const sendRequest = () => {
      const datatoSend = thirdParty
        ? { excludeLocked: true }
        : {
            teamIds: teamIds,
            roleTypes: roleTypes,
            excludeList: excludeList ? excludeList : [],
          };

      initStarted(0);
      axios
        .put(
          process.env.REACT_APP_BACKEND_URL +
            `/api/${thirdParty ? "thirdparty/getall" : "team/getByIdAndRole"}`,
          datatoSend,
          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        )
        .then((response) => {
          setTeam(
            Object.values(response.data.teamData).map((team) => {
              return {
                type: "team",
                name: team?.firstName && team?.lastName ? `${team.firstName} ${team.lastName[0]}` : team.email,
                value: team._id.toString(),
                data: team,
              };
            })
          );
          // setDefaults(defaultValue);
          initCompleted(0);
        })
        .catch((err) => {
          console.log(err);
          return initErrors(
            err?.response?.data?.message ?? err?.message ?? "",
            0
          );
        });
    };

    sendRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);


 
  if (team && defaultValue) {
    if (multi) {
      return (
        <MultiSelectDropdown
          color={
            "border-taskinatorMedGrey text-taskinatorDarkGrey font-semibold"
          }
          selectionColor={"text-taskinatorBlue uppercase"}
          label={label}
          id={id}
          searchPlaceHolder={searchPlaceHolder}
          type="SEARCH"
          sid={sid}
          defaultValue={defaultValue}
          options={team}
          onSelection={inputHandler}
          disabled={disabled}
          selectionDisplayText={selectionDisplayText}
          notRequired={notRequired ? notRequired : false}
          inputVer={inputVer}
          modal={modal}
          inline={inline}
          AddButtonText={AddButtonText}
          dontAutoSelect={dontAutoSelect}
          toolTip={toolTip}
        />
      );
    } else {
      return (
        <DropDownSelectorV4
          onSelection={inputHandler}
          label={label}
          options={team}
          sid={sid}
          id={id}
          defaultValue={defaultValue}
          selectionDisplayText={selectionDisplayText}
          disabled={disabled}
          toolTip={toolTip}
        />
      );
    }
  } else {
    return (
      <div>
        <div className="text-sm h-5">{label}</div>
        <div className="flex text-xs w-full items-center border h-8 rounded pl-2 my-2 border-taskinatorMedGrey">
          <IconSpinner />
          <div className="capitalize ml-2">{`Loading Team`}</div>
        </div>
      </div>
    );
  }
};

export default MemberSelector;
