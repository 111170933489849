import React from "react";

const LinkifyText = ({ text }) => {
  
  const linkify = (inputText) => {
    const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi;
    return inputText.replace(urlRegex, (url) => {
      return `<a href="${url}" target="_blank" rel="noopener noreferrer"  style="text-decoration: underline;">${url}</a>`;
    });
  };

  const createMarkup = (text) => {
    return { __html: linkify(text) };
  };

  return <div dangerouslySetInnerHTML={createMarkup(text)} />;
};

export default LinkifyText;
