import React, { useState, useEffect } from "react";
import axios from "axios";
import { useInit } from "../../hooks/useInit";
import IconSpinner from "../../UI_elements/iconSpinner";
import Button from "../../UI_elements/button";
import Modal from "../../UI_elements/modal";
import Tag from "../../UI_elements/tag";

const AddJobsButton = ({ onSuccess, pid, pref }) => {
  const { initState, initStarted, initCompleted, initErrors } = useInit(2);
  const [getJobs, setGetJobs] = useState();
  const [availableJobs, setAvailableJobs] = useState();
  const [selectedJobs, setSelectedJobs] = useState({});


  const getAvailableJobs = ()=> {
    initStarted(0);

    axios
      .get(process.env.REACT_APP_BACKEND_URL + `/api/project/availablejobs`, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      .then((response) => {
     
        setAvailableJobs(response.data.jobs);
        initCompleted(0);
      })
      .catch((err) => {
        console.log(err);
        const errorMessage = err?.response?.data?.message ?? err?.message ?? '';
        initErrors(errorMessage, 0);
      });
  }


  useEffect(() => {
    getAvailableJobs ()
  }, []);

 

  const addJobHandler = () => {
    initStarted(1);
    axios
      .put(
        process.env.REACT_APP_BACKEND_URL + `/api/project/add/jobs/${pid}/${pref}`,
        {tids: Object.values(selectedJobs)},
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        onSuccess();
        getAvailableJobs ()
        setGetJobs()
        initCompleted(1);
      })
      .catch((err) => {
        console.log(err);
        const errorMessage = err?.response?.data?.message ?? err?.message ?? '';
        initErrors(errorMessage, 1);
      });
  };

  const selectJobHandler = (job) => {
    if (selectedJobs[job._id]) {
      const currentLocal = selectedJobs;
      delete currentLocal[job._id];
      setSelectedJobs({ ...currentLocal });
    } else {
      setSelectedJobs({ ...selectedJobs, [job._id]: job._id });
    }
  };

  const isValid = Object.values(selectedJobs).length > 0;

  return (
    <>
      <div>
        <Button
          onClick={() => setGetJobs(true)}
          label="Add Existing"
          customClasses={"border-taskinatorBlue bg-taskinatorBlue   text-taskinatorWhite uppercase items-center text-xs h-8 px-2 border rounded"}
        />
      </div>

      {getJobs && (
        <Modal local={() => setGetJobs()} title="Add Existing Job">
          <div className="w-full md:w-96 flex flex-col overflow-auto max-h-96 mt-2">
            {availableJobs && initState[0].initialised && (
              <>
                <div className="border border-taskinatorMedGrey rounded-lg bg-taskinatorLightGrey ">
                  <div className="flex flex-col max-h-[12rem] overflow-auto rounded-lg scrollbar">
                    {availableJobs.length === 0  && initState[0].initialised && <div className="px-2 h-10 flex items-center justify-center text-taskinatorRed">No Available Jobs</div>}
                    {availableJobs.map((job, index) => {
                      return (
                        <div
                          key={job._id}
                          className={`${index === 0 ? "" : "border-t border-t-taskinatorMedGrey"} bg-clip-border flex  ${
                            selectedJobs[job._id] ? "bg-taskinatorBlue text-taskinatorWhite" : "hover:bg-taskinatorHoverBlue bg-taskinatorWhite"
                          } cursor-pointer   px-2 h-10 items-center  text-sm capitalize`}
                          onClick={() => selectJobHandler(job)}
                        >
                          <div className="uppercase font-bold">{job.taskDetails.ref}</div>
                          <div className="flex pl-2">
                            {" "}
                            {job.taskDetails.taskTags.length > 0 &&
                              job.taskDetails.taskTags.map((tag) => {
                                return (
                                  <div className=" md:text-sm  ">
                                    <Tag key={tag._id} tagData={tag} />
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      );
                    })}
                  </div>{" "}
                </div>
                <div className="pt-2">
                  <Button
                    label="Add To Project"
                    customClasses={` ${!isValid ? "bg-taskinatorMedGrey text-taskinatorWhite" : "bg-taskinatorBlue text-taskinatorWhite"}  w-full h-8`}
                    onClick={() => addJobHandler()}
                    isLoading={initState[1].started}
                    disabled={!isValid}
                  />
                </div>
              </>
            )}
            {initState[0].started && <IconSpinner size="m" />}
            {initState[0].error && <div className="text-taskinatorRed ">{initState[0].error}</div>}
          </div>
        </Modal>
      )}
    </>
  );
};

export default AddJobsButton;
