import React, { useState } from "react";
import StringShortenDisplay from "../../UI_elements/stringShortenDisplay";
import Modal from "../../UI_elements/modal";
import ItemRevisionDetailsContainer from "../items/itemRevisionDetailsContainer";
import { MdUpdate } from "react-icons/md";
import Tag from "../../UI_elements/tag";
import FileListView from "./fileListView";

const FileSimpleDetailsElement = ({ fileSet, clickable, removeItem, aidDetails, border, stacked, current, referenceFileSet }) => {
  const [modal, setModal] = useState();

  return (
    <>
      <div
        onClick={
          clickable
            ? (event) => {
                event.stopPropagation();
                setModal(true);
              }
            : () => {}
        }
        className={` border items-center  rounded-lg justify-center px-4 text-taskinatorDarkGrey  ${
          clickable ? "cursor-pointer hover:bg-taskinatorHoverBlue" : ""
        }  w-full bg-taskinatorItemBlue shadow  ${referenceFileSet ? "py-1" : "py-3 h-12"} flex ${stacked ? "lg:flex-col md:flex-row flex-col" : ""} ${border ? "border border-taskinatorBlue rounded shadow border-2" : ""} `}
      >
        <div className={`text-taskiantorDarkGrey ${referenceFileSet ? "text-xs py-0.5 px-2" : "text-sm p-2" }  border  border-taskinatorDarkGrey rounded items-center uppercase font-semibold`}>
          {fileSet.number} - {fileSet.name}
        </div>
        <div>
          <div className={`pl-2 flex`}>
            {fileSet?.settings?.fileTags?.map((tag, index) => {
              return <Tag key={tag._id} tagData={tag} />;
            })}
          </div>
        </div>
        <div className="flex grow"></div>
        {removeItem && (
          <div
            className={`text-xs cursor-pointer text-taskinatorRed hover:opacity-100 opacity-50 justify-end w-fit px-4 flex items-center `}
            onClick={(event) => {
              event.stopPropagation();
              removeItem(fileSet._id);
            }}
          >
            Remove
          </div>
        )}
      </div>

      {modal && (
        <Modal
          size="max"
          title="Files In Set"
          bgColour="bg-taskinatorLightGrey"
          local={() => {
            setModal();
          }}
        >
          <div>
            <FileListView fileSetId={fileSet._id} fileSelection={{}} localOnly />{" "}
          </div>
        </Modal>
      )}
    </>
  );
};

export default FileSimpleDetailsElement;
