import React, { useContext, useState } from "react";
import Tag from "../../UI_elements/tag";
import UserDisplayBubble from "../../UI_elements/userDisplayBubble";
import StringShortenDisplay from "../../UI_elements/stringShortenDisplay";
import DateDisplay from "../../UI_elements/dateDisplay";
import CountUpTimer from "../../../utils/countUpTimer";
import WatchTask from "../task/watchTask";
import FilterOutUniqueIds from "../../../utils/filterUniqueIds";
import DocumentDisplay from "../../UI_elements/documentDisplay";
import JobDetailsForm from "../../forms/jobDetailsForm";
import Modal from "../../UI_elements/modal";
import { AppContext } from "../../../contexts/appContext";
import Button from "../../UI_elements/button";
import IconSpinner from "../../UI_elements/iconSpinner";
import NewProjectForm from "../../forms/newProjectForm";


const ProjectDetailsDisplay = ({ data, getData }) => {
  const [editTaskDetails, setEditTaskDetail] = useState();
  const appData = useContext(AppContext);
  const canEdit = [process.env.REACT_APP_ACCESS_A, process.env.REACT_APP_ACCESS_C, process.env.REACT_APP_ACCESS_O, process.env.REACT_APP_ACCESS_M].includes(appData.workspaceDataV4.role);
  const manager =
    (data && data.managers.filter((manager) => manager._id === appData.workspaceDataV4.user_id).length > 0) ||
    [process.env.REACT_APP_ACCESS_A, process.env.REACT_APP_ACCESS_O].includes(appData.workspaceDataV4.role);
  
  
    if (data) {
    return (
      <>
        <div className="flex grow items-center w-full">
          <div className="font-bold text-taskinatorDarkGrey text-base uppercase"> Details</div>
          <div className="flex pl-2 justify-end grow">
            {manager && (
              <div className="pr-1 ">
                <Button
                  onClick={() => setEditTaskDetail(true)}
                  label="Edit Details"
                  customClasses={"border-taskinatorMedGrey text-taskinatorMedGrey  hover:bg-taskinatorMedGrey  hover:text-taskinatorWhite uppercase items-center text-xs h-8 px-2 border rounded"}
                />
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col grow w-full rounded-lg border p-2 mt-1 border-taskinatorMedGrey bg-taskinatorWhite ">
          <div className="flex grow py-1 ">
            {/* <div className="sm:hidden block  pr-1 font-semibold text-taskinatorDarkGrey "> Job Details </div> */}
            <div className="flex w-full flex-wrap ">
              <div className="flex-col flex items-start pr-2 border-r-taskinatorMedGrey border-r  mr-2 justify-start grow">
                <div className=" text-taskinatorDarkGrey text-taskinatorDarkGrey pr-1  md:text-sm ">Project Ref:</div>
                <div className="  md:text-sm text-taskinatorGreen font-medium uppercase py-1"> {StringShortenDisplay(30, data.ref)}</div>
              </div>
              <div className="flex-col flex items-start pr-2 border-r-taskinatorMedGrey border-r  mr-2 justify-start grow">
                <div className=" text-taskinatorDarkGrey pr-1  md:text-sm ">Tag/s:</div>
                <div className="font-semibold ">
                  <div className="w-full flex flex-wrap">
                    {data.projectTags.length > 0 ? (
                      data.projectTags.map((tag) => {
                        return (
                          <div className=" md:text-sm  ">
                            <Tag key={tag._id} tagData={tag} />
                          </div>
                        );
                      })
                    ) : (
                      <div className="text-taskinatorMedGrey  md:text-sm whitespace-nowrap">None Set...</div>
                    )}
                  </div>
                </div>
              </div>

              <div className="flex-col flex items-start pr-2 border-r-taskinatorMedGrey border-r  mr-2 justify-start grow">
                <div className=" text-taskinatorDarkGrey pr-1  md:text-sm ">Manager/s:</div>
                <div className="font-semibold ">
                  <div className="grow flex flex-wrap">
                    {data.managers && data.managers.length > 0 ? (
                      data.managers &&
                      data.managers.map((manager) => {
                        return <UserDisplayBubble firstName={manager.firstName} lastName={manager.lastName} user={manager} textSize={" md:text-sm"} />;
                      })
                    ) : (
                      <div className="text-taskinatorMedGrey  md:text-sm   whitespace-nowrap">None Set...</div>
                    )}
                  </div>
                </div>
              </div>

              <div className="flex-col flex items-start pr-2 border-r-taskinatorMedGrey border-r  mr-2 justify-start grow">
                <div className=" text-taskinatorDarkGrey pr-1  md:text-sm ">Created By:</div>
                <div className=" md:text-sm capitalize py-1">
                  {data.createdBy.split(" ")[0]} {data.createdBy.split(" ")[1][0]}
                </div>
              </div>

              <div className="flex-col flex items-start  mr-2 grow">
                <div className=" text-taskinatorDarkGrey pr-1  md:text-sm ">Active For:</div>
                <div className=" text-base  md:text-sm py-1"> {<CountUpTimer startDate={data.createdDate} />}</div>
              </div>
            </div>
            <div></div>
          </div>
          <div>
            <div className="border-t border-t-taskinatorMedGrey py-2 mt-2">
              <div className="flex flex-col grow">
                <div className=" text-taskinatorDarkGrey font-semibold whitespace-nowrap text-sm pb-2">Project Details:</div>
                <div className=" text-base md:text-sm whitespace-pre-wrap  h-fit grow">
                  {data.notes.length > 0 ? data.notes : <div className="text-taskinatorMedGrey whitespace-nowrap md:text-sm">No Details</div>}
                </div>
              </div>
              <div className="flex flex-col pt-1 border-t border-t-taskinatorMedGrey py-2 mt-2">
                <div className=" text-taskinatorDarkGrey pr-1  text-sm font-semibold whitespace-nowrap pb-2">Project Attachments:</div>
                <div className=" text-base  md:text-sm flex ">
                  {data.attachments.length > 0 ? (
                    data.attachments.map((attachment) => {
                      return (
                        <div className="w-fit  md:text-sm pr-1">
                          <DocumentDisplay attachment={attachment} />
                        </div>
                      );
                    })
                  ) : (
                    <div className="text-taskinatorMedGrey whitespace-nowrap md:text-sm">No Attachments</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {editTaskDetails && (
          <Modal size="medium" local={() => setEditTaskDetail()} title="Edit Project Details">
            <div className=" w-full ">
              <NewProjectForm
                details={data}
                onSuccess={() => {
                  getData();
                  setEditTaskDetail();
                }}
              />

              {/* <JobDetailsForm details={data} closeModal={() => setEditTaskDetail()} onSuccess={() => getData()} /> */}
            </div>
          </Modal>
        )}
      </>
    );
  } else {
    return (
      <div>
        <IconSpinner />
      </div>
    );
  }
};

export default ProjectDetailsDisplay;
