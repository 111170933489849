import React, { useContext } from "react";
import axios from "axios";
import { AppContext } from "../../contexts/appContext";

import { useInit } from "../hooks/useInit";
import { useInputV4 } from "../../stateManagement/useInputV4";

import InputV4 from "../inputs/input_V4";
import Button from "../UI_elements/button";
import TagSelector from "../dataSelectors/tagSelector";
import { VALIDATOR_MINLENGTH, VALIDATOR_REQUIRE } from "./validators/validators";
import SharedOrganisationSelectors from "../dataSelectors/sharedOrganisationSelectors";

const EditItemDetailsForm = ({ itemData, onSuccess }) => {
  const appData = useContext(AppContext);
  const { inputState, inputHandler, attachmentHandler } = useInputV4(
    {
      inputs: {
        name: { value: itemData.currentItemDetails.itemName, isValid: true },
        number: { value: itemData.currentItemDetails.itemNumber, isValid: true },
        wd: { value: itemData.currentItemDetails.wd, isValid: true },
      },
    },
    false
  );
  const { initState, initStarted, initCompleted, initErrors, initReset } = useInit(2);

  console.log(inputState);

  const submitHandler = (event) => {
    initStarted(0);
    axios
      .put(
        process.env.REACT_APP_BACKEND_URL + `/api/items/update/details/${itemData._id}`,
        {
          itemName: inputState.inputs.name.value,
          itemNumber: inputState.inputs.number.value,
          accessTags: inputState.inputs.accessTags.value,
          itemTags: inputState.inputs.itemTags.value,
          wd: inputState.inputs.wd.value,
          reasonForChange: inputState.inputs.reason.value,
          externalAccess: inputState.inputs.externalAccess && inputState.inputs.externalAccess.value,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        onSuccess();
        initCompleted(0);
      })
      .catch((err) => {
        const errorMessage = err?.response?.data?.message ?? err?.message ?? "";

        return initErrors(errorMessage, 0);
      });
  };

  return (
    <div className="md:w-96 w-full">
      <InputV4
        sid="inputs"
        id="number"
        label="Number"
        placeholder={"enternumber"}
        customClasses={"rounded-md"}
        defaultValue={itemData.currentItemDetails.itemNumber}
        onInput={inputHandler}
        validators={[VALIDATOR_REQUIRE()]}
        validityMessage={"Required"}
      />

      <div className="pt-2">
        <InputV4
          sid="inputs"
          id="name"
          label="Name"
          defaultValue={itemData.currentItemDetails.itemName}
          placeholder={"Item Name"}
          customClasses={"rounded-md"}
          onInput={inputHandler}
          validators={[VALIDATOR_REQUIRE()]}
          validityMessage={"Required"}
        />
      </div>

      <div className="pt-2">
        <TagSelector
          notRequired
          id={"itemTags"}
          inputHandler={inputHandler}
          defaultValue={
            itemData
              ? itemData.currentItemTags.map((tag) => {
                  return { value: tag._id };
                })
              : []
          }
          sid="inputs"
          type="item"
          label="Filter Tags"
          selectionDisplayText={"Select/Create Filter Tag"}
        />
      </div>

      <div className="pt-2">
        <TagSelector
          notRequired
          id={"accessTags"}
          inputHandler={inputHandler}
          defaultValue={
            itemData
              ? itemData.currentAccessTags.map((tag) => {
                  return { value: tag._id };
                })
              : []
          }
          sid="inputs"
          type="access"
          label="Internal Access Tags"
          selectionDisplayText={"Select/Create Access Tag"}
        />
      </div>

      <SharedOrganisationSelectors
        notRequired
        id={"externalAccess"}
        inputHandler={inputHandler}
        defaultValue={
          itemData.sharedWith
            ? itemData.sharedWith.map((sharedWith) => {
                return { value: sharedWith._id };
              })
            : []
        }
        sid="inputs"
        label="External Access"
        selectionDisplayText={"Grant Access To"}
      />

      <div className="pt-2">
        <InputV4
          sid="inputs"
          id="wd"
          label="Working Directory link"
          defaultValue={itemData.currentItemDetails.wd}
          placeholder={"Enter working directory Link"}
          customClasses={"rounded-md"}
          onInput={inputHandler}
          validators={[]}
          validityMessage={"Required"}
        />
      </div>

      <div className="pt-2">
        <InputV4
          textareaRows={3}
          sid="inputs"
          id="reason"
          label="Reason For Change"
          placeholder={"Reason for change"}
          customClasses={"rounded-md"}
          onInput={inputHandler}
          validators={[VALIDATOR_REQUIRE()]}
          validityMessage={"Required"}
        />
      </div>

      <Button
        onClick={submitHandler}
        label={"Update Drawing Details"}
        customClasses={`${inputState.isValid ? "bg-taskinatorBlue" : "bg-taskinatorMedGrey"} text-taskinatorWhite h-10 w-full mt-4 `}
        disabled={!inputState.isValid}
        isLoading={initState[0].started}
        error={initState[0].error}
      />
    </div>
  );
};

export default EditItemDetailsForm;
