import React from "react";
import { MdOutlineAdd, MdArrowForward } from "react-icons/md";
import IconSpinner from "../UI_elements/iconSpinner";

const AddStepDisplayButton = ({ customClasses, onClick, action, loading, rotate, notClickable, colour }) => {
  return (
    <div onClick={!notClickable ? () => onClick() : () => {}} className={`p-2`}>
      <div
        className={`   flex items-center justify-center border  rounded  ${colour ? `${colour}` : "border-taskinatorBlue text-taskinatorBlue"}  ${
          !notClickable ? "hover:text-taskinatorWhite hover:bg-taskinatorBlue  cursor-pointer" : ""
        }  h-6 w-6 rounded-full shadow`}
      >
        {!loading ? (
          action ? (
            <MdOutlineAdd size={"1rem"} />
          ) : (
            <div className={` opacity-100 ${rotate ? "rotate-90" : ""}`}>
              <MdArrowForward size={"1rem"} />
            </div>
          )
        ) : (
          <IconSpinner />
        )}
      </div>
    </div>
  );
};

export default AddStepDisplayButton;
