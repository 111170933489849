import React, { useEffect, useContext, useState, useCallback, useReducer } from "react";
import { AppContext } from "../../../contexts/appContext";
import { useInit } from "../../../components/hooks/useInit";
import axios from "axios";

import IconSpinner from "../../../components/UI_elements/iconSpinner";
import InputV4 from "../../inputs/input_V4";
import { useInputV4 } from "../../../stateManagement/useInputV4";
import Button from "../../UI_elements/button";
import NewItemForm from "../../forms/newItemForm";

import ItemRegisterListElement from "./itemRegisterListElement";
import { VALIDATOR_MINLENGTH } from "../../forms/validators/validators";
import Modal from "../../UI_elements/modal";
import { useParams } from "react-router-dom";
import LargeToggle from "../../UI_elements/LargeToggle";

const ItemGetAndSearchContainer = ({
  searchAndAdd,
  setEditButton,
  itemsOnTask,
  selectableItemsOnTask,
  setItemModal,
  addItemHandler,
  globalSetItems,
  wfid,
  isRegister,
  limitSelection,
  isLoading,
  error,
  label,
  tid,
  files,
}) => {
  const appData = useContext(AppContext);
  const numberOfItemsToGet = 40;
  const itemsViewBeforeAdding = 10;
  const { initState, initStarted, initCompleted, initErrors, initReset } = useInit(2);
  const { inputState, inputHandler } = useInputV4({ inputs: { search: { value: "", isValid: false } }, isValid: false });
  // const { tid } = useParams();

  const [modal, setModal] = useState();

  const [itemsOnList, setItemsOnList] = useState();
  const [numberOfItems, setNumberOfItems] = useState(numberOfItemsToGet);
  const [firstRender, setFirstRender] = useState(true);

  const [itemsSelected, setItemsSelcted] = useState({});

  const [toggle, setToggle] = useState();
  const options = [
    { label: "Active", value: "active" },
    { label: "Pending", value: "pending" },
  ];

  const onSelectHandler = (itemRowData, itemState) => {
    if (itemsSelected && itemsSelected[itemRowData._id]) {
      const currentItems = itemsSelected;
      delete currentItems[itemRowData._id];
      setItemsSelcted({ ...currentItems });
    } else {
      if (limitSelection && Object.values(itemsSelected).length < limitSelection) {
        setItemsSelcted({ ...itemsSelected, [itemRowData._id]: itemRowData });
      } else if (!limitSelection) {
        setItemsSelcted({ ...itemsSelected, [itemRowData._id]: itemRowData });
      }
    }
  };

  const onCompleteHandler = () => {
    if (globalSetItems) {
      globalSetItems(itemsSelected);
    } else {
      addItemHandler(itemsSelected);
    }
  };

  //// this can be deleted once we do not need to add items in item manager
  const onResponse = () => {
    setModal();
    sendRequest();
    inputHandler("search", "", true, "inputs");
  };

  //// this auto searches
  useEffect(() => {
    const delay = 500;
    let timer;

    clearTimeout(timer);

    if (inputState.inputs.search.value.trim().length > 0) {
      timer = setTimeout(() => {
        sendRequest();
        // seachItems();
      }, delay);
    } else if (inputState.inputs.search.value.trim().length === 0) {
      sendRequest();
    }
    return () => {
      clearTimeout(timer);
    };
  }, [inputState]);

  /// this sets up the button if required

  useEffect(() => {
    if (setEditButton && ![process.env.REACT_APP_ACCESS_G].includes(appData.workspaceDataV4.role)) {
      setEditButton({
        label: `Create New ${appData.itemName}`,
        customClasses: "bg-taskinatorBlue border-taskinatorBlue text-taskinatorWhite h-10 text-xs md:text-sm ml-2 py-2",
        onClick: () => setModal(true),
      });
    } else if (setEditButton) {
      setEditButton({});
    }
  }, [itemsOnList]);

  //this resets to default all current views

  // useEffect(() => {
  //   if (inputState.inputs.search.value.length === 0 && !firstRender) {
  //     setNumberOfItems(numberOfItemsToGet);
  //     sendRequest();
  //   }

  //   setFirstRender(false);
  // }, [inputState]);

  const sendRequest = () => {
    initReset(1);
    initStarted(0);

    let url;
    if (tid) {
      console.log("this one");
      url = `available/${numberOfItems}/${tid}/${wfid}/$${inputState.inputs.search.value}`;

      axios
        .get(
          process.env.REACT_APP_BACKEND_URL + `/api/items/${url}`,

          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        )
        .then((response) => {
          setItemsOnList(response.data.items);
          initCompleted(0);
        })
        .catch((err) => {
          initErrors(err?.response?.data?.message ?? err?.message ?? "", 0);
        });
    } else {
      const isAll = Object.keys(appData.tagFilter)[0] === "all tags";
      url = `initialise/${isAll ? 100 : numberOfItems}/$${inputState.inputs.search.value}`;

      axios
        .put(
          process.env.REACT_APP_BACKEND_URL + `/api/items/${url}`,
          {
            tagFilter: !isAll
              ? Object.values(appData.tagFilter)
                  .map((key) => appData.jobListFilterValues[key])
                  .flat(1)
              : [],
            isAll: isAll,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        )
        .then((response) => {
          setItemsOnList(response.data.items);
          initCompleted(0);
        })
        .catch((err) => {
          initErrors(err?.response?.data?.message ?? err?.message ?? "", 0);
        });
    }
  };

  useEffect(() => {
    sendRequest();
  }, [numberOfItems, appData.tagFilter]);

  return (
    <>
      <div className={` w-full h-full  flex flex-col grow overflow-auto`}>
        <div className="w-full flex-col  justify-center items-center px-2 flex bg-taskinatorWhite py-4 shadow">
          <div className="flex w-full items-center justify-center">
            <div className="flex items-center justify-center w-full">
              <InputV4
                onInput={inputHandler}
                customClasses=" text-center border-taskinatorDarkGrey md:w-96 w-[100%]"
                defaultValue={inputState.inputs.search.value}
                placeholder={`Search for ${appData.itemName.toLowerCase()} by name, number or filter tag`}
                sid="inputs"
                id="search"
                validators={[VALIDATOR_MINLENGTH(1)]}
              />
            </div>
          </div>
        </div>
        <div className="h-full overflow-auto flex flex-col w-full">
          {initState[0].started && !itemsOnList && (
            <div className=" my-4">
              <IconSpinner size="m" />
            </div>
          )}
          <div className="flex  overflow-auto">
            {!initState[0].started && itemsOnList && (
              <ItemRegisterListElement
                tid={tid}
                itemData={itemsOnList}
                setModal={setModal}
                searchAndAdd={searchAndAdd}
                itemsSelected={itemsSelected}
                onSelectHandler={onSelectHandler}
                itemsOnTask={itemsOnTask}
                selectableItemsOnTask={selectableItemsOnTask}
                isRegister={isRegister}
              />
            )}
          </div>
          {!initState[0].started && itemsOnList && itemsOnList.length === 0 && (
            <div className="py-4 bg-taskinatorWhite w-full items-center justify-center flex text-taskinatorDarkGrey flex-col">
              <div className="py-2 text-sm">{`No ${appData.itemName}s Available`}</div>
            </div>
          )}
          {!setEditButton && ![process.env.REACT_APP_ACCESS_G].includes(appData.workspaceDataV4.role) && (
            <div className="my-4 flex items-center justify-center">
              <Button
                label={`Create New Drawing`}
                customClasses="text-taskinatorBlue border rounded hover:bg-taskinatorBlue hover:text-taskinatorWhite border-taskinatorBlue  h-10 text-xs md:text-sm ml-2 py-2"
                onClick={() => {
                  setModal(true);
                }}
              />
            </div>
          )}
        </div>
        {itemsOnList && itemsOnList.length === numberOfItems && (
          <div className="w-full justify-center flex pt-4 pb-2">
            <Button
              label={`Load More ${appData.itemName}s`}
              customClasses="border border-taskinatorBlue text-taskinatorBlue  text-xs md:text-sm py-1"
              onClick={() => {
                setNumberOfItems(numberOfItems + 20);
                inputHandler("search", "", true, "inputs");
              }}
            />
          </div>
        )}
      </div>
      {addItemHandler && (
        <div>
          <Button
            onClick={() => {
              onCompleteHandler();
              if (setItemModal) {
                setItemModal();
              }
            }}
            label={label ? label : `Add ${appData.itemName}`}
            customClasses={` my-2 ${
              Object.keys(itemsSelected).length > 0 ? "border-taskinatorBlue bg-taskinatorBlue" : "border-taskinatorMedGrey bg-taskinatorMedGrey"
            } w-full border text-taskinatorWhite hover:text-taskinatorWhite hover:bg-taskinatorBlue  text-sm  mx-1 py-2 h-10 `}
            disabled={Object.keys(itemsSelected).length === 0}
            isLoading={isLoading}
            error={error}
          />
        </div>
      )}
      {modal && (
        <Modal bgColour="bg-taskinatorLightGrey" title={modal.itemData ? "Create Working Version" : `Create New ${appData.itemName}`} local={() => setModal()}>
          <NewItemForm onResponse={onResponse} itemData={modal.itemData} />
        </Modal>
      )}
    </>
  );
};

export default ItemGetAndSearchContainer;
