import LowerCaseTrim from "./lowerCaseAndTrim";

const TaskFormatter = (
  taskStateData,
  filter,
  mineOnly,
  all,
  managedOnly,
  appData
) => {
  // const appData = useContext(AppContext);
  let formattedTracers = {};

  // function sortItemsByDate(items) {
  //   return items.sort((a, b) => new Date(b.date) - new Date(a.date));
  // }

  Object.values(taskStateData)
    .filter((tracer) => {
      if (!filter) {
        return true;
      } else {
        if (filter.trim().length === 0) {
          return true;
        } else {
          return (
            LowerCaseTrim(tracer.taskDetails.ref).includes(
              LowerCaseTrim(filter)
            ) ||
            (tracer.wfid._id &&
              LowerCaseTrim(tracer.wfid.name).includes(
                LowerCaseTrim(filter)
              )) ||
            (tracer.itemsOnTracer &&
              LowerCaseTrim(
                tracer.itemsOnTracer.currentItemDetails.itemName
              ).includes(LowerCaseTrim(filter))) ||
            (tracer.itemsOnTracer &&
              LowerCaseTrim(
                tracer.itemsOnTracer.currentItemDetails.itemNumber
              ).includes(LowerCaseTrim(filter))) ||
            (tracer.aid.name &&
              LowerCaseTrim(tracer.aid.name).includes(LowerCaseTrim(filter))) ||
            tracer.taskDetails.taskTags.filter((tag) =>
              LowerCaseTrim(tag.name).includes(LowerCaseTrim(filter))
            ).length > 0 ||
            tracer.assignee.filter((assignee) =>
              LowerCaseTrim(assignee.lastName).includes(LowerCaseTrim(filter))
            ).length > 0 ||
            tracer.assignee.filter((assignee) =>
              LowerCaseTrim(assignee.firstName).includes(LowerCaseTrim(filter))
            ).length > 0
          );
        }
      }
    })
    .map((tracer) => {
      let isManaged;
      let isNew;
      let isAssigned;
      let assignedToSomeoneElse;
      let couldbeAssigned = false;
      let isWatched;
      let taskManaged;

      if (!all) {
        if (
          tracer.aid &&
          tracer.aid._id &&
          tracer.assignee.length === 0 &&
          tracer.aid.team.filter(
            (user) =>
              user._id.toString() === appData.workspaceDataV4.user_id.toString()
          ).length === 1
        ) {
          isNew = true;
        }

        /// this checks who is assigned
        if (Array.isArray(tracer.assignee) && tracer.assignee.length > 0) {
          if (
            tracer.assignee.filter(
              (user) =>
                user._id.toString() ===
                appData.workspaceDataV4.user_id.toString()
            ).length === 1
          ) {
            isAssigned = true;
          }

          if (
            tracer.assignee.filter(
              (user) =>
                user._id.toString() ===
                appData.workspaceDataV4.user_id.toString()
            ).length === 0
          ) {
            assignedToSomeoneElse = true;
          }
        }

        if (
          !assignedToSomeoneElse &&
          !isAssigned &&
          tracer.aid.team.filter(
            (user) =>
              user._id.toString() === appData.workspaceDataV4.user_id.toString()
          ).length === 1
        ) {
          couldbeAssigned = true;
        }

        if (
          !isAssigned &&
          tracer.wfid.managers &&
          tracer.wfid.managers.filter(
            (manager) => manager._id === appData.workspaceDataV4.user_id
          ).length > 0
        ) {
          isManaged = true;
        }
        if (
          tracer.taskDetails.watchers
            ? tracer.taskDetails.watchers.filter(
                (watcher) =>
                  watcher._id.toString() ===
                  appData.workspaceDataV4.user_id.toString()
              ).length > 0
            : false
        ) {
          isWatched = isAssigned || couldbeAssigned ? false : true;
        }
        if (
          tracer.taskDetails.managers.filter(
            (manager) =>
              manager._id.toString() ===
              appData.workspaceDataV4.user_id.toString()
          ).length > 0
        ) {
          taskManaged = true;
        }
      } else {
        isAssigned = true;
      }

      return {
        ...tracer,
        isNew: isNew,
        isAssigned: isAssigned,
        isManaged: isManaged,
        couldbeAssigned: couldbeAssigned,
        isWatched: isWatched,
        taskManaged: taskManaged,
      };
    })
    .filter((filteredTracers) => {
      if (mineOnly) {
        return filteredTracers.isAssigned || filteredTracers.couldbeAssigned;
      } else if (managedOnly) {
        return !filteredTracers.isAssigned && !filteredTracers.couldbeAssigned;
      } else {
        return true;
      }
    })
    .sort((a, b) => {
      const priorityValA = a.isManaged || a.isWatched || a.taskManaged ? 0 : 1;
      const priorityValB = b.isManaged || b.isWatched || b.taskManaged ? 0 : 1;

      // If the priority values are different, sort based on priority
      if (priorityValA !== priorityValB) {
        return priorityValB - priorityValA;
      }

      // If both have the same priority status, compare dates
      return new Date(b.time_in) - new Date(a.time_in);
    })
    .map((filteredTracer, index) => {
      const { taskDetails, ...tracerDetails } = filteredTracer;
      // const { itemsOnTracer, ...otherDetails } = filteredTracer;

      // const updatedTracerDetails = formattedTracers[filteredTracer.tid]
      //   ? { ...formattedTracers[filteredTracer.tid].elementData, [filteredTracer._id]: { ...tracerDetails } }
      //   : { [filteredTracer._id]: { ...tracerDetails } };

      // formattedTracers = { ...formattedTracers, [filteredTracer.tid]: { ...taskDetails, tid: filteredTracer.tid, elementData: updatedTracerDetails } };

      const updatedTracerDetails = formattedTracers[index]
        ? {
            ...formattedTracers[index].elementData,
            [filteredTracer._id]: { ...tracerDetails },
          }
        : { [filteredTracer._id]: { ...tracerDetails } };

      formattedTracers = {
        ...formattedTracers,
        [index]: {
          ...taskDetails,
          tid: filteredTracer.tid,
          elementData: updatedTracerDetails,
        },
      };

      return filteredTracer;
    });

  return formattedTracers;
};

export default TaskFormatter;
