import React, { useContext, useState, useRef, useEffect } from "react";
import { MdInfoOutline } from "react-icons/md";
import { AppContext } from "../contexts/appContext";

const ToolTip = ({ content, full, video, customCSS }) => {
  const [show, setShow] = useState(false);
  const appData = useContext(AppContext);
  const tooltipRef = useRef(null);
  const parentRef = useRef(null);
  const [tooltipStyles, setTooltipStyles] = useState({});

  useEffect(() => {
    if (show) {
      const tooltip = tooltipRef.current;
      const parent = parentRef.current;
      const parentRect = parent.getBoundingClientRect();
      const offset = 4; // Small offset for close positioning

      let newStyles = {
        top: `${parentRect.bottom + window.scrollY + offset}px`,
        left: `${parentRect.left + window.scrollX + offset}px`,
      };

      const tooltipRect = tooltip.getBoundingClientRect();

      // Adjust if it goes outside the screen boundaries
      if (tooltipRect.bottom + offset > window.innerHeight) {
        newStyles.top = `${window.innerHeight - tooltipRect.height - offset}px`;
      }
      if (tooltipRect.right + offset > window.innerWidth) {
        newStyles.left = `${window.innerWidth - tooltipRect.width - offset}px`;
      }

      setTooltipStyles(newStyles);
    }
  }, [show]);

  return (
    <div ref={parentRef} className=" flex items-center px-1 normal-case ">
      <div
        onClick={video ? () => appData.setToolTipModal(content) : () => {}}
        onMouseEnter={video ? () => {} : () => setShow(true)}
        onMouseLeave={video ? () => {} : () => setShow(false)}
        className="cursor-pointer"
      >
        {full ? (
          <div
            className={`text-xs font-medium border-taskinatorMedGrey text-taskinatorDarkGrey bg-taskinatorMedGrey rounded w-fit hover:opacity-75 px-2 py-1 ${customCSS}`}
          >
            <div className="flex items-center justify-center">
              <div className="pr-1">
                <MdInfoOutline />
              </div>{" "}
              Watch Quick Guide
            </div>
          </div>
        ) : (
          <MdInfoOutline />
        )}
      </div>
      {show && (
        <div
          ref={tooltipRef}
          // style={{ ...tooltipStyles, zIndex: 9999, position: 'absolute' }}
          className="px-4 py-2 text-sm text-taskinatorDarkGrey bg-taskinatorWhite min-w-40 max-w-96 rounded-md shadow-lg border border-taskinatorMedGrey absolute ml-4 z-50"
        >
          {content}
        </div>
      )}
    </div>
  );
};

export default ToolTip;
