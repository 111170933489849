import React from "react";
import LoginScreen from "../../pageTemplates/loginScreen";
import ResetPasswordRequestForm from "../../components/forms/resetPasswordRequestForm";

const ResetPasswordRequest = () => {
  return (
    <LoginScreen>
 
      <ResetPasswordRequestForm />
    </LoginScreen>
  );
};

export default ResetPasswordRequest;
