import axios from "axios";
import React, { useState } from "react";
import Button from "../../UI_elements/button";
import { useInit } from "../../hooks/useInit";

const JobOnHold = ({ jobId, activator, taskDetails, tracer, getData }) => {
  const { initState, initStarted, initCompleted, initErrors } = useInit(1);

  const isOnHold = taskDetails?.onHold;
  const [onHold, setOnHold] = useState(isOnHold ?? false);

  const onSubmitHandler = (e) => {
    e.stopPropagation();

    if (taskDetails) {
      initStarted(0);
      axios
        .put(
          process.env.REACT_APP_BACKEND_URL + `/api/job/onHold/${jobId}`,
          { onHold: !onHold },
          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        )
        .then((response) => {
          setOnHold(response.data.onHold);
          // getData();
          initCompleted(0);
          return;
        })
        .catch((err) => {
          console.log(err);
          const errorMessage = err?.response?.data?.message ?? err?.message ?? "";
          initErrors(errorMessage, 0);
        });
    }
  };

  if (activator) {
    return (
      <Button
        onClick={onSubmitHandler}
        label={onHold ? "Reactivate Job" : "Put Job On Hold"}
        customClasses={`uppercase h-8 flex items-center justify-center text-xs px-2 border rounded ml-2 ${
          onHold ? "border-taskinatorGreen bg-taskinatorGreen text-taskinatorWhite" : "border-taskinatorOrange text-taskinatorOrange hover:bg-taskinatorOrange hover:text-taskinatorWhite"
        }`}
        isLoading={initState[0].started}
      />
    );
  } else {
    return (
      onHold && (
        <div className="uppercase mr-2 px-2 rounded text-base border border-taskinatorORange text-taskinatorWhite bg-taskinatorOrange hover:text-taskinatorWhite h-8 animate-pulse flex items-center justify-center">
          On Hold
        </div>
      )
    );
  }
};

export default JobOnHold;
