import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { AppContext } from "../../../contexts/appContext";
import { TaskContext } from "../../../contexts/taskContext";
import { useInit } from "../../hooks/useInit";
import { useInputV4 } from "../../../stateManagement/useInputV4";
import Button from "../../UI_elements/button";
import Modal from "../../UI_elements/modal";
import { useParams } from "react-router-dom";
import TaskItemDisplayV4 from "./taskItemDisplayV4";
import TaskWorkflowDisplayV4 from "./taskWorkflowDisplayV4";

const AddWorkflowItemHandler = ({ closeModal, tid }) => {
  const appData = useContext(AppContext);
  const taskContext = useContext(TaskContext);

  const { initState, initStarted, initCompleted, initErrors, initReset } = useInit();
  const { inputState, inputHandler, attachmentHandler } = useInputV4();

  const addHandler = () => {
    initStarted(0);
    axios
      .put(
        process.env.REACT_APP_BACKEND_URL + `/api/task/addworkflowitems/${tid}`,
        {
          taskDetails: inputState.details,
          // attachments: Object.values(inputState.attachments).map((attachment) => {
          //   return { key: attachment.value.key, fileName: attachment.value.fileName };
          // }),
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        // taskContext.getActionData();

        console.log()
        appData.setActionViewHandler({ tracerId: response.data.newTracerId, tid: tid });
        closeModal();
        initCompleted(0);
      })
      .catch((err) => {
        console.log(err);
        initErrors(err?.response?.data?.message ?? err?.message ?? "", 0);
      });
  };

  const workflowSelected = inputState.details && inputState.details.workflow.value.length > 0;

  // get allowable action data
  useEffect(() => {
    if (workflowSelected) {
      console.log("get data");
    }
  }, [workflowSelected]);

  return (
    <Modal title="Add Workflow & Drawing" bgColour="bg-taskinatorLightGrey" size="max" local={() => closeModal()}>
      <div className="flex flex-col grow overflow-auto ">
        <div className="flex xl:flex-row flex-col grow xl:overflow-auto ">
          <div className="flex flex-col mb-2 xl:w-7/12 w-full ">
            <div className="xl:mt-0 mt-1 border-l border-l-4 border-l-taskinatorGreen py-2 px-4  bg-taskinatorGreen shadow mb-1 font-semibold uppercase flex items-center rounded-lg ">
              <div className="flex items-center text-taskinatorWhite text-base">Workflow</div> <div className="pl-1 text-taskinatorWhite capitalize font-normal text-base">(Required)</div>
              {/* <div className="pl-1 text-taskinatorRed capitalize font-normal text-xs">(Required)</div> */}
            </div>
            <TaskWorkflowDisplayV4 globalSid={"details"} globalInputHandler={inputHandler} globalAttachmentHandler={attachmentHandler} buttonLabel="Add Workflow" />
          </div>

          <div className="xl:w-5/12 xl:ml-1 ml-0 xl:mt-0 mt-1 w-full">
            <TaskItemDisplayV4
              globalSid={"details"}
              globalInputHandler={inputHandler}
              workflowId
              workflowAdded={workflowSelected}
              type="workflow"
              tid={tid}
              wfid={workflowSelected && inputState.details.workflow.value[0].wfid._id}
              number="2"
            />
          </div>
        </div>

        <div className="xl:pt-0 pt-8 ">
          <Button
            onClick={() => addHandler()}
            disabled={!inputState.isValid}
            label="Add"
            customClasses={`border ${
              inputState.isValid ? "border-taskinatorBlue bg-taskinatorBlue" : "bg-taskinatorMedGrey border-taskinatorMedGrey"
            } hover:opacity-75   text-taskinatorWhite h-10 text-xs md:text-sm  py-2 w-full `}
            error={initState[0].error}
            isLoading={initState[0].started}
          />
        </div>
      </div>
    </Modal>
  );
};

export default AddWorkflowItemHandler;
