import { useEffect, useState } from "react";
import { useInit } from "../components/hooks/useInit";
import JobListFormatter from "../utils/jobListFormatter";

export const useDashboardState = (socket, socketConnected, workspaceData) => {
  const { initState, initStarted, initCompleted, initErrors } = useInit(5);
  const [tasks, setTask] = useState({});
  const [jobs, setJobs] = useState({});
  const [questions, setQuestions] = useState({});
  const [notifications, setNotifications] = useState({});
  const [schedule, setSchedule] = useState([]);
  const [priorityTasks, setPriorityTasks] = useState([]);

  useEffect(() => {
    if (socketConnected && socket && workspaceData) {
      initStarted(0);
      initStarted(1);
      initStarted(2);
      initStarted(3);
      initStarted(4);

      socket.emit(
        "dashboard",
        {
          action: "getInitDashboardData",
        },
        (callback) => {
          if (callback.success) {
          } else {
            initErrors(callback.error, 0);
          }
        }
      );

      return () => {
        socket.off("taskUpdate");
        socket.off("questionUpdate");
        socket.off("notificationUpdate");
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketConnected, socket, workspaceData]);

  socket.on("scheduleUpdate", (data) => {
    setSchedule(data?.schedule ?? []);
    setPriorityTasks(data?.schedule?.[0] ?? []);
    initCompleted(0);
    console.log(data, "scheduleUpdate");
  });

  //////////// this turns on the sockets

  socket.on("taskUpdate", (data) => {
    if (data.action === "init") {
      initCompleted(1);
      setTask(data.tasks);
    } else {
      setTask((currentTasks) => {
        const updatedTasks = { ...currentTasks }; // Create a shallow copy for immutability

        if (data.action === "add") {
          // Add or update the task
          updatedTasks[data.tracer._id] = data.tracer;
        } else if (data.action === "remove") {
          // Remove the task if it exists
          delete updatedTasks[data.idToRemove];
        }

        return updatedTasks;
      });
    }
  });

  socket.on("jobUpdate", (data) => {
    if (data.action === "init") {
      initCompleted(2);
      setJobs(data.jobs);
    } else {
      setJobs((currentJobs) => {
        const updatedJobs = { ...currentJobs }; // Create a shallow copy for immutability

        if (data.action === "update") {
          // Add or update the task
          if (data.job.completed) {
            delete updatedJobs[data.job._id];
          } else {
            updatedJobs[data.job._id] = data.job;
          }
        } else if (data.action === "remove") {
          // Remove the task if it exists
          delete updatedJobs[data.idToRemove];
        }

        return updatedJobs;
      });
    }
  });

  socket.on("questionUpdate", (data) => {
    if (data.action === "init") {
      initCompleted(3);
      setQuestions(data.questions);
    }

    if (data.action === "add") {
      setQuestions((prevQuestions) => ({
        ...prevQuestions,
        [data.newQuestion._id]: data.newQuestion,
      }));
    }

    if (data.action === "remove") {
      setQuestions((prevQuestions) => {
        const updatedQuestionState = { ...prevQuestions };
        delete updatedQuestionState[data.idToRemove];
        return updatedQuestionState;
      });
    }
  });

  socket.on("notificationUpdate", (data) => {
    if (data.action === "init") {
      initCompleted(4);
      setNotifications(data.notifications);
    }

    if (data.action === "add") {
      setNotifications((prevNotifications) => ({
        ...prevNotifications,
        [data.newNotifications._id]: data.newNotifications,
      }));
    }

    if (data.action === "remove") {
      setNotifications((prevNotifications) => {
        const updatedNotificationState = { ...prevNotifications };
        delete updatedNotificationState[data.idToRemove];
        return updatedNotificationState;
      });
    }
  });

  const jobListFilterValues = tasks && JobListFormatter([...Object.values(tasks), ...Object.values(jobs)]); /// we use this to store the task details as a live value

  let jobListFilterOptions = {};

  jobListFilterValues &&
    Object.keys(jobListFilterValues)?.map((v) => {
      jobListFilterOptions = { ...jobListFilterOptions, [v]: v };
      return null;
    });

  return [priorityTasks, schedule, jobs, tasks, questions, notifications, initState, jobListFilterValues, jobListFilterOptions, setTask];
};
