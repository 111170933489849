import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../contexts/appContext";
import { useInputV4 } from "../../stateManagement/useInputV4";
import Button from "../UI_elements/button";
import IconSpinner from "../UI_elements/iconSpinner";
import TagSelector from "../dataSelectors/tagSelector";
import BulkFileUpload from "../displayElements/files/bulkFileUpload";
import { useInit } from "../hooks/useInit";
import InputV4 from "../inputs/input_V4";
import LiveDataCheckOnInput from "../inputs/liveDataCheckOnInput";
import { VALIDATOR_MINLENGTH } from "./validators/validators";
import { MdOutlineWarning } from "react-icons/md";
import Tag from "../UI_elements/tag";
import ToolTip from "../../utils/toolTip";
import { FileContext } from "../displayElements/files/FileContext";
const NewFileSetForm = ({ fileSet, onResponse, type, setModalLabel, closeFocusModal, onFilesUploaded }) => {
  const appData = useContext(AppContext);
  const fileData = useContext(FileContext);
  const { inputState, inputHandler, attachmentHandler } = useInputV4({ inputs: {} }, false);
  const [addFiles, setAddFiles] = useState();
  const { initState, initStarted, initCompleted, initErrors, initReset } = useInit(3);
  const [nextNumberAndSettings, setNextNumberAndSettings] = useState();
  const [percentage, setPercentage] = useState(0);
  const [manualNumber, setManualNumber] = useState(fileSet ? true : false);

  useEffect(() => {
    const sendRequest = () => {
      if (!manualNumber) {
        initStarted(1);
        axios
          .get(process.env.REACT_APP_BACKEND_URL + `/api/file/numberandsettings`, {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          })
          .then((response) => {
            setNextNumberAndSettings(response.data);
            inputHandler("fileSetNumber", response.data.nextNumber, true, "inputs");
            initCompleted(1);
          })
          .catch((err) => {
            return initErrors(err?.response?.data?.message ?? err?.message ?? "", 1);
          });
      }
    };

    sendRequest();
  }, [manualNumber]);

  const submitHandler = async (type) => {
    let submitData = {
      fileSetNumber: inputState.inputs.fileSetNumber.value,
      fileSetName: inputState.inputs.fileSetName.value,
      // accessTags: inputState.inputs.accessTags.value,
      fileTags: inputState.inputs.fileTags.value,
      wd: inputState.inputs.wd.value,
      // externalAccess: inputState.inputs.externalAccess ? inputState.inputs.externalAccess.value : [],
    };

    try {
      type === "addFiles" ? initStarted(2) : initStarted(0);
      const responseData = await axios.put(process.env.REACT_APP_BACKEND_URL + `/api/file/${fileSet ? `save/${fileSet._id}` : "create"}/focus`, submitData, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      });

      if (type === "addFiles") {
        setAddFiles({
          fileSetId: responseData.data.fileSetId,
          fileSetName: responseData.data.fileSetName,
        });
        setModalLabel("Add Files");
        type === "addFiles" ? initCompleted(2) : initCompleted(0);
      } else {
        closeFocusModal();
      }

      // automatically set the data into display once it is created and select it
      fileData.setItemsOnList(
        (currentData) => {

          if(fileSet){
          return currentData.map((fileSet) => {
            if (fileSet?._id === responseData.data.fileSetData._id) {
              return responseData.data.fileSetData;
            } else {
              return fileSet;
            }
          })} else {
            return [responseData?.data?.fileSetData, ...(currentData ?? [])];
          };
        }
        // if (fileSet ? currentData.filter((item) => item._id !== fileSet._id) : currentData) return [responseData.data.fileSetData, ...currentData];
      );
      fileData.setFileSelection();

      if (!fileSet) {
        fileData.setItemsSelcted({ [responseData.data.fileSetId]: responseData.data.fileSetData });
      }
      onResponse(responseData.data.fileSetId);

      initCompleted(0);
    } catch (err) {
      return type === "addFiles" ? initErrors(err?.response?.data?.message ?? err?.message ?? "", 2) : initErrors(err?.response?.data?.message ?? err?.message ?? "", 0);
    }
  };

  const removeFileHandler = () => {
    initStarted(1);

    axios
      .get(process.env.REACT_APP_BACKEND_URL + `/api/file/fileSet/remove/${fileSet._id.toString()}`, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      .then((response) => {
        initCompleted(1);
        onResponse(fileSet._id.toString());
      })
      .catch((err) => {
        return initErrors(err?.response?.data?.message ?? err?.message ?? "", 1);
      });
  };

  /// this automatically sets draft state items to draft status
  const itemStatus = inputState.inputs.itemState && inputState.inputs.itemState.value;

  useEffect(() => {
    if (itemStatus === "draft") inputHandler("status", "draft", true, "inputs");
  }, [itemStatus]);
  ///// stamping handling

  return !addFiles ? (
    <div className="w-full h-full flex flex-col overflow-auto">
      <div className="flex flex-col md:flex-row w-full h-full grow overflow-auto scrollbar relative">
        <div className={`w-full md:w-96 flex flex-col bg-taskinatorWhite p-4 rounded-0 md:rounded-l-lg h-full`}>
          {fileSet?.inJob?.length > 0 && (
            <div className="text-xs mt-4 text-center text-taskinatorRed flex  rounded-lg items-center  justify-center py-1">
              <MdOutlineWarning size={"1rem"} />
              <div className="px-1"> This folder is active in a job: </div>{" "}
              <div className="font-bold  uppercase  text-taskinatorRed   ">
                {fileSet?.inJob?.map?.((job) => {
                  return <div>{job?.ref}</div>;
                })}
              </div>
              <div>
                {/* <Button
                  label="View Job Details"
                  customClasses={"text-taskinatorWhite bg-taskinatorBlue mt-2 w-full h-8"}
                  onClick={() => {
                    appData.setJobViewHandler({ jobId: inJob._id });
                  }}
                /> */}
              </div>
            </div>
          )}
          {!manualNumber && (
            <div>
              <div className="text-sm mt-2 flex">
                <div className="text-taswkinatorDarkGrey">{`Folder ID`}</div>
                <div className="text-taskinatorRed pl-1">(Required)</div>
              </div>
              <div className="text-base rounded-md block w-full appearance-none border border-taskinatorMedGrey px-3 py-2 text-gray-900 placeholder-gray-500">
                {initState[1].started && (
                  <div className="flex w-fll items-center">
                    <IconSpinner />
                    <div className="text-sm text-taskinatorBlue">Getting next item number...</div>
                  </div>
                )}
                {initState[1].finished && (
                  <div className="flex w-full items-center text-xs">
                    <div className="grow">{nextNumberAndSettings.nextNumber}</div>
                    <div
                      onClick={() => {
                        setManualNumber(true);
                      }}
                      className="text-xs text-taskinatorBlue underlined hover:opacity-75 cursor-pointer"
                    >
                      Enter Manually
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          {manualNumber && (
            <div>
              <LiveDataCheckOnInput
                validators={[VALIDATOR_MINLENGTH(1)]}
                onValid={inputHandler}
                id="fileSetNumber"
                sid="inputs"
                defaultValue={fileSet?.number ?? ""}
                placeholder={`Folder ID`}
                label={`Folder Id`}
                route="file"
                minLength={1}
              />
              <div
                onClick={() => {
                  setManualNumber();
                }}
                className="text-xs text-taskinatorBlue underlined hover:opacity-75 cursor-pointer"
              >
                Or Get Next
              </div>
            </div>
          )}

          {
            <LiveDataCheckOnInput
              validators={[VALIDATOR_MINLENGTH(3)]}
              onValid={inputHandler}
              id="fileSetName"
              sid="inputs"
              defaultValue={fileSet?.name ?? ""}
              placeholder={"Folder Name"}
              label={`Folder Name`}
              route="file"
              minLength={3}
            />
          }

          <div className="pt-2">
            <TagSelector
              id={"fileTags"}
              inputHandler={inputHandler}
              defaultValue={
                fileSet?.settings?.fileTags?.length > 0
                  ? fileSet?.settings?.fileTags?.map((tag) => {
                      return { value: tag._id };
                    })
                  : []
              }
              sid="inputs"
              type="item"
              label="Folder Tag"
              selectionDisplayText={"Select/Create Foldert Tag"}
              notRequired
              toolTip={"Use this as an easy way to filter folder"}
            />
          </div>

          <div className="pt-2 hidden">
            <div className="flex">
              Access Tags <ToolTip content={"Access Tags Are Set By the files"} />
            </div>
            <div className="flex flex-wrap border rounded border-taskinatorDarkGrey w-full p-2">
              {fileSet?.settings?.accessTags?.map?.((tag) => {
                return <Tag tagData={tag} />;
              })}
              {fileSet?.settings?.accessTags === undefined || fileSet?.settings?.accessTags?.length === 0 ? <div className="text-taskinatorMedGrey text-xs r ">No Access Tags</div> : ""}
            </div>
          </div>

          <div className="pt-2 hidden">
            <InputV4
              sid="inputs"
              id="wd"
              label={"Working Directory Link"}
              placeholder={"add working directory link"}
              customClasses={"rounded-md"}
              // defaultValue={!newRev && revData ? revData.revision : ""}
              onInput={inputHandler}
              validators={[]}
              textareaRows={1}
            />
          </div>
        </div>
      </div>
      {!fileSet && (
        <div>
          <Button
            onClick={() => {
              submitHandler("addFiles");
            }}
            label={`Create Folder & Add Files`}
            customClasses={`${inputState.isValid ? "bg-taskinatorDarkGrey" : "bg-taskinatorMedGrey"} bg-taskinatorBlue text-taskinatorWhite h-10 w-full mt-4`}
            disabled={!inputState.isValid}
            isLoading={initState[2].started}
            error={initState[2].error}
          />
        </div>
      )}
      <Button
        onClick={submitHandler}
        label={fileSet ? "Save Folder" : `Create Folder`}
        customClasses={`${inputState.isValid ? "bg-taskinatorBlue" : "bg-taskinatorMedGrey"} bg-taskinatorBlue text-taskinatorWhite h-10 w-full mt-2`}
        disabled={!inputState.isValid}
        isLoading={initState[0].started}
        error={initState[0].error}
      />

      {fileSet && (
        <Button
          onClick={removeFileHandler}
          label={fileSet?.archived === true ? "Undo Archiving" : "Archive File"}
          customClasses={`${fileSet.inJob.length ? "cursor-not-allowed" : ""} border-taskiantorRed opacity-75 hover:opacity-100 text-taskinatorRed border h-10 w-full mt-2 text-sm`}
          isLoading={initState[1].started}
          disabled={initState[1].started || fileSet.inJob.length}
        />
      )}
    </div>
  ) : (
    <div className={`w-full md:w-96 flex flex-col bg-taskinatorWhite p-4 rounded-0 md:rounded-l-lg h-full`}>
      <BulkFileUpload fileSetId={addFiles.fileSetId} fileSetName={addFiles.fileSetName} onFilesUploaded={onFilesUploaded} SaveLocally />
    </div>
  );
};

export default NewFileSetForm;
