import axios from "axios";
import React, { useContext } from "react";
import { AppContext } from "../../contexts/appContext";
import { useInputV4 } from "../../stateManagement/useInputV4";
import Button from "../UI_elements/button";
import { useInit } from "../hooks/useInit";
import InputV4 from "../inputs/input_V4";
import { VALIDATOR_MINLENGTH, VALIDATOR_NUMBER } from "./validators/validators";
import CurrentPlan from "../../pages/settings/currentPlan";
import HeaderTitle from "../UI_elements/headerTitle";
import { MdOutlineKeyboardDoubleArrowDown } from "react-icons/md";
const UserDetailsForm = ({ done }) => {
  const { initState, initStarted, initCompleted, initErrors } = useInit();

  const { inputState, inputHandler } = useInputV4({
    inputs: { confirmAgree: { value: false, isValid: true } },
    isValid: false,
  });

  const appData = useContext(AppContext);

  const activatePaymentPlan = appData.workspaceDataV4.role === process.env.REACT_APP_ACCESS_O && !appData.workspaceDataV4.planSelected;

  const dataToSubmit = {
    name: inputState?.inputs?.firstName?.value?.toLowerCase(),
    lastName: inputState?.inputs?.lastName?.value?.toLowerCase(),
    organisationName: inputState?.inputs?.organisationName?.value?.toLowerCase(),
    contactNumber: inputState?.inputs?.contactNumber?.value,
    isValid: inputState.isValid,
  };

  const submitHandler = () => {
    initStarted(0);
    axios
      .put(`${process.env.REACT_APP_BACKEND_URL}/api/team/org-details`, dataToSubmit, {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      })
      .then(() => {
        done();
      })
      .catch((err) => {
        console.log(err);
        const errorMessage = err.response && err.response.data ? err.response.data.message : err.message;
        return initErrors(errorMessage, 0);
      });
  };
  
  return (
    <div className="h-full w-full flex items-center justify-center flex-col ">
      <div className="bg-taskinatorBlue text-taskinatorWhite text-lg w-full flex justify-center py-2 items-center grow">
        <div className="">
          {" "}
          <HeaderTitle customCSS="text-taskinatorWhite " />
        </div>
      </div>
      <div className="w-full flex flex-col  h-full  items-center  overflow-auto   ">
        {/* <form className="mx-auto" onSubmit={submitHandler}> */}

        <div className="bg-taskinatorWhite px-10 py-6 md:max-w-6xl w-full rounded-xl shadow justify-center mt-6">
          <div className=" items-center justify-center w-96 flex flex-col mx-auto h-fit">
            <div className="flex w-full ">
              <div className="items-center justify-center mx-auto text-center  text-xl font-bold text-taskinatorDarkGrey">Step 1. Finalise Your Details</div>
            </div>
            <div className=" w-96">
              <div className="pt-2 ">
                <InputV4
                  inputVer="1"
                  label="First Name"
                  sid="inputs"
                  id="firstName"
                  textSize={"text-base"}
                  placeholder={`First name`}
                  customClasses={"rounded-md "}
                  defaultValue={appData?.workspaceDataV4?.firstName}
                  onInput={inputHandler}
                  validators={[VALIDATOR_MINLENGTH(2)]}
                  error={"Minimum 2 characters"}
                />
              </div>
              <div className="pt-2 ">
                <InputV4
                  inputVer="1"
                  label="Last Name"
                  textSize={"text-base"}
                  sid="inputs"
                  id="lastName"
                  placeholder={`Last name`}
                  customClasses={"rounded-md "}
                  defaultValue={appData?.workspaceDataV4?.lastName}
                  onInput={inputHandler}
                  validators={[VALIDATOR_MINLENGTH(2)]}
                  error={"Minimum 2 characters"}
                />
              </div>
            </div>

            <div className=" w-96">
              {!appData?.workspaceDataV4?.orgName && (
                <div className="pt-2  ">
                  <InputV4
                    inputVer="1"
                    label="Company Name"
                    textSize={"text-base"}
                    sid="inputs"
                    id="organisationName"
                    placeholder={`Company Name...`}
                    customClasses={"rounded-md "}
                    defaultValue={appData?.workspaceDataV4?. orgName}
                    onInput={inputHandler}
                    validators={[VALIDATOR_MINLENGTH(2)]}
                    error={"Minimum 6 characters"}
                  />
                </div>
              )}
              {!appData?.workspaceDataV4?.contactNumber && (
                <div className="pt-2 ">
                  <InputV4
                    inputVer="1"
                    label="Contact number"
                    textSize={"text-base"}
                    sid="inputs"
                    id="contactNumber"
                    placeholder={`Contact Number...`}
                    customClasses={"rounded-b-md "}
                    defaultValue={appData?.workspaceDataV4?.contactNumber}
                    onInput={inputHandler}
                    validators={[VALIDATOR_NUMBER(), VALIDATOR_MINLENGTH(6)]}
                    error={"Minimum 3 characters"}
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        {/* <div className="flex justify-center text-taskinatorBlue text-4xl pt-1">
                  <MdOutlineKeyboardDoubleArrowDown />
                  <MdOutlineKeyboardDoubleArrowDown />
                  <MdOutlineKeyboardDoubleArrowDown />
                </div> */}

        {activatePaymentPlan && (
          <div className="bg-taskinatorWhite  md:max-w-6xl w-full px-10 py-6 rounded-xl shadow justify-center  mt-1">
            <div className="flex flex-col">
              <div className="flex w-full  flex-col justify-center items-center ">
                <div className="items-center justify-center mx-auto text-center   text-xl font-bold text-taskinatorDarkGrey">Step 2. Select A Plan</div>
                <div className=" text-center text-md text-taskinatorRed font-medium ">90 Day 100% Money Back Guarantee</div>
                <div className=" text-center text-md text-taskinatorRed font-medium text-wrap ">
                  <b>BONUS:</b> 50% Off your next bill if Next-Task you start using within the first 30 days.
                </div>
              </div>
              <div className="flex justify-center">
                <CurrentPlan plan={appData?.workspaceDataV4?.planSelected} upgradeTriggeredLocally={dataToSubmit} getData={done} />
              </div>
            </div>
          </div>
        )}
        {!activatePaymentPlan && (
          <div>
            <Button
              type="submit"
              customClasses={`${inputState.isValid ? "bg-taskinatorBlue" : "bg-taskinatorMedGrey"} text-taskinatorWhite h-10 w-full my-2`}
              label={"Complete"}
              isLoading={initState[0].started}
              disabled={!inputState.isValid}
              error={initState[0].error}
              onClick={() => submitHandler()}
            />
          </div>
        )}
        {/* </form> */}
      </div>
    </div>
  );
};

export default UserDetailsForm;
