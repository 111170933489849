import React, { useReducer, useContext, useEffect, useState, useCallback } from "react";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

import { AppContext } from "../../../contexts/appContext";
import { useInit } from "../../hooks/useInit";

import TaskWorkflowElementDisplayV4 from "../task/taskWorkflowElementDisplayV4";

import AddWorkflowHandler from "../task/addWorkflowHandler";
import Button from "../../UI_elements/button";
import JobAutoSequenceDisplay from "./jobAutoSequenceDisplay";
const WorkflowValidatorReducer = (state, action) => {
  switch (action.type) {
    case "ADD_WORKFLOW":
      // return { ...state, selectedWorkflows: [...state.selectedWorkflows, action.workflowId] };
      let stateIdGenerator = uuidv4();
      return { ...state, selectedWorkflows: { ...state.selectedWorkflows, [stateIdGenerator]: { ...action.workflow, stateId: stateIdGenerator } } };

    case "REMOVE_WORKFLOW":
      const currentSelectedWorkflows = state.selectedWorkflows;
      const currentActions = state.actions;
      delete currentSelectedWorkflows[action.stateId];
      delete currentActions[action.stateId];
      return { ...state, selectedWorkflows: currentSelectedWorkflows, actions: currentActions };

    case "ADD_ITEMS":
      return { ...state, selectedItems: { ...state.selectedItems, [action.workflowStateId]: action.itemData } };

    case "SET_WORKFLOW_TEMPLATES":
      return { ...state, workflows: action.workflows };

    case "WORKFLOW_SCOPE":
      return { ...state, workflowScope: { ...state.workflowScope, [action.workflowStateId]: action.workflowScope } };

    case "ACTION_CHANGE":
      let newActions;

      if (state.actions[action.stateId]) {
        newActions = {
          ...state.actions,
          [action.stateId]: {
            ...state.actions[action.stateId],
            [action._id]: {
              ...action.actionData,
              _id: action._id,
              isActive: action.isActive,
              stageIndex: action.stageIndex,
              stageUUID: action.stageUUID,
              canApproveItems: action.canApproveItems,
              viewReleased: action.viewReleased,
              viewDraft: action.viewDraft,
              addMoreItems: action.addMoreItems,
              canCreateDraftItems: action.canCreateDraftItems,
              canMarkupItems: action.canMarkupItems,
              name: action.name,
              team: action.team,
              time: action.time,
              thirdParty: action.thirdParty,
              fileTags: action.fileTags,
              completeButton: action.completeButton,
              rejectButton: action.rejectButton,
              milestoneTask: action.milestoneTask,
              toDo: action.toDo,
            },
          },
        };
      } else {
        newActions = { ...state.actions, [action.stateId]: { [action._id]: { ...action.actionData, _id: action._id, isActive: action.isActive, stageIndex: action.stageIndex } } };
      }
      return { ...state, actions: newActions };

    default:
      return state;
  }
};

const JobWorkflowDisplay = ({ globalInputHandler, globalSid, buttonLabel, globalAttachmentHandler, closeModal, fileSetsSelected }) => {
  const appData = useContext(AppContext);
  const { initState, initStarted, initCompleted, initErrors, initReset } = useInit();
  const [taskWorkflowDisplayState, dispatch] = useReducer(WorkflowValidatorReducer, {
    workflows: [],
    selectedWorkflows: {},
    actions: {},
    workflowScope: {},
    selectedItems: {},
  });

  const [excludeList, setExcludeList] = useState({});
  const [usePreset, setUsePreset] = useState();

  useEffect(() => {
    setExcludeList({});
  }, [fileSetsSelected]);

  useEffect(() => {
    globalInputHandler("exclusionList", Object.keys(excludeList ?? {}), true, globalSid);
  }, [excludeList]);

  const workflowSelected = Object.values(taskWorkflowDisplayState.selectedWorkflows).length > 0;

  useEffect(() => {
    const workflows = Object.values(taskWorkflowDisplayState.selectedWorkflows);

    let workflowArray = [];

    if (workflows.length > 0) {
      workflowArray = workflows.map((workflow) => {
        let actions;

        let firstAction;
        const stateId = workflow.stateId;
        const isActions = taskWorkflowDisplayState.actions[stateId];
        const workflowScope = taskWorkflowDisplayState.workflowScope[stateId];

        if (isActions) {
          Object.values(isActions)
            .filter((actions) => actions.isActive == true)
            .map((item) => {
              if (firstAction) {
                if (item.stageIndex < firstAction.index) {
                  firstAction = { index: item.stageIndex, id: item._id };
                }
              } else {
                firstAction = { index: item.stageIndex, id: item._id };
              }
              actions = {
                ...actions,
                [item._id]: {
                  scope: item.scope,
                  assignee: item.assignee,
                  stageUUID: item.stageUUID,
                  canApproveItems: item.canApproveItems,
                  viewReleased: item.viewReleased,
                  viewDraft: item.viewDraft,
                  thirdParty: item.thirdParty,
                  addMoreItems: item.addMoreItems,
                  canCreateDraftItems: item.canCreateDraftItems,
                  canMarkupItems: item.canMarkupItems,
                  fileTags: item.fileTags,
                  completeButton: item.completeButton,
                  rejectButton: item.rejectButton,
                  milestoneTask: item.milestoneTask,
                  time: item.time,
                  stageIndex: item.stageIndex,
                  team: item.team,
                  _id: item._id,
                  name: item.name,
                  toDo: item.toDo,
                },
              };
            });
        }

        return {
          wfid: { _id: workflow._id, name: workflow.name, managers: workflow.managers },
          tasks: actions ? actions : null,
          firstAction: firstAction ? firstAction.id : null,
          workflowScope: workflowScope ? workflowScope : "",
        };
      });
    }

    let actionsValid = false;

    actionsValid =
      workflowArray[0] && workflowArray[0].tasks
        ? Object.values(workflowArray[0].tasks).every(
            (action) =>
              (action?.toDo?.length === 0 && action?.assignee?.length > 0) || (action?.toDo?.length > 0 ? action.toDo.every((todo) => todo.assignee.length > 0 && todo.duration !== undefined) : false)
          )
        : true;

    //  actionsValid = actionsValid && !Object.values(workflowArray?.[0]?.tasks ?? {}).find((task) => isNaN(task.time));

    globalInputHandler("workflow", workflowArray, workflowArray.length > 0 && actionsValid ? true : false, globalSid);
  }, [taskWorkflowDisplayState, dispatch]);

  const [modal, setModal] = useState();

  const sendRequest = () => {
    initStarted(0);
    axios
      .get(process.env.REACT_APP_BACKEND_URL + `/api/workflow/workflows/valid`, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      .then((response) => {
        let workflowObject = {};
        response.data.workflows.map((workflow) => {
          workflowObject = { ...workflowObject, [workflow._id]: workflow };
        });
        dispatch({ type: "SET_WORKFLOW_TEMPLATES", workflows: workflowObject });
        initCompleted(0);
      })
      .catch((err) => {
        initErrors(err?.response?.data?.message ?? err?.message ?? "", 0);
      });
  };

  useEffect(() => {
    sendRequest();
  }, [appData.workspaceDataV4]);

  const addWorkflowHandler = (workflow) => {
    dispatch({
      type: "ADD_WORKFLOW",
      workflow: workflow,
    });
    setModal();
  };

  const addManagerNotesHandler = useCallback((value, workflowData) => {
    dispatch({
      type: "MANAGER_NOTES",
      workflowId: workflowData._id,
      notesForManager: value,
    });
  }, []);

  const selectedWorkflows = taskWorkflowDisplayState.selectedWorkflows;

  return (
    <>
      <div className="w-full flex justify-center flex flex-col  items-center ">
        <div className=" pb-4 w-full flex flex-col  h-auto">
          {selectedWorkflows &&
            Object.values(selectedWorkflows).map((workflowState, index) => {
              return (
                <TaskWorkflowElementDisplayV4
                  key={workflowState.stateId}
                  stateId={workflowState.stateId}
                  actions={taskWorkflowDisplayState.actions}
                  workflowScope={taskWorkflowDisplayState.workflowScope}
                  workflowData={taskWorkflowDisplayState.workflows[workflowState._id]}
                  globalDispatch={dispatch}
                  globalInputHandler={globalInputHandler}
                  globalAttachmentHandler={globalAttachmentHandler}
                  globalSid={globalSid}
                  itemsAlreadySelected={taskWorkflowDisplayState.selectedItems}
                  fileSets={fileSetsSelected}
                  setExcludeList={setExcludeList}
                  excludeList={excludeList}
                />
              );
            })}
          {usePreset && (
            <JobAutoSequenceDisplay
              fileSets={fileSetsSelected}
              setUsePreset={setUsePreset}
              globalInputHandler={globalInputHandler}
              globalSid={globalSid}
              usePreset={usePreset}
              setExcludeList={setExcludeList}
              excludeList={excludeList}
            />
          )}
        </div>
        <div className=" w-full justify-center items-center flex">
          {!workflowSelected && !usePreset && (
            <>
              <AddWorkflowHandler
                globalDispatch={{
                  workflowList: taskWorkflowDisplayState.workflows,
                  selectedWorkflowIds: Object.values(selectedWorkflows).map((workflow) => {
                    return workflow.wfid;
                  }),
                  dispatch: (data) => dispatch(data),
                  addWorkflowHandler: addWorkflowHandler,
                }}
                buttonLabel={buttonLabel ? buttonLabel : "Select One"}
                closeModal={closeModal}
              />
              {/* <Button
                onClick={() => {
                  setUsePreset(true);
                }}
                label="Use Pre-Sets"
                customClasses={"border-taskinatorGreen border text-taskinatorGreen  hover:text-taskinatorWhite hover:bg-taskinatorGreen  text-xs  mx-1 py-2 "}
              /> */}

              <div className="pl-1 text-taskinatorRed capitalize font-normal text-sm">(Required)</div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default JobWorkflowDisplay;
