import React, { useState } from "react";
import CountUpTimer from "../../../utils/countUpTimer";
import FilterOutUniqueIds from "../../../utils/filterUniqueIds";
import LinkifyText from "../../../utils/linkifyText";
import DateDisplay from "../../UI_elements/dateDisplay";
import DocumentDisplay from "../../UI_elements/documentDisplay";
import IconSpinner from "../../UI_elements/iconSpinner";
import Modal from "../../UI_elements/modal";
import Tag from "../../UI_elements/tag";
import UserDisplayBubble from "../../UI_elements/userDisplayBubble";
import JobDetailsForm from "../../forms/jobDetailsForm";
import WatchTask from "../task/watchTask";

const ActionJobDetailsDisplay = ({
  data,
  getData,
  simpleView,
  setViewOtherWorkflows,
  viewOtherWorkflows,
  isManager,
  isCompleted,
  tracerId,
}) => {
  const [editTaskDetails, setEditTaskDetail] = useState();

  if (data) {
    return (
      <>
        <div className="flex grow w-full lg:flex-row flex-col">
          <div className="flex md:mr-1 rounded-lg border p-2 lg:w-1/4 border-taskinatorMedGrey bg-taskinatorWhite">
            <div className="flex flex-col w-full flex-wrap grow">
              <div className="flex items-start py-1 mb-1 justify-start grow border-b-2 border-b-taskinatorLightGrey">
                <div className="text-taskinatorDarkGrey pr-1 md:text-sm">
                  Tag/s:
                </div>
                <div className="font-semibold">
                  <div className="w-full flex flex-wrap">
                    {data.taskTags.length > 0 ? (
                      data.taskTags.map((tag, index) => {
                        return (
                          <div className="md:text-sm" key={index}>
                            <Tag key={tag._id} tagData={tag} />
                          </div>
                        );
                      })
                    ) : (
                      <div className="text-taskinatorMedGrey md:text-sm whitespace-nowrap">
                        None Set
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {!simpleView && (
                <div className="flex items-start py-1 mb-1 justify-start grow border-b-2 border-b-taskinatorLightGrey">
                  <div className="text-taskinatorDarkGrey pr-1 md:text-sm">
                    Manager/s:
                  </div>
                  <div className="font-semibold">
                    <div className="grow flex flex-wrap">
                      {data.managers && data.managers.length > 0 ? (
                        data.managers &&
                        data.managers.map((manager, index) => {
                          return (
                            <UserDisplayBubble
                              firstName={manager.firstName}
                              lastName={manager.lastName}
                              user={manager}
                              textSize={"md:text-xs"}
                              key={index}
                            />
                          );
                        })
                      ) : (
                        <div className="text-taskinatorMedGrey md:text-sm whitespace-nowrap">
                          None Set
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}

              {!simpleView && (
                <div className="flex items-start py-1 mb-1 justify-start border-b-2 border-b-taskinatorLightGrey">
                  <div className="text-taskinatorDarkGrey pr-1 md:text-sm">
                    Due Date:
                  </div>
                  <div className="md:text-sm">{DateDisplay(data.dueDate)}</div>
                </div>
              )}
              {!simpleView && (
                <div className="flex items-start py-1 mb-1 justify-start grow border-b-2 border-b-taskinatorLightGrey">
                  <div className="text-taskinatorDarkGrey pr-1 md:text-sm">
                    Priority:
                  </div>
                  <div className="md:text-sm"> {data.priority}</div>
                </div>
              )}

              {!simpleView && data.createdBy && (
                <div className="flex items-start py-1 mb-1 justify-start grow border-b-2 border-b-taskinatorLightGrey">
                  <div className="text-taskinatorDarkGrey pr-1 md:text-sm">
                    Created By:
                  </div>
                  <div className="md:text-sm capitalize">
                    {data.createdBy.split(" ")[0]}{" "}
                    {data.createdBy.split(" ")[1][0]}
                  </div>
                </div>
              )}
              {!simpleView && data.createdDate && (
                <div className="flex items-start py-1 mb-1 justify-start grow border-b-2 border-b-taskinatorLightGrey">
                  <div className="text-taskinatorDarkGrey pr-1 md:text-sm">
                    Active For:
                  </div>
                  <div className="text-base md:text-sm">
                    {<CountUpTimer startDate={data.createdDate} />}
                  </div>
                </div>
              )}
              {!simpleView && (
                <div className="flex grow justify-end mb-1">
                  <div className="flex items-start py-1 mb-1 justify-start grow border-b-2 border-b-taskinatorLightGrey">
                    <div className="text-taskinatorDarkGrey pr-1 md:text-sm">
                      Watching:
                    </div>
                    <div className="flex flex-wrap grow items-center">
                      {data.watchers && data.watchers.length > 0 ? (
                        data.watchers &&
                        FilterOutUniqueIds(data.watchers).map(
                          (watcher, index) => {
                            return (
                              <UserDisplayBubble
                                firstName={watcher.firstName}
                                lastName={watcher.lastName}
                                user={watcher}
                                textSize={"md:text-xs"}
                                key={index}
                              />
                            );
                          }
                        )
                      ) : (
                        <div className="text-taskinatorMedGrey whitespace-nowrap md:text-sm pr-1">
                          None...
                        </div>
                      )}
                      <div className="">
                        <WatchTask
                          taskDetails={data}
                          getData={() => getData()}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="grow lg:mt-0 mt-1  rounded-lg border p-2  border-taskinatorMedGrey bg-taskinatorWhite flex flex-col">
            <div className="flex flex-col grow">
              <div className="flex flex-col grow h-auto">
                <div className="text-taskinatorDarkGrey font-semibold whitespace-nowrap text-sm pb-2">
                  Job Scope Notes:
                </div>
                <div className="text-base md:text-sm whitespace-pre-wrap  h-fit grow">
                  {data.scope.length > 0 ? (
                    <LinkifyText text={data.scope} />
                  ) : (
                    <div className="text-taskinatorMedGrey whitespace-nowrap md:text-sm">
                      No Scope Details
                    </div>
                  )}
                </div>
              </div>
              <div className="flex  flex-col pt-1 border-t-2 border-t-taskinatorLightGrey py-2 mt-2">
                <div className="text-taskinatorDarkGrey pr-1  text-sm font-semibold whitespace-nowrap">
                  Job Reference Attachments:
                </div>
                <div className="text-base  md:text-sm flex flex-wrap">
                  {" "}
                  {data.attachments.length > 0 ? (
                    data.attachments.map((attachment) => {
                      return (
                        <div className="w-fit   md:text-sm pr-1">
                          <DocumentDisplay attachment={attachment} />
                        </div>
                      );
                    })
                  ) : (
                    <div className="text-taskinatorMedGrey whitespace-nowrap md:text-sm">
                      No Attachments
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {editTaskDetails && (
          <Modal local={() => setEditTaskDetail()} title="Edit Job Details">
            <div className="md:w-96 w-full">
              <JobDetailsForm
                details={data}
                tracerId={tracerId}
                closeModal={() => setEditTaskDetail()}
                onSuccess={() => getData()}
              />
            </div>
          </Modal>
        )}
      </>
    );
  } else {
    return (
      <div>
        <IconSpinner />
      </div>
    );
  }
};

export default ActionJobDetailsDisplay;
