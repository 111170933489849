import React from "react";

const ItemStatus = ({ status, whiteOverride, blackOverwrite }) => {
  let statusStyle;

  const currentStatus = status ? status.toLowerCase() : ""

  if (["draft"].includes(currentStatus)) {
    statusStyle = " border text-taskinatorWhite border-taskinatorDarkGrey bg-taskinatorDarkGrey";
  }
  if (["released"].includes(currentStatus)) {
    statusStyle = " border-taskinatorGreen text-taskinatorWhite bg-taskinatorGreen ";
  }
  if (["partially approved", "partial"].includes(currentStatus)) {
    statusStyle = " border-taskinatorOrange  text-taskinatorWhite bg-taskinatorOrange ";
  }
  if (["superseded", "archived"].includes(currentStatus)) {
    statusStyle = " border-taskinatorRed text-taskinatorWhite bg-taskinatorRed opacity-50";
  }
  if (currentStatus === "obsolete") {
    statusStyle = "border-taskinatorMedGrey text-taskinatorWhite  bg-taskinatorMedGrey ";
  }

  if(whiteOverride){
    statusStyle = "border-taskinatorWhite  text-taskinatorWhite"
  }

  if(blackOverwrite){
    statusStyle = "border-taskinatorDarkGrey text-taskinatorDarkGrey"
  }

  return <div className={`${statusStyle} border rounded px-2 text-center  capitalize my-auto  `}>{status}</div>;
};

export default ItemStatus;
