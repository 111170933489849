export const documentName = "asset";
export const jobName = "job";

export const previewFileExtensions = [".jpg", ".jpeg", ".png", ".gif", ".bmp", ".tiff", ".pdf"];

export const JOB_WEIGHT = {
  priority: 10,
  dueDate: -2,
  estimatedTime: 2,
};

export const JOB_PRIORITY_RATING = {
  low: 1,
  Standard: 4,
  High: 7,
  Critical: 20,
};

export const NAME_FOR_SEQUENCE = "workflow"
