import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import IconSpinner from "../../../components/UI_elements/iconSpinner";
import { useInit } from "../../../components/hooks/useInit";
import { AppContext } from "../../../contexts/appContext";
import { useInputV4 } from "../../../stateManagement/useInputV4";
import Button from "../../UI_elements/button";
import Modal from "../../UI_elements/modal";
import StandardToggle from "../../UI_elements/standardToggle";
import NewItemForm from "../../forms/newItemForm";
import { VALIDATOR_MINLENGTH } from "../../forms/validators/validators";
import InputV4 from "../../inputs/input_V4";
import CreateFileSet from "../files/createFileSet";
import FileRegisterListElement from "../files/fileRegisterListElement";
import { FileContext } from "./FileContext";
import CreateFile from "./createFile";
import FileSetRegisterListElement from "./fileSetRegisterListElement";
import LowerCaseTrim from "../../../utils/lowerCaseAndTrim";
import BulkFileEditor from "./bulkFileEditor";
import { set } from "lodash";

const FilesGetAndSearchContainer = ({
  searchAndAdd,
  setEditButton,
  itemsOnTask,
  selectableItemsOnTask,
  setItemModal,
  addItemHandler,
  globalSetItems,
  wfid,
  isRegister,
  limitSelection,
  isLoading,
  error,
  label,
  tid,
  files,
  disableDetails,
  dontRetainSelection,
  limitedSelectionOverRide,
  hideFiles,
  isthisWorking,
  hideArchived,
  newJob,
  fileSetExclusions,
}) => {
  const appData = useContext(AppContext);
  const numberOfItemsToGet = 100;
  const itemsViewBeforeAdding = 10;
  const { initState, initStarted, initCompleted, initErrors, initReset } = useInit(2);
  const { inputState, inputHandler } = useInputV4({
    inputs: { search: { value: "", isValid: false } },
    isValid: false,
  });

  const [fileSetFilter, setFileSetFilter] = useState("");
  const [fileFilter, setFileFilter] = useState("");

  // const { tid } = useParams();
  const { fileSetId, focusName } = useParams();

  const [modal, setModal] = useState();

  const [itemsOnList, setItemsOnList] = useState();
  const [numberOfItems, setNumberOfItems] = useState(numberOfItemsToGet);
  const [isSingle, setIsSingle] = useState(fileSetId ? true : false);
  const [itemsSelected, setItemsSelcted] = useState(selectableItemsOnTask ?? {});
  const [fileSelection, setFileSelection] = useState();
  const [bulkFileSelection, setBulkFileSelection] = useState();

  const [filesDeleted, setFilesDeleted] = useState({});
  const [itemList, setItemList] = useState({});
  const [bulkEditEnabled, setBulkEditEnabled] = useState();
  // const limitedSelectionOverRide = fileSetId ? false : limitSelection;
  // const dontRetainSelection = fileSetId ? false : true;

  const inFileSetView = Object.keys(itemsSelected).length > 0;
  const [wasSelected, setWasSelected] = useState(inFileSetView);

  
  
  useEffect(() => {
    if (inFileSetView) {
      if (!wasSelected) {
        inputHandler("search", "", true, "inputs");
        setWasSelected(true);
      } else {
        setFileFilter(inputState.inputs.search.value);
      }
    } else {
      if (wasSelected) {
        console.log(fileSetFilter, "fileSetDetails");
        inputHandler("search", fileSetFilter, true, "inputs");
        setWasSelected(false);
      }
      console.log("setting file set filter", inputState.inputs.search.value);
      setFileSetFilter(inputState.inputs.search.value);
    }
  }, [inputState.inputs.search.value, inFileSetView]);



  const onBulkSelection = (selectedId, isChecked, selectionData, fileSetId) => {
    let newBulkLength = Object.values(bulkFileSelection ?? {}).length;

    if (isChecked) {
      setBulkFileSelection((prevSelected) => ({
        ...prevSelected,
        [selectedId]: selectionData, // Add the selected ID
      }));

      newBulkLength++;
    } else {
      setBulkFileSelection((prevSelected) => {
        const newSelection = { ...prevSelected }; // Create a new object
        delete newSelection[selectedId]; // Remove the ID
        return newSelection; // Return the new state
      });

      newBulkLength--;
    }
    if (newBulkLength > 1 || newBulkLength === 0) {
      setFileSelection();
    } else if (newBulkLength > 0) {
      setFileSelection({ file: selectionData, fileSetId: fileSetId });
    } else {
    }
  };

  useEffect(() => {}, [bulkFileSelection]);

  const onSelectHandler = (itemRowData, itemState) => {
    setFileSelection();

    if (itemsSelected && itemsSelected[itemRowData._id]) {
      const currentItems = itemsSelected;
      delete currentItems[itemRowData._id];
      setItemsSelcted({ ...currentItems });
    } else {
      if (dontRetainSelection) {
        setItemsSelcted({ [itemRowData._id]: itemRowData });
      } else {
        if (limitedSelectionOverRide && Object.values(itemsSelected).length < limitedSelectionOverRide) {
          setItemsSelcted({ ...itemsSelected, [itemRowData._id]: itemRowData });
        } else if (!limitedSelectionOverRide) {
          setItemsSelcted({ ...itemsSelected, [itemRowData._id]: itemRowData });
        }
      }
    }
  };

  // useEffect(() => {
  //   if (Object.values(itemsSelected ?? {}).length === 0) {
  //     inputHandler("search", "", true, "inputs");
  //   }
  // }, [itemsSelected]);

  const onCompleteHandler = () => {
    if (globalSetItems) {
      globalSetItems(itemsSelected);
    } else {
      addItemHandler(itemsSelected);
    }
  };

  //// this can be deleted once we do not need to add items in item manager
  const onResponse = () => {
    setModal();
    sendRequest();
    inputHandler("search", "", true, "inputs");
  };

  //// this auto searches
  useEffect(() => {
    const delay = 500;
    let timer;

    if (!inFileSetView) {
      clearTimeout(timer);

      if (fileSetFilter.trim().length > 0) {
        timer = setTimeout(() => {
          sendRequest(null, "search");
          // seachItems();
        }, delay);
      } else if (fileSetFilter.trim().length === 0) {
        sendRequest();
      }
      return () => {
        clearTimeout(timer);
      };
    }
  }, [fileSetFilter]);

  const sendRequest = (newFileSetId, initId) => {
    initReset(1);
    initStarted(0, initId);

    let url;
    if (tid) {
      url = `available/${numberOfItems}/${tid}/${wfid}/$${inputState.inputs.search.value}`;

      // console.log(`/api/${isSingle ? "file" : "file/focus"}/${url}`, "urlllll");
      axios
        .get(
          process.env.REACT_APP_BACKEND_URL + `/api/${isSingle ? "file" : "file/focus"}/${url}`,

          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        )
        .then((response) => {
          setItemsOnList(response.data.data);
          initCompleted(0);
        })
        .catch((err) => {
          initErrors(err?.response?.data?.message ?? err?.message ?? "", 0);
        });
    } else {
      const isAll = Object.keys(appData.tagFilter)[0] === "all tags";
      url = `initialise/${isAll ? 100 : numberOfItems}/$${inputState.inputs.search.value}${isSingle ? `/${fileSetId}` : ``}`;

      axios
        .put(
          process.env.REACT_APP_BACKEND_URL + `/api/${isSingle ? "file" : "file/focus"}/${url}${newJob ? `/true` : `/false`}`,
          {
            tagFilter: !isAll
              ? Object.values(appData.tagFilter)
                  .map((key) => appData.jobListFilterValues[key])
                  .flat(1)
              : [],
            isAll: isAll,
            disableDetails: disableDetails,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        )
        .then((response) => {
          setItemsOnList(response.data.data);

          if (newFileSetId) {
            response.data.data.map((data) => {
              if (data._id.toString() === newFileSetId) {
                setItemsSelcted({ [data._id]: data });
              }
            });
          }

          initCompleted(0);
        })
        .catch((err) => {
          console.log(err);
          initErrors(err?.response?.data?.message ?? err?.message ?? "", 0);
        });
    }
  };

  useEffect(() => {
    setFileSelection();
    setBulkFileSelection();
    sendRequest();
  }, [numberOfItems, appData.tagFilter, isSingle]);

  return (
    <FileContext.Provider
      value={{
        setItemsOnList,
        itemsSelected,
        setItemsSelcted,
        fileSelection,
        setFileSelection,
        setBulkFileSelection,
        bulkFileSelection,
        sendRequest: () => sendRequest(),
        fetchFilesAgain: () => {},
        filesDeleted,
        setFilesDeleted,
        itemList,
        setItemList,
      }}
    >
      <div className={`w-full h-full flex flex-col overflow-auto`}>
        <div className="w-full flex-col  justify-center items-center px-2 flex bg-taskinatorWhite py-4 shadow">
          <div className="flex w-full items-center justify-center">
            <div className="flex grow justify-start pl-2 font-medium text-taskinatorDarkGrey items-center">
              <div className="pr-4 items-center whitespace-nowrap">{fileSetId ? `${focusName}` : "Files"}</div>
              {!fileSetId && !hideFiles && (
                <div className={` border border-taskinatorMedGrey py-1 px-4 rounded items-center ${inFileSetView ? "hidden" : "flex"}`}>
                  <div className="whitespace-nowrap text-xs pr-2 items-center">By Folder</div>
                  <div className="items-center">
                    <StandardToggle
                      onInput={(data) => {
                        setItemsOnList();
                        setIsSingle(data);
                      }}
                    />
                  </div>
                  <div className="whitespace-nowrap text-xs pl-2 items-center">By File</div>
                </div>
              )}
            </div>

            <div className="flex items-center justify-center w-full">
              <InputV4
                onInput={inputHandler}
                customClasses="text-center border-taskinatorDarkGrey md:w-96 w-[100%]"
                defaultValue={inputState.inputs.search.value}
                placeholder={`Search for files by name, number, extension or filter tag`}
                sid="inputs"
                id="search"
                validators={[VALIDATOR_MINLENGTH(1)]}
              />
              {initState[0].started && initState[0].identifier === "search" && <IconSpinner />}
            </div>
            <div className="flex grow justify-end pr-2">
              <CreateFileSet onResponse={() => {}} onFilesUploaded={sendRequest} />
            </div>
          </div>
        </div>
        <div className=" overflow-auto h-full flex justify-start  flex-col w-full pt-1">
          <div className="flex h-full items-start overflow-auto">
            <div className="flex h-full overflow-auto grow mr-1 flex-col">
              {initState[0].started && !itemsOnList && (
                <div className="my-4 w-full">
                  <IconSpinner size="m" />
                </div>
              )}

              {!initState[0].initalised &&
                itemsOnList &&
                (isSingle ? (
                  <FileRegisterListElement
                    tid={tid}
                    itemData={itemsOnList}
                    setModal={setModal}
                    searchAndAdd={searchAndAdd}
                    itemsSelected={fileSelection}
                    onSelectHandler={setFileSelection}
                    itemsOnTask={itemsOnTask}
                    selectableItemsOnTask={selectableItemsOnTask}
                    isRegister={isRegister}
                    fileSelection={fileSelection}
                    setFileSelection={setFileSelection}
                  />
                ) : (
                  <FileSetRegisterListElement
                    fileSetExclusions={fileSetExclusions}
                    tid={tid}
                    itemData={itemsOnList}
                    setModal={setModal}
                    searchAndAdd={searchAndAdd}
                    itemsSelected={itemsSelected}
                    onSelectHandler={onSelectHandler}
                    itemsOnTask={itemsOnTask}
                    selectableItemsOnTask={selectableItemsOnTask}
                    isRegister={isRegister}
                    fileSelection={fileSelection}
                    setFileSelection={setFileSelection}
                    onFilesUploaded={() => {}}
                    hideFiles={hideFiles}
                    searchValue={fileFilter}
                    onBulkSelection={onBulkSelection}
                    bulkFileSelection={bulkFileSelection}
                    bulkEditEnabled={bulkEditEnabled}
                    setBulkEditEnabled={setBulkEditEnabled}
                    hideArchived={hideArchived}
                  />
                ))}

              {itemsOnList && itemsOnList.length === numberOfItems && (
                <div className="w-full justify-center flex pt-4 pb-2">
                  <Button
                    label={`Load More Files`}
                    customClasses="border border-taskinatorBlue text-taskinatorBlue text-xs md:text-sm py-1"
                    onClick={() => {
                      setNumberOfItems(numberOfItems + 20);
                      inputHandler("search", "", true, "inputs");
                    }}
                  />
                </div>
              )}
            </div>

            {!disableDetails && Object.values(fileSelection ?? {}).length > 0 && Object.values(bulkFileSelection ?? {}).length < 2 && (
              <div className={` bg-taskinatorWhite w-96 h-full border border-taskinatorMedGrey overflow-auto scrollbar `}>
                {fileSelection?.file && <CreateFile file={fileSelection.file} currentFileSetId={fileSelection.fileSetId} onUpdate={() => sendRequest()} />}
              </div>
            )}
            {!disableDetails && Object.values(bulkFileSelection ?? {}).length > 1 && !!Object.values(bulkFileSelection ?? {})[0]?.canEdit && (
              <div className={` bg-taskinatorWhite w-96 h-full border border-taskinatorBlue overflow-auto scrollbar `}>
                <BulkFileEditor bulkFileList={bulkFileSelection} currentFileSetId={Object.values(itemsSelected)?.[0]?._id} onUpdate={() => sendRequest()} />
              </div>
            )}
          </div>
        </div>
      </div>

      {addItemHandler && (
        <div>
          <Button
            onClick={() => {
              onCompleteHandler();
              if (setItemModal) {
                setItemModal();
              }
            }}
            label={label ? label : `Add To Job`}
            customClasses={`my-2 ${
              Object.keys(itemsSelected).length > 0 ? "border-taskinatorBlue bg-taskinatorBlue" : "border-taskinatorMedGrey bg-taskinatorMedGrey"
            } w-full border text-taskinatorWhite hover:text-taskinatorWhite hover:bg-taskinatorBlue  text-base mx-1 py-2 h-10`}
            disabled={Object.keys(itemsSelected).length === 0}
            isLoading={isLoading}
            error={error}
          />
        </div>
      )}
      {modal && (
        <Modal bgColour="bg-taskinatorLightGrey" title={modal.itemData ? "Create Working Version" : `Create New ${appData.itemName}`} local={() => setModal()}>
          <NewItemForm onResponse={onResponse} itemData={modal.itemData} />
        </Modal>
      )}
    </FileContext.Provider>
  );
};

export default FilesGetAndSearchContainer;
