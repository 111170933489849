import React, { useContext, useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { AppContext } from "../../../contexts/appContext";
import { useInit } from "../../hooks/useInit";
import IconSpinner from "../../UI_elements/iconSpinner";
import AddStepDisplayButton from "../addStepDisplayButton";
import { MdClose, MdCheck, MdUpdate, MdCheckCircle, MdPending, MdFiberManualRecord } from "react-icons/md";
import { IoMdCloseCircle } from "react-icons/io";
import UserDisplayBubble from "../../UI_elements/userDisplayBubble";
import DateDisplay from "../../UI_elements/dateDisplay";
const ActionNextStepsDisplayV2 = ({
  tracer,
  aidDetails,
  canSubmitBack,
  setNextStep,
  setBackStep,
  setActionPlanLoaded,
  workflowUpdateTrigger,
  displayOnly,
  noAssignee,
  thirdParty,
  actionPlan,
  activeTracerId,
}) => {
  const appData = useContext(AppContext);

  const { initState, initStarted, initCompleted, initErrors, initReset } = useInit(2);


  useEffect(() => {
    if (actionPlan && !displayOnly) {
      let next = {};
      let back = {};

      actionPlan.map((stage) => {
        stage.stages.map((action) => {
          if (action.isNext === true) {
            if (stage.stageType === "action") {
              next = {
                ...next,
                [action.tracerIdMatch]: {
                  _id: action.tracerIdMatch,
                  stageId: action.stageId,
                  rejectedStatus: action.rejectedStatus,
                  approvedStatus: action.approvedStatus,
                  assignee: action.assignee,
                  name: action.name,
                  thirdParty: action.thirdParty,
                  thirdPartyAccessCode: action.thirdPartyAccessCode,
                },
              };
            } else {
              next = {
                ...next,
                [action.workflowId]: {
                  _id: action.workflowId,
                  name: action.name,
                },
                ["workflow"]: true,
              };
            }
          }

          if (action.isBack === true) {
            back = {
              ...back,
              [action.tracerIdMatch]: {
                _id: action.tracerIdMatch,
                stageId: action.stageId,
                rejectedStatus: action.rejectedStatus,
                approvedStatus: action.approvedStatus,
                assignee: action.assignee,
                name: action.name,
                thirdParty: action.thirdParty,
                thirdPartyAccessCode: action.thirdPartyAccessCode,
              },
            };
          }
        });
      });

      setNextStep(next);
      setBackStep(back);
    }
  }, [actionPlan, tracer]);

  return (
    <div className="flex flex-row ">
      {!actionPlan && initState[0].started && (
        <div className="w-full flex items-center justify-center h-full grow">
          <IconSpinner size={"m"} />
        </div>
      )}

      {!actionPlan && initState[0].error && <div className="text-xs text-taskinatorRed text-center">{/* {initState[0].error} */}</div>}
      {!tracer?.aid?._id && !tracer.archived && (
        <div className="px-2 py-2 items-center flex justify-center">
          <div className="flex bg-taskinatorRed rounded-lg py-1 font-medium px-2 text-taskinatorWhite  text-sm uppercase ">
            Please Set Workflow
            {!noAssignee && (
              <div className=" flex justify-center pl-2">
                <div className="flex items-center text-sm w-full justify-start  ">
                  {tracer.assignee && tracer.assignee.length > 0 ? (
                    tracer.assignee.map((assignee, assigneeIndex) => {
                      return (
                        <UserDisplayBubble
                          key={assigneeIndex}
                          firstName={assignee.firstName}
                          lastName={assignee.lastName}
                          user={assignee}
                          textSize={"text-xxs"}
                          colourOverride={"bg-taskinatorWhite text-taskinatorRed"}
                        />
                      );
                    })
                  ) : (
                    <div className=" text-taskinatorWhite whitespace-nowrap text-xxs border border-taskinatorWhite rounded py-0.5 px-1">No Assignee</div>
                  )}
                </div>{" "}
              </div>
            )}
          </div>{" "}
        </div>
      )}
      <div className="flex flex-row  overflow-auto grow px-2 py-1 items-center xl:h-full h-fit scrollbar bg-clip-border">
        {actionPlan &&
          !initState[0].started &&
          actionPlan.map((stage, stageIndex) => {

        
            {
              if (stage.stageType === "action") {
                return (
                  <div key={stage.stageId} className="flex items-center flex-row ">
                    <div className="flex flex-col   justify-center ">
                      {stage.stages.map((action, actionIndex) => {
                           
                        return (
                          <div key={`action-${actionIndex}`} className={"flex flex-col justify-center lg:mr-0 mr-4 items-center  my-2 cursor-pointer hover:scale-110"}>
                            {/* {tracer._id.toString() === activeTracerId && <div className="mx-2 border border-taskiantorGreen text-taskinatorGreen font-medium rounded py-1 px-2">Currently Selected</div>} */}
                            <div
                              onClick={() => {}}
                              className={` border rounded-lg shadow by px-2 py-1 text-xs flex flex-col w-fit 
                        ${
                          action.isCurrent
                            ? " text-taskinatorWhite bg-taskinatorGreen border-taskinatorGreen border-2 "
                            : ` border-taskinatorMedGrey text-taskinatorMedGrey  ${action.isNext || (action.isBack && canSubmitBack) ? "" : "opacity-75"}`
                        }
                        `}
                            >
                              <div className="flex justify-start">
                                <div className="flex items-center  w-full">
                                  <div className="justify-center flex">
                                    <div className=" flex w-full  items-center ">
                                      {" "}
                                      <div className="font-semibold text-sm uppercase  text-center pr-2 whitespace-nowrap"> {action.name} </div>
                                    </div>
                                    {!noAssignee && (
                                      <div className=" flex justify-center ">
                                        <div className="flex items-center text-sm w-full justify-start  ">
                                          {action.assignee && action.assignee.length > 0 ? (
                                            action.assignee.map((assignee, assigneeIndex) => {
                                              return (
                                                <UserDisplayBubble
                                                  key={`assignee-${assigneeIndex}`}
                                                  firstName={assignee.firstName}
                                                  lastName={assignee.lastName}
                                                  user={assignee}
                                                  textSize={"text-xxs"}
                                                  colourOverride={action.isCurrent ? "bg-taskinatorWhite text-taskinatorGreen" : "bg-taskinatorMedGrey text-taskinatorDarkGrey opacity-50"}
                                                />
                                              );
                                            })
                                          ) : (
                                            <div className=" text-taskinatorMedGrey whitespace-nowrap text-xxs border rounded py-0.5 px-1">No Assignee</div>
                                          )}

                                          {/* {action.isCurrent && <div className={`pl-1 xs text-taskinatorWhite  `}>(Current)</div>} */}
                                          {/* <div className=" pr-2 text-taskinatorDarkGrey">Assignees:</div> */}
                                        </div>

                                        {(action.approvedStatus || action.rejectedStatus) && (
                                          <div className={`  h-full text-xs items-center flex opacity-50 ${action.approvedStatus ? "text-taskinatorGreen" : "text-taskinatorRed"}`}>
                                            {action.approvedStatus ? "Completed" : "Rejected"}
                                          </div>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>

                    {stage.stages.length > 0 && stageIndex < actionPlan.length - 2 && (
                      <div className="block">
                        <AddStepDisplayButton notClickable />
                      </div>
                    )}
                    {stage.stages.length > 0 && stageIndex < actionPlan.length - 1 && (
                      <div className="hidden">
                        <AddStepDisplayButton notClickable rotate />
                      </div>
                    )}
                  </div>
                );
              }
            }
          })}
        {actionPlan && tracer.tracerType === "workflow" && tracer.completed && (
          <div className="flex items-center justify-end grow text-taskinatorGreen ">
            <MdCheckCircle size={"2rem"} />
            <div className="px-2 justify-center flex flex-col text-sm font-medium">
              <div>Completed </div> <div>{DateDisplay(tracer.completedDate)}</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ActionNextStepsDisplayV2;
