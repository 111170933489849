import { useInit } from "./useInit";
import { UploadFiles } from "../../utils/fileUploaderFunctions";
import axios from "axios";
import { useState } from "react";

export const useUploader = (type) => {
  
    const saveHandler = async ({ attachment, fileSetId }) => {
    return new Promise(async (resolve, reject) => {
      let response;

      try {
        const returnedData = await axios.put(
          process.env.REACT_APP_BACKEND_URL + `/api/attachment/attach/upload`,
          { fileName: attachment.value.fileName, fileKey: attachment.value.key, type: type || "document", fileSetId: fileSetId },

          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        );

        response = returnedData.data;

        console.log(response, "response");

        switch (response.driveOption) {
          case "GOOGLE":
            break;

          case "MICROSOFT":
            break;

          default:
            //upload to aws
            await axios.put(response.signedUrl, attachment, {
              headers: {
                "Content-Type": attachment.type,
                "Content-Disposition": "inline",
              },
            });

            // confirm upload on server
            await axios.put(
              process.env.REACT_APP_BACKEND_URL + `/api/attachment/attach/success`,
              {
                key: response.key,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                },

                withCredentials: true,
              }
            );
        }

        resolve();
      } catch (err) {
        console.log(err);
        await axios.put(
          process.env.REACT_APP_BACKEND_URL + `/api/attachment/attach/error`,
          {
            key: response.key,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },

            withCredentials: true,
          }
        );
        reject(err?.response?.data?.message ?? err?.message ?? "");
      }
    });
  };

  const saveTrigger = async ({ attachments, fileSetId }) => {
    
    return new Promise(async (resolve, reject) => {
      let report = {};

      for (const attachment of attachments) {
        console.log(attachment);
        try {
          await saveHandler({ attachment: attachment });
          report = { ...report, [attachment.value.key]: "success" };
        } catch (err) {
          console.log(err);
          report = { ...report, [attachment.value.key]: "error" };
        }
      }

      resolve(report);
    });
  };

  return [saveTrigger];
};
