import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { MdCheck, MdClose } from "react-icons/md";
import { v4 as uuidv4 } from "uuid";
import { AppContext } from "../../contexts/appContext";
import { useInputV4 } from "../../stateManagement/useInputV4";
import { deepEqual } from "../../utils/deepEqual";
import ToolTip from "../../utils/toolTip";
import { useTimeDisplay } from "../../utils/useTimeDisplay";
import DurationSelector from "../dataSelectors/durationSelector";
import MemberSelector from "../dataSelectors/memberSelector";
import PrioritySelector from "../dataSelectors/prioritySelector";
import BulkFileUpload from "../displayElements/files/bulkFileUpload";
import { JobModalContext } from "../displayElements/jobs/jobModalContext";
import TaskCommentDisplay from "../displayElements/task/taskCommentDisplay";
import { useInit } from "../hooks/useInit";
import InputV4 from "../inputs/input_V4";
import Button from "../UI_elements/button";
import DateDisplay from "../UI_elements/dateDisplay";
import Modal from "../UI_elements/modal";
import StringShortenDisplay from "../UI_elements/stringShortenDisplay";
import UserDisplayBubble from "../UI_elements/userDisplayBubble";
import ApproveRejectForm from "./approveRejectForm";
import { VALIDATOR_MINLENGTH } from "./validators/validators";

const NewInstructionRow = ({ toDoData, setToDos, onSave, inJob, jobData }) => {
  const { inputState, inputHandler, attachmentHandler } = useInputV4({
    inputs: {},
    isValid: false,
  });
};

const NewInstructionForm = ({
  setToDos,
  toDoData,
  onSave,

  label,
  inJob,
  nonModal,
  availableTeam,
  defaultAsForm,
  jobData,
  taskUUID,
  appData,
  isPreset,
  setFileIdsInTask,
  JobModalContext,
}) => {
  const { inputState, inputHandler, attachmentHandler } = useInputV4({
    inputs: {},
    isValid: false,
  });
  const { initState, initStarted, initCompleted, initErrors, initReset } = useInit(4);
  const idToUser = toDoData?._id ?? uuidv4();

  const isNew = !toDoData?._id;
  const [hover, setHover] = useState(false); // State to manage hover status
  const [completed, setCompleted] = useState(toDoData.completed);
  const [comments, setComments] = useState(toDoData?.comments ?? []);
  const [seen, setSeen] = useState(toDoData?.seen ?? []);
  const [confirmDelete, setConfirmDelete] = useState();
  const seenByMe = !isNew && seen.find((user) => user._id === appData.workspaceDataV4.user_id);



  const taskData = {
    _id: idToUser,
    tempId: uuidv4(),
    title: inputState?.inputs?.title?.value,
    details: inputState?.inputs?.details?.value,
    attachments: Object.values(inputState?.attachments ?? {}).map((attachment) => {
      return {
        key: attachment?.value?.key,
        fileName: attachment?.value?.fileName,
        fileExtension: attachment?.value?.fileExtension,
        _id: attachment.value._id,
      };
    }),
    priority: inputState?.inputs?.priority?.value,
    // complexity: inputState.inputs.complexity.value,
    dueDate: inputState?.inputs?.dueDate?.value ?? null,
    duration: inputState?.inputs?.duration?.value ?? 1,
    assignee: inputState?.inputs?.assignee?.value ?? [],
    currentJobTaskUUID: taskUUID,
  };

  // useEffect(() => {
  //   if (!seenByMe && !isNew && !toDoData.closed) {
  //     axios
  //       .post(
  //         process.env.REACT_APP_BACKEND_URL + `/api/job/task/seen/${toDoData?._id}/${jobData.jobId}`,
  //         {},
  //         {
  //           headers: {
  //             "Content-Type": "application/json",
  //             Authorization: "token ",
  //           },
  //           withCredentials: true,
  //         }
  //       )
  //       .then((response) => {
  //         setSeen((current) => [...current, { _id: appData.workspaceDataV4.user_id, firstName: appData.workspaceDataV4.firstName, lastName: appData.workspaceDataV4.lastName }]);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         const errorMessage = err?.response?.data?.message ?? err?.message ?? "";
  //       });
  //   }
  // }, []);

  const CompleteHandler = () => {
    initStarted(1);
    axios
      .get(process.env.REACT_APP_BACKEND_URL + `/api/job/task/completed/${toDoData?._id}/${jobData.jobId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "token ",
        },
        withCredentials: true,
      })
      .then((response) => {
        initCompleted(1);
        setCompleted(!completed);
      })
      .catch((err) => {
        console.log(err);
        const errorMessage = err?.response?.data?.message ?? err?.message ?? "";
        return initErrors(errorMessage, 1);
      });
  };

  const DeleteHandler = () => {
    initStarted(2);
    axios
      .delete(process.env.REACT_APP_BACKEND_URL + `/api/job/task/delete/${toDoData?._id}/${jobData.jobId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "token ",
        },
        withCredentials: true,
      })
      .then((response) => {
        initCompleted(2);
        jobData?.setSelectedTaskIds({});
        defaultAsForm();
      })
      .catch((err) => {
        console.log(err);
        const errorMessage = err?.response?.data?.message ?? err?.message ?? "";
        return initErrors(errorMessage, 2);
      });
  };

  const onSaveHandler = () => {
    if (!defaultAsForm) {
      setToDos((currentData) => {
        return {
          ...currentData,
          [idToUser]: taskData,
        };
      });
      onSave?.();
    } else {
      initStarted(0);
      axios
        .put(process.env.REACT_APP_BACKEND_URL + `/api/job/task/save/${toDoData?._id}/${jobData.jobId}/${toDoData?.primaryTaskDetails?.workflowGroupId}`, taskData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "token ",
          },
          withCredentials: true,
        })
        .then((response) => {
          initCompleted(0);
          onSave?.();
        })
        .catch((err) => {
          console.log(err);
          const errorMessage = err?.response?.data?.message ?? err?.message ?? "";

          return initErrors(errorMessage, 0);
        });
    }
  };

  return (
    <div className={`${nonModal ? "flex w-full md:flex-row flex-col" : " w-full flex flex-col p-4 h-full grow"}  normal-case`}>
      {!seenByMe && !isNew && !isPreset && (
        <div className="flex grow justify-start h-0 pt-2 z-40">
          <div className="relative -top-2 ml-2 items-center flex h-4 w-fit text-taskinatorWhite text-[0.75rem] bg-taskinatorRed px-1 rounded-full font-semibold">New</div>
        </div>
      )}
      <div className="flex md:flex-row flex-col w-full h-full items-stretch ">
        <div className="grow h-full flex flex-col w-full">
          <div className="bg-taskinatorWhite p-4 rounded-lg shadow ">
            <InputV4
              naked={nonModal}
              label={nonModal ? null : "Task"}
              sid={"inputs"}
              id="title"
              placeholder={`Task`}
              customClasses={"rounded-md md:text-sm"}
              onInput={inputHandler}
              validators={[VALIDATOR_MINLENGTH(3)]}
              validityMessage="Min 3 characters"
              defaultValue={toDoData?.title ?? ""}
              disabled={completed}
            />

            {inJob && (
              <div className="text-xs flex">
                Seen By:{" "}
                {seen.map((user) => {
                  return (
                    <div className="capitalize ml-1">
                      {user.firstName} {user.lastName[0]}
                    </div>
                  );
                })}
              </div>
            )}

            <div className="pt-2">
              <InputV4
                label="Description"
                id="details"
                customClasses={"rounded-md md:text-sm"}
                placeholder="Detail scope of works for this task and it's deliverables..."
                textareaRows={4}
                defaultValue={toDoData?.details ?? ""}
                sid={"inputs"}
                onInput={inputHandler}
                validators={[]}
                disabled={completed}
                // requiredOverride
              />
            </div>
            <div className="h-fit pt-2">
              <BulkFileUpload
                globalHandler={{
                  inputHandler: inputHandler,
                  attachmentHandler: attachmentHandler,
                }}
                defaultValue={toDoData?.attachments ?? null}
                noButton
                type="task"
                disabled={completed}
              />
            </div>
          </div>

          {!isNew && !isPreset && (
            <div className=" flex flex-col grow bg-taskinatorWhite p-4 rounded-lg shadow mt-1">

              <TaskCommentDisplay comments={comments} setComments={setComments} jobId={jobData.jobId} taskId={toDoData._id} isCompleted={completed}/>
              {/* <div className="flex flex-col h-fit  grow w-full">
                <div>Comments</div>

                {comments?.length > 0 ? (
                  comments?.map((comment) => {
                    console.log(comment, "taskcomment");
                    return (
                      <div className=" mt-1 w-full  flex flex-col">
                        <div className="flex items-center my-1 justify-between text-xs font-semibold text-taskinatorDarkGrey ">
                          <div className="capitalize pr-1 ">
                            {" "}
                            {comment?.createdBy?.firstName} {comment?.createdBy?.lastName}
                          </div>
                          {FormatDate(comment?.createdDate)}{" "}
                        </div>
                        <div className="border w-full-taskinatorMedGrey text-taskiantorDarkGrey border-taskinatorItemBlue bg-taskinatorOffWhite p-2 flex-col ">
                          {comment?.comment}
                          <div className="flex pt-2">
                          {comment?.attachments?.map(attachment => {return <FilePreview file={attachment} typeOverride="task" showName viewMarkupsOnly small />})}</div>
                          </div>
                     
                      </div>
                    );
                  })
                ) : (
                  <div className="text-taskinatorMedGrey">No Comments</div>
                )}
              </div>

              <div className="w-full  mt-1  border-t border-t-taskiantorMedGrey pt-1">
                {" "}
                <TaskCommentsNoteAdder
                  taskId={toDoData._id}
                  jobId={jobData.jobId}
                  refresh={(newComment) =>
                    setComments((comments) => {
                      return [...comments, newComment];
                    })
                  }
                />
              </div> */}
            </div>
          )}

          {/* {inJob && (
            <div className="flex flex-col h-fit grow  pt-2">
              <div>File Reference Reference To:</div>
              <div className="flex text-xxs flex-wrap">
                {toDoData?.submittedFiles?.map((file, index) => {
                  if (index < 20) {
                    return (
                      <div className="mr-2 ">
                        <b>
                          {decodeURIComponent(file.fileName)}
                          {file.fileExtension}
                        </b>{" "}
                        - [Rev{file.revision}] - [{file.status}],
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          )} */}
        </div>
        <div className="grow flex flex-col md:w-1/4 h-fit md:ml-2 md:mt-0 mt-2 bg-taskinatorWhite shadow rounded-lg p-4  md:w-96 ">
          {inJob && (
            <div className="pt-1">
              <InputV4
                label="Due Date"
                id="dueDate"
                type="date"
                customClasses={"rounded-md md:text-sm"}
                sid={"inputs"}
                onInput={inputHandler}
                defaultValue={toDoData?.dueDate ?? null}
                validators={[]}
                validityMessage="Please Set A Due Date"
                disabled={completed}
              />
            </div>
          )}

          <div className="pt-2 w-full">
            <DurationSelector onInput={inputHandler} sid="inputs" id="duration" label="Estimated Duration" validationRequired defaultValue={toDoData?.duration ?? null} disabled={completed} />
            {/* <InputV4
          label="Estimated Duration (hrs)"
          id="duration"
          customClasses={"rounded-md md:text-sm"}
          sid={"inputs"}
          onInput={inputHandler}
          defaultValue={toDoData?.duration ?? 1}
          validators={[VALIDATOR_NUMBER()]}
          // requiredOverride
          validityMessage="Add Time In Hours"
        /> */}
          </div>

          <div className="pt-2">
            <MemberSelector
              label={"Assignees"}
              selectionDisplayText={`Select Team Members`}
              inputHandler={inputHandler}
              sid={"inputs"}
              roleTypes={[]}
              id="assignee"
              teamIds={
                defaultAsForm
                  ? toDoData?.primaryTaskDetails.taskDetails.team.map((team) => team._id)
                  : availableTeam?.map((team) => {
                      return team._id;
                    }) ?? []
              }
              defaultValue={(toDoData?.assignee ?? []).map((assignee) => {
                return { value: assignee._id };
              })}
              // thirdParty={actionData?.thirdParty}
              multi
              notRequired={inJob ? false : true}
              disabled={completed}
            />
          </div>

          <div className="pt-1">
            <PrioritySelector
              sid="inputs"
              id="priority"
              inputHandler={inputHandler}
              defaultValue={toDoData?.priority}
              notRequired={true}
              toolTip={"This sets the priority for this to do"}
              disabled={completed}
            />
          </div>
          <div className="h-full flex grow flex-col"></div>
          {/* <div className="py-2">
          <SliderComponent
            defaultValue={toDoData?.complexity}
            onInput={inputHandler}
            high={10}
            low={0}
            label={"Complexity "}
            sid="inputs"
            id="complexity"
            units={""}
            toolTip={"how complex is this task usually? if left at 0 then it will be ignored"}
            notRequired
          />
        </div> */}
        </div>
      </div>
      <div></div>
      <div className={`h-fit flex items-center justify-end mt-2 ${!toDoData.closed ? "" : "hidden"}`}>
        <div className="w-full flex ">
          {" "}
          {inJob && completed && !isPreset ? (
            <Button
              label={
                hover ? (
                  "Undo"
                ) : (
                  <div className="flex items-center">
                    {" "}
                    <MdCheck /> Completed
                  </div>
                )
              }
              customClasses={`${
                hover ? "text-taskinatorRed border-taskinatorRed" : "text-taskinatorGreen border-taskinatorGreen"
              } border flex items-center font-bold rounded   h-10 px-2 cursor-pointer w-32 justify-center`}
              onMouseEnter={() => setHover(true)} // Set hover to true when mouse enters
              onMouseLeave={() => setHover(false)} // Set hover to false when mouse leaves
              onClick={() => CompleteHandler()}
              isLoading={initState[1].started}
              error={initState[1].error}
            />
          ) : !isNew && !isPreset ? (
            <Button
              onClick={() => CompleteHandler()}
              isLoading={initState[1].started}
              error={initState[1].error}
              label="Mark As Completed"
              customClasses={`bg-taskinatorGreen text-taskinatorWhite h-10 w-full `}
            />
          ) : (
            <div></div>
          )}
          {!isNew && !isPreset && !completed && (
            <Button
              label={"Delete Task"}
              customClasses={`text-taskinatorRed border-taskinatorRed
            border flex items-center font-bold rounded opacity-50  h-10 px-2 cursor-pointer w-32 justify-center ml-4`}
              onClick={() => setConfirmDelete(true)}
              isLoading={initState[2].started}
              error={initState[2].error}
            />
          )}
        </div>
        {onSave && !completed && (
          <Button
            onClick={() => {
              onSaveHandler();
            }}
            label={toDoData._id ? "Save Task" : "Create Task"}
            customClasses={`${inputState.isValid ? "bg-taskinatorBlue" : "bg-taskinatorMedGrey"} bg-taskinatorBlue text-taskinatorWhite h-10 w-full `}
            disabled={!inputState.isValid}
            isLoading={initState[0].started}
            error={initState[0].error}
          />
        )}

        {!inJob && (
          <Button
            onClick={() => {
              setToDos((current) => {
                const tempData = { ...current };
                delete tempData[toDoData._id];

                return tempData;
              });
              onSave?.();
            }}
            label={"Remove"}
            customClasses={`border-taskinatorRed border opacity-50  text-taskinatorRed h-10 w-full ml-2 `}
          />
        )}

        {/* {inJob && toDoData.completed && !toDoData.closed ? <Button label="Close Task" customClasses={`bg-taskinatorGreen text-taskinatorWhite h-10 w-full ml-2`} /> : <div></div>} */}
      </div>
      {confirmDelete && (
        <Modal local={() => setConfirmDelete()}>
          <ApproveRejectForm
            message={<div>Are You Sure You Want To Delete</div>}
            confirmFunction={() => DeleteHandler()}
            rejectFunction={() => setConfirmDelete()}
            confirmLoadingState={initState[2].started}
            error={initState[2].error}
            confirmButtonCSS={"bg-taskinatorRed text-taskinatorWhite"}
            confirmButtonLabel="Delete"
          />
        </Modal>
      )}
    </div>
  );
};

const NewInstruction = ({
  job,
  customerButtonName,
  defaultValues,
  globalHandler,
  label,
  toolTip,
  inJob,
  oneRequired,
  nonModal,
  disabled,
  horizontal,
  availableTeam,
  defaultAsForm,
  presetTasks,
  taskUUID,
  isPreset,
}) => {
  let startingValue = {};

  const [ogDefaults, setOgDefaults] = useState(defaultValues ?? []);
  const result = (defaultValues ?? []).forEach((todo) => (startingValue = { ...startingValue, [todo._id]: todo }));
  const areEqual = deepEqual(ogDefaults, defaultValues ?? []);

  const { getRunTime } = useTimeDisplay();
  const [toDos, setToDos] = useState(startingValue);
  const jobData = useContext(JobModalContext);
  const [createInstruction, setCreateInstruction] = useState(defaultAsForm ? defaultValues[0] : false);

  /// this resets default values on changes
  useEffect(() => {
    let startingValue = {};

    const result = (defaultValues ?? []).forEach((todo) => (startingValue = { ...startingValue, [todo._id]: todo }));
    setToDos(startingValue);
  }, [areEqual]);

  const appData = useContext(AppContext);

  useEffect(() => {
    globalHandler?.("toDo", Object.values(toDos), oneRequired ? Object.values(toDos).length > 0 : true, "inputs");
  }, [toDos, Object.values(toDos).length]);

  return (
    <>
      <div className="flex capitalize text-sm text-taskinatorDarkGrey items-center justify-start text-start border-b border-b-2 border-b-taskinatorLightGrey w-full mt-2">
        <div className={``}>{label ?? "Tasks"}</div>
        {oneRequired ? <div className="opacity-75 pl-1 text-taskinatorRed">One (1) Required</div> : <div className="text-taskinatorMedGrey pl-1">Optional</div>}
        <div>
          <ToolTip content={toolTip ?? "This is where you can define the required deliverables for this task."} />
        </div>
      </div>
      <div className="flex flex-col w-full ">
        <div className="flex grow w-full flex-col">
          {horizontal && (
            <div className="flex text-xs text-taskinatorDarkGrey font-medium  mt-3">
              <div className="w-5 text-center">#</div>
              <div className="w-80 pl-2">Task</div>
              <div className="w-20 text-center ">Priority</div>
              <div className="w-20 text-center">Due</div>
              <div className="w-20  text-center">Duration</div>
              <div className="w-40 text-center ">Assignee/s</div>
              {/* <div className="w-40 text-center ">Details</div> */}
            </div>
          )}
          {Object.values(toDos).length > 0 ? (
            Object.values(toDos).map((todo, index) => {
              if (!horizontal) {
                return (
                  <div
                    className="border border-taskinatorGreen rounded w-full shadow p-2 bt-taskinatorOffWhite hover:bg-taskinatorHoverBlue cursor-pointer mb-1 text-taskinatorDarkGrey "
                    onClick={() => setCreateInstruction(todo)}
                  >
                    <div className="flex">
                      {" "}
                      <div className=" font-medium text-sm uppercase text-taskinatorGreen flex grow">{todo.title}</div>{" "}
                    </div>
                    <div className="flex">
                      <div className="flex text-xs mr-2">
                        <div className=" flex">
                          Priority: <div className="pl-1 font-medium">{todo.priority}</div>{" "}
                        </div>{" "}
                      </div>
                      {inJob && (
                        <div className=" text-xs flex">
                          Due: <div className="pl-1 font-medium">{DateDisplay(todo.dueDate)}</div>
                          {/* {getRunTime(todo.dueDate, "days")} */}
                        </div>
                      )}
                      <div className=" text-xs flex">
                        Duration:    <div className="pl-1 font-medium">{todo?.duration >= 0 ? `${todo.duration} hr` : "Receiver To Set"} </div>
                        {/* {getRunTime(todo.dueDate, "days")} */}
                      </div>
                    </div>
                    <div className="text-xs     flex flex-nowrap items-center pt-1">
                      Assignee:{" "}
                      <div className="pl-1 font-medium flex flex-no-wrap">
                        {" "}
                        {todo?.assignee?.length > 0 ? (
                          todo?.assignee?.map((user) => {
                            return (
                              <div key={user._id} className="">
                                <UserDisplayBubble firstName={user.firstName} lastName={user.lastName} user={user} />
                              </div>
                            );
                          })
                        ) : (
                          <div>None</div>
                        )}
                      </div>
                    </div>

                    <div className="text-xs flex-wrap border-t border-t-taskinatorLightGrey pt-1 mt-1">{StringShortenDisplay(50, todo.details)}</div>
                  </div>
                );
              } else {
                return (
                  <>
                    {isPreset && (
                      <div className="flex grow justify-start h-0 pt-2 z-40">
                        <div className="relative -top-2 ml-2 items-center flex h-4 w-fit text-taskinatorWhite text-[0.5rem] bg-taskinatorOrange px-1 rounded-full font-semibold">Preset</div>
                      </div>
                    )}

                    <div className="border border-taskinatorMedGrey rounded   w-full    bt-taskinatorOffWhite mb-1 text-taskinatorDarkGrey  flex justify-center items-center h-full ">
                      <div className="flex hover:bg-taskinatorHoverBlue cursor-pointer items-center py-2" onClick={() => setCreateInstruction(todo)}>
                        <div className="w-5 text-center text-sm border-r border-r-taskinatorMedGre">{index + 1}</div>
                        <div className="flex border-r items-start border-r-taskinatorMedGrey w-80">
                          {" "}
                          <div className=" font-medium text-sm uppercase text-taskinatorGreen flex grow pl-2 text-nowrap overflow-clipped">{todo.title}</div>{" "}
                        </div>
                        <div className="flex text-xs border-r border-r-taskinatorMedGrey w-20 justify-center">
                          <div className="pl-1 font-medium">{todo.priority}</div>{" "}
                        </div>
                        {inJob && (
                          <div className=" text-xs flex border-r border-r-taskinatorMedGrey w-20 justify-center">
                            <div className="pl-1 font-medium">{DateDisplay(todo.dueDate)}</div>
                            {/* {getRunTime(todo.dueDate, "days")} */}
                          </div>
                        )}
                        <div className=" text-xs flex border-r border-r-taskinatorMedGrey w-20 justify-center">
                          <div className="pl-1 font-medium">{todo?.duration >= 0 ? `${todo.duration} hr` : "Set Later"} </div>
                          {/* {getRunTime(todo.dueDate, "days")} */}
                        </div>

                        <div className="text-xs   border-r border-r-taskinatorMedGrey w-40 justify-center text-center flex flex-nowrap">
                          {todo?.assignee?.map((user) => {
                            return (
                              <div key={user._id} className="">
                                <UserDisplayBubble firstName={user.firstName} lastName={user.lastName} user={user} />
                              </div>
                            );
                          })}
                        </div>
                        {/* <div className="text-xs  border-r border-r-taskinatorMedGrey  w-40 justify-center text-center overflow-clipped">{StringShortenDisplay(15, todo.details)}</div> */}
                      </div>

                      <div
                        onClick={(event) => {
                          setToDos((current) => {
                            const tempData = { ...current };
                            delete tempData[todo._id];

                            return tempData;
                          });
                        }}
                        className="text-xs h-full grow justify-center text-center overflow-clipped text-taskinatorRed hover:opacity-70 flex text-lg cursor-pointer "
                      >
                        <MdClose size={"1.5rem"} />
                      </div>
                    </div>
                  </>
                );
              }
            })
          ) : (
            <div className="border w-full border-taskinatorMedGrey text-xs py-0.5 px-2 rounded text-taskinatorDarkGrey">None Set</div>
          )}
        </div>
        <div className="w-full flex justify-start pt-1">
          <Button
            label={customerButtonName ?? "Add Tasks"}
            onClick={() => setCreateInstruction(true)}
            disabled={disabled}
            customClasses={
              "border border-taskinatorGreen rounded text-center py-1 px-2  text-taskinatorWhite bg-taskinatorGreen hover:bg-taskinatorGreen hover:text-taskinatorWhite cursor-pointer text-sm"
            }
          />
        </div>
      </div>
      {createInstruction && (
        <Modal
          bgColour={"bg-taskinatorLightGrey"}
          local={
            defaultAsForm
              ? () => defaultAsForm()
              : () => {
                  setCreateInstruction();
                }
          }
          title={
            <div className="flex">
              Create/Edit Task Details
              {createInstruction?.primaryTaskDetails?.name ? (
                <div className="flex pl-1">
                  for <div className="font-bold mx-1">{createInstruction?.primaryTaskDetails?.name}</div> Task
                </div>
              ) : (
                ""
              )}
            </div>
          }
          size="70%"
        >
          <NewInstructionForm
            toDoData={createInstruction}
            setToDos={setToDos}
            toDos={toDos}
            onSave={defaultAsForm ? () => defaultAsForm() : () => setCreateInstruction()}
            inJob={inJob}
            nonModal={false}
            availableTeam={availableTeam}
            defaultAsForm={defaultAsForm}
            jobData={jobData}
            taskUUID={taskUUID}
            appData={appData}
            isPreset={isPreset}
          />
        </Modal>
      )}
    </>
  );
};

export default NewInstruction;
