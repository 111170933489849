import { useEffect, useState } from "react";
import { io } from "socket.io-client";

const socket = io(process.env.REACT_APP_BACKEND_URL, {
  autoConnect: false,
  forceNew: true,
  withCredentials: true,
});

export const useSocket = (workspaceData) => {
  const [socketConnected, setSocketConnected] = useState();
  const [socketError, setSocketError] = useState();

  useEffect(() => {
    if (workspaceData?.authenticated) {
      socket.connect();

      const onConnect = (startup) => {
        setSocketConnected(true);
      };

      const onConnectError = (err) => {
        console.log(err);
        if (err.message === "Can not connect - auth") {
          setSocketError(true);
          setSocketConnected();
        }
      };

      socket.on("connect", onConnect);
      socket.on("connect_error", onConnectError);

      return () => {
        socket.off("connect", onConnect);
        socket.off("connect_error", onConnectError);
      };
    } else {
      socket.disconnect();
      setSocketConnected();
    }
  }, [workspaceData]);

  return { socket, socketConnected, socketError };
};
