import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import AutoScrollDownContainer from "../../../utils/autoScrollDownContainer";
import { useInit } from "../../hooks/useInit";
import { JobModalContext } from "../jobs/jobModalContext";
import JobSubmissionBubble from "../jobs/jobSubmissionBubble";
import CreateFile from "./createFile";
import FileModifierButtons from "./fileModifierButtons";

const FileJobViewDetails = ({
  file,
  thirdParty,
  accessCode,
  accessCode2,
  id,
  accessCode3,
  itemId,
  inOtherWorkflow,
  taskData,
  workflowGroupId,
  fileSetId,
  workflowId,
  workflowName,
  taskTags,
  taskCompleted,
  onResponse,
  isRef,
  fileSubmitted,
 
}) => {
  const { initState, initStarted, initCompleted, initErrors, initReset } = useInit(3);
  const [signedUrl, setSignedUrl] = useState();
  const [fileDetails, setFileDetails] = useState();
  const [editFile, setEditFile] = useState();

  const jobModalContext = useContext(JobModalContext);

  const getFileData = async () => {
    try {
      const responseData = await axios.get(process.env.REACT_APP_BACKEND_URL + `/api/job/fileDetails/${file.revisionTrackingId}/${jobModalContext.jobId}`, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      });

      setFileDetails(responseData.data);
      initCompleted(1);
    } catch (err) {
      console.log(err);
      return initErrors(err?.response?.data?.message ?? err?.message ?? "", 1);
    }
  };

  useEffect(() => {
    // attachmentClickHandler();
    getFileData();
  }, [file._id, file]);



  return (
    <>
      <div className="flex flex-col ">
        {!taskCompleted && !fileSubmitted && !isRef && (
          <div className="border-b-2 border-b-taskinatorMedGrey mb-2 pb-2">
            <FileModifierButtons
              file={file}
              horizontal
              inOtherWorkflow={inOtherWorkflow}
              taskData={taskData}
              workflowGroupId={workflowGroupId}
              fileSetId={fileSetId}
              workflowId={workflowId}
              workflowName={workflowName}
              taskTags={taskTags}
              fileSubmitted={fileSubmitted}
             
            />
          </div>
        )}
        <div className="flex">
          {/* {file.fileExtension === ".pdf" && ( */}

        

          {/* )} */}
          <div className=" m-0.5   flex flex-col grow overflow-auto">
            <div className="font-medium pb-1 uppercase text-taskinatorDarkGrey text-center">File Log</div>
            <div className="overflow-hidden rounded-lg border border-taskinatorMedGrey">
              <AutoScrollDownContainer id={"submission"} customCSS={"h-[404px] p-2 py-2 bg-taskinatorWhite"} triggerAgain={fileDetails?.submissions?.length}>
                {fileDetails?.submissions?.length > 0 ? (
                  fileDetails?.submissions?.map((submission) => {
                    return (
                      <div key={submission._id}>
                        <JobSubmissionBubble submission={submission} />
                      </div>
                    );
                  })
                ) : (
                  <div className="flex  items-start justify-center text-taskinatorMedGrey">No submission comments...</div>
                )}
              </AutoScrollDownContainer>
            </div>
          </div>
          <div className="m-1  flex flex-col w-96 h-fit">
            <div className="font-medium pb-1 uppercase text-taskinatorDarkGrey text-center">Details</div>
            <div className="bg-taskinatorWhite  rounded-lg border border-taskinatorMedGrey">
              <CreateFile
                file={file}
                titleless
                currentFileSetId={fileSetId}
                taskData={taskData}
                workflowGroupId={workflowGroupId}
                fileSetId={fileSetId}
                noButtonsOrStatus
                taskCompleted={taskCompleted}
                onResponse={onResponse}
                taskTags={taskTags}
                fileSubmitted={fileSubmitted}
                isRef={isRef}
              />
              {/* <FilePreview file={file} isPreview viewMarkupsOnly={taskCompleted} /> */}
            </div>
          </div>
  

          {/* <div className=" m-0.5  flex flex-col w-1/3  overflow-auto">
            <div className="font-medium pb-1  uppercase text-taskinatorDarkGrey text-center">File Activity</div>
            <div className="overflow-hidden rounded-lg border border-taskinatorMedGrey">
              <AutoScrollDownContainer id={"log"} customCSS={"h-[404px] p-2 py-2 bg-taskinatorWhite"} triggerAgain={fileDetails?.logs.length}>
                {fileDetails?.logs.length > 0 ? (
                  fileDetails?.logs?.map((log) => {
                    return (
                      <div className="capitalize mb-1 text-xs border-b border-b-taskinatorLightGrey pb-1">
                        <div className="text-taskinatorDarkGrey font-semibold">{log.creationReason}</div> {log.creator.firstName} {log.creator.lastName} - {DateDisplay(log.createdDate)}
                      </div>
                    );
                  })
                ) : (
                  <div className="flex  items-start h-full justify-center text-taskinatorMedGrey">No logs...</div>
                )}
              </AutoScrollDownContainer>
            </div>


          </div> */}
          {/* <div className=" m-0.5  flex flex-col w-1/3  overflow-auto">
            <div className="font-medium pb-1  uppercase text-taskinatorDarkGrey text-center">File Notes</div>
            <div className="overflow-hidden rounded-lg border border-taskinatorMedGrey">
              <AutoScrollDownContainer id={"log"} customCSS={"h-[404px] p-2 py-2 bg-taskinatorWhite"} triggerAgain={fileDetails?.logs.length}>
                {fileDetails?.logs.length > 0 ? (
                  fileDetails?.logs?.map((log) => {
                    return (
                      <div className="capitalize mb-1 text-xs border-b border-b-taskinatorLightGrey pb-1">
                        <div className="text-taskinatorDarkGrey font-semibold">{log.creationReason}</div> {log.creator.firstName} {log.creator.lastName} - {DateDisplay(log.createdDate)}
                      </div>
                    );
                  })
                ) : (
                  <div className="flex  items-start h-full justify-center text-taskinatorMedGrey">No logs...</div>
                )}
              </AutoScrollDownContainer>
            </div>
 */}

          {/* </div> */}
        </div>
      </div>
      {/* {editFile && (
        <Modal
          local={() => {
            setEditFile();
          }}
          title="Edit File Details"
        >
          <div className="min-w-96 overflow-auto h-fit scrollbar">
            <CreateFile
              file={file}
              titleless
              onResponse={() => {
                setEditFile();
              }}
            />
          </div>
        </Modal>
      )} */}
    </>
  );
};

export default FileJobViewDetails;
