import axios from "axios";
import React, { useContext, useEffect } from "react";
import { AppContext } from "../../../contexts/appContext";
import { TaskContext } from "../../../contexts/taskContext";
import { useInputV4 } from "../../../stateManagement/useInputV4";
import Button from "../../UI_elements/button";
import Modal from "../../UI_elements/modal";
import { useInit } from "../../hooks/useInit";
import JobFileSelector from "./jobFileSelector";
import JobWorkflowDisplay from "./jobWorkflowDisplay";

const AddFileSetToJob = ({ closeModal, tid }) => {
  const appData = useContext(AppContext);

  const { initState, initStarted, initCompleted, initErrors, initReset } = useInit();
  const { inputState, inputHandler, attachmentHandler } = useInputV4();

  const addHandler = () => {
    initStarted(0);
    axios
      .put(
        process.env.REACT_APP_BACKEND_URL + `/api/job/add/fileSet/${tid}`,
        {
          jobDetails: inputState.details,

          // attachments: Object.values(inputState.attachments).map((attachment) => {
          //   return { key: attachment.value.key, fileName: attachment.value.fileName };
          // }),
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        // taskContext.getActionData();

       
        appData.setActionViewHandler({ tracerId: response.data.newTracerId, tid: tid });
        closeModal();
        initCompleted(0);
      })
      .catch((err) => {
        console.log(err);
        initErrors(err?.response?.data?.message ?? err?.message ?? "", 0);
      });
  };

  const workflowSelected = inputState.details && inputState.details.workflow.value.length > 0;

  // get allowable action data
  useEffect(() => {
    if (workflowSelected) {
    }
  }, [workflowSelected]);

  return (
    <Modal title="Add Folder To Job" bgColour="bg-taskinatorLightGrey" size="max" local={() => closeModal()}>
      <div className="flex flex-col grow overflow-auto ">
        <div className="flex  flex-col grow xl:overflow-auto ">
          <div className=" mb-2 w-full">
            <JobFileSelector
              globalSid={"details"}
              globalInputHandler={inputHandler}
              workflowId
              workflowAdded={workflowSelected}
              type="workflow"
              tid={tid}
              wfid={workflowSelected && inputState.details.workflow.value[0].wfid._id}
              number="2"
            />
          </div>

          <div className="flex flex-col w-full  ml-0  mt-1">
            <div className=" mt-1 border-l border-l-4 border-l-taskinatorGreen py-2 px-4  bg-taskinatorGreen shadow mb-1 font-semibold uppercase flex items-center rounded-lg ">
              <div className="flex items-center text-taskinatorWhite text-base">Workflow</div> <div className="pl-1 text-taskinatorWhite capitalize font-normal text-base">(Required)</div>
              {/* <div className="pl-1 text-taskinatorRed capitalize font-normal text-xs">(Required)</div> */}
            </div>
            <JobWorkflowDisplay
              globalSid={"details"}
              globalInputHandler={inputHandler}
              globalAttachmentHandler={attachmentHandler}
              buttonLabel="Add Workflow"
              fileSetsSelected={inputState?.details?.fileSets?.value}
            />
          </div>
        </div>

        <div className="xl:pt-0 pt-8 ">
          <Button
            onClick={() => addHandler()}
            disabled={!inputState.isValid}
            label="Add"
            customClasses={`border ${
              inputState.isValid ? "border-taskinatorBlue bg-taskinatorBlue" : "bg-taskinatorMedGrey border-taskinatorMedGrey"
            } hover:opacity-75   text-taskinatorWhite h-10 text-xs md:text-sm  py-2 w-full `}
            error={initState[0].error}
            isLoading={initState[0].started}
          />
        </div>
      </div>
    </Modal>
  );
};

export default AddFileSetToJob;
