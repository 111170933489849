import { PDFDocument, rgb, degrees, StandardFonts } from "pdf-lib";
import FormatDate from "../../utils/formattedDate";
import QRCode from "qrcode";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

const A3_WIDTH = 842; // in points
const A3_HEIGHT = 1191; // in points

const getScaleFunction = (targetWidth, targetHeight, actualWidth, actualHeight) => {
  const actualShorter = Math.min(actualWidth, actualHeight);
  const actualLonger = Math.max(actualWidth, actualHeight);

  // Calculate the scaling factors based on the shorter and longer sides
  const widthScale = targetWidth / actualShorter;
  const heightScale = targetHeight / actualLonger;

  // Use the smaller scale to ensure content fits within both dimensions
  const scale = Math.min(widthScale, heightScale);
  return Math.max(Math.min(scale, 1), 0.6);
};

const MergeAndStampPDF = async (pdfFiles, QRCodeLink, Revision, Status, itemNumber, oidName, addStamp) => {
  let error;
  let font;
  let stampFont;

  try {
    const mergedPDF = await PDFDocument.create();

    const stampingOption = await axios.get(process.env.REACT_APP_BACKEND_URL + `/api/items/stampingOptions`, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });

    for (const pdfFile of pdfFiles) {
      const existingPDFBytes = await pdfFile.arrayBuffer();

      error = pdfFile.name;
      const pdfDoc = await PDFDocument.load(existingPDFBytes, {
        ignoreEncryption: true,
      });
      font = await pdfDoc.embedFont(StandardFonts.Helvetica);
      // stampFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);

      const pageCount = pdfDoc.getPageCount();

      for (let pageIndex = 0; pageIndex < pageCount; pageIndex++) {
        const [page] = await mergedPDF.copyPages(pdfDoc, [pageIndex]);

        page.setRotation(degrees(0));

        mergedPDF.addPage(page);
      }
    }
    // Save a copy of the merged but unstamped PDF
    const mergedUnstampedPDfBytes = await mergedPDF.save();

    const pages = mergedPDF.getPages();
    const totalPages = pages.length;
    let currentPage = 1;

    if (Status !== "markup") {
      for (const page of pages) {
        var opts = {
          margin: 0,
          color: {
            dark: Status === "draft" ? "#000000" : "#FF0000",
            light: "#FFFFFF",
          },
        };

        const qrCodeDataUrl = await QRCode.toDataURL(QRCodeLink, opts);
        const qrImage = await mergedPDF.embedPng(qrCodeDataUrl);
        const qrOffset = 10;
        const { width, height } = page.getSize();
        const scale = getScaleFunction(A3_WIDTH, A3_HEIGHT, width, height);

        const statusText = Status.toUpperCase();
        let waterMarkTextSize = 100 / scale;
        const cmToPoints = (cm) => cm * 28.3465;
        let qrSize = cmToPoints(2.5) / scale;
        let textSize = 8 / scale;

        const stampText = Status === "draft" ? "NOT FOR CONSTRUCTION" : "APPROVED DRAWING FOR PRODUCTION";
        let stampSize = 8 / scale;

        const stampWidth = font.widthOfTextAtSize(stampText, stampSize);
        const stampHeight = stampSize; // Approximation

        // Define font size
        // Calculate text width and height
        const textWidth = font.widthOfTextAtSize(statusText, waterMarkTextSize);
        const textHeight = waterMarkTextSize; // Approximation

        // Calculate the center of the page
        const centerX = width / 2;
        const centerY = height / 2;

        // Calculate the rotation angle in radians
        const rotationAngle = 45; // in degrees
        const radianAngle = (rotationAngle * Math.PI) / 180;

        // Calculate the coordinates of the four corners of the bounding box of the text before rotation
        const textXCenter = textWidth / 2;
        const textYCenter = textHeight / 2;

        const textAngleToCenter = Math.atan2(textYCenter, textXCenter);
        const textHypTocenter = Math.sqrt(textYCenter ** 2 + textXCenter ** 2);

        const pageXTextCenter = textHypTocenter * Math.cos(textAngleToCenter + radianAngle);
        const pageYTextCenter = textHypTocenter * Math.sin(textAngleToCenter + radianAngle);

        const watermarkX = centerX - pageXTextCenter;
        const watermarkY = centerY - pageYTextCenter + 20;
        /// calculate center position of text at 45 degrees

        const qrCodeX = qrOffset; // Set the X coordinate for top left corner placement

        // width > height ? width * 0.05 : height * 0.05;
        const qrCodeY = height - qrOffset - qrSize; // Set the Y coordinate for top left corner placement

        if (stampingOption?.data?.addWaterMarks) {
          page.drawText(`${statusText}`, {
            x: centerX - textXCenter,
            y: 30,
            size: waterMarkTextSize,
            color: Status === "draft" ? rgb(0, 0, 0) : rgb(1, 0, 0),

            opacity: 0.1,
          });
        }

        if (stampingOption?.data?.addQRCode) {
          page.drawImage(qrImage, {
            x: 30,
            y: 30,
            width: qrSize,
            height: qrSize,
            color: rgb(Status === "draft" ? 0 : 1, 0, 0),
          });
        }

        if (stampingOption?.data?.addStamp) {
          page.drawRectangle({
            x: 30 + qrSize + 3,
            y: 30,
            width: qrSize * 2.5,
            height: qrSize / 2,
            color: rgb(1, 1, 1),
            borderColor: rgb(Status === "draft" ? 0 : 1, 0, 0),
            borderWidth: 2,
          });

          page.drawText(stampText, {
            x: 30 + qrSize + (qrSize * 2.5) / 2 - stampWidth / 2 + 3,
            y: 30 + qrSize / 4 + 1, // Adjusting for the font size to keep the text within the page bounds
            size: stampSize,
            color: rgb(Status === "draft" ? 0 : 1, 0, 0),
          });

          page.drawText(`${oidName.toUpperCase()} - ${itemNumber} Rev ${Revision}`, {
            x: 30 + qrSize + (qrSize * 2.5) / 2 - stampWidth / 2 + 3,
            y: 30 + qrSize / 4 - stampHeight - 1, // Adjusting for the font size to keep the text within the page bounds
            size: stampSize,
            color: rgb(Status === "draft" ? 0 : 1, 0, 0),
          });
        }

        const textString = `${statusText} - ${itemNumber} Rev ${Revision} - Page ${currentPage} of ${totalPages} - ${FormatDate(new Date(), true)} - Scan QR code to confirm status.`;

        const headerFooterTextWidth = font.widthOfTextAtSize(textString, textSize);

        if (stampingOption?.data?.addWaterMarks) {
          page.drawRectangle({
            x: 30 - 2,
            y: height - textSize - 10,
            width: headerFooterTextWidth + 4,
            height: textSize,
            color: rgb(1, 1, 1),
            borderColor: rgb(Status === "draft" ? 0 : 1, 0, 0),
          });

          page.drawText(textString, {
            x: 30,
            y: height - textSize - 10, // Adjusting for the font size to keep the text within the page bounds
            size: textSize,
            color: rgb(Status === "draft" ? 0 : 1, 0, 0),
          });

          page.drawRectangle({
            x: 30 - 4,
            y: 30 - textSize - 3,
            width: headerFooterTextWidth + 4,
            height: textSize,
            color: rgb(1, 1, 1),
            borderColor: rgb(Status === "draft" ? 0 : 1, 0, 0),
          });

          // Pattern along the bottom of the page

          page.drawText(textString, {
            x: 30,
            y: 30 - textSize - 2, // Drawing at the very bottom of the page
            size: textSize,
            color: rgb(Status === "draft" ? 0 : 1, 0, 0),
          });
        }

        currentPage++;
      }
    }

    const modifiedPDFBytes = await mergedPDF.save();
    const blob = new Blob([modifiedPDFBytes], { type: "application/pdf" });
    const blob2 = new Blob([mergedUnstampedPDfBytes], {
      type: "application/pdf",
    });

    // return { stampedPDF: blob, og: blob2 };
    return { stampedPDF: blob, og: blob2 };
  } catch (err) {
    throw { success: false, err: err, stampingError: "Error Applying Stamps" };
  }
};

export const StampPDF = async (pdfBlob, file) => {
  return new Promise(async (resolve, reject) => {
    try {
      const status = file.status === "released" ? "released" : "draft";

      const revision = file.revision;

      const QRCodeLink = `${process.env.REACT_APP_WEBSITE}/status/files/${file.key}`;
      const originalFile = await pdfBlob.arrayBuffer();
      const pdfDoc = await PDFDocument.load(originalFile, {
        ignoreEncryption: false,
      });

      if (pdfDoc.isEncrypted) {
        throw new Error("The PDF is encrypted and cannot be processed.");
      }

      const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
      const pages = pdfDoc.getPages();
      const totalPages = pages.length;

      const opts = {
        margin: 0,

        color: {
          dark: status === "draft" ? "#000000" : "#FF0000",
          light: "#FFFFFF",
        },
      };

      const stampingOption = await axios.get(process.env.REACT_APP_BACKEND_URL + `/api/items/stampingOptions`, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      });

      let currentPage = 1;
      for (const page of pages) {
        const qrCodeDataUrl = await QRCode.toDataURL(QRCodeLink, opts);
        const qrImage = await pdfDoc.embedPng(qrCodeDataUrl);
        const qrOffset = 10;
        const { width, height } = page.getSize();
        const scale = getScaleFunction(A3_WIDTH, A3_HEIGHT, width, height);

        const statusText = file.status.toUpperCase();
        let waterMarkTextSize = 100 / scale;
        const cmToPoints = (cm) => cm * 28.3465;
        let qrSize = cmToPoints(2.5) / scale;
        let textSize = 8 / scale;

        const stampText = status === "draft" ? "NOT FOR CONSTRUCTION" : "APPROVED DRAWING FOR PRODUCTION";
        let stampSize = 8 / scale;

        const stampWidth = font.widthOfTextAtSize(stampText, stampSize);
        const stampHeight = stampSize; // Approximation

        // Define font size
        // Calculate text width and height
        const textWidth = font.widthOfTextAtSize(statusText, waterMarkTextSize);
        const textHeight = waterMarkTextSize; // Approximation

        // Calculate the center of the page
        const centerX = width / 2;
        const centerY = height / 2;

        // Calculate the rotation angle in radians
        const rotationAngle = 45; // in degrees
        const radianAngle = (rotationAngle * Math.PI) / 180;

        // Calculate the coordinates of the four corners of the bounding box of the text before rotation
        const textXCenter = textWidth / 2;
        const textYCenter = textHeight / 2;

        const textAngleToCenter = Math.atan2(textYCenter, textXCenter);
        const textHypTocenter = Math.sqrt(textYCenter ** 2 + textXCenter ** 2);

        const pageXTextCenter = textHypTocenter * Math.cos(textAngleToCenter + radianAngle);
        const pageYTextCenter = textHypTocenter * Math.sin(textAngleToCenter + radianAngle);

        const watermarkX = centerX - pageXTextCenter;
        const watermarkY = centerY - pageYTextCenter + 20;
        /// calculate center position of text at 45 degrees

        const qrCodeX = qrOffset; // Set the X coordinate for top left corner placement

        // width > height ? width * 0.05 : height * 0.05;
        const qrCodeY = height - qrOffset - qrSize; // Set the Y coordinate for top left corner placement

        if (stampingOption?.data?.addWaterMarks) {
          page.drawText(`${statusText}`, {
            x: centerX - textXCenter,
            y: 30,
            size: waterMarkTextSize,
            color: status === "draft" ? rgb(0, 0, 0) : rgb(1, 0, 0),
            opacity: 0.1,
          });
        }

        if (stampingOption?.data?.addQRCode) {
          page.drawImage(qrImage, {
            x: 30,
            y: 30,
            width: qrSize,
            height: qrSize,
            color: rgb(status === "draft" ? 0 : 1, 0, 0),
            opacity: 0.5,
          });
        }

        if (stampingOption?.data?.addStamp) {
          page.drawRectangle({
            x: 30 + qrSize + 3,
            y: 30,
            width: qrSize * 2.5,
            height: qrSize / 2,
            color: rgb(1, 1, 1),
            borderColor: rgb(status === "draft" ? 0 : 1, 0, 0),
            borderWidth: 2,
          });

          page.drawText(stampText, {
            x: 30 + qrSize + (qrSize * 2.5) / 2 - stampWidth / 2 + 3,
            y: 30 + qrSize / 4 + 1, // Adjusting for the font size to keep the text within the page bounds
            size: stampSize,
            color: rgb(status === "draft" ? 0 : 1, 0, 0),
          });

          page.drawText(`${stampingOption?.data?.name.toUpperCase()}`, {
            x: 30 + qrSize + (qrSize * 2.5) / 2 - stampWidth / 2 + 3,
            y: 30 + qrSize / 4 - stampHeight - 1, // Adjusting for the font size to keep the text within the page bounds
            size: stampSize,
            color: rgb(status === "draft" ? 0 : 1, 0, 0),
          });
        }

        const textString = `${statusText} - ${decodeURIComponent(file.fileName)}${file.fileExtension} Rev ${revision} - Page ${currentPage} of ${totalPages} - ${FormatDate(
          new Date(),
          true
        )} - Scan QR code to confirm status.`;

        const headerFooterTextWidth = font.widthOfTextAtSize(textString, textSize);

        if (stampingOption?.data?.addWaterMarks) {
          page.drawRectangle({
            x: 30 - 2,
            y: height - textSize - 10,
            width: headerFooterTextWidth + 4,
            height: textSize,
            color: rgb(1, 1, 1),
            borderColor: rgb(status === "draft" ? 0 : 1, 0, 0),
          });

          page.drawText(textString, {
            x: 30,
            y: height - textSize - 10, // Adjusting for the font size to keep the text within the page bounds
            size: textSize,
            color: rgb(status === "draft" ? 0 : 1, 0, 0),
          });

          page.drawRectangle({
            x: 30 - 4,
            y: 30 - textSize - 3,
            width: headerFooterTextWidth + 4,
            height: textSize,
            color: rgb(1, 1, 1),
            borderColor: rgb(status === "draft" ? 0 : 1, 0, 0),
          });

          // Pattern along the bottom of the page

          page.drawText(textString, {
            x: 30,
            y: 30 - textSize - 2, // Drawing at the very bottom of the page
            size: textSize,
            color: rgb(status === "draft" ? 0 : 1, 0, 0),
          });
        }

        currentPage++;
      }

      const stampedPDF = await pdfDoc.save();
      const blob = new Blob([stampedPDF], { type: "application/pdf" });

      resolve(blob);
    } catch (error) {
      console.error("Error processing PDF:", error);
      reject(new Error("Failed to process and stamp the PDF."));
    }
  });
};

export default MergeAndStampPDF;
