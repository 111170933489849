import axios from "axios";
import React, { useContext, useEffect, useReducer, useState } from "react";
import { useInputV4 } from "../../../stateManagement/useInputV4";
import Button from "../../UI_elements/button";
import Modal from "../../UI_elements/modal";
import UserDisplayBubble from "../../UI_elements/userDisplayBubble";
import { VALIDATOR_MINLENGTH } from "../../forms/validators/validators";
import { useInit } from "../../hooks/useInit";
import Input_V4 from "../../inputs/input_V4";
import NextWorkflowDisplayAndSelect from "../workflow/nextWorkflowDisplayAndSelect";
import { JobModalContext } from "./jobModalContext";
import { AppContext } from "../../../contexts/appContext";
import Checkbox_V4 from "../../inputs/checkbox_V4";
import BulkFileUpload from "../files/bulkFileUpload";
import NewInstruction from "../../forms/newInstruction";
import ToolTip from "../../../utils/toolTip";

const submissionStateReducer = (state, action) => {
  let currentSetWorkflowState = state;

  switch (action.type) {
    case "ADD_WORKFLOW":
      delete currentSetWorkflowState["close"];
      return {
        ...currentSetWorkflowState,
        [action.workflow._id]: {
          wfid: action.workflow._id,
          managers: [],
          autoSelect: action.autoSelect,
        },
      };

    case "DELETE_WORKFLOW":
      delete currentSetWorkflowState[action.workflow._id];
      delete currentSetWorkflowState["close"];
      return { ...currentSetWorkflowState };

    case "CLOSE_WORKFLOW":
      if (currentSetWorkflowState.close) {
        return {};
      } else {
        return { ["close"]: "close" };
      }

    case "MANAGER_CHANGE":
      return {
        ...currentSetWorkflowState,
        [action.wfid]: {
          ...currentSetWorkflowState[action.wfid],
          managers: action.managers,
        },
      };

    case "RESET":
      return {};

    default:
      return state;
  }
};

const nextTaskDisplay = (task, filesBeingSubmitted) => {
  console.log(task, "task concol");
  return (
    <div className=" mb-1 border py-1 px-2 border-taskinatorMedGrey bg-taskinatorWhite  rounded flex items-center justify-center">
      <div className="text-taskinatorDarkGrey font-semibold pr-1 border-r border-r-taskinatorGreen mr-1">{task?.taskDetails?.name ?? ""} </div>
      <div className="font-normal pr-1 "> Assignee/s:</div>
      <div className="flex">
        {(task?.assignee ?? []).map((user) => {
          return <UserDisplayBubble user={user} />;
        })}
      </div>
    </div>
  );
};

const JobSubmission = ({ fileSelected, fileSetByTask, setFileSelected, setSelectAll, workflowData, submissionId, allFilesSelected }) => {
  const [submit, setSubmit] = useState();
  const [sendDirection, setSendDirection] = useState();
  const [isDone, setIsDone] = useState("");
  const [manual, setManual] = useState();

  useEffect(() => {
    setIsDone();
  }, [submit]);

  const jobData = useContext(JobModalContext);
  const appData = useContext(AppContext);
  const [originalSubmit, setOriginalSubmit] = useState(submit);

  const allWorkflows = appData?.jobs?.[jobData.jobId]?.workflows;

  const { inputState, inputHandler, attachmentHandler } = useInputV4({ inputs: {}, attachments: {} }, false);
  const { initState, initStarted, initCompleted, initErrors, initReset } = useInit(2);
  // const workflowData = jobData.selectedWorkflowData;

  const [nextWorkflows, setNextWorkflows] = useReducer(submissionStateReducer, {});

  const movementOptions = workflowData?.workflowMap;

  console.log(movementOptions);
  const backMovementOptions = movementOptions[fileSetByTask._id]?.back;
  const forwardMovementOptions = movementOptions[fileSetByTask._id]?.forward;
  const nextWorkflowOptions = movementOptions[fileSetByTask._id]?.nextworkflow;
  const filesSelected = Object.keys(fileSelected)?.length > 0;
  const workflowSelected = Object.values(nextWorkflows).length > 0;

  // const tasksToClose = jobData.tasks.filter(
  //   (task) => task.currentJobTaskUUID === fileSetByTask.taskUUID && !task.completed && task.submittedFiles.filter((f) => Object.keys(fileSelected ?? {}).includes(f._id)).length > 0
  // );

  // console.log(tasksToClose);
  useEffect(() => {
    if (Object.values(nextWorkflows ?? {}).length > 0) setSendDirection();
  }, [nextWorkflows]);

  useEffect(() => {
    if (workflowSelected && sendDirection) {
      setNextWorkflows({ type: "RESET" });
    }
  }, [sendDirection]);

  useEffect(() => {
    if (submit !== originalSubmit) {
      setOriginalSubmit(submit);
      setNextWorkflows({ type: "RESET" });
      setSendDirection();
    }

    initReset(0);
  }, [submit]);

  const SubmitHandler = async () => {
    try {
      initStarted(0);

      await axios.put(
        process.env.REACT_APP_BACKEND_URL + `/api/job/submit/${jobData.jobId?.toString()}/${workflowData?.workflowGroupId?.toString()}/${fileSetByTask._id}/${fileSetByTask.fileSetId}`,
        {
          direction: sendDirection,
          nextWorkflow: Object.values(nextWorkflows),
          filesToSend: Object.keys(fileSelected),
          comments: inputState?.inputs?.comments?.value,
          attachments: Object.values(inputState?.attachments ?? {}).map((attachment) => {
            return {
              key: attachment.value.key,
              fileName: attachment.value.fileName,
              fileExtension: attachment.value.fileExtension,
              _id: attachment.value._id,
            };
          }),
          tasks: inputState?.inputs?.toDo?.value ?? [],
          isDone: allFilesSelected ? (isDone === "done" ? true : false) : true,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      initCompleted(0);
      setSubmit();
      setFileSelected({});
      jobData?.setSelectedTaskIds({});
      setSelectAll(false);
      setSendDirection();
    } catch (err) {
      console.log(err);
      return initErrors(err?.response?.data?.message ?? err?.message ?? "", 0);
    }
  };

  const CheckInHandler = async () => {
    try {
      initStarted(0);

      await axios.put(
        process.env.REACT_APP_BACKEND_URL +
          `/api/job/checkIn/${jobData.jobId?.toString()}/${workflowData?.workflowGroupId?.toString()}/${fileSetByTask._id}/${fileSetByTask.fileSetId}/${fileSetByTask.taskUUID}`,
        {
          filesToSend: Object.keys(fileSelected),
          comments: inputState?.inputs?.comments?.value,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      initCompleted(0);
      setSubmit();
      setFileSelected({});
      jobData?.setSelectedTaskIds({});
      setSelectAll(false);
    } catch (err) {
      console.log(err);
      return initErrors(err?.response?.data?.message ?? err?.message ?? "", 0);
    }
  };

  const nextTaskIds = movementOptions?.[fileSetByTask._id]?.[sendDirection] ?? [];

  const selectedTaskData = workflowData.tasks.filter((task) => nextTaskIds.includes(task._id));

  return (
    <>
      <Button
        label={filesSelected ? "Issue Files" : "Issue Next Task Only"}
        customClasses={`bg-taskinatorBlue  text-taskinatorWhite  hover:opacity-75 py-2 text-xs uppercase`}
        // disabled={!filesSelected}
        onClick={() => setSubmit(true)}
        isLoading={initState[0].started}
      />
      {submit && (
        <Modal
          local={() => {
            setSubmit();
            setSendDirection();
          }}
          size="medium"
          title={filesSelected ? "Issue Files" : "Submission Details"}
        >
          <div className="flex flex-col w-full  p-4 justify-center md:mt-0  ">
            <div className=" text-sm flex flex-col  pb-4 items-center font-medium w-full  border-b-2 border-b-taskinatorMedGrey mb-2 ">
              {filesSelected && (
                <div className="flex w-full flex-col mb-2">
                  <div className="flex normal-case">
                    Files To Issue <ToolTip content="This releases these files from the task and will add them to the next if the visibility tags match" />
                  </div>
                  <div className="text-base  text-taskinatorRed border py-1 px-2 rounded-lg">
                    {Object.keys(fileSelected ?? {}).length} {Object.keys(fileSelected ?? {}).length === 1 ? "file" : "files"} selected
                  </div>
                  {!allFilesSelected && <div className="normal-case font-normal ">Not all files are selected - This stage will remain active for you to continue working on</div>}
                </div>
              )}

              <div className="flex normal-case w-full text-start">Send To Next-Task: </div>
              <div className="flex w-full md:flex-row flex-col ">
                {backMovementOptions ? (
                  // <div className=" md:w-1/2 w-full flex flex-col mr-2">
                  <div
                    onClick={() => {
                      setSendDirection((direction) => (direction === "back" ? "" : "back"));
                    }}
                    className={`${
                      sendDirection === "back" ? "md:w-3/4" : sendDirection ? "opacity-25 hover:opacity-100 md:w-1/4" : "md:w-1/2"
                    } hover:ring-2 hover:ring-taskinatorDarkGrey h-fit rounded-md cursor-pointer   border-taskinatorMedGrey  w-full flex flex-col md:mr-1 mr-0`}
                  >
                    <div
                      className={`w-full  border-taskinatorDarkGrey items-center justify-center flex rounded-t-md ${
                        sendDirection === "back" ? "text-taskinatorWhite bg-taskinatorDarkGrey border scale-50 " : "text-taskinatorDark border"
                      }  h-10 normal-case `}
                    >
                      BACK to
                    </div>

                    <div className=" border p-1 border-t-0 rounded-b border-taskinatorDarkGrey bg-taskinatorLightGrey">
                      {backMovementOptions?.map((backOptions) => {
                        const taskData = workflowData.tasks.find((task) => task._id === backOptions);

                        return nextTaskDisplay(
                          taskData,
                          fileSetByTask.files.filter((file) => Object.keys(fileSelected).includes(file.revisionTrackingId))
                        );
                      })}{" "}
                    </div>
                  </div>
                ) : (
                  <div className="border border-taskinatorMedGrey text-taskinatorMedGrey h-10 uppercase items-center justify-center md:w-1/2 w-full flex  mr-2 px-2 rounded mb-2">
                    No Options Backward
                  </div>
                )}

                {forwardMovementOptions?.length > 0 ? (
                  <div
                    onClick={() => {
                      setSendDirection((direction) => (direction === "forward" ? "" : "forward"));
                    }}
                    className={`${
                      sendDirection === "forward" ? "md:w-3/4" : sendDirection ? "opacity-25 hover:opacity-100 scale-75 md:w-1/4" : "md:w-1/2"
                    } hover:ring-2  text-taskinatorBlue hover:ring-taskinatorBlue rounded-md cursor-pointer    borderborder-taskinatorGreen md:w-1/2 w-full flex flex-col md:ml-1 ml-0`}
                  >
                    <div
                      className={`w-full  border-taskinatorBlue items-center justify-center flex rounded-t-md ${
                        sendDirection === "forward" ? "text-taskinatorWhite bg-taskinatorBlue border scale-105" : "text-taskinatorBlue border"
                      }  h-10  normal-case  `}
                    >
                      FORWARD to
                    </div>
                    {/* <Button
                      grow
                      label="Send FORWARD To"
                      customClasses={`w-full border border-taskinatorGreen ${
                        sendDirection === "forward" ? "text-taskinatorWhite bg-taskinatorGreen" : "text-taskinatorGreen"
                      }  h-10 uppercase hover:bg-taskinatorGreen hover:text-taskinatorWhite rounded-b-none`}
                      onClick={() => {
                        setSendDirection("forward");
                      }}
                    /> */}
                    {/* Next Tasks In Workflow: */}
                    <div className=" border p-1 border-t-0 rounded-b-md border-taskinatorBlue bg-taskinatorLightGrey">
                      {forwardMovementOptions?.map((forwardOptions) => {
                        const taskData = workflowData.tasks.find((task) => task._id === forwardOptions);

                        return (
                          <div key={forwardOptions._id}>
                            {nextTaskDisplay(
                              taskData,
                              fileSetByTask.files.filter((file) => Object.keys(fileSelected).includes(file.revisionTrackingId))
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : nextWorkflowOptions?.length > 0 ? (
                  <div className="md:mt-0 mt-2 md:border-t-0 border-t-2 md:pt-0 pt-2  border-taskinatorMedGrey md:w-1/2 w-full flex flex-col md:ml-1 ml-0 ">
                    {/* <div
                      className={`w-full  border-taskinatorBlue items-center justify-center flex rounded-t-md ${
                        sendDirection === "forward" ? "text-taskinatorWhite bg-taskinatorBlue border" : "text-taskinatorBlue border"
                      }  h-10  uppercase  `}
                    >
                      Mark Workflow Completed
                    </div> */}

                    <div
                      className={`w-full border border-taskinatorPurple flex items-center justify-center
                       text-taskinatorPurple bg-taskinatorHoverPurple rounded  h-10 uppercase  normal-case`}
                    >
                      to Next WORKFLOWS
                    </div>

                    {nextWorkflowOptions.map((workflow) => {
                      if (workflow !== true) return <NextWorkflowDisplayAndSelect workflow={workflow} onSelect={setNextWorkflows} isSelected={nextWorkflows[workflow._id]} />;
                    })}
                  </div>
                ) : (
                  <div className="border border-taskinatorMedGrey text-taskinatorMedGrey h-10 uppercase items-center justify-center w-1/2 flex  mr-2 px-2 rounded">No Options Forward</div>
                )}
              </div>
            </div>

            {sendDirection && (
              <div className={`border p-2 rounded-lg bg-taskinatorLightGrey shadow border-taskinatorMedGrey`}>
                {/* <div>Which Task Is Completed</div> */}
                {selectedTaskData.map((task) => {
                  return (
                    <div>
                      <div className="text-base border border-taskinatorGreen rounded-lg border-2 text-taskinatorDarkGrey font-bold uppercase py-1 px-1 w-fit">{task.taskDetails.name}</div>
                      <NewInstruction
                        customerButtonName="Add Tasks"
                        defaultValues={
                          task?.preSetTasks?.map((pst) => {
                            return { ...pst, currentJobTaskUUID: task.taskUUID };
                          }) ?? []
                        }
                        isPreset={task?.preSetTasks?.length > 0}
                        taskUUID={task.taskUUID}
                        availableTeam={task.assignee}
                        globalHandler={inputHandler}
                        toolTip={"Add Task Details For Next Task"}
                        label={"Tasks"}
                        oneRequired={true}
                        horizontal
                        inJob
                      />
                    </div>
                  );
                })}
              </div>
            )}

            {(Object.values(nextWorkflows).length > 0 || (filesSelected && !sendDirection && !workflowSelected)) && (
              <div className="normal-case">
                <Input_V4
                  sid={"inputs"}
                  id="comments"
                  validityMessage={"Minimum 6 chars required"}
                  validators={sendDirection === "back" ? [VALIDATOR_MINLENGTH(3)] : filesSelected && !sendDirection && !workflowSelected ? [VALIDATOR_MINLENGTH(3)] : []}
                  onInput={inputHandler}
                  textareaRows={5}
                  placeholder={
                    Object.values(nextWorkflows).length > 0
                      ? "Enter any details that might be helpful or members should be aware of in the next task..."
                      : "Reason for issuing files from task without a next task?"
                  }
                  label={Object.values(nextWorkflows).length > 0 ? "Submit Next Instructions" : "Reason For Issuing Files Without a next task?"}
                />

                {Object.values(nextWorkflows).length > 0 && (
                  <div className="pt-2">
                    <BulkFileUpload globalHandler={{ inputHandler: inputHandler, attachmentHandler: attachmentHandler }} type={"submission"} />
                  </div>
                )}
              </div>
            )}
            {/* {allFilesSelected && (
              <div className="flex items-center mt-2 w-full justify-center">
                <Button
                  label={"Still More To Do"}
                  onClick={() => {
                    setIsDone("moretodo");
                  }}
                  customClasses={`mx-1 grow uppercase text-sm h-8 border-taskinatorOrange border text-taskinatorOrange ${
                    isDone !== "moretodo" ? "hover:bg-taskinatorOrange hover:text-taskinatorWhite" : "bg-taskinatorOrange text-taskinatorWhite"
                  } `}
                />
                <Button
                  label={"Task Completed"}
                  onClick={() => {
                    setIsDone("done");
                  }}
                  customClasses={`mx-1 grow uppercase text-sm h-8 border-taskinatorGreen border text-taskinatorGreen ${
                    isDone !== "done" ? "hover:bg-taskinatorGreen hover:text-taskinatorWhite" : "bg-taskinatorGreen text-taskinatorWhite"
                  } `}
                />
              </div>
            )} */}
            {filesSelected && !sendDirection && !workflowSelected && (
              <div className="mt-4 text-taskinatorRed normal-case text-sm">By not selecting a Next Task these files will be removed from this step WITHOUT notification to others.</div>
            )}
            <Button
              label={filesSelected && (sendDirection || workflowSelected) ? `Issues Files To Next Task` : !filesSelected ? "Issue Next Tasks Only" : "Issue files out of this task"}
              customClasses={`${
                (sendDirection && inputState?.inputs?.toDo?.value?.length > 0) || workflowSelected || (filesSelected && !sendDirection && !workflowSelected && inputState.isValid)
                  ? "bg-taskinatorBlue"
                  : "bg-taskinatorMedGrey"
              }  text-sm text-taskinatorWhite h-10 grow w-full  `}
              disabled={sendDirection && inputState?.inputs?.toDo?.value?.length > 0 && workflowSelected && filesSelected && !sendDirection && !workflowSelected}
              isLoading={initState[0].started}
              error={initState[0].error}
              onClick={(sendDirection && inputState?.inputs?.toDo?.value?.length > 0) || workflowSelected ? () => SubmitHandler() : () => CheckInHandler()}
            />
          </div>
        </Modal>
      )}{" "}
    </>
  );
};

export default JobSubmission;
