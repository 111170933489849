import React, { useState, useEffect } from "react";
import Select from "react-select";

const DurationSelector = ({ onInput, sid, id, label, validationRequired, defaultValue, maxDuration }) => {
  const generateOptions = () => {
    const options = [{ value: "-1", label: "Receiver To Set" }];
    const maxHours = maxDuration || 1000; // Default to 1000 if no maxDuration is provided
    
    // Add increments of 15 minutes up to 1 hour
    for (let i = 1; i <= 4; i++) {
      const minutes = i * 15;
      const hours = (minutes / 60).toFixed(2); // Convert minutes to hours
      if (hours <= maxHours) {
        options.push({ value: hours, label: `${minutes} minutes` });
      }
    }

    // Add increments of 1 hour up to maxHours
    for (let i = 1; i <= maxHours; i++) {
      options.push({ value: i, label: `${i} hours` });
    }
    
    return options;
  };

  const [value, setValue] = useState(generateOptions().find((option) => option?.value?.toString() === (defaultValue?.toString() ?? "-1")));

  useEffect(() => {
    onInput(id, value?.value, validationRequired ? (value?.value ? true : false) : true, sid);
  }, [value]);

  return (
    <div className="w-full grow flex-col normal-case">
      {label && (
        <div className="flex text-sm">
          <div className="flex capitalize text-sm text-taskinatorDarkGrey">{label}</div>
          <div className={`${validationRequired ? "text-taskinatorRed" : "text-taskinatorMedGrey"} opacity-75 pl-1`}>{validationRequired ? " (Required)" : " (Optional)"}</div>
        </div>
      )}
      <Select
        options={generateOptions()}
        value={value}
        isSearchable
        onChange={(selectedOption) => {
          setValue(selectedOption);
        }}
        className="mr-2 text-xs w-full"
        maxMenuHeight={150}
      />
    </div>
  );
};

export default DurationSelector;
