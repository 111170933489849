import React, { useEffect, useState } from "react";

import DropDownSelectorV4 from "../inputs/dropDownSelector_V4";
import { MdArrowBack, MdArrowForward } from "react-icons/md";
import ToolTip from "../../utils/toolTip";

const DirectionControlSelector = ({ inputVer, inputHandler, defaultValue, sid, disabled, Options }) => {
  return (
    <div className="w-full flex flex-col items-start my-2 grow">
      <div className={`text-sm h-5 flex text-taskinatorDarkGrey`}>
        Task Activation <div className="text-taskinatorRed items-center ml-1">(Required)</div> <ToolTip content={"Sets what direction the workflow must be moving in order for this task to activate"} />
      </div>
      <div className="grow w-full">
        <DropDownSelectorV4
          color={"border-taskinatorMedGrey text-taskinatorDarkGrey font-semibold"}
          selectionColor={"text-taskinatorBlue uppercase"}
          id={"directionControl"}
          notSearchable
          type="SEARCH"
          sid={sid}
          defaultValue={defaultValue}
          options={Options}
          onSelection={inputHandler}
          disabled={disabled}
          selectionDisplayText={"Select Direction Control"}
          inputVer={inputVer}
        />
      </div>
    </div>
  );
};

export default DirectionControlSelector;
