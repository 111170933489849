import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import BulkDownloader from "../../../utils/bulkDownloader";
import CountUpTimer from "../../../utils/countUpTimer";
import IconSpinner from "../../UI_elements/iconSpinner";
import Modal from "../../UI_elements/modal";
import Tag from "../../UI_elements/tag";
import UserDisplayBubble from "../../UI_elements/userDisplayBubble";
import { useInit } from "../../hooks/useInit";
import CheckboxV4 from "../../inputs/checkbox_V4";
import BulkFileEditor from "../files/bulkFileEditor";
import BulkFileUpload from "../files/bulkFileUpload";
import FileViewAndActions from "../files/fileViewAndActions";
import { JobModalContext } from "./jobModalContext";
import JobModalDropDownContainer from "./jobModalDropDownContainer";
import JobSubmission from "./jobSubmission";
import Button from "../../UI_elements/button";
import NewInstruction from "../../forms/newInstruction";
import { FileDisplayWithEdits } from "./fileDisplayWithEdits";
import ApproveRejectForm from "../../forms/approveRejectForm";

const FileSetAction = ({ fileSetByTask, workflowData, setWorkflow }) => {
  const [selectAll, setSelectAll] = useState(false);

  const [referenceFiles, setReferenceFiles] = useState([]);
  const { initState, initStarted, initCompleted, initErrors, initReset } = useInit(2);
  const [fileSelected, setFileSelected] = useState({});
  const [bulkEdit, setBulkEdit] = useState();
  const [showFiles, setShowFiles] = useState(true); //fileSetByTask?.isAssigned
  const [addTask, setAddTask] = useState();
  const [markedAsCompleted, setMarkedAsCompleted] = useState();
  const [showReferenceFiles, setShowReferenceFiles] = useState();
  const jobData = useContext(JobModalContext);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;

  useEffect(() => {
    setCurrentPage(0);

    setSelectAll(false);
  }, [fileSetByTask?.files?.length, fileSetByTask]);

  // Calculate the current items to display
  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const movementOptions = workflowData?.workflowMap;
  const forwardMovementOptions = movementOptions?.[fileSetByTask._id]?.forward;
  const backwardMovementOptions = movementOptions?.[fileSetByTask._id]?.back;
  const nextWorkflows = movementOptions?.[fileSetByTask._id]?.nextworkflow;
  const isPlaceHolderOnly = fileSetByTask?.files?.[0]?.fileName === process.env.REACT_APP_PLACEHOLDER_CODE;
  const allFilesSelected = fileSetByTask?.files.length === Object.keys(fileSelected ?? {}).length;
  const allFilesDone = fileSetByTask?.files.every((file) => file.currentJobs?.positive === true || file.currentJobs?.positive === false);
  // Change page handler
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const fileSelectionHandler = (selectedId, isChecked, file) => {
    if (isChecked) {
      setFileSelected((prevSelected) => ({
        ...prevSelected,
        [selectedId]: file, // Add the selected ID
      }));
    } else {
      setFileSelected((prevSelected) => {
        const newSelection = { ...prevSelected }; // Create a new object
        delete newSelection[selectedId]; // Remove the ID
        return newSelection; // Return the new state
      });
    }
  };

  const CompleteHandler = () => {
    initStarted(1);

    axios
      .put(
        process.env.REACT_APP_BACKEND_URL +
          `/api/job/marktaskascompleted/${jobData?.jobId}/${workflowData?.workflowGroupId}/${fileSetByTask._id}/${fileSetByTask?.taskCompleted ? "undo" : "completed"}`,
        { data: true },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        initCompleted(1);
        setMarkedAsCompleted();
      })
      .catch((err) => {
        console.log(err);
        return initErrors(err?.response?.data?.message ?? err?.message ?? "", 1);
      });
  };

  return (
    <>
      <div className="w-full m-1">
        <JobModalDropDownContainer
          openAtStart={fileSetByTask?.isAssigned}
          textColour={"text-taskinatorDarkGrey"}
          borderColour={"border-taskinatorMedGrey"}
          backgroundColour={"bg-taskinatorLightGrey hover:bg-taskinatorHoverBlue border border-taskinatorMedGrey"}
          headerContent={
            workflowData && (
              <div className=" text-xs   text-taskinatorDarkGrey  uppercase items-center flex pb-1 h-fit grow ">
                {/* <b className="text-base">Files In: </b> */}
                {/* <b className="ml-1 px-4 py-1 items-center text-lg border border-2 rounded-xl  border-taskinatorGreen "> </b> */}
                <b className="ml-1 pr-4 py-1 items-center text-base  rounded-xl   text-taskinatorDarkGrey ">{fileSetByTask.name}</b>
                <div className="text-xs pl-2 h-full flex items-center capitalize  ml-2 ">
                  Assigned To:
                  {fileSetByTask?.assignees?.map((user, index) => {
                    if (index < 2)
                      return (
                        <div key={user._id} className="">
                          <UserDisplayBubble firstName={user.firstName} lastName={user.lastName} user={user} />
                        </div>
                      );
                  })}
                  {fileSetByTask?.assignees?.length > 2 && <div className="text-xs ">...</div>}
                </div>

                <div className="text-xs px-2 flex items-center capitalize mx-2 ">
                  <div className="pr-2 ">Visible File Tags:</div>
                  {fileSetByTask?.taskDetails?.fileTags?.map((tag, index) => {
                    // if (index < 2)
                    return (
                      <div key={tag._id} className="opacity-50">
                        <Tag tagData={tag} />
                      </div>
                    );
                  })}
                </div>

                {!jobData.jobCompleted && fileSetByTask.isManager && !fileSetByTask.isAssigned && (
                  <div className=" bg-taskinatorOrange opacity-75  px-2 text-xs text-taskinatorWhite py-0.5 font-medium rounded uppercase">You're Managing This Task</div>
                )}
                <div className="justify-end grow flex"></div>
               
              </div>
            )
          }
        >
          <div className="flex flex-col rounded-lg    grow  ">
            <FileDisplayWithEdits
              fileSetByTask={fileSetByTask}
              workflowData={workflowData}
              fileSelected={fileSelected}
              setSelectAll={setSelectAll}
              setFileSelected={setFileSelected}
              selectAll={selectAll}
              fileSelectionHandler={fileSelectionHandler}
              setWorkflow={setWorkflow}
              CompleteHandler={CompleteHandler}
              backwardMovementOptions={backwardMovementOptions}
              forwardMovementOptions={forwardMovementOptions}
              nextWorkflows={nextWorkflows}
              setAddTask={setAddTask}
              allFilesSelected={allFilesSelected}
              setMarkedAsCompleted={setMarkedAsCompleted}
            />

            <div className="flex flex-col grow"></div>
          </div>
        </JobModalDropDownContainer>
      </div>
      {bulkEdit && (
        <Modal local={() => setBulkEdit()} title="Bulk Edit" size={"medium"}>
          <BulkFileEditor
            titleLess
            bulkFileList={fileSelected}
            currentFileSetId={fileSetByTask.fileSetId}
            taskId={fileSetByTask._id}
            workflowGroupId={workflowData?.workflowGroupId}
            onResponse={() => setBulkEdit()}
            taskTags={fileSetByTask?.taskDetails?.fileTags}
          />
        </Modal>
      )}

      {addTask && <NewInstruction defaultValues={[{ primaryTaskDetails: fileSetByTask }]} defaultAsForm={() => setAddTask()} inJob setAddTask={() => {}} taskUUID={fileSetByTask.taskUUID} />}

      {markedAsCompleted && (
        <Modal local={() => setMarkedAsCompleted()}>
          <ApproveRejectForm
            message={<div>Confirm Marking Stage As Completed?</div>}
            confirmFunction={() => CompleteHandler()}
            rejectFunction={() => setMarkedAsCompleted()}
            confirmLoadingState={initState[1].started}
            error={initState[1].error}
            confirmButtonCSS={"bg-taskinatorOrange text-taskinatorBlack"}
            confirmButtonLabel="Mark As Completed"
          />
        </Modal>
      )}
    </>
  );
};

export default FileSetAction;
