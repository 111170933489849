import React, { useState, useEffect , useContext} from "react";
import { AppContext } from "../../../contexts/appContext";
import ItemSimpleDetailsElement from "../items/itemSimpleDetailsElement";

import { MdOutlineStickyNote2 } from "react-icons/md";
import AddItemsButton from "../items/addItemsButton";
import AddEditNoteForm from "../../forms/addEditNoteForm";

const TaskItemDisplayV4 = ({ globalInputHandler, globalSid, workflowAdded, type, wfid, number, tid, noNotes }) => {
  const [itemsSelected, setItemsSelected] = useState({});
  const appData = useContext(AppContext);
  const [notes, setNotes] = useState();

  // const [itemSelectorState, dispatch] = useReducer(itemSelectReducer, {});
  const isItemSelected = Object.values(itemsSelected).length > 0;

  useEffect(() => {
    globalInputHandler(
      "items",
      Object.values(itemsSelected).map((item) => {
        return { _id: item._id, currentItemDetails: item.currentItemDetails, draft: item?.draft?._id ? item.draft : null, released: item?.released?._id ? item.released : null };
      }),
      true,
      globalSid
    );
  }, [itemsSelected]);

  useEffect(() => {
    globalInputHandler("notes", notes ? notes : null, true, globalSid);
  }, [notes]);

  const removeItemHandler = (itemId) => {
    const currentState = itemsSelected;
    delete currentState[itemId];
    setItemsSelected({ ...currentState });
  };


  useEffect(()=>{

    if(!workflowAdded){
      setItemsSelected({})
    }
  }, [workflowAdded])

  return (
    <>
      <div className="w-full flex justify-center flex flex-col h-fit items-center overflow-auto scrollbar ">
        <div className="  w-full ">
          <div className=" py-2 px-4 text-taskinatorWhite bg-taskinatorBlue shadow mb-1 text-base font-semibold uppercase flex items-center rounded-lg">
            {/* <div className=" border rounded-full h-6 w-6 items-center justify-center flex font-bold border-2 mr-2 text-taskinatorDarkGrey border-taskinatorDarkGrey">3</div> */}
            <div className="flex items-center text-base">
              {/* <div className="border rounded-full justify-center flex h-6 w-6 items-center mr-2 border-2 text-sm border-taskinatorBlue text-taskinatorBlue">{number}</div> */}
              Focus Of Task</div> <div className="pl-1 text-taskinatorWhite capitalize font-normal text-base">(Optional)</div>
          </div>
          {isItemSelected && (
            <div className="w-full">
              {Object.values(itemsSelected).map((item) => {
                return (
                  <div className="pb-1 text-sm flex flex-col overflow-auto scrollbar ">
                    <ItemSimpleDetailsElement item={item} removeItem={removeItemHandler} clickable />
                    
                  </div>
                );
              })}
            </div>
          )}
          {notes && (
            <div className="w-full ">
              <div className="h-12 pb-1 text-sm flex flex-col overflow-auto scrollbar ">
                <div className="border-l border-l-4 border-l-taskinatorBlue py-2 px-4 text-taskinatorDarkGrey bg-taskinatorPaper shadow mb-1 text-sm font-semibold flex items-center ">
                  <div className="pr-1">
                    <MdOutlineStickyNote2 />
                  </div>
                  <div className="flex-grow flex-wrap">{notes}</div>
                  <div className={`text-xs cursor-pointer text-taskinatorRed hover:opacity-100 opacity-50 justify-end w-fit px-4 flex items-center `} onClick={() => setNotes()}>
                    Remove
                  </div>
                </div>
                {/* <ItemList item={item} secondary dispatch={dispatch} clickable /> */}
              </div>
            </div>
          )}
          {/* <div className="w-full flex items-center justify-center bg-taskinatorLightGrey rounded-b-lg"></div> */}
          <div className={`w-full justify-center items-center flex `}>
            <AddItemsButton searchAndAdd={true} globalSetItems={(itemData) => setItemsSelected(itemData)} disabled={!workflowAdded} type={type} wfid={wfid}  tid={tid} />{" "}
          {!noNotes && !notes && <AddEditNoteForm setNotes={setNotes}/>}
          </div>
        </div>
      </div>

    </>
  );
};

export default TaskItemDisplayV4;
