import axios from "axios";
import React, { useEffect, useState, useContext } from "react";
import IconSpinner from "../../components/UI_elements/iconSpinner";
import Modal from "../../components/UI_elements/modal";
import AddThirdParty from "../../components/forms/addthirdparty";
import { useInit } from "../../components/hooks/useInit";
import InputV4 from "../../components/inputs/input_V4";
import ContentView from "../../pageTemplates/contentView";
import LowerCaseTrim from "../../utils/lowerCaseAndTrim";
import { AppContext } from "../../contexts/appContext";
import { MdLock } from "react-icons/md";
import TagSelector from "../../components/dataSelectors/tagSelector";
import Tag from "../../components/UI_elements/tag";
import ToolTip from "../../utils/toolTip";
const ThirdParty = () => {
  const { initState, initStarted, initCompleted, initErrors } = useInit();
  const [responseData, setResponseData] = useState();
  const [modal, setModal] = useState();
  const [filter, setFilter] = useState("");
  const appData = useContext(AppContext);

  const sendRequest = () => {
    initStarted(0);
    axios
      .put(
        process.env.REACT_APP_BACKEND_URL + `/api/thirdparty/getall`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        setResponseData(response.data.teamData);

        if (modal) {
          setModal({ ...modal, user: response.data.teamData.find((user) => user._id === modal.user._id) });
        }

        initCompleted(0);
      })
      .catch((err) => {
        return initErrors(err?.response?.data?.message ?? err?.message ?? "", 0);
      });
  };

  /// get team data on start up
  useEffect(() => {
    sendRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const buttons = [
    {
      label: "Invite 3rd Party",
      customClasses: "bg-taskinatorBlue text-taskinatorWhite h-10 text-xs md:text-sm ml-2 py-2",
      onClick: () => setModal({ user: null }),
      id: "newThirdParty",
    },
  ];

  const centerContent = <div className="flex items-center sm:flex-row flex-col w-full sm:w-fit sm:justify-center  sm:mt-0 mt-1"></div>;

  return (
    <>
      <ContentView buttons={buttons} label={"Manage 3rd Parties (Beta)"} centerContent={centerContent}>
        <div className="grid-rows-2">
          <div className="grid lg:grid-cols-5 grid-cols-4 bg-taskinatorWhite text-xs font-semibold py-2 text-taskinatorDarkGrey">
            <div className="pl-2 border-r-taskinatorLightGrey border-r-2 overflow-clip">Full Name</div>
            <div className="pl-2 border-r-taskinatorLightGrey border-r-2 overflow-clip">Email</div>
            <div className="pl-2 border-r-taskinatorLightGrey border-r-2 overflow-clip">Company</div>
            <div className="pl-2 border-r-taskinatorLightGrey border-r-2 overflow-clip flex">Access Tags</div>
            <div className="pl-2 hidden lg:flex overflow-clip">Status</div>
          </div>
          {initState[0].started && (
            <div className="my-4">
              <IconSpinner size="m" />
            </div>
          )}
          {initState[0].error && <div className="text-center w-full text-taskinatorRed my-4 capitalize">{initState[0].error}</div>}
          {initState[0].finished &&
            responseData &&
            responseData
              // .filter((user) => {
              //   return (
              //     LowerCaseTrim(user.sender.firstName).includes(LowerCaseTrim(filter)) ||
              //     LowerCaseTrim(user.sender.lastName).includes(LowerCaseTrim(filter)) ||
              //     LowerCaseTrim(user.sender.email).includes(LowerCaseTrim(filter)) ||
              //     LowerCaseTrim(user.sender.company).includes(LowerCaseTrim(filter)) ||
              //     LowerCaseTrim(user.receiver.firstName).includes(LowerCaseTrim(filter)) ||
              //     LowerCaseTrim(user.receiver.lastName).includes(LowerCaseTrim(filter)) ||
              //     LowerCaseTrim(user.receiver.email).includes(LowerCaseTrim(filter)) ||
              //     LowerCaseTrim(user.receiver.company).includes(LowerCaseTrim(filter))
              //   );
              // })
              .map((user) => {
                const detailsToUser = user?.partyA?.oid.toString() !== appData.workspaceDataV4.oid.toString() ? "partyA" : "partyB";
                const mySettings = user?.partyA?.oid === appData.workspaceDataV4.oid ? "partyB" : "partyA";

                return (
                  // <div className="flex w-full">
                  <div
                    key={user[detailsToUser]._id}
                    className={`h-10 items-center grid lg:grid-cols-5 grid-cols-4 bg-taskinatorWhite my-0.5 py-1 text-sm hover:bg-taskinatorHoverBlue ${
                      user.locked ? "text-taskinatorMedGrey" : ""
                    } cursor-pointer`}
                    onClick={() => setModal({ user: user, theirDetails: detailsToUser, myDetails: mySettings })}
                  >
                    <div className="pl-2 border-r-taskinatorLightGrey border-r-2 flex">
                      <div className="capitalize mr-1 overflow-clip">{user[detailsToUser]?.firstName}</div>
                      <div className="capitalize mr-1 overflow-clip  ">{user[detailsToUser]?.lastName}</div>
                    </div>
                    <div className="pl-2 border-r-taskinatorLightGrey border-r-2 overflow-clip">{user[detailsToUser].email}</div>
                    <div className="pl-2 border-r-taskinatorLightGrey border-r-2 overflow-clip capitalize">{user[detailsToUser]?.companyName}</div>
                    <div className="pl-2 border-r-taskinatorLightGrey border-r-2 overflow-clip capitalize flex">
                      {user[detailsToUser]?.accessTagsGranted?.map?.((tag) => {
                        return (
                          <div key={tag._id}>
                            <Tag tagData={tag} />
                          </div>
                        );
                      })}
                    </div>
                    {/* <div className="pl-2 border-r-taskinatorLightGrey border-r-2 overflow-clip capitalize">
                      {user.receiver.assignedManagers.map((manager, index) => {
                        return (
                          <div
                            key={manager.value ?? index}
                            className={`border-taskinatorBlue text-taskinatorBlue border rounded py-1 px-2 capitalize mr-1 text-xs mb-1 flex items-center ${manager.locked ? "opacity-50" : ""}`}
                          >
                            {manager.locked ? (
                              <div className="pr-1">
                                <MdLock />
                              </div>
                            ) : (
                              ""
                            )}{" "}
                            {manager.firstName} {manager.lastName[0]}
                          </div>
                        );
                      })}
                    </div> */}

                    <div className={`pl-2 capitalize hidden lg:flex ${!user.locked ? (user.accepted ? "text-taskinatorGreen" : "text-taskinatorRed") : "text-taskinatorMedGrey"}`}>
                      {user.locked ? "locked" : user.accepted ? "active" : "Pending..."}
                    </div>
                  </div>
                );
              })}
        </div>
      </ContentView>

      {modal && (
        <Modal
          title={
            <div className="flex">
              {` ${modal.user ? "Edit 3rd Party" : "Add 3rd Party"}`}
              {/* <ToolTip content={"Share files with third parties who also have a Next-Task account."} /> */}
            </div>
          }
          local={() => {
            setModal();
          }}
        >
          <AddThirdParty
            user={modal.user}
            theirDetails={modal.theirDetails}
            myDetails={modal.myDetails}
            onResponse={() => {
              sendRequest();
            }}
            onCloseModal={() => setModal()}
          />
        </Modal>
      )}
    </>
  );
};

export default ThirdParty;
