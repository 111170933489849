import React, { useMemo, useCallback, useReducer, useContext, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import { AppContext } from "../../contexts/appContext";

import Button from "../UI_elements/button";
import AttachmentDisplayV4 from "../displayElements/attachmentDisplayV4";
import OldAttachmentDisplayV4 from "../displayElements/workflow/oldAttachmentDisplayV4";

import { MdAttachFile } from "react-icons/md";

const baseStyle = {};

const focusedStyle = {
  borderColor: "red",
};

const acceptStyle = {
  border: "3px solid #33ABEF",
  borderRadius: "5px",
};

const rejectStyle = {
  borderColor: "1px solid red",
};

const uploaderReducer = (state, action) => {
  let isValid = true;

  if (action.attachment) {
    for (const key in state) {
      if (key === "isValid") {
      } else if (key === action.key) {
        isValid = isValid && action.attachment.isValid;
      } else {
        isValid = isValid && state[key].isValid;
      }
    }
  }

  switch (action.type) {
    case "ATTACHMENT_ADDED":
      return { ...state, [action.key]: action.attachment, isValid: isValid };

    case "ATTACHMENT_DELETING":
      return { ...state, [action.key]: { ...state[action.key], isDeleting: true, isValid: false } };

    case "ATTACHMENT_DELETED":
      const { [action.key]: removedKey, ...newState } = state;
      return newState;

    default:
      return state;
  }
};

const OldFileUploaderV4 = ({ id, label, required, inputHandler, attachmentHandler, defaultValue, disabled }) => {
  const appData = useContext(AppContext);
  const inputId = id ? id : "Attachment";

  const [uploaderState, dispatch] = useReducer(uploaderReducer, {});

  /// this pushes the local state to the master state above on change to the local state
  const { ["isValid"]: removedKey, ["deleteRefs"]: removedDeletedRefs, ...attachmentsOnly } = uploaderState;

  // / get the details on the attachments if there are default values.
  useEffect(() => {
    if (defaultValue) {
      defaultValue.map((attachment) => {
        dispatch({
          type: "ATTACHMENT_ADDED",
          key: attachment.key,
          attachment: {
            fileName: attachment.fileName,
            uploadProgress: 100,
            inputId: inputId,
            isValid: true,
          },
        });
      });
    }
  }, [id]);

  //This sends the details to

  useEffect(() => {
    let attachmentObject = {};
    Object.keys(attachmentsOnly).map((key) => {
      attachmentObject = { ...attachmentObject, [key.split(".")[0]]: { value: { key: key, fileName: encodeURIComponent(attachmentsOnly[key].fileName) }, isValid: attachmentsOnly[key].isValid } };
    });

    if (Object.keys(attachmentsOnly).length === 0 && required) {
      attachmentObject = { ["required"]: { value: null, isValid: false } };
    }

    attachmentHandler(attachmentObject);
  }, [uploaderState, required, id]);

  const attachmentDetails =
    uploaderState &&
    Object.keys(uploaderState)
      .filter((data) => data != "isValid")
      .filter((data) => data != "deleteRefs")
      .map((key) => {
        return {
          isDeleting: uploaderState[key].isDeleting ? true : false,
          key: key,
          name: uploaderState[key].fileName,
          progress: uploaderState[key].uploadProgress,
          inputId: uploaderState[key].inputId,
        };
      })
      .flat();

  // add attachment to state
  const addAttachmentToState = useCallback(
    (attachment) => {
      let fileName = attachment.name;
      let attachmentObject;

      axios
        .put(
          process.env.REACT_APP_BACKEND_URL + `/api/attachment/attach/sign`,
          {
            fileName: fileName,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },

            withCredentials: true,
          }
        )
        .then((responseData) => {
          attachmentObject = responseData.data;

          dispatch({
            type: "ATTACHMENT_ADDED",
            key: attachmentObject.key,
            attachment: {
              ...attachmentObject,
              file: attachment,
              fileName: fileName,
              uploadProgress: 0,
              inputId: inputId,
              isValid: false,
            },
          });

          addAttachmentToAWS(attachmentObject, attachment);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    [inputId]
  );

  /// uploaded to aws
  const addAttachmentToAWS = useCallback((attachmentObject, attachment) => {
    let percentage;
    const options = {
      headers: {
        "Content-Type": attachment.type,
        "Content-Disposition": "inline",
      },
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        percentage = Math.floor((loaded * 100) / total);

        if (percentage < 100) {
          dispatch({
            type: "UPDATED_UPLOAD_PROGRESS",
            key: attachmentObject.key,
            uploadProgress: percentage,
          });
        }
      },
    };

    axios
      .put(attachmentObject.uploadURL, attachment, options)
      .then((responseData) => {
        dispatch({
          type: "ATTACHMENT_ADDED",
          key: attachmentObject.key,
          attachment: {
            fileName: attachment.name,
            uploadProgress: 100,
            inputId: inputId,
            isValid: true,
          },
        });
      })
      .catch((err) => {
        console.log("upload Error");
      });
  }, []);

  const tempDeleteAttachment = useCallback(
    (key) => {
      dispatch({
        type: "ATTACHMENT_DELETED",
        key: key,
      });
      inputHandler(key, key, true, "deleteRef");
    },
    [inputHandler]
  );

  ///delete attachment
  const deleteAttachment = useCallback((key) => {
    dispatch({
      type: "ATTACHMENT_DELETING",
      key: key,
    });

    axios
      .delete(process.env.REACT_APP_BACKEND_URL + `/api/attachment/delete/${key}`, {
        headers: {
          "Content-Type": "application/json",
        },

        withCredentials: true,
      })
      .then((responseData) => {
        dispatch({
          type: "ATTACHMENT_DELETED",
          key: key === responseData.data.key ? key : "",
        });
      })
      .catch((err) => {
        console.log("deleting error");
      });
  }, []);

  /// on drop activate the upload state function
  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.map((file) => {
        console.log("file type", file.name, file.type);
        addAttachmentToState(file);
      });
    },
    [addAttachmentToState]
  );

  const handlePaste = useCallback(
    (e) => {
      const clipboardItems = e.clipboardData.items;
      for (const item of clipboardItems) {
        if (item.kind === "file") {
          const file = item.getAsFile();
          // if (file && isAllowedMimeType(file.type)) {
          addAttachmentToState(file);
          // }
        }
      }
    },
    [addAttachmentToState]
  );

  const { getRootProps, getInputProps, open, acceptedFiles, isFocused, isDragAccept, isDragReject } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    onDrop,
    accept: {},
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  return (
    <div onPaste={handlePaste}>
      {label && (
        <div className="flex">
          <div className={`flex capitalize text-sm text-taskinatorDarkGrey`} htmlFor={label}>
            {label}
          </div>
          <div className={`${required ? "text-taskinatorRed" : "text-taskinatorMedGrey"} opacity-75 pl-1 text-sm`}>{required ? " (Required)" : " (Optional)"}</div>
        </div>
      )}
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <div
          onClick={open}
          className={`py-2  rounded-md  border-taskinatorBlue border
          flex  justify-center items-center bg-taskinatorHoverBlue flex-wrap cursor-pointer `}
        >
          <div className="text-xs text-taskinatorBlue pr-2 flex">
            {" "}
            <MdAttachFile size={"0.9rem"} />
            <div className="pl-1">Click Here Or Drag & Drop to Attach</div>
          </div>
          {/* <div className="">
            <Button
              type="button"
              disabled={disabled}
              onClick={open}
              label={
                <div className="flex items-center justify-center">
                  {" "}
                  <MdAttachFile size={"0.9rem"} /> <div className="pl-1">Attach File</div>
                </div>
              }
              customClasses={`bg-taskinatorBlue text-taskinatorWhite text-xs h-8`}
            /> 
          </div> */}
        </div>
        {isDragReject && <div className=" text-right text-xs text-taskinatorRed capitalize">file type not allowed</div>}

        <div className="flex flex-row flex-wrap">
          {attachmentDetails &&
            attachmentDetails.map((data) => {
              if (data.inputId === inputId) {
                return (
                  <OldAttachmentDisplayV4 attachmentData={data} onDelete={!defaultValue ? deleteAttachment : tempDeleteAttachment} disabled={disabled} alreadySaved={defaultValue ? true : false} />
                );
              }
            })}
        </div>
      </div>
    </div>
  );
};

export default OldFileUploaderV4;
