import { useState, useEffect } from "react";
import { Switch } from "@headlessui/react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function StandardToggle({ onInput, defaultValue, activeColour }) {
  const [enabled, setEnabled] = useState(defaultValue || false);
  const [lastState, setLastState] = useState(enabled);

  useEffect(() => {
    if (enabled !== lastState) {
      console.log("running");
      onInput(enabled);
    }
  }, [enabled]);

  return (
    <Switch
      checked={enabled}
      onChange={() => {
        setEnabled(!enabled);
        setLastState(enabled);
      }}
      className={classNames(
        enabled ? (activeColour ? activeColour : `bg-taskinatorBlue`) : "bg-taskinatorMedGrey",
        "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-taskinatorMedGrey border-transparent transition-colors duration-200 ease-in-out "
      )}
    >
      <span className="sr-only">Use setting</span>
      <span
        aria-hidden="true"
        className={classNames(
          enabled ? "translate-x-5" : "translate-x-0",
          "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-taskinatorWhite shadow ring-0 transition duration-200 ease-in-out"
        )}
      />
    </Switch>
  );
}
