import React, { useEffect, useContext } from "react";
import axios from "axios";
import { AppContext } from "../../contexts/appContext";
import { TaskContext } from "../../contexts/taskContext";
import { useInit } from "../hooks/useInit";
import { useInputV4 } from "../../stateManagement/useInputV4";
import Button from "../UI_elements/button";
import InputV4 from "../inputs/input_V4";
import FileUploaderV4 from "../inputs/fileUploader_V4";
import MemberSelector from "../dataSelectors/memberSelector";
import TagSelector from "../dataSelectors/tagSelector";
import LiveDataCheckOnInput from "../inputs/liveDataCheckOnInput";
import { VALIDATOR_MINLENGTH, VALIDATOR_REQUIRE, VALIDATOR_MAXLENGTH, VALIDATOR_MAX } from "./validators/validators";

const NewProjectForm = ({ details, onSuccess }) => {
  const appData = useContext(AppContext);
  const taskContext = useContext(TaskContext);
  const { inputState, inputHandler, attachmentHandler } = useInputV4();
  const { initState, initStarted, initCompleted, initErrors, initReset } = useInit();
  const sid = "inputs";


  console.log(inputState)

  const SubmitHandler = () => {
    initStarted(0);
    axios
      .put(
        process.env.REACT_APP_BACKEND_URL + `/api/project/${details ? `edit/${details._id}` : "create"}`,
        {
          ref: inputState.inputs.ref.value,
          notes: inputState.inputs.notes.value,
          attachments: Object.values(inputState.attachments).map((attachment) => {
            return { key: attachment.value.key, fileName: attachment.value.fileName };
          }),
          projectTags: inputState.inputs.taskTag.value,
          managers: inputState.inputs.managers.value,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + appData.tokenV4,
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        onSuccess(response.data.pid);
        initCompleted(0);
      })
      .catch((err) => {
        return initErrors(err?.response?.data?.message ?? err?.message ?? '', 0);
      });
  };

  return (
    <div className={` `}>
      {/* {!details && <div className="flex pb-2 text-taskinatorDarkGrey uppercase"><div className="text-lg border rounded-full h-6 w-6 items-center justify-center flex font-bold border-2 mr-2 text-taskinatorDarkGrey border-taskinatorDarkGrey">1</div><div className="text-md font-semibold">Task Details</div></div>} */}
      <div className="mt-2">
        <LiveDataCheckOnInput
          validators={[VALIDATOR_MINLENGTH(2)]}
          onValid={inputHandler}
          id="ref"
          sid="inputs"
          placeholder={`Enter project number or ref`}
          label={`Project Number/Reference`}
          route="project"
          minLength={2}
          defaultValue={details && details.ref}
        />
      </div>
      <div className="mt-2">
        <MemberSelector
          inputHandler={inputHandler}
          defaultValue={
            details
              ? details.managers.map((manager) => {
                  return { value: manager._id };
                })
              : []
          }
          sid={sid}
          id="managers"
          label={"Manager/s"}
          multi
          roleTypes={[process.env.REACT_APP_ACCESS_M, process.env.REACT_APP_ACCESS_O, process.env.REACT_APP_ACCESS_A]}
          notRequired
          dontAutoSelect
        />
      </div>

      <div className="mt-2">
        <InputV4
          label="Project Notes "
          id="notes"
          customClasses={"rounded-md md:text-sm "}
          placeholder="Enter Project Notes"
          textareaRows={20}
          sid={sid}
          onInput={inputHandler}
          validators={[]}
          defaultValue={details ? details.notes : null}
          // validityMessage="Min of 6"
          requiredOverride
        />
      </div>

      <div className="mt-2">
        <FileUploaderV4 label="Reference Documents" id="attachment" inputHandler={inputHandler} attachmentHandler={attachmentHandler} defaultValue={details ? details.attachments : null} />
      </div>

      <div className="pt-2">
        <TagSelector
          notRequired
          id={"taskTag"}
          inputHandler={inputHandler}
          defaultValue={
            details
              ? details.projectTags.map((tag) => {
                  return { value: tag._id };
                })
              : []
          }
          sid="inputs"
          type="project"
          label="Tags"
          selectionDisplayText={"Add/Create Job Tag"}
        />
      </div>

      <Button
        onClick={() => SubmitHandler()}
        label={details ? "Save Project" : "Create Project"}
        customClasses={`${inputState.isValid ? "bg-taskinatorBlue" : "bg-taskinatorMedGrey"} bg-taskinatorBlue text-taskinatorWhite h-10 w-full mt-4 `}
        disabled={!inputState.isValid}
        isLoading={initState[0].started}
        error={initState[0].error}
      />
    </div>
  );
};

export default NewProjectForm;
