import React, { useContext, useState } from "react";
import { AppContext } from "../../contexts/appContext";
import ContentView from "../../pageTemplates/contentView";
import JobFormatter from "../../utils/jobFormatter";
import JobDashboardElement from "../../components/dashboard/jobDashboardElement";
import InputV4 from "../../components/inputs/input_V4";
const MyJobs = () => {
  const appData = useContext(AppContext);
  const JobData = appData.jobs;
  const [filter, setFilter] = useState("");

  const centerContent = (
    <div className="flex items-center sm:flex-row flex-col w-full sm:w-fit sm:justify-center  sm:mt-0 mt-1">
      <InputV4
        customClasses={"sm:w-96 w-[100%] items-center"}
        placeholder="Search Jobs"
        onInput={(id, value, isValid, sid) => {
          setFilter(value);
        }}
        validators={[]}
      />
    </div>
  );

  return (
    <ContentView buttons={[]} label={"All My Jobs"} centerContent={centerContent}>
      {Object.values(JobFormatter(appData.jobs, filter, appData)).map((job, index) => {
        {
          return (
            <div key={job._id ?? index} className="mx-1">
              <JobDashboardElement primaryColour={"bg-taskinatorGreen"} primaryBorder={"border-l-taskinatorGreen"} job={job} textColour={"text-taskinatorGreen"} setAllJobsViewHandler={() => {}} />
            </div>
          );
        }
      })}
    </ContentView>
  );
};

export default MyJobs;
