import React, { useContext, useState, useEffect } from "react";
import { AppContext } from "../../../contexts/appContext";
import UserDisplayBubble from "../../UI_elements/userDisplayBubble";
import { MdRunningWithErrors, MdNotificationsActive, MdCheckCircle } from "react-icons/md";
import AcknowledgeNotificationButton from "../notification/acknowledgeNotificationButton";
import FormatDate from "../../../utils/formattedDate";
import DocumentDisplay from "../../UI_elements/documentDisplay";
import DateDisplay from "../../UI_elements/dateDisplay";
import ActivityDisplay from "./activityDisplay";
import LinkifyText from "../../../utils/linkifyText";

const CommentBubbleElement = ({ actionComment, tracerWorkflowGroupId, refresh, setRefresh }) => {
  const appData = useContext(AppContext);
  const isYou = actionComment.creatorId === appData.workspaceDataV4.user_id;
  const [showDetails, setShowDetails] = useState();
  // if (index <= loadMoreComments) {


  return (
    <div key={actionComment._id} className="p-2">
      <div className="flex items-center my-1 justify-between text-xs font-semibold text-taskinatorDarkGrey ">
        <div className="capitalize pr-1 "> {actionComment.creatorName}</div>
        {FormatDate(actionComment.createdDate)}{" "}
      </div>
      <div className="flex w-full items-start">
        {/* {!isYou && (
          <div className=" ">
            <UserDisplayBubble
              firstName={actionComment.creatorName.split(" ")[0]}
              lastName={actionComment.creatorName.split(" ")[1]}
              large
              colourOverride={actionComment.thirdParty ? "bg-taskinatorHoverOrange" : ""}
            />
          </div>
        )} */}
        <div className={`w-full  `}>
          <div
            key={actionComment._id}
            className={`  ${
              isYou
                ? `${actionComment.critical ? "bg-taskinatorAlertRed text-taskinatorBlack" : "bg-taskinatorPaper text-taskinatorDarkGre"} rounded-br-none  `
                : `${
                    actionComment.critical
                      ? "bg-taskinatorAlertRed text-taskinatorBlack"
                      : `${
                          actionComment.thirdParty ? "bg-taskinatorHoverOrange text-taskinatorDarkGrey border-taskinatorMedGrey border" : "rounded-bl-none  bg-taskinatorDarkGrey text-taskinatorWhite"
                        }`
                  }    `
            }   px-4 py-1 grow  shadow `}
          >
            <div className=" text-sm mr-2   ">
              <div className="flex items-center">
                {actionComment.critical && (
                  <div className="pr-1">
                    <MdNotificationsActive />
                  </div>
                )}
                <div className="flex ">
                  <div className={` uppercase font-semibold`}>{actionComment.logDescription}</div>
                  {/* <div className="px-1  uppercase">{actionComment.taskName} </div> */}
                  {/* <div className="flex">({getRunTime(actionComment.date)})</div> */}
                </div>
              </div>
              {(actionComment.comments || actionComment.attachments) && (
                <div className={`flex flex-col items-start border-t ${actionComment.critical ? "border-t-taskinatorDarkGrey" : "border-t-taskinatorMedGrey"} pt-2 mt-1 py-1`}>
                  <div className="whitespace-break-spaces">{actionComment.comments && <LinkifyText text={actionComment.comments} />}</div>
                  {actionComment.attachments.length > 0 && (
                    <div className="font-medium pt-2">
                      {actionComment.attachments.map((attachment) => {
                        return <DocumentDisplay attachment={attachment} colorChange={"bg-taskinatorLightGrey text-taskinatorDarkGrey"} />;
                      })}
                    </div>
                  )}
                </div>
              )}
            </div>
            {}
          </div>
          {
            showDetails && <ActivityDisplay actionComment={actionComment} />
          }
          <div
            onClick={() => {
              setShowDetails(!showDetails);
            }}
            className={`cursor-pointer underline text-center text-taskinatorMedGrey  ${showDetails ? "" : ""} text-xxs `}
          >
            {showDetails ? "Hide" : "Show Full Log"}
          </div>
          {actionComment.critical && actionComment.acknowledgedBy && actionComment.acknowledgedBy.length > 0 && (
            <div className="pt-2 flex items-center">
              <div className="text-xxs pr-1">Acknowledged:</div>
              {actionComment.acknowledgedBy.map((user) => {
                if (user._id === appData.workspaceDataV4.user_id && !user.acknowledged) {
                  return (
                    <div className="animate-pulse">
                      <AcknowledgeNotificationButton tracerWorkflowGroupId={tracerWorkflowGroupId} logId={actionComment._id} getNotifications={() => setRefresh(!refresh)} />
                    </div>
                  );
                } else {
                  return (
                    <div className={`${user.acknowledged ? "" : "opacity-30"}`}>
                      <UserDisplayBubble
                        textSize={"text-xxs"}
                        colourOverride={user.acknowledged ? "bg-taskinatorHoverGreen text-taskinatorWhite" : null}
                        firstName={user.firstName}
                        lastName={user.lastName}
                      >
                        {user.acknowledged && (
                          <div className="pr-1">
                            {" "}
                            <MdCheckCircle />{" "}
                          </div>
                        )}
                      </UserDisplayBubble>
                    </div>
                  );
                }
              })}
            </div>
          )}{" "}
        </div>
      </div>
    </div>
  );
};

export default CommentBubbleElement;
