import React, { useContext, useState } from "react";
import FilesGetAndSearchContainer from "../../components/displayElements/files/fileGetAndSearchContainer";
import { AppContext } from "../../contexts/appContext";
import ContentView from "../../pageTemplates/contentView";

const Focus = () => {
  const [buttons, setButtons] = useState([]);
  const appData = useContext(AppContext);
  const setEditButton = (editButton) => {
    setButtons([editButton]);
  };

  return (
    <ContentView buttons={buttons} label={"Folders & Files"} hideTopMenu>
      <FilesGetAndSearchContainer
        viewOnly
        setEditButton={(data) => setEditButton(data)}
        isRegister
        files
        searchAndAdd
        limitSelection={1}
        dontRetainSelection
      />
    </ContentView>
  );
};

export default Focus;
