import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { AppContext } from "../../../contexts/appContext";
import { TaskContext } from "../../../contexts/taskContext";
import StringShortenDisplay from "../../UI_elements/stringShortenDisplay";
import Modal from "../../UI_elements/modal";
import ItemRevisionDetailsContainer from "./itemRevisionDetailsContainer";
import { MdUpdate, MdOutlineStickyNote2, MdCheck } from "react-icons/md";
import ItemStatus from "../../UI_elements/itemStatus";
import DocumentDisplay from "../../UI_elements/documentDisplay";

import YourItemActions from "../actionDisplay/yourItemActions";
import ApproveItemButton from "../actionDisplay/approveItemButton";

import { MdGrading } from "react-icons/md";
import { IoDocumentSharp } from "react-icons/io5";

import Button from "../../UI_elements/button";
import UserDisplayBubble from "../../UI_elements/userDisplayBubble";
import AssignAsset from "../../forms/assignAsset";

const ItemActionableElementV2 = ({ item, tracer, aidDetails, stacked, viewHistory, taskDetails, getData, displayOnly, background }) => {
  const appData = useContext(AppContext);
  const taskContext = useContext(TaskContext);

  const [attachmentMarkups, setAttachmentMarkups] = useState([]);
  const [viewHistoryModal, setViewHistoryModal] = useState();
  const [convertToItemModal, setConvertToItemModal] = useState();
  const canCreateDraft = aidDetails && aidDetails.canCreateDraftItems;
  const canApprove = aidDetails && aidDetails.canApproveItems;
  const canMarkup = aidDetails?.canMarkupItems;

  const [childHover, setChildHover] = useState(false);
  const [showOptions, setShowOptions] = useState();
  const assignees = tracer?.assignee.length > 0;
  const disable = tracer?.tracerType === "workflow";
  const youCanChange = tracer?.isAssigned || tracer?.isManaged;

  // const checkIsAttachmentMarkedup = (key) => {
  //   axios
  //     .get(process.env.REACT_APP_BACKEND_URL + `/api/item/attachment/ismarkedup/${key}`, {
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       withCredentials: true,
  //     })
  //     .then((res) => setAttachmentMarkups(res.data.mark_up))
  //     .catch((err) => console.log({ err }));
  // };

  // useEffect(() => {
  //   if (item?.draft?.attachment?.key) {
  //     checkIsAttachmentMarkedup(item.draft.attachment.key);
  //   }
  // }, [item]);

  return (
    <>
      {!displayOnly && (
        <div className={` whitespace-nowrap px-0 text-xs flex md:flex-row flex-col  text-taskinatorDarkGrey justify-end grow md:items-center  pb-1 `}>
          <div className="text-base font-bold px-1 whitespace-nowrap text-taskinatorDarkGrey uppercase ">Focus Of task</div>
          <div
            onClick={(event) => {
              event.stopPropagation();
            }}
            className="  md:justify-end justify-start grow  items-center  flex  "
          >
            {item?._id && (
              <div className="flex items-center h-full">
                {assignees && youCanChange && !disable && (
                  <div
                    className="flex items-center h-full "
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                  >
                    {canCreateDraft && (
                      <div className="pr-1 ">
                        <YourItemActions
                          item={item}
                          tracer={tracer}
                          aidDetails={aidDetails}
                          state="draft"
                          onSuccess={() => {
                            getData();
                          }}
                        />
                      </div>
                    )}
                    {canMarkup && !disable && item?.draft && (
                      <div className="pr-1">
                        <YourItemActions
                          item={item}
                          tracer={tracer}
                          aidDetails={aidDetails}
                          state="markup"
                          onSuccess={() => {
                            getData();
                          }}
                        />
                      </div>
                    )}
                    {canApprove && !disable && (
                      <div className="pr-1">
                        <YourItemActions
                          item={item}
                          tracer={tracer}
                          aidDetails={aidDetails}
                          state="released"
                          onSuccess={() => {
                            getData();
                          }}
                        />
                      </div>
                    )}
                  </div>
                )}

                {item?.currentItemDetails?.wd && canCreateDraft && viewHistory && (
                  <div className="grow lg:justify-end justify-start  w-full md:flex items-center hidden">
                    <Button
                      customClasses={
                        "border-taskinatorDarkGrey text-taskinatorDarkGrey  hover:bg-taskinatorDarkGrey  hover:text-taskinatorWhite uppercase items-center text-xs h-8 px-2 border rounded"
                      }
                      label="Working Directory"
                      onClick={(event) => {
                        event.stopPropagation();
                        window.open(`${item.currentItemDetails?.wd}`, `_blank`, `noopener, noreferrer`);
                      }}
                    />
                    {/* <div
                      onMouseEnter={() => setChildHover(true)}
                      onMouseLeave={() => setChildHover(false)}
                     
                      class={"border-taskinatorMedGrey text-taskinatorMedGrey  hover:bg-taskinatorMedGrey  hover:text-taskinatorWhite uppercase items-center text-xs h-8 px-2 border rounded"}>
                      Working Directory
                    </div> */}
                  </div>
                )}
              </div>
            )}

            {!item?._id && !disable && (
              <div className="flex w-full justify-end   grow  ">
                <div
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                  className=" flex items-center justify-start "
                >
                  <div className="">
                    {/* {!tracer.note && <div className=" text-xs flex px-4 rounded py-2 font-medium text-taskinatorWhite bg-taskinatorMedGrey">{`No ${appData.itemName}`}</div>} */}
                    {/* {tracer.note && (
                      <div className=" py-2 pl-1 pr-2 text-taskinatorDarkGrey bg-taskinatorPaper shadow mb-1 text-xs font-semibold flex items-center">
                        <div className="pr-1">
                          <MdOutlineStickyNote2 />
                        </div>
                        <div className="flex-grow flex-wrap">{tracer.note}</div>
                      </div>
                    )} */}
                  </div>
                </div>

                <div
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                  className=""
                >
                  <Button
                    label={`Add ${appData.itemName}`}
                    onClick={() => {
                      setConvertToItemModal(true);
                    }}
                    customClasses={`border rounded 
                         text-taskinatorBlue  ml-2 hover:bg-taskinatorBlue hover:text-taskinatorWhite   text-xs  py-2 `}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      <div className={` items-center flex flex-col ${background ? "bg-taskinatorWhite border border-taskinatorMedGrey rounded-md p-2 " : ""} `}>
        {!item?._id && (
          <div
            className={` text-sm flex  md:h-10 h-fit md:py-0 py-2 px-4 font-medium ${
              tracer.note ? "bg-taskinatorItemBlue text-taskinatorDarkGrey" : "text-taskinatorDarkGrey bg-taskinatorMedGrey "
            }  items-center  w-full uppercase ${displayOnly ? "rounded-t-md hover:bg-taskinatorBlue cursor-pointer hover:text-taskinatorWhite" : "rounded-md"} `}
            onClick={displayOnly ? () => displayOnly() : () => {}}
          >
            {tracer.note ? (
              <div className="flex items-center justify-center flex-nowrap">
                <MdGrading size={"1rem"} /> <div className="px-1">|</div> {tracer.note}
              </div>
            ) : (
              `No ${appData.itemName} Added Yet`
            )}
          </div>
        )}
        {item?._id && (
          <div className="flex-col flex w-full text-sm ">
            {/* <div className="pr-2 text-taskinatorDarkGrey whitespace-nowrap md:mb-0 mb-1">{`${appData.itemName}:`}</div> */}
            <div
              onClick={!childHover ? (!displayOnly ? () => setViewHistoryModal(true) : () => displayOnly()) : () => {}}
              // onClick={!childHover ? () => setShowOptions(!showOptions) : () => {}}
              className={`flex justify-start items-center  w-full  bg-taskinatorItemBlue ${
                displayOnly || item.markups ? "rounded-t-md" : "rounded-md"
              } md:h-10 h-fit md:py-0 py-2 px-4   text-taskinatorDarkGrey ${
                viewHistory
                  ? `${childHover ? "" : "hover:bg-taskinatorBlue hover:text-taskinatorWhite  cursor-pointer"}`
                  : `${childHover ? "" : "hover:bg-taskinatorBlue hover:text-taskinatorWhite  cursor-pointer"}`
              }`}
            >
              <div className="flex  flex-col md:flex-row grow md:items-center">
                <div className={`flex  font-medium w-fit items-center  pr-2 `}>
                  {/* <div className="uppercase text-taskinatorDarkBlue pr-2 text-xs">{documentName}:</div> */}

                  {item?.draft?._id && (
                    <div className={`flex text-taskinatorOrange pr-1 border-r border-r-taskinatorMedGrey mr-1`}>
                      <MdUpdate size={"1rem"} />
                    </div>
                  )}
                  <div className="">{item?.currentItemDetails?.itemNumber}</div>
                  <div className="px-2 ">-</div>
                  <div className=" uppercase w-fit whitespace-nowrap">{StringShortenDisplay(40, item?.currentItemDetails?.itemName)}</div>

                  <div className={`lg:block hidden px-2 font-bold text-taskinatorMedGrey `}>|</div>
                </div>

                <div className={` flex xl:items-center   `}>
                  {!item?.draft?._id && (
                    <div className={` items-center flex justify-center  `}>
                      {item?.released ? (
                        <div className="flex items-center justify-center ">
                          <div className="pr-2 ">
                            <ItemStatus status={item?.released?.status} />
                          </div>
                          <div className="pr-2 ">Revision: </div>
                          <div className="font-semibold">{item?.released?.revision}</div>

                          <div className={`${stacked ? "hidden" : ""} px-4 font-bold text-taskinatorMedGrey `}>|</div>
                          <div className="pr-2 ">Attachment: </div>
                          <div
                            onMouseEnter={() => setChildHover(true)}
                            onMouseLeave={() => setChildHover(false)}
                            className="font-semibold "
                            onClick={(event) => {
                              event.stopPropagation();
                            }}
                          >
                            {item?.released?.attachment ? (
                              <DocumentDisplay
                                viewMarkupsOnly={true}
                                attachment={item.released.attachment}
                                notClickable={!viewHistory}
                                item={{ ...item?.released, itemDetails: { itemName: item.currentItemDetails.itemName, itemNumber: item.currentItemDetails.itemNumber } }}
                                itemId={item.released._id}
                              />
                            ) : (
                              <div className="">None</div>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div className="text-xs text-taskinatorRed opacity-60 justify-center flex w-full">No Current Release</div>
                      )}
                    </div>
                  )}

                  <div className={`items-center flex justify-start  `}>
                    {item?.draft?._id && (
                      <div className="flex items-center text-taskinatorDarkGrey flex-row  grow">
                        <div className="flex">
                          <div className="pr-2 items-center justify-center flex ">
                            <ItemStatus status={item.draft.status} />
                            {item.draft.approvalStatus && <div className="flex text-taskinatorGreen items-center  pl-2 font-semibold"> Approved </div>}
                          </div>

                          <div className="flex">
                            <div className="pr-2 ">Revision: </div>
                            <div className="font-semibold">{item.draft.revision}</div>
                          </div>
                        </div>
                        <div className={`${stacked ? "hidden" : ""} px-4 font-bold text-taskinatorMedGrey `}>|</div>

                        <div className="pr-2">Attachment: </div>
                        <div
                          onMouseEnter={() => setChildHover(true)}
                          onMouseLeave={() => setChildHover(false)}
                          className="font-semibold items-center  justify-center flex "
                          onClick={(event) => {
                            event.stopPropagation();
                          }}
                        >
                          {item?.draft?.attachment ? (
                            <DocumentDisplay
                              markup={canMarkup}
                              viewMarkupsOnly={!canMarkup ? true : false}
                              attachment={item.draft.attachment}
                              notClickable={!viewHistory}
                              itemId={item.draft._id}
                              item={{ ...item?.draft, itemDetails: { itemName: item.currentItemDetails.itemName, itemNumber: item.currentItemDetails.itemNumber } }}
                            />
                          ) : (
                            <div className="">{`None`}</div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="text-xxs w-full flex gap-2 justify-start items-center h-fit px-4 py-2 bg-taskinatorHoverPurple rounded-br-md rounded-bl-md">
              {attachmentMarkups?.length > 0 && (
                <div className=" text-xxs">
                  <span className="pr-1 font-semibold">Live Markups By:</span>
                  {attachmentMarkups.map((item) => (
                    <span className={`mr-1 w-fit h-fit rounded-md py-1 px-2 ${item === "YOU" ? "bg-taskinatorGreen text-taskinatorWhite" : "bg-taskiantorMedDarkGrey text-taskinatorBlack"}`}>
                      {item}
                    </span>
                  ))}
                </div>
              )}

              <span className="font-semibold">Manually Attached Markups:</span>
              {item?.markups?.length > 0 ? (
                item.markups.map((markup, index) => (
                  <div
                    onMouseEnter={() => setChildHover(true)}
                    onMouseLeave={() => setChildHover(false)}
                    className="font-semibold "
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                  >
                    {markup.attachment ? <DocumentDisplay attachment={markup.attachment} notClickable={!viewHistory} itemId={markup._id} item={item} /> : <div className="">None</div>}
                  </div>
                ))
              ) : (
                <div>None Attached</div>
              )}
            </div> */}
          </div>
        )}
      </div>

      {viewHistoryModal && (
        <Modal
          size="max"
          title={`${appData.itemName} Revision History`}
          bgColour="bg-taskinatorLightGrey"
          local={() => {
            setViewHistoryModal();
          }}
        >
          <ItemRevisionDetailsContainer iid={item._id} aidDetails={aidDetails} />
        </Modal>
      )}

      {convertToItemModal && (
        <Modal title={`Select One ${appData.itemName}`} size="max" bgColour="bg-taskinatorLightGrey" local={() => setConvertToItemModal()}>
          <AssignAsset tracer={tracer} closeModal={() => setConvertToItemModal()} taskDetails={taskDetails} getData={() => getData()} />
        </Modal>
      )}
    </>
  );
};

export default ItemActionableElementV2;
