import React, { useContext } from "react";
import { AppContext } from "../../../contexts/appContext";
import Button from "../../UI_elements/button";
import { useInit } from "../../hooks/useInit";
import axios from "axios";
import { useParams } from "react-router-dom";
import { TaskContext } from "../../../contexts/taskContext";

const WatchTask = ({ taskDetails, getData }) => {
  const appData = useContext(AppContext);
  const taskContext = useContext(TaskContext);
  const { initState, initStarted, initCompleted, initErrors, initReset } = useInit();

  const isAWatcher = taskDetails.watchers
    ? taskDetails.watchers
        .map((watcher) => {
          return watcher._id.toString();
        })
        .includes(appData.workspaceDataV4.user_id)
    : false;

  const onClickHandler = () => {
    initStarted(0);
    axios
      .put(
        process.env.REACT_APP_BACKEND_URL + `/api/task/watch/${isAWatcher ? "stop" : "start"}/${taskDetails.tid}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        getData();
        initCompleted(0);
      })
      .catch((err) => {
        console.log(err);
        return initErrors(err.message ? err.response.data.message : err.response.data, 0);
      });
  };

  return (
    <div className="">
      <Button
        onClick={() => {
          onClickHandler();
        }}
        label={isAWatcher ? "Stop" : `Start`}
        customClasses={`${isAWatcher ? " border-taskinatorRed text-taskinatorRed" : "border-taskinatorGreen text-taskinatorGreen"} border rounded py-0.5 px-1 text-sm`}
        isLoading={initState[0].started}
        error={initState[0].error}
      />
    </div>
  );
};

export default WatchTask;
