import React, { useEffect, useState } from "react";
import { useInit } from "../../hooks/useInit";
import axios from "axios";
import FilesileListViewDisplayer from "../files/fileListViewDisplayer";
import CheckboxV4 from "../../inputs/checkbox_V4";
import Button from "../../UI_elements/button";
import InputV4 from "../../inputs/input_V4";
import LowerCaseTrim from "../../../utils/lowerCaseAndTrim";
import IconSpinner from "../../UI_elements/iconSpinner";

const JobFileViewer = ({ fileSets, setGlobalFiles, excludeList, setExcludeList, startingWorkflow, globalInputHandler, globalSid }) => {
  const { initState, initStarted, initCompleted, initErrors, initReset } = useInit(2);
  const [files, setFiles] = useState([]);
  const [localSelection, setLocalSelection] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const fileSelectionHandler = (selectedId, isChecked, file) => {
    if (isChecked) {
      setLocalSelection((prevSelected) => ({
        ...prevSelected,
        [selectedId]: file, // Add the selected ID
      }));
    } else {
      setLocalSelection((prevSelected) => {
        const newSelection = { ...prevSelected }; // Create a new object
        delete newSelection[selectedId]; // Remove the ID
        return newSelection; // Return the new state
      });
    }
  };

  const GetFiles = () => {
    initStarted(1);
    axios
      .put(
        process.env.REACT_APP_BACKEND_URL + `/api/file/autosequence`,
        {
          fileSetsIds: fileSets?.map((fileSet) => fileSet._id),
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        setFiles(response.data.files);
        setGlobalFiles?.(response.data.files);
        initCompleted(1);
      })
      .catch((err) => {
        console.log(err);
        initErrors(err?.response?.data?.message ?? err?.message ?? "", 1);
      });
  };

  useEffect(() => {
    GetFiles();
  }, [fileSets]);

  useEffect(() => {
    globalInputHandler?.("files", files, true, globalSid);
  }, [files]);

  const excludeSelectionHandler = () => {
    let newList = {};

    Object.keys(localSelection).forEach((key) => {
      newList = { ...newList, [key]: key };
    });

    setExcludeList(newList);
  };

  const includeSelectionHandler = () => {
    let newList = {};

    files
      .filter((file) => !localSelection[file.revisionTrackingId.toString()])
      .forEach((file) => {
        newList = { ...newList, [file.revisionTrackingId.toString()]: file._id.toString() };
      });

    setExcludeList(newList);
  };

  return (
    <div className="flex flex-col w-full ">
      {/* <div className="xl:mt-0 mt-1  py-1 px-4 bg-taskinatorMedGrey shadow mb-1 font-semibold uppercase flex items-center rounded-lg w-full">
        <div className="flex items-center text-taskinatorDarkGrey">Files | {files.length - Object.keys(excludeList ?? {}).length} Selected</div>
        <div className="flex grow justify-end">
          {" "}
          <InputV4
            onInput={(id, value, isValid, sid) => {
              setSearchValue(LowerCaseTrim(value));
            }}
            customClasses="text-center border-taskinatorDarkGrey md:w-96 w-[100%]"
            defaultValue={""}
            placeholder={`File search`}
            sid="inputs"
            id="search"
            validators={[]}
          />
        </div>
      </div> */}

      <div className="pl-2 flex items-center text-sm py-1">
        <CheckboxV4
          label={`Select All Files (${Object.values(localSelection ?? {}).length})`}
          onInput={(id, isChecked, isValid, sid) => {
            setSelectAll(isChecked);

            if (!isChecked) {
              setLocalSelection({});
            }
          }}
          defaultValue={selectAll}
          customCheckbox={"h-5 w-5"}
        />
        <div className="flex pl-2">
          <Button
            label="Exclude Selected"
            customClasses={"underline cursor-pointer hover:opacity-75 text-taskinatorBlue"}
            onClick={() => {
              excludeSelectionHandler();
            }}
          />
          <Button onClick={() => includeSelectionHandler()} label="Include Selected" customClasses={"underline cursor-pointer hover:opacity-75 text-taskinatorBlue"} />
        </div>
      </div>

      <div className="w-full overflow-x-auto flex flex-col scrollbar">
        <div className="text-nowrap flex bg-taskinatorWhite mb-0.5   p-2 grow text-xxs font-medium text-taskinatorDarkGrey w-full">
          <div className="w-5 mr-1.5"> </div>
          <div className="px-2 sm:flex hidden text-taskinatorMedGrey">|</div>
          <div className="w-20 pr-2 text-center">Status</div>
          <div className="px-2  sm:flex hidden text-taskinatorMedGrey">|</div>
          <div className=" w-20 text-center justify-center sm:flex hidden">Date</div>
          <div className="px-2  sm:flex hidden text-taskinatorMedGrey">|</div>
          <div className="w-12 overflow-hidden">Revision</div>
          <div className="px-2 text-taskinatorMedGrey">|</div>
          <div className="w-60 px-2">File Name</div>
          <div className="px-2 text-taskinatorMedGrey ">|</div>
          <div className="w-40 text-no-wrap">Description</div>
        
          <div className="px-2 text-taskinatorMedGrey">|</div>
          <div className="w-80">Task Visibility Tag</div>
          <div className="px-2 text-taskinatorMedGrey">|</div>
          <div className="w-42">Starting workflow</div>
          <div className="grow flex"></div>
        </div>

        {initState[1].started && (
          <div className="flex grow py-2 bg-taskinatorWhite justify-center">
            <IconSpinner size="m" />
          </div>
        )}
        {fileSets?.length > 0 &&
          files?.length > 0 &&
          files.map((file, index) => {
            let isVisible = false;

            if (searchValue && searchValue?.length > 0) {
              isVisible =
                LowerCaseTrim(file?.fileName)?.includes(searchValue) ||
                LowerCaseTrim(file?.fileExtension)?.includes(searchValue) ||
                LowerCaseTrim(file?.status)?.includes(searchValue) ||
                LowerCaseTrim(file?.revision)?.includes(searchValue) ||
                !!file.settings.fileTags.find((tag) => LowerCaseTrim(tag.name).includes(searchValue));
            } else isVisible = true;

            return (
              //   <div className="mb-0.5 text-sm flex flex-col bg-taskinatorWhite h-full border border-taskinatorMedGrey rounded-lg  w-full">
              <FilesileListViewDisplayer
                file={file}
                fileId={file._id}
                isVisible={isVisible}
                showAuto
                index={index}
                selectAll={selectAll}
                localSelection={localSelection}
                onSelectHandler={(isChecked, selectedId, file, fileSetId) => {
                  fileSelectionHandler(selectedId, isChecked, file);
                }}
                initState={initState}
                excludeList={excludeList}
                excludeHandler={() => {
                  if (excludeList[file.revisionTrackingId.toString()]) {
                    const updatedList = { ...excludeList };
                    delete updatedList[file.revisionTrackingId.toString()];
                    setExcludeList(updatedList);
                  } else {
                    setExcludeList({ ...excludeList, [file.revisionTrackingId.toString()]: true });
                  }
                }}
                createJob
                startingWorkflow={startingWorkflow}
              />
              //   </div>
            );
          })}
      </div>
    </div>
  );
};

export default JobFileViewer;
