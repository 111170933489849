import axios from "axios";
import React, { useContext, useState } from "react";
import { MdLock } from "react-icons/md";
import { AppContext } from "../../contexts/appContext";
import ColourGenerator from "../../utils/colourGenerator";
import Modal from "../UI_elements/modal";
import Tag from "../UI_elements/tag";
import ApproveRejectForm from "../forms/approveRejectForm";
import { useInit } from "../hooks/useInit";
import WorkflowDisplay from "./workflowDisplay";

const WorkflowContainer = ({ onEditHandler, workflowData, teamData, onResponse, workflowOptions }) => {
  const appData = useContext(AppContext);
  const { initState, initStarted, initCompleted, initErrors } = useInit();
  const [zoomPos, setZoomPos] = useState(0);
  const [status, setStatus] = useState(workflowData.isValid);
  const [modal, setModal] = useState();
  const zoom = ["scale-100", "scale-90", "scale-75", "scale-50"];

  const zoomHandler = (direction) => {
    if (direction === "in") {
      if (zoomPos !== 0) {
        setZoomPos(zoomPos - 1);
      }
    }

    if (direction === "out") {
      if (zoomPos !== 3) {
        setZoomPos(zoomPos + 1);
      }
    }
  };

  const archiveConfirmHandler = async () => {
    initStarted(0);

    try {
      const responseData = await axios.delete(
        process.env.REACT_APP_BACKEND_URL + `/api/workflow/delete/${workflowData._id}`,

        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      initCompleted(0);
      onResponse();
      setModal();
    } catch (err) {
      console.log(err);
      initErrors(err?.response?.data?.message ?? err?.message ?? "", 0);
    }
  };

  return (
    <>
      <div className="bg-taskinatorWhite mb-2 shadow-lg border border-taskinatorMedGrey">
        <div className="border-b border-b-taskinatorMedGrey p-4 flex items-center">
          <div className="flex items-center">
            <div
              className={` uppercase text-taskinatorDarkGrey  font-bold pr-4 py-1 rounded-md text-lg
       
              `}
            >
              {workflowData?.name}
            </div>
            <div
              className={`${
                status?.status ? "text-taskinatorGreen border-taskinatorGreen border rounded" : "text-taskinatorRed rounded border-taskinatorRed border  "
              } text-xxs ml-1 font-medium py-1 px-2`}
            >
              {status?.status ? "Active" : status?.reason}
            </div>
          </div>
          <div className="border-l-2 border-l-taskinatorMedGrey ml-4 pl-4 text-sm items-center pr-2">Managers:</div>
          {workflowData.managers.map((manager) => {
            return (
              <div key={manager._id} className={`mr-1 text-xs items-center border border-taskinatorBlue text-taskinatorBlue rounded py-1 px-2 capitalize flex ${manager.locked ? "opacity-50" : ""}`}>
                {manager.locked ? (
                  <div className="pr-1">
                    <MdLock />
                  </div>
                ) : (
                  ""
                )}
                {`${manager.firstName} ${manager.lastName[0]}`}
              </div>
            );
          })}
          <div className="border-l-2 border-l-taskinatorMedGrey ml-2 pl-2 pr-2 text-sm items-center hidden">Access Tags:</div>
          {workflowData.accessTags.map((tag) => {
            return <Tag tagData={tag} key={tag._id} />;
          })}
          <div className="md:grow md:justify-end hidden md:flex">
            <div onClick={() => setModal(true)} className=" rounded cursor-pointer hover:opacity-100 opacity-25 text-xs text-taskinatorRed py-1 px-2">
              Delete Workflow
            </div>
          </div>
          <div className="hover:text-taskinatorBlue cursor-pointer pr-4 underline text-sm flex justify-end px-4" onClick={() => onEditHandler(workflowData)}>
            Edit{/* <MdOutlineMoreHoriz size={"1.25rem"} /> */}
          </div>
        </div>
        <div className={`p-4`}>
          <WorkflowDisplay zoom={zoom[zoomPos]} workflowData={workflowData} teamData={teamData} setStatus={setStatus} workflowOptions={workflowOptions} />
        </div>
      </div>
      {modal && (
        <Modal local={() => setModal()}>
          <ApproveRejectForm
            message={`Are you sure you want to DELETE this Sequence?`}
            requiredTextMatch={workflowData.name}
            confirmFunction={() => archiveConfirmHandler()}
            rejectFunction={() => setModal()}
            confirmLoadingState={initState[0].started}
            error={initState[0].error}
            confirmButtonLabel="DELETE"
            confirmButtonCSS={"bg-taskinatorRed "}
          />
        </Modal>
      )}
    </>
  );
};

export default WorkflowContainer;
