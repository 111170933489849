import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Button from "../../components/UI_elements/button";
import { AppContext } from "../../contexts/appContext";
import CheckboxV4 from "../../components/inputs/checkbox_V4";
import ApproveRejectForm from "../../components/forms/approveRejectForm";
import Modal from "../../components/UI_elements/modal";

const DRIVE_OPTIONS = {
  AWS: "AWS",
  GOOGLE: "GOOGLE",
  MICROSOFT: "MICROSOFT",
};

let previousOption = "";

const DriveSettings = ({ plan }) => {
  const appData = useContext(AppContext);
  const [selectedOption, setSelectedOption] = useState(appData.workspaceDataV4.driveOption);

  const [modal, setModal] = useState();
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const [driveStatus, setDriveStatus] = useState(undefined);

  useEffect(() => {
    setSelectedOption(appData.workspaceDataV4.driveOption);
  }, [appData.workspaceDataV4.driveOption]);

  const selectHandler = (type) => {
    setLoading(true);
    if (type === DRIVE_OPTIONS.GOOGLE) {
    }

    if (type === DRIVE_OPTIONS.MICROSOFT) {
      window.location.href = `${process.env.REACT_APP_BACKEND_URL}/api/auth/microsoft`;
    }

    if (type === DRIVE_OPTIONS.AWS) {
    }
  };

  // useEffect(() => {
  //   if (selectedOption === "google" && appData.workspaceDataV4.driveOption !== "google") userGoogleLogin();
  //   if (selectedOption === "microsoft" && appData.workspaceDataV4.driveOption !== "microsoft") userMicrosoftLogin();
  // }, [selectedOption, appData.workspaceDataV4]);

  // useEffect(() => {
  //   if (location.search) {
  //     const queryParams = new URLSearchParams(location.search);
  //     const status = queryParams.get("status");
  //     if (status) {
  //       window.opener.postMessage(status, window.location.origin);
  //       window.close();
  //     }
  //   }
  // }, [location]);

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.origin === window.location.origin) {
        setDriveStatus(event.data);
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  // useEffect(() => {
  //   if (driveStatus === "success") {
  //     appData.getWorkspaceData();
  //     toast.success(`Successfully setuped with ${DRIVE_OPTIONS[selectedOption]}`, {
  //       position: "top-center",
  //       hideProgressBar: true,
  //     });
  //   }
  //   if (driveStatus === "failed") {
  //     toast.error(`Failed setup with ${DRIVE_OPTIONS[selectedOption]}`, {
  //       position: "top-center",
  //       hideProgressBar: true,
  //     });
  //     setSelectedOption(previousOption);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [driveStatus]);

  console.log(appData?.workspaceDataV4?.planSelected);
  return (
    <div>
      <div className="font-semibold text-sm">{`Select storage option ${plan?.FREE ? `Premium Feature - Will require a paid plan after 30 days trial` : ""}`}</div>
      <div className="w-full flex flex-col items-start pt-2 text-sm gap-2 border-t border-taskinatorMedGrey mt-2">
        {DRIVE_OPTIONS.AWS === selectedOption && (
          <div>
            Current default: <b>Next-Task AWS (Sydney)</b>
          </div>
        )}
        {/* <CheckboxV4 label="Next-Task AWS (Sydney)" onInput={(id, value, isValid, sid) => {}} defaultValue={DRIVE_OPTIONS.AWS === selectedOption} id="aws" sid="inputs" />
        <label className="text-taskinatorDarkGrey text-xs font-medium flex items-center gap-1 hover:cursor-pointer"> */}

        {/* <label className="text-taskinatorDarkGrey text-xs font-medium flex items-center gap-1 hover:cursor-pointer">
          <input
            type="radio"
            value="google"
            checked={selectedOption === "google"}
            onChange={handleOptionChange}
          />
          Google Drive
        </label> */}
        {/* <div className="font-medium text-taskinatorDarkGrey mt-2">Selected Storage</div> */}
        <CheckboxV4
          customCheckbox={"h-4 w-4"}
          label={
            <div className="flex h-8 items-center w-fit text-nowrap">
              {" "}
              <img className="pr-2" src="/OneDriveLogo.svg" style={{ width: "auto", height: "auto", maxWidth: "100%", maxHeight: "100%" }} />
              Microsoft OneDrive <div className={`pl-2 text-taskinatorGreen font-medium ${DRIVE_OPTIONS.MICROSOFT === selectedOption ? "" : "hidden"}`}>(Primary Storage)</div>
            </div>
          }
          onInput={(id, value, isValid, sid) => {
            if (value && DRIVE_OPTIONS.MICROSOFT !== selectedOption) {
              setModal(DRIVE_OPTIONS.MICROSOFT);
            }
          }}
          disabled={DRIVE_OPTIONS.MICROSOFT === selectedOption }
          defaultValue={DRIVE_OPTIONS.MICROSOFT === selectedOption}
          id="aws"
          sid="inputs"
        />
        {appData?.workspaceDataV4?.planSelected?.freePlan && <div className="text-taskinatorRed">You Must Activate A Paid Plan To Store Files On OneDrive</div>}

        {/* <label className="text-taskinatorDarkGrey text-xs font-medium flex items-center gap-1 hover:cursor-pointer">
          <input type="radio" value="microsoft" checked={selectedOption === "microsoft"} onChange={handleOptionChange} />
          Microsoft Onedrive
        </label> */}
      </div>
      {/* <div className="pt-4">
        <Button label={"Save Changes"} customClasses={"bg-taskinatorBlue h-8 py-2 text-taskinatorWhite text-xs"} />
      </div>
      <div>
        <ToastContainer />
      </div> */}

      {modal && (
        <Modal
          title="Confirm Drive Storage"
          local={() => {
            setModal();
          }}
        >
          <ApproveRejectForm
            message={
              <div className="flex flex-col">
                <span className="mb-1">Select {DRIVE_OPTIONS[modal]} for your default storage? </span>
                <span className="text-taskinatorRed">
                  {" "}
                  <b>WARNING!</b> - this puts you in control of your data. Dashboard do not modify, move or delete on {DRIVE_OPTIONS[modal]} or you WILL loose connection with next-task and this cannot
                  be undone.
                </span>
              </div>
            }
            // requiredTextMatch={workflowData.name}
            confirmFunction={() => selectHandler(modal)}
            rejectFunction={() => setModal()}
            confirmButtonLabel="CONFIRM"
            rejectButtonLabel="CANCEL"
            confirmButtonCSS={"bg-taskinatorBlue "}
            confirmLoadingState={loading}
            requiredTextMatch="ACKNOWLEDGE"
          />
        </Modal>
      )}
    </div>
  );
};

export default DriveSettings;
