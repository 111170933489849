import React, { useState, useEffect } from "react";
import { MdPlayArrow } from "react-icons/md";

const JobModalDropDownContainer = ({
  headerContent,
  children,
  backgroundColour,
  openAtStart,
  thin,
  noDropDownBorder,
  onOpen,
  onClose,
  autoRender,
  textColour,
  borderColour,
  contentBackGround,
  nonClosing,
}) => {
  const [dropdown, setDropDown] = useState(openAtStart ? true : false);

  useEffect(() => {
    if (openAtStart) {
      setDropDown(openAtStart);
    }
  }, [openAtStart]);

  return (
    <>
      <div
        onClick={
          nonClosing
            ? () => {}
            : () => {
                setDropDown((current) => {
                  return !current;
                });
              }
        }
        className={`${nonClosing ? "" : "cursor-pointer"} text-sm pr-4 pl-2 ${textColour ?? "text-taskinatorWhite"} flex w-full  z-0  ${backgroundColour}   ${
          dropdown ? "rounded-t-lg border-b-0" : `${noDropDownBorder ? "rounded-t-lg" : "rounded-lg"}`
        }  ${thin ? "h-fit" : "min-h-12 h-fit"}  md:py-0 py-2 flex-col justify-center items-start 
`}
      >
          <div className="flex w-full items-center text-base uppercase whitespace-nowrap  ">
          <div className="flex justify-start grow">
          {headerContent}</div>
       
          <div className="flex w-fit justify-end  ml-4 border-l border-l-2 my-0.5">
            <div className={`text-sm flex justify-center items-center ${textColour ?? "text-taskinatorWhite "} hover:taskinatorWhite h-6 w-6 ${dropdown ? "rotate-90" : ""}`}>
              <MdPlayArrow size={"1.25rem"} />
            </div>
          </div>
        </div>
      </div>
      {(dropdown || autoRender) && (
        <div
          className={`px-1 h-fit ${contentBackGround ?? "bg-taskinatorOffWhite"} ${noDropDownBorder ? "" : `border ${borderColour ?? "border-taskinatorMedGrey"}`} p-1 rounded-b-lg border-t-0 shadow ${
            autoRender && !dropdown ? "hidden" : ""
          }`}
        >
          {children}
        </div>
      )}
    </>
  );
};

export default JobModalDropDownContainer;
