import React, { useContext, useState, useRef } from "react";
import { AppContext } from "../../contexts/appContext";
import { MdClose } from "react-icons/md";
import ReactDOM from "react-dom";

const Modal = ({ local, pending, bgColour, bgOverRide, size, title, children, topSection, isWithoutClose, expand }) => {
  const appData = useContext(AppContext);
  const [startElement, setStartElement] = useState();
  let sizeCSS;

  if (size === "max") {
    sizeCSS = "2xl:w-2xl md:w-[99%] md:max-h-[98%] w-full h-full rounded-xl";
  } else if (size === "full") {
    sizeCSS = "h-full w-full";
  } else if (size === "80%") {
    sizeCSS = "2xl:w-2xl md:w-[80%] md:h-[90%] max-h-[98%] w-full h-full rounded-xl";
  } 
  else if (size === "70%") {
    sizeCSS = " md:max-w-4xl md:h-auto   max-h-[98%] w-full h-full rounded-xl";
  }
  else if (size === "large") {
    sizeCSS = "md:max-w-[1024px] md:max-h-[95%] md:mx-auto w-full h-full rounded-xl";
  } else if (size === "medium") {
    sizeCSS = "md:max-w-4xl md:max-h-[95%] md:h-auto w-full h-full rounded-xl";
  } else if (size === "small") {
    sizeCSS = "md:max-w-lg w-full h-fit rounded-xl";
  } else if (size === "max-expanding") {
    sizeCSS = "md:max-h-[97%] max-h-[97%] rounded-xl";
  } else {
    sizeCSS = "md:min-w-lg md:max-h-[95%] w-full md:h-auto h-full rounded-xl";
  }

  const backgroundRef = useRef();

  return (
    <>
      <div className="fixed flex items-center justify-center z-50 inset-0">
        <div
          onClick={(event) => {
            event.stopPropagation();
          }}
          className={`${bgColour ? bgColour : "bg-taskinatorWhite"} mx-auto p-4 ${size ? sizeCSS : "md:w-fit w-full h-auto md:max-h-[95%] rounded"} flex flex-col sm:overflow-auto relative`}
        >
          {!isWithoutClose ? (
            <div className={`flex w-full ${topSection ? `p-4 rounded-lg ${topSection} text-taskinatorWhite` : "h-fit text-taskinatorDarkGrey"}`}>
              <div className="grow items-center justify-center flex text-lg font-semibold">{title ? title : ""}</div>

              {!pending && (
                <div
                  className="hover:opacity-50 justify-end cursor-pointer"
                  onClick={() => {
                    appData.setIsModalOpened(false);
                    local ? (!pending ? (() => local())() : (() => {})()) : (() => appData.modelCloseController())();
                  }}
                >
                  <MdClose size={size === "full" ? 35 : 35} />
                </div>
              )}
            </div>
          ) : (
            <></>
          )}

          {children}
        </div>
      </div>
      <div className={`fixed top-0 left-0 h-full w-full z-40 ${bgOverRide ? bgOverRide : "bg-taskinatorDarkGrey opacity-90"}`}></div>
    </>
  );
};

export default Modal;
