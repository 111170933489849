import React, { useState, useEffect } from "react";
import Select from "react-select";
import Button from "../components/UI_elements/button";
import CheckboxV4 from "../components/inputs/checkbox_V4";
import DropDownSelector_V4 from "../components/inputs/dropDownSelector_V4";
import { MdClose } from "react-icons/md";
// Create time options for 24 hours in 30-minute increments
const timeOptions = Array.from({ length: 48 }, (_, i) => {
  const adjustedIndex = (i + 12) % 48; // Start from 6 AM
  const hour = Math.floor(adjustedIndex / 2);
  const minute = adjustedIndex % 2 === 0 ? "00" : "30";
  const time = `${hour.toString().padStart(2, "0")}:${minute}`;
  const label = `${hour % 12 === 0 ? 12 : hour % 12}:${minute} ${hour < 12 ? "AM" : "PM"}`;
  return { value: time, label: label };
});

const DaySchedule = ({ day, onSelect, userAvailability }) => {
  const preset = userAvailability.find((d) => d.day === day.name);

  const [available, setAvailable] = useState(preset ?? !day.unavailable);
  const [timeSlots, setTimeSlots] = useState(
    preset
      ? preset.timeSlots.map((ts) => {
          return { ...ts, isValid: null };
        })
      : [{ isValid: "" }]
  );

  useEffect(() => {
    onSelect(day.name, available, timeSlots, !available ? true : timeSlots.filter((slot) => slot.isValid !== null).length === 0);
  }, [timeSlots, available]);

  const timeSlotSetHandler = (index, position, value) => {
    const newTimeSlots = [...timeSlots];
    newTimeSlots[index][position] = value;

    // Check if end time is greater than start time
    if (newTimeSlots[index].start > newTimeSlots[index].end) {
      newTimeSlots[index].isValid = "End time should be greater than start time";
    } else if (!newTimeSlots[index]?.start || !newTimeSlots[index]?.end) {
      newTimeSlots[index].isValid = "Select start or end time";
    } else {
      newTimeSlots[index].isValid = null;

      // Check for overlapping time slots
      const newSlot = newTimeSlots[index];
      for (let i = 0; i < newTimeSlots.length; i++) {
        if (i !== index) {
          const existingSlot = newTimeSlots[i];
          if (
            (newSlot.start >= existingSlot.start && newSlot.start < existingSlot.end) ||
            (newSlot.end > existingSlot.start && newSlot.end <= existingSlot.end) ||
            (newSlot.start <= existingSlot.start && newSlot.end >= existingSlot.end)
          ) {
            newSlot.isValid = "Time slot overlaps with an existing slot";
            break;
          }
        }
      }
    }

    setTimeSlots(newTimeSlots);
  };

  const addTimeSlot = () => {
    setTimeSlots([...timeSlots, { isValid: "" }]);
  };

  const removeTimeSlot = (index) => {
    const newTimeSlots = timeSlots.filter((_, i) => i !== index);
    setTimeSlots(newTimeSlots);
  };

  return (
    <div className="mb-2 flex flex-col items-start">
      <div className="flex items-center w-full mb-2">
        <input type="checkbox" checked={available} onChange={() => setAvailable(!available)} className="mr-2 h-4 w-4 " />
        <span className="mr-2 text-sm font-medium">{day.name}</span>
        <div className="flex grow justify-end">{!available && <span className="text-taskinatorMedGrey ml-auto text-sm">Unavailable</span>}</div>
      </div>

      {available &&
        timeSlots
          // .sort((a, b) => a?.start - b?.start)
          .map((slot, index) => (
            <div className="flex-col flex mb-2">
              <div key={index} className="flex items-center ">
                <Select
                  options={timeOptions.filter((option) => (slot.end ? option.value < slot.end : true))}
                  value={timeOptions.find((option) => option.value === slot.start)}
                  // defaultValue={slot?.start ? timeOptions.find((option) => option.value === slot.start) : null}
                  onChange={(selectedOption) => {
                    timeSlotSetHandler(index, "start", selectedOption.value);
                  }}
                  className="w-32 mr-2 text-sm"
                />
                <span className="mr-2">-</span>
                <Select
                  options={timeOptions.filter((option) => option.value > slot.start)}
                  value={timeOptions.find((option) => option.value === slot.end)}
                  onChange={(selectedOption) => {
                    timeSlotSetHandler(index, "end", selectedOption.value);
                  }}
                  className="w-32 mr-2 text-sm"
                />
                <button onClick={() => removeTimeSlot(index)} className="text-red-500 hover:opacity-50">
                  <MdClose />
                </button>
              </div>
              {slot.isValid && <div className="text-taskinatorRed text-xs">{slot.isValid}</div>}
            </div>
          ))}
      {available && <Button onClick={addTimeSlot} customClasses="border-taskinatorBlue border text-taskinatorBlue h-8 rounded ml-auto hover:opacity-75 text-sm" label="Add TimeSlot" />}
    </div>
  );
};

export default DaySchedule;
