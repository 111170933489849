import React, { useContext } from "react";
import axios from "axios";

import { AppContext } from "../../contexts/appContext";
import { useInit } from "../hooks/useInit";
import { useInputV4 } from "../../stateManagement/useInputV4";

import MemberSelector from "../dataSelectors/memberSelector";
import InputV4 from "../inputs/input_V4";
import TagSelector from "../dataSelectors/tagSelector";
import Button from "../UI_elements/button";
import CheckboxV4 from "../inputs/checkbox_V4";

import { VALIDATOR_MINLENGTH, VALIDATOR_NOTMATCH } from "./validators/validators";

const NewWorkflowForm = ({ otherWorkflowNames, onResponse, defaultValue }) => {
  const appData = useContext(AppContext);
  const { inputState, inputHandler } = useInputV4({ inputs: {}, isValid: false });
  const { initState, initStarted, initCompleted, initErrors } = useInit();

  let startingValue = { name: "", managers: [], entry: false, exit: false };

  if (defaultValue !== null && defaultValue !== undefined) {
    startingValue.name = defaultValue.name;
    startingValue.accessTags = defaultValue.accessTags;
    startingValue.entry = defaultValue.noEntry;
    startingValue.exit = defaultValue.noExit;

    if (defaultValue.managers.length > 0) {
      startingValue.managers = defaultValue.managers.map((manager) => {
        return { type: "", name: `${manager.firstName} ${manager.lastName[0]}`, value: manager._id };
      });
    }
  }

  const nameCheckerValidator =
    otherWorkflowNames && otherWorkflowNames.workflows
      ? otherWorkflowNames.workflows
          .filter((data) => data.name.toLowerCase() !== startingValue.name.toLowerCase())
          .map((values) => {
            return values.name.toLowerCase();
          })
      : [" "];

  const url = defaultValue ? `edit/${defaultValue._id}` : "create";

  const submitHandler = async (event) => {
    event.preventDefault();

    initStarted(0);
    axios
      .put(
        process.env.REACT_APP_BACKEND_URL + `/api/workflow/${url}`,
        {
          workflowName: inputState.inputs.workflowName.value.toLowerCase().trim(),
          managers: inputState.inputs.managers.value,
          accessTags: inputState.inputs.tags.value,
          // noEntry: inputState.inputs.noEntry.value,
          // noExit: inputState.inputs.noExit.value,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        initCompleted(0);
        onResponse();
      })
      .catch((err) => {
        return initErrors(err?.response?.data?.message ?? err?.message ?? "", 0);
      });
  };

  return (
    <form className=" w-full md:w-96" onSubmit={submitHandler}>
      <InputV4
        inputVer="1"
        sid="inputs"
        id="workflowName"
        placeholder={"Unique Workflow Name"}
        customClasses={"rounded-md"}
        onInput={inputHandler}
        defaultValue={startingValue.name}
        validators={[VALIDATOR_MINLENGTH(3), VALIDATOR_NOTMATCH(nameCheckerValidator)]}
        validityMessage={"Min 3 charactors - Must be unique"}
        label="Workflow Name"
        disabled={initState[0].started}
      />

      <div className="pt-2">
        <MemberSelector
          sid="inputs"
          id="managers"
          inputHandler={inputHandler}
          disabled={initState[0].started}
          label="Select Managers"
          multi
          selectionDisplayText="Select Managers"
          defaultValue={startingValue.managers}
          roleTypes={[process.env.REACT_APP_ACCESS_M, process.env.REACT_APP_ACCESS_A, process.env.REACT_APP_ACCESS_O]}
          inputVer="1"
        />
      </div>

      <div className="mt-2 ">
        <div className="hidden">
        <TagSelector
          inputVer="1"
          notRequired={true}
          id={"tags"}
          inputHandler={inputHandler}
          defaultValue={
            startingValue.accessTags
              ? startingValue.accessTags.map((tag) => {
                  return { type: "", name: tag.name, value: tag._id, data: tag };
                })
              : []
          }
          sid="inputs"
          type="access"
          label="Access Tag"
          selectionDisplayText={"Select/Create Access Tag"}
        /></div>
        {/* 
        <div className="py-2">
          <CheckboxV4 sid="inputs" id="noEntry" onInput={inputHandler} label="Can't Be Triggered By Other Workflows" defaultValue={startingValue.entry} customeLabel={"text-xs"} />
          <CheckboxV4 sid="inputs" id="noExit" onInput={inputHandler} label="Can't Trigger other Workflows" defaultValue={startingValue.exit} customeLabel={"text-xs"} />
        </div> */}
        <Button
          type="submit"
          label={defaultValue ? "Save Workflow" : "Create Workflow"}
          customClasses={`${inputState.isValid ? "bg-taskinatorBlue" : "bg-taskinatorMedGrey"} bg-taskinatorBlue text-taskinatorWhite h-10 w-full mt-2 `}
          disabled={!inputState.isValid}
          isLoading={initState[0].started}
          error={initState[0].error}
        />
      </div>
    </form>
  );
};

export default NewWorkflowForm;
