import React, { useEffect } from "react";
import { animateScroll } from "react-scroll";

const AutoScrollDownContainer = ({ children, id, triggerAgain, customCSS }) => {
  const fixScrollToBottom = () => {
    const card = document.getElementById(id); // Changed to getElementById for clarity

    if (card) {
      const duration = (card.scrollHeight - card.clientHeight) / 5;

      animateScroll.scrollToBottom({
        containerId: id,
        duration: duration,
        smooth: "easeInOutQuint", // Example of easing function
      });
    }
  };

  useEffect(() => {
    fixScrollToBottom();
  }, [id, triggerAgain]); // Simplified and corrected dependencies

  return (
    <div className={`${customCSS} overflow-auto scrollbar pt-1`} id={id}>
    {children}
  </div>
  );
};

export default AutoScrollDownContainer;
