import React, { useContext, useState, useEffect } from "react";
import { AppContext } from "../../../contexts/appContext";

import FormatDate from "../../../utils/formattedDate";
import DocumentDisplay from "../../UI_elements/documentDisplay";
import { MdStar, MdCheck, MdAddCircleOutline, MdOutlinePending } from "react-icons/md";
import LinkifyText from "../../../utils/linkifyText";
import FilePreview from "../../../utils/filePreview";

const JobSubmissionBubble = ({ submission }) => {
  const appData = useContext(AppContext);
  const isYou = submission.createdBy._id.toString() === appData.workspaceDataV4.user_id;
  const [showDetails, setShowDetails] = useState();
  // if (index <= loadMoreComments) {

  return (
    <div key={submission._id} className="p-2">
      <div className="flex items-center my-1 justify-between text-xs font-semibold text-taskinatorDarkGrey ">
        <div className="capitalize pr-1 ">
          {" "}
          {submission.createdBy.firstName} {submission.createdBy.lastName}
        </div>
        {FormatDate(submission.createdDate)}{" "}
      </div>
      <div className="flex w-full items-start">
        <div className={`w-full  `}>
          <div
            className={`  ${
              isYou
                ? `${submission.critical ? "bg-taskinatorAlertRed text-taskinatorBlack" : "bg-taskinatorPaper   rounded  text-taskinatorDarkGrey"}   `
                : `${
                    submission.critical
                      ? "bg-taskinatorAlertRed text-taskinatorBlack"
                      : `${
                          submission.thirdParty
                            ? "bg-taskinatorHoverOrange text-taskinatorDarkGrey border-taskinatorMedGrey border"
                            : "   bg-taskinatorPaper rounded text-taskinatorDarkGrey"
                        }`
                  }    `
            }   px-2 py-1 grow  shadow `}
          >
            <div className=" text-sm    ">
              <div className="flex items-center">
                {/* {actionComment.critical && (
                  <div className="pr-1">
                    <MdNotificationsActive />
                  </div>
                )} */}
                <div className="flex items-center grow">
                  <div className="  flex items-center py-2">
                    <b className="pr-1 ">{submission?.action ?? "submitted"}</b> at
                    <div className="mx-1 text-xs border border-taskinatorDarkGrey px-1 capitalize  rounded items-center">{submission.taskName}</div> in{" "}
                    <div className="mx-1 text-xs border border-taskinatorDarkGrey px-1 capitalize  rounded">{submission.workflowName}</div> workflow.{" "}
                  </div>
                  <div className="flex grow justify-end">
                    {submission.mileStone && (
                      <div className="flex bg-taskinatorYellow text-taskinatorBlack font-medium rounded py-1 px-2 items-center">
                        <MdStar />
                        <div className="pl-1">Milestone Action</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* {(submission?.comments || submission?.attachments?.length > 0) && ( */}
              <div className={`flex flex-col items-start border-t ${submission.critical ? "border-t-taskinatorDarkGrey" : "border-t-taskinatorMedGrey"} pt-2 mt-1 py-1`}>
                {submission.comments && (
                  <div className="whitespace-pre-wrap flex justify-start w-full ">
                    <div> Issue Comments: </div>
                    {submission.comments ? <LinkifyText text={submission.comments} /> : <div className="text-xs">No Instructions Submitted</div>}
                  </div>
                )}
                {submission.attachments.length > 0 && (
                  <div className="font-medium pt-2">
                    {submission.attachments.map((attachment) => {
                      return <FilePreview file={attachment} colorChange={"bg-taskinatorLightGrey text-taskinatorDarkGrey"} typeOverride={"submission"} />;
                    })}
                  </div>
                )}
                {/* {submission.tasksAtSubmission.length > 0 ? (
                  <div className="py-1 mt-2 ">
                    Tasks submitted:
                    <div className="flex">
                      {submission.tasksAtSubmission.map((task) => {
                        return (
                          <div className="mr-1 border border-taskinatorGreen border-2 px-2 py-0.5 hover:bg-taskinatorGreenHover rounded-lg flex items-center justify-center cursor-pointer ">
                            <div className="pr-2 text-lg">
                              {task.completed ? (
                                <div className="text-taskinatorGreen">
                                  <MdCheck color={"text-taskinatorGreen"} />
                                </div>
                              ) : (
                                <div className="text-taskinatorMedGrey">
                                  <MdOutlinePending />{" "}
                                </div>
                              )}
                            </div>
                            <div className="font-medium">{task.title}</div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : (
                  <div>No Tasks</div>
                )} */}
              </div>
              {/* )} */}
            </div>
          </div>
      
        </div>
      </div>
    </div>
  );
};

export default JobSubmissionBubble;
