import React from 'react'
import { CalcTime } from '../../../utils/countUpTimer'

const ActionTimesheetDetailsDisplay = ({ data, timeSheetData = [] }) => {
  const totalTime = timeSheetData.reduce(
    (total, currentValue) => total + currentValue.totalTime,
    0
  )

  return (
    <div className='flex grow w-full mt-1'>
      {/* added from */}
      <div className='w-full md:mt-0 mt-1  rounded-lg border p-2  border-taskinatorMedGrey bg-taskinatorWhite flex flex-col justify-start items-start gap-y-2'>
        <div className='flex justify-start items-center'>
          <span className='text-taskiantorMedDarkGrey'>Total Time: &nbsp;</span>
          <span className='text-taskinatorBlue font-bold'>
            {GetTimeString(totalTime,false,true)}
            &nbsp;
          </span>
          <span className='text-taskiantorMedDarkGrey'>Of : &nbsp;</span>
          <span className='text-taskinatorBlue font-bold'>
            {GetTimeString(
              Number(data?.estimatedTime ?? 0) * 3600,
              false,
              true
            )}
            &nbsp;
          </span>
        </div>
        <div className='w-full flex-col'>
          {timeSheetData.length ? (
            timeSheetData.map((item) => (
              <div className='w-full flex justify-start items-center border-t border-solid border-taskiantorMedDarkGrey box-border py-2'>
                <span className='w-40 flex justify-start items-center text-taskinatorBlack px-5'>
                  <span className='capitalize font-semibold'>
                    {item?.firstName ?? ''}
                  </span>
                  &nbsp;
                  <span className='capitalize font-semibold'>
                    {item?.lastName ?? ''}
                  </span>
                </span>
                <span className='w-40 flex justify-start items-center text-taskinatorBlack box-border border-l border-solid border-taskinatorBlack px-5'>
                  {GetTimeString(item.totalTime)}
                </span>
                {item.clockedIn ? (
                  <span className='flex-1 flex border-l border-solid border-taskinatorBlack justify-start items-center text-taskinatorGreen px-5'>
                    Clocked In
                  </span>
                ) : (
                  <></>
                )}
              </div>
            ))
          ) : (
            <div className='w-full flex justify-center items-center border-t border-solid border-taskiantorMedDarkGrey box-border py-2 italic'>
              No Timesheets
            </div>
          )}
        </div>
      </div>
      {/* added to */}
    </div>
  )
}

export default ActionTimesheetDetailsDisplay

export const GetTimeString = (
  countedSeconds,
  needSecond = false,
  hourOnly = false
) => {
  const { seconds, minutes, hours, days } = CalcTime(countedSeconds * 1000)

  if (hourOnly) {
    const restHour = Number((minutes / 60).toFixed(1))
    return `${hours + restHour}h`
  }

  return `${hours < 0 ? 0 : hours}h ${minutes < 0 ? 0 : minutes}m ${
    needSecond ? `${seconds < 0 ? 0 : seconds}s` : ''
  }`
}
