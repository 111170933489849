import React, { useContext, useState } from "react";
import axios from "axios";
import { AppContext } from "../../contexts/appContext";
import StringShortenDisplay from "../UI_elements/stringShortenDisplay";
import ItemStatus from "../UI_elements/itemStatus";
import ElementCard from "./elementCard";
import Modal from "../UI_elements/modal";

import ActionDetailDisplayV2 from "../displayElements/actionDisplay/actionDetailDisplayV2";
import UserDisplayBubble from "../UI_elements/userDisplayBubble";
import { useInit } from "../hooks/useInit";
import Button from "../UI_elements/button";

const ColumnItemElement = ({ primaryColour, primaryBorder, data, jobData, type, getData, textColour, opacity }) => {
  const appData = useContext(AppContext);
  const { initState, initStarted, initCompleted, initErrors, initReset } = useInit();
  const isNew = data.seen && data.seen.filter((seen) => seen._id === appData.workspaceDataV4.user_id).length === 0;
  const [modal, setModal] = useState();
  const item = data.itemsOnTracer;
  const [hoverChild, setHoverChild] = useState();
  const [modalSize, setModalSize] = useState("medium");

  const StartHandler = () => {
    initStarted(0);
    axios
      .put(
        process.env.REACT_APP_BACKEND_URL + `/api/tracer/start/multiple`,
        {
          tracerIds: [data._id],
          tid: jobData.tid,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        getData();
        initCompleted(0);
      })
      .catch((err) => {
        console.log(err);
        initErrors(err?.response?.data?.message ?? err?.message ?? "", 0);
      });
  };

  return (
    <>
      <ElementCard
        key={data._id}
        topContent={
          <div className="flex capitalize text-xs items-center">
            <div className="font-bold uppercase text-taskinatorDarkGrey">{StringShortenDisplay(20, jobData.ref)}</div>

            <div className=" px-2">|</div>
            <div className={`${textColour} font-semibold uppercase  `}>{StringShortenDisplay(20, data.aid.name)}</div>
            <div className="text-taskinatorDarkGrey font-medium pl-1">({StringShortenDisplay(15, data.wfid.name)})</div>
            <div className=" px-2">|</div>
            {data.assignee.map((user, index) => {
              if (index < 2)
                return (
                  <div KEY={user._id} className="">
                    <UserDisplayBubble firstName={user.firstName} lastName={user.lastName} textSize={"text-xs"} colourOverride={`${primaryColour} text-taskinatorWhite`} user={user} />
                  </div>
                );
            })}
            {data.assignee.length > 2 && <div className="text-xs ">...</div>}
          </div>
        }
        bottomContent={
          <div className="flex grow items-center">
            {item ? (
              <div className={` text-xs flex items-center text-taskinatorDarkGrey `}>
                <div className=" font-semibold uppercase ">{item.currentItemDetails.itemNumber}</div>
                <div className="px-1">-</div>
                <div className=" font-semibold uppercase pr-2">{StringShortenDisplay(20, item.currentItemDetails.itemName)}</div>
                <div className=" pr-2">|</div>
                <div className="pr-2">{item.draft ? <ItemStatus status={item.draft.status} /> : item.released ? <ItemStatus status={item.released.status} /> : "No Release"}</div>
                <div className="pr-2 ">Rev: </div>
                <div className=" font-semibold uppercase pr-2 ">{item.draft ? item.draft.revision : item.released ? item.released.revision : ""}</div>
              </div>
            ) : (
              <div className={`font-semibold  opacity-50   text-xs uppercase`}>{`No ${appData.itemName}`}</div>
            )}
            {data.couldbeAssigned && (
              <div
                className="flex grow items-center justify-end  "
                onClick={(event) => {
                  event.stopPropagation();
                }}
              >
                <Button
                  label="Start"
                  onClick={() => StartHandler()}
                  onMouseEnter={() => {
                    setHoverChild(true);
                  }}
                  onMouseLeave={() => {
                    setHoverChild();
                  }}
                  isLoading={initState[0].started}
                  customClasses={"hover:bg-taskinatorGreen border uppercase  text-taskinatorGreen border-taskinatorGreen hover:text-taskinatorWhite text-xs py-1 px-2"}
                />
              </div>
            )}
          </div>
        }
        isNew={isNew}
        primaryBorder={primaryBorder}
        createdDate={data.time_in}
        // onClick={() => setModal(true)}
        onClick={() =>
          appData.setActionViewHandler({
            tracerId: data._id,
            tid: data.tid,
            tracerWorkflowGroupId: data.tracerWorkflowGroupId,
            getData: () => getData(),
          })
        }
        seenBy={data.seen}
        hoverChild={hoverChild}
        opacity={opacity}
      />

      {modal && (
        <Modal
          size={"max"}
          bgColour="bg-taskinatorWhite "
          title={
            <div className="uppercase font-normal">
              <b>{data.wfid.name}</b> Workflow
            </div>
          }
          local={() => {
            // setModalSize("medium")
            setModal();
          }}
        >
          <ActionDetailDisplayV2
            type={"mine"}
            aidDetails={data.aid}
            item={item}
            tracer={data}
            tracerId={data._id}
            tid={data.tid}
            onSubmitSuccess={() => {
              getData();
              setModal();
            }}
            taskDetails={jobData}
            getData={getData}
            onStartHandler={() => StartHandler()}
            // onStartHandler={onStartHandler}
            setModalSize={setModalSize}
            startHandlerLoading={initState[0]}
          />
        </Modal>
      )}
    </>

    // <div className="hover:scale-95">
    //   {isNew && (
    //     <div className="flex grow  justify-end h-0 pt-3 z-40">
    //       <div className="relative -top-2 mr-2 items-center flex h-4 w-fit text-taskinatorWhite text-[0.75rem] bg-taskinatorRed  px-2 rounded-full font-semibold">New</div>
    //     </div>
    //   )}
    //   <div className={`flex flex-col grow bg-taskinatorWhite shadow border-l-4 ${primaryBorder} mt-1  cursor-pointer`}>
    //     <div className="flex grow border-b border-b-taskinatorMedGrey p-2">
    //       <div className="flex capitalize text-base">
    //         <div className="text-taskinatorGreen font-semibold ">{StringShortenDisplay(20, data.aid.name)}</div>
    //         <div className="text-taskinatorMedGrey pl-1 ">({StringShortenDisplay(10, data.wfid.name)})</div>
    //       </div>
    // \
    //     </div>

    //     {item ? (
    //       <div className={`flex  items-center px-2 text-sm  m-0.5 rounded h-10`}>
    //         <div className=" font-semibold uppercase ">{item.currentItemDetails.itemNumber}</div>
    //         <div className="px-1">-</div>
    //         <div className=" font-semibold uppercase pr-2">{StringShortenDisplay(10, item.currentItemDetails.itemName)}</div>
    //         <div className=" pr-2">|</div>
    //         <div className="pr-2">{item.draft ? <ItemStatus status={item.draft.status} /> : item.released ? <ItemStatus status={item.released.status} /> : "No Release"}</div>
    //         <div className="pr-2 ">Rev: </div>
    //         <div className=" font-semibold uppercase pr-2 ">{item.draft ? item.draft.revision : item.released ? item.released.revision : ""}</div>
    //       </div>
    //     ) : (
    //       <div className={`font-semibold  opacity-50 px-2 items-center flex text-xs uppercase h-10`}>{`No ${appData.itemName}`}</div>
    //     )}
    //   </div>
    // </div>
  );
};

export default ColumnItemElement;
