const FormatDate = (dateTimeString, showFull) => {
  const date = new Date(dateTimeString)
  const currentDate = new Date()

  if (date.toDateString() === currentDate.toDateString() && !showFull) {
    const time = date.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
    })
    return time
  } else if (showFull) {
    const day = date.getDate()
    const year = date.getFullYear()
    const monthAbbreviation = date.toLocaleString('default', { month: 'short' })
    const time = date.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
    })
    return `${day} ${monthAbbreviation} ${year} at ${time}`
  } else {
    const day = date.getDate()
    const monthAbbreviation = date.toLocaleString('default', { month: 'short' })
    const time = date.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
    })
    return `${day} ${monthAbbreviation} at ${time}`
  }
}

export default FormatDate

export const FormatDateWithoutTime = (dateTimeString = null) => {
  const date = new Date(dateTimeString)

  const day = date.getDate()
  const year = date.getFullYear()
  const monthAbbreviation = date.toLocaleString('default', { month: 'short' })

  return `${day} ${monthAbbreviation} ${year}`
}
