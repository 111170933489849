import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { MdOutlineWarning } from "react-icons/md";
import { AppContext } from "../../../contexts/appContext";
import Button from "../../UI_elements/button";
import Modal from "../../UI_elements/modal";
import { useInit } from "../../hooks/useInit";
import EditWorkflow from "../workflow/editWorkflow";
import FileSetAction from "./fileSetAction";
import JobDetailsWorkflowDisplay from "./jobDetailsWorkflowDisplay";
import { JobModalContext } from "./jobModalContext";
import JobModalDropDownContainer from "./jobModalDropDownContainer";
import AddStepDisplayButton from "../addStepDisplayButton";
import UserDisplayBubble from "../../UI_elements/userDisplayBubble";
const FileSetDropdown = ({ selectedWorkflowData, fileSetId, fileSetName }) => {
  const appData = useContext(AppContext);

  let filesByTask = {};

 
  const isManager = selectedWorkflowData.workflowData.managers.find((manager) => manager._id === appData.workspaceDataV4.user_id);

  const [isOnlyWorkFlow, setIsOnlyWorkFlow] = useState(true);
  const [onDelete, setDelete] = useState();
  const [editWorkflow, setEditWorkflow] = useState();
  const [actionPlan, setActionPlan] = useState([]);
  const [bulkEditEnabled, setBulkEditEnabled] = useState();

  const { initState, initStarted, initCompleted, initErrors, initReset } = useInit(2);
  const jobData = useContext(JobModalContext);

  const disableEdits = false;

  (Object.values(selectedWorkflowData.files) ?? [])
    .sort((a, b) => {
      if (b.fileName > a.fileName) {
        return -1;
      } else if (b.fileName < a.fileName) {
        return 1;
      }
      return 0;
    })
    .map((file) => {
      file.currentJobs
        .filter((wfs) => wfs.workflowGroupId === selectedWorkflowData.workflowData.workflowGroupId)
        .map((currentJob) => {
          // workflow.currentFilePosition.map((currentTask) => {

          const task = selectedWorkflowData.workflowData.tasks.find((task) => task._id === currentJob.taskId);
          const isAssigned = task.assignee.find((user) => user._id.toString() === appData.workspaceDataV4.user_id) ? true : false;
          const isAssignedToUser = !isAssigned && task.assignee.length > 0 ? true : false;
          const isManager = selectedWorkflowData.workflowData.managers.find((user) => user._id.toString() === appData.workspaceDataV4.user_id) ? true : false;
          const canStart = !isAssigned && !isAssignedToUser && task.taskDetails.team.find((user) => user._id.toString() === appData.workspaceDataV4.user_id) ? true : false;

          filesByTask[currentJob.taskId] = {
            name: task.taskDetails.name,
            _id: currentJob.taskId,
            stageIndex: currentJob.stageIndex,
            taskDetails: { ...task.taskDetails, _id: currentJob.taskId },
            taskCompleted: task.markedAsCompleted,
            completedBy: task.completedBy,
            completedDate: task.completedDate,
            taskUUID: task.taskUUID,
            isAssigned: isAssigned,
            isManager: isManager,
            canStart: canStart,
            assignees: task.assignee,
            fileSetId: fileSetId,
            fileSetName: fileSetName,
            workflowGroupId: selectedWorkflowData.workflowData.workflowGroupId,

            files: [...(filesByTask[currentJob.taskId]?.files || []), { ...file, currentJobs: file.currentJobs.find((cj) => cj.taskId === currentJob.taskId) }],
          };
        });
    });

  useEffect(() => {
    initStarted(1);
    axios
      .get(process.env.REACT_APP_BACKEND_URL + `/api/job/actionPlan/${jobData?.jobId}/${selectedWorkflowData?.workflowData?.workflowGroupId}`, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      .then((response) => {
        initCompleted(1);
        setActionPlan(response.data.actionPlan);
      })
      .catch((err) => {
        console.log(err);
        return initErrors(err?.response?.data?.message ?? err?.message ?? "", 1);
      });

    // getJobActionPlan({
    //   jobId: jobData?.jobId,
    //   workflowGroupId: selectedWorkflowData?.workflowData?.workflowGroupId,
    //   onFinish: (res, err) => {
    //     console.log(res, err);
    //     initCompleted(1);
    //     if (!res || err) {
    //       initErrors(err?.response?.data?.message ?? err?.message ?? "Error while getting actionplan data of the job", 1);
    //     }
    //     setActionPlan(res?.actionPlan ?? []);
    //   },
    // });
  }, [jobData.jobId, selectedWorkflowData?.workflowData?.tasks]);

  const mustSetWorkflow = selectedWorkflowData.setWorkflow;

  return (
    <>
      <div className=" rounded-lg">
        {/* <JobModalDropDownContainer
          openAtStart
          thin
          headerContent={
            <>
              <div className="pl-1">Sequence:</div>
              <b className=" order-taskinatorWhite pl-2 pr-2"> {selectedWorkflowData?.name} </b>

              <div className="flex grow justify-end"></div>
            </>
          }
          textColour={"text-taskinatorDarkGrey"}
          borderColour={"border-taskinatorMedGrey"}
          backgroundColour={"bg-taskinatorMedGrey border border-taskinatorMedGrey "}
        > */}
        <div className="border border-taskinatorHoverGreen border-2 rounded-lg">
          {/* {([process.env.REACT_APP_ACCESS_A, process.env.REACT_APP_ACCESS_O].includes(appData.workspaceDataV4.role) ||
            ([process.env.REACT_APP_ACCESS_M].includes(appData.workspaceDataV4.role) && isManager)) && ( */}
          <div className="flex flex-col ">
            <div className={`flex grow md:justify-end`}>
              {/* {isManager && !disableEdits && (
                    <div className="flex">
                      <div className="items-center flex">
                        <Button
                          label={`Mark Workflow As Completed`}
                          customClasses={`${
                            initState[1].error
                              ? "border-taskiantorRed text-taskinatorRed cursor-not-allowed"
                              : "cursor-pointer text-taskinatorRed hover:bg-taskinatorRed hover:text-taskinatorWhite border-taskinatorRed"
                          } justify-end h-8 text-sm px-4 py-2 border rounded opacity-50`}
                          onClick={(e) => {
                            e.stopPropagation();
                            setDelete(true);
                          }}
                          error={initState[1].error}
                          disabled={initState[1].error}
                        />
                      </div>
                    </div>
                  )} */}
            </div>

            <div className=" p-1  ">
              {!mustSetWorkflow ? (
                <div className="flex w-full  items-center justify-center flex-col text-taskinatorMedGrey">
                  <div className="flex justify-start items-center w-full h-fit pl-2">
                    <div className="uppercase text-lg font-medium text-taskinatorHoverGreen flex justify-center">
                      <b className="pr-1 ">{selectedWorkflowData?.name}</b> Workflow
                    </div>
                    <div className="px-1  mx-1">Managed By: </div>
                    <div className="flex">
                      {selectedWorkflowData?.workflowData?.managers.map((user) => {
                        return <UserDisplayBubble user={user} />;
                      })}
                    </div>
                    {isManager && !disableEdits && !jobData.jobCompleted && (
                      <Button
                        onClick={(e) => {
                          e.stopPropagation();
                          setEditWorkflow(true);
                        }}
                        label={!mustSetWorkflow ? "Edit This Workflow" : "Set Workflow"}
                        customClasses={`${
                          mustSetWorkflow
                            ? "bg-taskinatorRed text-taskinatorBlue animate-pulse hover:opacity-75 h-8 items-center justify-center"
                            : "underline text-taskinatorBlue  hover:text-taskinatorBlue items-end h-full"
                        } capitalize  flex  text-xs px-2  rounded  `}
                      />
                    )}
                  </div>
                  {/* <JobDetailsWorkflowDisplay actionPlan={actionPlan} workflowGroupId={selectedWorkflowData?.workflowData?.workflowGroupId} isManager={isManager} /> */}
                </div>
              ) : (
                <div className=" text-taskinatorRed font-bold py-1 px-2 rounded w-fit items-center uppercase animate-pulse flex">
                  <MdOutlineWarning size={"1.25rem"} className="mr-2" /> Must Set Workflow To Active it{" "}
                  {isManager && !disableEdits && !jobData.jobCompleted && (
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        setEditWorkflow(true);
                      }}
                      label={!mustSetWorkflow ? "Edit This Sequence" : "Set Workflow"}
                      customClasses={`${
                        mustSetWorkflow
                          ? "bg-taskinatorRed text-taskinatorWhite animate-pulse hover:opacity-75"
                          : "border-taskinatorDarkGrey text-taskinatorDarkGrey hover:bg-taskinatorWhite hover:text-black"
                      }  uppercase h-8 flex items-center justify-center text-xs px-2 border rounded ml-2 `}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
          {/* )} */}
          <div className="flex flex-col grow h-full items-stretch overflow-auto ">
            {Object.values(filesByTask)
              .sort((a, b) => a.stageIndex - b.stageIndex)
              .filter((task) =>
                Object.values(jobData.selectedTaskIds).length === 0
                  ? true
                  : Object.values(jobData.selectedTaskIds)
                      .map((selections) => selections.primaryTask)
                      .flat(1)
                      .includes(task._id)
              )
              .map((fileSetByTask, index) => (
                <div key={fileSetByTask._id} className="pt-1  flex  grow  ">
                  <FileSetAction fileSetByTask={fileSetByTask} workflowData={selectedWorkflowData.workflowData} setWorkflow={selectedWorkflowData.setWorkflow} />
                  {/* {index+1 !== Object.values(filesByTask).length && <div className="h-full justify-center items-center flex"><AddStepDisplayButton notClickable colour={"text-taskinatorBlue  border-taskinatorBlue"} /></div>} */}
                </div>
              ))}
          </div>
        </div>
        {/* </JobModalDropDownContainer> */}
      </div>

      {editWorkflow && (
        <Modal
          title="Edit Current Workflow"
          local={() => {
            setEditWorkflow();
          }}
        >
          <EditWorkflow
            selectedWorkflowData={selectedWorkflowData}
            splitFilesByTask={filesByTask}
            selStageIds={selectedWorkflowData?.workflowData?.tasks}
            actionPlan={actionPlan}
            handleCloseModal={() => setEditWorkflow(false)}
            noneTaskEdit
          />
        </Modal>
      )}
    </>
  );
};

export default FileSetDropdown;
