import React, { useCallback, useState, useEffect } from "react";
import Button from "../../UI_elements/button";
import Modal from "../../UI_elements/modal";
import FileUploaderV4 from "../../inputs/fileUploader_V4";
import Input_V4 from "../../inputs/input_V4";
import TaskWorkflowSequenceDisplay from "./taskWorkflowSequenceDisplay";
import { MdLock } from "react-icons/md";
import FilesileListViewDisplayer from "../files/fileListViewDisplayer";
import { useInit } from "../../hooks/useInit";
import axios from "axios";
import JobFileViewer from "../jobs/jobFileViwer";
import InputV4 from "../../inputs/input_V4";
import { VALIDATOR_MINLENGTH, VALIDATOR_NUMBER } from "../../forms/validators/validators";
import PrioritySelector from "../../dataSelectors/prioritySelector";
import DurationSelector from "../../dataSelectors/durationSelector";
const TaskWorkflowElementDisplayV4 = ({
  stateId,
  actions,
  workflowData,
  globalDispatch,
  globalInputHandler,
  globalAttachmentHandler,
  globalSid,
  itemsAlreadySelected,
  workflowScope,
  excludeList,
  setExcludeList,
  fileSets,
}) => {
  const { initState, initStarted, initCompleted, initErrors, initReset } = useInit(2);

  const [workflowModal, setWorkflowModal] = useState();
  const [scopeModal, setScopeModal] = useState();
  const [files, setFiles] = useState();
  const workflowScopeValue = workflowScope && workflowScope[stateId] ? workflowScope[stateId] : "No Scope Defined";

  const scopeChangeHandler = useCallback(
    (id, value, isValid, sid) => {
      globalDispatch({
        type: "WORKFLOW_SCOPE",
        workflowStateId: stateId,
        workflowScope: value,
      });
    },
    [stateId]
  );

  const GetFiles = () => {
    initStarted(1);
    axios
      .put(
        process.env.REACT_APP_BACKEND_URL + `/api/file/autosequence`,
        {
          fileSetsIds: fileSets?.map((fileSet) => fileSet._id),
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        setFiles(response.data.files);
        initCompleted(1);
      })
      .catch((err) => {
        console.log(err);
        initErrors(err?.response?.data?.message ?? err?.message ?? "", 1);
      });
  };

  useEffect(() => {
    GetFiles();
  }, [fileSets]);

  return (
    <>
      <div key={stateId} className="mb-2 flex flex-col bg-taskinatorWhite h-full border border-taskinatorMedGrey rounded-lg   ">
        {/* <div className="">
          <TaskItemDisplayV4 globalSid={globalSid} globalInputHandler={globalInputHandler} workflowId />
        </div> */}
        <div className="w-full flex-col flex h-fit">
          <div className="flex-col flex px-4 py-2 ">
            <div className="flex items-center justify-center">
              {/* <div className="text-md border rounded-full h-6 w-6 items-center justify-center flex font-bold border-2 mr-2 text-taskinatorDarkGrey border-taskinatorDarkGrey">2</div> */}
              <div className="w-fit pr-2 text-base flex text-taskinatorDarkGrey uppercase font-semibold p-1 rounded ">{workflowData.name}</div>

              <div className="items-center text-sm px-2 text-taskinatorDarkGrey opacity-90">Managers:</div>
              <div className="w-fit pr-2 flex items-center ">
                {workflowData.managers.map((manager) => {
                  return (
                    <div
                      key={manager._id}
                      className={`ml-2 w-fit capitalize bg-taskinatorHighlightBlue text-taskinatorDarkGrey font-medium py-0.5 px-2 flex items-center justify-center rounded text-xs whitespace-nowrap ${
                        manager.locked ? "opacity-50" : ""
                      }`}
                    >
                      {manager.locked ? (
                        <div className="pr-1">
                          <MdLock />
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="pr-1">{manager.firstName}</div>
                      <div className="uppercase"> {manager.lastName[0]} </div>
                    </div>
                  );
                })}
              </div>
              <div className=" flex items-center border-l border-l-taskinatorMedGrey">
                <div className="text-sm text-taskinatorDarkGrey pr-2 pl-1 hidden">Target Due:</div>
                <div className="hidden">
                  <InputV4
                    id="workflowDueDate"
                    type="date"
                    customClasses={"rounded-md md:text-sm"}
                    sid={globalSid}
                    onInput={globalInputHandler}
                    validators={[]}
                    validityMessage="Please Set A Due Date"
                  />
                </div>
              </div>
              <div className="text-sm text-taskinatorDarkGrey pr-2 pl-2 hidden">Duration:</div>
              <div className="w-32 hidden">
                <DurationSelector onInput={globalInputHandler} sid={globalSid} />
              </div>
              {/* <InputV4
                id="workflowEtimatedTime"
                customClasses={"rounded-md md:text-sm"}
                sid={globalSid}
                onInput={globalInputHandler}
                validators={[VALIDATOR_NUMBER()]}
                validityMessage="Add Estimated Time"
              /> */}
              <div className="flex grow"></div>
              <div
                className="cursor-pointer text-taskinatorRed hover:opacity-100 justify-end text-xs mx-1 px-4 py-1  opacity-50"
                onClick={() => globalDispatch({ type: "REMOVE_WORKFLOW", stateId: stateId })}
              >
                Change Sequence
              </div>
            </div>

            <div className="">
              {/* <Input_V4
                sid={globalSid}
                id="workflowscope"
                label="Workflow Instructions"
                placeholder={"Detail instructions specific to this workflow"}
                textareaRows={2}
                onInput={globalInputHandler}
                validators={[]}
                validityMessage="Min 6 Charactors"
              />

              <div className="pt-1">
                <FileUploaderV4 id="attachment" attachmentHandler={globalAttachmentHandler} fileNameOverride={"jobs"} />
              </div> */}

              {/* <div className="flex grow">
                {" "}
                <div className="w-1/3 pt-2">
                  <InputV4
                    label="Workflow Due Date"
                    id="workflowDueDate"
                    type="date"
                    customClasses={"rounded-md md:text-sm"}
                    sid={globalSid}
                    onInput={globalInputHandler}
                    validators={[VALIDATOR_MINLENGTH(6)]}
                    validityMessage="Please Set A Due Date"
                  />
                </div>
                <div className="w-1/3 pt-2 mx-2">
                  <InputV4
                    label="Estimated Time in hours"
                    id="workflowEtimatedTime"
                    customClasses={"rounded-md md:text-sm"}
                    sid={globalSid}
                    onInput={globalInputHandler}
                    validators={[VALIDATOR_NUMBER()]}
                    validityMessage="Add Estimated Time"
                  />
                </div>
                <div className="w-1/3 ">
                  {" "}
                  <PrioritySelector sid={globalSid} id="workflowPriority" inputHandler={globalInputHandler} notRequired={true} />
                </div>
              </div> */}
            </div>
          </div>
        </div>

        <div className="border-t-4 border-t-taskinatorLightGrey h-fit overflow-auto  ">
          <TaskWorkflowSequenceDisplay incomingWorkflowData={workflowData} stateId={stateId} dispatch={globalDispatch} setModal={setWorkflowModal} currentlySetActionData={actions[stateId]} />
        </div>

        {/* <div className="mt-1 border-l border-l-4 border-l-taskinatorGreen py-2 px-4 text-taskinatorDarkGrey bg-taskinatorWhite shadow mb-1 text-md font-semibold uppercase flex items-center ">
          <div>WorkfFlow</div>
        </div>

        <div className="bg-taskinatorWhite shadow  border-l border-l-4 border-l-taskinatorGreen ">
          <TaskWorkflowSequenceDisplay incomingWorkflowData={workflowData} stateId={stateId} dispatch={globalDispatch} setModal={setWorkflowModal} currentlySetActionData={actions[stateId]} />
        </div> */}
      </div>

      {/* <JobFileViewer fileSets={fileSets} excludeList={excludeList} setExcludeList={setExcludeList} startingWorkflow={workflowData.name} globalInputHandler={globalInputHandler} globalSid={globalSid} /> */}

      {/* {workflowModal && (
        <TaskWorkflowActionSetModal incomingWorkflowData={workflowData} stateId={stateId} dispatch={globalDispatch} setModal={setWorkflowModal} currentlySetActionData={actions[stateId]} />
      )} */}
      {scopeModal && (
        <Modal local={() => setScopeModal()}>
          <div className="w-96">
            <div className="w-full text-lg text-taskinatorDarkGrey font-semibold text-center pb-2">Set/Edit Workflow Scope</div>
            <Input_V4 id={"scope"} sid={"workflow"} textareaRows={5} defaultValue={workflowScopeValue} onInput={scopeChangeHandler} validators={[]} />
            <Button onClick={() => setScopeModal()} label={"Save Scope"} customClasses={`bg-taskinatorBlue text-taskinatorWhite h-10 w-full mt-4`} />
          </div>
        </Modal>
      )}
    </>
  );
};

export default TaskWorkflowElementDisplayV4;
