import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../../../contexts/appContext";
import axios from "axios";
import { useInit } from "../../hooks/useInit";
import Button from "../../UI_elements/button";
import IconSpinner from "../../UI_elements/iconSpinner";
import CountUpTimer from "../../../utils/countUpTimer";

import Modal from "../../UI_elements/modal";

const ClockInClockOut = ({ tid, tracerId }) => {
  const { initState, initStarted, initCompleted, initErrors, initReset } = useInit(3);
  const appData = useContext(AppContext);
  const [timeSheet, setTimeSheet] = useState();
  const [ClockedInAlready, setClockedInAlready] = useState();

  useEffect(() => {
    initStarted(0);
    axios
      .get(process.env.REACT_APP_BACKEND_URL + `/api/timesheet/time/${tracerId}/${tid}`, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      .then((response) => {
        setTimeSheet(response.data);
        initCompleted(0);
      })
      .catch((err) => {
        console.log(err);
        return initErrors(err?.response?.data?.message ?? err?.message ?? "", 0);
      });
  }, [tid, tracerId]);

  const clockInHandler = (tracerId_toUse, tid_toUse) => {
    initStarted(1);
    axios
      .put(
        process.env.REACT_APP_BACKEND_URL + `/api/timesheet/clockIn/${tracerId_toUse}/${tid_toUse}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        setTimeSheet(response.data);
        initCompleted(1);
      })
      .catch((err) => {
        console.log(err);

        if (err.response.data.tracerId) {
          setClockedInAlready(err.response.data.tracerId);
        }
        return initErrors(err?.response?.data?.message ?? err?.message ?? "", 1);
      });
  };

  const clockOutHandler = (tracerId_toUse, tid_toUse, notThisTimer) => {
    initStarted(2);
    axios
      .put(
        process.env.REACT_APP_BACKEND_URL + `/api/timesheet/clockOut/${tracerId_toUse}/${tid_toUse}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        if (!notThisTimer) {
          setTimeSheet(response.data);
        } else {
          setClockedInAlready();
          clockInHandler(tracerId, tid);
        }

        initCompleted(2);
      })
      .catch((err) => {
        console.log(err);
        return initErrors(err?.response?.data?.message ?? err?.message ?? "", 2);
      });
  };

  if (timeSheet) {
    const clockedIn = timeSheet.clockedIn;

    return (
      <>
        <div className={`items-center flex mr-2`}>
          <div className={`px-4 border h-8 ${clockedIn ? "border-taskinatorRed" : "border-taskinatorBlue"} rounded-l-lg items-center flex`}>
            <CountUpTimer startDate={clockedIn} hoursOnly={timeSheet.totalTime} />
          </div>
          {!timeSheet.clockedIn && (
            <div>
              <Button label={"Clock In"} customClasses={"bg-taskinatorBlue h-8 uppercase rounded-l-none  text-base"} onClick={() => clockInHandler(tracerId, tid)} isLoading={initState[1].started} />
            </div>
          )}
          {timeSheet.clockedIn && (
            <Button label={"Clock Out"} customClasses={"bg-taskinatorRed h-8 uppercase rounded-l-none  text-base"} onClick={() => clockOutHandler(tracerId, tid)} isLoading={initState[2].started} />
          )}
        </div>
        {ClockedInAlready && (
          <Modal
            local={() => {
              setClockedInAlready();
            }}
          >
            <div className="text-taskinatorDarkGrey text-base sm:w-96 w-full text-center">
              <div className="text-lg font-medium">WARNING!</div>
              <div className="py-1">You're already clocked in elsewhere. You MUST clock out their in order to clock in here.</div>
              <Button
                onClick={() => {
                  appData.setViewAction({
                    tracerId: ClockedInAlready.tracerId,
                    getData: appData.viewAction.getData,
                  });
                  setClockedInAlready();
                }}
                customClasses={"bg-taskinatorBlue w-full text-base my-2 py-1 px-2 text-taskinatorWhite"}
                label={"Go To Clocked In Tasks"}
              />
              <Button
                onClick={() => clockOutHandler(ClockedInAlready.tracerId, ClockedInAlready.tid, true)}
                label="Clock In Here Instead"
                customClasses={"bg-taskinatorRed w-full text-base  py-1 px-2 text-taskinatorWhite"}
                isLoading={initState[2].started}
              />
            </div>
          </Modal>
        )}
      </>
    );
  } else
    return (
      <div className="items-center flex  h-full">
        <IconSpinner />
      </div>
    );
};

export default ClockInClockOut;
