import React, { useEffect, useState } from "react";
import Input_V4 from "./input_V4";
import axios from "axios";
import { useInputV4 } from "../../stateManagement/useInputV4";
import { useInit } from "../hooks/useInit";

const LiveDataCheckOnInput = ({ onValid, placeholder, id, sid, label, defaultValue, route, minLength, validators }) => {


  const { inputState, inputHandler, attachmentHandler } = useInputV4({ inputs: { input: { value: defaultValue ? defaultValue : "", isValid: defaultValue ? true : false } } }, false);
  const { initState, initStarted, initCompleted, initErrors, initReset } = useInit();
  const [isValid, setIsValid] = useState(defaultValue ? true : false);

  const length = inputState.inputs.input.value.trim().length > minLength - 1

  const getData = () => {
    initStarted(0);
    setIsValid();
    axios
      .get(process.env.REACT_APP_BACKEND_URL + `/api/${route}/check/${id}/${inputState.inputs.input.value}`, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      .then((response) => {
        setIsValid(response.data.isValid);
        initCompleted(0);
      })
      .catch((err) => {
        console.log(err);
        initErrors(err.message ? err.message : err.response.data ? err.response.data.message : "Unknown Error", 0);
      });
  };

  //// the use effect to
  useEffect(() => {
    const delay = 500;
    let timer;

    clearTimeout(timer);

    if (length && defaultValue !== inputState.inputs.input.value) {

      timer = setTimeout(() => {
        getData();
      }, delay);
    } else {
      initCompleted(0);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [inputState]);

  /// this sends the value of input and the validatation back to the main functino
  useEffect(() => {
    onValid(id, inputState.inputs.input.value, isValid, sid);
  }, [isValid, inputState]);

  return (
    <div className="pt-2">
      <div className="w-full">
        <Input_V4
          onInput={inputHandler}
          validators={validators}
          placeHolder={placeholder}
          defaultValue={inputState.inputs.input.value}
          label={label}
          sid="inputs"
          id="input"
          waitingOnPendingData={initState[0].started}
          validityMessage={`Enter at least ${minLength} characters`}
        />
      </div>
      {initState[0].error && <div className="text-taskinatorRed text-xs">{initState[0].error}</div>}
      {initState[0].finished && isValid === false &&  length && <div className="text-taskinatorRed text-xs">Name must be unique</div>}
    </div>
  );
};

export default LiveDataCheckOnInput;
