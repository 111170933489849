import React, { useState } from "react";
import Modal from "../../UI_elements/modal";
import ItemRevisionDetailsContainer from "../items/itemRevisionDetailsContainer";
import FileRowDisplay from "./fileRowDisplay";

const FileRegisterListElement = ({ itemData, searchAndAdd, itemsSelected, onSelectHandler, isRegister, tid, setFileSelection, fileSelection }) => {
  const [viewItemDetails, setViewItemDetails] = useState();
  // const {tid} = useParams();

  const sectionCSS = "flex border-r border-r-taskinatorLightGrey whitespace-nowrap items-center h-full";
  const widths = [
    "w-10 shrink-0 border-r-2 justify-center",
    " xl:w-4/12 sm:w-8/12  shrink-0 px-2 border-r-2 ",
    "xl:w-1/12 w-2/12 shrink-0 px-2 justify-center border-r-2",
    "xl:w-1/12 w-2/12 shrink-0 px-2 justify-center border-r-2",
    "w-32 px-2 justify-center items-center border-r-2",
    "w-2/12 shrink-0 px-2 border-r-2 hidden xl:flex",
    "w-2/12 shrink-0 px-2 border-r-2 hidden xl:flex",
    "w-2/12 shrink-0 px-2 justify-center border-r-2 hidden 2xl:flex",
    "w-1/12 shrink-0 px-2 justify-center hidden 2xl:flex",
    "lg:w-1/12 md:w-2/12 md:flex hidden shrink-0 px-2 justify-center border-r-2",
    " shrink-0 px-2 border-r-2 xl:flex w-60 hidden",
  ];

  const availableItems = tid
    ? itemData &&
      itemData.length > 0 &&
      itemData.filter(
        (item) =>
          !item.inJob
            .map((itemOnTask) => {
              return itemOnTask._id;
            })
            .includes(tid)
      )
    : itemData && itemData.length > 0 && itemData;

  const itemsOnTask = tid
    ? itemData &&
      itemData.length > 0 &&
      itemData.filter((item) =>
        item.inTask
          .map((itemOnTask) => {
            return itemOnTask._id;
          })
          .includes(tid)
      )
    : null;

  return (
    <>
      <div className={`w-full flex flex-col overflow-auto`}>
        <div className="w-full flex-col justify-center items-center h-full overflow-auto flex  pt-1">
          <div className="flex bg-taskinatorWhite text-[0.6rem] font-semibold text-taskinatorDarkGrey py-2 uppercase w-full h-10 mb-0.5">
            <div className={`${sectionCSS} ${widths[0]}`}></div>
            <div className={`${sectionCSS} ${widths[4]}`}>Status</div>

            <div className="flex w-full items-center">
              {/* <div className={`${sectionCSS} ${widths[2]}`}>Number</div> */}
              <div className={`${sectionCSS} ${widths[1]}`}>File</div>
              <div className={`${sectionCSS} ${widths[3]}`}>Revision</div>
              {/* <div className={`${sectionCSS} ${widths[9]}`}>Attachment</div> */}
              <div className={`${sectionCSS} ${widths[10]}`}>Task Access</div>
              <div className={`${sectionCSS} ${widths[10]}`}>File Set</div>

              {/* {isRegister && (
                <div className={`${sectionCSS} ${widths[6]}`}>Access Tag/s</div>
              )} */}
              {/* {isRegister && <div className={`${sectionCSS} ${widths[7]}`}>Created By</div>} */}
              {isRegister && <div className={`${sectionCSS} ${widths[8]} border-r-0`}>Created Date</div>}
            </div>
            {/* <div className={`${sectionCSS} ${widths[0]}`}></div> */}
          </div>
          <div className="w-full overflow-auto scrollbar ">
            {availableItems &&
              availableItems.length > 0 &&
              availableItems.map((file, index) => {
                const thisFileHasBeenSelected = fileSelection?.file?._id === file._id ? true : false;

                return (
                  <div key={itemData._id ?? index} className={`w-full pb-0.5 `}>
                    <FileRowDisplay
                      file={file}
                      index={0}
                      sectionCSS={sectionCSS}
                      widths={widths}
                      setViewItemDetails={setViewItemDetails}
                      itemsSelected={itemsSelected}
                      onSelectHandler={onSelectHandler}
                      searchAndAdd={searchAndAdd}
                      isRegister={isRegister}
                      fileSelection={fileSelection?.file}
                      setFileSelection={setFileSelection}
                      tid={tid}
                    />

                    {/* {!itemData?.released?._id && itemData?.draft?._id && (
                      <FileRowDisplay
                        itemRowData={itemData}
                        index={itemData?.released?._id ? 1 : 0}
                        itemState={"draft"}
                        sectionCSS={sectionCSS}
                        widths={widths}
                        setViewItemDetails={setViewItemDetails}
                        itemsSelected={itemsSelected}
                        onSelectHandler={onSelectHandler}
                        searchAndAdd={searchAndAdd}
                        isRegister={isRegister}
                        tid={tid}
                      />
                    )} */}
                    {/* {!itemData.released && itemData.draft && rowDisplay(itemData, itemData.released ? 1 : 0, "draft")} */}
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      {viewItemDetails && (
        <Modal
          size="max"
          title="Item Revision History"
          bgColour="bg-taskinatorLightGrey"
          local={() => {
            setViewItemDetails();
          }}
        >
          <ItemRevisionDetailsContainer iid={viewItemDetails.itemData._id} itemState={viewItemDetails.itemState} />
        </Modal>
      )}
    </>
  );
};

export default FileRegisterListElement;
