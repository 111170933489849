import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../contexts/appContext";
import { TaskContext } from "../../contexts/taskContext";
import { useInputV4 } from "../../stateManagement/useInputV4";
import Button from "../UI_elements/button";
import MemberSelector from "../dataSelectors/memberSelector";
import PrioritySelector from "../dataSelectors/prioritySelector";
import TagSelector from "../dataSelectors/tagSelector";
import { JobModalContext } from "../displayElements/jobs/jobModalContext";
import { useInit } from "../hooks/useInit";
import InputV4 from "../inputs/input_V4";
import { VALIDATOR_MINLENGTH, VALIDATOR_NUMBER } from "./validators/validators";
import LiveDataCheckOnInput from "../inputs/liveDataCheckOnInput";
import BulkFileUpload from "../displayElements/files/bulkFileUpload";
import OldFileUploaderV4 from "../inputs/oldFIleUploaderV4";
import IconSpinner from "../UI_elements/iconSpinner";
import DurationSelector from "../dataSelectors/durationSelector";
import JobFileSelector from "../../components/displayElements/jobs/jobFileSelector";

const JobDetailsForm = ({
  jobId,
  details,
  globalSid,
  globalInputHandler,
  disabled,
  workflowId,
  closeModal,
  onFilesUploaded,
  uploadTrigger,
  onSuccess = () => {},
  project,
  tracerId,
  errors,
  newJob,
  addDetails,
  fileSetExclusions,
}) => {
  const appData = useContext(AppContext);
  const taskContext = useContext(TaskContext);

  const [manualNumber, setManualNumber] = useState(details ? true : false);

  const [nextNumber, setNextNumber] = useState();
  const [availableDepartments, setAvailableDepartments] = useState();

  const jobData = useContext(JobModalContext);
  const [tempFiles, setTempFiles] = useState([]);
  // const [localUploadTrigger, setLocalUploadTrigger] = useState();
  const { inputState, inputHandler, attachmentHandler } = useInputV4({
    inputs: {},
    attachments: {},
    deleteRef: {},
    isValid: false,
  });
  const { initState, initStarted, initCompleted, initErrors } = useInit(2);
  const sid = "inputs";

  console.log(inputState, "job details");

  const onSaveHandler = async () => {
    initStarted(0);

    axios
      .post(
        process.env.REACT_APP_BACKEND_URL + `/api/job/edit/${jobData.jobId}`,
        {
          ref: inputState.inputs.ref.value,
          dueDate: inputState.inputs.dueDate.value,
          priority: inputState.inputs.priority.value,
          scope: inputState.inputs.scope.value,
          attachments: Object.values(inputState?.attachments ?? {}).map((attachment) => {
            return {
              key: attachment.value.key,
              fileName: attachment.value.fileName,
              fileExtension: attachment.value.fileExtension,
              _id: attachment.value._id,
            };
          }),
          taskTags: inputState.inputs.taskTag.value,
          managers: inputState?.inputs?.managers?.value ?? [],
          watchers: details ? details.watchers : [],
          itemsOnTask: details.itemsOnTask,
          estimatedTime: inputState?.inputs?.estimatedTime?.value,
          project: inputState?.inputs?.project?.value,
          fileSetIds: inputState?.inputs?.refFileSet?.value ?? [],
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        initCompleted(0);
        onSuccess();
        closeModal();
      })
      .catch((err) => {
        return initErrors(err?.response?.data?.message ?? err?.message ?? "", 0);
      });

    // updateJobDetail({
    //   jobId: jobData.jobId,
    //   data: {
    //     ref: inputState.inputs.ref.value,
    //     dueDate: inputState.inputs.dueDate.value,
    //     priority: inputState.inputs.priority.value,
    //     scope: inputState.inputs.scope.value,
    //     attachments: Object.values(inputState?.attachments ?? {}).map((attachment) => {
    //       return {
    //         key: attachment.value.key,
    //         fileName: attachment.value.fileName,
    //         fileExtension: attachment.value.fileExtension
    //       };
    //     }),
    //     taskTags: inputState.inputs.taskTag.value,
    //     managers: inputState.inputs.managers.value,
    //     watchers: details ? details.watchers : [],
    //     itemsOnTask: details.itemsOnTask,
    //     estimatedTime: inputState.inputs.estimatedTime.value,
    //     project: inputState.inputs.project.value,
    //   },
    //   onFinish: (res, err) => {

    //     initCompleted(0);
    //     if (!res || err) {
    //       console.log(err)
    //       initErrors(err?.response?.data?.message ?? err?.message ?? "Error while updating Job Detail", 0);
    //     } else {
    //       onSuccess();
    //       // taskContext.getActionData();
    //       closeModal();
    //     }
    //   },
    // });
  };

  useEffect(() => {
    const sendRequest = () => {
      if (!manualNumber) {
        initStarted(1);
        axios
          .get(process.env.REACT_APP_BACKEND_URL + `/api/job/nextNumber`, {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          })
          .then((response) => {
            setNextNumber(response.data);
            inputHandler("ref", response.data.nextNumber, true, "inputs");
            initCompleted(1);
          })
          .catch((err) => {
            return initErrors(err?.response?.data?.message ?? err?.message ?? "", 1);
          });
      }
    };

    sendRequest();
  }, [manualNumber]);

  useEffect(() => {
    const sendRequest = () => {
      initStarted(1);
      axios
        .get(process.env.REACT_APP_BACKEND_URL + `/api/job/getByDepartmentRole`, {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        })
        .then((response) => {
          setNextNumber(response.data);

          initCompleted(1);
        })
        .catch((err) => {
          return initErrors(err?.response?.data?.message ?? err?.message ?? "", 1);
        });
    };

    sendRequest();
  }, []);

  /// this sends to global details
  useEffect(() => {
    if (globalSid) {
      Object.keys(inputState.inputs).map((inputKey) => globalInputHandler(inputKey, inputState.inputs[inputKey].value, inputState.inputs[inputKey].isValid, globalSid));

      globalInputHandler(
        "attachments",
        Object.keys(inputState.attachments).map((key) => {
          return inputState.attachments[key].value;
        }),
        Object.values(inputState.attachments).filter((attachment) => attachment.isValid === false).length === 0,
        globalSid
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputState]);

  let refFileSetDefaults = {};

  Object.values(details?.referenceFileSetIds ?? {}).map((fileSet) => {
    refFileSetDefaults = { [fileSet.fileSetDetails._id.toString()]: fileSet.fileSetDetails };
  });

  return (
    <div className={!details ? "bg-taskinatorWhite border border-taskinatorMedGrey rounded-lg py-2 px-4 shadow grow  " : "flex flex-col gap-2 "}>
      {!manualNumber && (
        <div>
          <div className="text-sm mt-2 flex">
            <div className="text-taswkinatorDarkGrey">Job Number/Reference</div>
            <div className="text-taskinatorRed pl-1">(Required)</div>
          </div>
          <div className="text-base rounded-md block w-full appearance-none border border-taskinatorMedGrey px-3 py-2 text-gray-900 placeholder-gray-500">
            {initState[1].started && (
              <div className="flex w-fll items-center">
                <IconSpinner />
                <div className="text-sm text-taskinatorBlue">Getting next item number...</div>
              </div>
            )}
            {/* {!initState[1].started && initState[1].error && <div className="text-sm text-taskinatorRed">{initState[1].error}</div>} */}
            {initState[1].finished && (
              <div className="flex w-full items-center text-xs">
                <div className="grow">{nextNumber.nextNumber}</div>
                <div
                  onClick={() => {
                    setManualNumber(true);
                  }}
                  className="text-xs text-taskinatorBlue underlined hover:opacity-75 cursor-pointer"
                >
                  Enter Manually
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      {manualNumber && (
        <div>
          <LiveDataCheckOnInput
            validators={[VALIDATOR_MINLENGTH(3)]}
            onValid={inputHandler}
            sid={"inputs"}
            id="ref"
            defaultValue={details?.ref ?? ""}
            placeholder={`Enter job number or ref`}
            label="Job Number/Reference"
            route="job"
            minLength={2}
          />
          <div
            onClick={() => {
              setManualNumber();
            }}
            className="text-xs text-taskinatorBlue underlined hover:opacity-75 cursor-pointer"
          >
            Or Get Next
          </div>
        </div>
      )}

      <div className="pt-1">
        <MemberSelector
          inputHandler={inputHandler}
          defaultValue={
            details
              ? details.managers.map((manager) => {
                  return { value: manager._id };
                })
              : []
          }
          sid={sid}
          id="managers"
          label={"Manager/s"}
          multi
          roleTypes={[process.env.REACT_APP_ACCESS_M, process.env.REACT_APP_ACCESS_O, process.env.REACT_APP_ACCESS_A]}
          dontAutoSelect
          selectionDisplayText={"Add Member"}
          toolTip={"Who is responsible for this job"}
        />
      </div>

      <div className="pt-1 ">
        <DurationSelector onInput={inputHandler} sid="inputs" id="estimatedTime" label="Estimated Duration" />
      </div>

      <div className="py-1">
        <InputV4
          label="Due Date"
          id="dueDate"
          type="date"
          customClasses={"rounded-md md:text-sm lowercase"}
          disabled={disabled}
          sid={sid}
          onInput={inputHandler}
          defaultValue={details ? details.dueDate : null}
          validators={[VALIDATOR_MINLENGTH(6)]}
          validityMessage="Please Set A Due Date"
        />
      </div>

      <PrioritySelector sid="inputs" id="priority" inputHandler={inputHandler} defaultValue={details?.priority ?? null} />

      <div className="pt-1">
        <InputV4
          label="Job Background/Scope"
          id="scope"
          customClasses={"rounded-md md:text-sm"}
          placeholder="Detail scope of works for this task and it's deliverables..."
          textareaRows={3}
          sid={sid}
          onInput={inputHandler}
          validators={[]}
          defaultValue={details ? details.scope : null}
          disabled={disabled}
          // requiredOverride
        />
        {/* <div className="text-[0.75rem]">{inputState.inputs && inputState.inputs.scope && 500 - inputState.inputs.scope.value.length} characters remaining</div> */}
      </div>

      <div className="pt-1">
        <BulkFileUpload
          globalHandler={{
            inputHandler: inputHandler,
            attachmentHandler: attachmentHandler,
          }}
          defaultValue={details ? details.attachments : null}
          // label="Job Reference Documents"
          disabled={disabled}
          noButton
          type="job"
        />
        {/* <FileUploaderV4
          label="Reference Documents"
          disabled={disabled}
          id="attachment"
          inputHandler={inputHandler}
          attachmentHandler={attachmentHandler}
          defaultValue={details ? details.attachments : null}
          dontAutoUpload={true}
          tempFileHold={tempFiles}
          setTempFileHold={setTempFiles}
          jobId={jobId}
        /> */}
      </div>

      <div className="w-full">
        <JobFileSelector
          referenceFileSet={"refFileSet"}
          globalSid={"inputs"}
          globalInputHandler={inputHandler}
          workflowId
          workflowAdded={null}
          number={`3`}
          noNotes
          defaultValue={details ? refFileSetDefaults : null}
          fileSetExclusions={fileSetExclusions}
          notRequired
          toolTip={"Select folder to be used as reference only within this job. ie. Customer supplied drawings. This folder is shown at every step in the workflow. "}
        />
      </div>

      <div className="pt-1">
        <TagSelector
          notRequired
          id={"taskTag"}
          inputHandler={inputHandler}
          defaultValue={
            details
              ? details.taskTags.map((tag) => {
                  return { value: tag._id };
                })
              : []
          }
          sid="inputs"
          type="task"
          label="Tags"
          selectionDisplayText={"Add/Create Job Tag"}
        />
      </div>

      {details && (
        <Button
          onClick={() => {
            onSaveHandler();
          }}
          label={"Save Job Details"}
          customClasses={`${inputState.isValid ? "bg-taskinatorBlue" : "bg-taskinatorMedGrey"} bg-taskinatorBlue text-taskinatorWhite h-10 w-full`}
          disabled={!inputState.isValid}
          isLoading={initState[0].started}
          error={initState[0].error}
        />
      )}
    </div>
  );
};

export default JobDetailsForm;
