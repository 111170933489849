import axios from "axios";
import { saveAs } from "file-saver";
import React, { useContext, useEffect, useState } from "react";
import IconSpinner from "../components/UI_elements/iconSpinner";

import { MdFolderZip, MdImage, MdPictureAsPdf , MdClose} from "react-icons/md";
import Modal from "../components/UI_elements/modal";
import StringShortenDisplay from "../components/UI_elements/stringShortenDisplay";
import { useInit } from "../components/hooks/useInit";
import { previewFileExtensions } from "../constants";
import { AppContext } from "../contexts/appContext";
import AdobePDFViewerContainer from "./adobePDFViewerContainer";
import { StampPDF } from "../components/functions/mergeAndStampPDF";

const FilePreview = ({
  file,
  viewMarkupsOnly,
  setMarkupDocument,
  viewInMarkup,
  savedMarkupURL,
  markup,
  isPreview,
  showName,
  notClickable,
  wide,
  colorChange,
  textLimit,
  noName,
  small,
  typeOverride,
  deletable
}) => {
  const { initState, initStarted, initCompleted, initErrors, initReset } = useInit(2);
  const [previewURL, setPreviewURL] = useState();
  const [previewError, setPreviewError] = useState();
  const [fileURL, setFileURL] = useState();
  const [modal, setModal] = useState();
  const appData = useContext(AppContext);
  const [expand, setExpand] = useState();

  const getPreviewURL = async (type) => {
    initStarted(1, type);

    if (type === "preview") {
      setPreviewError();
    }

    try {
      const responseData = await axios.get(
        process.env.REACT_APP_BACKEND_URL + `/api/attachment/get/${file.key}${type === "preview" ? "__preview" : ""}/${type === "file" ? (typeOverride ? typeOverride : "document") : type}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      const response = responseData.data;

      let dataToUse;
      let fileData;
      if (!response?.noPreview) {
        switch (response.driveOption) {
          case "GOOGLE":
            break;

          case "MICROSOFT":
            fileData = await axios.get(
              `https://graph.microsoft.com/v1.0/me/drive/items/${response.thirdPartyStorageId}/content`,
              // `https://graph.microsoft.com/v1.0/me/drive/root:/${encodeURIComponent(process.env.REACT_APP_EXTERNAL_STORAGE_FOLDER_NAME)}/${file.key}${type === "preview" ? "__preview" : ""}${
              //   response.fileExtension
              // }:/content`,
              {
                responseType: "blob",
                headers: {
                  Authorization: `Bearer ${response.accessToken}`,
                },
              }
            );

            break;

          case "AWS":
            fileData = await axios.get(response.signedURL, {
              responseType: "blob",
            });

            break;
        }

        if (!setMarkupDocument) {
          if (type === "preview") {
            setPreviewURL(URL.createObjectURL(fileData.data));
          }

          if (type === "file") {
            if (previewFileExtensions.includes(file?.fileExtension?.toLowerCase())) {
              if (file.status && file.type === "document" && file?.fileExtension?.toLowerCase() === ".pdf") {
                const stampedFile = await StampPDF(fileData.data, file);
                setFileURL({ url: URL.createObjectURL(stampedFile), blob: stampedFile });
              } else {
                setFileURL({ url: URL.createObjectURL(fileData.data), blob: fileData.data });
              }

              // setFileURL(dataToUse.signedURL);
              setModal(true);
            } else {
              
              saveAs(
                fileData.data,
                `${decodeURIComponent(file.fileName)}${
                  file.type === "document" ? `-[Rev_${file.revision}]-[${[file.superseded ? "superseded" : file.status]}]` : ""
                }${file?.fileExtension?.toLowerCase()}`
              );
              // window.open(`${URL.createObjectURL(fileData.data)}`, `_blank`, `noopener, noreferrer`);
            }
          }
        } else {
          setMarkupDocument({ ...file, attachmentKey: file.key, pdfFileName: file.fileName, pdfURL: { url: URL.createObjectURL(fileData.data), blob: fileData.data } });
        }
      } else {
        setPreviewError(true);
      }

      initCompleted(1, type);
    } catch (err) {
      console.log(err);
      return initErrors(err?.response?.data?.message ?? err?.message ?? "", 1, type);
    }
  };

  useEffect(() => {
    if (isPreview && previewFileExtensions.includes(file?.fileExtension?.toLowerCase())) {
      getPreviewURL("preview");
    } else {
      setPreviewError(true);
    }
  }, [file.key, isPreview]);

  return (
    <>
      {isPreview ? (
        <div className="  flex shadow border border-taskinatorMedGrey rounded-lg overflow-hidden ">
          {previewURL && !previewError && (
            <div className="flex flex-col">
              <div className="relative">
                <img
                  style={{ width: "auto", height: "auto", maxWidth: "100%", maxHeight: "100%" }}
                  src={previewURL}
                  alt="No Preview"
                  className="cursor-pointer items-center justify-center flex scale-50"
                  onClick={() => getPreviewURL("file")}
                  onError={() => {
                    setPreviewError(true);
                    initCompleted(1, "preview");
                  }}
                  onLoad={() => initCompleted(1, "preview")}
                />
                {initState[1].started && <div className="absolute inset-0 flex items-center justify-center z-5 bg-taskinatorDarkGrey opacity-75 "></div>}
                {initState[1].started && (
                  <div className="absolute inset-0 flex items-center justify-center z-10  flex-col text-taskinatorWhite ">
                    <IconSpinner size="l" />
                    Downloading File...
                  </div>
                )}
              </div>

              {showName && (
                <div className="font-medium pl-1 overflow-clip text-sm text-center bg-taskinatorWhite">
                  {StringShortenDisplay(textLimit ? textLimit : 20, decodeURIComponent(file?.fileName))}
                  {file?.fileExtension?.toLowerCase()}{" "}
                </div>
              )}
            </div>
          )}
          {previewError && (
            <div className="relative w-full ">
              <div
                onClick={() => getPreviewURL("file")}
                className={` cursor-pointer ${
                  small ? "h-fit grow" : "h-[300px] w-[175px]"
                } p-4  flex items-center justify-center text-center text-wrap bg-taskinatorItemBlue text-taskinatorDarkGrey font-bold text-xs`}
              >
                No Preview - Click to view or download{" "}
              </div>
              {showName && (
                <div className="font-medium pl-1 overflow-clip text-sm text-center bg-taskinatorWhite">
                  {StringShortenDisplay(textLimit ? textLimit : 20, decodeURIComponent(file?.fileName))}
                  {file?.fileExtension?.toLowerCase()}{" "}
                </div>
              )}
              {initState[1].started && <div className="absolute inset-0 flex items-center justify-center z-5 bg-taskinatorDarkGrey opacity-75 "></div>}
              {initState[1].started && (
                <div className="absolute inset-0 flex items-center justify-center z-10 text-sm flex-col text-taskinatorWhite">
                  <IconSpinner size="s" />
                  Downloading File...
                </div>
              )}
            </div>
          )}
          {initState[1].error && <div>{initState[1].error}</div>}
          {initState[1].started && initState[1].identifier === "preview" && (
            <div className="flex items-center justify-center text-center grow h-[200px] w-[300px]">
              <IconSpinner />
            </div>
          )}
        </div>
      ) : (
        <>
          {(file?.liveMarkups || file?.markups?.length > 0) && (
            <div className="flex grow  justify-end h-0  z-40">
              <div className="relative -top-1 mr-2 items-center flex h-4 w-fit text-taskinatorWhite text-[0.5rem] bg-taskinatorRed  px-2 rounded-full font-semibold">Markups</div>
            </div>
          )}
          <div
            className={`flex flex-col `}
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <div
              className={`flex ${wide ? "w-60" : "w-fit"} rounded border bg-taskinatorItemBlue  px-2 py-1  ${!colorChange ? "border-taskinatorBlue text-taskinatorDarkGrey" : colorChange} ${
                notClickable ? "" : "cursor-pointer  hover:bg-taskinatorBlue hover:border-1 hover:text-taskinatorWhite"
              }`}
              onClick={notClickable ? () => {} : () => getPreviewURL("file")}
            >
              <div className="flex justify-start items-center grow">
                {initState[1].started && initState[1].identifier === "file" && (
                  <div className="flex ">
                    <div>{<IconSpinner color={"text-taskinatorBlue"} />}</div>
                  </div>
                )}
                {/* </div>{downloadPercentage !== 100 && <div  className="text-xs pl-1"> {downloadPercentage}%</div> */}
                {!initState[1].started && (
                  <div className="flex items-center justify-center grow ">
                    <div>
                      {file?.fileExtension?.toLowerCase() === ".pdf" ? (
                        <MdPictureAsPdf size={"1.25rem"} />
                      ) : file?.fileExtension?.toLowerCase() === ".zip" ? (
                        <MdFolderZip size={"1.25rem"} />
                      ) : (
                        <MdImage size={"1.25rem"} />
                      )}
                    </div>
                    {!noName && (
                      <div className="font-medium pl-1 overflow-clip text-xs  ">
                        {StringShortenDisplay(textLimit ? textLimit : 20, decodeURIComponent(file?.fileName))}
                        {file?.fileExtension?.toLowerCase()}{" "}
                      </div>
                    )}
                    <div className="flex grow justify-end "></div>{" "}
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}

      {modal && (
        <Modal
          expand
          size={expand ? "full" : "large"}
          local={() => {
            setModal();
            appData.socket.emit(
              "pdfAnnotation",
              {
                action: "disconnect",
                attachmentKey: file.key,
              },
              (callback) => {}
            );
          }}
          isWithoutClose={file.fileExtension.toLowerCase() === ".pdf"}
        >
          {file.fileExtension.toLowerCase() === ".pdf" ? (
            <div className="flex flex-row h-full text-taskinatorDarkGrey">
              <AdobePDFViewerContainer
                expand={expand}
                setExpand={setExpand}
                attachmentKey={file.key}
                pdfFileName={file.fileName}
                pdfURL={fileURL}
                file={file}
                savedMarkupURL={savedMarkupURL}
                viewMarkupsOnly={viewMarkupsOnly}
                uniqueID={file.key}
                typeOverride={typeOverride}
                close={() => {
                  setModal();
                  appData.socket.emit(
                    "pdfAnnotation",
                    {
                      action: "disconnect",
                      attachmentKey: file.key,
                    },
                    (callback) => {}
                  );
                }}
              />
            </div>
          ) : (
            <div className="h-full w-full flex items-center justify-center">
              <img style={{ width: "auto", height: "auto", maxWidth: "100%", maxHeight: "100%" }} src={fileURL.url} alt="No Preview" className="cursor-pointer items-center justify-center flex " />
            </div>
          )}
        </Modal>
      )}
    </>
  );
};

export default FilePreview;
