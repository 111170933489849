import React, { useState } from "react";
import StringShortenDisplay from "../../UI_elements/stringShortenDisplay";
import Modal from "../../UI_elements/modal";
import ItemRevisionDetailsContainer from "./itemRevisionDetailsContainer";
import { MdUpdate } from "react-icons/md";

const ItemSimpleDetailsElement = ({ item, clickable, removeItem, aidDetails, border, stacked, current }) => {
  const [modal, setModal] = useState();
  

  return (
    <>
      <div
        onClick={
          clickable
            ? (event) => {
                event.stopPropagation();
                setModal(true);
              }
            : () => {}
        }
        className={`h-12 border  border-taskinatorBlue rounded-lg justify-center px-4  ${clickable ? "cursor-pointer hover:bg-taskinatorHoverBlue" : ""}  w-full bg-taskinatorWhite shadow  py-3 flex ${stacked ? "lg:flex-col md:flex-row flex-col" : ""} ${
          border ? "border border-taskinatorBlue rounded shadow border-2" : ""
        } `}
      >
        <div className="flex text-base font-medium text-taskinatorBlue  w-fit items-center pr-2">
        {item.draft && <div className="flex text-taskinatorOrange pr-1 border-r border-r-taskinatorMedGrey mr-1">
            <MdUpdate size={"1rem"}/>
          </div>}
          <div className="">{item.currentItemDetails.itemNumber}</div>
          <div className="px-2 ">-</div>
          <div className=" uppercase w-fit whitespace-nowrap">{StringShortenDisplay(20, item.currentItemDetails.itemName)}</div>
          <div className={`${stacked ? "hidden" : ""} px-2 font-bold text-taskinatorMedGrey `}>|</div>
        </div>

        <div className={`flex items-center h-full w-full  ${stacked ? "lg:pt-1 md:pt-0 pt-1 md:px-1 px-0 lg:px-0" : "px-1"}  `}>
          <div className={` items-center flex justify-center `}>
            {item.released ? (
              <div className="flex items-center justify-center text-taskinatorDarkGrey">
                {/* <div className="text-taskinatorMedGrey pr-2 flex  ">  <div > Released: </div> </div> */}
                <div className="pr-2 ">Released Rev:</div>
                <div className="font-semibold">{item.released.revision}</div>
              </div>
            ) : (
              <div className="text-xs text-taskinatorRed opacity-60 justify-center flex w-full">No Current Release</div>
            )}
          </div>
          <div className={`px-2 font-bold text-taskinatorMedGrey `}>|</div>
          <div className={`items-center flex justify-center px-1 `}>
            {item.draft ? (
              <div className="flex items-center text-taskinatorDarkGrey">
                {/* <div className=" text-taskinatorMedGrey pr-2  flex">  <div>  Draft: </div></div> */}
                <div className="pr-2">Draft Rev:</div>
                <div className="font-semibold">{item.draft.revision}</div>
              </div>
            ) : (
              <div className="text-xs text-taskinatorRed opacity-60">No Pending Drafts</div>
            )}{" "}
          </div>
        </div>
        <div className="flex grow"></div>
        { removeItem && (
          <div
            className={`text-xs cursor-pointer text-taskinatorRed hover:opacity-100 opacity-50 justify-end w-fit px-4 flex items-center `}
            onClick={(event) => {
              event.stopPropagation();
              removeItem(item._id)
    
            }}
          >
            Remove
          </div>
        )}
      </div>

      {modal && (
        <Modal
          size="max"
          title="Item Version History"
          bgColour="bg-taskinatorLightGrey"
          local={() => {
            setModal();
          }}
        >
          <ItemRevisionDetailsContainer iid={item._id} aidDetails={aidDetails} />
        </Modal>
      )}
    </>
  );
};

export default ItemSimpleDetailsElement;
