export const useTimeDisplay = () => {
  
  
  const getRunTime = (setDueDate, isDue, displayAsNow) => {
    if (setDueDate) {
      const currentDate = new Date();
      const dueDate = new Date(setDueDate);

      const oneMinute = 1000 * 60;
      const oneHour = 1000 * 60 * 60;
      const oneDay = 1000 * 60 * 60 * 24;

      const diffInTime = Math.abs(currentDate.getTime() - dueDate.getTime());

      const overDue = currentDate.getTime() > dueDate.getTime();
      const diffInMinutes = Math.round(diffInTime / oneMinute);
      const diffInHours = Math.round(diffInTime / oneHour);
      const diffInDays = Math.round(diffInTime / oneDay);

      let textAtEnd;
      let timeVal;

      if (diffInMinutes < 60 && diffInHours < 24) {
        textAtEnd =
          diffInMinutes === 1 || diffInMinutes === -1 ? "min" : "mins";
        // return `${isInPast ? "": "in "}` + diffInMinutes + " " + textAtEnd + `${isInPast ? " ago": ""}`;
        // return { value: diffInMinutes, textAtEnd: textAtEnd, overDue:overDue }
        timeVal = diffInMinutes;
      } else if (diffInMinutes > 60 && diffInHours < 24) {
        textAtEnd = diffInHours === 1 || diffInHours === -1 ? "hour" : "hours";
        // return { value: diffInHours, textAtEnd: textAtEnd, overDue:overDue }
        // return `${isInPast ? "": "in "}` + diffInHours + " " + textAtEnd + `${isInPast ? " ago": ""}`;
        timeVal = diffInHours;
      } else {
        textAtEnd = diffInDays === 1 || diffInDays === -1 ? "day" : "days";
        // return `${isInPast ? "": "in "}` + diffInDays + " " + textAtEnd + `${isInPast ? " ago": ""}`;
        // return { value: diffInDays, textAtEnd: textAtEnd, overDue:overDue }
        timeVal = diffInDays;
      }

      const standardDisplayString =
        `${overDue ? "" : "in "}` +
        timeVal +
        " " +
        textAtEnd +
        `${overDue ? " ago" : ""}`;

      return (
        <div className={`${overDue && isDue ? "text-taskinatorRed" : ""}`}>
          {overDue && displayAsNow ? "NOW" : standardDisplayString}
        </div>
      );
    } else {
      return <div>N/A</div>;
    }
  };

  return { getRunTime };
};

// const getNumberOfDays = (setDueDate) => {
//   const currentDate = new Date();
//   const dueDate = new Date(setDueDate);

//   const oneMinute = 1000 * 60;
//   const oneHour = 1000 * 60 * 60;
//   const oneDay = 1000 * 60 * 60 * 24;

//   const diffInTime = currentDate.getTime() - dueDate.getTime();

//   const diffInMinutes = Math.round(diffInTime / oneMinute);
//   const diffInHours = Math.round(diffInTime / oneHour);
//   const diffInDays = Math.round(diffInTime / oneDay);

//   let textAtEnd;
//   if (diffInMinutes < 60 && diffInHours < 24) {
//     textAtEnd = diffInMinutes === 1 || diffInMinutes === -1 ? "min" : "mins";
//     return diffInMinutes + " " + textAtEnd;
//   } else if (diffInMinutes > 60 && diffInHours < 24) {
//     textAtEnd = diffInHours === 1 || diffInHours === -1 ? "hour" : "hours";
//     return diffInHours + " " + textAtEnd;
//   } else {
//     textAtEnd = diffInDays === 1 || diffInDays === -1 ? "day" : "days";
//     return diffInDays + " " + textAtEnd;
//   }
// };
