import { useReducer, useCallback } from "react";

const initReducer = (state, action) => {
  switch (action.type) {
    case "INIT_STARTED":
      return { ...state, [action.id]: { ...state[action.id], started: true, error: false, identifier: action.identifier } };

    case "INIT_FINISHED":
      return { ...state, [action.id]: { started: false, finished: true, error: false, initialised: true, identifier: action.identifier } };

    case "INIT_ERROR":
      return { ...state, [action.id]: { started: false, finished: false, error: action.error, initialised: false, identifier: action.identifier } };

    case "INIT_RESET":
      return { ...action.initialValue };

    default:
      return state;
  }
};

const stateFormate = { started: false, finished: false, error: false, initialised: false, identifier: null };

export const useInit = (multiValue) => {
  let initObject = {};

  if (multiValue) {
    for (var i = 0; i < multiValue; i++) {
      initObject = { ...initObject, [i]: stateFormate };
    }
  } else {
    initObject = { ...initObject, [0]: stateFormate };
  }

  // const initialValue = multiValue ? multiValue : {started: false, finished: false, error: false}

  const [initState, dispatch] = useReducer(initReducer, initObject);

  const initStarted = useCallback((id, identifier) => {
    dispatch({
      type: "INIT_STARTED",
      id: id,
      identifier: identifier,
    });
  }, []);

  const initCompleted = useCallback((id, identifier) => {
    dispatch({
      type: "INIT_FINISHED",
      id: id,
      identifier: identifier,
    });
  }, []);

  const initErrors = useCallback((error, id, identifier) => {
    dispatch({
      type: "INIT_ERROR",
      id: id,
      error: error,
      identifier: identifier,
    });
  }, []);

  const initReset = useCallback(() => {
    dispatch({
      type: "INIT_RESET",
      initialValue: initObject,
    });
  }, []);

  return { initState, initStarted, initCompleted, initErrors, initReset };
};
