import React from "react";

import LoginForm from "../../components/forms/loginForm";
import LoginScreen from "../../pageTemplates/loginScreen";

const Login = ({ login }) => {
  return (
    <LoginScreen>
      <LoginForm login={login} />
    </LoginScreen>
  );
};

export default Login;
