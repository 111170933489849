import React, { useContext, useState } from "react";
import ColumnElement from "../../components/dashboard/columnElement";
import ColumnElementV2 from "../../components/dashboard/columnElement_V2";
import CheckboxV4 from "../../components/inputs/checkbox_V4";
import InputV4 from "../../components/inputs/input_V4";
import { AppContext } from "../../contexts/appContext";
import ContentView from "../../pageTemplates/contentView";
import JobFormatter from "../../utils/jobFormatter";
import NotificationFormatter from "../../utils/notificationFormatter";
import RequestFormatter from "../../utils/requestFormatter";
import TaskFormatter_bi from "../../utils/taskFormatter_byItemAction";
import CalendarColumnElement from "../../components/dashboard/calanderColumnElement";
import LowerCaseTrim from "../../utils/lowerCaseAndTrim";

const Dashboard = () => {
  const [filter, setFilter] = useState();
  const [mine, setMine] = useState(true);

  const appData = useContext(AppContext);

  const getTasks = () => {};
  // const getProject = () => {};
  const getRequests = () => {};
  const getNotifications = () => {};

  const allTask = appData.schedule
    ?.map((job) =>
      job.tasks
        .map((task) => task)
        .reduce((uniqueTasks, task) => {
          if (!uniqueTasks.some((t) => t._id.toString() === task._id.toString())) {
            uniqueTasks.push({ ...task, jobData: job });
          }
          return uniqueTasks;
        }, [])
    )
    .flat(1)
    .filter((task) => {
      if (!filter) {
        return true;
      } else {
        if (filter.trim().length === 0) {
          return true;
        } else {
          return (
            LowerCaseTrim(task?.title).includes(LowerCaseTrim(filter)) ||
            LowerCaseTrim(task?.workflowData?.name).includes(LowerCaseTrim(filter)) ||
            LowerCaseTrim(task?.fileSetDetails?.number).includes(LowerCaseTrim(filter)) ||
            LowerCaseTrim(task?.fileSetDetails?.name).includes(LowerCaseTrim(filter)) ||
            LowerCaseTrim(task?.jobData?.jobRef).includes(LowerCaseTrim(filter)) ||
            LowerCaseTrim(task.title).includes(LowerCaseTrim(filter)) ||
            LowerCaseTrim(task.title).includes(LowerCaseTrim(filter)) ||
            task?.jobData?.jobTags.filter((tag) => LowerCaseTrim(tag.name).includes(LowerCaseTrim(filter))).length > 0
          );
        }
      }
    });

  console.log(allTask[0]);

  const priorityTasks = (appData?.priorityTasks?.tasks ?? []).reduce((uniqueTasks, task) => {
    if (!uniqueTasks.some((t) => t._id.toString() === task._id.toString())) {
      uniqueTasks.push(task);
    }
    return uniqueTasks;
  }, []);

  const upcomingTasks = appData.schedule
    ?.map((job) =>
      job.tasks
        .map((task) => task)
        .reduce((uniqueTasks, task) => {
          if (!uniqueTasks.some((t) => t._id.toString() === task._id.toString())) {
            uniqueTasks.push({ ...task, jobData: job });
          }
          return uniqueTasks;
        }, [])
    )
    .flat(1)
    .filter((task) => !priorityTasks.map((pt) => pt._id).includes(task._id));

  const buttons = [];
  const centerContent = (
    <div className="flex items-center sm:flex-row flex-col w-full sm:w-fit sm:justify-start sm:mt-0 mt-1">
      {/* <div className="pr-2">
       <CheckboxV4
          defaultValue={true}
          onInput={(id, isChecked, isValid, sid) => {
            setMine(isChecked);
          }}
          customCheckbox={"h-4 w-4"}
          label={"Only Show My Jobs"}
          customeLabel={"font-semibold text-taskinatorDarkGrey"}
        /> 
      </div> */}
      <InputV4
        inputVer={"1"}
        customClasses={"sm:w-96 w-[100%] items-center"}
        placeholder="Search Dashboard"
        onInput={(id, value, isValid, sid) => {
          setFilter(value);
        }}
        validators={[]}
      />
    </div>
  );
  const css = " m-1 h-auto  md:min-w-[25rem]";
  // const canViewProjects =
  //   appData.workspaceDataV4.projects &&
  //   [
  //     process.env.REACT_APP_ACCESS_M,
  //     process.env.REACT_APP_ACCESS_A,
  //     process.env.REACT_APP_ACCESS_O,
  //   ].includes(appData.workspaceDataV4.role);

  return (
    <ContentView buttons={buttons} label={""} centerContent={centerContent}>
      <div className="flex flex-col h-full w-full">
        <div className="flex md:flex-row flex-col h-full w-full overflow-auto">
          <div className="md:w-fit w-full h-full  flex flex-col grow">
            <CalendarColumnElement />
          </div>

          <div className={`${css} md:w-1/4`}>
            <ColumnElementV2
              type="item"
              primaryColour={"bg-taskinatorDarkGrey"}
              faintColour={"bg-taskinatorHoverGrey"}
              primaryBorder={"border-l-taskinatorGreen"}
              label="Jobs"
              jobData={Object.values(TaskFormatter_bi(appData.tasks, filter, mine, false, false, appData))}
              jobs={Object.values(JobFormatter(appData.jobs, filter, appData)).sort((a, b) => b.jobScore - a.jobScore)}
              getData={getTasks}
              textColour={"text-taskinatorWhite"}
              initState={appData.dashboardState}
              stateNumber={1}
            />
          </div>

          <div className={`${css} md:w-1/4 flex-col flex grow`}>
            {/* <div className={` grow min-h-[20rem]  max-h-[20rem]`}> */}
            <ColumnElementV2
              type="item"
              primaryColour={"bg-taskinatorGreen border border-taskinatorMedGrey"}
              faintColour={"bg-taskinatorGreenHover"}
              primaryBorder={"border-l-taskinatorGreen"}
              label="Tasks"
              upcomingTasks={allTask}
              // priorityTasks={allTask}
              // priorityJob={appData?.priorityTasks}
              needsTimeZoneAvailability={!appData.schedule}
              getData={getTasks}
              textColour={"text-taskinatorWhite"}
              initState={appData.dashboardState}
              stateNumber={0}
            />
            {/* </div> */}
            {/* 
            <div className={`${css} grow  overflow-auto -mx-0.5`}>
              <ColumnElementV2
                type="item"
                primaryColour={"bg-taskinatorMedGrey"}
                faintColour={"bg-taskinatorHoverGrey"}
                primaryBorder={"border-l-taskinatorMedGrey"}
                label="All Other Tasks"
                upcomingTasks={allTask}
              
                
                getData={getTasks}
                textColour={"text-taskinatorDarkGrey"}
                initState={appData.dashboardState}
                stateNumber={0}
              />
            </div> */}
          </div>

          {/* <div className={`${css} md:w-1/4`}><CalendarColumnElement /></div> */}
          <div className={`${css} md:w-1/4`}>
            <ColumnElement
              type="request"
              primaryColour={"bg-taskinatorOrange border border-taskinatorMedGrey"}
              faintColour={"bg-taskinatorHoverOrange"}
              primaryBorder={"border-l-taskinatorOrange"}
              textColour={"text-taskinatorWhite"}
              label="Questions"
              getData={getRequests}
              jobData={Object.values(RequestFormatter(appData.questions, filter, appData))}
              initState={appData.dashboardState}
              stateNumber={3}
            />
          </div>
          <div className={`${css}  md:w-1/4`}>
            <ColumnElement
              type="notification"
              primaryColour={"bg-taskinatorRed border border-taskinatorMedGrey"}
              faintColour={"bg-taskinatorHoverRed"}
              primaryBorder={"border-l-taskinatorRed"}
              textColour={"text-taskinatorWhite"}
              label="Notifications"
              getData={getNotifications}
              jobData={Object.values(NotificationFormatter(appData.notifications, filter, appData))}
              initState={appData.dashboardState}
              stateNumber={4}
            />
          </div>

          {/* <div className="md:w-fit w-full h-auto mt-1">
            <CalendarColumnElement />
          </div>  */}
        </div>
      </div>
    </ContentView>
  );
};

export default Dashboard;
