import { useCallback, useReducer, useMemo } from "react";

const StandardValidation = (newState) => {
  let isValid = true;

  for (const sid in newState) {
    if (sid !== "isValid") {
      for (const key in newState[sid]) {
        isValid = isValid && newState[sid][key].isValid;
      }
    }
  }
  return isValid;
};

const inputReducer = (state, action) => {
  let newState;
  switch (action.type) {
    case "INPUT_CHANGE":
      newState = {
        ...state,
        [action.sid]: {
          ...state[action.sid],
          [action.id]: { value: action.value, isValid: action.isValid },
        },
      };
      return { ...newState, isValid: StandardValidation(newState) };

    case "REMOVE_SID":
      const currentState = { ...state };
      delete currentState[action.sid];
      return currentState;

    case "ATTACHMENT":
      newState = { ...state, attachments: action.attachments };
      return { ...newState, isValid: StandardValidation(newState) };

    case "MUST_MATCH":
      return { ...state, mustMatch: action.keys };

    case "RESET":
      console.log("resetting state", action.state)
      return { ...action.state, isValid: StandardValidation(action.state) };

    default:
      return state;
  }
};

export const useInputV4 = (initialInputs, initialState) => {
  const [inputState, dispatch] = useReducer(inputReducer, {
    ...initialInputs,
    isValid: initialState ? initialState : false,
  });

  // Memoize the state object to prevent re-renders when it doesn't actually change
  const memoizedState = useMemo(() => inputState, [inputState]);

  const inputHandler = useCallback((id, value, isValid, sid) => {
    dispatch({
      type: "INPUT_CHANGE",
      id,
      sid,
      value,
      isValid,
    });
  }, []);

  const removeSidHandler = useCallback((sid) => {
    dispatch({
      type: "REMOVE_SID",
      sid,
    });
  }, []);

  const attachmentHandler = useCallback((attachments) => {
    dispatch({
      type: "ATTACHMENT",
      attachments,
      sid: "attachments",
    });
  }, []);

  const resetState = useCallback((defaultState) => {
    dispatch({
      type: "RESET",
      state: defaultState,
    });
  }, []);

  return {
    inputState: memoizedState,
    inputHandler,
    removeSidHandler,
    attachmentHandler,
    resetState,
  };
};
