import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import MergeAndStampPDF from "../functions/mergeAndStampPDF";

const StampingFunction = async (
  itemNumber,
  revision,
  attachments,
  state,
  setPercentage,
  oidName,
  addStamp
) => {
  //  const [progress, setProgress] = useState();
  const QRcodeUUID = uuidv4();
  const QRCodeLink = `${process.env.REACT_APP_WEBSITE}/status/item/${QRcodeUUID}`;
  const fileName =
    state === "markup" ? `${revision}.pdf` : `${itemNumber}_Rev${revision}.pdf`;
  const newFileName = encodeURIComponent(fileName);

  let signedAWSURLdetails = {};

  let percentage;

  try {
    setPercentage("Stamping And Merging...");
    const stampedPDF = await MergeAndStampPDF(
      Object.values(attachments).map((attachment) => {
        return attachment.value;
      }),
      QRCodeLink,
      revision,
      state,
      itemNumber,
      oidName,
      addStamp
    );

    if (stampedPDF.error) {
      return { error: stampedPDF.error };
    }

    setPercentage("Preparing Upload...");

    const documentKey = uuidv4();
    let uploadArray = [];

    if (state === "markup") {
      uploadArray = ["stampedPDF"];
    } else {
      uploadArray = Object.keys(stampedPDF);
    }

    await Promise.all(
      uploadArray.map(async (key) => {
        const signedAWSURL = await axios.put(
          process.env.REACT_APP_BACKEND_URL + `/api/attachment/attach/sign`,
          {
            fileName: newFileName,
            presetKey: key === "og" ? `${documentKey}-original` : documentKey,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        );

        if (key === "stampedPDF") {
          signedAWSURLdetails = signedAWSURL;
        }

        const options = {
          headers: {
            "Content-Type": "application/pdf",
            "Content-Disposition": "inline",
          },
          onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            percentage = Math.floor((loaded * 100) / total);
            setPercentage(`${percentage}%`);
            // console.log(percentage)
          },
        };

        await axios.put(signedAWSURL.data.uploadURL, stampedPDF[key], options);
      })
    );

    return {
      QRcodeUUID: QRcodeUUID,
      attachmentKey: signedAWSURLdetails.data.key,
      attachmentFileName: signedAWSURLdetails.data.fileName,
    };
  } catch (err) {
    console.log(err);
    throw { message: err.stampingError ? err.stampingError : err.message };
  }
};

export default StampingFunction;
