import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from "react";
import { useDropzone } from "react-dropzone";
import { AppContext } from "../../contexts/appContext";
import AttachmentDisplayV4 from "../displayElements/attachmentDisplayV4";

import { MdAttachFile } from "react-icons/md";

const baseStyle = {};

const focusedStyle = {
  borderColor: "red",
};

const acceptStyle = {
  border: "3px solid #33ABEF",
  borderRadius: "5px",
};

const rejectStyle = {
  borderColor: "1px solid red",
};

const uploaderReducer = (state, action) => {
  switch (action.type) {
    case "ATTACHMENT_ADDED":
      return { ...state, [action.attachment.fileName]: action.attachment };

    case "ATTACHMENT_DELETED":
      const { [action.key]: removedKey, ...newState } = state;
      return newState;

    default:
      return state;
  }
};

const PDFAttachV4 = ({
  id,
  label,
  required,
  attachmentHandler,
  disabled,
  customCSS,
}) => {
  const appData = useContext(AppContext);
  const inputId = id ? id : "Attachment";

  const [uploaderState, dispatch] = useReducer(uploaderReducer, {});

  useEffect(() => {
    let attachmentObject = {};
    Object.keys(uploaderState).map((key) => {
      attachmentObject = {
        ...attachmentObject,
        [key]: { value: uploaderState[key].file, isValid: true },
      };
    });

    if (Object.keys(uploaderState).length === 0) {
      attachmentObject = { ["required"]: { value: null, isValid: !required } };
    }

    attachmentHandler(attachmentObject);
  }, [uploaderState, required]);

  const attachmentDetails =
    uploaderState &&
    Object.keys(uploaderState)
      .map((key) => {
        return {
          isDeleting: false,
          key: key,
          name: key,
          progress: 100,
        };
      })
      .flat();

  const deleteAttachment = useCallback((key) => {
    dispatch({
      type: "ATTACHMENT_DELETED",
      key: key,
    });
  });

  const addAttachmentToState = useCallback((attachment) => {
    let fileName = attachment.name;
    let attachmentObject;

    dispatch({
      type: "ATTACHMENT_ADDED",
      attachment: {
        ...attachmentObject,
        file: attachment,
        fileName: fileName,
      },
    });
  }, []);

  /// on drop activate the upload state function
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.map((file) => {
      addAttachmentToState(file);
    });
  }, []);

  const {
    getRootProps,
    getInputProps,
    open,
    acceptedFiles,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop,
    accept: { "application/pdf": [] },
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  return (
    <div>
      {label && (
        <div className="flex">
          <div
            className={`flex capitalize text-sm text-taskinatorDarkGrey`}
            htmlFor={label}
          >
            {label}
          </div>
          <div
            className={`${
              required ? "text-taskinatorRed" : "text-taskinatorMedGrey"
            } opacity-75 pl-1 text-sm`}
          >
            {required ? " (Required)" : " (Optional)"}
          </div>
        </div>
      )}
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <div
          onClick={open}
          className={`py-2  rounded-md  border-taskinatorBlue border ${customCSS}
          flex  justify-center items-center bg-taskinatorHoverBlue flex-wrap cursor-pointer `}
        >
          <div className="text-xs text-taskinatorBlue pr-2 flex">
            {" "}
            <MdAttachFile size={"0.9rem"} />
            <div className="pl-1">Click Here Or Drag & Drop to Attach</div>
          </div>
          {/* <div className="">
            <Button
              type="button"
              disabled={disabled}
              onClick={open}
              label={
                <div className="flex items-center justify-center">
                  {" "}
                  <MdAttachFile size={"0.9rem"} /> <div className="pl-1">Attach File</div>
                </div>
              }
              customClasses={`bg-taskinatorBlue text-taskinatorWhite text-xs h-8`}
            />
          </div> */}
        </div>
        {/* <div
          className={`py-2  rounded-md  border-taskinatorDarkGrey  border
          flex flex-col justify-center items-center bg-taskinatorLightGrey `}
        >
          <div className="text-xs text-taskinatorDarkGrey">Click Button Or Drag Here to attach</div>
          <div className="mt-2">
            <Button
              type="button"
              disabled={disabled}
              onClick={open}
              label={
                <div className="flex items-center justify-center">
                  {" "}
                  <MdAttachFile size={"0.9rem"} /> <div className="pl-1">Attach File</div>
                </div>
              }
              customClasses={`bg-taskinatorBlue text-taskinatorWhite text-xs h-8`}
            />
          </div>
        </div> */}
        {isDragReject && (
          <div className=" text-right text-xs text-taskinatorRed capitalize">
            file type not allowed
          </div>
        )}

        <div className="flex flex-row flex-wrap w-full">
          {attachmentDetails &&
            attachmentDetails.length > 0 &&
            attachmentDetails.map((data) => {
              return (
                <AttachmentDisplayV4
                  key={data.name}
                  attachmentData={data}
                  onDelete={deleteAttachment}
                  disabled={disabled}
                  noUploadProgress
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default PDFAttachV4;
