import React, { useContext } from "react";
import { MdCheckCircle, MdLock, MdOutlineSubdirectoryArrowRight, MdUpdate } from "react-icons/md";
import { AppContext } from "../../../contexts/appContext";
import ItemStatus from "../../UI_elements/itemStatus";

import DateDisplay from "../../UI_elements/dateDisplay";
import Tag from "../../UI_elements/tag";

import FilePreview from "../../../utils/filePreview";
const FileRowDisplay = ({
  file,
  index,
  itemState,
  sectionCSS,
  widths,
  setViewItemDetails,
  itemsSelected,
  onSelectHandler,
  setFileSelection,
  fileSelection,
  searchAndAdd,
  isRegister,
  tid,
  isPreview,
  notClickable,
  viewMarkupsOnly,
  showName,
  small
}) => {
  const thisItemHasBeenSelected = fileSelection?._id.toString() === file._id.toString();

  const appData = useContext(AppContext);
  const itemIsLocked = file?.inAction || (file?.inTask?.length > 0 && file?.inTask?.filter((task) => task?._id === tid)?.length === 0 && !isRegister);

  const isGuest = appData.workspaceDataV4.role === process.env.REACT_APP_ACCESS_G;

  return (
    // <>
    <div
      className={` ${
        thisItemHasBeenSelected
          ? "bg-taskinatorBlue text-taskinatorWhite"
          : file.inAction
          ? "bg-taskinatorWhite text-taskinatorDarkGrey opacity-50"
          : `${index !== 0 ? "bg-taskinatorOffWhite text-taskinatorDarkGrey hover:bg-taskinatorHoverBlue" : "bg-taskinatorWhite text-taskinatorDarkGrey hover:bg-taskinatorHoverBlue"}`
      } ${itemIsLocked ? "cursor-not-allowed opacity-50" : `${isGuest ? "" : "cursor-pointer"}`}  py-2  text-sm  items-center w-full flex justify-center `}
      onClick={
        searchAndAdd
          ? !itemIsLocked
            ? notClickable
              ? () => {}
              : () => {
                  // onSelectHandler(file, itemState);
                  setFileSelection({ file: file, fileSetId: null });
                }
            : () => {}
          : //   : () =>
          //       setConfirmModal({
          //         file: file,
          //         itemState: itemState,
          //         message: file.inAction ? "Can't add this item as its already in the selected workflow." : "Warning! Item is already in an task.",
          //         button: file.inAction ? "Confirm" : "Proceed",
          //         inAction: file.inAction,
          //       })
          isGuest
          ? () => {}
          : notClickable
          ? () => {}
          : () => setViewItemDetails({ itemData: file, itemState: itemState })
      }
    >
      <div className={`${sectionCSS} ${widths[0]}`}>
        {file.status === "draft" && index !== 0 && <MdOutlineSubdirectoryArrowRight />}

        {!itemIsLocked && file.newDraftPending && (
          <div className={`${thisItemHasBeenSelected ? "text-taskiantorWhite" : "text-taskinatorOrange"}`}>
            <MdUpdate size={"1rem"} />
          </div>
        )}

        {!itemIsLocked && !file.newDraftPending && file?.status !== "pending" && (
          <div className={`${thisItemHasBeenSelected ? "text-taskiantorWhite" : "text-taskinatorGreen"} px-2`}>
            <MdCheckCircle size={"1rem"} />
          </div>
        )}
        {itemIsLocked && (
          <div className="text-taskinatorRed">
            <MdLock />
          </div>
        )}
      </div>
      <div className={`${sectionCSS} ${widths[4]} opacity-80 `}>
        <ItemStatus status={file.status} whiteOverride={thisItemHasBeenSelected} />
      </div>
      <div className="flex w-full ">
        {/* <div className={`${sectionCSS} ${widths[2]} `}>{file.fileNumber}</div> */}
        <div className={`${sectionCSS} ${widths[1]} `}>
          <div class>
          {<FilePreview file={file} viewInMarkup viewMarkupsOnly={viewMarkupsOnly} isPreview={isPreview} notClickable={notClickable} showName={showName}  small={ small}/>}</div>
        </div>

        <div className={`${sectionCSS} ${widths[3]}`}>{file?.revision ?? <div>...</div>}</div>

        <div className={`${sectionCSS} ${widths[10]}`}>
          {file?.settings?.fileTags?.length === 0 && <div> &nbsp;</div>}
          {file?.settings?.fileTags?.map((tag, index) => {
            if (index < 2) {
              return <Tag key={tag._id} tagData={tag} />;
            } else if (index === 2) {
              return <div>...</div>;
            }
          })}
        </div>

        <div className={`${sectionCSS} ${widths[10]} items-center`}>
          {file?.fileSets?.length > 0 ? (
            file?.fileSets?.map((fileSet) => {
              return (
                <div className="bg-taskinatorItemBlue text-taskinatorDarkGrey font-medium rounded-full py-1 px-4  text-xs items-center uppercase" key={fileSet._id}>
                  {fileSet.name}
                </div>
              );
            })
          ) : (
            <div>&nbsp;</div>
          )}
        </div>

        {/* {isRegister && (
          <div className={`${sectionCSS} ${widths[6]}`}>
            <div>
              {file?.settings?.accessTags?.map((tag, index) => {
                return <Tag key={tag._id} tagData={tag} />;
              })}
            </div>
            <div>
              {file.sharedWith &&
                file.sharedWith.map((tag, index) => {
                  console.log(tag);
                  return <Tag key={tag._id} tagData={{ type: "external", ...tag }} />;
                })}
            </div>
          </div>
        )} */}
        {/* {isRegister && <div className={`${sectionCSS} ${widths[7]} capitalize`}>{file?.creatorName}</div>} */}
        {isRegister && <div className={`${sectionCSS} ${widths[8]} border-r-0`}>{DateDisplay(file?.createdDate)}</div>}
      </div>
    </div>
  );
};

export default FileRowDisplay;
