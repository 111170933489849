import React, { useState, useContext } from "react";
import axios from "axios";
import { TaskContext } from "../../contexts/taskContext";
import { AppContext } from "../../contexts/appContext";
import { useInit } from "../hooks/useInit";

import ItemGetAndSearchContainer from "../displayElements/items/itemGetAndSearchContainer";

const AssignAsset = ({ tracer, closeModal, taskDetails, getData }) => {
  const { initState, initStarted, initCompleted, initErrors, initReset } = useInit(2);
  const appData = useContext(AppContext);
  const taskContext = useContext(TaskContext)

 
  const addItemHandler = (itemData) => {
    initStarted(0);
    axios
      .put(
        process.env.REACT_APP_BACKEND_URL + `/api/tracer/assignItem/${tracer._id}/${tracer.tracerWorkflowGroupId}`,
        {
          itemData: Object.values(itemData)[0],
          taskDetails: taskDetails
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        getData()
        initCompleted(0);
        closeModal();
      })
      .catch((err) => {
        console.log(err);
        initErrors(err?.response?.data?.message ?? err?.message ?? '', 0);
      });
  };

  return (
    <div className="flex flex-col grow overflow-auto">
      <ItemGetAndSearchContainer
        wfid={tracer.wfid._id}
        searchAndAdd={true}
        addItemHandler={addItemHandler}
        limitSelection={1}
        isLoading={initState[0].started}
        error={initState[0].error}
        label={`Add ${appData.itemName}`}
      />
    </div>
  );
};

export default AssignAsset;
