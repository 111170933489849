import React, { useEffect, useState } from "react";
import axios from "axios";
import MultiSelectDropdown from "../../inputs/multiSelectDropdown";
import { useInit } from "../../hooks/useInit";
import DropDownSelector_V4 from "../../inputs/dropDownSelector_V4";

const SequenceSetter = ({ sid, id, defaultValue, pendingTextOverride, disabled, dontAutoSelect, inputHandler, notRequired, inputVer }) => {
  const { initState, initStarted, initCompleted, initErrors, initReset } = useInit();
  const [availableSequences, setAvailableSequences] = useState([]);

  const sendRequest = () => {
    initStarted(0);
    axios
      .get(process.env.REACT_APP_BACKEND_URL + `/api/workflow/workflows/valid`, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      .then((response) => {
        setAvailableSequences(response.data.workflows);
        initCompleted(0);
      })
      .catch((err) => {
        initErrors(err?.response?.data?.message ?? err?.message ?? "", 0);
      });
  };

  useEffect(() => {
    sendRequest();
  }, []);

  return (
    !initState[0].started &&
    defaultValue && (
      <div>
        <MultiSelectDropdown
          notRequired={notRequired}
          label={"Auto Start Sequence"}
          sid={sid}
          onSelection={inputHandler}
          defaultValue={defaultValue}
          options={availableSequences?.map((data) => {
            return { value: data._id.toString(), name: data.name, type: "workflows", data: { _id: data._id, name: data.name, value: data._id.toString() } };
          })}
          selectionDisplayText={"Select One Sequence"}
          id={id}
          inputVer={inputVer}
          dontAutoSelect
          toolTip={"Which sequence to activate for this file when staring a new job and selecting use pre-set"}
          disabled={disabled}
          pendingTextOverride={pendingTextOverride}
          limit={1}
        />
      </div>
    )
  );
};

export default SequenceSetter;
