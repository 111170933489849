import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../contexts/appContext";
import AddFileButton from "../files/addFileButton";
import FileSimpleDetailsElement from "../files/fileSimpleDetailsElement";
import JobFileViewer from "./jobFileViwer";
import ToolTip from "../../../utils/toolTip";

const JobFileSelector = ({ globalInputHandler, globalSid, defaultValue, workflowAdded, type, wfid, number, tid, noNotes, referenceFileSet, fileSetExclusions,  notRequired, toolTip }) => {
  const [fileSetSelected, setFileSetSelected] = useState(defaultValue ?? {});
  const appData = useContext(AppContext);
  const [notes, setNotes] = useState();
  const [localFileView, setLocalFileView] = useState();
  // const [itemSelectorState, dispatch] = useReducer(itemSelectReducer, {});
  const isItemSelected = Object.values(fileSetSelected).length > 0;


  useEffect(() => {
    globalInputHandler(
      referenceFileSet ?? "fileSets",
      Object.values(fileSetSelected).map((fileSet) => {
        return { ...fileSet };
      }),
      notRequired ? true : Object.values(fileSetSelected).length > 0 ? true : false,
      globalSid
    );
  }, [fileSetSelected]);

  // useEffect(() => {
  //   globalInputHandler("notes", notes ? notes : null, true, globalSid);
  // }, [notes]);

  const removeItemHandler = (itemId) => {
    const currentState = fileSetSelected;
    delete currentState[itemId];
    setFileSetSelected({ ...currentState });
  };

  // useEffect(() => {
  //   if (!workflowAdded) {
  //     setFileSetSelected({});
  //   }
  // }, [workflowAdded]);

  return (
    <>
      <div className="w-full justify-center flex flex-col h-fit items-center overflow-auto scrollbar">
        <div className="w-full">
          {!referenceFileSet && (
            <div className="py-2 px-4 text-taskinatorDarkGrey bg-taskinatorItemBlue border border-taskinatorMedGrey shadow mb-1 text-base font-semibold uppercase flex items-center rounded-lg">
              {/* <div className=" border rounded-full h-6 w-6 items-center justify-center flex font-bold border-2 mr-2 text-taskinatorDarkGrey border-taskinatorDarkGrey">3</div> */}
              <div className="flex items-center text-base">
                {/* <div className="border rounded-full justify-center flex h-6 w-6 items-center mr-2 border-2 text-sm border-taskinatorBlue text-taskinatorBlue">{number}</div> */}
                Starting Folder
              </div>{" "}
            </div>
          )}

          {(Object.values(fileSetSelected).length === 0 || referenceFileSet) && (
            <div className={`${referenceFileSet ? "justify-center flex-col items-start -ml-1" : "justify-center items-center"} w-full  flex`}>
              {referenceFileSet && (
                <div className="text-sm mt-2 ml-1 -mb-1 flex">
                  <div className="text-taskinatorDarkGrey">Reference Folder</div>
                  {toolTip && <ToolTip content={toolTip} />}
                  <div className="text-taskinatorMedGrey pl-1">(Optional)</div>
                </div>
              )}
              <AddFileButton
                searchAndAdd={true}
                multi={referenceFileSet}
                fileSetExclusions={fileSetExclusions}
                globalSetItems={(itemData) => setFileSetSelected(itemData)}
                type={type}
                wfid={wfid}
                tid={tid}
                selectableItemsOnTask={fileSetSelected}
              />{" "}
              {!referenceFileSet && Object.values(fileSetSelected).length === 0 && <div className="pl-1 text-taskinatorRed capitalize font-normal text-sm">(Required)</div>}
            </div>
          )}

          {isItemSelected && (
            <div className="w-full">
              {Object.values(fileSetSelected).map((fileSet, index) => {
                return (
                  <div className="pb-1 text-sm flex flex-col overflow-auto scrollbar" key={index}>
                    <FileSimpleDetailsElement fileSet={fileSet} removeItem={removeItemHandler} localView referenceFileSet/>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default JobFileSelector;
