const ColourGenerator = (firstName, lastName, name, border, text) => {
  const combinedInitials = name ? (name.charAt(0) + name.charAt(3)).toUpperCase() : (firstName.charAt(0) + lastName.charAt(0)).toUpperCase();
  const asciiSum = combinedInitials.split("").reduce((sum, char) => sum + char.charCodeAt(0), 0);

  // const colorPalette = [
  //   "bg-orange-300", "bg-orange-400", "bg-orange-500", "bg-orange-600", "bg-orange-700", "bg-orange-800",
  //   "bg-amber-300", "bg-amber-400", "bg-amber-500", "bg-amber-600", "bg-amber-700", "bg-amber-800",
  //   "bg-yellow-300", "bg-yellow-400", "bg-yellow-500", "bg-yellow-600", "bg-yellow-700", "bg-yellow-800",
  //   "bg-lime-300", "bg-lime-400", "bg-lime-500", "bg-lime-600", "bg-lime-700", "bg-lime-800",
  //   "bg-green-300", "bg-green-400", "bg-green-500", "bg-green-600", "bg-green-700", "bg-green-800",
  //   "bg-emerald-300", "bg-emerald-400", "bg-emerald-500", "bg-emerald-600", "bg-emerald-700", "bg-emerald-800",
  //   "bg-teal-300", "bg-teal-400", "bg-teal-500", "bg-teal-600", "bg-teal-700", "bg-teal-800",
  //   "bg-cyan-300", "bg-cyan-400", "bg-cyan-500", "bg-cyan-600", "bg-cyan-700", "bg-cyan-800",
  //   "bg-sky-300", "bg-sky-400", "bg-sky-500", "bg-sky-600", "bg-sky-700", "bg-sky-800",
  //   "bg-blue-300", "bg-blue-400", "bg-blue-500", "bg-blue-600", "bg-blue-700", "bg-blue-800",
  //   "bg-indigo-300", "bg-indigo-400", "bg-indigo-500", "bg-indigo-600", "bg-indigo-700", "bg-indigo-800",
  //   "bg-violet-300", "bg-violet-400", "bg-violet-500", "bg-violet-600", "bg-violet-700", "bg-violet-800",
  //   "bg-purple-300", "bg-purple-400", "bg-purple-500", "bg-purple-600", "bg-purple-700", "bg-purple-800",
  //   "bg-fuchsia-300", "bg-fuchsia-400", "bg-fuchsia-500", "bg-fuchsia-600", "bg-fuchsia-700", "bg-fuchsia-800",
  //   "bg-pink-300", "bg-pink-400", "bg-pink-500", "bg-pink-600", "bg-pink-700", "bg-pink-800",
  //   "bg-rose-300", "bg-rose-400", "bg-rose-500", "bg-rose-600", "bg-rose-700", "bg-rose-800",
  // ];
  const colorPalette = !border
    ? !text
      ? [
          "bg-orange-300",
          "bg-orange-400",
          "bg-orange-500",
          "bg-orange-600",
          "bg-amber-300",
          "bg-amber-400",
          "bg-amber-500",
          "bg-amber-600",
     
          "bg-lime-300",
          "bg-lime-400",
          "bg-lime-500",
          "bg-lime-600",
        
          "bg-emerald-300",
          "bg-emerald-400",
          "bg-emerald-500",
          "bg-emerald-600",
          "bg-teal-300",
          "bg-teal-400",
          "bg-teal-500",
          "bg-teal-600",
          "bg-cyan-300",
          "bg-cyan-400",
          "bg-cyan-500",
          "bg-cyan-600",
          "bg-sky-300",
          "bg-sky-400",
          "bg-sky-500",
          "bg-sky-600",
        
        ]
      : [
          "text-orange-300",
          "text-orange-400",
          "text-orange-500",
          "text-orange-600",
          "text-amber-300",
          "text-amber-400",
          "text-amber-500",
          "text-amber-600",

          "text-lime-300",
          "text-lime-400",
          "text-lime-500",
          "text-lime-600",
       
          "text-emerald-300",
          "text-emerald-400",
          "text-emerald-500",
          "text-emerald-600",
          "text-teal-300",
          "text-teal-400",
          "text-teal-500",
          "text-teal-600",
          "text-cyan-300",
          "text-cyan-400",
          "text-cyan-500",
          "text-cyan-600",
          "text-sky-300",
          "text-sky-400",
          "text-sky-500",
          "text-sky-600",
        
        ]
    : [
        "border-orange-300",
        "border-orange-400",
        "border-orange-500",
        "border-orange-600",
        "border-amber-300",
        "border-amber-400",
        "border-amber-500",
        "border-amber-600",

        "border-lime-300",
        "border-lime-400",
        "border-lime-500",
        "border-lime-600",
      
        "border-emerald-300",
        "border-emerald-400",
        "border-emerald-500",
        "border-emerald-600",
        "border-teal-300",
        "border-teal-400",
        "border-teal-500",
        "border-teal-600",
        "border-cyan-300",
        "border-cyan-400",
        "border-cyan-500",
        "border-cyan-600",
        "border-sky-300",
        "border-sky-400",
        "border-sky-500",
        "border-sky-600",
     
      ];

  const colorIndex = asciiSum % colorPalette.length;
  const selectedColorAndShade = colorPalette[colorIndex];

  return selectedColorAndShade;
};

export default ColourGenerator;
