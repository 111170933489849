import React, { useState, useEffect } from "react";
import ToolTip from "../../utils/toolTip";
import DateDisplay from "../../components/UI_elements/dateDisplay";
const PlanElement = ({ plan, selectedPlan, setSelectedPlan, final, minimumTeam, currentView }) => {
  const [extra, setExtra] = useState(plan.extraRequiredUsers ? plan.extraRequiredUsers : 0);

  const planSelected = selectedPlan?.key === plan?.key;
  const cantSelect = (!plan.available && plan.extraRequiredUsers === 0) || currentView;

  const totalExtraUsers = final ? plan.totalExtras : extra;
  const totalTeam = plan.teamLimit + extra;
  const totalPrice = final ? plan.selectedTotalPrice : plan.freePlan ? 0 : plan.extraUsers * totalExtraUsers + plan.price;

  useEffect(() => {
    if (setSelectedPlan && planSelected) {
      setSelectionHandler();
    }
  }, [totalExtraUsers, totalTeam, totalPrice]);

  const setSelectionHandler = () => {
    setSelectedPlan({ ...plan, selectedTotalPrice: totalPrice, totalTeam: totalTeam, totalExtras: extra });
  };

  return (
    <div
      onClick={
        cantSelect || final
          ? () => {}
          : () => {
              setSelectionHandler();
            }
      }
      className={`${
        setSelectedPlan ? `${cantSelect ? "text-taskinatorMedGrey cursor-not-allowed" : "hover:bg-taskinatorHoverBlue text-taskinatorDarkGrey cursor-pointer"}` : ""
      }  p-2 mb:mt-1 rounded text-sm ${final || currentView ? "grow" : "lg:w-64 w-full"} ${planSelected ? "border-4  border border-taskinatorBlue" : "border-taskinatorMedGrey border-2 shadow"}`}
    >
      <div className="font-medium text-lg"> {plan.name}</div>
      {!final && (
        <div className={` font-medium ${selectedPlan && cantSelect ? "text-taskinatorMedGrey" : "text-taskinatorBlue"} pb-2`}>
          ${plan.price} {plan.currency} <b> / 3 Months</b>
        </div>
      )}
      {!final && <div className="text-xs"> Includes:</div>}

      {!final && (
        <div className="flex ">
          <b className="pr-1">{plan.teamLimit}</b> Team Members <ToolTip content={"Number of users who can do and manage tasks"} />
        </div>
      )}
      {/* <div className="flex">
        <b className="pr-1">{plan.guestLimit} </b> Guest Members{" "}
        <ToolTip content={"Guests can only view drawing log items that have been shared with them via access tags"} />
      </div> */}
      {!final && (
        <div className="flex">
          {" "}
          <b className="pr-1">{plan.storageLimit / 1000}GB </b> Storage Limit <ToolTip content={"Total file storage on Next-Task AWS Sydney Server"} />
        </div>
      )}
      {!final && (
        <div className="flex">
          {!plan.freePlan && (
            <div className="flex">
              Or - <b className="px-1">Onedrive</b> Storage <ToolTip content={"Store your files on your One Drive Account"} />
            </div>
          )}{" "}
        </div>
      )}
      {!final && (
        <div className="flex">
          {!plan.freePlan && (
            <div className="flex">
              <b className="pr-1">Share</b> Files And Tasks <ToolTip content={"Share files and task with other Next-Task users"} />
            </div>
          )}{" "}
        </div>
      )}
      {/* {plan.freePlan && (
        <div className="border-t border-t-taskinatorMedGrey pt-1 mt-1">
          <div className={`${plan.available ? "text-taskinatorRed" : `${setSelectedPlan ? "" : "text-taskinatorRed"}`}`}>
            <b className="pr-1">{plan.freeLimit}</b>Active Workflow Limit{" "}
          </div>
        </div>
      )} */}
      {!currentView && plan.extraUsers && !final && (
        <div className="border-t border-t-taskinatorMedGrey pt-1 mt-1">
          {" "}
          <b> ${plan.extraUsers}</b> {plan.currency}/Extra Team / 3 months
        </div>
      )}
      {currentView && <div className="border-t border-t-taskinatorMedGrey pt-1 mt-1">Next Payment Date: {DateDisplay(new Date(plan?.subscriptionDetails?.EndOfBillingCycle) * 1000) ?? "NOW"}</div>}

      {(setSelectedPlan || final) && (
        <div className="pt-4">
          {planSelected && !final && !selectedPlan.freePlan && (
            <div className="flex items-center">
              Add More Users: <b className="pl-2 pr-2">{extra}</b>{" "}
              <div
                onClick={extra > 0 && totalTeam > minimumTeam ? () => setExtra(extra - 1) : () => {}}
                className={`${totalTeam > minimumTeam ? "" : "cursor-not-allowed"} flex bg-taskinatorMedGrey h-6 w-6 ml-1 text-taskinatorWhite rounded items-center justify-center  font-bold`}
              >
                -
              </div>
              <div onClick={() => setExtra(extra + 1)} className="bg-taskinatorMedGrey h-6 w-6 text-taskinatorWhite rounded items-center justify-center flex font-bold ml-1">
                +
              </div>
            </div>
          )}
          <div className="font-medium flex items-center ">
            Users:{" "}
            <div className="text-taskinatorRed pl-2 items">
              ({plan.teamLimit} included + {totalExtraUsers} Extra )
            </div>
          </div>
          <div className="flex items-end">
            <div className="text-taskinatorBlue font-medium text-lg"> ${totalPrice}</div>

            <div className="text-sm pl-1">
              {plan.currency}/ <b>3 Months</b>
            </div>
          </div>
          <div className="text-xxs">Inc Applicable Taxes</div>
        </div>
      )}
    </div>
  );
};

export default PlanElement;
