import axios from "axios";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { MdCheckCircle, MdOutlineWarning } from "react-icons/md";
import { AppContext } from "../../../contexts/appContext";
import Button from "../../UI_elements/button";
import Modal from "../../UI_elements/modal";
import { useInit } from "../../hooks/useInit";
import CheckboxV4 from "../../inputs/checkbox_V4";
import { JobModalContext } from "../jobs/jobModalContext";
import FileDetails from "./fileDetails";
import FileJobViewDetails from "./fileJobViewDetails";
import FileViewDetailsModal from "./fileViewDetailsModal";
import LowerCaseTrim from "../../../utils/lowerCaseAndTrim";
import StringShortenDisplay from "../../UI_elements/stringShortenDisplay";



const FileViewAndActions = ({ isRef, file, globalOnInput, index, selectAll, fileSetByTask, workflowData, setWorkflow, taskData, workflowGroupId, fileSetId, completed, selectedFiles }) => {
  const jobData = useContext(JobModalContext);
  const filter = jobData.filter;
  const appData = useContext(AppContext);

  const [viewFileDetails, setViewFileDetails] = useState();
  const [showDetails, setShowDetails] = useState();
  // const [localCheck, setLocalCheck] = useState();
  const { initState, initStarted, initCompleted, initErrors, initReset } = useInit(3);
  const currentActiveTask = fileSetByTask?.taskDetails;
  const isSubmitted = file?.currentJobs?.submissionDirection ? true : false;

  // const finalAction = (file?.lastActions || [])?.filter((data) => data).length > 0 ? determineOverallStatus(file.lastActions, workflowData) : null;
  const isNew = file?.currentJobs?.seen?.filter((seen) => seen._id === appData.workspaceDataV4.user_id).length === 0;
  // const isActive = file?.currentJobs?.active;

  const tagsOnTask = currentActiveTask?.fileTags?.map((tag) => tag._id.toString());
  const tagsOnFile = file?.settings?.fileTags?.map((tag) => tag._id.toString());
  const cantCheckInBecauseFileTagsDoNoMatch = !(tagsOnFile ?? []).some((element) => new Set(tagsOnTask ?? []).has(element));

  const [isDone, setIsDone] = useState();

  let isVisible =
    LowerCaseTrim(file?.fileName)?.includes(LowerCaseTrim(filter)) ||
    LowerCaseTrim(file?.revision ?? "N/A")?.includes(LowerCaseTrim(filter)) ||
    LowerCaseTrim(file?.fileExtension)?.includes(LowerCaseTrim(filter)) ||
    LowerCaseTrim(file?.status ?? "pending")?.includes(LowerCaseTrim(filter)) ||
    file?.settings?.fileTags?.map((tag) => LowerCaseTrim(tag.name))?.includes(LowerCaseTrim(filter)) ||
    (file?.settings?.accessTags?.map((tag) => LowerCaseTrim(tag.name))?.includes(LowerCaseTrim(filter)) &&
      (isRef ||
        (!isRef &&
          (Object.values(jobData.selectedTaskIds).length === 0 ||
            !Object.values(jobData.selectedTaskIds)[0].revisionTrackingId ||
            Object.values(jobData.selectedTaskIds)
              .map((files) => files.files)
              .flat(1).length === 0 ||
            (Object.values(jobData.selectedTaskIds)
              .map((files) => files.files.map((f) => f?.revisionTrackingId?.toString()))
              .flat(1)
              .includes(file?.revisionTrackingId?.toString()) &&
              Object.values(jobData.selectedTaskIds)
                .map((files) => files.files.map((f) => f?.submissionId?.toString()))
                .flat(1)
                .includes(file?.currentJobs?.submissionId.toString()))))));

  
  useEffect(() => {
    initReset(0);
    initReset(2);
  }, [file]);

  useEffect(() => {
    setIsDone(file?.currentJobs?.status);
  }, [file?.currentJobs?.status]);

  const onSelectHandler = useCallback(
    (isChecked) => {
      if (globalOnInput) {
        // setLocalCheck(isChecked);
        globalOnInput(file.revisionTrackingId, isChecked);
      }
    },
    [file]
  );


  useEffect(() => {
    if (!isVisible && globalOnInput && selectedFiles?.[file.revisionTrackingId]) {
      globalOnInput(file.revisionTrackingId, false);
    }

    if (isVisible && globalOnInput && !selectedFiles?.[file.revisionTrackingId] && selectAll) {
      globalOnInput(file.revisionTrackingId, true);
    }
  }, [isVisible]);

 

  let isAlreadyInWorkflow = isRef && !!file.currentJobs.find(cj => cj.workflowGroupId === fileSetByTask.workflowGroupId)


  let selectionDefault = false;

  useEffect(() => {
    selectionDefault = !!selectedFiles?.[file._id];
  }, [selectedFiles]);



  const removeFileHandler = () => {
    initStarted(2);

    axios
      .get(process.env.REACT_APP_BACKEND_URL + `/api/file/remove/${file._id}/${jobData?.jobId}/${workflowData?.workflowGroupId ?? workflowGroupId}/${fileSetByTask?._id}`, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      .then((response) => {
        initCompleted(2);
      })
      .catch((err) => {
        return initErrors(err?.response?.data?.message ?? err?.message ?? "", 2);
      });
  };

  if (isVisible) {
    return (
      <div className={`${showDetails ? "" : ""}`}>
    
        <div className={`${showDetails ? "ring ring-2 ring-taskinatorItemBlue rounded-lg" : ""}`}>
          <div
            className={`
              ${
                file?.fileName === process.env.REACT_APP_PLACEHOLDER_CODE
                  ? "bg-taskinatorLightGrey cursor-normal"
                  : showDetails
                  ? "bg-taskinatorHoverBlue rounded-t-lg hover:bg-taskinatorHoverBlue"
                  : "bg-taskinatorLightGrey rounded-lg hover:bg-taskinatorHoverBlue"
              } border border-taskinatorMedGrey flex p-2 items-center mt-0.5 text-taskinatorDarkGrey   `}
          >
            <div
              onClick={(event) => {
                event.stopPropagation();
              }}
              className=" border-r border-r-taskinatorMedGrey mr-2"
            >
              <CheckboxV4
                disabled={
                  isSubmitted ||
                  file?.fileName === process.env.REACT_APP_PLACEHOLDER_CODE || isAlreadyInWorkflow || 
                  (!fileSetByTask?.isAssigned && !isRef) ||
                  !globalOnInput ||
                  (!isRef && setWorkflow) ||
                  fileSetByTask?.taskCompleted ||
                  (cantCheckInBecauseFileTagsDoNoMatch && isRef) ||
                  !workflowData
                }
                id={"check"}
                sid="fileOnSet"
                defaultValue={selectAll ? selectAll : showDetails ? true : selectionDefault}
                onInput={(id, isChecked, isValid, sid) => onSelectHandler(isChecked)}
                customCheckbox={"h-5 w-5"}
              />
            </div>
            <div
              onClick={
                file?.fileName === process.env.REACT_APP_PLACEHOLDER_CODE
                  ? () => {}
                  : () => {
                      setShowDetails(!showDetails);
                    }
              }
              className="grow justify-start flex items-center cursor-pointer"
            >
              {file?.fileName !== process.env.REACT_APP_PLACEHOLDER_CODE ? <FileDetails file={file} wide noDate isRef={isRef} /> : <div className="">Task Active On This Step. Close Tasks or add files.</div>}

              {isRef && file.currentJobs.length > 0 && (
                <div className="flex pl-2 items-center grow justify-end text-xxs w-fit flex-nowrap">
                  Active In:{" "}
                  <div className="pl-1">
                    {file.currentJobs.map((job) => {
                      return (
                        <div className="flex items-center text-xxs opacity-75">
                          <div className="border p-1 mr-1 rounded border-taskinatorDarkGrey text-taskinatorDarkGrey  uppercase text-nowrap">
                            {job.jobId === jobData.jobId ? "This Job" : `${StringShortenDisplay(10, job.jobRef)}`}{" "}
                          </div>
                          <div className="border p-1 mr-1 rounded border-taskinatorGreen text-taskinatorGreen  uppercase text-nowrap">{StringShortenDisplay(10, job.workflowName)} </div>
                          <div className="border p-1 mr-1 rounded border-taskinatorDarkGrey text-taskinatorWhite bg-taskinatorDarkGrey text-nowrap uppercase">{StringShortenDisplay(10, job.taskName)}</div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}

            </div>
            {globalOnInput && !isRef && !fileSetByTask?.canStart && !jobData.jobCompleted && (
              <div>
                {isSubmitted ? (
                  <div className=" w-fit items-center sm:flex hidden text-taskinatorGreen font-medium flex">
                    <MdCheckCircle />{" "}
                    <div className="pl-1 flex">
                      Submitted <div className="uppercase pl-1">({file?.currentJobs?.submissionDirection})</div>
                    </div>
                  </div>
                ) : file.status !== "pending" ? (
                  <div></div>
                ) : (
                  <div className="text-taskinatorRed text-xs flex items-center text-nowrap">
                    <MdOutlineWarning size={"1rem"} />
                    <p className="pl-1 text-nowrap">Set File Status</p>
                    <Button
                      onClick={() => {
                        removeFileHandler();
                      }}
                      label={"Delete"}
                      customClasses={"text-taskinatorRed opacity-50 hover:opacity-100 hover:border-taskinatorRed hover:border"}
                      isLoading={initState[2].started}
                    />
                  </div>
                )}
              </div>
            )}
            {/* {isRef && (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <Button label="Check File Out" customClasses={"border border-taskinatorGreen text-taskinatorGreen px-2 hover:bg-taskinatorGreen hover:text-taskinatorWhite"} />
              </div>
            )} */}
          </div>
          {showDetails && (
            <div className=" bg-taskinatorOffWhite p-2 border border-taskinatorMedGrey border-t-0 rounded-b-lg shadow">
              <FileJobViewDetails
                file={file}
                inOtherWorkflow={file?.inOtherWorkflow}
                taskData={fileSetByTask?.taskDetails || taskData}
                workflowGroupId={workflowData?.workflowGroupId}
                taskCompleted={fileSetByTask?.taskCompleted ?? completed}
                fileSetId={fileSetByTask?.fileSetId || fileSetId}
                workflowId={workflowData?._id}
                workflowName={workflowData?.name}
                taskTags={currentActiveTask?.fileTags ?? taskData?.fileTags}
                onResponse={() => {}}
                fileSubmitted={isSubmitted}
                isRef={isRef}
              />
            </div>
          )}
        </div>

        {/* {viewFileDetails && (
          <Modal
            size={"max"}
            isWithoutClose
            local={() => {
              closeModelHandler();
            }}
          >

            this is commented
            <FileViewDetailsModal
              updatePositionStatusHandler={updatePositionStatusHandler}
              file={file}
              isNew={isNew}
              openLocal={() => {
                closeModelHandler();
              }}
            />
          </Modal>
        )} */}
      </div>
    );
  }
};

export default FileViewAndActions;
