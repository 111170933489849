import { createContext } from "react";

export const FileContext = createContext({
  fileSelection: null,
  setFileSelection: null,
  getFiles: () => {},
  filesDeleted: null,
  setFilesDeleted: null,
  itemList: null,
  setItemList: null

});
