import axios from "axios";
import React, { useCallback, useContext, useEffect, useMemo, useReducer, useState } from "react";
import { useDropzone } from "react-dropzone";
import { MdAttachFile } from "react-icons/md";
import { pdfjs } from "react-pdf";
import { v4 as uuidv4 } from "uuid";
import { previewFileExtensions } from "../../constants";
import { AppContext } from "../../contexts/appContext";
import Modal from "../UI_elements/modal";
import AttachmentDisplayV4 from "../displayElements/attachmentDisplayV4";
import ApproveRejectForm from "../forms/approveRejectForm";
import { useInit } from "../hooks/useInit";
import { FileContext } from "../displayElements/files/FileContext";

const BATCH_SIZE = 10; // Number of files per batch

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const baseStyle = {};

const focusedStyle = {
  borderColor: "red",
};

const acceptStyle = {
  border: "3px solid #33ABEF",
  borderRadius: "5px",
};

const rejectStyle = {
  borderColor: "1px solid red",
};

const uploaderReducer = (state, action) => {
  let isValid = true;

  if (action.attachment) {
    for (const key in state) {
      if (key === "isValid") {
      } else if (key === action.key) {
        isValid = isValid && action.attachment.isValid;
      } else {
        isValid = isValid && state[key].isValid;
      }
    }
  }

  switch (action.type) {
    case "RESET":
      console.log("resetting state");
      return { isValid: true };

    case "ATTACHMENT_ADDED":
      return { ...state, [action.key]: { ...action.attachment, uploadError: false }, isValid: isValid };

    case "ATTACHMENT_DELETING":
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          isDeleting: true,
          isValid: false,
        },
      };

    case "CREATING_PREVIEW":
      return { ...state, [action.key]: { ...state[action.key], preview: true } };

    case "ERROR_UPLOADING":
      return { ...state, [action.key]: { ...state[action.key], uploadError: true, isValid: false, isDeleting: false } };

    case "UPDATED_UPLOAD_PROGRESS":
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          progress: action.uploadProgress,
          isValid: action.uploadProgress !== 100 ? false : true,
          uploading: action.uploading,
          returnedFile: action.returnedFile,
          returnedFileId: action?.returnedFileId,
        },
      };

    case "ATTACHMENT_DELETED":
      const { [action.key]: removedKey, ...newState } = state;
      return newState;

    default:
      return state;
  }
};

const FileUploaderV4 = ({
  id,
  label,
  required,
  inputHandler,
  attachmentHandler,
  defaultValue,
  disabled,
  jobId,
  setUploadSuccessful,
  uploadError,
  uploadTrigger,
  type,
  fileSetId,
  fileSetName,
  jobData,
  cancelTokens,
  setCancelTokens,
  limit,
  revision,
  markup,
  autoTrigger,
  fileNameOverride,
  onFilesUploaded,
  reset,
}) => {
  const appData = useContext(AppContext);
  const fileContext = useContext(FileContext);
  const { initState, initStarted, initCompleted, initErrors, initReset } = useInit(0);

  const [uploaderState, dispatch] = useReducer(uploaderReducer, {});
  const [defaultValues, setDefaultValues] = useState([]);
  const [returnedFiles, setReturnedFiles] = useState();
  const [approveModal, setApproveModal] = useState();
  const [storageOption, setStorageOption] = useState();
  const [remainingFiles, setRemainingFiles] = useState();
  /// this pushes the local state to the master state above on change to the local state
  const { ["isValid"]: removedKey, ["deleteRefs"]: removedDeletedRefs, ...attachmentsOnly } = uploaderState;
  const [isUploading, setIsUploading] = useState();
  const inputId = id ? id : "Attachment";

  const attachmentDetails =
    uploaderState &&
    Object.keys(uploaderState)
      .filter((key) => key !== "isValid" && key !== "deleteRefs")
      .map((key) => {
        return {
          key: key,
          name: uploaderState[key].fileName,
          inputId: uploaderState[key].inputId,
          progress: uploaderState[key].progress,
          isDeleting: uploaderState[key].isDeleting,
          uploadError: uploaderState[key].uploadError,
          uploading: uploaderState[key].uploading,
          preview: uploaderState[key].preview,
          fileExtension: uploaderState[key].fileExtension.toLowerCase(),
          returnedFile: uploaderState[key].returnedFile,
        };
      })
      .filter((data) => !data?.key?.endsWith("__preview"))
      .flat();

  ///upload file from que
  useEffect(() => {
    const uploadTrigger = async (file) => {
      await uploadHandler(file);
    };

    const filesToUse = Object.values(attachmentsOnly).filter((file) => !file.uploading && file.uploadError === false && file.progress !== 100);
    setRemainingFiles(Object.values(attachmentsOnly).length - filesToUse.length);

    if (filesToUse.length > 0 && !isUploading) {
      uploadTrigger(filesToUse[0]);
    } else {
      onFilesUploaded?.();
    }
  }, [attachmentsOnly, isUploading]);

  const uploadHandler = async (attachment) => {
    /// this sorts out the files correctly

    setIsUploading(true);

    let previewFile;

    const fileNameRegex = /(.+?)(\.[^.]*$|$)/;
    const fileNameOnly = attachment.fileName.match(fileNameRegex)[1];

    /// this is where we create the file preview
    if (attachment.fileExtension.toLowerCase() === ".pdf") {
      dispatch({
        type: "CREATING_PREVIEW",
        key: attachment.key,
      });

      previewFile = await new Promise((resolve, reject) => {
        const fileReader = new FileReader();

        fileReader.onload = async (event) => {
          try {
            const typedArray = new Uint8Array(event.target.result);
            const pdfDoc = await pdfjs.getDocument({ data: typedArray }).promise;
            const page = await pdfDoc.getPage(1);
            const unscaledViewport = page.getViewport({ scale: 1 });
            const scaleFactor = 200 / unscaledViewport.height; // Desired height is 400px

            const viewport = page.getViewport({ scale: scaleFactor });
            const canvas = document.createElement("canvas");
            const context = canvas.getContext("2d");
            canvas.height = viewport.height;
            canvas.width = viewport.width;

            await page.render({ canvasContext: context, viewport: viewport }).promise;
            const dataUrl = canvas.toDataURL("image/png");

            // Trigger download
            const response = await fetch(dataUrl);
            const blob = await response.blob();
            // Create a File object
            const imageFile = new File([blob], `${fileNameOnly}.png`, { type: "image/png" });

            resolve(imageFile);
          } catch (error) {
            console.error("Error converting PDF to image:", error);
            reject(error);
          }
        };

        fileReader.onerror = () => {
          reject(new Error("Error reading PDF file"));
        };

        fileReader.readAsArrayBuffer(attachment.file);
      });
    } else if (previewFileExtensions.includes(attachment.fileExtension.toLowerCase())) {
      dispatch({
        type: "CREATING_PREVIEW",
        key: attachment.key,
      });

      previewFile = await new Promise((resolve, reject) => {
        const fileReader = new FileReader();

        fileReader.onload = (event) => {
          const img = new Image();
          img.src = event.target.result;
          img.onload = () => {
            const maxWidth = 200; // Desired maximum width
            const maxHeight = 200; // Desired maximum height
            const resizedCanvas = resizeImage(img, maxWidth, maxHeight);

            resizedCanvas.toBlob((blob) => {
              const imageFile = new File([blob], `${fileNameOnly}.png`, { type: "image/png" });
              resolve(imageFile);
            }, "image/png");
          };
        };

        fileReader.onerror = () => {
          reject(new Error("Error reading image file"));
        };

        fileReader.readAsDataURL(attachment.file);
      });
    }

    let previewUpload = [attachment];

    // { ...attachment, fileName: `${fileNameOnly}--${attachment.key}${attachment.fileExtension}` }
    if (previewFile) {
      previewUpload = [
        ...previewUpload,
        {
          key: `${attachment.key}__preview`,
          file: previewFile,
          fileName: `${fileNameOnly}__preview.png`,
          fileExtension: ".png",
        },
      ];
    }

    await Promise.all(
      previewUpload.map(async (file) => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        // Store the cancel token using the attachment key
        setCancelTokens((prevTokens) => ({
          ...prevTokens,
          [file.key]: source,
        }));

        if (!file.key.endsWith("__preview")) {
          dispatch({
            type: "UPDATED_UPLOAD_PROGRESS",
            key: file.key,
            uploadProgress: 1,
            uploading: true,
          });
        }

        try {
          const responseData = await axios.put(
            `${process.env.REACT_APP_BACKEND_URL}/api/attachment/attach/upload`,
            { fileName: file.fileName, fileKey: file.key, type: type || "document", fileSetId: fileSetId, fileSetName: fileSetName },
            {
              headers: {
                "Content-Type": "application/json",
              },
              withCredentials: true,
              cancelToken: source.token,
            }
          );

          if (!responseData) {
            throw new Error("Error From Server");
          }

          const response = responseData.data;

          setStorageOption(response.driveOption);

          let percentage;

          const options = {
            onUploadProgress: (progressEvent) => {
              const { loaded, total } = progressEvent;
              percentage = Math.floor((loaded * 100) / total);

              if (percentage < 100 && !file.key.endsWith("__preview")) {
                dispatch({
                  type: "UPDATED_UPLOAD_PROGRESS",
                  key: file.key,
                  uploadProgress: percentage,
                  uploading: true,
                });
              }
            },
            cancelToken: source.token,
          };

          let uploadResponse;

          switch (response.driveOption) {
            case "GOOGLE":
              break;

            case "MICROSOFT":
              if (!response.accessToken) {
                throw new Error("No Access Token");
              }

              uploadResponse = await axios.put(
                `https://graph.microsoft.com/v1.0/me/drive/root:/${encodeURIComponent(process.env.REACT_APP_EXTERNAL_STORAGE_FOLDER_NAME)}/${fileNameOnly}--${attachment.key}${
                  file.key.endsWith("__preview") ? "__preview.png" : `${attachment.fileExtension.toLowerCase()}`
                }:/content`,
                file.file,
                {
                  headers: {
                    Authorization: `Bearer ${response.accessToken}`,
                  },
                  ...options,
                }
              );

              if (!uploadResponse?.data?.id) {
                throw new Error();
              }

              break;

            default:
              // Assume uploading to AWS if not specified
              await axios.put(response.signedUrl, file.file, {
                headers: {
                  "Content-Type": attachment.file.type,
                  "Content-Disposition": "inline",
                },
                ...options,
              });
          }

          const notPreview = !file.key.endsWith("__preview");
          // confirm upload on server
          const fileResponse = await axios.put(
            `${process.env.REACT_APP_BACKEND_URL}/api/attachment/attach/success`,
            {
              key: file.key,
              jobData: jobData,
              fileSetId: fileSetId,
              fileSetName: fileSetName,
              revision: notPreview && revision ? revision : null,
              markup: notPreview && markup ? markup : null,
              thirdPartyStorageId: uploadResponse?.data?.id,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
              withCredentials: true,
            }
          );

          if (!file.key.endsWith("__preview")) {
            dispatch({
              type: "UPDATED_UPLOAD_PROGRESS",
              key: attachment.key,
              uploadProgress: 100,
              uploading: true,
              returnedFile: fileResponse.data.file,
              returnedFileId: fileResponse.data.file._id,
            });

            fileContext?.setItemList?.((currentData) => {
              return { ...currentData, [fileSetId]: { ...currentData[fileSetId], [fileResponse.data.file.revisionTrackingId.toString()]: fileResponse.data.file } };
            });

            fileContext?.setFileSelection?.();
          }

          const currentCancelTokens = cancelTokens;
          delete currentCancelTokens[attachment.key];

          setCancelTokens(currentCancelTokens);

          return true;
        } catch (err) {
          console.log(err);
          if (!axios.isCancel(err)) {
            if (!file.key.endsWith("__preview")) {
              dispatch({
                type: "ERROR_UPLOADING",
                key: attachment.key,
              });
            }

            await axios.put(
              `${process.env.REACT_APP_BACKEND_URL}/api/attachment/attach/error`,
              { key: file.key },
              {
                headers: {
                  "Content-Type": "application/json",
                },
                withCredentials: true,
              }
            );

            return err;
          } else if (axios.isCancel(err)) {
            return "cancelled";
          }
        }
      })
    );

    setIsUploading();
  };

  useEffect(() => {
    const filesToCheck = Object.keys(uploaderState)
      .filter((key) => key !== "isValid" && key !== "deleteRefs")
      .map((key) => uploaderState[key]);

    if (autoTrigger && filesToCheck.filter((file) => (file?.returnedFile ? true : false)).length === limit) {
      autoTrigger(
        Object.keys(uploaderState)
          .filter((key) => key !== "isValid" && key !== "deleteRefs")
          .map((key) => uploaderState[key].returnedFile)
      );
    }
  }, [uploaderState]);

  const tempDeleteAttachment = useCallback(
    (key) => {
      dispatch({
        type: "ATTACHMENT_DELETED",
        key,
      });
      inputHandler(key, key, true, "deleteRef");
    },
    [inputHandler]
  );

  // delete attachment

  const deleteAttachment = async (key) => {
    if (uploaderState[key].uploading && uploaderState[key].progress !== 100) {
      dispatch({
        type: "ATTACHMENT_DELETED",
        key: key,
      });

      if (cancelTokens[key]) {
        cancelTokens[key]?.cancel(`Upload canceled for key: ${key}`);
        cancelTokens[`${key}__preview`]?.cancel(`Upload canceled for key: ${key}__preview`);
      }
    } else {
      dispatch({
        type: "ATTACHMENT_DELETING",
        key: key,
      });

      let success;

      try {
        await axios.get(process.env.REACT_APP_BACKEND_URL + `/api/file/remove/${uploaderState[key].returnedFileId}/${jobData?._id}/${jobData?.workflowData.workflowGroupId}/${jobData?.taskId}`, {
          // await axios.delete(process.env.REACT_APP_BACKEND_URL + `/api/attachment/delete/${key}/${jobData?._id}/${fileSetId}`, {
          headers: {
            "Content-Type": "application/json",
          },

          withCredentials: true,
        });
        success = true;
      } catch (err) {
        console.log(err);
      }

      if (success) {
        dispatch({
          type: "ATTACHMENT_DELETED",
          key: key,
        });
      } else {
        dispatch({
          type: "ERROR_UPLOADING",
          key: key,
        });
      }
    }
  };

  const acceptFilesHandler = (attachment, key) => {
    if (!Object.values(uploaderState).find((file) => file.fileName === attachment.fileName)) {
      dispatch({
        type: "ATTACHMENT_ADDED",
        key: key,
        attachment: attachment,
      });
    }
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (!disabled) {
        let files = [];

        acceptedFiles.forEach(async (file, index) => {
          const key = uuidv4();

          const fileName = file.name;

          const regex = /\.([^.]+)$/;
          const extension = fileName.match(regex)[0];

          const attachment = {
            file: file,
            fileName,
            inputId,
            isValid: false,
            progress: 0,
            fileExtension: extension.toLowerCase(),
            key: key,
            uploading: false,
          };

          files = [...files, attachment];

          if (fileNameOverride && fileName !== fileNameOverride && !markup) {
            setApproveModal({ attachment: attachment, fileName: fileName, key: key });
            return;
          } else {
            acceptFilesHandler(attachment, key);
          }
        });
      }

      // uploadHandler(files);
    },

    []
  );

  const handlePaste = useCallback((e) => {
    const clipboardItems = e.clipboardData.items;
    for (const item of clipboardItems) {
      if (item.kind === "file") {
        const file = item.getAsFile();

        const key = uuidv4();

        const fileName = file.name;
        const regex = /\.([^.]+)$/;
        const extension = fileName.match(regex)[0];

        const attachment = {
          file: file,
          fileName,
          inputId,
          isValid: false,
          progress: 0,
          fileExtension: extension,
          key: key,
          uploading: false,
        };

        if (!Object.values(uploaderState).find((file) => file.fileName === fileName)) {
          dispatch({
            type: "ATTACHMENT_ADDED",
            key: key,
            attachment: attachment,
          });
        }

        // uploadHandler([attachment]);
      }
    }
  }, []);

  const { getRootProps, getInputProps, open, isFocused, isDragAccept, isDragReject } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    onDrop,
    disabled: disabled,
    accept: {},
    ...(limit ? { maxFiles: limit } : {}),
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );


  
  useEffect(() => {
    dispatch({
      type: "RESET",
    });
  }, [reset]);

  
  // / get the details on the attachments if there are default values.
  useEffect(() => {
    if (defaultValue) {
      const defaultUploads = defaultValue.map((attachment) => {
        dispatch({
          type: "ATTACHMENT_ADDED",
          key: attachment.key,
          attachment: {
            fileName: `${attachment.fileName}${attachment.fileExtension.toLowerCase() ?? ".jpg"}`,
            inputId: inputId,
            isValid: true,
            progress: 100,
            fileExtension: attachment.fileExtension.toLowerCase(),
            returnedFileId: attachment._id.toString(),
            returnedFile: attachment,
          },
        });
      });
      // setDefaultValues(defaultUploads);
    }
  }, [id, defaultValue, reset]);



  //This sends the details to
  useEffect(() => {
    let attachmentObject = {};
    Object.keys(attachmentsOnly)
      .filter((key) => !key?.endsWith("__preview"))
      .map((key) => {
        const regex = /\.([^.]+)$/;
        const extension = attachmentsOnly[key]?.fileName?.match(regex)[0];

        const fileNameRegex = /(.+?)(\.[^.]*$|$)/;
        const fileNameOnly = attachmentsOnly[key]?.fileName?.match(fileNameRegex)[1];

        attachmentObject = {
          ...attachmentObject,
          [key.split(".")[0]]: {
            value: { ...attachmentsOnly[key], key: key, fileName: fileNameOnly, fileExtension: extension.toLowerCase(), _id: attachmentsOnly[key]?.returnedFileId },
            isValid: attachmentsOnly[key].isValid,
          },
        };
        return true;
      });

    if (Object.keys(attachmentsOnly).length === 0 && required) {
      attachmentObject = { ["required"]: { value: null, isValid: false } };
    }

    attachmentHandler(attachmentObject);
  }, [uploaderState, required, id]);

  return (
    <>
      <div onPaste={handlePaste}>
        {label && (
          <div className="flex">
            <div className={`flex capitalize text-sm text-taskinatorDarkGrey`} htmlFor={label}>
              {label}
            </div>
            <div className={`${required ? "text-taskinatorRed" : "text-taskinatorMedGrey"} opacity-75 pl-1 text-sm`}>{required ? " (Required)" : " (Optional)"}</div>
          </div>
        )}
        {limit && <div>Only One ({limit}) File Allowed</div>}
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <div
            onClick={disabled ? () => {} : open}
            className={`py-1 rounded-md border-taskinatorMedGrey border flex justify-center items-center  flex-wrap ${disabled ? "cursor-not-allowed" : "cursor-pointer"}`}
          >
            <div className="text-xs text-taskinatorDarkGrey pr-2 flex">
              <MdAttachFile size={"0.9rem"} />
              <div className="pl-1">Click Here Or Drag & Drop to Attach</div>
            </div>
          </div>
          {isDragReject && (
            <div className="flex text-right text-xs text-taskinatorRed capitalize">
              <div className=" ">file type not allowed</div> {limit && <div className="pl-2">Limit of One File</div>}{" "}
            </div>
          )}
          {remainingFiles > 0 && (
            <div className="text-xs">
              Upload Progress: {remainingFiles} of {attachmentDetails.length} ({attachmentDetails.length - remainingFiles} Remaining)
            </div>
          )}
          <div className="flex flex-row flex-wrap">
            {attachmentDetails &&
              filterAndLimitItems([...attachmentDetails, ...defaultValues]).map((data, index) => {
                if (data.inputId === inputId) {
                  return (
                    <div key={data.key}>
                      <AttachmentDisplayV4
                        attachmentData={data}
                        onDelete={(key) => {
                          deleteAttachment(key);
                        }}
                        disabled={disabled}
                        key={index}
                        index={index}
                      />
                    </div>
                  );
                }
                return true;
              })}
          </div>
        </div>
      </div>

      {approveModal && (
        <Modal local={() => setApproveModal()}>
          <ApproveRejectForm
            message={`File Names Do Not Match are you sure you want to overwrite ${fileNameOverride} with ${approveModal.fileName}`}
            // requiredTextMatch={workflowData.name}
            confirmFunction={() => {
              acceptFilesHandler(approveModal.attachment, approveModal.key);
              setApproveModal();
            }}
            rejectFunction={() => setApproveModal()}
            // confirmLoadingState={file.archived === true ? initState[2].started : initState[0].started}
            // error={file.archived === true ? initState[2].error : initState[0].error}
            confirmButtonLabel="CONFIRM"
            confirmButtonCSS={"bg-taskinatorRed "}
          />
        </Modal>
      )}
    </>
  );
};

export default FileUploaderV4;

function resizeImage(img, maxWidth, maxHeight) {
  let width = img.width;
  let height = img.height;

  // Calculate new dimensions while preserving aspect ratio
  if (width > height) {
    if (width > maxWidth) {
      height *= maxWidth / width;
      width = maxWidth;
    }
  } else {
    if (height > maxHeight) {
      width *= maxHeight / height;
      height = maxHeight;
    }
  }

  // Create a new canvas and draw the resized image
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  canvas.width = width;
  canvas.height = height;
  ctx.drawImage(img, 0, 0, width, height);

  return canvas;
}

function filterAndLimitItems(items) {
  // Always start by trying to include all items.
  let displayedItems = items.slice(0, 20);

  if (items.length > 20) {
    // If more than 20 items, prioritize items with progress !== 100
    const itemsWithoutComplete = items.filter((item) => item.progress !== 100);
    displayedItems = itemsWithoutComplete.slice(0, 20);

    // If there's still space after filtering out complete items, add complete items
    if (displayedItems.length < 20) {
      const completeItems = items.filter((item) => item.progress === 100);
      displayedItems = displayedItems.concat(completeItems.slice(0, 20 - displayedItems.length));
    }
  }

  return displayedItems;
}
