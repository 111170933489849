import React, { useContext, useEffect, useState } from "react";
import { MdClose } from "react-icons/md";
import axios from "axios";
import IconSpinner from "../../UI_elements/iconSpinner";
import  { useInit } from "../../hooks/useInit";

const OldAttachmentDisplayV4 = ({ attachmentData, onDelete, disabled, alreadySaved, noUploadProgress }) => {
  const { initState, initStarted, initCompleted, initErrors, initReset } = useInit(2);
  const isUploaded = attachmentData.progress === 100;

  const regex = /\.([^.]+)$/;
  const extension = attachmentData.name.match(regex);

  const imageExtensions = ["jpg", "jpeg", "png", "gif"]; // Add more extensions if needed
  const isImage = imageExtensions.includes(extension[1]);

  const [imageURL, setImageURL] = useState();

  useEffect(() => {
    if (isImage && isUploaded) {
      initStarted(1);

      axios
        .get(process.env.REACT_APP_BACKEND_URL + `/api/attachment/download/${attachmentData.key}/${attachmentData.name}/${null}`, {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        })
        .then((responseData) => {
          setImageURL(responseData.data.signedURL);
          initCompleted(1);
        })
        .catch((err) => {
          console.log(err);
          return initErrors(err?.response?.data?.message ?? err?.message ?? "", 1);
        });
    }
  }, [isImage, isUploaded]);

  return (
    <div className="text-xs border rounded mr-2 my-1 p-1 border-taskinatorBlue ">
      <div className="flex justify-center items-start ">
      {imageURL && (
          <img
            src={imageURL} // Assuming 'url' contains the image source
            alt={attachmentData.name}
            className="max-w-xs max-h-20" // Set a max width and height
          />
        )}
        {!imageURL && <div>{attachmentData.name}</div>}
        {isUploaded && !attachmentData.isDeleting && (
          <div className={`text-taskinatorRed ml-3 ${!disabled ? "cursor-pointer" : "cursor-not-allowed"} hover:opacity-75 h-4 w-4 text-base`}>
            <MdClose
              onClick={() => {
                if (isUploaded && !disabled) {
                  onDelete(attachmentData.key);
                }
              }}
            />
          </div>
        )}
       
      </div>
      {!imageURL && !attachmentData.isDeleting && !noUploadProgress && <div>{attachmentData.progress}%</div>}
      {attachmentData.isDeleting && (
        <div className="flex items-center">
          <div>
            <IconSpinner color={"text-taskinatorBlue"} />
          </div>
          <div className="ml-1">deleting...</div>
        </div>
      )}
    </div>
  );
};

export default OldAttachmentDisplayV4;
