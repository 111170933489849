import React, { useContext, useState } from "react";
import axios from "axios";
import { useInit } from "../hooks/useInit";
import { useInputV4 } from "../../stateManagement/useInputV4";

import InputV4 from "../inputs/input_V4";
import Button from "../UI_elements/button";

import { VALIDATOR_EMAIL } from "./validators/validators";

const ResetPasswordRequestForm = () => {
  const { initState, initStarted, initCompleted, initErrors, initReset } = useInit();
  const { inputState, inputHandler, attachmentHandler } = useInputV4({});
  const [requestSubmitted, setRequestSubmitted] = useState();

  const submitHandler = async (event) => {
    event.preventDefault();
    initStarted(0);
    try {
      const responseData = await axios.put(
        process.env.REACT_APP_BACKEND_URL + `/api/auth/resetpasswordrequest`,
        {
          email: inputState.inputs.email.value,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      setRequestSubmitted(true);
      initCompleted(0);
    } catch (err) {
      console.log(err);
      initErrors(err.response.data.message, 0);
      return;
    }
  };

  return (
    <>
      {requestSubmitted && <div className="text-center my-4">Your request has been submitted. Please check your emails.</div>}
      {!requestSubmitted && (
        <form className="mt-8 w-full" onSubmit={submitHandler}>
          <div className="-space-y-px rounded-md shadow-sm">
            <InputV4
              inputVer="1"
              sid="inputs"
              type="email"
              id="email"
              textSize={"text-base"}
              placeholder={"Email"}
              customClasses={"rounded-md"}
              onInput={inputHandler}
              validators={[VALIDATOR_EMAIL()]}
            />
          </div>
          <Button
            type="submit"
            label={"Request Password Reset"}
            customClasses={`${inputState.isValid ? "bg-taskinatorBlue" : "bg-taskinatorMedGrey"} bg-taskinatorBlue text-taskinatorWhite h-10 w-full mt-2 `}
            disabled={!inputState.isValid}
            isLoading={initState[0].started}
            error={initState[0].error}
          />
        </form>
      )}
    </>
  );
};

export default ResetPasswordRequestForm;
