import React, { useContext, useState } from "react";
import { AppContext } from "../../../contexts/appContext";
import { useInputV4 } from "../../../stateManagement/useInputV4";
import Button from "../../UI_elements/button";
import Modal from "../../UI_elements/modal";
import { useInit } from "../../hooks/useInit";
import { useUploader } from "../../hooks/useUploader";
import FileUploaderV4 from "../../inputs/fileUploader_V4";

const BulkFileUpload = ({
  SaveLocally,
  fileSetId,
  fileSetName,
  type,
  onFilesUploaded,
  customButtonCSS,
  globalHandler,
  required,
  label,
  defaultValue,
  disabled,
  jobData,
  fileId,
  limit,
  markup,
  revision,
  autoTrigger,
  fileNameOverride,
  reset
}) => {
  const inputId = "Attachment";
  const [cancelTokens, setCancelTokens] = useState({});
  const appData = useContext(AppContext);
  const [uploadTrigger, setUploadTrigger] = useState();
  const [isUploading, setIsUploading] = useState();

  const { initState, initStarted, initCompleted, initErrors, initReset } = useInit(0);
  const [saveTrigger, loadingState] = useUploader();

  const [attach, setAttach] = useState();
  // const [uploaderState, dispatch] = useReducer(uploaderReducer, {})
  const { inputState, inputHandler, attachmentHandler } = useInputV4({
    inputs: {},
    attachments: {},
    deleteRef: {},
    isValid: false,
  });

  const closeModalHandler = () => {
    Object.keys(cancelTokens).forEach((key) => {
      if (cancelTokens[key]) {
        cancelTokens[key].cancel("Component unmounted, upload canceled");
      }
    });

    setAttach();
  };

  // useEffect(() => {
  //   return globalHandler ? () => closeModalHandler() : () => {};
  // }, []);

  if (!globalHandler && !SaveLocally) {
    return (
      <>
        <Button
          label="Add New Files"
          onClick={() => {
            initReset(0);
            setAttach(true);
          }}
          customClasses={customButtonCSS ? customButtonCSS : "bg-taskinatorBlue mt-2 border-taskinatorBlue text-taskinatorWhite h-10 text-xs md:text-sm py-2 w-full"}
        />

        {attach && (
          <div className="absolute top-0 left-0 h-screen w-screen border border-red flex items-center justify-center">
            <Modal local={() => closeModalHandler()} size="medium" title="Upload Files" bgColour={"bg-taskinatorLightGrey"}>
              <div className="p-4 flex w-full grow overflow-y-auto flex-col">
                <FileUploaderV4
                disabled={disabled}
                  attachmentHandler={attachmentHandler}
                  revision={revision}
                  type={markup ? "markup" : type}
                  limit={limit}
                  autoTrigger={autoTrigger ? (files) => autoTrigger(files) : () => {}}
                  setIsUploading={setIsUploading}
                  isUploading={isUploading}
                  required
                  fileSetId={fileSetId}
                  fileSetName={fileSetName}
                  jobData={jobData}
                  cancelTokens={cancelTokens}
                  setCancelTokens={setCancelTokens}
                  fileNameOverride={fileNameOverride}
                  defaultValue={defaultValue}
                  
                  // uploadError={(err) => initErrors(err, 0)}
                />
                <Button
                  onClick={() => {
                    onFilesUploaded();
                    closeModalHandler();
                  }}
                  label={"Finalise Details"}
                  disabled={!inputState.isValid}
                  customClasses={`bg-taskinatorBlue text-taskinatorWhite h-10 w-full mt-2 ${inputState.isValid ? "bg-taskinatorBlueGrey" : "bg-taskinatorMedGrey"}`}
                  isLoading={initState[0]?.started}
                />
              </div>
            </Modal>
          </div>
        )}
      </>
    );
  } else {
    return (
      <div className=" flex w-full grow overflow-y-auto flex-col">
        {label && (
          <div className="text-sm flex">
            {label}
            {required ? <div className="text-taskiantorRed pl-1">Required</div> : <div className="text-taskinatorMedGrey pl-1">(Optional)</div>}
          </div>
        )}
        <FileUploaderV4
          disabled={disabled}
          inputHandler={globalHandler ? globalHandler?.inputHandler : inputHandler}
          attachmentHandler={globalHandler ? globalHandler?.attachmentHandler : attachmentHandler}
          customCSS={""}
          fileSetId={fileSetId}
          fileSetName={fileSetName}
          jobData={jobData}
          type={markup ? "markup" : type}
          defaultValue={defaultValue}
          reset={reset}
          cancelTokens={cancelTokens}
          setCancelTokens={setCancelTokens}
          revision={revision}
          markup={markup}
          limit={limit}
          autoTrigger={autoTrigger ? (files) => autoTrigger(files) : () => {}}
       
          fileNameOverride={fileNameOverride}
        />
        {SaveLocally && !autoTrigger && (
          <Button
            onClick={() => {
              onFilesUploaded();
              closeModalHandler();
            }}
            label={"Finalise Details"}
            disabled={!inputState.isValid}
            customClasses={`bg-taskinatorBlue text-taskinatorWhite h-10 w-full mt-2 ${inputState.isValid ? "bg-taskinatorBlueGrey" : "bg-taskinatorMedGrey"}`}
            isLoading={initState[0]?.started}
          />
        )}
      </div>
    );
  }
};

export default BulkFileUpload;
