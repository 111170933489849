import LowerCaseTrim from "./lowerCaseAndTrim";

const TaskFormatter_byTask = (data, filter) => {
  let formattedTracer_V2 = {};

  data
    .filter((tracer) => {
      if (!filter) {
        return true;
      } else {
        if (filter.trim().length === 0) {
          return true;
        } else {
          return (
            LowerCaseTrim(tracer.taskDetails.ref).includes(
              LowerCaseTrim(filter)
            ) ||
            (tracer.wfid._id &&
              LowerCaseTrim(tracer.wfid.name).includes(
                LowerCaseTrim(filter)
              )) ||
            (tracer.itemsOnTracer?.currentItemDetails &&
              LowerCaseTrim(
                tracer.itemsOnTracer?.currentItemDetails?.itemName
              ).includes(LowerCaseTrim(filter))) ||
            (tracer.itemsOnTracer?.currentItemDetails &&
              LowerCaseTrim(
                tracer.itemsOnTracer?.currentItemDetails?.itemNumber
              ).includes(LowerCaseTrim(filter))) ||
            (tracer.aid.name &&
              LowerCaseTrim(tracer.aid.name).includes(LowerCaseTrim(filter))) ||
            tracer.taskDetails.taskTags.filter((tag) =>
              LowerCaseTrim(tag.name).includes(LowerCaseTrim(filter))
            ).length > 0 ||
            tracer.assignee.filter((assignee) =>
              LowerCaseTrim(assignee.lastName).includes(LowerCaseTrim(filter))
            ).length > 0 ||
            tracer.assignee.filter((assignee) =>
              LowerCaseTrim(assignee.firstName).includes(LowerCaseTrim(filter))
            ).length > 0
          );
        }
      }
    })
    .map((filteredTracer) => {
      const { taskDetails, aid, wfid, project, taskStatus, ...tracerDetails } =
        filteredTracer;

      const key = filteredTracer.tid;

      // Determine the key based on available data (currentItemDetailsId, note, or "null")
      const currentItemDetailsId =
        filteredTracer.itemsOnTracer && filteredTracer.itemsOnTracer._id
          ? filteredTracer.itemsOnTracer._id
          : filteredTracer.note
          ? filteredTracer.note
          : "null";

      const tracerObject = {
        ...tracerDetails,
        wfid: wfid,
        aid: aid,
      };

      let currentItemObject = {};

      // Check if there are item details or a note to be added
      if (filteredTracer.itemsOnTracer && filteredTracer.itemsOnTracer._id) {
        currentItemObject.item = filteredTracer.itemsOnTracer;
      } else if (filteredTracer.note) {
        currentItemObject.note = filteredTracer.note;
      }

      // Update or create the structure with the new conditions, avoiding empty tracers array initialization
      const updatedItems_V2 = formattedTracer_V2[key]
        ? {
            ...formattedTracer_V2[key].items,
            [currentItemDetailsId]: formattedTracer_V2[key].items[
              currentItemDetailsId
            ]
              ? {
                  ...formattedTracer_V2[key].items[currentItemDetailsId],
                  // Only add tracers if they exist; avoid empty array initialization
                  tracers: [
                    ...(formattedTracer_V2[key].items[currentItemDetailsId]
                      .tracers || []),
                    tracerObject,
                  ],
                }
              : {
                  ...currentItemObject,
                  tracers: [tracerObject], // Initialize tracers with the current tracer object since it exists
                },
          }
        : {
            [currentItemDetailsId]: {
              ...currentItemObject,
              tracers: [tracerObject], // Directly initializing with the tracer object since it exists
            },
          };

      // Update the main structure with the new or updated items
      formattedTracer_V2 = {
        ...formattedTracer_V2,
        [key]: {
          ...taskDetails,
          actionName: aid.name,
          workflowName: wfid.name,
          tid: filteredTracer.tid,
          taskStatus: taskStatus,
          items: updatedItems_V2, // Keep the renamed structure to items
          _id: tracerDetails._id, // Ensure this is correctly placed according to your data structure requirements
        },
      };

      // const key = filteredTracer.tid

      // const updatedTracerDetails_V2 = formattedTracer_V2[key]
      //   ? {
      //       ...formattedTracer_V2[key].tracers,
      //       [filteredTracer._id]: { ...tracerDetails, wfid: wfid, aid: aid },
      //     }
      //   : { [filteredTracer._id]: { ...tracerDetails, wfid: wfid, aid: aid } }

      // formattedTracer_V2 = {
      //   ...formattedTracer_V2,
      //   [key]: {
      //     ...taskDetails,
      //     actionName: aid.name,
      //     workflowName: wfid.name,
      //     tid: filteredTracer.tid,
      //     taskStatus: taskStatus,
      //     tracers: updatedTracerDetails_V2,
      //     _id: tracerDetails._id,
      //   },
      // }
      return null;
    });

  return formattedTracer_V2;
};

export default TaskFormatter_byTask;
